import React, { Component } from "react";
import * as d3 from "d3";
import "../../css/components/Card/D3StackedBarChart.css";

class D3StackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 20, right: 10, bottom: 30, left: 35 };
    this.duration = 1000;
    this.clientWidth = 217;
    this.clientHeight = 217;
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.update();
  };

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  init = () => {
    this.x = d3.scaleBand();
    this.y = d3.scaleLinear();
    this.createSvg();
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3StackedBarChartSVG")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "gcontainer");

    const barG = this.svgG.selectAll("g.bar-container").data(
      (d) => [d],
      (d) => d
    );
    barG
      .enter()
      .append("g")
      .merge(barG)
      .attr("class", "bar-container")
      .attr("id", (d) => d + "bar-container");
    barG.exit().remove();
    this.barG = this.svg.select("g#" + svgId + "bar-container");

    const barTitleG = this.svgG.selectAll("g.bar-title").data(
      (d) => [d],
      (d) => d
    );
    barTitleG
      .enter()
      .append("g")
      .merge(barTitleG)
      .attr("class", "bar-title")
      .attr("id", (d) => d + "bar-title");
    barTitleG.exit().remove();
    this.barTitleG = this.svgG.select("g#" + svgId + "bar-title");

    const gLine = this.svgG.selectAll("g.dashed-line").data(
      (d) => [d],
      (d) => d
    );
    gLine
      .enter()
      .append("g")
      .merge(gLine)
      .attr("class", "dashed-line")
      .attr("id", (d) => d + "dashed-line");
    gLine.exit().remove();
    this.gLine = this.svgG.select("g#" + svgId + "dashed-line");

    const gAxisSelection = this.svgG.selectAll("g.axis-container").data(
      (d) => [d],
      (d) => d
    );
    gAxisSelection
      .enter()
      .append("g")
      .merge(gAxisSelection)
      .attr("class", "axis-container")
      .attr("id", (d) => d + "axis-container");
    gAxisSelection.exit().remove();
    this.axisG = this.svgG.select("g#" + svgId + "axis-container");

    const xAxis = this.axisG.selectAll(".x.axis").data(
      (d) => [d],
      (d) => d
    );
    xAxis
      .enter()
      .append("g")
      .merge(xAxis)
      .attr("id", (d) => d + "xAxis")
      .attr("class", "x axis");
    xAxis.exit().remove();
    this.xAxis = this.svgG.select("g#" + svgId + "xAxis");

    const yAxis = this.axisG.selectAll(".y.axis").data(
      (d) => [d],
      (d) => d
    );
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("id", (d) => d + "yAxis")
      .attr("class", "y axis");
    yAxis.exit().remove();
    this.yAxis = this.svgG.select("g#" + svgId + "yAxis");

    const tooltipRect = this.svgG.selectAll("rect.tooltip-rect").data(
      (d) => [d],
      (d) => d
    );
    tooltipRect
      .enter()
      .append("rect")
      .merge(tooltipRect)
      .attr("id", (d) => d + "tooltip-rect")
      .attr("class", "tooltip-rect")
      .style("opacity", 0);
    tooltipRect.exit().remove();
    this.tooltipRect = this.svgG
      .select("rect#" + svgId + "tooltip-rect")
      .on("mousemove", () => {
        d3.event.stopPropagation();
        this.drawTooltip();
      })
      .on("mouseout", () => {
        d3.event.stopPropagation();
        this.drawTooltip(true);
      });

    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;

    this.x.rangeRound([0, this.width]).padding(0.15);
    this.y.rangeRound([this.height, 0]);

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height + this.margin.top + this.margin.bottom
        }`
      );

    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.xAxis.attr("transform", "translate(0, " + this.height + ")");
    this.yAxis.attr("transform", "translate(0, 0)");
    this.tooltipRect
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this.height);
  };

  update = () => {
    const { sbData } = this.props;
    this.autoAlignSVG();
    if (sbData.data && sbData.data.length) {
      this.x.domain(sbData.data.map((d) => d.label));
      this.y.domain([0, 100]).nice();

      this.updateXAxis();
      this.updateYAxis();
      this.updateStackedBar();
      this.updateDashedLine();
      this.updateBarTitle();
    }
  };

  updateXAxis = () => {
    this.xAxis
      .call(d3.axisBottom(this.x))
      .selectAll(".tick text")
      .style("font-size", "12px")
      .style("fill", "#424B54")
      .style("font-family", "ProximaNova-Regular")
      .call(function (t) {
        t.each(function (d) {
          // for each one
          const self = d3.select(this);
          const s = self.text().split(" "); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "0")
            .attr("dy", (d, i) => {
              return i === 0 ? "0.5em" : "1em";
            })
            .text((d) => d);
          sSelection.exit().remove();
        });
      });
  };

  updateYAxis = () => {
    this.yAxis
      .call(
        d3
          .axisLeft(this.y)
          .ticks(4)
          .tickFormat((d) => d + "%")
      )
      .selectAll(".tick text")
      .style("font-size", "10px")
      .style("font-family", "ProximaNova-Regular")
      .style("fill", "#424b54");
  };

  updateStackedBar = () => {
    const { sbData } = this.props;
    const stackBarData = d3
      .stack()
      .keys(sbData.columns)(sbData.data)
      .sort((a, b) => {
        return b.index - a.index;
      });

    const stack = this.barG
      .selectAll("g.stack")
      .data(stackBarData, (d) => d.key);
    const newStack = stack.enter().append("g");
    newStack
      .merge(stack)
      .attr("class", (d) => "stack " + d.key)
      .style("fill", (d) => (d.key === "black" ? "#424B54" : "#D3D3D3"));
    stack.exit().remove();

    const bar = newStack
      .merge(stack)
      .selectAll("rect.bar")
      .data(
        (d) => {
          d.forEach((c) => {
            c.key = d.key;
          });
          return d;
        },
        (d) => d.data && d.data.label
      );
    const newBar = bar.enter().append("rect");
    newBar
      .attr("class", "bar")
      .attr("x", (d) => this.x(d.data.label))
      .attr("width", this.x.bandwidth())
      .attr("height", 0)
      .attr("y", (d) => (d.key === "gray" ? this.y(0) : this.y(d[0])))
      .transition()
      .duration(this.duration)
      .attr("height", (d) => {
        return d.key === "gray" ? this.y(0) : this.y(d[0]) - this.y(d[1]);
      })
      .attr("y", (d) => (d.key === "gray" ? this.y(100) : this.y(d[1])));
    bar
      .attr("class", "bar")
      .attr("x", (d) => this.x(d.data.label))
      .attr("width", this.x.bandwidth())
      .attr("height", (d) => {
        return d.key === "gray" ? this.y(0) : this.y(d[0]) - this.y(d[1]);
      })
      .attr("y", (d) => (d.key === "gray" ? this.y(100) : this.y(d[1])));
    bar.exit().remove();
  };

  updateDashedLine = () => {
    const { sbData } = this.props;
    const line = this.gLine.selectAll("path.dash").data(
      sbData.data.map((o) => {
        o.value = sbData.usValue;
        return o;
      }),
      (d) => d.label
    );
    const newLine = line.enter().append("path");
    newLine
      .merge(line)
      .attr("class", (d) => "dash " + d.label.toLowerCase().replace(/ /g, "-"))
      .attr("d", (d) => {
        const offset =
          d.value === d.black ? this.x.paddingOuter() * this.x.bandwidth() : 0;
        const path = d3.path();
        path.moveTo(this.x(d.label) - offset, this.y(d.value));
        path.lineTo(
          this.x(d.label) + this.x.bandwidth() + offset,
          this.y(d.value)
        );
        path.closePath();
        return path;
      })
      .style("stroke", (d) => (d.black > d.value ? "#FFFFFF" : "#424B54"))
      .style("stroke-width", "1px")
      .style("stroke-dasharray", "5,5");
    line.exit().remove();
  };

  updateBarTitle = () => {
    const { sbData } = this.props;
    const text = this.barTitleG
      .selectAll("text.bar-title")
      .data(sbData.data, (d) => d.label);
    const newText = text.enter().append("text");

    newText
      .merge(text)
      .attr("class", "bar-title")
      .attr("dx", (d) => this.x(d.label) + this.x.bandwidth() / 2)
      .attr("dy", this.y(100) - 5)
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "12px")
      .style("fill", "#424b54")
      .text((d) => d.population);
    text.exit().remove();
  };

  drawTooltip = (isRemove) => {
    const { sbData } = this.props;
    if (this.parentEle) {
      this.fontSize =
        Math.min(this.parentEle.clientWidth, this.parentEle.clientHeight) / 20;
    } else {
      this.fontSize = 10;
    }

    const makeTooltip = (data, mPos) => {
      const tooltip = d3
        .select("body")
        .selectAll("div.d3-tooltip")
        .data(data, (d) => d);
      const newTooltip = tooltip.enter().append("div");
      newTooltip
        .merge(tooltip)
        .attr("class", "d3-tooltip")
        .style("display", "block")
        .style("position", "absolute")
        .style("font-size", this.fontSize)
        .style("font-family", "ProximaNova-Regular")
        .style("color", "#424b54")
        .style("background-color", "rgba(255, 255, 255, 1)")
        .style("box-shadow", "0px 5px 15px 0px rgba(0,0,0,0.3)")
        .style("padding", this.fontSize / 2 + "px")
        .style("text-align", "left")
        .style("left", mPos[0] + this.fontSize * 2 + "px")
        .style("top", mPos[1] - this.fontSize * 2 + "px");

      const div = newTooltip
        .merge(tooltip)
        .selectAll("div.d3-tooltip-div")
        .data(
          (d) =>
            Object.keys(d).map((key, i) => {
              return { id: i, text: d[key], key };
            }),
          (d) => d.id
        );
      const newDiv = div.enter().append("div");
      newDiv
        .merge(div)
        .attr("class", "d3-tooltip-div")
        .style("font-size", this.fontSize + "px")
        .style("font-weight", (d) => (d.key === "title" ? "bold" : ""))
        .html((d) => d.text);
      div.exit().remove();
      tooltip.exit().remove();
    };

    if (isRemove) {
      makeTooltip([], [0, 0]);
    } else {
      const mPos = d3.mouse(d3.select("body").node());
      makeTooltip(sbData.tooltip, mPos);
    }
  };

  wrap = (text, width) => {
    text.each(function () {
      var breakChars = ["/", "&", "-"],
        text = d3.select(this),
        textContent = text.text(),
        spanContent;

      breakChars.forEach((char) => {
        // Add a space after each break char for the function to use to determine line breaks
        textContent = textContent.replace(char, char + " ");
      });

      let words = textContent.split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy") || 0),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em");
      word = words.pop();
      breakChars.forEach((char) => {
        // Remove spaces trailing breakChars that were added above
        spanContent = spanContent.replace(char + " ", char);
      });
      while (word) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          spanContent = line.join(" ");
          tspan.text(spanContent);
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .style("font-size", this.fontSize + "px")
            .style("font-family", "ProximaNova-Regular")
            .style("fill", "#424b54")
            .text(word);
        }
      }
    });
  };

  render() {
    return (
      <div className="D3StackedBarChart h-100 w-100">
        <div
          className="h-100 d-flex justify-content-center align-items-center w-100"
          ref={(ele) => (this.parentEle = ele)}
        />
      </div>
    );
  }
}

export default D3StackedBarChart;
