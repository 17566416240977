import React, { Component, Fragment } from "react";
import * as _ from "lodash";
import * as d3 from "d3";
import lunchImg from "../../images/lunch.svg";
import lunchGooGreen from "../../images/lunchGooGreen.svg";
import lunchGooRed from "../../images/lunchGooRed.svg";
import "../../css/components/Card/LunchChartCard.css";

class LunchChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 23, bottom: 10, left: 10 };
    this.clientWidth = 250;
    this.clientHeight = 155;
    this.state = {
      ratio: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { lunchData } = this.props;
    if (!_.isEqual(lunchData, nextProps.lunchData)) {
      this.init(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init(this.props);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  init = (props) => {
    this.createSvg(props);
  };

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 245) {
      this.setState({
        ratio: this.size / 245,
      });
    }
  };

  createSvg = (props) => {
    const id = props.id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([id], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "LunchChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + id);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + id + "gcontainer");

    this.autoAlignSVG();
    this.makeLunchChart(props);
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;

    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 245) {
      this.setState({
        ratio: this.size / 245,
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height);
    this.svgG.attr(
      "transform",
      "translate(" + (this.margin.left + 2) + "," + 0 + ")"
    );
  };

  makeLunchChart = (props) => {
    const {
      lunchData: { data },
    } = props;

    const height = Math.round(this.height / 2);
    const width = Math.round(this.width / 3);

    const imgGauge = this.svgG
      .selectAll("img.lunch-img")
      .data([{ src: lunchImg }], (d) => d.src);

    const imageForeignObject = imgGauge
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height);

    const imageEleDiv = imageForeignObject
      .append("xhtml:div")
      .attr("class", "lunch-img-div")
      .style("position", "relative");

    const newImageEle = imageEleDiv.append("img");
    newImageEle
      .merge(imgGauge)
      .attr("src", (d) => d.src)
      .attr("class", "lunch-img")
      .attr("width", this.width)
      .attr("height", this.height);

    let lunchGooSrc = lunchGooGreen;
    if (data.length && data[0].text === "HIGHER") {
      lunchGooSrc = lunchGooRed;
    }

    const img = this.svgG
      .select(".lunch-img-div")
      .selectAll("img.lunch-goo-img")
      .data([{ src: lunchGooSrc }], (d) => d.src);
    const newImg = img.enter().append("img");
    newImg
      .merge(img)
      .attr("class", "lunch-goo-img")
      .attr("src", (d) => d.src)
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(14, ${height + 4})`);
    img.exit().remove();
  };

  render() {
    const {
      lunchData: { data },
    } = this.props;
    const { ratio } = this.state;
    const titleStyle = {
      fontSize: "26px",
      fontFamily: "ProximaNova-Bold",
      color: "#424b54",
      marginBottom: 0,
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
      padding: "7px 0",
    };
    const subTitleStyle = {
      fontSize: "13px",
      fontFamily: "Proxima Nova, Light",
      width: "100%",
      color: "#424b54",
      marginBottom: 0,
      lineHeight: 1.3,
    };

    const compareText = data.length ? data[0].text : "";
    //if(data.length) { data[0].ourcommunity = null; }
    return (
      <div
        className="LunchChart d-flex flex-column justify-content-center align-items-center h-100 w-100"
        ref={(ele) => (this.mainEle = ele)}
        style={{ color: "#424b54" }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            height: "245px",
            width: "245px",
            transform: `scale(${ratio})`,
          }}
        >
          <div style={{ height: "135px" }}>
            <div ref={(ele) => (this.parentEle = ele)} />
          </div>
          <div className="d-flex px-2 text-left">
            <div className="flex-shrink-1 pr-2" style={titleStyle}>
              <span>
                {data && data.length && data[0].ourcommunity
                  ? Math.round(data[0].ourcommunity) + "%"
                  : "NA"}
              </span>
            </div>
            <div>
              {data && data.length && data[0].ourcommunity ? (
                <div style={subTitleStyle}>
                  <span>of children are eligible for</span>
                  <br />
                  <span>free and reduced price lunch</span>
                  <br />
                  <span>
                    in<b> Our Community.</b>
                  </span>
                </div>
              ) : (
                <div style={subTitleStyle}>
                  <span>
                    NCES <b>data not available</b> for{" "}
                  </span>
                  <br />
                  <span>free and reduced price lunch </span>
                  <br />
                  <span>in schools in MA and TN.</span>
                </div>
              )}
              <div className="pt-2" style={subTitleStyle}>
                {data && data.length && data[0].ourcommunity && (
                  <Fragment>
                    <span>
                      This is
                      <span style={{ color: data.length && data[0].color }}>
                        <b> {compareText} </b>
                      </span>
                      {compareText === "EQUAL" ? "to" : "than"} the
                    </span>
                    <br />
                  </Fragment>
                )}
                <span>
                  U.S. average of{" "}
                  <b>{data.length && Math.round(data[0].unitedstates)}%</b>
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LunchChart;
