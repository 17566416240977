import React, { useState, useEffect, useRef } from "react";
import "../../css/components/ImageEditorPopup/ImageEditorPopup.css";
import AvatarEditor from "react-avatar-editor";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { StandardTallModalPopup } from "common-components";
import SelectFeaturedImagePopup from "../CommonComponents/SelectFeaturedImagePopup";

const addimage = require("../../images/uni-featured.png");

const FeaturedImagePopup = (props) => {
  const {
    avatarImage,
    avatarHeight,
    avatarEditorRatio,
    data,
    handleLoginPopUp,
    featureImageId,
    handleUpdate,
    featureImageUpdated,
    handleUpdateBoard,
    closePopUp,
    userData,
  } = props;

  useEffect(() => {
      if (avatarImage) {
          console.log("setImageBase(avatarImage)");
      setImageBase(avatarImage);
    } else {
          console.log("setImageBase(data.board.featureImage");
          setImageBase(data.board.featureImage ? data.board.featureImage.url : "");
    }
  }, [avatarImage, data.board.featureImage]);

  const [imageBase, setImageBase] = useState();
  const [imageName, setImageName] = useState("");
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const max = 3;
  const min = 0.25;
  const [isWorking, setIsWorking] = useState(false);
  const [wasError, setWasError] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [parentImageId, setParentImageId] = useState();
  const [isFeatureImageModalVisible, setIsFeatureImageModalVisible] =
    useState(false);

  useEffect(() => {
      console.log("useEffect avatarHeight");
    let width = 1000;
    let height = avatarHeight;
    if (height) {
      const ratio = avatarEditorRatio.split(":");
      width = (ratio[0] * height) / ratio[1];
    } else {
      const ratio = avatarEditorRatio.split(":");
      height = (width * ratio[1]) / ratio[0];
    }
    setHeight(height);
    setWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImage = (e) => {
    // TODO: do a raw original upload with correct async timing
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageBase(reader.result);
        setImageName(file.name);
        setParentImageId(null);
        fetch(reader.result)
          .then((res) => res.blob())
          .then((blob) => {
            // TODO: then get the new imageId and select it for editing while setting
            // parentImageId
            handleUploadImage(
              { 0: new File([blob], "ProjectImg.png", { type: "image/png" }) },
              "original"
            );
          });
      };
      reader.readAsDataURL(file);
    }
  };

  // TODO: too much duplicated code here, need to refactor use of uploadBoardImage mutation
  const handleUploadImage = (file, imageList, callback = null) => {
    const mutation = graphql`
      mutation FeaturedImagePopupUploadMutation(
        $input: UploadBoardImageInput!
      ) {
        uploadBoardImage(input: $input) {
          success
          boardImageNode {
            id
            name
            url
          }
        }
      }
    `;
    var uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    const variables = {
      input: {
        file: null,
        imageList: imageList,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      uploadables,
      onCompleted: (response, errors) => {
        if (callback != null) {
          callback(response.uploadBoardImage.boardImageNode.id);
          console.log("child image uploaded");
        } else {
          setParentImageId(response.uploadBoardImage.boardImageNode.id);
          console.log("parent image uploaded");
        }
      },
      onError: (err) => {
        console.error("upload image Error from server", err);
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          handleLoginPopUp();
          setIsWorking(false);
          setWasError(false);
          setWasSaved(false);
        } else {
          setIsWorking(false);
          setWasError(true);
          setWasSaved(false);
        }
      },
    });
  };

  const rotateMethod = (value) => {
    setRotate(rotate + value);
    setWidth(height);
    setHeight(width);
  };

  const scaleMethod = (value) => {
    setScale(value);
  };

  const onClickSave = () => {
    //const { featureImageId } = this.props;
    if (isWorking) {
      return;
    }
    setIsWorking(true);
    setWasSaved(false);
    setWasError(false);
    // TODO: take a snapshot of current editor canvas and upload new child image
    fetch(editorRef.current.getImage().toDataURL())
      .then((res) => res.blob())
      .then((blob) => {
        let newParentImageId =
          parentImageId == null ? featureImageId : parentImageId;
        setParentImageId(newParentImageId);
        handleUploadImage(
          { 0: new File([blob], "ProjectImg.png", { type: "image/png" }) },
          "co",
          handleUpdateBoardApi
        );
      });
  };

  // api call to update board
  const handleUpdateBoardApi = (featureImageId) => {
    if (!!featureImageId) {
      const mutation = graphql`
        mutation FeaturedImagePopupSaveMutation($input: UpdateBoardInput!) {
          updateBoard(input: $input) {
            boardNode {
              id
              name
              subtitle
            }
          }
        }
      `;
      const variables = {
        input: {
          boardId: data.board.id,
          featureImageId: featureImageId,
        },
      };

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          featureImageUpdated(featureImageId);
          handleUpdate();
          handleUpdateBoard(true);
          setIsWorking(false);
          setWasSaved(true);
          setWasError(false);
        },
        onError: (err) => {
          console.error("update board Error from server", err);
          if (
            err.errors &&
            err.errors.length &&
            err.errors[0].message === "UserLoginRequired"
          ) {
            handleLoginPopUp();
            setIsWorking(false);
            setWasError(false);
            setWasSaved(false);
          } else {
            setIsWorking(false);
            setWasError(true);
            setWasSaved(false);
          }
        },
      });
    }
  };

  useEffect(() => {
      console.log("useEffect isWorking");
    if (!isWorking && wasSaved && !wasError) {
      setTimeout(() => {
        if (wasSaved) {
          setWasSaved(false);
        }
      }, 10000);
    }
  }, [isWorking, wasSaved, wasError]);

  const editorRef = useRef();
  const uploadImageRef = useRef();

  const AvatarEditorComponent = () => {
    return (
      <>
        <div className="row mb-0">
          <div className="col-12 p-0 text-center">
            <AvatarEditor
              ref={editorRef}
              image={imageBase ? imageBase : addimage}
              width={width}
              height={height}
              border={30}
              color={[0, 0, 0, 0.6]} // RGBA
              scale={scale}
              rotate={rotate}
              style={{ height: "188px", width: "283px" }}
            />
          </div>
        </div>
      </>
    );
  };

  const onSelectFeaturedImage = (featureImageId, url) => {
    setParentImageId(featureImageId);
    setImageBase(url);
  };

  const pages = [
    {
      showTitle: true,
      title: "Edit Image",
      showPageItem: true,
      showButton: true,
      buttonTitle: wasSaved ? "SAVED" : "SAVE",
      name: "edit-image",
      modalCustomClass: "overflow-x px-3",
      handleOnClick: () => {
        onClickSave();
      },
      formItems: [
        {
          type: "image",
          component: <AvatarEditorComponent />,
        },
        {
          fieldContainerClass: "d-flex mt-5",
          label: "My Images:",
          labelClassName: "min-w-100",
          type: "button",
          buttonTitle: "SELECT",
          helperText: "Browse Images",
          helperTextClass: "notes",
          handleOnClick: () => {
            setIsFeatureImageModalVisible(true);
          },
        },
        {
          fieldContainerClass: "d-flex  mt-3",
          label: "Upload File:",
          labelClassName: "min-w-100",
          type: "button",
          buttonTitle: "UPLOAD",
          helperText: imageName ? imageName : "No File Selected",
          helperTextClass: "notes",
          handleOnClick: () => uploadImageRef.current.click(),
        },
        {
          fieldContainerClass: "d-flex mt-3",
          type: "file",
          ref: uploadImageRef,
          accept: "image/png,image/x-png,image/gif,image/jpeg",
          multiple: false,
          className: "hidden",
          onChange: addImage,
        },
        {
          componentWrapperClass: "d-flex",
          fieldContainerClass: "d-flex mt-3",
          label: "Zoom:",
          labelClassName: "min-w-100",
          type: "slider",
          parentClassName: "w-75 mr-2 mt-2",
          min: min,
          max: max,
          scale: scale,
          onChange: scaleMethod,
        },
        {
          label: "Rotate:",
          labelClassName: "min-w-100",
          fieldContainerClass: "d-flex mt-3",
          componentWrapperClass: "d-flex",
          type: "button",
          multipleButtons: true,
          buttons: [
            {
              buttonTitle: "LEFT",
              handleOnClick: () => rotateMethod(-90),
            },
            {
              buttonTitle: "RIGHT",
              handleOnClick: () => rotateMethod(+90),
            },
          ],
        },
      ],
    },
  ];
  const [pagesArray, setPagesArray] = useState(pages);
  const currentPage = pagesArray[0];

  useEffect(() => {
      console.log("useEffect setPagesArray");
    setPagesArray(pages);
  }, [imageBase, scale, rotate, imageName, wasSaved]);

  const imageType =
    avatarEditorRatio === "16:5"
      ? "banner"
      : avatarEditorRatio === "3:2"
      ? "co"
      : "squarefeature";

  return (
    <>
      {isFeatureImageModalVisible ? (
        <SelectFeaturedImagePopup
          avatarEditorRatio={avatarEditorRatio}
          avatarImage={avatarImage}
          avatarHeight={avatarHeight}
          hash={"#selectfeaturedimage"}
          userData={userData}
          handleUpdateBoard={handleUpdateBoard}
          handleLoginPopUp={handleLoginPopUp}
          onSelectFeaturedImage={onSelectFeaturedImage}
          goBack={() => setIsFeatureImageModalVisible(false)}
          style={{ height: "188px", width: "283px" }}
          imageType={imageType}
          data={data}
          closePopUp={() => setIsFeatureImageModalVisible(false)}
        />
      ) : (
        <StandardTallModalPopup
          goBack={closePopUp}
          closePopUp={closePopUp}
          pages={pagesArray}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default FeaturedImagePopup;
