// External mutation for one-time save of all updated sections' contents
//  followed by call this.props.handleUpdateBoard(true, hasUnpublishedChanges);
import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
    mutation  
        ProjectBoardUpdateSectionContentsMutation($input:UpdateBoardSectionContentsInput!)  
        {  updateBoardSectionContents(input:$input)  
            {  updatesApplied 
            }  
        }        
    `;

export default (contents, callback, errorCallback) => {
    const variables = {
        "input": {"contents": contents}
    };

    //console.log(sectionLayoutMutation);
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response, errors) => {
                // console.log('Response from server section contents update.',JSON.stringify(response))
                // console.log('Response from server section contents update.',(response))
                callback();
            },
            onError: (err) => {
                console.error("Error from server section contents update SectionContentMutation", JSON.stringify(err));
                errorCallback(err);
            }
        },
    );
}
