import React, { Component } from 'react';
import D3DualHorizontalBarChart from '../Card/D3DualHorizontalBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3DualHorizontalBarWideChart extends Component {
  render() {
    const { id, dhbData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'dualHorizontalBarWideChart' : 'dualHorizontalBarTwitterChart');
    const content = (
      <div className='D3DualHorizontalBarWideChart d-flex w-100 h-100 '>
        {(dhbData && dhbData.data) &&
        <D3DualHorizontalBarChart
          {...this.props}
          id={wideChartId}
          key={wideChartId}
          xTitle={'DEATHS PER 100k PEOPLE'}
          dhbData={dhbData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3DualHorizontalBarWideChart;
