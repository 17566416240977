/* This acts as a landing page for private sharing links like:
    https://www.broadstreet.io/board/sh/Qm9hcmROb2RlOi0x/23014ec932b4b4eab40aeaf1c2e08ead3b0cf

  The URL parts are:
    /sh/ - tells us it is a sharing link
    /Qm9hcmROb2RlOi0x/ - a GraphQL Relay ID for the type of resource -
      this one translates to BoardNode which is a page or collection
    /23014ec932b4b4eab40aeaf1c2e08ead3b0cf - resource ID pointing to the
      actual resource.  For a BoardNode this references the invitation_link
      field labeled "Copy Private Link" in Page Settings
*/
import React, { Component } from "react";
import { withRouter } from "react-router";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FooterComponent from "../CommonComponents/FooterComponent";
import Subheader from "../Subheader/Subheader";
import "../../css/components/InvitationLinkConfirm/InvitationLinkConfirm.css";

class InvitationLinkConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activatedBoardId: null,
      activatedBoardName: null,
      activationError: null,
      isWorking: false,
      wasError: false,
      wasSaved: false,
    };
  }

  componentDidMount() {
    if (this.testForLogin()) {
      return;
    }
    this.setState({ isWorking: true }, () => {
      this.useBoardInvitationLink();
    });
  }

  testForLogin = () => {
    const { userData, handleLoginPopUp } = this.props;
    if (
      (userData == null ||
        userData.currentUser == null ||
        userData.currentUser.edges.length === 0) &&
      handleLoginPopUp != null
    ) {
      // prompt for login if needed
      handleLoginPopUp();
      return true;
    }
    return false;
  };

  useBoardInvitationLink = () => {
    const { nodeType, invite, history, updatedBoard } = this.props;
    const mutation = graphql`
      mutation InvitationLinkConfirmMutation(
        $input: UseBoardInvitationLinkInput!
      ) {
        useBoardInvitationLink(input: $input) {
          boardNode {
            id
            name
            sourceBoard {
              publishedStoryCard {
                id
              }
            }
            publishedStoryCard {
              id
            }
          }
        }
      }
    `;
    const input = {
      linkText: "/sh/" + nodeType + "/" + invite,
    };

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        if (response.useBoardInvitationLink.boardNode != null) {
          // check for activated homepage board id then update
          const activatedBoardId = response.useBoardInvitationLink.boardNode.id;
          const activatedBoardName =
            response.useBoardInvitationLink.boardNode.name;
          const publishedStoryCardId =
            response.useBoardInvitationLink.boardNode.sourceBoard != null
              ? response.useBoardInvitationLink.boardNode.sourceBoard
                  .publishedStoryCard.id
              : response.useBoardInvitationLink.boardNode.publishedStoryCard.id;
          this.setState(
            {
              activatedBoardId,
              activatedBoardName,
              isWorking: false,
              wasSaved: true,
              wasError: false,
              publishedStoryCardId,
            },
            () => {
              setTimeout(() => {
                history.push(`/pubboard/${escape(publishedStoryCardId)}`);
              }, 5000);
              updatedBoard();
            }
          );
        } else {
          console.error(JSON.stringify(response));
          this.setState({
            isWorking: false,
            wasSaved: false,
            wasError: true,
            activationError:
              "We encountered an error processing your request.  Please double check the link you used and try again.  Contact our support using the Help options if you continue to experience difficulties.",
          });
        }
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        this.setState({
          isWorking: false,
          wasSaved: false,
          wasError: true,
          activationError: (
            <div>
              There was a system error processing your request.
              <br /> Please double check the link you used and try again.
              <br /> Contact support using our Help options if you continue to
              experience difficulties.
            </div>
          ),
        });
      },
    });
  };

  render() {
    const {
      wasSaved,
      wasError,
      isWorking,
      activatedBoardId,
      activationError,
      activatedBoardName,
      publishedStoryCardId,
    } = this.state;
    return (
      <div className="invitationLinkConfirm">
        <Helmet>
          <meta
            name="description"
            content="CHNA Community Health Needs Assessment by BroadStreet"
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="BroadStreet" />
          <meta
            property="og:title"
            content="Collaborate on a Story - BroadStreet"
          />
          <meta
            property="og:description"
            content="Collaborate on a story while visiting BroadStreet"
          />
          <meta
            property="og:url"
            content="https://www.broadstreet.io/board/sh"
          />
          <meta itemprop="name" content="BroadStreet Collaborate on a Story" />

          <meta
            name="twitter:title"
            content="Collaborate on a Story - BroadStreet"
          />
          <meta
            name="twitter:description"
            content="Collaborate on a story while visiting BroadStreet"
          />

          <title>Activate Custom Homepage - BroadStreet</title>
        </Helmet>
        <div className="projectBoard-right-component">
          <div className="project-board-home-wrapper container">
            <Subheader />
            <div>
              <div>
                <div className="project-board-home-wrapper container mt-5">
                  {wasSaved === true && activatedBoardId != null && (
                    <div>
                      Added you to the Story <b>{activatedBoardName}</b>.<br />
                      You should be automatically redirected there shortly, or{" "}
                      <Link to={`/pubboard/${escape(publishedStoryCardId)}`}>
                        tap here to visit it.
                      </Link>
                    </div>
                  )}
                  {wasError === true && <div>{activationError}</div>}
                  {isWorking === true && (
                    <div>
                      Please wait a moment while we process your request.
                    </div>
                  )}
                  <br />
                  <br />
                </div>
              </div>
              <FooterComponent />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvitationLinkConfirm);
