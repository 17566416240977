import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import TextLayoutType1 from '../Card/TextLayoutType1';

class TextLayoutType1Wide extends Component {
  render() {
    const { textLayoutType1, id, showTwitterCardPopup } = this.props;
    const content = (
      textLayoutType1 &&
      <TextLayoutType1
        id={id}
        key={id + 'textLayoutType1Wide'}
        mainTitle={textLayoutType1.mainTitle}
        mainSubTitle={textLayoutType1.mainSubTitle}
        title1={textLayoutType1.title1}
        title2={textLayoutType1.title2}
        isWide={true}
      />
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default TextLayoutType1Wide;
