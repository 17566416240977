const cardAmex = require('../../images/creditcards/americanexpress.svg');
const cardDiners = require('../../images/creditcards/dinersclub.svg');
const cardDiscover = require('../../images/creditcards/discover.svg');
const cardJcb = require('../../images/creditcards/jcb.svg');
const cardMastercard = require('../../images/creditcards/mastercard.svg');
const cardVisa = require('../../images/creditcards/visa.svg');
const cardUnknown = require('../../images/creditcards/bank.svg');

class CreditCardImages {
    getImage = (brand) => {
        var cardImage;
        if (brand === 'amex') {
            cardImage = cardAmex;
        } else if (brand === 'diners') {
            cardImage = cardDiners;
        } else if (brand === 'discover') {
            cardImage = cardDiscover;
        } else if (brand === 'jcb') {
            cardImage = cardJcb;
        } else if (brand === 'mastercard') {
            cardImage = cardMastercard;
        } else if (brand === 'unionpay') {
            cardImage = cardUnknown;
        } else if (brand === 'visa') {
            cardImage = cardVisa;
        } else {
            // unknown?
            cardImage = cardUnknown;
        }
        return cardImage;
    }
    
    getDefaultImage() {
        return cardUnknown;
    }
};
export default new CreditCardImages();