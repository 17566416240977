/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CardButtonsPopUpCardDataQueryVariables = {|
  input: string
|};
export type CardButtonsPopUpCardDataQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +jsonid: ?string,
    +additionalConfiguration: ?{|
      +jsonProperties: string
    |},
    +chartImage: ?string,
    +configuration: {|
      +id: string,
      +author: ?{|
        +id: string,
        +name: ?string,
        +profileImage: ?{|
          +url: ?string
        |},
      |},
      +publishedStoryBoard: ?{|
        +hasEditPermission: ?boolean,
        +sourceBoard: ?{|
          +id: string,
          +name: string,
          +publishOutsidePaywall: boolean,
        |},
      |},
      +citation: ?string,
      +endYear: ?number,
      +internalOrderList: string,
      +jsonStyleProperties: string,
      +organization: ?{|
        +id: string,
        +organizationName: string,
      |},
      +permaLink: ?string,
      +report: ?{|
        +id: string,
        +jsonid: ?string,
      |},
      +savedArea: ?{|
        +id: string,
        +jsonid: ?string,
        +name: string,
      |},
      +startYear: ?number,
      +storyLink: string,
      +storyPublishedDate: ?any,
      +storyUpdatedDate: ?any,
      +thumbnailImage: ?{|
        +id: string,
        +url: ?string,
      |},
      +subtitle: ?string,
      +title: ?string,
      +toolLink: string,
    |},
    +cardPosition: number,
    +frontFacingComponent: {|
      +componentType: string,
      +name: string,
    |},
    +subComponentType: ?string,
    +statistics: ?{|
      +name: string,
      +jsonData: string,
    |},
    +isFavorite: ?boolean,
    +sharingStatus: number,
    +tags: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardTag: {|
            +name: string,
            +permaTag: number,
          |}
        |}
      |}>
    |},
    +boardSection: ?{|
      +board: {|
        +id: string,
        +isCardFamily: boolean,
        +isPublishedStory: boolean,
        +publishOutsidePaywall: boolean,
        +name: string,
        +publicFeedStatus: number,
        +publishedStoryCard: ?{|
          +id: string
        |},
      |}
    |},
  |}
|};
export type CardButtonsPopUpCardDataQuery = {|
  variables: CardButtonsPopUpCardDataQueryVariables,
  response: CardButtonsPopUpCardDataQueryResponse,
|};
*/


/*
query CardButtonsPopUpCardDataQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    jsonid
    additionalConfiguration {
      jsonProperties
      id
    }
    chartImage
    configuration {
      id
      author {
        id
        name
        profileImage {
          url
          id
        }
      }
      publishedStoryBoard {
        hasEditPermission
        sourceBoard {
          id
          name
          publishOutsidePaywall
        }
        id
      }
      citation
      endYear
      internalOrderList
      jsonStyleProperties
      organization {
        id
        organizationName
      }
      permaLink
      report {
        id
        jsonid
      }
      savedArea {
        id
        jsonid
        name
      }
      startYear
      storyLink
      storyPublishedDate
      storyUpdatedDate
      thumbnailImage {
        id
        url
      }
      subtitle
      title
      toolLink
    }
    cardPosition
    frontFacingComponent {
      componentType
      name
      id
    }
    subComponentType
    statistics {
      name
      jsonData
      id
    }
    isFavorite
    sharingStatus
    tags {
      edges {
        node {
          boardTag {
            name
            permaTag
            id
          }
          id
        }
      }
    }
    boardSection {
      board {
        id
        isCardFamily
        isPublishedStory
        publishOutsidePaywall
        name
        publicFeedStatus
        publishedStoryCard {
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonProperties",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartImage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasEditPermission",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishOutsidePaywall",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardNode",
  "kind": "LinkedField",
  "name": "sourceBoard",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "citation",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalOrderList",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationNode",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permaLink",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "WizardReportNode",
  "kind": "LinkedField",
  "name": "report",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "SavedAreaNode",
  "kind": "LinkedField",
  "name": "savedArea",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyLink",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyPublishedDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyUpdatedDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardImageNode",
  "kind": "LinkedField",
  "name": "thumbnailImage",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toolLink",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonData",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sharingStatus",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permaTag",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardNode",
  "kind": "LinkedField",
  "name": "board",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCardFamily",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublishedStory",
      "storageKey": null
    },
    (v9/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicFeedStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardCardUseNode",
      "kind": "LinkedField",
      "name": "publishedStoryCard",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CardButtonsPopUpCardDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardAdditionalConfigurationNode",
            "kind": "LinkedField",
            "name": "additionalConfiguration",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNode",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardImageNode",
                    "kind": "LinkedField",
                    "name": "profileImage",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "publishedStoryBoard",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v29/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardStatisticsNode",
            "kind": "LinkedField",
            "name": "statistics",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v30/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardTagUseNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTagUseNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardTagUseNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardTagNode",
                        "kind": "LinkedField",
                        "name": "boardTag",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v33/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNode",
            "kind": "LinkedField",
            "name": "boardSection",
            "plural": false,
            "selections": [
              (v34/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CardButtonsPopUpCardDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardAdditionalConfigurationNode",
            "kind": "LinkedField",
            "name": "additionalConfiguration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNode",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardImageNode",
                    "kind": "LinkedField",
                    "name": "profileImage",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "publishedStoryBoard",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v29/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardStatisticsNode",
            "kind": "LinkedField",
            "name": "statistics",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v30/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardTagUseNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTagUseNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardTagUseNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardTagNode",
                        "kind": "LinkedField",
                        "name": "boardTag",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v33/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNode",
            "kind": "LinkedField",
            "name": "boardSection",
            "plural": false,
            "selections": [
              (v34/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CardButtonsPopUpCardDataQuery",
    "operationKind": "query",
    "text": "query CardButtonsPopUpCardDataQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    jsonid\n    additionalConfiguration {\n      jsonProperties\n      id\n    }\n    chartImage\n    configuration {\n      id\n      author {\n        id\n        name\n        profileImage {\n          url\n          id\n        }\n      }\n      publishedStoryBoard {\n        hasEditPermission\n        sourceBoard {\n          id\n          name\n          publishOutsidePaywall\n        }\n        id\n      }\n      citation\n      endYear\n      internalOrderList\n      jsonStyleProperties\n      organization {\n        id\n        organizationName\n      }\n      permaLink\n      report {\n        id\n        jsonid\n      }\n      savedArea {\n        id\n        jsonid\n        name\n      }\n      startYear\n      storyLink\n      storyPublishedDate\n      storyUpdatedDate\n      thumbnailImage {\n        id\n        url\n      }\n      subtitle\n      title\n      toolLink\n    }\n    cardPosition\n    frontFacingComponent {\n      componentType\n      name\n      id\n    }\n    subComponentType\n    statistics {\n      name\n      jsonData\n      id\n    }\n    isFavorite\n    sharingStatus\n    tags {\n      edges {\n        node {\n          boardTag {\n            name\n            permaTag\n            id\n          }\n          id\n        }\n      }\n    }\n    boardSection {\n      board {\n        id\n        isCardFamily\n        isPublishedStory\n        publishOutsidePaywall\n        name\n        publicFeedStatus\n        publishedStoryCard {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5827d2fe090c252293322e487e57959';

module.exports = node;
