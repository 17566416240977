import React, { Component } from "react";
import * as d3 from "d3";
import Switch from "react-switch";
import "../../css/components/Card/D3HorizontalStackedBarChart.css";
import * as _ from "lodash";

class D3HorizontalStackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 5, right: 10, bottom: 10, left: 10 };
    this.clientWidth = 217;
    this.clientHeight = 185;
    this.toggleHeight = 20;
    this.leftLegend = 55;
    this.rightLegend = 30;
    this.bottomLegend = 30;
    this.duration = 1000;
    this.state = {
      ratio: 1,
      isToggle: false,
      sbData: { data: [] },
    };
  }

  handleChange = (checked, e) => {
    e.stopPropagation();
    this.setState({ isToggle: checked }, () => {
      this.makeData(this.props);
    });
  };

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
      this.makeData(nextProps);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
  };

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  makeData = (props) => {
    const { data } = props;
    const { isToggle } = this.state;
    if (isToggle) {
      this.setState(
        {
          sbData: data,
        },
        () => this.update()
      );
    } else {
      this.setState(
        {
          sbData: data,
        },
        () => this.update()
      );
    }
  };

  init = () => {
    this.x = d3.scaleLinear();
    this.y = d3.scaleBand();
    this.y2 = d3.scaleBand();
    this.createSvg();
    this.makeData(this.props);
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    /* const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
     selection.enter().append('svg').merge(selection).attr('class', 'D3HorizontalStackedBarChartSVG').attr('id', d => d);
     selection.exit().remove();*/
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg
      .selectAll("g.g-container")
      .data([svgId], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "gcontainer");

    const barG = this.svgG.selectAll("g.bar-container").data([svgId], (d) => d);
    barG
      .enter()
      .append("g")
      .merge(barG)
      .attr("class", "bar-container")
      .attr("id", (d) => d + "bar-container");
    barG.exit().remove();
    this.barG = this.svgG.select("g#" + svgId + "bar-container");

    const gAxisTitle = this.svgG
      .selectAll("g.axis-title")
      .data([svgId], (d) => d);
    gAxisTitle
      .enter()
      .append("g")
      .merge(gAxisTitle)
      .attr("class", "axis-title")
      .attr("id", (d) => d + "axis-title");
    gAxisTitle.exit().remove();
    this.axisTitleG = this.svgG.select("g#" + svgId + "axis-title");

    const gLine = this.svgG.selectAll("g.dashed-line").data([svgId], (d) => d);
    gLine
      .enter()
      .append("g")
      .merge(gLine)
      .attr("class", "dashed-line")
      .attr("id", (d) => d + "dashed-line");
    gLine.exit().remove();
    this.gLine = this.svgG.select("g#" + svgId + "dashed-line");

    const gAxisSelection = this.svgG
      .selectAll("g.axis-container")
      .data([svgId], (d) => d);
    gAxisSelection
      .enter()
      .append("g")
      .merge(gAxisSelection)
      .attr("class", "axis-container")
      .attr("id", (d) => d + "axis-container");
    gAxisSelection.exit().remove();
    this.axisG = this.svgG.select("g#" + svgId + "axis-container");

    const xAxis = this.axisG.selectAll(".x.axis").data([svgId], (d) => d);
    xAxis
      .enter()
      .append("g")
      .merge(xAxis)
      .attr("id", (d) => d + "xAxis")
      .attr("class", "x axis");
    xAxis.exit().remove();
    this.xAxis = this.axisG.select("g#" + svgId + "xAxis");

    const yAxis = this.axisG.selectAll(".y.axis").data([svgId], (d) => d);
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("id", (d) => d + "yAxis")
      .attr("class", "y axis");
    yAxis.exit().remove();
    this.yAxis = this.axisG.select("g#" + svgId + "yAxis");

    const y2Axis = this.axisG.selectAll(".y2.axis").data([svgId], (d) => d);
    y2Axis
      .enter()
      .append("g")
      .merge(y2Axis)
      .attr("id", (d) => d + "y2Axis")
      .attr("class", "y2 axis");
    y2Axis.exit().remove();
    this.y2Axis = this.axisG.select("g#" + svgId + "y2Axis");
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width =
      this.clientWidth -
      this.margin.left -
      this.margin.right -
      this.leftLegend -
      this.rightLegend;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.toggleHeight -
      this.bottomLegend;

    this.x.rangeRound([this.width, 0]);
    this.y.rangeRound([0, this.height]).padding(0.25);
    this.y2.rangeRound([0, this.height]).padding(0.25);
    if (isWide) {
      this.setState({
        ratio: (this.size / 205) * 0.65,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size - this.toggleHeight : "100%")
      .attr(
        "viewBox",
        `0 0 ${
          this.width +
          this.margin.left +
          this.margin.right +
          this.rightLegend +
          this.leftLegend
        } ${
          this.height + this.margin.top + this.margin.bottom + this.bottomLegend
        }`
      );

    this.svgG.attr(
      "transform",
      "translate(" +
        (this.margin.left + this.leftLegend) +
        "," +
        (this.margin.top + 5) +
        ")"
    );
    this.xAxis.attr("transform", "translate(0, " + this.height + ")");
    this.yAxis.attr("transform", "translate(0, 0)");
    this.y2Axis.attr("transform", "translate(" + this.width + ", 0)");
    this.axisTitleG.attr(
      "transform",
      "translate(" + (this.width + this.rightLegend) + ", 0)"
    );
  };

  update = () => {
    const { sbData, isToggle } = this.state;
    this.autoAlignSVG();
    if (sbData && sbData.data && sbData.data.length) {
      if (isToggle) {
        this.x.domain([
          sbData.data
            .map((d) => d.povertyTotal)
            .reduce((a, b) => {
              return a + b;
            }),
          0,
        ]);
      } else {
        this.x.domain([100, 0]);
      }
      this.y.domain(sbData.data.map((d) => d.label));
      this.y2.domain(
        sbData.data.map((d, i) =>
          isToggle ? d.povertyTotal : d.povertyPercent + "-" + i
        )
      );
      this.updateXAxis();
      this.updateYAxis();
      this.updateY2Axis();
      this.updateStackedBar();
      this.updateDashedLine();
      this.updateY2AxisTitle();
    }
  };

  updateXAxis = () => {
    const { isToggle } = this.state;
    if (!isToggle) {
      this.xAxis.call(
        d3
          .axisBottom(this.x)
          .ticks(3)
          .tickFormat((d) => d + "%")
      );
    } else {
      this.xAxis.call(
        d3
          .axisBottom(this.x)
          .ticks(3)
          .tickFormat((d) => (d !== 0 ? d3.format("0.2s")(d) : d))
      );
    }
  };

  updateYAxis = () => {
    this.yAxis
      .call(d3.axisLeft(this.y))
      .selectAll(".tick text") // select all the x tick texts
      .call(function (t) {
        t.each(function () {
          // for each one
          const self = d3.select(this);
          const s = self.text().split("-"); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "-9px")
            .attr("dy", (d, i) => {
              return i === 0 ? -(0.2 * (s.length - 1)) + "em" : "1em";
            })
            .text((d) => d);
          sSelection.exit().remove();
        });
      });
  };

  updateY2Axis = () => {
    const { isToggle } = this.state;
    if (isToggle) {
      this.y2Axis.call(
        d3
          .axisRight(this.y2)
          .ticks(20)
          .tickFormat((d) => {
            if (d <= 999 && d >= 0) {
              d = _.round(d);
              return d;
            } else if (d < 0) {
              d = _.round(d, 1);
              return d;
            } else {
              return d3.format("0.3s")(d);
            }
          })
      );
    } else {
      this.y2Axis.call(
        d3.axisRight(this.y2).tickFormat((d) => {
          let value = d.split("-")[0];
          if (value >= 0) {
            value = _.round(value);
          } else if (d < 0) {
            value = _.round(value, 1);
          }
          return value + "%";
        })
      );
    }
    // this.y2Axis.selectAll('text').style('text-anchor', 'end');
  };

  updateY2AxisTitle = () => {
    const { isToggle, sbData } = this.state;
    // const {isWide} = this.props;
    let titleData = [];
    if (Array.isArray(sbData.title)) {
      titleData.push(!isToggle ? sbData.title[0] : sbData.title[1]);
    } else {
      titleData.push(sbData.title);
    }
    const title = this.axisTitleG
      .selectAll("text.y2-title")
      .data(titleData, (d) => d);
    const newTitle = title.enter().append("text");
    newTitle
      .merge(title)
      .attr("class", "y2-title")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dx", "0.5em")
      .style("text-anchor", "end")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "12px")
      .text((d) => d);
    title.exit().remove();
    this.axisTitleG
      .selectAll("text.y2-title") // select all the x tick texts
      .call(function (t) {
        t.each(function () {
          // for each one
          const self = d3.select(this);
          const s = self.text().split("-"); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "-9px")
            .attr("dy", (d, i) => {
              return i === 0 ? -(0.3 * (s.length - 1)) + "em" : "1em";
            })
            .text((d) => d);
          sSelection.exit().remove();
        });
      });
  };

  updateStackedBar = () => {
    const { isToggle, sbData } = this.state;
    const stack = this.barG.selectAll("g.stack").data(
      [
        {
          type: "blank",
          color: "dark",
          data: sbData.data,
        },
        {
          type: isToggle ? "povertyTotal" : "povertyPercent",
          color: "light",
          data: sbData.data,
        },
      ],
      (d) => d.color
    );
    const newStack = stack.enter().append("g");
    newStack
      .merge(stack)
      .attr("class", "stack")
      .transition()
      .duration(this.duration)
      .style("fill", (d) =>
        d.type !== "blank" ? "#424B54" : !isToggle ? "#D3D3D3" : "transparent"
      );
    stack.exit().remove();
    const bar = newStack
      .merge(stack)
      .selectAll("rect.bar")
      .data(
        (d) => {
          return d.data.map((c) => ({ parent: d.type, data: c }));
        },
        (d) => d.data.label
      );
    const newBar = bar.enter().append("rect");
    newBar
      .merge(bar)
      .attr("class", "bar")
      .attr("y", (d) => this.y(d.data.label))
      .attr("height", this.y.bandwidth())
      .transition()
      .duration(this.duration)
      .attr("x", 0)
      .attr("width", (d) =>
        d.parent === "blank"
          ? this.x(this.x.domain()[0])
          : this.x(isToggle ? d.data.povertyTotal : d.data.povertyPercent)
      );
    bar.exit().remove();
  };
  updateDashedLine = () => {
    const { isToggle, sbData } = this.state;
    // const {isWide} = this.props;
    const line = this.gLine
      .selectAll("line.dash-line")
      .data(
        !isToggle && sbData.usValue ? [sbData.usValue, sbData.usValue] : [],
        (d, i) => d + "" + i
      );
    const newLine = line
      .enter()
      .append("line")
      .style("opacity", 0)
      .attr("x1", 0)
      .attr("x2", 0);
    newLine
      .merge(line)
      .attr("class", "dash-line")
      .style("stroke", (d, i) => (i === 1 ? "#FFFFFF" : "#424B54"))
      .style("stroke-width", "1px")
      .style("stroke-linecap", "butt")
      .style("stroke-dasharray", (d, i) => (i === 0 ? "" : "4,4"))
      .attr("y1", () => {
        const offset = this.y.paddingOuter() * this.y.bandwidth();
        return this.y(this.y.domain()[0]) - offset;
      })
      .attr("y2", () => {
        const offset = this.y.paddingOuter() * this.y.bandwidth();
        return (
          this.y(this.y.domain()[sbData.data.length - 1]) +
          this.y.bandwidth() +
          offset +
          this.bottomLegend -
          10
        );
      })
      .transition()
      .duration(this.duration)
      .attr("x1", (d) => this.x(d))
      .attr("x2", (d) => this.x(d))
      .style("opacity", 1);
    line
      .exit()
      .transition()
      .duration(this.duration)
      .style("opacity", 0)
      .remove();
    const lineText = this.gLine.selectAll("text.line-text").data(
      !isToggle && sbData.usValue
        ? [
            {
              value: sbData.usValue,
              text: "United States " + _.round(sbData.usValue) + "%",
            },
          ]
        : [],
      (d, i) => d.value + "" + i
    );
    const newLineText = lineText
      .enter()
      .append("text")
      .style("opacity", 0)
      .attr("x", 0);
    newLineText
      .merge(lineText)
      .attr("class", "line-text")
      .text((d) => d.text)
      .style("font-size", "10px")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("fill", "#424B54")
      .attr("dy", "1.6em")
      .attr("y", () => {
        const offset = this.y.paddingOuter() * this.y.bandwidth();
        return (
          this.y(this.y.domain()[sbData.data.length - 1]) +
          this.y.bandwidth() +
          offset +
          20
        );
      })
      .transition()
      .duration(this.duration)
      .attr("x", (d) => this.x(d.value))
      .style("opacity", 1);
    lineText
      .exit()
      .transition()
      .duration(this.duration)
      .style("opacity", 0)
      .remove();
  };

  render() {
    const { isToggle, ratio } = this.state;
    const { id } = this.props;
    const switchTitle = {
      fontSize: "11px",
      fontFamily: "ProximaNova-Regular",
      lineHeight: 0.9,
      display: "block",
      color: "#989898",
    };
    return (
      <div className="D3HorizontalStackedBarChart d-flex flex-column h-100 w-100">
        <div
          className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          ref={(ele) => (this.parentEle = ele)}
        >
          <svg id={id.replace(/=/g, "")} />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "6px",
              height: `${28}px`,
              lineHeight: "0.8",
              transform: `scale(${ratio})`,
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: this.toggleHeight + "px" }}
            >
              <div>
                <small style={switchTitle} className={"text-right"}>
                  Percent of Population
                </small>
              </div>
              <div className="px-2">
                <Switch
                  onChange={this.handleChange}
                  checked={isToggle}
                  className="react-switch"
                  id="togglePie"
                  height={18}
                  width={35}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#989898"
                  onHandleColor="#fff"
                />
              </div>
              <div>
                <small style={switchTitle} className={"text-left"}>
                  Total Number
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3HorizontalStackedBarChart;
