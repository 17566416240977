import React from 'react';
import { AnnouncementPopup } from 'common-components';
import '../../css/custom.css';

const StartMemberShipPopup = ({
  closePopUp,
  startMemberShip,
  isPausedAccount,
}) => {
  if(isPausedAccount) {
      return (
        <>
          <AnnouncementPopup closePopUp={closePopUp} onOkClick={closePopUp}
            announcementMessage="Looks like you have exceeded the capacity of the Free Trial."
            announcementPrompt="You can create new stories when your paused subscription resumes."
            closeButtonLabel="" okButtonLabel="OK" title="YOUR MEMBERSHIP IS PAUSED">
          </AnnouncementPopup>
        </>
      );
  } else {
      return (
        <>
          <AnnouncementPopup closePopUp={closePopUp} onOkClick={startMemberShip}
            announcementMessage="Looks like you have exceeded the capacity of the Free Trial."
            announcementPrompt="To continue creating new stories, please start your membership."
            closeButtonLabel="Not Yet" okButtonLabel="Start" title="START YOUR MEMBERSHIP">
          </AnnouncementPopup>
        </>
      );
  }
};

export default StartMemberShipPopup;
