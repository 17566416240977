import React, { Component } from 'react';
import DiamondVerticalBarChart from './DiamondVerticalBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class DiamondVerticalBarWideChart extends Component {

  render() {
    const { id, diamondVerticalBarChartData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3BarWideChart d-flex w-100 h-100 position-relative'>
        {(diamondVerticalBarChartData && diamondVerticalBarChartData.data) &&
        <DiamondVerticalBarChart
          id={id}
          key={id}
          diamondVerticalBarChartData={diamondVerticalBarChartData}
          isWide={true}
          {...this.props}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default DiamondVerticalBarWideChart;
