/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginUserInput = {|
  username: string,
  userPassword?: ?string,
  oauth2Token?: ?string,
  oauth2Source?: ?string,
  clientMutationId?: ?string,
|};
export type LoginPopUpMutationVariables = {|
  input: LoginUserInput
|};
export type LoginPopUpMutationResponse = {|
  +loginUser: ?{|
    +userAccountNode: ?{|
      +id: string
    |},
    +success: ?boolean,
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
    +clientMutationId: ?string,
  |}
|};
export type LoginPopUpMutation = {|
  variables: LoginPopUpMutationVariables,
  response: LoginPopUpMutationResponse,
|};
*/


/*
mutation LoginPopUpMutation(
  $input: LoginUserInput!
) {
  loginUser(input: $input) {
    userAccountNode {
      id
    }
    success
    validationErrors {
      key
      message
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "LoginUserInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginUserPayload",
    "kind": "LinkedField",
    "name": "loginUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNode",
        "kind": "LinkedField",
        "name": "userAccountNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "validationErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginPopUpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginPopUpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LoginPopUpMutation",
    "operationKind": "mutation",
    "text": "mutation LoginPopUpMutation(\n  $input: LoginUserInput!\n) {\n  loginUser(input: $input) {\n    userAccountNode {\n      id\n    }\n    success\n    validationErrors {\n      key\n      message\n    }\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9115357ea38e5b867294b543b7ecb71';

module.exports = node;
