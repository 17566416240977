/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdiMapCardContainerQueryVariables = {|
  input: string
|};
export type AdiMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +configuration: {|
      +savedArea: ?{|
        +counties: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fips5: string,
              +geojsonFeature: string,
            |}
          |}>
        |},
        +reportstatisticsSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +name: string,
              +areaDeprivationIndex: number,
              +blockGroups: {|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +fips5: string,
                    +fips12: string,
                    +geojsonWithProperties: string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type AdiMapCardContainerQuery = {|
  variables: AdiMapCardContainerQueryVariables,
  response: AdiMapCardContainerQueryResponse,
|};
*/


/*
query AdiMapCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    configuration {
      savedArea {
        counties {
          edges {
            node {
              id
              fips5
              geojsonFeature
            }
          }
        }
        reportstatisticsSet {
          edges {
            node {
              name
              areaDeprivationIndex
              blockGroups {
                edges {
                  node {
                    id
                    fips5
                    fips12
                    geojsonWithProperties
                  }
                }
              }
              id
            }
          }
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fips5",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "WizardCountyNodeConnection",
  "kind": "LinkedField",
  "name": "counties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WizardCountyNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WizardCountyNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geojsonFeature",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "areaDeprivationIndex",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ReportStatisticsBlockGroupNodeConnection",
  "kind": "LinkedField",
  "name": "blockGroups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportStatisticsBlockGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportStatisticsBlockGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fips12",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geojsonWithProperties",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdiMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportStatisticsNodeConnection",
                    "kind": "LinkedField",
                    "name": "reportstatisticsSet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportStatisticsNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportStatisticsNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdiMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportStatisticsNodeConnection",
                    "kind": "LinkedField",
                    "name": "reportstatisticsSet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportStatisticsNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportStatisticsNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AdiMapCardContainerQuery",
    "operationKind": "query",
    "text": "query AdiMapCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    configuration {\n      savedArea {\n        counties {\n          edges {\n            node {\n              id\n              fips5\n              geojsonFeature\n            }\n          }\n        }\n        reportstatisticsSet {\n          edges {\n            node {\n              name\n              areaDeprivationIndex\n              blockGroups {\n                edges {\n                  node {\n                    id\n                    fips5\n                    fips12\n                    geojsonWithProperties\n                  }\n                }\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c68b7c3a83ccfa2529942cf04de602bc';

module.exports = node;
