import React, { Component } from "react";
import "../../css/components/Card/CardBottomComponent.css";
import CardBottom from "../Card/CardBottom";
import CardCitation from "../Card/CardCitation";
import EditHashTagPopUp from "../CommonComponents/EditHashTagPopUp";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../RelayEnvironment";

//mutation  removeHashtagFromCard($input:RemoveHashtagFromCardInput!)  {
// removeHashtagFromCard(input:  $input)  {  boardTagNode  {  id            name  }  }  }

const mutation = graphql`
  mutation CardBottomComponentsMutation($input: RemoveHashtagFromCardInput!) {
    removeHashtagFromCard(input: $input) {
      boardTagNode {
        id
        name
      }
    }
  }
`;

class CardBottomComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.bottomData.boardCardUse.tags,
    };
  }

  handleAddingTags = (obj) => {
    const { tags } = this.state;
    const currentTags = tags.edges;
    const totalTags = currentTags.concat([obj]);
    this.setState({ tags: { edges: totalTags } });
  };

  handleRemoveTagsApi = (tagName) => {
    const { bottomData } = this.props;
    const variables = {
      input: {
        boardCardUseId: bottomData.boardCardUse.id,
        tagName: tagName,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        const { tags } = this.state;
        const tagsArray = tags.edges;
        const newTagsArray = [];
        tagsArray.map((item) => {
          if (item.node.boardTag.name !== tagName) {
            newTagsArray.push(item);
          }
          return newTagsArray;
        });
        this.setState({ tags: { edges: newTagsArray } });
      },
      onError: (err) => console.error(err),
    });
  };

  render() {
    const {
      bottomData,
      isEditHashTagPopUpVisible,
      y,
      hideBottomComponent,
      isWide,
      isTwitterCard,
    } = this.props;
    const { tags } = this.state;
    let citation = bottomData.boardCardUse.configuration.citation;
    if (
      isTwitterCard &&
      bottomData &&
      bottomData.boardCardUse &&
      bottomData.boardCardUse.configuration &&
      bottomData.boardCardUse.configuration.citation
    ) {
      citation =
        bottomData.boardCardUse.configuration.citation +
        " Made on broadstreet.io.";
    }
    return (
      <div className="cardBottomComponent">
        <CardCitation data={citation} isWide={isWide} />
        {/*<CardTagsContainer cardId={bottomData.boardCardUse.id} tags={tags}
                                   starTags={bottomData.boardCardUse.configuration.savedArea}
                                   handleRemoveTagsApi={this.handleRemoveTagsApi} {...this.props} />*/}
        {!hideBottomComponent && <CardBottom {...this.props} tags={tags} />}
        <EditHashTagPopUp
          isVisible={isEditHashTagPopUpVisible}
          x={100}
          y={y ? y : 250}
          selectedCardId={bottomData.boardCardUse.id}
          tags={tags}
          handleAddingTags={this.handleAddingTags}
          //handleEditHashTagPopUp={this}
          handleRemoveTagsApi={this.handleRemoveTagsApi}
        />
      </div>
    );
  }
}

export default CardBottomComponents;
