import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3MultipleStackBarChart from './D3MultipleStackBarChart';

class D3MultipleStackBarWideChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 23, bottom: 10, left: 10 };
    this.state = {
      legends: [{ label: 'Most Affluent', color: '#78A22F', range: [0, 20] },
        { label: '', color: '#6A8D73', range: [20, 40] },
        { label: 'Average', color: '#D5DED6', range: [40, 60] },
        { label: '', color: '#FFA987', range: [60, 80] },
        { label: 'Most Deprived', color: '#FF6B35', range: [80, 100] }
      ]
    };
  }

  render() {
    const { id, key, msbData, showTwitterCardPopup } = this.props;
    const { legends } = this.state;
    const middleContent = (
      <ul className="list-group mt-0 mt-md-5 legend">
        {legends.map((legend, index) => {
          return (
            <li key={index} className="list-group-item p-1 py-0" style={{ lineHeight: 0.5 }}>
              <div style={{ background: legend.color }}/>
              {legend.label}
            </li>
          );
        })}
      </ul>
    );
    const content = (
      <div className='D3MultiForceLayoutWideChart d-flex w-100 h-100'>
        {msbData &&
        <D3MultipleStackBarChart
          id={id}
          key={key}
          msbData={msbData}
          isWide={true}/>
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} middleContent={middleContent}
                            isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3MultipleStackBarWideChart;
