import React, { Component } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

class D3DualHorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = {top: 10, right: 23, bottom: 10, left: 10};
    this.spaceBetweenLegeds = 13;
    this.clientWidth = 245;
    this.clientHeight = 230;
    this.state = {
      ratio: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentDidMount() {
    this.init(this.props);
  }

  init = (props) => {
    const {dhbData, id} = props;
    if (dhbData && id) {
      d3.select(this.parentEle).selectAll('svg').filter((d) => {
        return d && d.id !== id;
      }).remove();
      this.createSvg(props);
      window.addEventListener('resize', this.onWindowResize);
      this.mounted = true;
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    const {isWide} = this.props;
    const {ratio} = this.state;
    if (!this.mounted) return;
    this.size = this.mainParentEle.clientHeight > this.mainParentEle.clientWidth ? this.mainParentEle.clientWidth : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 218) {
      this.setState({
        ratio: this.size / 218
      });
    }
  };

  createSvg = (props) => {
    const {dhbData} = props;
    const id = props.id.replace(/=/g, '');
    const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3DualHorizontalBarChart').attr('id', d => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select('svg#' + id);
    const gSelection = this.svg.selectAll('g.g-container').data((d) => [d], (d) => d);
    gSelection.enter().append('g').merge(gSelection).attr('class', 'g-container').attr('id', (d) => d + 'g-container');
    gSelection.exit().remove();
    this.svgG = d3.select('g#' + id + 'g-container');
    const gRectSelection = this.svgG.selectAll('g.rect-container').data((d) => [d], (d) => d);
    gRectSelection.enter().append('g').merge(gRectSelection).attr('class', 'rect-container').attr('id', (d) => d + 'rect-container');
    gRectSelection.exit().remove();
    this.svgRectG = d3.select('g#' + id + 'rect-container');
    const gLegendsSelection = this.svgG.selectAll('g.legends-container').data((d) => [d], (d) => d);
    gLegendsSelection.enter().append('g').merge(gLegendsSelection).attr('class', 'legends-container').attr('id', (d) => d + 'legends-container');
    gLegendsSelection.exit().remove();
    this.svgLegendG = d3.select('g#' + id + 'legends-container');
    const gLabels = this.svgG.selectAll('g.labels-container').data((d) => [d], (d) => d);
    gLabels.enter().append('g').merge(gLabels).attr('class', 'labels-container').attr('id', (d) => d + 'labels-container');
    gLabels.exit().remove();

    const gAxisSelection = this.svgG.selectAll('g.axis-container').data((d) => [d], (d) => d);
    gAxisSelection.enter().append('g').merge(gAxisSelection).attr('class', 'axis-container').attr('id', (d) => d + 'axis-container');
    gAxisSelection.exit().remove();
    this.axisG = d3.select('g#' + id + 'axis-container');

    this.svgLabelsG = d3.select('g#' + id + 'labels-container');
    this.y = d3.scaleBand().domain(dhbData.data.map((d) => d.label)).paddingInner(0.5).paddingOuter(0);
    const maxValue = d3.max(dhbData.barTypes.map((d) => d3.max(dhbData.data.map((c) => c[d.barType]))));
    const mv = maxValue ? maxValue : 0;
    this.x = d3.scaleLinear().domain([0, mv]);
    this.xLabel = d3.scaleLinear().range([0, mv]);
    this.yBarType = d3.scaleBand()
      .domain(dhbData.barTypes.map((d) => d.barType))
      .paddingInner(0.4).paddingOuter(0);
    this.autoAlignSVG(mv, props);
    this.addXAxis(props);
    this.createRect(props);
    this.updateLegends(props);
    this.createLabels(props);
  };
  autoAlignSVG = (maxValue, props) => {
    const {dhbData, isWide} = props;
    const {ratio} = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.labelHeight = 50;
    this.height = this.clientHeight < (dhbData.data.length * 35) ?
      (dhbData.data.length * 35) :
      this.clientHeight
      - this.margin.top - this.margin.bottom - this.labelHeight;
    this.labelWidth = 100;

    this.size = this.mainParentEle.clientHeight > this.mainParentEle.clientWidth ? this.mainParentEle.clientWidth : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 230) {
      this.setState({
        ratio: this.size / 230
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg.attr('width', (this.width + this.margin.left + this.margin.right))
      .attr('height', (this.height + this.margin.top + this.margin.bottom + this.labelHeight));
    this.svgG
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.svgLegendG
      .attr('transform', 'translate(0,' + (this.height + this.margin.top + 20) + ')');
    this.y.range([0, this.height]);
    this.yBarType.range([0, this.y.bandwidth()]);
    this.svgRectG.attr('transform', 'translate(' + this.labelWidth + ',0)');
    this.svgLabelsG.attr('transform', 'translate(0,0)');
    this.x.range([0, this.width - this.labelWidth]);
    this.xLabel.domain([0, this.width - this.labelWidth]);
    const newMaxValue = this.xLabel((maxValue.toString().length * 8) + 5);
    this.x.domain([0, (maxValue + newMaxValue)]).nice(2);
    this.axisG.attr('transform', 'translate(' + this.labelWidth + ',' + this.y.bandwidth() + ')');
  };

  addXAxis = (props) => {
    const {xTitle} = props;
    this.axisG.select('.axis--x').remove();
    this.axisG.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.height})`)
      .call(d3.axisBottom(this.x).ticks(2))
      .append('text')
      .attr('x', 0)
      .attr('transform', `translate(${(this.width - this.labelWidth) / 2},25)`)
      .attr('fill', '#000')
      .text(xTitle)
      .style('font-size', '9px')
      .style('text-anchor', 'middle')
      .attr('dy', '4px')
      .style('fill', '#424B54');
  };

  createLabels(props) {
    //const self = this;
    const rightSidePadding = 5;
    const lineG = this.svgLabelsG.selectAll('g.labels-g').data(props.dhbData.data, (d) => d.label);
    const newLineG = lineG.enter()
      .append('g');
    newLineG.merge(lineG)
      .attr('class', 'labels-g')
      .attr('transform', (d) => {
        return 'translate(0,' + (this.y(d.label) + this.y.bandwidth() / 2) + ')';
      });
    lineG.exit().remove();
    const foreignObjectLabel = newLineG.merge(lineG).selectAll('foreignObject.foreignObjectLabel').data(d => [d], (d) => d.label);
    const newForeignObjectLabel = foreignObjectLabel.enter()
      .append('foreignObject');

    newForeignObjectLabel.merge(foreignObjectLabel)
      .attr('width', this.labelWidth - rightSidePadding)
      .attr('height', this.y.bandwidth() * 2)
      .attr('x', '0')
      .attr('y', '-4')
      .attr('class', 'foreignObjectLabel');

    foreignObjectLabel.exit().remove();
    const textLabel = newForeignObjectLabel.merge(foreignObjectLabel).selectAll('div.label').data(d => [d], (d) => d.label);
    textLabel.enter()
      .append('xhtml:div')
      .merge(textLabel)
      .text(d => d.label)
      .attr('class', 'label')
      .style('font-size', '11px')
      .style('color', '#424B54')
      .style('font-family', 'ProximaNova-Regular')
      .style('padding', '0')
      .style('max-width', ((this.labelWidth - rightSidePadding) - 10) + 'px')
      .style('line-height', '1em')
      .style('text-align', 'right')
      .style('width', ((this.labelWidth - rightSidePadding) - 10) + 'px')
      .each(function(d) {
        // const label = d3.select(this)
        //const labelH = label && label.node() && label.node().getBoundingClientRect() &&
        // label.node().getBoundingClientRect().height

        //if (labelH > (self.y.bandwidth() * 2)) {
        //    label.text(d.label.replace(/\W*\s(\S)*$/, ''))
        //}

      });
    textLabel.exit().remove();
  }

  createRect(props) {
    const rectG = this.svgRectG.selectAll('g.rect-g').data(props.dhbData.data, (d) => d.label);
    const newRectG = rectG.enter()
      .append('g');
    newRectG.merge(rectG)
      .attr('class', 'rect-g')
      .attr('transform', (d) => {
        return 'translate(0,' + (this.y(d.label) + this.y.bandwidth() / 2) + ')';
      });
    const rectCountG = newRectG.merge(rectG)
      .selectAll('g.rect-count-g')
      .data(d => {
        const data = [];
        props.dhbData.barTypes.forEach((c) => {
          const obj = {};
          obj.data = d;
          obj.barObj = c;
          data.push(obj);
        });
        return data;
      }, (d) => d.barType + d.data.label);
    const newRectCountG = rectCountG.enter().append('g');
    newRectCountG.merge(rectCountG)
      .attr('class', 'rect-count-g')
      .attr('transform', d => 'translate(0,' + this.yBarType(d.barObj.barType) + ')');
    rectCountG.exit().remove();
    rectG.exit().remove();
    const rect = newRectCountG.merge(rectCountG)
      .selectAll('rect.rect')
      .data(d => [d], (d) => (d.barType + d.data.label));
    rect.enter()
      .append('rect')
      .merge(rect)
      .attr('class', (d) => 'rect ' + (d.barObj && d.barObj.barType))
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', (this.yBarType.bandwidth() / 2))
      .attr('ry', (this.yBarType.bandwidth() / 2))
      .attr('width', (d) => {
        return d.barObj && this.x(d.data[d.barObj.barType]);
      })
      .attr('height', this.yBarType.bandwidth())
      .style('fill', d => d.barObj && d.barObj.color ? d.barObj.color : '#424B54');

    rect.exit().remove();
    const count = newRectCountG.merge(rectCountG)
      .selectAll('text.count')
      .data(d => d.barObj && props.dhbData.barTypes[0].barType === d.barObj.barType ? [d] : [], (d) => (d.barType + d.data.label));
    count.enter()
      .append('text')
      .merge(count)
      .attr('class', (d) => 'count ' + (d.barObj && d.barObj.barType))
      .attr('x', (d) => {
        return d.barObj && this.x(d.data[d.barObj.barType]) + 5;
      })
      .attr('y', 0)
      .attr('dy', '8px')
      .text(d => d.barObj && ((d.data[d.barObj.barType] < 1 && d.data[d.barObj.barType] > -1) ? _.round(d.data[d.barObj.barType], 1) : _.round(d.data[d.barObj.barType])))
      .style('fill', '#424B54')
      .style('font-size', '12px')
      .style('text-anchor', 'start')
      .style('font-family', 'ProximaNova-Bold');

    rect.exit().remove();
  }

  updateLegends = (props) => {
    const {dhbData: {barTypes}} = props;
    // legends G enter, update, exit
    const rectHeight = 5;
    const rectWidth = 14;
    const legendG = this.svgLegendG.selectAll('g.legend').data(barTypes, (d) => d && d.barTypes);
    const newLegendG = legendG.enter().append('g');
    newLegendG.merge(legendG).attr('class', 'legend')
      .attr('transform', (d, i) => `translate(${this.margin.left},${this.spaceBetweenLegeds * i})`);
    legendG.exit().remove();
    // legends rect enter, update, exit
    const legendRect = newLegendG.merge(legendG).selectAll('rect').data((d) => [d], (d) => d && d.barTypes);
    const newRect = legendRect.enter().append('rect');
    newRect.merge(legendRect)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', rectWidth)
      .attr('height', rectHeight)
      .attr('rx', (rectHeight / 2))
      .attr('ry', (rectWidth / 2))
      .attr('transform', `translate(0,0)`)
      .style('fill', (d) => d.color);
    legendRect.exit().remove();
    // legends text enter, update, exit
    const legendText = newLegendG.merge(legendG).selectAll('text').data((d) => [d], (d) => d && d.barTypes);
    const newText = legendText.enter().append('text');
    newText.merge(legendText)
      .attr('x', (rectWidth + 5))
      .attr('y', 0)
      .style('font-size', '9px')
      .attr('text-anchor', 'start')
      .attr('dy', '0.71em')
      .style('fill', '#424B54')
      .style('font-family', 'ProximaNova-Regular')
      .text((d) => d.title);
    legendText.exit().remove();
  };

  render() {
    const {titleText} = this.props;
    const {ratio} = this.state;
    return (
      <div
        className='D3DualHorizontalBarChart h-100 d-flex flex-grow-1 flex-column justify-content-center align-items-center'
        ref={(ele) => {
          this.mainParentEle = ele;
        }}>
        <div className='d-flex flex-column'
             style={{height: '230px', width: '247px', transform: `scale(${ratio})`}}>
          <div className='title-text text-center px-3'>{titleText}</div>
          <div className='flex-grow-1 card-scrollbar' ref={(ele) => {
            this.parentEle = ele;
          }}>
          </div>
        </div>
      </div>
    );
  }
}

export default D3DualHorizontalBarChart;
