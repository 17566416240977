import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchQuery, commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import UpdatePaymentPopup from "./UpdatePaymentPopup";
import RemoveCardPopup from "./RemoveCardPopup";
import EditPromoCodePopup from "./EditPromoCodePopup";
import CreditCardImages from "./CreditCardImages";

const Billing = () => {
  const [showEditPromoCodePopup, setShowEditPromoCodePopup] = useState(false);
  const [showUpdatePaymentPopup, setShowUpdatePaymentPopup] = useState(false);
  const [showRemoveCardPopup, setShowRemoveCardPopup] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [nextInvoice, setNextInvoice] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [creditAmount, setCreditAmount] = useState(0);

  const [successNextInvoiceMsg, setSuccessNextInvoiceMsg] = useState("");
  const [errorNextInvoiceMsg, setErrorNextInvoiceMsg] = useState("");
  const [applyingNextInvoice, setApplyingNextInvoice] = useState(false);

  const [activePromoCode, setActivePromoCode] = useState("");

  useEffect(() => {
    refreshPage();
  }, []);

  const editPromoCode = () => {
    setShowEditPromoCodePopup(true);
  };

  const refreshPage = () => {
    const _query = graphql`
      query BillingPageQuery {
        currentUser {
          edges {
            node {
              username
              stripeCustomerId
              subscriptions
              userSubscriptionLevel
              userSubscriptionTerm
              userSubscriptionExpiration
              creditAmount
              activeCouponCode {
                subscriptionCouponCode
              }
              activePromoCode
              activePromoCodeMessage
              pauseSubscriptionStartDate
              pauseSubscriptionEndDate
              pausedUserSubscriptionLevel
              nextInvoice {
                id
                currentPeriodEndFormatted
                object
                plan {
                  id
                  amount
                  currency
                  interval
                  object
                }
                status
                total
              }
              defaultPaymentMethod {
                id
                object
                type
                card {
                  brand
                  country
                  last4
                  expMonth
                  expYear
                }
              }
              groupAccounts {
                edges {
                  node {
                    name
                    billingdocumentSet {
                      edges {
                        node {
                          id
                          documentType
                          invoiceNumber
                          summary
                          documentDate
                          documentUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    fetchQuery(environment, _query)
      .then((resp) => {
        if (resp.currentUser && resp.currentUser.edges[0]) {
          if (resp.currentUser.edges[0].node.groupAccounts) {
            setCurrentUser(resp.currentUser.edges[0].node);
            setBillingHistory(
              resp.currentUser.edges[0].node.groupAccounts.edges[0].node.billingdocumentSet.edges.map(
                (it) => it.node
              )
            );
          } else {
            setBillingHistory([]);
          }
          if (resp.currentUser.edges[0].node.defaultPaymentMethod) {
            setPaymentMethod(
              resp.currentUser.edges[0].node.defaultPaymentMethod
            );
          } else {
            setPaymentMethod(null);
          }
          if (resp.currentUser.edges[0].node.nextInvoice) {
            setNextInvoice(resp.currentUser.edges[0].node.nextInvoice);
          } else {
            setNextInvoice(null);
          }
          if (resp.currentUser.edges[0].node.activePromoCode) {
            setActivePromoCode(resp.currentUser.edges[0].node.activePromoCode);
          } else {
            setActivePromoCode("");
          }

          setCreditAmount(
            resp.currentUser.edges[0].node
              ? resp.currentUser.edges[0].node.creditAmount || 0
              : 0
          );
        }
      })
      .catch((err) => {
        setBillingHistory([]);
      });
  };

  const updatePayment = (e) => {
    e.preventDefault();
    setShowUpdatePaymentPopup(true);
  };
  const applyNextInvoice = () => {
    const mutation = graphql`
      mutation BillingPageApplyNextInvoiceMutation(
        $input: ApplyCreditToNextInvoiceInput!
      ) {
        applyCreditToNextInvoice(input: $input) {
          resultMessage
          success
        }
      }
    `;
    const variables = {
      input: {
        clientMutationId: null,
      },
    };

    setSuccessNextInvoiceMsg("");
    setErrorNextInvoiceMsg("");
    setApplyingNextInvoice(true);

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.applyCreditToNextInvoice.success) {
          setSuccessNextInvoiceMsg(
            response.applyCreditToNextInvoice.resultMessage
          );
        } else {
          setErrorNextInvoiceMsg(
            response.applyCreditToNextInvoice.resultMessage
          );
        }
        refreshPage();
        setApplyingNextInvoice(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorNextInvoiceMsg(err.errors[0].message);
        setApplyingNextInvoice(false);
      },
    });
  };
  // amex, diners, discover, jcb, mastercard, unionpay, visa, or unknown
  var cardImage = CreditCardImages.getDefaultImage();
  if (paymentMethod != null) {
    cardImage = CreditCardImages.getImage(paymentMethod.card.brand);
  }
  return (
    <>
      <div className="tab-pane fade active show" id="accordion-2">
        {/* <div className="text-left mb-5">
          <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
        <div className="ibox-head mb-5">
          <h3 className="font-weight-bold ">Billing</h3>
        </div>
        <div className="text-left mb-5">
          <h5 className="mb-4">Payment</h5>
          {paymentMethod ? (
            <div className="">
              <img src={cardImage} alt="" style={{ height: "50px" }} />
              <div className="my-3">
                **** {paymentMethod.card.last4} {paymentMethod.card.expMonth}/
                {paymentMethod.card.expYear}
              </div>
              <div className="my-1">
                <Link to="#" onClick={updatePayment}>
                  Update payment details
                </Link>
              </div>
              <div className="my-1">
                <Link
                  to="#"
                  onClick={() => {
                    setShowRemoveCardPopup(true);
                  }}
                >
                  Remove card
                </Link>
              </div>
            </div>
          ) : (
            <Link to="#" onClick={updatePayment}>
              Add payment details
            </Link>
          )}
        </div>
        <div className="text-left mb-5">
          <h5 className="mb-3">Membership Renewal</h5>
          {currentUser && currentUser.userSubscriptionLevel === 0 && (
            <div className="mb-4">
              You are on a Free Trial subscription with no expiration.
              {currentUser.pauseSubscriptionStartDate &&
                currentUser.pausedUserSubscriptionLevel === 1 && (
                  <span>
                    {" "}
                    (paused on{" "}
                    {new Date(
                      currentUser.pauseSubscriptionStartDate
                    ).toDateString()}
                    )
                  </span>
                )}
            </div>
          )}
          {nextInvoice && currentUser.userSubscriptionLevel !== 0 && (
            <div className="mb-4">
              Your <b>{currentUser.subscriptionTerm}</b> membership will
              automatically renew on {nextInvoice.currentPeriodEndFormatted} for
              ${Number(nextInvoice.total / 100.0).toLocaleString("en-US")}
            </div>
          )}
          {nextInvoice == null &&
            currentUser &&
            currentUser.userSubscriptionLevel !== 0 && (
              <div className="mb-4">
                Your <b>sponsored {currentUser.subscriptionTerm}</b> membership
                will not automatically renew. It will expire on{" "}
                {new Date(
                  currentUser.userSubscriptionExpiration
                ).toDateString()}
                .
              </div>
            )}
          <div className="mb-5">
            <div className="mb-3 d-flex align-items-end">
              <div className="mr-3">
                <div className="text-bold">
                  You have ${Number(creditAmount).toLocaleString("en-US")} in
                  credit
                </div>
                <div>Want to apply it to your next invoice?</div>
              </div>
              <button
                className="button button-subtle"
                onClick={applyNextInvoice}
                disabled={
                  applyingNextInvoice || !nextInvoice || creditAmount === 0
                }
              >
                Apply
              </button>
            </div>
            {errorNextInvoiceMsg && (
              <div className="alert alert-danger mb-3 rounded" role="alert">
                {errorNextInvoiceMsg}
              </div>
            )}
            {successNextInvoiceMsg && (
              <div className="alert alert-success mb-3 rounded" role="alert">
                {successNextInvoiceMsg}
              </div>
            )}
          </div>
          <div className="mb-5">
            <div className="mb-3 d-flex">
              <div className="mr-3">
                <div className="text-bold">Promo Code</div>
                {currentUser &&
                (currentUser.userSubscriptionTerm === "monthly" ||
                  currentUser.userSubscriptionLevel === 0) ? (
                  <div className="d-flex align-items-end">
                    <div className="mr-4">
                      Monthly and free memberships do not qualify for promotions
                      and discounts.
                    </div>
                    <button className="button button-subtle" disabled>
                      Edit
                    </button>
                  </div>
                ) : activePromoCode ? (
                  <div className="d-flex align-items-end">
                    <div className="mr-4">
                      Congrats! Your next annual membership invoice will be
                      discounted using the promo code: <b>{activePromoCode}</b>
                    </div>
                    <button
                      className="button button-subtle"
                      onClick={editPromoCode}
                    >
                      Edit
                    </button>
                  </div>
                ) : currentUser &&
                  currentUser.userSubscriptionTerm === "yearly" &&
                  currentUser.userSubscriptionLevel === 1 ? (
                  <div className="d-flex align-items-end">
                    <div className="mr-4">
                      Click "Edit" to apply a promo code to your next annual
                      membership invoice.
                    </div>
                    <button
                      className="button button-subtle"
                      onClick={editPromoCode}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-end">
                    <div className="mr-4">
                      This membership does not qualify for promotions and
                      discounts.
                    </div>
                    <button className="button button-subtle" disabled>
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
            {errorNextInvoiceMsg && (
              <div className="alert alert-danger mb-3 rounded" role="alert">
                {errorNextInvoiceMsg}
              </div>
            )}
            {successNextInvoiceMsg && (
              <div className="alert alert-success mb-3 rounded" role="alert">
                {successNextInvoiceMsg}
              </div>
            )}
          </div>
        </div>
        <div className="text-left mb-5">
          <h5 className="mb-3">Billing History</h5>
          <div className="descriptions">
            {billingHistory && billingHistory.length > 0 ? (
              billingHistory
                .filter((item) => {
                  return !(
                    item.summary && item.summary.startsWith("Opening invoice")
                  );
                })
                .map((item, index) => (
                  <div className="mb-3" key={index}>
                    {/* <div className="text-success font-weight-bold mr-3 text-capitalize">{item.documentType}</div> */}
                    {/* <div className="text-success font-weight-bold mr-3">${item.price}</div> */}
                    <div>{item.summary}</div>
                    {(item.documentType === "invoice" ||
                      item.documentType === "purchase") &&
                      item.invoiceNumber !== "" && (
                        <Link to={"/invoice/" + item.id} target="_blank">
                          View invoice {item.invoiceNumber}
                        </Link>
                      )}
                    <div className="text-muted">
                      {new Date(item.documentDate).toLocaleString()}
                    </div>
                  </div>
                ))
            ) : (
              <div>No any transactions</div>
            )}
          </div>
        </div>
      </div>
      {showUpdatePaymentPopup && (
        <UpdatePaymentPopup
          closePopUp={() => {
            setShowUpdatePaymentPopup(false);
            refreshPage();
          }}
        />
      )}
      {showEditPromoCodePopup && (
        <EditPromoCodePopup
          oldPromoCode={activePromoCode}
          closePopUp={() => {
            setShowEditPromoCodePopup(false);
            refreshPage();
          }}
        />
      )}
      {showRemoveCardPopup && (
        <RemoveCardPopup
          closePopUp={() => {
            setShowRemoveCardPopup(false);
            refreshPage();
          }}
        />
      )}
    </>
  );
};

export default Billing;
