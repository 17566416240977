import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/components/Card/ChartCardContainer.css";
import withCardContainer from "../Card/CardContainer";
import D3AreaAndLineChart from "./D3AreaAndLineChart";
import D3GridProgressChart from "./D3GridProgressChart";
import D3DotGridProgressChart from "./D3DotGridProgressChart";
import D3PieChart from "./D3PieChart";
import D3RingChart from "./D3RingChart";
import TextLayoutType1 from "./TextLayoutType1";
import manGrid from "../../images/man-grid-white.svg";
import infantBottle from "../../images/infant-bottle.svg";
import hatGrid from "../../images/icon-grid-hat-white.svg";
import dotGrid from "../../images/dot-grid-white.svg";
import gaugeImg from "../../images/gauge-green-red.svg";
import gaugeNeedleImg from "../../images/gauge-needle.svg";
import thermometer from "../../images/thermometer-blank.svg";
import D3LineDotChart from "./D3LineDotChart";
import D3LineChart from "./D3LineChart";
import D3ThermometerChart from "./D3ThermometerChart";
import D3HorizontalBarChart from "./D3HorizontalBarChart";
import D3PopulationBlockChart from "./D3PopulationBlockChart";
import D3DisparitiesDeprivationChart from "./D3DisparitiesDeprivationChart";
import D3AreaDeprivationScore from "./D3AreaDeprivationScore";
import D3DualHorizontalBarChart from "./D3DualHorizontalBarChart";
import D3PositiveNegativeHorizontalBarChart from "./D3PositiveNegativeHorizontalBarChart";
import TextLayoutType2 from "./TextLayoutType2";
import D3MultipleStackBarChart from "./D3MultipleStackBarChart";
import D3PollutionChart from "./D3PollutionChart";
import D3GaugeChart from "./D3GaugeChart";
import D3HeartChart from "./D3HeartChart";
import D3HeartChart2 from "./D3HeartChart2";
import D3HeartChart3 from "./D3HeartChart3";
import D3HeartChart4 from "./D3HeartChart4";
import D3MultipleHorizontalBarChart from "./D3MultipleHorizontalBarChart";
import D3SimpleGaugeChart from "./D3SimpleGaugeChart";
import D3ClickableGaugeChart from "./D3ClickableGaugeChart";
import DownloadDataCard from "./DownloadDataCard";
import D3NumberCard from "./D3NumberCard";
import D3CheckboxCard from "./D3CheckboxCard";
import D3ProgressBarChart from "./D3ProgressBarChart";
import D3StackedBarChart from "./D3StackedBarChart";
import D3DonutProgressChart from "./D3DonutProgressChart";
import D3PictureProgressChart from "./D3PictureProgressChart";
import D3TextLayoutMotherChart from "./D3TextLayoutMotherChart";
import D3TextLayoutBabyChart from "./D3TextLayoutBabyChart";
import D3HorizontalStackedBarChart from "./D3HorizontalStackedBarChart";
import D3MultiForceLayout from "./D3MultiForceLayoutChart";
import D3SaladChart from "./D3SaladChart";
import PeopleChart from "./PeopleChart";
import saladSvg from "../../images/salad.svg";
import SvgIconCard from "./SvgIconCard";
import acesSvg from "../../images/report-aces.svg";
import violenceSvg from '../../images/violencefeatured.png';
import suiprevSvg from '../../images/suiprevfeatured.png';
import appleSvg from "../../images/apple.svg";
import demoSvg from "../../images/report-star.svg";
import houseSvg from "../../images/house.svg";
import mortSvg from "../../images/report-mort.svg";
import stiSvg from '../../images/stifeatured.png';
import infantMortSvg from "../../images/infant-featured.png";
import covidSvg from "../../images/covid19featured4.png";
import toolSvg from "../../images/tool.svg";
import makeMapSvg from "../../images/make-map.svg";
import mapRoom from "../../images/map-room.svg";
import browseBlueSvg from "../../images/browse-blue.svg";
import * as _ from "lodash";
import cloud from "../../images/comment-black-oval-bubble-shape.svg";
import D3WordCloudChart from "./D3WordCloudChart";
import DiamondVerticalBarChart from "./DiamondVerticalBarChart";
import D3BarChart from "./D3BarChart";
import D3MultipleStackHorizontalBarChart from "./D3MultipleStackHorizontalBarChart";
import LunchChart from "./LunchChartCard";
import { apiBaseUrl, reportToolCardArray } from "../../utility/utilities";
import { ButtonComponent } from "common-components";

//import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';

class ChartCardContainer extends Component {
  constructor(props) {
    super(props);
    //this.componentRef = React.createRef();
    this.state = {
      areaLineData: { data: [], pathTypes: [] },
      pieData: { data: [], pieTypes: [] },
      verticalBarData: { columns: [] },
      lineData: {
        data: [],
        pathTypes: [],
        xAxisLabel: "",
        yAxisLabel: "",
        name: "",
      },
      progressiveLineData: { data: [], pathTypes: [] },
      pollutionData: { legends: [], data: [] },
      mgpData: {},
      dsgcData: {},
      hgpData: {},
      dgpData: {},
      lineDot: {},
      horizontalBar: {},
      infantHorizontalBar: {},
      medianHorizontalBar: {},
      thermoData: { data: [], barTypes: [], imageHeight: 0, imageWidth: 0 },
      populationBlock: { data: [], blockTypes: [] },
      dhbData: { data: [], barTypes: [] },
      textLayoutType1: {},
      textLayoutType2: {},
      medianFamilyIncome: {},
      medianHomeVal: {},
      medianGrossRent: {},
      medianMonthlyMortgage: {},
      under9YearsEducation: {},
      highSchoolGraduation: {},
      peopleBelow150Poverty: {},
      homesMotorVehicle: {},
      homesWithoutTel: {},
      hwcp: {},
      homeOwnershipRate: {},
      homesWithCrowding: {},
      singleParentsWithChild: {},
      whiteCollarEmp: {},
      unemploymentRate: {},
      familiesLivingBelowPoverty: {},
      areaDeprivationScore: {},
      pnhbData: { data: [], barTypes: [] },
      msbData: [],
      familyADI: [],
      childFIMFLData: { nodes: [] },
      peopleFIMFLData: { nodes: [] },
      diabetesRateData: {},
      obesityRateData: {},
      progressBarChartData: {},
      numberChildrenCard: {},
      checkboxCard: {},
      numberSeniorsCard: {},
      hispanicPopulationData: {},
      percentBlackPopulationData: {},
      povertyByRaceCard: {},
      saladChartFEI: {},
      simpleGaugeChartData: { data: [], pieTypes: [] },
      clickableGaugeChartData: { data: [], pieTypes: [] },
      wordCloudChartData: {},
      dualVerticalBarChartData: { data: [], barTypes: [] },
      diamondVerticalBarChartData: { data: [], barTypes: [] },
      multipleSectionBarChartData: {
        data: [],
        pieTypes: [],
        mouseoverColor: "",
      },
      lunchChartData: { data: [] },
      svgFillData: {},
      svgTextLayoutType2Data: {},
      svgTextLayoutType1Data: {},
      heartChart1Data: {},
      heartChart2Data: {},
      heartChart3Data: {},
      heartChart4Data: {},
      multipleHorizontalBarChartData: {},
      svgRingChartData: {},
      peopleChartData: {},
    };
    this.charts = React.createRef();
  }

  handleCardVisibility = (e) => {
    const { subComponentType, data, handleWideCardVisible, isEditingEnabled } =
      this.props;
    if (handleWideCardVisible === undefined) {
      return;
    }
    if (
      isEditingEnabled !== true &&
      [
        "diamondVerticalBarChart",
        "dualVerticalBarChart",
        "verticalBarChart",
        "horizontalBar",
        "lineChart",
        "povertyByRaceCard",
        "pollutionChart",
        "hispanicPopulationSBChart",
        "populationBlock",
        "percentBlackPopulationCard",
        "donutChart",
        "areaDeprivationScore",
        "wordCloud",
        "hatGridProgress",
        "manGridProgress",
        "dotGridProgress",
        "donutSvgGaugeChart",
        "thermometerStackProgress",
        "saladChartFEI",
        "areaLineChart",
        "textLayoutType1",
        "textLayoutType2",
        "checkboxCard",
        "numberChildrenCard",
        "numberChildrenUnder5Card",
        "lineDot",
        "numberSeniorsCard",
        "lunchChart",
        "peopleFIMultiForceLayout",
        "childFIMultiForceLayout",
        "diabetesRateChart",
        "obesityRateChart",
        "progressBarChart",
        "simpleGaugeChart",
        "clickableGaugeChart",
        "multipleSectionBarChart",
        "highSchoolGraduation",
        "homesWithoutCompletePlumbing",
        "medianMonthlyMortgage",
        "medianGrossRent",
        "dualHorizontalBarChart",
        "under9YearsEducation",
        "whiteCollarEmp",
        "peopleBelow150Poverty",
        "medianFamilyIncome",
        "unemploymentRate",
        "positiveNegativeHorizontalBar",
        "homesWithCrowding",
        "singleParentsWithChild",
        "familiesLivingBelowPoverty",
        "homesWithoutTel",
        "homeOwnershipRate",
        "medianHomeVal",
        "homesMotorVehicle",
        "multipleStackBar",
        "downloadDataFamilyADI",
        "svgFillChart",
        "svgTextLayoutType2",
        "svgTextLayoutType1",
        "heartChart1",
        "heartChart2",
        "heartChart3",
        "heartChart4",
        "multipleHorizontalBarChart",
        "numberCard",
        "svgRingChart",
        "peopleChart1",
      ].indexOf(subComponentType) > -1
    ) {
      /*
          ['multipleStackBar', 'familiesLivingBelowPoverty'].indexOf(subComponentType) > -1) {
      */
      handleWideCardVisible(data.boardCardUse.id);
    }
  };

  setStatistics = (props) => {
    const { subComponentType, data } = props;
    const statistics = data.boardCardUse.statistics;
    if (statistics) {
      switch (subComponentType) {
        case "areaLineChart":
          this.setState((prevState) => ({
            areaLineData: JSON.parse(statistics.jsonData),
            ...prevState.areaLineData,
          }));
          break;
        case "dualHorizontalBarChart":
          this.setState((prevState) => ({
            dhbData: JSON.parse(statistics.jsonData),
            ...prevState.dhbData,
          }));
          break;
        case "donutChart":
          this.setState((prevState) => ({
            pieData: JSON.parse(statistics.jsonData),
            ...prevState.pieData,
          }));
          break;
        case "lineChart":
          const lineData = JSON.parse(statistics.jsonData);
          lineData.name = statistics.name;
          this.setState((prevState) => ({
            lineData: lineData,
            ...prevState.lineData,
          }));
          break;
        case "positiveNegativeHorizontalBar":
          const pnhbData = JSON.parse(statistics.jsonData);
          pnhbData.data && pnhbData.data.unshift({ label: "", value: 0 });
          this.setState((prevState) => ({
            pnhbData: pnhbData,
            ...prevState.pnhbData,
          }));
          break;
        case "manGridProgress":
          const manGridProgress = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            mgpData: manGridProgress,
            ...prevState.mgpData,
          }));
          break;
        case "donutSvgGaugeChart":
          const donutSvgGaugeChart = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            dsgcData: donutSvgGaugeChart,
            ...prevState.dsgcData,
          }));
          break;
        case "heartChart1":
          const heartChart1Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            heartChart1Data: heartChart1Data,
            ...prevState.heartChart1Data,
          }));
          break;
        case "heartChart2":
          const heartChart2Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            heartChart2Data: heartChart2Data,
            ...prevState.heartChart2Data,
          }));
          break;
        case "heartChart3":
          const heartChart3Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            heartChart3Data: heartChart3Data,
            ...prevState.heartChart3Data,
          }));
          break;
        case "heartChart4":
          const heartChart4Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            heartChart4Data: heartChart4Data,
            ...prevState.heartChart4Data,
          }));
          break;
        case "multipleHorizontalBarChart":
          const multipleHorizontalBarChartData = JSON.parse(
            statistics.jsonData
          );
          this.setState((prevState) => ({
            multipleHorizontalBarChartData: multipleHorizontalBarChartData,
            ...prevState.multipleHorizontalBarChartData,
          }));
          break;
        case "svgRingChart":
          const svgRingChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            svgRingChartData: svgRingChartData,
            ...prevState.svgRingChartData,
          }));
          break;
        case "svgFillChart":
          const svgFillChart = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            svgFillData: svgFillChart,
            ...prevState.svgFillData,
          }));
          break;
        case "svgTextLayoutType2":
          const svgTextLayoutType2Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            svgTextLayoutType2Data: svgTextLayoutType2Data,
            ...prevState.svgTextLayoutType2Data,
          }));
          break;
        case "svgTextLayoutType1":
          const svgTextLayoutType1Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            svgTextLayoutType1Data: svgTextLayoutType1Data,
            ...prevState.svgTextLayoutType1Data,
          }));
          break;
        case "hatGridProgress":
          const hgpData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            hgpData: hgpData,
            ...prevState.hgpData,
          }));
          break;
        case "dotGridProgress":
          const dgpData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            dgpData: dgpData,
            ...prevState.dgpData,
          }));
          break;
        case "populationBlock":
          const populationBlock = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            populationBlock: populationBlock,
            ...prevState.populationBlock,
          }));
          break;
        case "horizontalBar":
          const sd = JSON.parse(statistics.jsonData);
          if (
            data &&
            data.boardCardUse &&
            data.boardCardUse.configuration &&
            data.boardCardUse.configuration.title === "Median Family Income"
          ) {
            this.setState((prevState) => ({
              medianHorizontalBar: sd,
              ...prevState.medianHorizontalBar,
            }));
          } else if (
            data &&
            data.boardCardUse &&
            data.boardCardUse.configuration &&
            data.boardCardUse.configuration.title === "Infant Deaths"
          ) {
            this.setState((prevState) => ({
              infantHorizontalBar: sd,
              ...prevState.infantHorizontalBar,
            }));
          } else {
            this.setState((prevState) => ({
              horizontalBar: sd,
              ...prevState.horizontalBar,
            }));
          }
          break;
        case "lineDot":
          const lineDot = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            lineDot: lineDot,
            ...prevState.lineDot,
          }));
          break;
        case "textLayoutType1":
          const textLayoutType1 = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            textLayoutType1: textLayoutType1,
            ...prevState.textLayoutType1,
          }));
          break;
        case "textLayoutType2":
          const textLayoutType2 = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            textLayoutType2: textLayoutType2,
            ...prevState.textLayoutType2,
          }));
          break;
        case "thermometerStackProgress":
          const thermoData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            thermoData,
            ...prevState.thermoData,
          }));
          break;
        case "verticalBarChart":
          const verticalBarData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            verticalBarData,
            ...prevState.verticalBarData,
          }));
          break;
        case "areaDeprivationScore":
          const areaDeprivationScore = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            areaDeprivationScore,
            ...prevState.areaDeprivationScore,
          }));
          break;
        case "multipleStackBar":
          const msbData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({ msbData, ...prevState.msbData }));
          break;
        case "pollutionChart":
          const pollutionData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            pollutionData,
            ...prevState.pollutionData,
          }));
          break;
        case "medianFamilyIncome":
          const medianFamilyIncome = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            medianFamilyIncome,
            ...prevState.medianFamilyIncome,
          }));
          break;
        case "medianHomeVal":
          const medianHomeVal = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            medianHomeVal,
            ...prevState.medianHomeVal,
          }));
          break;
        case "medianGrossRent":
          const medianGrossRent = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            medianGrossRent,
            ...prevState.medianGrossRent,
          }));
          break;
        case "medianMonthlyMortgage":
          const medianMonthlyMortgage = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            medianMonthlyMortgage,
            ...prevState.medianMonthlyMortgage,
          }));
          break;
        case "under9YearsEducation":
          const under9YearsEducation = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            under9YearsEducation,
            ...prevState.under9YearsEducation,
          }));
          break;
        case "highSchoolGraduation":
          const highSchoolGraduation = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            highSchoolGraduation,
            ...prevState.highSchoolGraduation,
          }));
          break;
        case "peopleBelow150Poverty":
          const peopleBelow150Poverty = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            peopleBelow150Poverty,
            ...prevState.peopleBelow150Poverty,
          }));
          break;
        case "homesMotorVehicle":
          const homesMotorVehicle = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            homesMotorVehicle,
            ...prevState.homesMotorVehicle,
          }));
          break;
        case "homesWithoutTel":
          const homesWithoutTel = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            homesWithoutTel,
            ...prevState.homesWithoutTel,
          }));
          break;
        case "homesWithoutCompletePlumbing":
          const hwcp = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({ hwcp, ...prevState.hwcp }));
          break;
        case "homeOwnershipRate":
          const homeOwnershipRate = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            homeOwnershipRate,
            ...prevState.homeOwnershipRate,
          }));
          break;
        case "homesWithCrowding":
          const homesWithCrowding = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            homesWithCrowding,
            ...prevState.homesWithCrowding,
          }));
          break;
        case "singleParentsWithChild":
          const singleParentsWithChild = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            singleParentsWithChild,
            ...prevState.singleParentsWithChild,
          }));
          break;
        case "whiteCollarEmp":
          const whiteCollarEmp = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            whiteCollarEmp,
            ...prevState.whiteCollarEmp,
          }));
          break;
        case "unemploymentRate":
          const unemploymentRate = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            unemploymentRate,
            ...prevState.unemploymentRate,
          }));
          break;
        case "familiesLivingBelowPoverty":
          const familiesLivingBelowPoverty = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            familiesLivingBelowPoverty,
            ...prevState.familiesLivingBelowPoverty,
          }));
          break;
        case "saladChartFEI":
          const saladChartFEI = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            saladChartFEI,
            ...prevState.saladChartFEI,
          }));
          break;
        case "childFIMultiForceLayout":
          const childFIMFLData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            childFIMFLData,
            ...prevState.childFIMFLData,
          }));
          break;
        case "peopleFIMultiForceLayout":
          const peopleFIMFLData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            peopleFIMFLData,
            ...prevState.peopleFIMFLData,
          }));
          break;
        case "diabetesRateChart":
          const diabetesRateData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            diabetesRateData,
            ...prevState.diabetesRateData,
          }));
          break;
        case "progressBarChart":
          const progressBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            progressBarChartData,
            ...prevState.progressBarChartData,
          }));
          break;
        case "obesityRateChart":
          const obesityRateData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            obesityRateData,
            ...prevState.obesityRateData,
          }));
          break;
        case "numberChildrenCard":
        case "numberCard":
        // fallthrough
        case "numberChildrenUnder5Card":
          const numberChildrenCard = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            numberChildrenCard,
            ...prevState.numberChildrenCard,
          }));
          break;
        case "checkboxCard":
          const checkboxCard = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            checkboxCard,
            ...prevState.checkboxCard,
          }));
          break;
        case "numberSeniorsCard":
          const numberSeniorsCard = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            numberSeniorsCard,
            ...prevState.numberSeniorsCard,
          }));
          break;
        case "povertyByRaceCard":
          const jsonData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            ...{ povertyByRaceCard: jsonData },
            ...prevState.povertyByRaceCard,
          }));
          break;
        case "hispanicPopulationSBChart":
          const hispanicPopulationData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            hispanicPopulationData,
            ...prevState.hispanicPopulationData,
          }));
          break;
        case "percentBlackPopulationCard":
          const percentBlackPopulationData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            percentBlackPopulationData,
            ...prevState.percentBlackPopulationData,
          }));
          break;
        case "simpleGaugeChart":
          const simpleGaugeChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            simpleGaugeChartData,
            ...prevState.simpleGaugeChartData,
          }));
          break;
        case "clickableGaugeChart":
          const clickableGaugeChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            clickableGaugeChartData,
            ...prevState.clickableGaugeChartData,
          }));
          break;
        case "wordCloud":
          const wordCloudChartData = JSON.parse(statistics.jsonData);
          wordCloudChartData["chartImage"] = data.boardCardUse.chartImage;
          this.setState((prevState) => ({
            wordCloudChartData,
            ...prevState.wordCloudChartData,
          }));
          break;
        case "diamondVerticalBarChart":
          const diamondVerticalBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            diamondVerticalBarChartData,
            ...prevState.diamondVerticalBarChartData,
}));
          break;
        case "dualVerticalBarChart":
          const dualVerticalBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            dualVerticalBarChartData,
            ...prevState.dualVerticalBarChartData,
          }));
          break;
        case "multipleSectionBarChart":
          const multipleSectionBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            multipleSectionBarChartData,
            ...prevState.multipleSectionBarChartData,
          }));
          break;
        case "lunchChart":
          const lunchChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            lunchChartData,
            ...prevState.lunchChartData,
          }));
          break;
        case "peopleChart1":
          const peopleChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({
            peopleChartData,
            ...prevState.peopleChartData,
          }));
          break;
        default:
          console.log(subComponentType, JSON.parse(statistics.jsonData));
      }
    }
  };

  onCardClick = (path, popupType) => {
    const { userData, handleLoginPopUp, history } = this.props;
    if (popupType === "map-room") {
      window.open(apiBaseUrl + `map/`);
    } else {
      if (
        userData.currentUser.edges.length === 0 &&
        popupType !== "browseindicators"
      ) {
        handleLoginPopUp();
      } else {
        const state = {
          closeURL: window.location.pathname,
          popupType,
        };
        history.push(path, state);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subComponentType, data } = this.props;
    if (
      !_.isEqual(nextProps.subComponentType, subComponentType) ||
      !_.isEqual(nextProps.data, data)
    ) {
      this.setStatistics(nextProps);
    }
  }

  componentDidMount() {
    this.setStatistics(this.props);
  }

  getSubComponent = (data) => {
    // Each component will take 100% height and 100% width of its parent..
    const { subComponentType, isEditingEnabled } = this.props;

    const {
      areaLineData,
      textLayoutType1,
      textLayoutType2,
      areaDeprivationScore,
      mgpData,
      hgpData,
      dgpData,
      thermoData,
      dsgcData,
      populationBlock,
      pollutionData,
      infantHorizontalBar,
      medianHorizontalBar,
      horizontalBar,
      pnhbData,
      dhbData,
      verticalBarData,
      lineData,
      lineDot,
      pieData,
      msbData,
      medianFamilyIncome,
      under9YearsEducation,
      highSchoolGraduation,
      peopleBelow150Poverty,
      familiesLivingBelowPoverty,
      unemploymentRate,
      whiteCollarEmp,
      singleParentsWithChild,
      homesMotorVehicle,
      homesWithoutTel,
      homesWithCrowding,
      medianHomeVal,
      medianGrossRent,
      medianMonthlyMortgage,
      homeOwnershipRate,
      hwcp,
      hispanicPopulationData,
      percentBlackPopulationData,
      povertyByRaceCard,
      saladChartFEI,
      childFIMFLData,
      peopleFIMFLData,
      diabetesRateData,
      obesityRateData,
      progressBarChartData,
      numberChildrenCard,
      checkboxCard,
      numberSeniorsCard,
      simpleGaugeChartData,
      clickableGaugeChartData,
      wordCloudChartData,
      diamondVerticalBarChartData,
      dualVerticalBarChartData,
      multipleSectionBarChartData,
      lunchChartData,
      svgFillData,
      svgTextLayoutType2Data,
      svgTextLayoutType1Data,
      heartChart1Data,
      heartChart2Data,
      heartChart3Data,
      heartChart4Data,
      multipleHorizontalBarChartData,
      svgRingChartData,
      peopleChartData,
    } = this.state;

    switch (subComponentType) {
      case "areaLineChart": {
        return (
          <D3AreaAndLineChart
            key={data.id + "areaLineChart"}
            id={data.id}
            areaLineData={areaLineData}
          />
        );
      }
      case "textLayoutType1": {
        return (
          <TextLayoutType1
            id={data.id}
            key={data.id + "textLayoutType1"}
            mainTitle={textLayoutType1.mainTitle}
            mainSubTitle={textLayoutType1.mainSubTitle}
            title1={textLayoutType1.title1}
            title2={textLayoutType1.title2}
          />
        );
      }
      case "textLayoutType2": {
        return (
          <TextLayoutType2
            id={data.id}
            mainTitle={textLayoutType2.mainTitle}
            mainSubTitle={textLayoutType2.mainSubTitle}
            title1={textLayoutType2.title1}
            title={textLayoutType2.title}
          />
        );
      }
      case "areaDeprivationScore": {
        return (
          <D3AreaDeprivationScore
            id={data.id}
            key={data.id + "D3AreaDeprivationScore"}
            value={areaDeprivationScore.value}
            mainTitle={areaDeprivationScore.mainTitle}
            mainSubTitle={areaDeprivationScore.mainSubTitle}
          />
        );
      }
      case "manGridProgress": {
        return (
          <D3GridProgressChart
            id={data.id}
            key={data.id + "manGridProgress"}
            image={manGrid}
            imageHeight={72}
            imageWidth={200}
            data={mgpData}
          />
        );
      }
      case "hatGridProgress": {
        return (
          <D3GridProgressChart
            id={data.id}
            key={data.id + "hatGridProgress"}
            image={hatGrid}
            imageHeight={72}
            imageWidth={200}
            data={hgpData}
          />
        );
      }
      case "dotGridProgress": {
        return (
          <D3DotGridProgressChart
            id={data.id}
            key={data.id + "dotGridProgress"}
            image={dotGrid}
            imageHeight={72}
            imageWidth={200}
            data={dgpData}
          />
        );
}
      case "childFIMultiForceLayout": {
        return (
          <D3MultiForceLayout
            id={data.id}
            key={data.id + "childFIMultiForceLayout"}
            nodes={childFIMFLData.nodes}
            title={childFIMFLData.title}
            switchLabel1={childFIMFLData.switchLabel1}
            switchLabel2={childFIMFLData.switchLabel2}
          />
        );
      }
      case "peopleFIMultiForceLayout": {
        return (
          <D3MultiForceLayout
            id={data.id}
            key={data.id + "peopleFIMultiForceLayout"}
            nodes={peopleFIMFLData.nodes}
            title={peopleFIMFLData.title}
            switchLabel1={peopleFIMFLData.switchLabel1}
            switchLabel2={peopleFIMFLData.switchLabel2}
          />
        );
      }
      case "donutSvgGaugeChart": {
        if (Object.keys(dsgcData).length) {
          let svgImage;
          try {
            svgImage = require(`../../images/${dsgcData.svg1}`);
          } catch (e) {
            console.error(e);
          }
          return (
            <D3DonutProgressChart
              id={data.id}
              key={data.id + "donutSvgGaugeChart"}
              percent={dsgcData.value ? dsgcData.value : 0.1}
              text={dsgcData.mainText}
              image={svgImage || infantBottle}
            />
          );
        } else {
          return null;
        }
      }
      case "svgFillChart": {
        if (Object.keys(svgFillData).length) {
          let type;
          if (svgFillData.svg1 === "piggy-bank.svg") {
            type = "PIGGY_BANK";
          }
          return (
            <D3PictureProgressChart
              id={data.id}
              key={data.id + "D3PictureProgressChart"}
              percent={svgFillData.value ? svgFillData.value : 0.1}
              text={svgFillData.mainText}
              type={type}
              chartData={svgFillData}
            />
          );
        } else {
          return null;
        }
      }
      case "svgTextLayoutType2": {
        if (Object.keys(svgTextLayoutType2Data).length) {
          return (
            <D3TextLayoutMotherChart
              id={data.id}
              key={data.id + "D3TextLayoutMotherChart"}
              chartData={svgTextLayoutType2Data}
            />
          );
        } else {
          return null;
        }
      }
      case "svgTextLayoutType1": {
        if (Object.keys(svgTextLayoutType1Data).length) {
          return (
            <D3TextLayoutBabyChart
              id={data.id}
              key={data.id + "D3TextLayoutBabyChart"}
              chartData={svgTextLayoutType1Data}
            />
          );
        } else {
          return null;
        }
      }
      case "heartChart1": {
        if (Object.keys(heartChart1Data).length) {
          return (
            <D3HeartChart
              id={data.id}
              key={data.id + "D3HeartChart1"}
              chartData={heartChart1Data}
            />
          );
        } else {
          return null;
        }
      }
      case "heartChart2": {
        if (Object.keys(heartChart2Data).length) {
          return (
            <D3HeartChart2
              id={data.id}
              key={data.id + "D3HeartChart2"}
              chartData={heartChart2Data}
            />
          );
        } else {
          return null;
        }
      }
      case "heartChart3": {
        if (Object.keys(heartChart3Data).length) {
          return (
            <D3HeartChart3
              id={data.id}
              key={data.id + "D3HeartChart3"}
              chartData={heartChart3Data}
            />
          );
        } else {
          return null;
        }
      }
      case "heartChart4": {
        if (Object.keys(heartChart4Data).length) {
          return (
            <D3HeartChart4
              id={data.id}
              key={data.id + "D3HeartChart4"}
              chartData={heartChart4Data}
            />
          );
        } else {
          return null;
        }
      }
      case "multipleHorizontalBarChart": {
        if (multipleHorizontalBarChartData.data) {
          return (
            <D3MultipleHorizontalBarChart
              id={data.id}
              key={data.id + "D3MultipleHorizontalBarChart"}
              chartData={multipleHorizontalBarChartData}
            />
          );
        }
        break;
      }
      case "svgRingChart": {
        if (Object.keys(svgRingChartData).length) {
          return (
            <D3RingChart
              id={data.id}
              key={data.id + "D3RingChart"}
              ring_min1={svgRingChartData.value}
              ring_text1={svgRingChartData.label}
              ring_min2={svgRingChartData.value2}
              ring_text2={svgRingChartData.label2}
              chartData={svgRingChartData}
            />
          );
        } else {
          return null;
        }
      }
      case "saladChartFEI": {
        return (
          <D3SaladChart
            id={data.id}
            key={data.id + "saladChartFEI"}
            saladSrc={saladSvg}
            value={saladChartFEI.value}
            valueLabel={saladChartFEI.valueLabel}
            min={-2}
            max={12.5}
          />
        );
      }
      case "medianFamilyIncome": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "medianFamilyIncome"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={medianFamilyIncome.min}
            max={medianFamilyIncome.max}
            value={medianFamilyIncome.value}
            valueText={medianFamilyIncome.valueText}
            text={medianFamilyIncome.text}
            unit={medianFamilyIncome.unit}
          />
        );
      }
      case "under9YearsEducation": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "under9YearsEducation"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={under9YearsEducation.min}
            max={under9YearsEducation.max}
            value={under9YearsEducation.value}
            valueText={under9YearsEducation.valueText}
            text={under9YearsEducation.text}
            unit={under9YearsEducation.unit}
          />
        );
      }
      case "highSchoolGraduation": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "highSchoolGraduation"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={highSchoolGraduation.min}
            max={highSchoolGraduation.max}
            value={highSchoolGraduation.value}
            valueText={highSchoolGraduation.valueText}
            text={highSchoolGraduation.text}
            unit={highSchoolGraduation.unit}
          />
        );
      }
      case "peopleBelow150Poverty": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "peopleBelow150Poverty"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={peopleBelow150Poverty.min}
            max={peopleBelow150Poverty.max}
            value={peopleBelow150Poverty.value}
            valueText={peopleBelow150Poverty.valueText}
            text={peopleBelow150Poverty.text}
            unit={peopleBelow150Poverty.unit}
          />
        );
      }
      case "familiesLivingBelowPoverty": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "familiesLivingBelowPoverty"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={familiesLivingBelowPoverty.min}
            max={familiesLivingBelowPoverty.max}
            value={familiesLivingBelowPoverty.value}
            valueText={familiesLivingBelowPoverty.valueText}
            text={familiesLivingBelowPoverty.text}
            unit={familiesLivingBelowPoverty.unit}
          />
        );
      }
      case "unemploymentRate": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "unemploymentRate"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={unemploymentRate.min}
            max={unemploymentRate.max}
            value={unemploymentRate.value}
            valueText={unemploymentRate.valueText}
            text={unemploymentRate.text}
            unit={unemploymentRate.unit}
          />
        );
      }
      case "whiteCollarEmp": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "whiteCollarEmp"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={whiteCollarEmp.min}
            max={whiteCollarEmp.max}
            value={whiteCollarEmp.value}
            valueText={whiteCollarEmp.valueText}
            text={whiteCollarEmp.text}
            unit={whiteCollarEmp.unit}
          />
        );
      }
      case "singleParentsWithChild": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "singleParentsWithChild"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={singleParentsWithChild.min}
            max={singleParentsWithChild.max}
            value={singleParentsWithChild.value}
            valueText={singleParentsWithChild.valueText}
            text={singleParentsWithChild.text}
            unit={singleParentsWithChild.unit}
          />
        );
      }
      case "homesMotorVehicle": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "homesMotorVehicle"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={homesMotorVehicle.min}
            max={homesMotorVehicle.max}
            value={homesMotorVehicle.value}
            valueText={homesMotorVehicle.valueText}
            text={homesMotorVehicle.text}
            unit={homesMotorVehicle.unit}
          />
        );
      }
      case "homesWithoutTel": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "homesWithoutTel"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={homesWithoutTel.min}
            max={homesWithoutTel.max}
            value={homesWithoutTel.value}
            valueText={homesWithoutTel.valueText}
            text={homesWithoutTel.text}
            unit={homesWithoutTel.unit}
          />
        );
      }
      case "homesWithCrowding": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "homesWithCrowding"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={homesWithCrowding.min}
            max={homesWithCrowding.max}
            value={homesWithCrowding.value}
            valueText={homesWithCrowding.valueText}
            text={homesWithCrowding.text}
            unit={homesWithCrowding.unit}
          />
        );
      }
      case "medianHomeVal": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "medianHomeVal"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={medianHomeVal.min}
            max={medianHomeVal.max}
            value={medianHomeVal.value}
            valueText={medianHomeVal.valueText}
            text={medianHomeVal.text}
            unit={medianHomeVal.unit}
          />
        );
      }
      case "medianGrossRent": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "medianGrossRent"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={medianGrossRent.min}
            max={medianGrossRent.max}
            value={medianGrossRent.value}
            valueText={medianGrossRent.valueText}
            text={medianGrossRent.text}
            unit={medianGrossRent.unit}
          />
        );
      }
      case "medianMonthlyMortgage": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "medianMonthlyMortgage"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={medianMonthlyMortgage.min}
            max={medianMonthlyMortgage.max}
            value={medianMonthlyMortgage.value}
            valueText={medianMonthlyMortgage.valueText}
            text={medianMonthlyMortgage.text}
            unit={medianMonthlyMortgage.unit}
          />
        );
      }
      case "homeOwnershipRate": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "homeOwnershipRate"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={homeOwnershipRate.min}
            max={homeOwnershipRate.max}
            value={homeOwnershipRate.value}
            valueText={homeOwnershipRate.valueText}
            text={homeOwnershipRate.text}
            unit={homeOwnershipRate.unit}
          />
        );
      }
      case "homesWithoutCompletePlumbing": {
        return (
          <D3GaugeChart
            id={data.id}
            key={data.id + "homesWithoutCompletePlumbing"}
            gauge={gaugeImg}
            needle={gaugeNeedleImg}
            min={hwcp.min}
            max={hwcp.max}
            value={hwcp.value}
            valueText={hwcp.valueText}
            text={hwcp.text}
            unit={hwcp.unit}
          />
        );
      }
      case "thermometerStackProgress": {
        return (
          <D3ThermometerChart
            id={data.id}
            key={data.id + "thermometerStackProgress"}
            image={thermometer}
            imageHeight={thermoData.imageHeight}
            imageWidth={thermoData.imageWidth}
            thermoData={thermoData}
          />
        );
      }
      case "populationBlock": {
        return (
          <D3PopulationBlockChart
            id={data.id}
            key={data.id + "populationBlock"}
            blockData={populationBlock}
          />
        );
      }
      case "pollutionChart": {
        return (
          <D3PollutionChart
            id={data.id}
            key={data.id + "pollutionChart"}
            pollutionData={pollutionData}
          />
        );
      }
      case "horizontalBar": {
        if (data.configuration.title === "Median Family Income") {
          return (
            <D3HorizontalBarChart
              id={data.id}
              maxValue={medianHorizontalBar.maxValue}
              mainText={medianHorizontalBar.mainText}
              subText={medianHorizontalBar.subText}
              minValue={medianHorizontalBar.minValue}
              horizontalBarData={medianHorizontalBar.horizontalBarData}
              refData={medianHorizontalBar.refData}
              noCommunityBar={medianHorizontalBar.noCommunityBar}
              key={data.id + "horizontalBar"}
            />
          );
        } else if (data.configuration.title === "Infant Deaths") {
          return (
            <D3HorizontalBarChart
              id={data.id}
              maxValue={infantHorizontalBar.maxValue}
              mainText={infantHorizontalBar.mainText}
              subText={infantHorizontalBar.subText}
              minValue={infantHorizontalBar.minValue}
              horizontalBarData={infantHorizontalBar.horizontalBarData}
              refData={infantHorizontalBar.refData}
              noCommunityBar={infantHorizontalBar.noCommunityBar}
              key={data.id + "horizontalBar"}
            />
          );
        } else {
          return (
            <D3HorizontalBarChart
              id={data.id}
              maxValue={horizontalBar.maxValue}
              mainText={horizontalBar.mainText}
              subText={horizontalBar.subText}
              minValue={horizontalBar.minValue}
              horizontalBarData={horizontalBar.horizontalBarData}
              refData={horizontalBar.refData}
              noCommunityBar={horizontalBar.noCommunityBar}
              key={data.id + "horizontalBar"}
            />
          );
        }
      }
      case "positiveNegativeHorizontalBar": {
        return (
          <D3PositiveNegativeHorizontalBarChart
            id={data.id}
            key={data.id + "positiveNegativeHorizontalBar"}
            mainText={"U.S. Benchmark"}
            barTitle={"Higher than national rates"}
            xTitle={"DIFFERENCE IN DEATHS PER 100k PEOPLE"}
            pnhbData={pnhbData}
            min={0}
            max={0}
          />
        );
      }
      case "dualHorizontalBarChart": {
        return (
          <D3DualHorizontalBarChart
            id={data.id}
            key={data.id + "dualHorizontalBarChart"}
            xTitle={"DEATHS PER 100k PEOPLE"}
            dhbData={dhbData}
          />
        );
      }
      case "verticalBarChart": {
        return (
          <D3DisparitiesDeprivationChart
            id={data.id}
            key={data.id + "verticalBarChart"}
            vbData={verticalBarData}
          />
        );
      }
      case "lineChart": {
        return (
          <D3LineChart
            id={data.id}
            key={data.id + "lineChart"}
            lineData={lineData}
          />
        );
      }
      case "lineDot": {
        return (
          <D3LineDotChart
            id={data.id}
            key={data.id + "lineDot"}
            maxValue={lineDot.maxValue}
            minValue={lineDot.minValue}
            lineDotData={lineDot.data}
            lineColor={lineDot.lineColor}
            dotColor={lineDot.dotColor}
            dotRadius={lineDot.dotRadius}
            lineWidth={lineDot.lineWidth}
            titleText={lineDot.titleText}
          />
        );
      }
      case "donutChart": {
        return (
          <D3PieChart
            id={data.id}
            key={data.id + "donutChart"}
            thickness={25}
            pieData={pieData}
          />
        );
      }
      case "pieChart": {
        return (
          <D3PieChart
            id={data.id}
            key={data.id + "pieChart"}
            pieData={pieData}
          />
        );
      }
      case "numberChildrenCard":
      case "numberChildrenUnder5Card":
      case "numberCard": {
        return (
          <D3NumberCard
            id={data.id}
            key={data.id + "numberChildrenCard"}
            title={numberChildrenCard.title}
            tooltip={numberChildrenCard.tooltip}
            subTittle={numberChildrenCard.subTitle}
            description={numberChildrenCard.description}
            min={numberChildrenCard.min}
            max={numberChildrenCard.max}
            value={numberChildrenCard.value}
            usTitle={numberChildrenCard.usTitle}
            usValue={numberChildrenCard.usValue}
          />
        );
      }
      case "checkboxCard": {
        return (
          <D3CheckboxCard
            id={data.id}
            key={data.id + "checkboxCard"}
            title={checkboxCard.title}
            tooltip={checkboxCard.tooltip}
            subTittle={checkboxCard.subTitle}
            description={checkboxCard.description}
            min={checkboxCard.min}
            max={checkboxCard.max}
            value={checkboxCard.value}
            usTitle={checkboxCard.usTitle}
            usValue={checkboxCard.usValue}
          />
        );
      }
      case "numberSeniorsCard": {
        return (
          <D3NumberCard
            id={data.id}
            key={data.id + "numberSeniorsCard"}
            title={numberSeniorsCard.title}
            tooltip={numberSeniorsCard.tooltip}
            subTittle={numberSeniorsCard.subTitle}
            description={numberSeniorsCard.description}
            min={numberSeniorsCard.min}
            max={numberSeniorsCard.max}
            value={numberSeniorsCard.value}
            usTitle={numberSeniorsCard.usTitle}
            usValue={numberSeniorsCard.usValue}
          />
        );
      }
      case "obesityRateChart": {
        return (
          <D3ProgressBarChart
            id={data.id}
            key={data.id + "obesityRateChart"}
            data={obesityRateData.horizontalBarData}
            subText={obesityRateData.subText}
            min={obesityRateData.minValue}
            max={obesityRateData.maxValue}
            refData={obesityRateData.refData}
          />
        );
      }
      case "diabetesRateChart": {
        return (
          <D3ProgressBarChart
            id={data.id}
            key={data.id + "diabetesRateChart"}
            data={diabetesRateData.horizontalBarData}
            subText={diabetesRateData.subText}
            min={diabetesRateData.minValue}
            max={diabetesRateData.maxValue}
            refData={diabetesRateData.refData}
          />
        );
      }
      case "progressBarChart": {
        return (
          <D3ProgressBarChart
            id={data.id}
            key={data.id + "progressBarChart"}
            data={progressBarChartData.horizontalBarData}
            subText={progressBarChartData.subText}
            min={progressBarChartData.minValue}
            max={progressBarChartData.maxValue}
            refData={progressBarChartData.refData}
          />
        );
      }
      case "hispanicPopulationSBChart": {
        return (
          <D3StackedBarChart
            id={data.id}
            key={data.id + "hispanicPopulationSBChart"}
            sbData={hispanicPopulationData}
          />
        );
      }
      case "percentBlackPopulationCard": {
        return (
          <D3StackedBarChart
            id={data.id}
            key={data.id + "percentBlackPopulationCard"}
            sbData={percentBlackPopulationData}
          />
        );
      }
      case "povertyByRaceCard": {
        return (
          <D3HorizontalStackedBarChart
            id={data.id}
            key={data.id + "povertyByRaceCard"}
            data={povertyByRaceCard}
          />
        );
      }
      case "multipleStackBar": {
        return (
          <D3MultipleStackBarChart
            id={data.id + "multipleStackBar"}
            key={data.id + "multipleStackBar"}
            msbData={msbData}
          />
        );
      }
      case "infantMortalityToolCard": {
        return (
          <SvgIconCard
            id={data.id + "infantMortalityToolCard"}
            key={data.id + "infantMortalityToolCard"}
            link={"/choosearea/infantmortality"}
            popupType={"RUNAREPORT"}
            icon={infantMortSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at Our Community and the lives of the most vulnerable age group."
            }
          />
        );
      }
      case "stiToolCard": {
        return (
          <SvgIconCard
            id={data.id + "stiToolCard"}
            key={data.id + "stiToolCard"}
            link={"/choosearea/sti"}
            popupType={"RUNAREPORT"}
            icon={stiSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at Sexually Transmitted Infections in Our Community."
            }
          />
        );
      }
      case "covid19ToolCard": {
        return (
          <SvgIconCard
            id={data.id + "covid19ToolCard"}
            key={data.id + "covid19ToolCard"}
            link={"/choosearea/covid19"}
            popupType={"RUNAREPORT"}
            icon={covidSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "BroadStreet is proud to be a founding member of The COVID-19 Data Project."
            }
          />
        );
      }
      case "acesToolCard": {
        return (
          <SvgIconCard
            id={data.id + "acesToolCard"}
            key={data.id + "acesToolCard"}
            link={"/choosearea/aces"}
            popupType={"RUNAREPORT"}
            icon={acesSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at the burden of adverse childhood experiences (ACEs)."
            }
          />
        );
      }
      case "preventingViolenceToolCard": {
        return (
          <SvgIconCard
            id={data.id + "preventingViolenceToolCard"}
            key={data.id + "preventingViolenceToolCard"}
            link={"/choosearea/violence"}
            popupType={"RUNAREPORT"}
            icon={violenceSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "Creating a Safe and Caring Community to Promote Peace."
            }
          />
        );
      }
      case "suicidePreventionToolCard": {
        return (
          <SvgIconCard
            id={data.id + "suicidePreventionToolCard"}
            key={data.id + "suicidePreventionToolCard"}
            link={"/choosearea/suiprev"}
            popupType={"RUNAREPORT"}
            icon={suiprevSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at Our Community."
            }
          />
        );
      }
      case "demographicsToolCard": {
        return (
          <SvgIconCard
            id={data.id + "demographicsToolCard"}
            key={data.id + "demographicsToolCard"}
            link={"/choosearea/demographics"}
            popupType={"RUNAREPORT"}
            icon={demoSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Basic information about who is living in our community."}
          />
        );
      }
      case "foodAssessmentCard": {
        return (
          <SvgIconCard
            id={data.id + "foodAssessmentCard"}
            key={data.id + "foodAssessmentCard"}
            link={"/choosearea/foodassessment"}
            popupType={"RUNAREPORT"}
            icon={appleSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A view of food access, food security, and food deserts."}
          />
        );
      }
      case "mortalityToolCard": {
        return (
          <SvgIconCard
            id={data.id + "mortalityToolCard"}
            key={data.id + "mortalityToolCard"}
            link={"/choosearea/mortality"}
            popupType={"RUNAREPORT"}
            icon={mortSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Trends in life expectancy and leading causes of death."}
          />
        );
      }
      case "areaDeprivationIndexCard": {
        return (
          <SvgIconCard
            id={data.id + "areaDeprivationIndexCard"}
            key={data.id + "areaDeprivationIndexCard"}
            link={"/choosearea/areadeprivationindex"}
            popupType={"RUNAREPORT"}
            icon={houseSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"Socioeconomic and housing vulnerability in our community."}
          />
        );
      }
      case "communityIndicatorReportCard": {
        return (
          <SvgIconCard
            id={data.id + "communityIndicatorReportCard"}
            key={data.id + "communityIndicatorReportCard"}
            link={"/choosearea/communityindicatoreport"}
            popupType={"RUNAREPORT"}
            icon={toolSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A basic starter report with indicators in our community."}
          />
        );
      }
      case "makeAMapCard" && data.configuration.title === "Map Room": {
        return (
          <SvgIconCard
            id={data.id + "makeAMapCard"}
            key={data.id + "makeAMapCard"}
            link={apiBaseUrl + "map/"}
            popupType={"map-room"}
            icon={mapRoom}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A quick link to our Map Room tool."}
          />
        );
      }
      case "makeAMapCard": {
        return (
          <SvgIconCard
            id={data.id + "makeAMapCard"}
            key={data.id + "makeAMapCard"}
            link={"/choosearea/makeamap"}
            popupType={"EXPLOREMAPS"}
            icon={makeMapSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"Build a map for your community by selecting an area."}
          />
        );
      }
      case "browseIndicatorsCard": {
        return (
          <SvgIconCard
            id={data.id + "browseIndicatorsCard"}
            key={data.id + "browseIndicatorsCard"}
            link={"/browseindicators"}
            popupType={"browseindicators"}
            icon={browseBlueSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"See what we’ve got. Search for fun."}
          />
        );
      }
      case "downloadDataFamilyADI": {
        return (
          <QueryRenderer
            environment={environment}
            query={graphql`
              query ChartCardContainerDownloadAdiQuery($input: ID!) {
                boardCardUse(id: $input) {
                  adiComponentTable
                }
              }
            `}
            variables={{
              input: data.id,
            }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return <div>Loading...</div>;
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    You need to log in as a user with permissions to access this
                    card.
                  </div>
                );
              }
              const familyADI = JSON.parse(
                props.boardCardUse.adiComponentTable
              );
              return (
                <DownloadDataCard
                  id={data.id}
                  key={data.id + "downloadDataCard"}
                  familyADI={familyADI}
                />
              );
            }}
          />
        );
      }
      case "simpleGaugeChart": {
        return (
          <D3SimpleGaugeChart
            id={data.id}
            key={data.id + "simpleGaugeChart"}
            simpleGaugeChartData={simpleGaugeChartData}
            min={0}
            max={100}
          />
        );
      }
      case "clickableGaugeChart": {
        return (
          <D3ClickableGaugeChart
            id={data.id}
            key={data.id + "clickableGaugeChart"}
            clickableGaugeChartData={clickableGaugeChartData}
            min={0}
            max={100}
          />
        );
      }
      case "wordCloud": {
        return (
          <D3WordCloudChart
            id={data.id}
            image={cloud}
            imageHeight={200}
            wordCloudChartData={wordCloudChartData}
            imageWidth={200}
          />
        );
      }
      case "diamondVerticalBarChart": {
        return (
          <DiamondVerticalBarChart
            id={data.id}
            key={data.id + "barChart"}
            diamondVerticalBarChartData={diamondVerticalBarChartData}
          />
        );
}
      case "dualVerticalBarChart": {
        return (
          <D3BarChart
            id={data.id}
            key={data.id + "barChart"}
            dualVerticalBarChartData={dualVerticalBarChartData}
          />
        );
      }
      case "multipleSectionBarChart": {
        return (
          <D3MultipleStackHorizontalBarChart
            id={data.id + "multipleSectionBarChart"}
            key={data.id + "multipleSectionBarChart"}
            msbData={multipleSectionBarChartData}
          />
        );
      }
      case "lunchChart": {
        return (
          <LunchChart
            id={data.id}
            key={data.id + "lunchChart"}
            lunchData={lunchChartData}
          />
        );
      }
      case "peopleChart1": {
        return (
          <PeopleChart
            id={data.id}
            key={data.id + "peopleChart1"}
            peopleChartData={peopleChartData}
          />
        );
      }
      default:
        return null;
    }
  };

  handleAreaTallModalPopUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, showCardAreaPopUp } = this.props;
    const savedAreaId = data && data.boardCardUse.configuration.savedArea;
    showCardAreaPopUp(data.boardCardUse.id, savedAreaId);
  };

  showAreaToolTip = () => {
    const { showToolTip } = this.props;
    const bounds = this.charts
      ? this.charts.current.getBoundingClientRect()
      : null;
    showToolTip(bounds.x + 40, bounds.y + 30, "Select New Area");
  };

  render() {
    const {
      subComponentType,
      isEditingEnabled,
      isPublishedStory,
      data,
      hideToolTip,
    } = this.props;
    const areaTag =
      data &&
      data.boardCardUse &&
      data.boardCardUse.configuration &&
      data.boardCardUse.configuration.savedArea &&
      data.boardCardUse.configuration.savedArea.name;
    const isTitleShow = reportToolCardArray.indexOf(subComponentType) === -1;
    return (
      <div>
        <div className={"chartCardContainer"}>
          <div className={`chartCardContainer-title d-flex flex-column`}>
            <div
              className={
                "row px-3 d-flex justify-content-center align-items-center"
              }
            >
              <p className={"text-center title-spacing"}>
                {isTitleShow ? data.boardCardUse.configuration.title : ""}
              </p>
            </div>
            <div
              className={"row d-flex justify-content-center align-items-center"}
            >
              <div
                className={`text-center`}
                onMouseOver={
                  !isEditingEnabled && isPublishedStory && this.showAreaToolTip
                }
                onMouseOut={
                  !isEditingEnabled && isPublishedStory && hideToolTip
                }
                ref={this.charts}
              >
                {!isEditingEnabled ? (
                  <ButtonComponent
                    buttonType={
                      !isEditingEnabled && isPublishedStory
                        ? "select"
                        : isEditingEnabled
                        ? ""
                        : "link"
                    }
                    className={"btn-dashed"}
                    isAreaTag={true}
                    isPublishedStory={isPublishedStory}
                    isShuffleModeOn={isEditingEnabled}
                    iconClass={
                      !isEditingEnabled && isPublishedStory
                        ? "icon-arrow-down"
                        : ""
                    }
                    buttonTitle={areaTag ? areaTag : "United States"}
                    onClick={
                      !isEditingEnabled && isPublishedStory
                        ? this.handleAreaTallModalPopUp
                        : this.handleCardVisibility
                    }
                  />
                ) : (
                  <p
                    className={
                      "text-center mt-1 d-flex justify-content-center align-items-center"
                    }
                  >
                    {areaTag ? areaTag : "United States"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              "chartCardContainer-content" +
              (isEditingEnabled
                ? " movable"
                : ["verticalBarChart"].indexOf(subComponentType) > -1
                ? "clickable"
                : "")
            }
            onClick={this.handleCardVisibility}
          >
            {this.getSubComponent(data.boardCardUse)}
          </div>
        </div>
        {/*
        <button onClick={() => exportComponentAsPNG(this.componentRef)}>
         Export As PNG
        </button> */}
      </div>
    );
  }
}

export default withCardContainer(ChartCardContainer);
