// Card component container HOC , this componet is used as the container to all the cards we are
// using in our app. Must be used as container to every type of card.

import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import * as $ from "jquery";
import ReactDOM from "react-dom";
import { DownloadConsumer } from "../Root/downloadContext";

function withWideCardContainer(WrappingComponent) {
  return class WideCardContainer extends Component {
    hideUnHidePopUp = (x, y, popUpType, cardId) => {
      const { storyCard, hideUnHidePopUp } = this.props;
      if (storyCard) {
        const pos = $(
          $(ReactDOM.findDOMNode(this.cardParent)).parent()
        ).position();
        y = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
        if (document.querySelector("#card-family")) {
          y = document.querySelector("#card-family").scrollHeight - 10;
        }
      }
      hideUnHidePopUp(x, y, popUpType, cardId);
    };

    handleSaveCardsPopUp = (left, top, id, cardId, downloadCard) => {
      const { storyCard, handleSaveCardsPopUp } = this.props;
      if (storyCard) {
        const pos = $(
          $(ReactDOM.findDOMNode(this.cardParent)).parent()
        ).position();
        top = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
        if (document.querySelector("#card-family")) {
          top = document.querySelector("#card-family").scrollHeight;
        }
      }
      handleSaveCardsPopUp(left, top, id, cardId, downloadCard);
    };

    handleShareCardPopUp = (left, top) => {
      const { storyCard, handleShareCardPopUp } = this.props;
      if (storyCard) {
        const pos = $(
          $(ReactDOM.findDOMNode(this.cardParent)).parent()
        ).position();
        top = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
        if (document.querySelector("#card-family")) {
          top = document.querySelector("#card-family").scrollHeight;
        }
      }
      handleShareCardPopUp(left, top);
    };

    render() {
      const { id, cardId, isUpdate } = this.props;
      return (
        <div className="h-100" id={id} ref={(me) => (this.cardParent = me)}>
          <QueryRenderer
            environment={environment}
            query={graphql`
              query WideCardContainerQuery($input: ID!) {
                boardCardUse(id: $input) {
                  id
                  additionalConfiguration {
                    jsonProperties
                  }
                  chartImage
                  configuration {
                    author {
                      name
                    }
                    citation
                    endYear
                    internalOrderList
                    jsonStyleProperties
                    organization {
                      id
                      organizationName
                    }
                    permaLink
                    report {
                      id
                      jsonid
                    }
                    savedArea {
                      id
                      jsonid
                      name
                    }
                    startYear
                    storyLink
                    storyPublishedDate
                    storyUpdatedDate
                    thumbnailImage {
                      id
                      url
                    }
                    title
                    toolLink
                  }
                  cardPosition
                  frontFacingComponent {
                    componentType
                    name
                  }
                  subComponentType
                  statistics {
                    name
                    jsonData
                  }
                  isFavorite
                  sharingStatus
                  tags {
                    edges {
                      node {
                        boardTag {
                          name
                          permaTag
                        }
                      }
                    }
                  }
                }
              }
            `}
            variables={{
              input: unescape(cardId),
              isUpdate: isUpdate,
            }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return <div />;
              }
              return (
                <DownloadConsumer>
                  {(context) => {
                    //context.setCardId(props.boardCardUse.id);
                    return (
                      <>
                        <WrappingComponent
                          {...this.props}
                          hideUnHidePopUp={this.hideUnHidePopUp}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          handleShareCardPopUp={this.handleShareCardPopUp}
                          isCardLoaded={true}
                          id={
                            props && props.boardCardUse && props.boardCardUse.id
                          }
                          wideCardData={props}
                        />
                        {}
                      </>
                    );
                  }}
                </DownloadConsumer>
              );
            }}
          />
        </div>
      );
    }
  };
}

export default withWideCardContainer;
