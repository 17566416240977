/* /src/components/DotMap.js
   Maps for BroadStreet with Jittered Dots */
/* Adapted from work we did for the ADI map report,
     but with colored dots spread around shapes instead of
     filling shapes with color
*/
/* To use, pass arguments like this:
   <DotMap
    savedAreaJsonId={props.boardCardUse.configuration.savedArea.jsonid}
    subComponentType={props.boardCardUse.subComponentType}
    counties={this.state.countyGeoJsonArray}
    bounds={props.boardCardUse.configuration.savedArea.extent}
    />

   savedAreaJsonId: id for (cached) large dot data file for our REST API
   subComponentType: type of dot map to show (dotsAdi|dotsChildrenByRace)
   counties: array of GeoJSON feature strings for county outlines
   bounds: array of SW, NE extent of map like  [-88.263572, 41.46971, -87.524044, 42.154265]

   GeoJSON feature strings come from our GraphQL API calls, or
      REST API in the case of the very large number of dots

   Mapbox GL mapping library must be loaded within the html page for the app
*/
import React from "react";
import "../../css/components/DotMap/DotMap.css";

/* Utility function to convert from array of Relay GraphQL nodes
   returned from a query in an edges property
   into array of embedded GeoJSON features strings.
   Also works with flat array of GeoJSON feature strings */
function extractGeoJSONfeaturesFromEdges(edges) {
  if (edges != null && edges[0] != null) {
    if (edges && edges[0].node) {
      // parse GeoJSON property within each node object
      if (edges[0].node.geojsonWithProperties) {
        edges = edges.map((x) => JSON.parse(x.node.geojsonWithProperties));
      } else {
        edges = edges.map((x) => JSON.parse(x.node.geojsonFeature));
      }
    } else if (edges && edges[0]) {
      // handle a flat array of GeoJSON features as strings also
      edges = edges.map((x) => JSON.parse(x));
    }
  }

  /* return original array if no embedded features found */
  return edges;
}

class DotMap extends React.Component {
  constructor(props) {
    super(props);

    this.dots = [];
    this.counties = [];
    this.map = null;
    this.renderMap = this.renderMap.bind(this);
  }

  componentDidUpdate() {
    /* called after a new render (e.g. parent updated data passed to props) */
    //this.renderMap();
  }

  componentDidMount() {
    const { counties } = this.props;
    /* called after component is first rendered to the DOM */
    this.counties = extractGeoJSONfeaturesFromEdges(counties);
    this.renderMap();
  }

  componentWillUnmount() {
    if (this.map != null) {
      this.map.remove();
    }
  }

  render() {
    const { id } = this.props;
    return <div id={id} className="dotMap"></div>;
  }

  renderMap() {
    const { id } = this.props;
    var mapboxgl = window.mapboxgl;
    if (mapboxgl) {
      if (this.map == null) {
        this.map = new mapboxgl.Map({
          container: id,
          style:
            "https://api.maptiler.com/maps/d311b63e-9caf-4f91-8ebb-f675244e474f/style.json?key=92mrAH6NBXO3jvPRMmT9",
          //style: 'https://api.maptiler.com/maps/positron/style.json?key=92mrAH6NBXO3jvPRMmT9',
          center: [-98.585522, 39.8333333],
          zoom: 9,
          minZoom: 2,
          preserveDrawingBuffer: true,
        });
        this.map.on("load", this.addShapes);
      } else {
        if (this.map.loaded()) {
          this.addShapes();
        } else {
          this.map.on("render", this.addShapesAfterNextFrame);
        }
      }
    }
  }

  addShapesAfterNextFrame = () => {
    this.map.off("render", this.addShapesAfterNextFrame);
    this.addShapes();
  };

  addShapes = () => {
    const { bounds, subComponentType } = this.props;
    var mapboxgl = window.mapboxgl;
    var map = this.map;
    var bound = new mapboxgl.LngLatBounds(bounds);
    // render dots shapes on map
    // initialize layers for dots, counties
    // dotsAdi colors
    var colors_def;
    if (subComponentType === "dotsAdi") {
      colors_def = [
        "#D3D3D3",
        "#78A22F",
        "#6A8D73",
        "#D5DED6",
        "#FFA987",
        "#FF6B35",
        "#000000",
      ];
      if (map.getSource("dots") == null) {
        map.addSource("dots", {
          type: "vector",
          url: "https://api.maptiler.com/tiles/27581a15-2c45-4ab4-8442-a25cc8058da0/tiles.json?key=92mrAH6NBXO3jvPRMmT9",
        });
        /*
        map.addSource("dots", {
                "type": "geojson",
                "data": this.dots,
                /* NOTE: Setting tolerance too high discards small complex shapes completely */
        /*
                                "tolerance": 0.05,
                        });
                        */
        map.addLayer(
          {
            id: "indicator_dots",
            type: "circle",
            source: "dots",
            "source-layer": "children_adi_by_cbg_dots",
            layout: {},
            paint: {
              "circle-color": [
                "match",
                ["get", "category"],
                1,
                colors_def[1],
                2,
                colors_def[2],
                3,
                colors_def[3],
                4,
                colors_def[4],
                5,
                colors_def[5],
                6,
                colors_def[6],
                colors_def[0],
              ],
              "circle-radius": {
                base: 1,
                stops: [
                  [12, 1.5],
                  [15, 1.58],
                  [16, 2.5],
                  [17, 3.95],
                  [18, 6.25],
                  [19, 9.88],
                  [20, 15.63],
                  [21, 24.71],
                  [22, 39.06],
                ],
              },
              "circle-opacity": {
                base: 1,
                stops: [
                  [9, 0.7],
                  [17, 1],
                ],
              },
            },
          },
          "highway_name_other"
        );
      }
    } else {
      // dotsChildrenByRace colors
      /*
          colors_def = [
              "#424B54", // Black
              "#00939D", // Asian
              "#D35721", // Native American
              "#FF6B35", // Native Hawaiian
              "#B3CC87", // Other
              "#78A22F", // 2 or More
              "#D6CBAE", // White
              //"#E800E8",
              ];
              */
      // 0 - native, 1 - other, 2 - black, 3 - asian, 4 - white
      colors_def = [
        "#FF6B35", // Native American + Native Hawaiian
        "#78A22F", // Other + 2 or More
        "#424B54", // Black
        "#00939D", // Asian
        "#D6CBAE", // White
        "#E800E8",
        "#E800E8",
        "#E800E8",
      ];
      if (map.getSource("dots") == null) {
        map.addSource("dots", {
          type: "vector",
          url: "https://api.maptiler.com/tiles/ec08464a-fb53-4440-9f77-bb1f1ebac311/tiles.json?key=92mrAH6NBXO3jvPRMmT9",
          //"url":
          // "https://api.maptiler.com/tiles/b702c535-3852-4b93-9d24-aad7ad7f9153/tiles.json?key=92mrAH6NBXO3jvPRMmT9"
          // "url":
          // "https://api.maptiler.com/tiles/47445043-34d0-4ad6-bf5f-db6eb11c5dfd/tiles.json?key=92mrAH6NBXO3jvPRMmT9"
        });
        /*
        map.addSource("dots", {
                "type": "geojson",
                "data": this.dots,
                /* NOTE: Setting tolerance too high discards small complex shapes completely */
        /*
                                "tolerance": 0.05,
                        });
                        */
        map.addLayer(
          {
            id: "indicator_dots",
            type: "circle",
            source: "dots",
            "source-layer": "children_race_by_tract_dots",
            layout: {},
            paint: {
              "circle-color": [
                "match",
                ["get", "category"],
                1,
                colors_def[1],
                2,
                colors_def[2],
                3,
                colors_def[3],
                4,
                colors_def[4],
                5,
                colors_def[5],
                6,
                colors_def[6],
                colors_def[0],
              ],
              "circle-radius": {
                base: 1,
                stops: [
                  [12, 1.5],
                  [15, 1.58],
                  [16, 2.5],
                  [17, 3.95],
                  [18, 6.25],
                  [19, 9.88],
                  [20, 15.63],
                  [21, 24.71],
                  [22, 39.06],
                ],
              },
              "circle-opacity": {
                base: 1,
                stops: [
                  [9, 0.4],
                  [17, 1],
                ],
              },
            },
          },
          "highway_name_other"
        );
      }
    }
    /*var paths = [];*/

    if (map.getSource("counties") == null) {
      map.addSource("counties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        tolerance: 0.2,
      });
      map.addLayer(
        {
          id: "counties",
          type: "line",
          source: "counties",
          layout: {},
          paint: {
            "line-color": "rgba(90, 90, 90, 1)",
            "line-width": 2,
          },
        },
        "highway_name_other"
      );
    }
    var countiesFeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    // render county shapes on map
    if (!this.counties) {
      this.counties = [];
    }
    this.counties.forEach(function (object) {
      countiesFeatureCollection.features.push(object);
    });
    var countiesSource = map.getSource("counties");
    countiesSource.setData(countiesFeatureCollection);

    // zoom and center map around bounds of rendered shapes
    if (!bound.isEmpty()) {
      map.fitBounds(bound);
    }
  };
}

export default DotMap;
