import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/components/Card/AdiMapCardContainer.css";
import withCardContainer from "../Card/CardContainer";
import { withRouter } from "react-router";
import { arrayOfMapPlaceholders } from "../../utility/utilities";
import AdiMap from "../AdiMap/AdiMap";
import { ButtonComponent } from "common-components";

class AdiMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPlaceholder:
        (props.id.charCodeAt(props.id.length - 1) +
          props.id.charCodeAt(props.id.length - 2)) %
        arrayOfMapPlaceholders.length,
    };
    this.maps = React.createRef();
  }

  handleClick = () => {
    const { isEditingEnabled, handleWideCardVisible, data } = this.props;
    if (isEditingEnabled !== true && handleWideCardVisible !== undefined) {
      handleWideCardVisible(data.boardCardUse.id);
    }
  };

  handleAreaTallModalPopUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, showCardAreaPopUp } = this.props;
    const savedAreaId = data && data.boardCardUse.configuration.savedArea;
    showCardAreaPopUp(data.boardCardUse.id, savedAreaId);
  };

  showAreaToolTip = () => {
    const { showToolTip } = this.props;
    const bounds = this.maps ? this.maps.current.getBoundingClientRect() : null;
    showToolTip(bounds.x + 80, bounds.y + 30, "Select New Area");
  };

  render() {
    const {
      data,
      isEditingEnabled,
      isMapVisible,
      id,
      isPublishedStory,
      hideToolTip,
    } = this.props;
    const areaTag =
      data &&
      data.boardCardUse &&
      data.boardCardUse.configuration.savedArea.name;
    const { mapPlaceholder } = this.state;
    const title = data.boardCardUse.configuration.title;
    const titleLength = title != null ? title.length : 0;
    return (
      <div>
        <div
          className={
            isEditingEnabled
              ? "adiMapCardContainer movable"
              : "adiMapCardContainer clickable"
          }
          onClick={this.handleClick}
        >
          <div className="adiMapCardContainer-map">
            {isMapVisible === true ? (
              <QueryRenderer
                environment={environment}
                query={graphql`
                  query AdiMapCardContainerQuery($input: ID!) {
                    boardCardUse(id: $input) {
                      id
                      configuration {
                        savedArea {
                          counties {
                            edges {
                              node {
                                id
                                fips5
                                geojsonFeature
                              }
                            }
                          }
                          reportstatisticsSet {
                            edges {
                              node {
                                name
                                areaDeprivationIndex
                                blockGroups {
                                  edges {
                                    node {
                                      id
                                      fips5
                                      fips12
                                      geojsonWithProperties
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `}
                variables={{ input: id }}
                render={({ error, props }) => {
                  if (error) {
                    console.error(error);
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return (
                      <div>
                        <br />
                        <br />
                        Loading...
                      </div>
                    );
                  }
                  // console.log("project board ------> ", JSON.stringify(props))
                  if (props.boardCardUse == null) {
                    return (
                      <div>
                        <br />
                        You need to log in as a user with permissions to access
                        this content.
                      </div>
                    );
                  }
                  const { savedArea } = props.boardCardUse.configuration;
                  if (
                    savedArea.reportstatisticsSet &&
                    savedArea.reportstatisticsSet.edges.length === 0
                  ) {
                    return (
                      <div>
                        <br />
                        (Map Not Available)
                      </div>
                    );
                  }
                  return (
                    <div>
                      <AdiMap
                        id={"map" + id}
                        counties={
                          savedArea.counties ? savedArea.counties.edges : []
                        }
                        blockgroups={
                          savedArea.reportstatisticsSet &&
                          savedArea.reportstatisticsSet.edges &&
                          savedArea.reportstatisticsSet.edges.length
                            ? savedArea.reportstatisticsSet.edges[0].node
                                .blockGroups.edges
                            : []
                        }
                      />
                      <div className="adiMapCardContainer-watermrk-img">
                        <img
                          src={require("../../images/favicon1.svg")}
                          alt="BroadStreet"
                        />
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              <img src={arrayOfMapPlaceholders[mapPlaceholder]} alt="" />
            )}
          </div>
          <div
            className={
              titleLength > 70
                ? "adiMapCardContainer-title long-story-title title-spacing"
                : "adiMapCardContainer-title title-spacing"
            }
          >
            <div
              className={
                "row px-3 d-flex justify-content-center align-items-center"
              }
            >
              <p className={"text-center mb-0"}>{title}</p>
            </div>
            <div
              className={"row d-flex justify-content-center align-items-center"}
            >
              <div
                className={"text-center"}
                onMouseOver={
                  !isEditingEnabled && isPublishedStory && this.showAreaToolTip
                }
                onMouseOut={
                  !isEditingEnabled && isPublishedStory && hideToolTip
                }
                ref={this.maps}
              >
                {!isEditingEnabled ? (
                  <ButtonComponent
                    buttonType={
                      !isEditingEnabled && isPublishedStory
                        ? "select"
                        : isEditingEnabled
                        ? ""
                        : "link"
                    }
                    className={"btn-dashed"}
                    isShuffleModeOn={isEditingEnabled}
                    isAreaTag={true}
                    iconClass={
                      !isEditingEnabled && isPublishedStory
                        ? "icon-arrow-down"
                        : ""
                    }
                    buttonTitle={areaTag ? areaTag : "United States"}
                    onClick={
                      !isEditingEnabled && isPublishedStory
                        ? this.handleAreaTallModalPopUp
                        : null
                    }
                  />
                ) : (
                  <p
                    className={
                      "text-center d-flex justify-content-center align-items-center"
                    }
                  >
                    {areaTag ? areaTag : "United States"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCardContainer(withRouter(AdiMapCardContainer));
