import React, { Component } from "react";
import * as d3 from "d3";
import "../../css/components/Card/D3SaladChart.css";

class D3SaladChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.duration = 1000;
    this.clientWidth = 125;
    this.clientHeight = 230;
    this.scale = d3.scaleLinear();
    this.bowlHeight = 65;
    this.state = {
      ratio: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.update(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  init = () => {
    this.createSvg();
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3SaladChartSVG")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;

    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }

    this.scale.range([0, this.height - this.bowlHeight]);

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
  };

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  update = (props) => {
    const { min, max } = props;
    this.scale.domain([min, max]);
    this.autoAlignSVG();
    this.updateSalad(props);
    this.addSaladImage(props);
  };

  addSaladImage = (props) => {
    const { saladSrc } = props;
    const img = this.svgG
      .selectAll("img.salad-img")
      .data([{ src: saladSrc }], (d) => d.src);
    const imageForeignObject = img
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height);

    const imageEleDiv = imageForeignObject
      .append("xhtml:div")
      .attr("class", "lunch-img-div")
      .style("position", "relative");

    const newImageEle = imageEleDiv.append("img");
    newImageEle
      .merge(img)
      .attr("src", (d) => d.src)
      .attr("width", this.width)
      .attr("height", this.height);
    img.exit().remove();
  };

  updateSalad = (props) => {
    const padding = 18; // salad image padding
    const barWidth = (this.width - 2 * padding) / 3;
    var { value } = props;
    if (value == null) {
      value = 0;
    }
    const imgGauge = this.svgG
      .selectAll("rect.salad-bar")
      .data([{ value }, { value }, { value }], (d) => d.value);
    const newImageEle = imgGauge.enter().append("rect");
    newImageEle
      .merge(imgGauge)
      .attr("class", "salad-bar")
      .attr("x", (d, i) => i * barWidth + 1 + (padding - 2))
      .attr("y", (d) => this.height - this.bowlHeight - this.scale(d.value))
      .attr("width", barWidth)
      .attr("height", (d) => this.scale(d.value))
      .attr("fill", (d, i) => ((i + 1) % 2 === 0 ? "#FF5700" : "#78A22F"));
    imgGauge.exit().remove();
  };

  render() {
    const { value, valueLabel } = this.props;
    const { ratio } = this.state;
    return (
      <div
        className="D3SaladChart d-flex flex-column justify-content-center align-items-center h-100 w-100"
        ref={(ele) => (this.mainEle = ele)}
      >
        <div
          className={"d-flex"}
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="d-flex justify-content-end h-100 px-2">
            <div
              className="w-100 h-100"
              ref={(ele) => (this.parentEle = ele)}
            />
          </div>
          <div className="h-100">
            <div className="salad-detail-top-space" />
            <div className="salad-detail">
              <div className="main-title">
                <table>
                  <tbody>
                    <tr>
                      <td>{value != null ? valueLabel : "NA"}</td>
                      <td>{value != null ? "out of 10" : valueLabel}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="subTitle px-1">a score of 10 is best</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3SaladChart;
