import React from "react";
import PeopleChart from "./PeopleChart";
import "../../css/components/Card/PeopleChart.css";
import WideCardWrapper from "../WideCard/WideCardWrapper";

const PeopleWideChart = (props) => {
  const { id, peopleChartData, showTwitterCardPopup } = props;
  const content = (
    <div  className="d-flex justify-content-center h-100">
      <PeopleChart id={id}  peopleChartData={peopleChartData} isWide />
    </div>
  );
  return (
    <>
      {showTwitterCardPopup ? (
        content
      ) : (
        <WideCardWrapper {...props} fromChart={true} >
          {content}
        </WideCardWrapper>
      )}
    </>
  );
};

export default PeopleWideChart;
