import React, { Component } from "react";
import Switch from "react-switch";
import * as d3 from "d3";
import * as _ from "lodash";
import "../../css/components/Card/D3PopulationBlockChart.css";

class D3PopulationBlockChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 10, bottom: 0, left: 10 };
    this.clientWidth = 217;
    this.clientHeight = 195;
    this.legendHeight = 15;
    this.toggleHeight = 20;
    this.state = {
      ratio: 1,
      isToggle: false,
      data: [],
    };
  }

  handleChange = (checked, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { blockData } = this.props;
    // e.stopImmediatePropagation();
    this.setState({ isToggle: checked }, () => {
      this.createChartData(blockData);
    });
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { blockData } = nextProps;
    if (!_.isEqual(nextProps.blockData, nextState.blockData)) {
      this.createChartData(blockData);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    if (!this.mounted) return;
    this.size = Math.min(
      this.parentEle.clientWidth,
      this.parentEle.clientHeight
    );
    if (isWide) {
      this.setState({
        ratio: (this.size / 217) * 0.65,
      });
    }
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height + this.margin.top + this.margin.bottom + this.legendHeight
        }`
      );
  };

  createChartData = (blockData) => {
    const { isToggle } = this.state;
    if (isToggle) {
      this.setState(
        {
          blockData: blockData,
          data: blockData.data.map((o, i) => {
            const obj = {};
            obj.id = Date.now() + i;
            obj.label = o.label;
            if (o[blockData.blockTypes[1].blockType] != null) {
              obj.value = parseFloat(
                o[blockData.blockTypes[1].blockType].toFixed(2)
              );
            } else {
              obj.value = 0.0;
            }
            obj.color = o.color;
            return obj;
          }),
        },
        () => {
          this.init();
        }
      );
    } else {
      this.setState(
        {
          blockData: blockData,
          data: blockData.data.map((o, i) => {
            const obj = {};
            obj.id = Date.now() + i;
            obj.label = o.label;
            if (o[blockData.blockTypes[0].blockType] != null) {
              obj.value = parseFloat(
                o[blockData.blockTypes[0].blockType].toFixed(2)
              );
            } else {
              obj.value = 0.0;
            }
            obj.color = o.color;
            return obj;
          }),
        },
        () => {
          this.init();
        }
      );
    }
  };

  init = () => {
    const { blockData, id } = this.props;
    if (blockData && id) {
      d3.select(this.parentEle)
        .selectAll("svg")
        .filter((d) => d === id)
        .remove();
      this.createSvg();
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    /*const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3PopulationBlockChart').attr('id', d => d);
    selection.exit().remove();*/
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg
      .selectAll("g.g-container")
      .data([svgId], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "g-container");

    const gLegendSelection = this.svg
      .selectAll("g.legend-g-container")
      .data([svgId], (d) => d);
    gLegendSelection
      .enter()
      .append("g")
      .merge(gLegendSelection)
      .attr("class", "legend-g-container")
      .attr("id", (d) => d + "legend-g-container");
    gLegendSelection.exit().remove();
    this.svgLegendG = this.svg.select("g#" + svgId + "legend-g-container");

    // this.svgToggleG = this.svg.select('#' + id + 'toggle-g-container');

    const rotationSelection = this.svgG
      .selectAll("g.rotation-container")
      .data([svgId], (d) => d);
    rotationSelection
      .enter()
      .append("g")
      .merge(rotationSelection)
      .attr("class", "rotation-container")
      .attr("id", (d) => d + "rotation-container");
    rotationSelection.exit().remove();
    this.rotationG = this.svgG.select("g#" + svgId + "rotation-container");
    const rectSelection = this.rotationG
      .selectAll("g.rect-container")
      .data([svgId], (d) => d);
    rectSelection
      .enter()
      .append("g")
      .merge(rectSelection)
      .attr("class", "rect-container")
      .attr("id", (d) => d + "rect-container");
    rectSelection.exit().remove();
    this.rectG = this.svgG.select("g#" + svgId + "rect-container");
    const lineSelection = this.rotationG
      .selectAll("g.dash-line-container")
      .data([svgId], (d) => d);
    lineSelection
      .enter()
      .append("g")
      .merge(lineSelection)
      .attr("class", "dash-line-container")
      .attr("id", (d) => d + "dash-line-container");
    lineSelection.exit().remove();
    this.dashLineG = this.svgG.select("g#" + svgId + "dash-line-container");
    this.autoAlignSVG();
    this.updateLegends();
  };

  autoAlignSVG = () => {
    const { data, ratio } = this.state;
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.legendHeight -
      this.toggleHeight;
    this.spaceSize = Math.min(this.width, this.height) - 5;
    const newValue = this.size / 217;
    if (isWide && ratio !== newValue) {
      this.setState({
        ratio: (this.size / 217) * 0.65,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height + this.margin.top + this.margin.bottom + this.legendHeight
        }`
      );

    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.svgLegendG.attr(
      "transform",
      "translate(" +
        this.margin.left +
        "," +
        (this.height + this.margin.top + this.legendHeight / 2) +
        ")"
    );
    /*this.svgToggleG
        .attr('y', (this.height + this.margin.top + this.legendHeight + (this.toggleHeight / 2)));*/
    const rectMargin = 5;
    this.squareSize = d3
      .scaleLinear()
      .domain([0, d3.sum(data, (d) => d.value)])
      .range([
        this.spaceSize / 3.5,
        Math.sqrt(Math.pow(this.spaceSize, 2) / 2) - rectMargin * 4,
      ]);
    this.createRect(rectMargin);
    const box = this.rotationG.node().getBBox();
    this.rotationG.attr(
      "transform",
      "translate(" +
        ((this.width - box.width) / 2 + box.x * -1) +
        "," +
        ((this.height - box.height) / 2 + box.y * -1) +
        ")"
    );
    setTimeout(() => {
      const box = this.rotationG.node().getBBox();
      this.rotationG.attr(
        "transform",
        "translate(" +
          ((this.width - box.width) / 2 + box.x * -1) +
          "," +
          ((this.height - box.height) / 2 + box.y * -1) +
          ")"
      );
    });
  };
  updateLegends = () => {
    const { data } = this.state;

    // legends G enter, update, exit
    const rectHeight = 6;
    const rectWidth = 14;
    const legendG = this.svgLegendG
      .selectAll("g.legend")
      .data(data, (d) => d && d.label);
    const newLegendG = legendG.enter().append("g");
    newLegendG
      .merge(legendG)
      .attr("class", (d, i) => "legend")
      .attr("id", (d, i) => "g-legend-" + i);
    legendG.exit().remove();
    // legends rect enter, update, exit
    const legendRect = newLegendG
      .merge(legendG)
      .selectAll("rect")
      .data(
        (d) => [d],
        (d) => d && d.label
      );
    const newRect = legendRect.enter().append("rect");
    newRect
      .merge(legendRect)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", rectWidth)
      .attr("height", rectHeight)
      .attr("rx", rectHeight / 2)
      .attr("ry", rectWidth / 2)
      .attr("transform", `translate(0,-${rectHeight / 2})`)
      .style("fill", (d) => d.color);
    legendRect.exit().remove();
    // legends text enter, update, exit
    const legendText = newLegendG
      .merge(legendG)
      .selectAll("text")
      .data(
        (d) => [d],
        (d) => d && d.label
      );
    const newText = legendText.enter().append("text");
    newText
      .merge(legendText)
      .attr("x", rectWidth + 5)
      .attr("y", 0)
      .style("font-size", "12px")
      .attr("text-anchor", "start")
      .attr("dy", "4px")
      .style("fill", "#424B54")
      .style("color", "#424B54")
      .style("font-family", "ProximaNova-Regular")
      .text((d) => d.label && d.label);
    legendText.exit().remove();
    let legendSize = 0;
    this.svgLegendG.selectAll("g.legend").attr("transform", function (d, i) {
      let box = { x: 0, width: 0 };
      if (i !== 0) {
        box = d3
          .select(this.parentNode)
          .select("g#g-legend-" + (i - 1))
          .node()
          .getBBox();
        legendSize = legendSize + box.width + 10;
      }
      d3.select(this).attr("transform", `translate(${legendSize},${0})`);
      return `translate(${legendSize},${0})`;
    });
  };
  createRect = (rectMargin) => {
    const { data } = this.state;
    const self = this;
    const dataCount = data.length % 2 ? data.length + 1 : data.length;
    const rectG = this.rectG.selectAll("g.rect-g").data(data, (d) => d.id);
    const rectNewG = rectG.enter().append("g");
    rectNewG
      .merge(rectG)
      .attr("class", "rect-g")
      .attr("id", (d) => "g-rect-pb-" + d.id)
      .each((d, i) => (d.i = i));
    rectG.exit().remove();
    const rect = rectNewG
      .merge(rectG)
      .selectAll("rect.qube")
      .data(
        (d) => [d],
        (d) => d.id
      );
    const rectNew = rect.enter().append("rect");
    rectNew
      .merge(rect)
      .attr("id", (d) => "rect-pb-" + d.id)
      .attr("class", "qube")
      .attr("fill", (d) => (d.color ? d.color : "#424B54"))
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("height", (d) => this.squareSize(d.value))
      .attr("width", (d) => this.squareSize(d.value))
      .style("transform", (d) => {
        return (
          "rotate(" +
          ((360 / dataCount) * d.i + 135) +
          "deg)translate(" +
          rectMargin +
          "px," +
          rectMargin +
          "px)"
        );
      });
    rect.exit().remove();
    const textGSelection = rectNewG
      .merge(rectG)
      .selectAll("g.textG")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newTextG = textGSelection.enter().append("g").attr("class", "textG");
    newTextG.merge(textGSelection).style("transform", (d) => {
      return (
        "rotate(" +
        ((360 / dataCount) * d.i + 135) +
        "deg)translate(" +
        (self.squareSize(d.value) / 2 + rectMargin) +
        "px," +
        (self.squareSize(d.value) / 2 + rectMargin) +
        "px)"
      );
    });
    textGSelection.exit().remove();
    const titleG = newTextG
      .merge(textGSelection)
      .selectAll("g.titleG")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newTitleG = titleG.enter().append("g").attr("class", "titleG");
    newTitleG
      .merge(titleG)
      .style(
        "transform",
        (d) => "rotate(" + -((360 / dataCount) * d.i + 135) + "deg)"
      );
    titleG.exit().remove();
    const textSelection = newTitleG
      .merge(titleG)
      .selectAll("text.title")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newText = textSelection.enter().append("text");
    newText
      .merge(textSelection)
      .attr("class", "title")
      .text((d) => d.label)
      .style("font-size", function (d) {
        const val = Math.min(
          (2 * self.squareSize(d.value)) / 2,
          (((2 * self.squareSize(d.value)) / 2 - 8) /
            this.getComputedTextLength()) *
            10
        );
        return val >= 9 ? val + "px" : "0px";
      })
      .attr("dy", "-0.3em")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Bold")
      .style("fill", "#ffffff")
      .style("fill-opacity", "0.5")
      .attr("x", 0)
      .attr("y", 0);
    textSelection.exit().remove();
    const subTitleG = newTextG
      .merge(textGSelection)
      .selectAll("g.subTitleG")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newSubTitleG = subTitleG
      .enter()
      .append("g")
      .attr("class", "subTitleG");
    newSubTitleG
      .merge(subTitleG)
      .style(
        "transform",
        (d) => "rotate(" + -((360 / dataCount) * d.i + 135) + "deg)"
      );
    subTitleG.exit().remove();
    const subtextSelection = newSubTitleG
      .merge(subTitleG)
      .selectAll("text.subtitle")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newSubtext = subtextSelection.enter().append("text");
    newSubtext
      .merge(subtextSelection)
      .attr("class", "subtitle")
      .text(function (d) {
        return d.label;
      })
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Bold")
      .style("fill", "#ffffff")
      .style("fill-opacity", "0.5")
      .attr("dy", function (d) {
        return d3
          .select(this.parentNode.parentNode)
          .select("g.titleG")
          .select("text")
          .style("font-size") !== "0px"
          ? "0.75em"
          : "0.35em";
      })
      .text(function (d) {
        let value = d.value < 1 ? _.round(d.value, 1) : _.round(d.value);
        return value + "%";
      })
      .style("font-size", function (d) {
        return (
          Math.min(
            (2 * self.squareSize(d.value)) / 2.3,
            (((2 * self.squareSize(d.value)) / 2.3 - 8) /
              this.getComputedTextLength()) *
              12
          ) + "px"
        );
      })
      .attr("dy", function (d) {
        return d3
          .select(this.parentNode.parentNode)
          .select("g.titleG")
          .select("text")
          .style("font-size") !== "0px"
          ? "0.75em"
          : "0.35em";
      });
    subtextSelection.exit().remove();
    this.createDashPath(dataCount);
  };

  createDashPath = (dataCount) => {
    const { data } = this.state;
    const lineG = this.dashLineG
      .selectAll("path.dash")
      .data(data.slice(0, dataCount / 2), (d) => d.id);
    const newLineG = lineG.enter().append("path");
    newLineG
      .merge(lineG)
      .attr("class", "dash")
      .attr("d", (d) => {
        return `M0,-${this.spaceSize / 4} L0,${this.spaceSize / 4}`;
      })
      .style("stroke", "#ACB0B4")
      .style("stroke-width", " 1px")
      .style("stroke-dasharray", "5,4")
      .style("transform", (d, i) => {
        return (
          "rotate(" + ((360 / dataCount) * i + 135) + "deg)translate(0px,0px)"
        );
      });
    lineG.exit().remove();
  };

  render() {
    const { blockData, id } = this.props;
    const { isToggle, ratio } = this.state;
    const switchTitle = {
      fontSize: "11px",
      fontFamily: "ProximaNova-Regular",
      lineHeight: 0.9,
      display: "block",
      color: "#989898",
    };
    const svgId = id.replace(/=/g, "");
    return (
      <div className="D3PopulationBlockChart d-flex flex-column w-100 h-100">
        <div
          className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
          ref={(ele) => (this.parentEle = ele)}
        >
          <svg id={svgId}></svg>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "6px",
              height: `${29}px`,
              lineHeight: "0.8",
              transform: `scale(${ratio})`,
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: this.toggleHeight + "px" }}
            >
              <div>
                <small style={switchTitle} className={"text-right"}>
                  {blockData.blockTypes.length && blockData.blockTypes[0].title}
                </small>
              </div>
              <div className="px-2">
                <Switch
                  onChange={this.handleChange}
                  checked={isToggle}
                  className="react-switch"
                  id="togglePie"
                  height={18}
                  width={35}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#989898"
                  onHandleColor="#fff"
                />
              </div>
              <div>
                <small style={switchTitle} className={"text-left"}>
                  {blockData.blockTypes.length && blockData.blockTypes[1].title}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3PopulationBlockChart;
