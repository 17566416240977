import React, { Component } from 'react';
import '../../css/components/Card/D3LineDotChart.css';
import * as d3 from 'd3';
import * as _ from 'lodash';

class D3LineDotChart extends Component {
  constructor(props) {
    super(props);
    this.margin = {top: 10, right: 0, bottom: 10, left: 0};
    this.offsetWidth = 217;
    this.offsetHeight = 180;
    this.state = {
      ratio: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    const {lineDotData} = this.props;
    if (!_.isEqual(nextProps.lineDotData, lineDotData)) {
      this.init(nextProps);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidMount() {
    this.init(this.props);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const {isWide} = this.props;
    const {ratio} = this.state;
    this.size = this.mainParentEle.clientHeight > this.mainParentEle.clientWidth ? this.mainParentEle.clientWidth : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217
      });
    }
  };

  init = (nextProps) => {
    const {lineDotData, titleText, id} = nextProps;
    if (lineDotData && id) {
      this.textEle.innerHTML = titleText;
      d3.select(this.parentEle).selectAll('svg').filter((d) => {
        return d !== nextProps.id;
      }).remove();
      this.createSvg(nextProps);
      window.addEventListener('resize', this.onWindowResize);
      this.mounted = true;
    }
  };

  createSvg = (nextProps) => {
    const id = nextProps.id.replace(/=/g, '');
    const {lineDotData, minValue, maxValue} = nextProps;
    const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3PieChart').attr('id', d => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select('svg#' + id);
    this.svg.attr('height', 0);
    const gSelection = this.svg.selectAll('g.g-container').data((d) => [d], (d) => d);
    gSelection.enter().append('g').merge(gSelection).attr('class', 'g-container').attr('id', (d) => d + 'g-container');
    gSelection.exit().remove();
    this.svgG = d3.select('g#' + id + 'g-container');
    const gLineSelection = this.svgG.selectAll('g.line-container').data((d) => [d], (d) => d);
    gLineSelection.enter().append('g').merge(gLineSelection).attr('class', 'line-container').attr('id', (d) => d + 'line-container');
    gLineSelection.exit().remove();
    this.svgLineG = d3.select('g#' + id + 'line-container');
    const gdotSelection = this.svgG.selectAll('g.dot-container').data((d) => [d], (d) => d);
    gdotSelection.enter().append('g').merge(gdotSelection).attr('class', 'dot-container').attr('id', (d) => d + 'dot-container');
    gdotSelection.exit().remove();
    this.svgLineG = d3.select('g#' + id + 'line-container');
    this.svgDotG = d3.select('g#' + id + 'dot-container');
    const gDashPathSelection = this.svgG.selectAll('g.dash-path-container').data((d) => [d], (d) => d);
    gDashPathSelection.enter().append('g').merge(gDashPathSelection).attr('class', 'dash-path-container').attr('id', (d) => d + 'dash-path-container');
    gDashPathSelection.exit().remove();
    this.svgDashPathG = d3.select('g#' + id + 'dash-path-container');
    const gLabels = this.svgG.selectAll('g.labels-container').data((d) => [d], (d) => d);
    gLabels.enter().append('g').merge(gLabels).attr('class', 'labels-container').attr('id', (d) => d + 'labels-container');
    gLabels.exit().remove();
    this.svgLabelsG = d3.select('g#' + id + 'labels-container');
    this.y = d3.scaleBand().domain(lineDotData.map((d) => d.label)).align(0.5);
    this.x = d3.scaleLinear().domain([minValue, maxValue]);
    this.autoAlignSVG(nextProps);
    this.createLine(nextProps);
    this.createDot(nextProps);
    this.createDashPath(nextProps);
    this.createLabels(nextProps);
  };
  autoAlignSVG = (props) => {
    const {isWide} = props;
    const {ratio} = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.offsetWidth - this.margin.left - this.margin.right;
    this.height = this.offsetHeight - this.margin.top - this.margin.bottom;
    this.labelWidth = this.width / 2;
    this.size = this.mainParentEle.clientHeight > this.mainParentEle.clientWidth ? this.mainParentEle.clientWidth : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg.attr('width', (this.width + this.margin.left + this.margin.right))
      .attr('height', (this.height + this.margin.top + this.margin.bottom));
    this.svg.append('svg:rect')
      .attr('width', (this.width + this.margin.left + this.margin.right)) // the whole width of
                                                                          // g/svg
      .attr('height', (this.height + this.margin.top + this.margin.bottom)) // the whole heigh of
                                                                            // g/svg
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        this.count = this.count + 1;
        if (this.count === 1) {
          this.createLine(props);
          this.createDot(props);
        }
      })
      .on('mousemove', () => {
        d3.event.stopPropagation();
      }).on('mouseout', () => {
      this.count = 0;
      d3.event.stopPropagation();
    });
    this.svgG
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.y.range([0, this.height]);
    this.svgLineG.attr('transform', 'translate(' + this.labelWidth + ',0)');
    this.svgDotG.attr('transform', 'translate(' + this.labelWidth + ',0)');
    this.svgDashPathG.attr('transform', 'translate(' + this.labelWidth + ',0)');
    this.svgLabelsG.attr('transform', 'translate(' + (this.labelWidth - 5) + ',0)');
    this.x.range([0, this.width - this.labelWidth]);
  };

  createLabels(nextProps) {
    const lineG = this.svgLabelsG.selectAll('g.labels-g').data(nextProps.lineDotData, (d) => d.label);
    const newLineG = lineG.enter()
      .append('g');
    newLineG.merge(lineG)
      .attr('class', 'labels-g')
      .attr('transform', (d) => {
        return 'translate(0,' + (this.y(d.label) + this.y.bandwidth() / 2) + ')';
      });
    lineG.exit().remove();
    const textLabel = newLineG.merge(lineG).selectAll('text.label').data(d => [d], (d) => d.label);
    textLabel.enter()
      .append('text')
      .merge(textLabel)
      .text(d => d.label)
      .attr('class', 'label')
      .style('text-anchor', 'end')
      .style('font-family', 'ProximaNova-Regular')
      .style('font-size', '11px')
      .style('font-weight', 'bold')
      .style('fill', '#424B54')
      .attr('dy', '-2px');
    textLabel.exit().remove();
    const textValues = newLineG.merge(lineG).selectAll('text.values').data(d => [d], (d) => d.label);
    textValues.enter()
      .append('text')
      .merge(textValues)
      .text(d => d.value + ' ' + d.valuesscale)
      .attr('class', 'values')
      .style('text-anchor', 'end')
      .style('font-family', 'ProximaNova-Regular')
      .style('font-size', '11px')
      .attr('dy', '8px')
      .style('fill', '#424B54');
    textValues.exit().remove();

  }

  createLine(nextProps) {
    this.svgLineG.selectAll('g.line-g').remove();
    const lineG = this.svgLineG.selectAll('g.line-g').data(nextProps.lineDotData, (d) => d.label);
    const newLineG = lineG.enter()
      .append('g');
    newLineG.merge(lineG)
      .attr('class', 'line-g')
      .attr('transform', (d) => {
        return 'translate(0,' + (this.y(d.label) + this.y.bandwidth() / 2) + ')';
      });
    lineG.exit().remove();
    const line = newLineG.merge(lineG)
      .selectAll('line.line')
      .data(d => [d], (d) => d.label);
    line.enter()
      .append('line')
      .merge(line)
      .attr('class', 'line')
      .attr('x1', 0)
      .attr('y1', 0)
      .style('stroke', nextProps.lineColor ? nextProps.lineColor : '#424B54')
      .style('stroke-width', nextProps.lineWidth ? nextProps.lineWidth : 2)
      .transition()
      .duration(700)
      .attr('x2', (d) => this.x(d.value))
      .attr('y2', 0);

    line.exit().remove();
  }

  createDashPath(nextProps) {
    const lineG = this.svgDashPathG.selectAll('path.dash').data([nextProps.minValue, (nextProps.maxValue - 3.5)]);
    const newLineG = lineG.enter()
      .append('path');
    newLineG.merge(lineG)
      .attr('class', 'dash')
      .attr('d', (d) => {
        return 'M' + this.x(d) + ',0 L' + this.x(d) + ',' + this.height;
      })
      .style('stroke', '#ACB0B4')
      .style('stroke-width', ' 1px')
      .style('stroke-dasharray', '5,4');
    lineG.exit().remove();
  }

  createDot(nextProps) {
    const self = this;
    this.svgDotG.selectAll('g.dot-g').remove();
    const dotG = this.svgDotG.selectAll('g.dot-g').data(nextProps.lineDotData, (d) => d.label);
    const newDotG = dotG.enter()
      .append('g');
    newDotG.merge(dotG)
      .attr('class', 'dot-g')
      .attr('transform', function(d) {
        return `translate(${(d3.select(this).attr('x') ? d3.select(this).attr('x') : 0)}, ${(self.y(d.label) + self.y.bandwidth() / 2)})`;
      })
      .transition()
      .duration(700)
      .attr('transform', (d) => {
        return 'translate(' + this.x(d.value) + ',' + (this.y(d.label) + this.y.bandwidth() / 2) + ')';
      })
      .on('end', function(d) {
        d3.select(this).attr('x', self.x(d.value));
      });
    dotG.exit().remove();
    const dot = newDotG.merge(dotG)
      .selectAll('circle.dot')
      .data(d => [d], (d) => d.label);
    dot.enter()
      .append('circle')
      .merge(dot)
      .attr('class', 'dot')
      .attr('cx', 0)
      .attr('cy', 0)
      .style('fill', nextProps.dotColor ? nextProps.dotColor : '#00939D')
      .transition()
      .duration(700)
      .attr('r', nextProps.dotRadius ? nextProps.dotRadius : 10);

    dot.exit().remove();
  }

  render() {
    const {ratio} = this.state;
    return (
      <div className='D3LineDotChart h-100 w-100 d-flex justify-content-center align-items-center'
           ref={(ele) => {
             this.mainParentEle = ele;
           }}>
        <div className={'d-flex flex-column'}
             style={{height: '217px', width: '217px', transform: `scale(${ratio})`}}>
          <div className='title-text'
               ref={(ele) => {
                 this.textEle = ele;
               }}
          />
          <div className='chart-area' ref={(ele) => {
            this.parentEle = ele;
          }}>
          </div>
        </div>
      </div>
    );
  }
}

export default D3LineDotChart;
