import React, { Component } from 'react';
import * as d3 from 'd3';
import arrowDot from './../../images/arrowDiamond.svg';

class D3MultipleStackBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = {top: 10, right: 23, bottom: 10, left: 10};
    this.clientWidth = 245;
    this.clientHeight = 220;
    this.state = {
      stackBarData: [
        {color: '#78A22F', range: [0, 20]},
        {color: '#6A8D73', range: [20, 40]},
        {color: '#D5DED6', range: [40, 60]},
        {color: '#FFA987', range: [60, 80]},
        {color: '#FF6B35', range: [80, 100]}
      ],
      legends: ['Most Affluent', 'National Average', 'Most Deprived'],
      ratio: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.init(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.mounted = true;
    this.init(this.props);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const {isWide} = this.props;
    const {ratio} = this.state;
    this.size = this.mainEle.clientWidth > this.mainEle.clientHeight ? this.mainEle.clientHeight : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 220) {
      this.setState({
        ratio: this.size / 220
      });
    }
  };

  init = (props) => {
    const {msbData, id, isWide} = props;
    const {ratio} = this.state;
    if (msbData && id) {
      d3.select(this.parentEle).selectAll('svg').filter((d) => {
        return d.id !== id;
      }).remove();
      this.createSvg(props);
      this.size = this.mainEle.clientWidth > this.mainEle.clientHeight ? this.mainEle.clientHeight : this.mainEle.clientWidth;
      if (isWide && ratio !== this.size / 220) {
        this.setState({
          ratio: this.size / 220
        });
      }
    }
  };

  createSvg = (props) => {
    const id = props.id.replace(/=/g, '');
    const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3MultipleStackBarChart').attr('id', d => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select('svg#' + id);
    const gSelection = this.svg.selectAll('g.g-container').data((d) => [d], (d) => d);
    gSelection.enter().append('g').merge(gSelection).attr('class', 'g-container').attr('id', (d) => d + 'g-container');
    gSelection.exit().remove();
    this.svgG = d3.select('g#' + id + 'g-container');

    const gLegendSelection = this.svgG.selectAll('g.legends-container').data((d) => [d], (d) => d);
    gLegendSelection.enter().append('g').merge(gLegendSelection)
      .attr('class', 'legends-container')
      .attr('id', (d) => d + 'legends-container');
    gLegendSelection.exit().remove();
    this.svgLegendG = d3.select('g#' + id + 'legends-container');
    const gRectSelection = this.svgG.selectAll('g.rect-container').data((d) => [d], (d) => d);
    gRectSelection.enter().append('g').merge(gRectSelection).attr('class', 'rect-container').attr('id', (d) => d + 'rect-container');
    gRectSelection.exit().remove();
    this.svgRectG = d3.select('g#' + id + 'rect-container');

    const gLabels = this.svgG.selectAll('g.labels-container').data((d) => [d], (d) => d);
    gLabels.enter().append('g').merge(gLabels).attr('class', 'labels-container').attr('id', (d) => d + 'labels-container');
    gLabels.exit().remove();
    this.labelsG = d3.select('g#' + id + 'labels-container');
    this.barScale = d3.scaleLinear().domain([0, 100]);
    this.y = d3.scaleBand();
    this.x = d3.scaleLinear();
    this.autoAlignSVG(props);
    this.createLegends(props);
    this.createRect(props);
    this.createLabels(props);
  };
  autoAlignSVG = (props) => {
    this.barHeight = 5;
    const {msbData, isWide} = props;
    const {ratio} = this.state;
    this.legendHeight = 25;
    this.labelWidth = 100;
    this.labelHeight = 35;

    //  Set the dimensions and margins of the diagram
    this.width = (isWide ? this.parentEle.clientWidth : this.clientWidth) - this.margin.left - this.margin.right - this.labelWidth;
    this.height = ((isWide ? this.parentEle.clientHeight : this.clientHeight) < (msbData.length * this.labelHeight) ?
      (msbData.length * this.labelHeight) :
      ((isWide ? this.parentEle.clientHeight : this.clientHeight) - 10))
      - this.margin.top - this.margin.bottom - this.legendHeight;
    this.size = this.mainEle.clientWidth > this.mainEle.clientHeight ? this.mainEle.clientHeight : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 220) {
      this.setState({
        ratio: this.size / 220
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg.attr('width', (this.width + this.margin.left + this.margin.right + this.labelWidth))
      .attr('height', (this.height + this.margin.top + this.margin.bottom + this.legendHeight));
    this.svgG
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.svgLegendG.attr('transform', 'translate(' + (this.labelWidth - 10) + ',0)');
    this.svgRectG.attr('transform', 'translate(' + this.labelWidth + ',' + this.legendHeight + ')');
    this.labelsG.attr('transform', 'translate(0,' + this.legendHeight + ')');
    this.y.domain(msbData.map(d => d.title[0].replace(/ /g, '-') + d.title[1].replace(/ /g, '-'))).rangeRound([0, this.height]).padding(0);
    this.x.domain([0, 100]).range([0, this.width]);
    this.barScale.range([0, this.width]);
  };

  roundedRect(x, y, width, height, rtr, rbr, rbl, rtl) {
    const radiusTopRight = rtr ? rtr : 0;
    const radiusBottomRight = rbr ? rbr : 0;
    const radiusBottomLeft = rbl ? rbl : 0;
    const radiusTopLeft = rtl ? rtl : 0;
    return 'M' + x + ',' + y
      + 'h' + (width - radiusTopRight)
      + 'a' + radiusTopRight + ',' + radiusTopRight + ' 0 0 1 ' + radiusTopRight + ',' + radiusTopRight
      + 'v' + (height - 2 * radiusBottomRight)
      + 'a' + radiusBottomRight + ',' + radiusBottomRight + ' 0 0 1 ' + -radiusBottomRight + ',' + radiusBottomRight
      + 'h' + (radiusBottomLeft - width)
      + 'a' + radiusBottomLeft + ',' + radiusBottomLeft + ' 0 0 1 ' + -radiusBottomLeft + ',' + -radiusBottomLeft
      + 'v' + -(height - 2 * radiusTopLeft)
      + 'a' + radiusTopLeft + ',' + radiusTopLeft + ' 0 0 1 ' + radiusTopLeft + ',' + -radiusTopLeft
      + 'z';
  }

  createRect = (props) => {
    const {msbData} = props;
    const {stackBarData} = this.state;
    const dataGSelection = this.svgRectG.selectAll('g.data-g').data(msbData, d => d.title[0] + d.title[1]);
    const newDataG = dataGSelection.enter().append('g');
    newDataG.merge(dataGSelection)
      .attr('class', 'data-g')
      .attr('transform', (d, i) => {
        return `translate(0,${this.y((d.title[0].replace(/ /g, '-') + d.title[1].replace(/ /g, '-')))})`;
      });
    dataGSelection.exit().remove();

    const pathSelection = newDataG.merge(dataGSelection).selectAll('path.bar').data(stackBarData, d => d.color);
    const newPathSelection = pathSelection.enter().append('path');
    newPathSelection.merge(pathSelection)
      .attr('class', 'bar')
      .attr('transform', (d, i) => {
        return `translate(0,${(this.y.bandwidth() / 2)})`;
      })
      .attr('d', (d, i) => {
        const br = this.barHeight / 2;
        if (i === 0) {
          return this.roundedRect(this.barScale(d.range[0]), 0, (this.barScale(d.range[1]) - this.barScale(d.range[0])), this.barHeight, 0, 0, br, br);
        } else if (i === (stackBarData.length - 1)) {
          return this.roundedRect(this.barScale(d.range[0]) + br, 0, (this.barScale(d.range[1]) - this.barScale(d.range[0])) - br, this.barHeight, br, br, 0, 0);
        } else {
          return this.roundedRect(this.barScale(d.range[0]), 0, (this.barScale(d.range[1]) - this.barScale(d.range[0])), this.barHeight);
        }
      })
      .attr('fill', d => d.color);

    const dotSelection = newDataG.merge(dataGSelection).selectAll('image.dot').data(d => [d], d => d.title[0] + d.title[1]);
    const newDotSelection = dotSelection.enter().append('image');
    newDotSelection.merge(dotSelection)
      .attr('class', 'dot')
      .attr('transform', (d, i) => {
        return `translate(${(this.x(d.value))},${(this.y.bandwidth() / 2)})`;
      })
      .attr('xlink:href', arrowDot)
      .attr('x', -3.5)
      .attr('y', -2.5)
      .attr('height', 10)
      .attr('width', 7);

  };
  createLabels = (props) => {
    const {msbData} = props;
    const dataGSelection = this.labelsG.selectAll('g.labels-g').data(msbData, d => d.title[0] + d.title[1]);
    const newDataG = dataGSelection.enter().append('g');
    newDataG.merge(dataGSelection)
      .attr('class', 'data-g')
      .attr('transform', (d, i) => {
        return `translate(0,${this.y((d.title[0].replace(/ /g, '-') + d.title[1].replace(/ /g, '-')))})`;
      });
    dataGSelection.exit().remove();

    const pathSelection = newDataG.merge(dataGSelection).selectAll('foreignObject').data(d => [d], d => d.title[0] + d.title[1]);
    const newPathSelection = pathSelection.enter().append('foreignObject');
    newPathSelection.append('xhtml:div');
    newPathSelection.merge(pathSelection)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.labelWidth)
      .attr('height', this.labelHeight)
      .select('div')
      .style('width', (this.labelWidth - 10) + 'px')
      .style('height', this.labelHeight + 'px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('font-size', '10px')
      .style('color', '#424B54')
      .style('font-family', 'ProximaNova-Bold')
      .style('max-width', '95px')
      .style('line-height', '1em')
      .style('text-align', 'right')
      .style('justify-content', 'flex-end')
      .style(' width', '95px').html((d) => '<div style=\'word-break:break-word\'>' + d.title[0] + '</div>');

  };
  createLegends = (props) => {
    const {legends} = this.state;
    const legendSelection = this.svgLegendG.selectAll('legend-g').data(legends, d => d);
    const newLegendsG = legendSelection.enter().append('g');
    newLegendsG.merge(legendSelection)
      .attr('transform', (d, i) => {
        return `translate(${(((this.width + 20) / legends.length) * i)},0)`;
      });
    legendSelection.exit().remove();
    const foreignObj = newLegendsG.merge(legendSelection).selectAll('foreignObject').data((d, i) => [{
      value: d,
      i
    }], d => d);
    const newForeignObj = foreignObj.enter().append('foreignObject');
    newForeignObj.append('xhtml:div');
    newForeignObj.merge(foreignObj)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', (this.width + 20) / legends.length)
      .attr('height', this.legendHeight)
      .select('div')
      .style('font-size', '8px')
      .style('color', 'rgb(66, 75, 84)')
      .style('font-family', 'ProximaNova-Regular')
      .style('max-width', '95px')
      .style('line-height', '1em')
      .style('text-align', (d) => {
        return d.i === 0 ? 'left' : (d.i === legends.length - 1) ? 'right' : 'center';
      })
      .style(' width', '95px').text((d) => d.value);

  };

  render() {
    const {ratio} = this.state;
    return (
      <div ref={(ele) => this.mainEle = ele}
           className='D3MultipleStackBarChart d-flex justify-content-center h-100 w-100 align-items-center'>
        <div className='d-flex flex-column'
             style={{height: '220px', width: '245px', transform: `scale(${ratio})`}}>
          <div className='h-100' ref={(ele) => {
            this.parentEle = ele;
          }}/>
        </div>
      </div>
    );
  }
}

export default D3MultipleStackBarChart;
