/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBoardSectionInput = {|
  sectionId: string,
  name?: ?string,
  description?: ?string,
  layout?: ?$ReadOnlyArray<?BoardSectionCardLayoutInput>,
  sectionPosition?: ?number,
  image1Id?: ?string,
  image2Id?: ?string,
  image3Id?: ?string,
  clientMutationId?: ?string,
|};
export type BoardSectionCardLayoutInput = {|
  x?: ?number,
  y?: ?number,
  w?: ?number,
  h?: ?number,
  i?: ?string,
|};
export type SectionDMutationVariables = {|
  input: UpdateBoardSectionInput
|};
export type SectionDMutationResponse = {|
  +updateBoardSection: ?{|
    +boardSectionNode: ?{|
      +id: string,
      +board: {|
        +hasUnpublishedChanges: boolean
      |},
    |}
  |}
|};
export type SectionDMutation = {|
  variables: SectionDMutationVariables,
  response: SectionDMutationResponse,
|};
*/


/*
mutation SectionDMutation(
  $input: UpdateBoardSectionInput!
) {
  updateBoardSection(input: $input) {
    boardSectionNode {
      id
      board {
        hasUnpublishedChanges
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBoardSectionInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnpublishedChanges",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionDMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBoardSectionPayload",
        "kind": "LinkedField",
        "name": "updateBoardSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNode",
            "kind": "LinkedField",
            "name": "boardSectionNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SectionDMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBoardSectionPayload",
        "kind": "LinkedField",
        "name": "updateBoardSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNode",
            "kind": "LinkedField",
            "name": "boardSectionNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SectionDMutation",
    "operationKind": "mutation",
    "text": "mutation SectionDMutation(\n  $input: UpdateBoardSectionInput!\n) {\n  updateBoardSection(input: $input) {\n    boardSectionNode {\n      id\n      board {\n        hasUnpublishedChanges\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74517c4e8791df1d5110a40cd7a81737';

module.exports = node;
