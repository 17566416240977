import React from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3RingChart from './D3RingChart';

const D3RingWideChart = (props) => {
  const { id, chartData, showTwitterCardPopup } = props;
  const wideChartId = id + (showTwitterCardPopup ? 'D3RingWideChart' : 'D3RingTwitterChart');
  const content = <div className="D3RingWideChart d-flex w-100 h-100">
    <D3RingChart
      id={wideChartId}
      key={wideChartId}
      chartData={chartData}
      isWide={true}
    />
  </div>;
  return (
    <>
      {showTwitterCardPopup ? content
        : <WideCardWrapper {...props} fromChart={true}>
          {content}
        </WideCardWrapper>
      }
    </>
  );
};

export default D3RingWideChart;
