import React, { useEffect, useRef, useState } from "react";
import "../../css/components/Card/PeopleChart.css";
// require SVGs
import Shadows from "../../images/peoplechart/shadows.svg";
import Stroke1 from "../../images/peoplechart/shadows-stroke1.svg";
import Stroke2 from "../../images/peoplechart/shadows-stroke2.svg";
import Stroke3 from "../../images/peoplechart/shadows-stroke3.svg";
import Stroke4 from "../../images/peoplechart/shadows-stroke4.svg";
import Stroke5 from "../../images/peoplechart/shadows-stroke5.svg";
import Stroke6 from "../../images/peoplechart/shadows-stroke6.svg";
import Stroke7 from "../../images/peoplechart/shadows-stroke7.svg";
import Stroke8 from "../../images/peoplechart/shadows-stroke8.svg";
import Stroke9 from "../../images/peoplechart/shadows-stroke9.svg";
import Stroke10 from "../../images/peoplechart/shadows-stroke10.svg";

const PeopleChart = ({ isWide, id, peopleChartData: data }) => {
  const stroke = data.value;
  const textType = (
    <>
      {stroke} {data.label4} <br /> {data.label5}
    </>
  );
  const text = (
    <>
      <div className={`card-text px-25`}>
        {data.label} <span className="text-strong">{data.label2}</span> <br /> {data.label3}
      </div>
      <div className={`card-text text-strong px-3`}>
        {" "}
        {textType}
      </div>
    </>
  );

  const strokeArr = [
    Stroke1,
    Stroke2,
    Stroke3,
    Stroke4,
    Stroke5,
    Stroke6,
    Stroke7,
    Stroke8,
    Stroke9,
    Stroke10,
  ];

  const [strokeWidth, setStrokeWidth] = useState(0);

  useEffect(() => {
    if (shadowsImgRef.current) {
      const shadowsWidth = parseFloat(
        getComputedStyle(shadowsImgRef.current).width
      );
      const strokeWidth = ((shadowsWidth * 20) / 202) * stroke + 10;
      setStrokeWidth(strokeWidth);
    }
  }, [stroke]);

  const shadowsImgRef = useRef();
  return (
    <div id={id} className={`d-flex flex-column justify-content-center ${isWide ? "scale-card" : ""}`}>
      <div className={`position-relative p-4`}>
        <div ref={shadowsImgRef}>
          <img src={Shadows} className="shadows" alt="" />
        </div>
        <div className="shadow-stroke">
          <img
            src={strokeArr[stroke - 1]}
            alt=""
            width={isWide ? strokeWidth : "100%"}
          />
        </div>
      </div>
      <div>{text}</div>
    </div>
  );
};

export default PeopleChart;
