import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import TextLayoutType2 from '../Card/TextLayoutType2';

class TextLayoutType1Wide extends Component {
  render() {
    const { textLayoutType2, id, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'textLayoutType2' : 'textLayoutType2Twitter');
    const content = (
      textLayoutType2 &&
      <TextLayoutType2
        id={wideChartId}
        mainTitle={textLayoutType2.mainTitle}
        mainSubTitle={textLayoutType2.mainSubTitle}
        title1={textLayoutType2.title1}
        title={textLayoutType2.title}
        isWide={true}
      />
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default TextLayoutType1Wide;
