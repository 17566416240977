import React from 'react';

const Notifications = () => {
  return (
    <>
      <div className="tab-pane fade active show" id="accordion-2">
        {/* <div className="text-left mb-5">
          <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
        <div className="ibox-head mb-5">
          <h3 className="font-weight-bold " >Notifications</h3>
        </div>
        <div className="my-5 text-left">
          <p className="small-text mb-3">
            Manage your email preferences. What emails would you like to receive?
          </p>
          <div className="mb-5">
            <div className="mb-3">
              <lable className="d-flex align-items-center"><input type="checkbox" />  Marketing and Promotional Emails</lable>
            </div>
            <div className="mb-3">
              <lable className="d-flex align-items-center"><input type="checkbox" />  Major Software Updates</lable>
            </div>
            <div className="mb-3">
              <lable className="d-flex align-items-center"><input type="checkbox" />  Billing Emails</lable>
            </div>
          </div>
          <button className="button-outline long-button button"
            onClick={() => { }}>
            Save
            </button>
        </div>
      </div>
    </>
  );
};

export default Notifications;
