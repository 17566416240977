import React, { Component } from 'react';
import D3GridProgressChart from '../Card/D3GridProgressChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3GridProgressWideChart extends Component {
  render() {
    const { id, imagePath, chartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3GridProgressWideChart' : 'D3GridProgressTwitterChart');
    const content = (
      <div className='D3GridProgressWideChart d-flex w-100 h-100'>
        {chartData &&
        <D3GridProgressChart
          id={wideChartId}
          key={wideChartId}
          image={imagePath}
          imageHeight={72}
          imageWidth={200}
          data={chartData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3GridProgressWideChart;
