/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBoardSectionLayoutsInput = {|
  layouts: any,
  clientMutationId?: ?string,
|};
export type ProjectBoardUpdateSectionLayoutsMutationVariables = {|
  input: UpdateBoardSectionLayoutsInput
|};
export type ProjectBoardUpdateSectionLayoutsMutationResponse = {|
  +updateBoardSectionLayouts: ?{|
    +updatesApplied: ?number
  |}
|};
export type ProjectBoardUpdateSectionLayoutsMutation = {|
  variables: ProjectBoardUpdateSectionLayoutsMutationVariables,
  response: ProjectBoardUpdateSectionLayoutsMutationResponse,
|};
*/


/*
mutation ProjectBoardUpdateSectionLayoutsMutation(
  $input: UpdateBoardSectionLayoutsInput!
) {
  updateBoardSectionLayouts(input: $input) {
    updatesApplied
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBoardSectionLayoutsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBoardSectionLayoutsPayload",
    "kind": "LinkedField",
    "name": "updateBoardSectionLayouts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatesApplied",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBoardUpdateSectionLayoutsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBoardUpdateSectionLayoutsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectBoardUpdateSectionLayoutsMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectBoardUpdateSectionLayoutsMutation(\n  $input: UpdateBoardSectionLayoutsInput!\n) {\n  updateBoardSectionLayouts(input: $input) {\n    updatesApplied\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44ddec81dcd489f0ef0c151de12b277c';

module.exports = node;
