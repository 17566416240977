import React, { Component } from 'react';
import D3DotGridProgressChart from '../Card/D3DotGridProgressChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3DotGridProgressWideChart extends Component {
  render() {
    const { id, imagePath, chartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3DotGridProgressWideChart' : 'D3DotGridProgressTwitterChart');
    const content = (
      <div className='D3DotGridProgressWideChart d-flex w-100 h-100'>
        {chartData &&
        <D3DotGridProgressChart
          id={wideChartId}
          key={wideChartId}
          image={imagePath}
          imageHeight={72}
          imageWidth={200}
          data={chartData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3DotGridProgressWideChart;
