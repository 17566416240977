import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3TextLayoutBabyChart from './D3TextLayoutBabyChart';

class D3TextLayoutBabyWideChart extends Component {
  render() {
    const { id, chartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3TextLayoutBabyWideChart' : 'D3TextLayoutBabyTwitterChart');
    const content = (
      <div className='D3TextLayoutBabyWideChart d-flex w-100 h-100'>
        <D3TextLayoutBabyChart
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3TextLayoutBabyWideChart;
