import React from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3DonutProgressChart from '../Card/D3DonutProgressChart';

const D3DonutProgressWideChart = (props) => {
  const {id, image, percent, text, showTwitterCardPopup} = props;
  const content = (
    <div className='D3DonutProgressWideChart d-flex w-100 h-100'>
      {percent &&
      <D3DonutProgressChart
        id={id + 'DonutProgressChart'}
        key={id + 'DonutProgressChart'}
        percent={percent}
        text={text}
        image={image}
        isWide={true}
      />
      }</div>
  );
  return (
    <>
      {showTwitterCardPopup ?
        content :
        <WideCardWrapper {...props} fromChart={true}>
          {content}
        </WideCardWrapper>
      }
      </>
  );
};

export default D3DonutProgressWideChart;
