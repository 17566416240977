import React, { Component } from "react";
import "../../css/components/CommonComponents/RemoveCardPopUp.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import ReactDOM from "react-dom";

class ReportErrorPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportText: "",
      xOriginTollTip: 0,
      yOriginTollTip: 0,
    };
  }

  handleChild = (e) => {
    e.stopPropagation();
  };

  handleReport = () => {
    const { cardId, hidePopUp } = this.props;
    const { reportText } = this.state;
    if (true) {
      const bounds = ReactDOM.findDOMNode(
        this.refs["reportB"]
      ).getBoundingClientRect();
      this.setState({
        xOriginTollTip: bounds.x,
        yOriginTollTip: bounds.y,
      });

      /*const cardId = "Qm9hcmRDYXJkVXNlTm9kZTo0NDUz";*/
      const mutation = graphql`
        mutation ReportErrorPopUpMutation(
          $input: CreateBoardCardFeedbackInput!
        ) {
          createBoardCardFeedback(input: $input) {
            success
          }
        }
      `;
      const variables = {
        input: {
          boardCardUse: cardId,
          message: reportText,
        },
      };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log(
            "Response from report error Feedback.",
            JSON.stringify(response)
          );
          hidePopUp();
          if (response.createBoardCardFeedback.success) {
            this.showReportErrorTollTip();
            this.setState({
              reportText: "",
            });
          }
        },
        onError: (err) => console.error(JSON.stringify(err)),
      });
    }
  };

  showReportErrorTollTip() {
    const { showToolTip, hideToolTip } = this.props;
    const { xOriginTollTip, yOriginTollTip } = this.state;
    showToolTip(
      xOriginTollTip - 50,
      yOriginTollTip - 70,
      "Thank You for Feedback"
    );
    setTimeout(() => {
      hideToolTip();
    }, 3000);
  }

  onTextChange = (e) => {
    this.setState({
      reportText: e.target.value,
    });
  };

  render() {
    const { visibility, x, y, hidePopUp } = this.props;
    const { reportText } = this.state;
    let thisX = x != null ? x : 0;
    let thisY = y != null ? y : 0;
    return (
      <div
        style={{
          left: Math.max(0, thisX - 10),
          top: thisY + 30,
          position: "absolute",
          zIndex: 9999,
        }}
        onClick={this.handleChild}
      >
        {visibility ? (
          <div className="ReporterrorContainer">
            <ul className="reportpopuplist">
              <li>Report Error Or Abuse</li>
              <li className="question">
                <div>
                  <textarea
                    placeholder="Type Here"
                    value={reportText}
                    onChange={(e) => {
                      this.onTextChange(e);
                    }}
                  />
                </div>
              </li>
              <li className="copy d-flex">
                <button
                  className="align-items-baseline p-0"
                  onClick={hidePopUp}
                >
                  Cancel
                </button>
                <button
                  className="align-items-baseline p-0"
                  ref="reportB"
                  onClick={this.handleReport}
                >
                  Report
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ReportErrorPopUp;
