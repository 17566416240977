/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CascadingDeleteSavedAreaInput = {|
  savedAreaId: string,
  clientMutationId?: ?string,
|};
export type AreasCascadingDeleteSavedAreaMutationVariables = {|
  input: CascadingDeleteSavedAreaInput
|};
export type AreasCascadingDeleteSavedAreaMutationResponse = {|
  +cascadingDeleteSavedArea: ?{|
    +savedArea: ?{|
      +id: string
    |}
  |}
|};
export type AreasCascadingDeleteSavedAreaMutation = {|
  variables: AreasCascadingDeleteSavedAreaMutationVariables,
  response: AreasCascadingDeleteSavedAreaMutationResponse,
|};
*/


/*
mutation AreasCascadingDeleteSavedAreaMutation(
  $input: CascadingDeleteSavedAreaInput!
) {
  cascadingDeleteSavedArea(input: $input) {
    savedArea {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CascadingDeleteSavedAreaInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CascadingDeleteSavedAreaPayload",
    "kind": "LinkedField",
    "name": "cascadingDeleteSavedArea",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedAreaNode",
        "kind": "LinkedField",
        "name": "savedArea",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AreasCascadingDeleteSavedAreaMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AreasCascadingDeleteSavedAreaMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AreasCascadingDeleteSavedAreaMutation",
    "operationKind": "mutation",
    "text": "mutation AreasCascadingDeleteSavedAreaMutation(\n  $input: CascadingDeleteSavedAreaInput!\n) {\n  cascadingDeleteSavedArea(input: $input) {\n    savedArea {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6369b6acb5fa58e861a7c5237d515a36';

module.exports = node;
