import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3MultipleHorizontalBarChart from './D3MultipleHorizontalBarChart';

class D3MultipleHorizontalBarWideChart extends Component {
  render() {
    const { id, chartData, showTwitterCardPopup } = this.props;
    const content = (
      <div className="D3HeartWideChart d-flex w-100 h-100">
        <D3MultipleHorizontalBarChart
          id={id + 'D3MultipleHorizontalBarWideChart'}
          key={id + 'D3MultipleHorizontalBarWideChart'}
          chartData={chartData}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3MultipleHorizontalBarWideChart;
