// CardFamily is a host to sections of cards as a published collection
//   so it has the same top-level components and handlers for
//   custom tooltips and card popups as HomeFeed and ProjectBoard components
import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import WideChartCardContainer from "./WideChartCardContainer";
import WideMapCardContainer from "./WideMapCardContainer";
import FooterComponent from "../CommonComponents/FooterComponent";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { Value } from "slate";
import { Editor } from "slate-react";
import StoryCardContainer from "../Card/StoryCardContainer";
import ReportCardContainer from "../Card/ReportCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import CardDetailsText from "../CardDetailsText/CardDetailsText";
import { Responsive } from "react-grid-layout";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import "../../css/components/Card/CardFamily.css";
import ReportToolCardContainer from "./ReportToolCardContainer";
import MoreOptionPopup from "../CommonComponents/MoreOptionPopup";
import SaveCardsPopUp from "../CommonComponents/SaveCardsPopUp";
import { ShareCardPopUp } from "../CommonComponents/ShareCardPopUp";
import ReportErrorPopUp from "../CommonComponents/ReportErrorPopUp";
import * as _ from "lodash";
import { reportToolCardArray } from "../../utility/utilities";
import { WideModalPopup, ToolTip } from "common-components";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class CardFamily extends Component {
  render() {
    const { cardId, updateCustomPage } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query CardFamilyQuery($input: ID!) {
            boardCardUse(id: $input) {
              id
              jsonid
              additionalConfiguration {
                jsonProperties
              }
              adiComponentTable
              dataDownloadDescription
              frontFacingComponent {
                componentType
              }
              isFavorite
              subComponentType
              cardFamily {
                id
                allowCopy
                name
                subtitle
                sections {
                  edges {
                    node {
                      boardCardUses {
                        edges {
                          node {
                            id
                            cardPosition
                            frontFacingComponent {
                              componentType
                              name
                            }
                            statistics {
                              id
                              jsonData
                            }
                            isFavorite
                            subComponentType
                          }
                        }
                      }
                      id
                      name
                      description
                      layout
                      sectionPosition
                    }
                  }
                }
                theme {
                  jsonStyleProperties
                }
              }
              configuration {
                appreciates
                author {
                  id
                  briefBio
                  contactInfo
                  name
                  profileImage {
                    url
                  }
                }
                citation
                collaborators {
                  edges {
                    node {
                      id
                      briefBio
                      contactInfo
                      name
                      profileImage {
                        url
                      }
                    }
                  }
                }
                detailsText
                featureImage {
                  caption
                  url
                }
                permaLink
                remoteSyncCode
                savedArea {
                  id
                  jsonid
                  name
                }
                storyLink
                storyPublishedDate
                storyReadTime
                storyText
                storyUpdatedDate
                title
                toolLink
              }
              isFavorite
              sharingStatus
              tags {
                edges {
                  node {
                    boardTag {
                      name
                      permaTag
                    }
                  }
                }
              }
              boardSection {
                board {
                  id
                  isCardFamily
                  isPublishedStory
                  name
                  publicFeedStatus
                  publishedStoryCard {
                    id
                  }
                }
              }
            }
          }
        `}
        variables={{
          input: unescape(cardId),
          updateCustomPage: updateCustomPage,
        }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          // console.log("CardFamily data ", JSON.stringify(props))
          return <CardFamilyInner cardFamilyData={props} {...this.props} />;
        }}
      />
    );
  }
}

class CardFamilyInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      xOriginSaveCardsPopUp: 0,
      yOriginSaveCardsPopUp: 0,
      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isMorePopUpVisible: false,
      xOriginForMorePopUp: 0,
      yOriginForMorePopUp: 0,

      selectedCard: null,
    };
  }

  componentDidMount() {
    const { cardFamilyData } = this.props;
    window.scrollTo(0, 0);
    let data = _.cloneDeep(cardFamilyData);
    if (data) {
      let updatedEdges = [];
      data.boardCardUse.isUpdate = false;
      data.boardCardUse.cardFamily.sections.edges.map((section, index) => {
        let sectionObject = _.cloneDeep(section);
        sectionObject.node.boardCardUses.edges.map((item, index) => {
          item.isUpdate = false;
          return item;
        });
        updatedEdges.push(sectionObject);
        return updatedEdges;
      });
      data.boardCardUse.cardFamily.sections.edges = updatedEdges;
    }
    this.setState({
      data,
    });
  }

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({ isToolTipVisible: false });
  };

  renderNodeDesc = (props) => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case "block-quote":
        return <blockquote {...attributes}>{children}</blockquote>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "heading-one":
        return <h1 {...attributes}>{children}</h1>;
      case "heading-two":
        return <h2 {...attributes}>{children}</h2>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return (
          <ul>
            <ol {...attributes}>{children}</ol>
          </ul>
        );
      default:
        break;
    }
  };

  renderMark = (props) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "code":
        return <code {...attributes}>{children}</code>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      default:
        break;
    }
  };

  closeCardFamilyPopup = () => {
    const { closeCardFamilyPopup, cardFamilyData } = this.props;
    let parentCollectionLocation = null;
    const col = cardFamilyData.boardCardUse.boardSection.board;
    if (col.publicFeedStatus === 1 && col.name === "My Favorites") {
      parentCollectionLocation = "/FAVORITES";
    } else if (
      col.isPublishedStory === true &&
      col.publishedStoryCard != null
    ) {
      parentCollectionLocation = `/pubboard/${escape(
        col.publishedStoryCard.id
      )}`;
    }
    closeCardFamilyPopup(parentCollectionLocation);
  };

  buildAllSectionLayouts = (cardEdges) => {
    const sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    const layout = cardEdges.map((edge, index) => {
      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h:
          edge.node.frontFacingComponent.componentType === "ReportCard"
            ? 10
            : 12,
        i: edge.node.id,
      };
    });
    return layout;
  };
  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { isPopUpVisible, isMorePopUpVisible } = this.state;
    if (popUpType === "CloseTag") {
      if (isPopUpVisible) {
        this.setState({
          isPopUpVisible: false,
        });
      } else {
        this.setState({
          isPopUpVisible: true,
          xOriginTagCrossPopUp: x,
          yOriginTagCrossPopUp: y,
          selectedCardForMutation: cardId,
        });
      }
    } else if (popUpType === "MoreInfo") {
      let selectedCard = null;
      const { cardFamilyData } = this.props;
      if (cardFamilyData.boardCardUse.id === cardId) {
        selectedCard = { node: cardFamilyData.boardCardUse };
      }
      cardFamilyData.boardCardUse.cardFamily.sections.edges.map(
        (section, index) => {
          return section.node.boardCardUses.edges.map((item, index) => {
            if (item.node.id === cardId) {
              selectedCard = item;
            }
            return selectedCard;
          });
        }
      );
      if (isMorePopUpVisible) {
        this.setState({
          isMorePopUpVisible: false,
        });
      } else {
        this.setState({
          isMorePopUpVisible: true,
          xOriginForMorePopUp: x,
          yOriginForMorePopUp: y,
          isToolTipVisible: false,
          selectedCardForMutation: cardId,
          selectedCard,
        });
      }
    }
  };

  // show  ShareCardPopUp
  handleShareCardPopUp = (x, y) => {
    const { isShareCardPopUpVisible } = this.state;
    if (isShareCardPopUpVisible === false) {
      this.setState({
        isShareCardPopUpVisible: true,
        isMorePopUpVisible: false,
        xOriginShareCardPopUp: x,
        yOriginShareCardPopUp: y,
      });
    }
  };
  handleEditHashTagPopUp = () => {
    this.setState({
      isEditHashTagPopUpVisible: true,
    });
  };

  //ReportErrorPopup
  handleReportErrorPopUp = (x, y) => {
    const { isReportErrorPopUpVisible } = this.state;
    if (isReportErrorPopUpVisible === false) {
      this.setState({
        xOriginReportErrorPopUp: x,
        yOriginReportErrorPopUp: y,
        isReportErrorPopUpVisible: true,
        isMorePopUpVisible: false,
      });
    }
  };

  copyCardToCollection = (x, y) => {
    const { isMorePopUpVisible } = this.state;
    const { match } = this.props;
    if (isMorePopUpVisible === true) {
      if (match.params.component === "story") {
        x = x + 100;
        y = y + 10;
      }
      this.setState({
        xOriginSaveCardsPopUp: x + 35,
        yOriginSaveCardsPopUp: y + 35,
        isSaveCardsPopUpVisible: true,
        isMorePopUpVisible: false,
        saveCardFromMore: true,
      });
    }
  };

  handleReRenderCard = (id) => {
    const { data } = this.state;
    let cardData = _.cloneDeep(data);
    let updatedEdges = [];
    if (cardData.boardCardUse.id === id) {
      cardData.boardCardUse.isUpdate = !cardData.boardCardUse.isUpdate;
    } else {
      cardData.boardCardUse.cardFamily.sections.edges.map((section, index) => {
        let sectionObject = _.cloneDeep(section);
        sectionObject.node.boardCardUses.edges.map((item, index) => {
          if (item.node.id === id) {
            item.isUpdate = !item.isUpdate;
          }
          return item;
        });
        updatedEdges.push(sectionObject);
        return updatedEdges;
      });
      cardData.boardCardUse.cardFamily.sections.edges = updatedEdges;
    }
    this.setState(
      {
        data: cardData,
      },
      () => {
        this.hidePopUp();
      }
    );
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId) => {
    const {
      isMorePopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isSaveCardsPopUpVisibleForHome,
      isReportErrorPopUpVisible,
      isEditHashTagPopUpVisible,
    } = this.state;

    if (isMorePopUpVisible) {
      this.setState({ isMorePopUpVisible: false, selectedCard: null });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isSaveCardsPopUpVisibleForHome) {
      this.setState({
        isSaveCardsPopUpVisibleForHome: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
  };

  HtmlParser = (data) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = data;
    return textArea.innerText;
  };

  render() {
    // const {cardFamilyData} = this.props
    const {
      data: cardFamilyData,
      xOriginForMorePopUp,
      yOriginForMorePopUp,
      isMorePopUpVisible,
      selectedCard,
      selectedCardForMutation,
      isSaveCardsPopUpVisible,
      xOriginSaveCardsPopUp,
      yOriginSaveCardsPopUp,
      saveCardFromMore,
      isShareCardPopUpVisible,
      xOriginShareCardPopUp,
      yOriginShareCardPopUp,
      isReportErrorPopUpVisible,
      xOriginReportErrorPopUp,
      yOriginReportErrorPopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      isEditHashTagPopUpVisible,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
    } = this.state;

    const {
      handleLoginPopUp,
      userData,
      changeCardHeight,
      handleSaveCardsPopUp,
      handleUpdateBoard,
      handleCloseUpView,
      handleSelectThemePopUp,
      handleArrayOfTagsOfCardObject,
      addRemoveFavourite,
      history,
      isSavedTooltip,
      openStoryPopup,
      openStoryPopupWithUrl,
    } = this.props;

    const publishedStoryBoardName =
      cardFamilyData && cardFamilyData.boardCardUse.configuration.title;
    return (
      <WideModalPopup
        goBack={this.closeCardFamilyPopup}
        className="MySavedAreasPopup"
        closePopUp={this.closeCardFamilyPopup}
      >
        <Fragment>
          <Helmet>
            <title>{publishedStoryBoardName}</title>
          </Helmet>
          <div
            className="mainDiv card-family position-relative"
            id="card-family"
          >
            <div>
              <MoreOptionPopup
                x={xOriginForMorePopUp}
                y={yOriginForMorePopUp}
                isVisible={isMorePopUpVisible}
                handleShareCardPopUp={this.handleShareCardPopUp}
                handleEditHashTagPopUp={this.handleEditHashTagPopUp}
                handleReportErrorPopUp={this.handleReportErrorPopUp}
                isHomeFeed={false}
                isFavorites={true}
                isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
                isCollections={false}
                copyCardToCollection={this.copyCardToCollection}
                removeCardFromCollections={this.removeCardFromCollections}
                cardId={selectedCardForMutation}
                handleReRenderCard={this.handleReRenderCard}
                handleLoginPopUp={handleLoginPopUp}
                userData={userData}
              />
              <SaveCardsPopUp
                isVisible={isSaveCardsPopUpVisible}
                x={xOriginSaveCardsPopUp}
                y={yOriginSaveCardsPopUp}
                cardId={selectedCardForMutation}
                hidePopUp={this.hidePopUp}
                isHomeFeed={true}
                saveCardFromMore={saveCardFromMore}
              />
              <ShareCardPopUp
                isVisible={isShareCardPopUpVisible}
                x={xOriginShareCardPopUp}
                y={yOriginShareCardPopUp}
                handleShareCardWithSocial={this.handleShareCardWithSocial}
                handleShareCardWithEmbedded={this.handleShareCardWithEmbedded}
              />
              <ReportErrorPopUp
                isVisible={isReportErrorPopUpVisible}
                x={xOriginReportErrorPopUp}
                y={yOriginReportErrorPopUp}
                hidePopUp={this.hidePopUp}
                cardId={selectedCardForMutation}
                showToolTip={this.showToolTip}
                hideToolTip={this.hideToolTip}
              />
            </div>
            <div className="card-family-container">
              {cardFamilyData &&
                cardFamilyData.boardCardUse.cardFamily.sections.edges.map(
                  (section, index) => {
                    // NOTE: Render each section of a user-published collection below
                    if (index === 0) {
                      // First section is wide card rendered above
                      // Here we follow it with master geotagger, card details, data download
                      return (
                        <div className="row wide-card-row" key={index}>
                          <div className="col-12 primary-wide-card">
                            {cardFamilyData.boardCardUse.frontFacingComponent
                              .componentType === "ChartCard" ? (
                              <WideChartCardContainer
                                data={{
                                  node: cardFamilyData.boardCardUse,
                                  isUpdate:
                                    cardFamilyData.boardCardUse.isUpdate,
                                }}
                                isHomeFeed={true}
                                storyCard={true}
                                showToolTip={this.showToolTip}
                                hideToolTip={this.hideToolTip}
                                {...this.props}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                handleShareCardPopUp={this.handleShareCardPopUp}
                              />
                            ) : (
                              <WideMapCardContainer
                                id={cardFamilyData.boardCardUse.id}
                                cardId={cardFamilyData.boardCardUse.id}
                                isHomeFeed={true}
                                storyCard={true}
                                data={{
                                  node: cardFamilyData.boardCardUse,
                                  isUpdate:
                                    cardFamilyData.boardCardUse.isUpdate,
                                }}
                                showToolTip={this.showToolTip}
                                hideToolTip={this.hideToolTip}
                                {...this.props}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                handleShareCardPopUp={this.handleShareCardPopUp}
                              />
                            )}
                          </div>
                          <div className="col-12">
                            {/*<div>Change <strong>Area Tag</strong> SAVE</div>*/}
                            {![
                              "DotMapCard",
                              "IndicatorMapCard",
                              "ServiceAreaMapCard",
                            ].includes(
                              cardFamilyData.boardCardUse.frontFacingComponent
                                .componentType
                            ) ? (
                              <CardDetailsText
                                data={{
                                  node: cardFamilyData.boardCardUse,
                                  isUpdate:
                                    cardFamilyData.boardCardUse.isUpdate,
                                }}
                                showToolTip={this.showToolTip}
                                hideToolTip={this.hideToolTip}
                                storyCard={true}
                                title={publishedStoryBoardName}
                                description={
                                  cardFamilyData.boardCardUse.configuration
                                    .detailsText
                                }
                                dataSourceDescription="The Area Deprivation Project provides the Area Deprivation Index which is calculated using U.S. Census Bureau American Community Surey Data 5-year estimates."
                                dataDownloadDescription={
                                  cardFamilyData.boardCardUse
                                    .dataDownloadDescription
                                }
                                {...this.props}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                handleShareCardPopUp={this.handleShareCardPopUp}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                    const sectionLayouts = this.buildAllSectionLayouts(
                      section.node.boardCardUses.edges
                    );
                    const sectionDescription = JSON.parse(
                      section.node.description
                    );
                    return (
                      <div key={index} className="card-family-section">
                        <div className="text_dis">
                          <h3 className="textbx-story-title">
                            {this.HtmlParser(section.node.name)}
                          </h3>
                          <Editor
                            placeholder=""
                            value={
                              sectionDescription == null
                                ? Value.create()
                                : Value.fromJSON(sectionDescription)
                            }
                            renderNode={this.renderNodeDesc}
                            renderMark={this.renderMark}
                            readOnly={true}
                          />
                        </div>
                        <div className="card-family-card-wrap">
                          <ResponsiveReactGridLayout
                            className="rgl-menu-closed"
                            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
                            breakpoints={{
                              lg: 1350,
                              md: 1050,
                              sm: 768,
                              xs: 480,
                              xxs: 0,
                            }}
                            rowHeight={30}
                            layouts={{
                              lg: sectionLayouts[3],
                              md: sectionLayouts[3],
                              sm: sectionLayouts[2],
                              xs: sectionLayouts[1],
                              xxs: sectionLayouts[0],
                            }}
                            onLayoutChange={this.handleLayoutChange}
                            onBreakpointChange={this.onBreakpointChange}
                            isDraggable={false}
                            isResizable={false}
                            {...this.props}
                          >
                            {section.node.boardCardUses.edges.map(
                              (item, index) => {
                                if (
                                  item.node.frontFacingComponent
                                    .componentType === "ReportCard"
                                ) {
                                  return (
                                    <div key={item.node.id}>
                                      <ReportCardContainer
                                        changeCardHeight={changeCardHeight}
                                        isEditingEnabled={false}
                                        isHomeFeed={true}
                                        isCloseUpView={false}
                                        index={index}
                                        key={item.node.id}
                                        data={item}
                                        hideUnHidePopUp={this.hideUnHidePopUp}
                                        showToolTip={this.showToolTip}
                                        hideToolTip={this.hideToolTip}
                                        onCloseUpView={this.handleCloseUpView}
                                        handleSelectThemePopUp={
                                          this.handleSelectThemePopUp
                                        }
                                        handleSaveCardsPopUp={
                                          handleSaveCardsPopUp
                                        }
                                        newTagsForCardObject={
                                          newTagsForCardObject
                                        }
                                        arrayOfTagsOfCardObject={
                                          arrayOfTagsOfCardObject
                                        }
                                        handleArrayOfTagsOfCardObject={
                                          this.handleArrayOfTagsOfCardObject
                                        }
                                        selectedCardForMutation={
                                          selectedCardForMutation
                                        }
                                        isEditHashTagPopUpVisible={
                                          isEditHashTagPopUpVisible
                                        }
                                        storyCard={true}
                                      />
                                    </div>
                                  );
                                } else if (
                                  item.node.frontFacingComponent
                                    .componentType === "AdiMapCard"
                                ) {
                                  return (
                                    <div key={item.node.id}>
                                      <AdiMapCardContainer
                                        changeCardHeight={changeCardHeight}
                                        isEditingEnabled={false}
                                        subComponentType={
                                          item.node.subComponentType
                                        }
                                        statistics={item.node.statistics}
                                        isHomeFeed={true}
                                        isCloseUpView={false}
                                        index={index}
                                        key={item.node.id}
                                        data={item}
                                        hideUnHidePopUp={this.hideUnHidePopUp}
                                        showToolTip={this.showToolTip}
                                        hideToolTip={this.hideToolTip}
                                        handleSelectThemePopUp={
                                          this.handleSelectThemePopUp
                                        }
                                        handleSaveCardsPopUp={
                                          handleSaveCardsPopUp
                                        }
                                        newTagsForCardObject={
                                          newTagsForCardObject
                                        }
                                        arrayOfTagsOfCardObject={
                                          arrayOfTagsOfCardObject
                                        }
                                        handleArrayOfTagsOfCardObject={
                                          this.handleArrayOfTagsOfCardObject
                                        }
                                        selectedCardForMutation={
                                          selectedCardForMutation
                                        }
                                        isEditHashTagPopUpVisible={
                                          isEditHashTagPopUpVisible
                                        }
                                        handleUpdateBoard={handleUpdateBoard}
                                        isMapVisible={true}
                                        userData={userData}
                                        storyCard={true}
                                      />
                                    </div>
                                  );
                                } else if (
                                  item.node.frontFacingComponent
                                    .componentType === "ServiceAreaMapCard"
                                ) {
                                  return (
                                    <div key={item.node.id}>
                                      <ServiceAreaMapCardContainer
                                        changeCardHeight={changeCardHeight}
                                        isEditingEnabled={false}
                                        subComponentType={
                                          item.node.subComponentType
                                        }
                                        statistics={item.node.statistics}
                                        isHomeFeed={true}
                                        isCloseUpView={false}
                                        index={index}
                                        key={item.node.id}
                                        data={item}
                                        hideUnHidePopUp={this.hideUnHidePopUp}
                                        showToolTip={this.showToolTip}
                                        hideToolTip={this.hideToolTip}
                                        handleSelectThemePopUp={
                                          this.handleSelectThemePopUp
                                        }
                                        handleSaveCardsPopUp={
                                          handleSaveCardsPopUp
                                        }
                                        newTagsForCardObject={
                                          newTagsForCardObject
                                        }
                                        arrayOfTagsOfCardObject={
                                          arrayOfTagsOfCardObject
                                        }
                                        handleArrayOfTagsOfCardObject={
                                          this.handleArrayOfTagsOfCardObject
                                        }
                                        selectedCardForMutation={
                                          selectedCardForMutation
                                        }
                                        isEditHashTagPopUpVisible={
                                          isEditHashTagPopUpVisible
                                        }
                                        handleUpdateBoard={handleUpdateBoard}
                                        isMapVisible={true}
                                        userData={userData}
                                        storyCard={true}
                                      />
                                    </div>
                                  );
                                } else if (
                                  item.node.frontFacingComponent
                                    .componentType === "ChartCard"
                                ) {
                                  const { subComponentType } = item.node;
                                  const reportToolCard =
                                    reportToolCardArray.indexOf(
                                      subComponentType
                                    ) > -1;
                                  if (reportToolCard) {
                                    return (
                                      <div
                                        key={item.node.id}
                                        className="grid-card"
                                      >
                                        <ReportToolCardContainer
                                          changeCardHeight={changeCardHeight}
                                          isEditingEnabled={false}
                                          subComponentType={
                                            item.node.subComponentType
                                          }
                                          statistics={item.node.statistics}
                                          isHomeFeed={true}
                                          isCloseUpView={false}
                                          index={index}
                                          key={item.node.id}
                                          data={item}
                                          hideUnHidePopUp={this.hideUnHidePopUp}
                                          showToolTip={this.showToolTip}
                                          hideToolTip={this.hideToolTip}
                                          onCloseUpView={handleCloseUpView}
                                          handleSelectThemePopUp={
                                            handleSelectThemePopUp
                                          }
                                          handleSaveCardsPopUp={
                                            handleSaveCardsPopUp
                                          }
                                          newTagsForCardObject={
                                            newTagsForCardObject
                                          }
                                          arrayOfTagsOfCardObject={
                                            arrayOfTagsOfCardObject
                                          }
                                          handleArrayOfTagsOfCardObject={
                                            handleArrayOfTagsOfCardObject
                                          }
                                          selectedCardForMutation={
                                            selectedCardForMutation
                                          }
                                          isEditHashTagPopUpVisible={
                                            isEditHashTagPopUpVisible
                                          }
                                          isMapcardsVisible={true}
                                          addRemoveFavourite={
                                            addRemoveFavourite
                                          }
                                          userData={userData}
                                          history={history}
                                          handleLoginPopUp={handleLoginPopUp}
                                          storyCard={true}
                                        />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={item.node.id}>
                                        <ChartCardContainer
                                          changeCardHeight={changeCardHeight}
                                          isEditingEnabled={false}
                                          subComponentType={
                                            item.node.subComponentType
                                          }
                                          statistics={item.node.statistics}
                                          isHomeFeed={true}
                                          isCloseUpView={false}
                                          index={index}
                                          key={item.node.id}
                                          data={item}
                                          hideUnHidePopUp={this.hideUnHidePopUp}
                                          showToolTip={this.showToolTip}
                                          hideToolTip={this.hideToolTip}
                                          onCloseUpView={handleCloseUpView}
                                          handleSelectThemePopUp={
                                            handleSelectThemePopUp
                                          }
                                          handleSaveCardsPopUp={
                                            handleSaveCardsPopUp
                                          }
                                          newTagsForCardObject={
                                            newTagsForCardObject
                                          }
                                          arrayOfTagsOfCardObject={
                                            arrayOfTagsOfCardObject
                                          }
                                          handleArrayOfTagsOfCardObject={
                                            handleArrayOfTagsOfCardObject
                                          }
                                          selectedCardForMutation={
                                            selectedCardForMutation
                                          }
                                          isEditHashTagPopUpVisible={
                                            isEditHashTagPopUpVisible
                                          }
                                          isMapcardsVisible={true}
                                          addRemoveFavourite={
                                            addRemoveFavourite
                                          }
                                          userData={userData}
                                          history={history}
                                          handleLoginPopUp={handleLoginPopUp}
                                          storyCard={true}
                                        />
                                      </div>
                                    );
                                  }
                                } else if (
                                  item.node.frontFacingComponent
                                    .componentType === "MapCard"
                                ) {
                                  // else if (true) {
                                  return (
                                    <div key={item.node.id}>
                                      <StoryCardContainer
                                        changeCardHeight={changeCardHeight}
                                        isEditingEnabled={false}
                                        isHomeFeed={true}
                                        isCloseUpView={false}
                                        index={index}
                                        key={item.node.id}
                                        data={item}
                                        hideUnHidePopUp={this.hideUnHidePopUp}
                                        showToolTip={this.showToolTip}
                                        hideToolTip={this.hideToolTip}
                                        onCloseUpView={handleCloseUpView}
                                        handleSelectThemePopUp={
                                          handleSelectThemePopUp
                                        }
                                        handleSaveCardsPopUp={
                                          handleSaveCardsPopUp
                                        }
                                        newTagsForCardObject={
                                          newTagsForCardObject
                                        }
                                        arrayOfTagsOfCardObject={
                                          arrayOfTagsOfCardObject
                                        }
                                        handleArrayOfTagsOfCardObject={
                                          handleArrayOfTagsOfCardObject
                                        }
                                        selectedCardForMutation={
                                          selectedCardForMutation
                                        }
                                        isEditHashTagPopUpVisible={
                                          isEditHashTagPopUpVisible
                                        }
                                        isMapcardsVisible={true}
                                        addRemoveFavourite={addRemoveFavourite}
                                        userData={userData}
                                        handleLoginPopUp={handleLoginPopUp}
                                        isSavedToolTip={isSavedTooltip}
                                        storyCard={true}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={item.node.id}>
                                      <StoryCardContainer
                                        changeCardHeight={changeCardHeight}
                                        isEditingEnabled={false}
                                        isHomeFeed={true}
                                        isCloseUpView={false}
                                        index={index}
                                        key={item.node.id}
                                        data={item}
                                        hideUnHidePopUp={this.hideUnHidePopUp}
                                        showToolTip={this.showToolTip}
                                        hideToolTip={this.hideToolTip}
                                        onCloseUpView={this.handleCloseUpView}
                                        handleSelectThemePopUp={
                                          this.handleSelectThemePopUp
                                        }
                                        handleSaveCardsPopUp={
                                          handleSaveCardsPopUp
                                        }
                                        newTagsForCardObject={
                                          newTagsForCardObject
                                        }
                                        arrayOfTagsOfCardObject={
                                          arrayOfTagsOfCardObject
                                        }
                                        handleArrayOfTagsOfCardObject={
                                          this.handleArrayOfTagsOfCardObject
                                        }
                                        selectedCardForMutation={
                                          selectedCardForMutation
                                        }
                                        isEditHashTagPopUpVisible={
                                          isEditHashTagPopUpVisible
                                        }
                                        openStoryPopup={openStoryPopup}
                                        openStoryPopupWithUrl={
                                          openStoryPopupWithUrl
                                        }
                                        isSavedToolTip={isSavedTooltip}
                                        storyCard={true}
                                      />
                                    </div>
                                  );
                                }
                              }
                            )}
                          </ResponsiveReactGridLayout>
                        </div>
                      </div>
                    );
                  }
                )}
              <ToolTip
                x={xOrigin}
                y={yOrigin}
                isVisible={isToolTipVisible}
                text={text}
              />
            </div>
            <FooterComponent />
          </div>
        </Fragment>
      </WideModalPopup>
    );
  }
}

export default withRouter(CardFamily);
