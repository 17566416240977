import React, { Component } from "react";
import WideChartCardContainer from "../Card/WideChartCardContainer";
import WideMapCardContainer from "../Card/WideMapCardContainer";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import "../../css/components/Card/CardFamily.css";
import "../../css/components/CommonComponents/WideCardPopup.css";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";
import { apiBaseUrl, reportToolCardArray } from "../../utility/utilities";

import * as _ from "lodash";
import { WideModalPopup, ToolTip, SelectAreaPopup } from "common-components";

class WideCardPopup extends Component {
  render() {
    const { cardId } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query WideCardPopupQuery($input: ID!) {
            boardCardUse(id: $input) {
              id
              jsonid
              additionalConfiguration {
                jsonProperties
              }
              adiComponentTable
              dataDownloadDescription
              frontFacingComponent {
                componentType
              }
              isFavorite
              subComponentType
              cardFamily {
                id
                allowCopy
                name
                subtitle
                sections {
                  edges {
                    node {
                      boardCardUses {
                        edges {
                          node {
                            id
                            cardPosition
                            frontFacingComponent {
                              componentType
                              name
                            }
                            statistics {
                              id
                              jsonData
                            }
                            isFavorite
                            subComponentType
                          }
                        }
                      }
                      id
                      name
                      description
                      layout
                      sectionPosition
                    }
                  }
                }
                theme {
                  jsonStyleProperties
                }
              }
              configuration {
                appreciates
                author {
                  id
                  briefBio
                  contactInfo
                  name
                  profileImage {
                    url
                  }
                }
                citation
                collaborators {
                  edges {
                    node {
                      id
                      briefBio
                      contactInfo
                      name
                      profileImage {
                        url
                      }
                    }
                  }
                }
                detailsText
                featureImage {
                  caption
                  url
                }
                permaLink
                remoteSyncCode
                savedArea {
                  id
                  jsonid
                  name
                }
                storyLink
                storyPublishedDate
                storyReadTime
                storyText
                storyUpdatedDate
                title
                toolLink
              }
              isFavorite
              sharingStatus
              tags {
                edges {
                  node {
                    boardTag {
                      name
                      permaTag
                    }
                  }
                }
              }
              boardSection {
                board {
                  id
                  isCardFamily
                  isPublishedStory
                  name
                  publicFeedStatus
                  publishedStoryCard {
                    id
                  }
                }
              }
            }
          }
        `}
        variables={{ input: unescape(cardId) }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          return <WideCardPopupInner wideCardData={props} {...this.props} />;
        }}
      />
    );
  }
}

class WideCardPopupInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      selectedCard: null,
    };
  }

  getLinkForToolcard = (subComponentType) => {
    const { cardId } = this.props;
    let link;
    switch (subComponentType) {
      case 'suicidePreventionToolCard':
        link = '/choosearea/suiprev';
        break;
      case 'preventingViolenceToolCard':
        link = '/choosearea/violence';
        break;
      case 'stiToolCard':
        link = '/choosearea/sti';
        break;
      case "infantMortalityToolCard":
        link = "/choosearea/infantmortality";
        break;
      case "covid19ToolCard":
        link = "/choosearea/covid19";
        break;
      case "acesToolCard":
        link = "/choosearea/aces";
        break;
      case "demographicsToolCard":
        link = "/choosearea/demographics";
        break;
      case "foodAssessmentCard":
        link = "/choosearea/foodassessment";
        break;
      case "mortalityToolCard":
        link = "/choosearea/mortality";
        break;
      case "areaDeprivationIndexCard":
        link = "/choosearea/areadeprivationindex";
        break;
      case "communityIndicatorReportCard":
        link = "/choosearea/communityindicatoreport";
        break;
      case "makeAMapCard":
      case "createNewStory":
        link = "/choosearea/makeamap";
        break;
      case "browseIndicatorsCard":
        link = "/browseindicators";
        break;
      case "ToolCard":
        link = "/choosearea/user" + cardId;
        break;
      default:
        link = null;
        break;
    }
    return link;
  };

  componentDidMount() {
    const { wideCardData } = this.props;
    let data = _.cloneDeep(wideCardData);
    const { history } = this.props;
    if (data) {
      if (data.boardCardUse) {
        const {
          boardCardUse: {
            subComponentType,
            configuration: { title },
          },
        } = data;
        if (reportToolCardArray.indexOf(subComponentType) > -1) {
          let link = this.getLinkForToolcard(subComponentType);
          let popupType = "RUNAREPORT";
          if (subComponentType === "makeAMapCard" && title === "Map Room") {
            link = apiBaseUrl + "map/";
            popupType = "map-room";
          } else if (subComponentType === "makeAMapCard") {
            link = "/choosearea/makeamap";
            popupType = "EXPLOREMAPS";
          } else if (subComponentType === "browseIndicatorsCard") {
            popupType = "browseindicators";
          }
          const state = {
            closeURL: "/",
            popupType: popupType,
          };
          if (popupType === "map-room") {
            window.open(link, "_self");
          } else {
            if (link) {
              history.push(link, state);
            }
          }
        }
      }
      let updatedEdges = [];
      if (data && data.boardCardUse) {
        data.boardCardUse.isUpdate = false;
        data.boardCardUse.cardFamily &&
          data.boardCardUse.cardFamily.sections &&
          data.boardCardUse.cardFamily.sections.edges.map((section, index) => {
            let sectionObject = _.cloneDeep(section);
            sectionObject.node.boardCardUses.edges.map((item, index) => {
              item.isUpdate = false;
              return item;
            });
            updatedEdges.push(sectionObject);
            return updatedEdges;
          });
      }
      if (
        data &&
        data.boardCardUse &&
        data.boardCardUse.cardFamily &&
        data.boardCardUse.cardFamily.sections
      ) {
        data.boardCardUse.cardFamily.sections.edges = updatedEdges;
      }
    }
    this.setState({
      data,
    });
  }

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({ isToolTipVisible: false });
  };

  closeWideCardPopup = () => {
    const { wideCardData, closeWideCardPopup } = this.props;
    var parentCollectionLocation = null;
    const col = wideCardData.boardCardUse.boardSection.board;
    if (col.publicFeedStatus === 1 && col.name === "My Favorites") {
      parentCollectionLocation = "/FAVORITES";
    } else if (
      col.isPublishedStory === true &&
      col.publishedStoryCard != null
    ) {
      parentCollectionLocation = `/pubboard/${escape(
        col.publishedStoryCard.id
      )}`;
    }
    closeWideCardPopup(parentCollectionLocation);
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h:
          edge.node.frontFacingComponent.componentType === "ReportCard"
            ? 10
            : 12,
        i: edge.node.id,
      };
    });
    return layout;
  };
  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { isPopUpVisible } = this.state;
    if (popUpType === "CloseTag") {
      if (isPopUpVisible) {
        this.setState({
          isPopUpVisible: false,
        });
      } else {
        this.setState({
          isPopUpVisible: true,
          xOriginTagCrossPopUp: x,
          yOriginTagCrossPopUp: y - 250,
          selectedCardForMutation: cardId,
        });
      }
    } else if (popUpType === "MoreInfo") {
      let selectedCard = null;
      const { wideCardData } = this.props;
      if (wideCardData.boardCardUse.id === cardId) {
        selectedCard = { node: wideCardData.boardCardUse };
      }
      wideCardData.boardCardUse.cardFamily.sections.edges.map(
        (section, index) => {
          return section.node.boardCardUses.edges.map((item, index) => {
            if (item.node.id === cardId) {
              selectedCard = item;
            }
            return selectedCard;
          });
        }
      );
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
        selectedCard,
      });
    }
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const { isSaveCardsPopUpVisible } = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard: this.getSelectedCard(cardId),
      });
    }
  };

  handleReRenderCard = (id) => {
    const { isFavorites, handleReRenderCard } = this.props;
    const { data } = this.state;
    let cardData = _.cloneDeep(data);
    if (isFavorites) {
      if (handleReRenderCard && typeof handleReRenderCard === "function") {
        handleReRenderCard();
      }
    } else {
      let updatedEdges = [];
      if (cardData.boardCardUse.id === id) {
        cardData.boardCardUse.isUpdate = !cardData.boardCardUse.isUpdate;
      } else {
        cardData.boardCardUse.cardFamily.sections.edges.map(
          (section, index) => {
            let sectionObject = _.cloneDeep(section);
            sectionObject.node.boardCardUses.edges.map((item, index) => {
              if (item.node.id === id) {
                item.isUpdate = !item.isUpdate;
              }
              return item;
            });
            updatedEdges.push(sectionObject);
            return updatedEdges;
          }
        );
        cardData.boardCardUse.cardFamily.sections.edges = updatedEdges;
      }
    }
    this.setState(
      {
        data: cardData,
      },
      () => {
        this.hidePopUp();
        if (handleReRenderCard) {
          handleReRenderCard(id);
        }
      }
    );
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isSaveCardsPopUpVisibleForHome,
      isPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isSaveCardsPopUpVisibleForHome) {
      this.setState({
        isSaveCardsPopUpVisibleForHome: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
  };

  getSelectedCard = (cardId) => {
    const { wideCardData } = this.props;
    let selectedCard = null;
    if (wideCardData && wideCardData.boardCardUse) {
      const item = wideCardData.boardCardUse;
      if (item.id === cardId) {
        selectedCard = item;
      }
    }
    return selectedCard;
  };

  onCardButtonClick = (cardId) => {
    this.setState({
      showCardShareModal: false,
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId),
    });
  };

  onCloseCardButtonModal = () => {
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
  };

  render() {
    const { showFullPage } = this.props;
    const {
      data: wideCardData,
      showCardButtonModal,
      selectedCard,
      selectedCardForMutation,
      isSaveCardsPopUpVisible,
      yOriginTagCrossPopUp,
      xOriginTagCrossPopUp,
      isPopUpVisible,
      isToolTipVisible,
      text,
      xOrigin,
      yOrigin,
    } = this.state;
    const {
      showTwitterCardPopup,
      handleTwitterCardPopup,
      publishedStoryId,
      handleLoginPopUp,
      userData,
      location,
      history,
      match,
    } = this.props;
    const id =
      wideCardData &&
      wideCardData.boardCardUse &&
      wideCardData.boardCardUse.id &&
      wideCardData.boardCardUse.id.replace(/=/g, "");
    const mainContent = (
      <div
        className="mainDiv card-family position-relative wideCardPopup mt-md-auto mt-0"
        id={id}
        onClick={() => this.hidePopUp()}
      >
        <div>
          {isPopUpVisible === true ? (
            <SelectAreaPopup
              hidePopUp={this.hidePopUp}
              isVisible={isPopUpVisible}
              x={xOriginTagCrossPopUp}
              y={yOriginTagCrossPopUp}
              onCardButtonClick={this.onCardButtonClick}
              selectedCardId={selectedCardForMutation}
              handleReRenderCard={this.handleReRenderCard}
              userData={userData}
              location={location}
              history={history}
              match={match}
              showLogin={handleLoginPopUp}
            />
          ) : null}
        </div>
        <div className="card-family-container h-100 p-0 m-0 w-100">
          {wideCardData &&
            wideCardData.boardCardUse &&
            wideCardData.boardCardUse.cardFamily &&
            wideCardData.boardCardUse.cardFamily.sections.edges.map(
              (section, index) => {
                return (
                  index === 0 && (
                    <div className="row wide-card-row h-100 mx-0" key={index}>
                      <div className="col-12 primary-wide-card h-100 px-0">
                        {wideCardData.boardCardUse.frontFacingComponent
                          .componentType === "ChartCard" ? (
                          <WideChartCardContainer
                            data={{
                              node: wideCardData.boardCardUse,
                              isUpdate: wideCardData.boardCardUse.isUpdate,
                            }}
                            isHomeFeed={false}
                            storyCard={true}
                            showToolTip={this.showToolTip}
                            hideToolTip={this.hideToolTip}
                            {...this.props}
                            wideCardData={wideCardData}
                            onCardButtonClick={this.onCardButtonClick}
                            hideUnHidePopUp={this.hideUnHidePopUp}
                            handleShareCardPopUp={this.handleShareCardPopUp}
                            handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          />
                        ) : (
                          <WideMapCardContainer
                            id={wideCardData.boardCardUse.id}
                            cardId={wideCardData.boardCardUse.id}
                            isHomeFeed={false}
                            storyCard={true}
                            data={{
                              node: wideCardData.boardCardUse,
                              isUpdate: wideCardData.boardCardUse.isUpdate,
                            }}
                            showToolTip={this.showToolTip}
                            hideToolTip={this.hideToolTip}
                            {...this.props}
                            downloadWideVersion={showTwitterCardPopup}
                            wideCardData={wideCardData}
                            onCardButtonClick={this.onCardButtonClick}
                            hideUnHidePopUp={this.hideUnHidePopUp}
                            handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          />
                        )}
                      </div>
                    </div>
                  )
                );
              }
            )}

          <ToolTip
            x={xOrigin}
            y={yOrigin}
            isVisible={isToolTipVisible}
            text={text}
          />
        </div>
        {showCardButtonModal || isSaveCardsPopUpVisible ? (
          <CardButtonsPopUp
            history={history}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleReRenderCard={this.handleReRenderCard}
            displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
            handleTwitterCardPopup={handleTwitterCardPopup}
            isFavorites={true}
            isCollections={false}
            publishedStoryId={publishedStoryId}
            isWideCard={true}
            selectedCard={selectedCard}
            isFavorite={selectedCard ? selectedCard.isFavorite : false}
            cardId={selectedCardForMutation}
            closePopUp={this.onCloseCardButtonModal}
            showFullPage={showFullPage}
          />
        ) : null}
      </div>
    );
    return (
      <>
        {showFullPage ? (
          <div
            className={
              (!showTwitterCardPopup ? "position-absolute" : "") +
              " w-100 h-100"
            }
          >
            {mainContent}
          </div>
        ) : (
          <WideModalPopup
            goBack={this.closeWideCardPopup}
            closePopUp={this.closeWideCardPopup}
          >
            {mainContent}
          </WideModalPopup>
        )}
      </>
    );
  }
}

export default WideCardPopup;
