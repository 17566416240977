// Story is a host to sections of cards as a published collection
//   so it has the same top-level components and handlers for
//   custom tooltips and card popups as HomeFeed and ProjectBoard components
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import moment from "moment";
import ReactDOM from "react-dom";
import FooterComponent from "../CommonComponents/FooterComponent";
import { ToolTip } from "common-components";
import { Value } from "slate";
import { Editor } from "slate-react";
import StoryCardContainer from "../Card/StoryCardContainer";
import ReportCardContainer from "../Card/ReportCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import IndicatorMapCardContainer from "../Card/IndicatorMapCardContainer";
import DotMapCardContainer from "../Card/DotMapCardContainer";
import { Responsive } from "react-grid-layout";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import "../../css/components/Story/Story.css";
import CardBottom from "../Card/CardBottom";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import * as _ from "lodash";
import ShareStoryPopUp from "../CommonComponents/ShareStoryPopUp";
import StorySettingsPopUp from "../CommonComponents/StorySettingsPopUp";
import Error404 from "../Error404/Error404";
import {
  apiBaseUrl,
  appBaseUrl,
  reportToolCardArray,
} from "../../utility/utilities";
import WideCardPopup from "../CommonComponents/WideCardPopup";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const editImg = require("../../images/iconEdit.svg");
const shareImg = require("../../images/iconShare.svg");
const placeHolderBannerImage = require("../../images/uni-banner.png");
const placeHolderImage = require("../../images/uni-square.png");
const placeHolderFeaturedImage = require("../../images/uni-featured.png");

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateBoard: false, // Change this to call the board list api
      refreshPage: false, //
      isStoryEditing: false,
    };
  }

  handleUpdateBoard = (refreshPage = false) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: !prevState.isUpdateBoard,
        refreshPage,
      };
    });
  };

  render() {
    const { refreshPage, isUpdateBoard } = this.state;
    const { pageUrl, history, location, storyId } = this.props;
    /* Load published story from custom page url or card id*/
    if (pageUrl != null) {
      return (
        <QueryRenderer
          dataFrom={refreshPage ? "STORE_THEN_NETWORK" : ""}
          environment={environment}
          query={graphql`
            query StoryPageQuery($input: String!) {
              page(permaLink: $input) {
                id
                sourceBoard {
                  publishedStoryCard {
                    id
                    additionalConfiguration {
                      jsonProperties
                    }
                    configuration {
                      appreciates
                      author {
                        id
                        briefBio
                        contactInfo
                        name
                        userAccount {
                          email
                          username
                        }
                        profileImage {
                          url
                        }
                      }
                      citation
                      collaborators {
                        edges {
                          node {
                            id
                            name
                            userAccount {
                              email
                              visited {
                                edges {
                                  node {
                                    created
                                    lastModified
                                  }
                                }
                              }
                            }
                            profileImage {
                              id
                              caption
                              url
                            }
                          }
                        }
                      }
                      featureImage {
                        caption
                        url
                      }
                      permaLink
                      remoteSyncCode
                      savedArea {
                        id
                        jsonid
                        name
                      }
                      storyLink
                      storyPublishedDate
                      storyReadTime
                      storyText
                      storyUpdatedDate
                      title
                      toolLink
                      publishedStoryBoard {
                        id
                        allowCopy
                        name
                        subtitle
                        hasEditPermission
                        buttonColor
                        headerColor
                        headerImage {
                          id
                          url
                        }
                        faviconImage {
                          id
                          url
                        }
                        invitationLink
                        logoLink
                        sourceBoard {
                          id
                          name
                          invitationLinkRole
                          collaboratorRole
                          publishAsPublic
                          publishOutsidePaywall
                          allowCopy
                          invitationLink
                          boardType
                          invitations {
                            edges {
                              node {
                                email
                              }
                            }
                          }
                        }
                        privateLinkCollaborators {
                          edges {
                            node {
                              id
                              briefBio
                              contactInfo
                              name
                              userAccount {
                                email
                                visited {
                                  edges {
                                    node {
                                      created
                                      lastModified
                                    }
                                  }
                                }
                              }
                              profileImage {
                                url
                              }
                            }
                          }
                        }
                        sections {
                          edges {
                            node {
                              boardCardUses {
                                edges {
                                  node {
                                    id
                                    cardPosition
                                    frontFacingComponent {
                                      componentType
                                      name
                                    }
                                    statistics {
                                      id
                                      jsonData
                                    }
                                    isFavorite
                                    subComponentType
                                  }
                                }
                              }
                              id
                              name
                              description
                              layout
                              sectionPosition
                              image1 {
                                id
                                url
                              }
                              sectionLayout
                            }
                          }
                        }
                        theme {
                          jsonStyleProperties
                        }
                      }
                    }
                    isFavorite
                    sharingStatus
                    tags {
                      edges {
                        node {
                          boardTag {
                            name
                            permaTag
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          variables={{
            input: unescape(pageUrl),
            isUpdateBoard: isUpdateBoard,
          }}
          render={({ error, props }) => {
            if (error) {
              console.error(error);
              return <div>Error!</div>;
            }
            if (!props) {
              return <div>Loading...</div>;
            }
            let storyData = null;
            if (props && props.page && props.page.sourceBoard) {
              storyData = {
                boardCardUse: props.page.sourceBoard.publishedStoryCard,
              };
            }
            /*
            try {
            }
            catch(x) {
                storyData = null;
                console.log("storyData not found");
            }
            */
            if (
              storyData == null ||
              storyData.boardCardUse == null ||
              storyData.boardCardUse.configuration.publishedStoryBoard == null
            ) {
              return <Error404 history={history} />;
            }
            // NOTE: React Relay stupidly suppresses GraphQL errors so workaround
            if (
              "ERROR: Log in before contributing" ===
              storyData.boardCardUse.configuration.publishedStoryBoard.name
            ) {
              // prompt login
              let requestedPage = location.pathname;
              console.log("access denied to location", requestedPage);
              return (
                <Redirect
                  to={{
                    pathname: "/signin",
                    state: { requestedPage: requestedPage },
                  }}
                />
              );
            }
            if (
              "ERROR: You are not an invited collaborator on this story" ===
              storyData.boardCardUse.configuration.publishedStoryBoard.name
            ) {
              // TODO: Direct to ask permission or sign in to another account
              return (
                <>
                  <div>
                    You are not an invited collaborator on this story. Sign in
                    as an invited user to see it.
                  </div>
                  <div>
                    <a href="/board/">Return to Home</a>
                  </div>
                </>
              );
            }
            return (
              <StoryInner
                storyData={storyData}
                handleUpdateBoard={this.handleUpdateBoard}
                {...this.props}
              ></StoryInner>
            );
          }}
        />
      );
    }
    return (
      <QueryRenderer
        dataFrom={refreshPage ? "STORE_THEN_NETWORK" : ""}
        environment={environment}
        query={graphql`
          query StoryQuery($input: ID!) {
            boardCardUse(id: $input) {
              id
              additionalConfiguration {
                jsonProperties
                id
              }
              configuration {
                appreciates
                author {
                  id
                  briefBio
                  contactInfo
                  name
                  userAccount {
                    email
                    username
                    id
                  }
                  profileImage {
                    url
                    id
                  }
                }
                citation
                collaborators {
                  edges {
                    node {
                      id
                      name
                      userAccount {
                        email
                        id
                      }
                      profileImage {
                        id
                        caption
                        url
                      }
                    }
                  }
                }
                featureImage {
                  caption
                  url
                  id
                }
                permaLink
                remoteSyncCode
                savedArea {
                  id
                  jsonid
                  name
                }
                storyLink
                storyPublishedDate
                storyReadTime
                storyText
                storyUpdatedDate
                title
                toolLink
                publishedStoryBoard {
                  id
                  allowCopy
                  name
                  subtitle
                  hasEditPermission
                  buttonColor
                  headerColor
                  headerImage {
                    id
                    url
                  }
                  faviconImage {
                    id
                    url
                  }
                  invitationLink
                  logoLink
                  sourceBoard {
                    id
                    name
                    invitationLinkRole
                    collaboratorRole
                    invitationLink
                    allowCopy
                    publishAsPublic
                    permaLink
                    publishOutsidePaywall
                    boardType
                    invitations {
                      edges {
                        node {
                          email
                          id
                        }
                      }
                    }
                  }
                  collaboratorsVisited {
                    edges {
                      node {
                        id
                        collaboratorId
                        email
                        visited
                      }
                    }
                  }
                  privateLinkCollaborators {
                    edges {
                      node {
                        id
                        briefBio
                        contactInfo
                        name
                        userAccount {
                          email
                          id
                        }
                        profileImage {
                          url
                          id
                        }
                      }
                    }
                  }
                  sections {
                    edges {
                      node {
                        boardCardUses {
                          edges {
                            node {
                              id
                              cardPosition
                              frontFacingComponent {
                                componentType
                                name
                                id
                              }
                              statistics {
                                id
                                jsonData
                              }
                              isFavorite
                              subComponentType
                            }
                          }
                        }
                        id
                        name
                        description
                        layout
                        sectionPosition
                        image1 {
                          id
                          url
                        }
                        sectionLayout
                      }
                    }
                  }
                  theme {
                    jsonStyleProperties
                    id
                  }
                }
                id
              }
              isFavorite
              sharingStatus
              tags {
                edges {
                  node {
                    boardTag {
                      name
                      permaTag
                      id
                    }
                    id
                  }
                }
              }
            }
          }
        `}
        variables={{
          input: unescape(storyId),
          isUpdateBoard: isUpdateBoard,
        }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          if (
            props.boardCardUse == null ||
            props.boardCardUse.configuration.publishedStoryBoard == null
          ) {
            return <Error404 history={history} />;
          }
          const boardName =
            props.boardCardUse.configuration.publishedStoryBoard.name;
          // NOTE: React Relay stupidly suppresses GraphQL errors so workaround
          if ("ERROR: Log in before contributing" === boardName) {
            // prompt login
            let requestedPage = location.pathname;
            console.log("access denied to location", requestedPage);
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { requestedPage: requestedPage },
                }}
              />
            );
          }
          if (
            "ERROR: You are not an invited collaborator on this story" ===
            boardName
          ) {
            // TODO: Direct to ask permission or sign in to another account
            return (
              <>
                <div>
                  You are not an invited collaborator on this story. Sign in as
                  an invited user to see it.
                </div>
                <div>
                  <a href="/board/">Return to Home</a>
                </div>
              </>
            );
          }
          return (
            <StoryInner
              storyData={props}
              handleUpdateBoard={this.handleUpdateBoard}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

class StoryInner extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: props.userData.currentUser.edges.length === 0 ? false : true,
      perPage: 10,
      layout: [],

      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      xOriginSaveCardsPopUp: 0,
      yOriginSaveCardsPopUp: 0,
      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isFavourite:
        props.storyData &&
        props.storyData.boardCardUse &&
        props.storyData.boardCardUse.isFavorite,
      selectedData: null,
      data: [],
      showShareModal: false,
      showCardButtonModal: false,
      showSettingsModal: false,
      existingCollab: [],
      existingInvitations: [],
      privateLinkCollaborators: [],
    };
  }

  componentDidMount() {
    let data = [];
    const { wideCardId, storyData } = this.props;
    // open widecard if card id is in URL
    if (wideCardId) {
      this.setState({
        showWideCardPopup: true,
        cardId: wideCardId,
      });
    }

    const isWordpressStory =
      storyData.boardCardUse.configuration.remoteSyncCode === null
        ? false
        : true;

    if (
      !isWordpressStory &&
      storyData.boardCardUse.configuration.publishedStoryBoard != null
    ) {
      storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges.forEach(
        (section, index) => {
          let sectionObject = _.cloneDeep(section);
          sectionObject.node.boardCardUses.edges =
            sectionObject.node.boardCardUses.edges.map((item, index) => {
              let mutableObject = Object.assign({}, item);
              mutableObject.isUpdate = false;
              return mutableObject;
            });
          data.push(sectionObject);
        }
      );
      this.setState({
        data,
      });
    }

    const { publishedStoryBoard, collaborators } =
      storyData.boardCardUse.configuration;
    let colabArray = [];
    let existingInvitations = [];
    let duplicateCheckEmail = [];
    let privateLinkCollaborators = [];

    duplicateCheckEmail.push(
      storyData.boardCardUse.configuration.author.userAccount.email
    );

    collaborators.edges.map((object, index) => {
      colabArray.push(object.node);
      duplicateCheckEmail.push(object.node.userAccount.email);
      return colabArray && duplicateCheckEmail;
    });
    if (publishedStoryBoard) {
      publishedStoryBoard.sourceBoard.invitations.edges.map((object, index) => {
        let collabNoSearch = {
          id: object.node.id,
          name: null,
          userAccount: {
            email: object.node.email,
          },
          profileImage: {
            url: require("../../images/favicon1.svg"),
          },
        };
        existingInvitations.push(collabNoSearch);
        duplicateCheckEmail.push(object.node.email);
        return existingInvitations && duplicateCheckEmail;
      });
      publishedStoryBoard.privateLinkCollaborators.edges.map(
        (object, index) => {
          privateLinkCollaborators.push(
            Object.assign({ privateLinkCollaborator: true }, object.node)
          );
          return privateLinkCollaborators;
        }
      );
      this.setState({
        existingCollab: colabArray,
        existingInvitations,
        duplicateCheckArr: duplicateCheckEmail,
        privateLinkCollaborators,
        visitedCollaborators: publishedStoryBoard.collaboratorsVisited?.edges,
      });
    }

    window.name = "bsview-" + storyData.boardCardUse.id;
  }

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { storyData } = this.props;
    const { isPopUpVisible } = this.state;
    if (popUpType === "CloseTag") {
      if (isPopUpVisible) {
        this.setState({
          isPopUpVisible: false,
        });
      } else {
          this.props.setCardId(cardId);
        this.setState({
          isPopUpVisible: true,
          xOriginTagCrossPopUp: x,
          yOriginTagCrossPopUp: y,
          selectedCardForMutation: cardId,
        });
      }
    } else if (popUpType === "MoreInfo") {
      let selectedCard = null;
      const isWordpressStory =
        storyData.boardCardUse.configuration.remoteSyncCode === null
          ? false
          : true;

      if (!isWordpressStory) {
        storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges.forEach(
          (section, index) => {
            section.node.boardCardUses.edges.map((item, index) => {
              if (item.node.id === cardId) {
                selectedCard = item;
              }
              return selectedCard;
            });
          }
        );
      }
      this.props.setCardId(cardId);
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
        selectedCard,
      });
    }
  };

  scrollToCollaborators = () => {
    const box = ReactDOM.findDOMNode(
      this.refs["collaborators"]
    ).getBoundingClientRect();
    var body = document.body;
    var docEl = document.documentElement;
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var clientTop = docEl.clientTop || body.clientTop || 0;
    var top = box.top + scrollTop - clientTop;
    window.scrollTo({
      top: top,
      behaviour: "smooth",
    });
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedTooltip: false,
      savedCardId: "",
    });
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  renderCollaborators = (collaboratorsArray) => {
    const { storyData } = this.props;
    var arrayOfCollabs = [];
    const { publishedStoryBoard } = storyData.boardCardUse.configuration;
    const hyperlinkColor = { color: publishedStoryBoard.buttonColor };
    const arrayLength = collaboratorsArray.length;
    collaboratorsArray.map((collabObj, index) => {
      /*
      if (arrayLength > 2 && index === arrayLength - 2) {
        arrayOfCollabs.push('and');
      }
      */
      if (
        index < 2 &&
        collabObj.node.name != null &&
        collabObj.node.name !== "" &&
        collabObj.node.name !== " "
      ) {
        arrayOfCollabs.push(
          <a key={index} href="#collab" style={hyperlinkColor}>
            {" "}
            {collabObj.node.name}
            {index < 1 && arrayLength !== 1 ? "," : ""}
          </a>
        );
      } else if (
        index === 2 &&
        collabObj.node.name != null &&
        collabObj.node.name !== "" &&
        collabObj.node.name !== " "
      ) {
        arrayOfCollabs.push(<br key={"br" + index} />);
        arrayOfCollabs.push(
          <a key={index} href="#collab" style={hyperlinkColor}>
            {collabObj.node.name}
          </a>
        );
      }
      if (index > 2 && arrayLength === index + 1) {
        arrayOfCollabs.push(" and ");
        arrayOfCollabs.push(
          <u key="Others" onClick={this.scrollToCollaborators}>
            Others
          </u>
        );
      }
      return arrayOfCollabs;
    });
    if (arrayLength === 0) {
      return <p />;
    } else {
      return (
        <p className={"fs-15"}>
          {" "}
          in collaboration with
          {arrayOfCollabs.map((element) => {
            return element;
          })}
        </p>
      );
    }
  };

  renderNodeDesc = (props) => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case "block-quote":
        return <blockquote {...attributes}>{children}</blockquote>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "heading-one":
        return <h1 {...attributes}>{children}</h1>;
      case "heading-two":
        return <h2 {...attributes}>{children}</h2>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return (
          <ul>
            <ol {...attributes}>{children}</ol>
          </ul>
        );
      case "link": {
        const { data } = node;
        const href = data.get("href");
        return (
          <a
            {...attributes}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      }
      default:
        break;
    }
  };

  renderMark = (props) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "code":
        return <code {...attributes}>{children}</code>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      default:
        break;
    }
  };

  buildAllSectionLayouts = (userDefinedLayout) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildPackedSectionLayout(userDefinedLayout, c));
    }
    return sectionLayouts;
  };

  buildPackedSectionLayout = (userDefinedLayout, columns) => {
    const sortedLayout = userDefinedLayout
      .slice()
      .sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y));
    /*var currentRow = 0;
    var currentColumn = -1;*/
    const updatedLayout = sortedLayout.map((card, index) => {
      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h: card.h,
        i: card.i,
      };
    });
    return updatedLayout;
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const { isSaveCardsPopUpVisible } = this.state;
    const { storyData } = this.props;
    let selectedCard = null;
    storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges.forEach(
      (section, index) => {
        section.node.boardCardUses.edges.map((item, index) => {
          if (item.node.id === cardId) {
            selectedCard = item;
          }
          return selectedCard;
        });
      }
    );
    if (isSaveCardsPopUpVisible === false) {
      this.props.setCardId(cardId);
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard,
      });
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId) => {
    const {
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isSaveCardsPopUpVisibleForHome,
      isReportErrorPopUpVisible,
      isEditHashTagPopUpVisible,
    } = this.state;
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isSaveCardsPopUpVisibleForHome) {
      this.setState({
        isSaveCardsPopUpVisibleForHome: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
  };

  handleReRenderCard = (id) => {
    const { onRefreshLayout } = this.props;
    const { data: stateData } = this.state;
    let data = [];
    if (stateData && stateData.length) {
      stateData.forEach((section, index) => {
        const sectionObject = _.cloneDeep(section);
        sectionObject.node.boardCardUses.edges =
          sectionObject.node.boardCardUses.edges.map((item, index) => {
            if (item.node.id === id) {
              let changedObject = Object.assign({}, item);
              changedObject.isUpdate = !changedObject.isUpdate;
              return changedObject;
            } else {
              return item;
            }
          });
        data.push(sectionObject);
      });
      this.setState(
        {
          data,
        },
        () => {
          this.hidePopUp();
          onRefreshLayout();
        }
      );
    }
  };

  onEditStoryClick = (e) => {
    const { storyData } = this.props;
    const { publishedStoryBoard } = storyData.boardCardUse.configuration;
    this.setState({ isStoryEditing: true });
    /*
    if (window.opener && !window.opener.closed && window.opener.name === "bsedit-" + publishedStoryBoard.sourceBoard.id) {
        window.open("", "bsedit-" + publishedStoryBoard.sourceBoard.id);
    } else {
        if (publishedStoryBoard && publishedStoryBoard.sourceBoard && publishedStoryBoard.sourceBoard.id) {
            const sourceRoute = "/board/BOARD/collection/" + escape(publishedStoryBoard.sourceBoard.id) + "/" + escape(publishedStoryBoard.sourceBoard.name);
            window.open(sourceRoute, "bsedit-" + publishedStoryBoard.sourceBoard.id);
        }
    }
    */
    if (
      publishedStoryBoard &&
      publishedStoryBoard.sourceBoard &&
      publishedStoryBoard.sourceBoard.id
    ) {
      const sourceRoute =
        "/board/BOARD/collection/" +
        escape(publishedStoryBoard.sourceBoard.id) +
        "/" +
        escape(publishedStoryBoard.sourceBoard.name);
      window.open(sourceRoute, "_blank");
    }
  };

  onShareClick = () => {
    this.setState({ showShareModal: true, showSettingsModal: false });
  };

  onSettingsClick = () => {
    this.setState({ showShareModal: false, showSettingsModal: true });
  };

  onCardButtonClick = (cardId) => {
    const { storyData } = this.props;
    let selectedCard = null;
    const isWordpressStory =
      storyData.boardCardUse.configuration.remoteSyncCode === null
        ? false
        : true;

    if (!isWordpressStory) {
      if (
        storyData.boardCardUse &&
        storyData.boardCardUse.configuration &&
        storyData.boardCardUse.configuration.publishedStoryBoard &&
        storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges
      ) {
        storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges.forEach(
          (section, index) => {
            section.node.boardCardUses.edges.map((item, index) => {
              if (item.node.id === cardId) {
                selectedCard = item;
              }
              return selectedCard;
            });
          }
        );
      }
    }
    this.props.setCardId(cardId);
    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard,
    });
  };

  onCloseCardButtonModal = () => {
    const { history } = this.props;
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
    history.replace({ ...history.location.pathname });
  };

  onCloseShareModal = () => {
    const { history } = this.props;
    this.setState({ showShareModal: false });
    history.replace({ ...history.location.pathname });
  };

  onBackSettingsModal = () => {
    const { history } = this.props;
    this.setState({ showSettingsModal: false, showShareModal: true });
    history.replace({ ...history.location.pathname });
  };

  onCloseSettingsModal = () => {
    const { history } = this.props;
    this.setState({ showSettingsModal: false, showShareModal: false });
    history.replace({ ...history.location.pathname });
  };

  handleUpdate = (collbEmail) => {
    const { handleUpdateBoard } = this.props;
    const { existingInvitations, duplicateCheckArr } = this.state;
    let collabNoSearch = {
      id: null,
      name: null,
      userAccount: {
        email: collbEmail,
      },
      profileImage: {
        url: require("../../images/favicon1.svg"),
      },
    };
    if (collbEmail.length > 2) {
      //Update duplicate email List

      this.setState({
        existingInvitations: [...existingInvitations, collabNoSearch],
        collabSearchResult: null,
        addEmail: false,
        duplicateCheckArr: [...duplicateCheckArr, collbEmail],
      });
      this.onCloseSettingsModal();
      handleUpdateBoard(true);
    }
  };

  handleRemovecollabFromList = (object) => {
    const {
      existingInvitations,
      existingCollab,
      duplicateCheckArr,
      privateLinkCollaborators,
    } = this.state;
    let index = -1;
    let existingInvitation = existingInvitations.slice();
    if (existingInvitation.length > 0) {
      index = existingInvitation.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        existingInvitation.splice(index, 1);
        this.setState({
          existingInvitations: existingInvitation,
        });
      }
    }

    let existingCollaborator = existingCollab.slice();
    if (existingCollaborator.length > 0) {
      index = existingCollaborator.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        existingCollaborator.splice(index, 1);
        this.setState({
          existingCollab: existingCollaborator,
        });
      }
    }
    let duplicateCheckArray = duplicateCheckArr.slice();
    index = duplicateCheckArray.findIndex(
      (email) => email === object.userAccount.email
    );
    if (index >= 0) {
      duplicateCheckArray.splice(index, 1);
      this.setState({
        duplicateCheckArr: duplicateCheckArray,
      });
    }

    if (privateLinkCollaborators.length !== 0) {
      let privateLinkCollaborator = privateLinkCollaborators.slice();
      index = privateLinkCollaborator.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        privateLinkCollaborator.splice(index, 1);
        this.setState({
          privateLinkCollaborators: privateLinkCollaborator,
        });
      }
    }
  };

  HtmlParser = (data) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = data;
    return textArea.innerText;
  };

  closeWideCardPopup = () => {
    const { history, wideCardId, storyId } = this.props;
    if (wideCardId) {
      history.replace(`/pubboard/${storyId}`);
    }
    this.setState({
      showWideCardPopup: false,
      cardId: "",
    });
  };

  handleWideCardVisible = (cardId) => {
    this.setState({
      showWideCardPopup: true,
      cardId,
    });
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId) => {
    const { storyData } = this.props;
    let selectedCard = null;
    storyData &&
      storyData.boardCardUse &&
      storyData.boardCardUse.configuration &&
      storyData.boardCardUse.configuration.publishedStoryBoard &&
      storyData.boardCardUse.configuration.publishedStoryBoard.sections &&
      storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges &&
      storyData.boardCardUse.configuration.publishedStoryBoard.sections.edges.forEach(
        (section, index) => {
          section.node.boardCardUses.edges.map((item, index) => {
            if (item.node.id === cardId) {
              selectedCard = item;
            }
            return selectedCard;
          });
        }
      );
    this.props.setCardId(cardId);
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId,
      selectedCard,
    });
  };

  render() {
    const {
      isSavedTooltip,
      savedCardId,
      showShareModal,
      showSettingsModal,
      existingCollab,
      existingInvitations,
      privateLinkCollaborators,
      duplicateCheckArr,
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      selectedCard,
      showTwitterCardPopup,
      visitedCollaborators,
      data,
      isStoryEditing,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      selectedCardForMutation,
      text,
      showWideCardPopup,
      cardId,
    } = this.state;
    const {
      updatingLeftMenuOnCopyCollection,
      storyData,
      storyId,
      location,
      paramComponent,
      isLeftMenuOpen,
      handleUpdateBoard,
      userData,
      openCardFamilyPopupWithUrl,
      changeCardHeight,
      handleCloseUpView,
      handleSelectThemePopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      handleArrayOfTagsOfCardObject,
      isEditHashTagPopUpVisible,
      addRemoveFavourite,
      handleLoginPopUp,
      history,
      openStoryPopup,
      openStoryPopupWithUrl,
      openLoginPopUp,
    } = this.props;
    const { publishedStoryBoard } = storyData.boardCardUse.configuration;

    const isWordpressStory =
      storyData.boardCardUse.configuration.remoteSyncCode === null
        ? false
        : true;
    const publishedStoryId = storyId
      ? storyId
      : storyData && storyData.boardCardUse
      ? storyData.boardCardUse.id
      : "";
    const isTableauStory =
      isWordpressStory &&
      storyData.boardCardUse.configuration.storyText.indexOf("tableau") !== -1;

    const storyPublishedDate = moment(
      storyData.boardCardUse.configuration.storyPublishedDate
    ).format("MMM DD YYYY");
    var storyUpdatedDateText = null;
    if (storyData.boardCardUse.configuration.storyUpdatedDate != null) {
      const storyUpdatedDate = moment(
        storyData.boardCardUse.configuration.storyUpdatedDate
      ).format("MMM DD YYYY");
      storyUpdatedDateText = (
        <span>
          <i className="bullet">
            <img alt="" src={require("../../imagesStory/dot.svg")} />
          </i>
          UPDATED {storyUpdatedDate}
        </span>
      );
    }
    const publishedStoryBoardName = storyData.boardCardUse.configuration.title;

    const storyReadTime = storyData.boardCardUse.configuration.storyReadTime;
    const authorName = storyData.boardCardUse.configuration.author.name;
    const featureImageUrl =
      storyData.boardCardUse.configuration.featureImage === null
        ? placeHolderFeaturedImage
        : storyData.boardCardUse.configuration.featureImage.url;
    const featureImageId =
      storyData.boardCardUse.configuration.featureImage === null
        ? ""
        : storyData.boardCardUse.configuration.featureImage.id;
    const featureImageCaption =
      storyData.boardCardUse.configuration.featureImage === null
        ? ""
        : storyData.boardCardUse.configuration.featureImage.caption;
    /*const savedAreaName = storyData.boardCardUse.configuration.savedArea === null ? null : storyData.boardCardUse.configuration.savedArea.name;*/
    const collaboratorsArray =
      storyData.boardCardUse.configuration.collaborators.edges;
    const authorBriefBio = storyData.boardCardUse.configuration.author.briefBio;
    const authorData = storyData.boardCardUse.configuration.author;
    const authorProfileImage =
      storyData.boardCardUse.configuration.author.profileImage;
    /*const appreciates = storyData.boardCardUse.configuration.appreciates;*/
    const publishedStoryBoardSubtitle =
      storyData.boardCardUse.configuration.publishedStoryBoard === null
        ? null
        : storyData.boardCardUse.configuration.publishedStoryBoard.subtitle;

    var storyPermaLink = storyData.boardCardUse.configuration.permaLink;
    var storyPermaLinkAnchor = null;
    if (storyPermaLink != null) {
      // storyPermaLinkAnchor = (<a style={{marginLeft:"20px"}} id="ccpermalink" target="ccstory"
      // rel="noopener noreferrer" href={storyPermaLink} title="Story permalink">Link</a>)
    } else {
      // stories will always have a /board/story or /board/pubboard permalink
      storyPermaLink =
        appBaseUrl +
        "board" +
        location.pathname +
        location.search +
        location.hash;
    }
    let buttonColor = "";
    let headerColor = "#fff";
    let headerLogo = "";
    let favicon = "";
    let hasEditPermission = false;
    let privateLink = "";
    let boardType = "MyCollection";
    let logoLink = appBaseUrl;

    if (publishedStoryBoard) {
      headerColor = publishedStoryBoard.headerColor || "#fff";
      buttonColor = publishedStoryBoard.buttonColor;
      headerLogo =
        publishedStoryBoard.headerImage && publishedStoryBoard.headerImage.url;
      favicon =
        publishedStoryBoard.faviconImage &&
        publishedStoryBoard.faviconImage.url;
      hasEditPermission = publishedStoryBoard.hasEditPermission;
      privateLink =
        "https://" +
        window.location.hostname +
        "/board" +
        publishedStoryBoard.invitationLink;
      boardType = publishedStoryBoard.sourceBoard.boardType;
      if (
        publishedStoryBoard.logoLink != null &&
        publishedStoryBoard.logoLink !== ""
      ) {
        logoLink = publishedStoryBoard.logoLink;
      }
    }

    const customStyle = {
      backgroundColor: headerColor,
      borderBottom: "1px solid #e3e3e3",
    };
    const customButtonStyle = { backgroundColor: "#fff" };

    if (
      !(
        logoLink.startsWith("http") ||
        logoLink.startsWith("mailto:") ||
        logoLink.startsWith("ftp:")
      )
    ) {
      logoLink = "https://" + logoLink;
    }
    const subComponentType =
      selectedCard && selectedCard.node && selectedCard.node.subComponentType;
    const disabledDownloadButton =
      selectedCard &&
      selectedCard.node &&
      selectedCard.node.frontFacingComponent &&
      (selectedCard.node.frontFacingComponent.componentType === "StoryCard" ||
        reportToolCardArray.includes(subComponentType));
    return (
      <Fragment>
        <Helmet>
          <title>{publishedStoryBoardName}</title>
          <meta
            name="description"
            content={publishedStoryBoardName + " " + storyPublishedDate}
          />

          <meta property="og:title" content={publishedStoryBoardName} />
          <meta property="og:type" content="article" />
          <meta
            property="og:description"
            content={publishedStoryBoardName + " " + storyPublishedDate}
          />
          <meta
            property="og:image"
            content={apiBaseUrl + "images/BroadStreet_Logo2.png"}
          />
          <meta property="og:url" content={storyPermaLink} />
          <meta itemprop="name" content="BroadStreet" />
          <meta
            itemprop="image"
            content={apiBaseUrl + "images/BroadStreet_Logo2.png"}
          />
          <meta name="twitter:title" content={publishedStoryBoardName} />
          <meta
            name="twitter:description"
            content={publishedStoryBoardName + " " + storyPublishedDate}
          />

          {favicon && <link rel="icon" type="image/x-icon" href={favicon} />}
          {favicon && (
            <link rel="shortcut icon" type="image/x-icon" href={favicon} />
          )}
          {isTableauStory === true ? (
            <script>
              var tableauNodeList =
              document.querySelectorAll('div.tableauPlaceholder'); for (var
              divElement of tableauNodeList)
              divElement.getElementsByTagName('object')[0].style.width='650px';
              for (var divElement of tableauNodeList)
              divElement.getElementsByTagName('object')[0].style.height='887px';
            </script>
          ) : null}
          {isTableauStory === true ? (
            <script src="https://public.tableau.com/javascripts/api/viz_v1.js" />
          ) : null}
        </Helmet>
        <div className="projectBoard-right-component" id="storyPopup">
          <div
            className="story-wrap"
            onClick={this.hidePopUp}
            style={{ "--color": buttonColor, WebkitPrintColorAdjust: "exact" }}
          >
            <div className="story-top-nav pb-3">
              <div className="story-header-main" style={customStyle}>
                <div className="story-header d-flex px-4 py-3">
                  <div className="d-flex justify-content-between align-items-center flex-fill ml-3">
                    {/* header for mobile screen start */}
                    {hasEditPermission ? (
                      <div
                        className="mr-sm-4 story-btn show-mobile"
                        style={customButtonStyle}
                        onClick={this.onEditStoryClick}
                      >
                        <img
                          className="icon-img mr-1 mb-1"
                          alt="Edit"
                          src={editImg}
                          width={12}
                          height={12}
                        />
                        <span>Edit</span>
                      </div>
                    ) : (
                      <div
                        className="mr-sm-4 story-btn show-mobile"
                        style={customButtonStyle}
                      ></div>
                    )}
                    <a href={logoLink} className="show-mobile">
                      <img
                        src={
                          favicon
                            ? favicon
                            : require("../../images/favicon1.svg")
                        }
                        className="show-mobile"
                        alt="Return Home"
                        height="30px"
                        width="30px"
                      />
                    </a>
                    <div
                      className="story-btn show-mobile"
                      onClick={() => this.onShareClick()}
                      style={customButtonStyle}
                    >
                      <img
                        className="icon-img mr-1"
                        alt="Share"
                        src={shareImg}
                        width={12}
                        height={12}
                      />
                      <span>Share</span>
                    </div>
                    {/* header for mobile screen end */}

                    {/* header for larger screen*/}
                    <div className="hide-mobile">
                      {/* (!headerLogo && !favicon) && <span>Created at </span> */}
                      {/* favicon && <img src={favicon} alt="favicon" height="30px" width="30px"/> */}
                      <a
                        href={logoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="large-icon"
                          src={
                            headerLogo
                              ? headerLogo
                              : require("../../images/storyLogo.svg")
                          }
                          style={{ height: "40px" }}
                          alt="Return Home"
                        />
                      </a>
                    </div>
                  </div>
                  {/* header for larger screen*/}
                  <div className="d-flex justify-content-end flex-fill mr-sm-3 hide-mobile">
                    {hasEditPermission && (
                      <div
                        className="mr-sm-4 story-btn"
                        style={customButtonStyle}
                        onClick={this.onEditStoryClick}
                      >
                        <img
                          className="icon-img mr-1 mb-1"
                          alt="Close"
                          src={editImg}
                          width={12}
                          height={12}
                        />
                        <span>Edit</span>
                      </div>
                    )}
                    <div
                      className="story-btn"
                      style={customButtonStyle}
                      onClick={() => this.onShareClick()}
                    >
                      <img
                        className="icon-img mr-1"
                        alt="Close"
                        src={shareImg}
                        width={12}
                        height={12}
                      />
                      <span>Share</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 story-header story-sub-header d-flex"></div>
            {boardType !== "MyPage" && (
              <div className="feature-wrap text-left col-md-auto flex-lg-row flex-md-column flex-column px-4 mt-0">
                <div className="feature-box text-box col-12 col-md-6 pb-3 order-2 order-sm-1">
                  <div className="update-text">
                    <span>
                      {storyPublishedDate}
                      {storyUpdatedDateText}
                      {storyPermaLinkAnchor}
                    </span>
                  </div>
                  <h3 className="s-main-title">{publishedStoryBoardName}</h3>
                  <span className="s-sub-title">
                    {publishedStoryBoardSubtitle}
                  </span>

                  <div className="s-media s-media-top ">
                    <div className="s-media-left author">
                      <figure>
                        {
                          storyData.boardCardUse.configuration.author
                            .profileImage !== null ? (
                            <img
                              src={
                                storyData.boardCardUse.configuration.author
                                  .profileImage.url
                              }
                              alt=""
                              className="media-object"
                            />
                          ) : (
                            <span className="media-no-object">
                              {authorName.charAt(0).toUpperCase()}
                            </span>
                          ) /* TODO add name's first character when image url is null */
                        }
                      </figure>
                    </div>
                    <div className="s-media-body">
                      <h4 className="s-media-heading">
                        Shared by{" "}
                        <a href={"#collab"} style={{ color: buttonColor }}>
                          {authorName}
                        </a>
                      </h4>
                      {this.renderCollaborators(collaboratorsArray)}
                      <p className="time">{storyReadTime}</p>
                    </div>
                  </div>
                </div>
                <div className="feature-box image-box  col-12 col-md-6 order-1 order-sm-2">
                  <figure>
                    <img
                      src={featureImageUrl}
                      alt=""
                      className="story_main_image w-100"
                    />
                  </figure>
                  <span className="img-caption">{featureImageCaption}</span>
                </div>
              </div>
            )}

            <div className="feature-wrap text-area-story text-left col-md-auto flex-lg-row flex-md-column flex-column pt-0">
              {paramComponent === "oldcard" && (
                <div className="left-area pr-5 d-flex flex-md-row flex-lg-column">
                  <div className="story-card-buttons">
                    <CardBottom
                      {...this.props}
                      storyModal={this.storyModal}
                      bottomData={storyData}
                      isHomeFeed
                      isStoryCard
                      tags={storyData.boardCardUse.tags}
                    />
                  </div>
                </div>
              )}
              <div className="right-area pt-1">
                {isWordpressStory === false ? (
                  storyData.boardCardUse.configuration.publishedStoryBoard
                    .allowCopy === false ? null : (
                    <div
                      className="feature-srch-wrap"
                      style={{ display: "none" }}
                    >
                      <div
                        className="comm-card-wrap middle-search"
                        style={{
                          width: "100%",
                          opacity: 0.3,
                          cursor: "not-allowed",
                        }}
                      >
                        <img
                          src={require("../../images/selectAreaComingSoon.png")}
                          width="367"
                          height="39"
                          alt="Copy to Another Community Area Coming Soon"
                          title="Copy to Another Community Area Coming Soon"
                        />
                      </div>
                      {/*
                                            <div className="search-container">
                                                <span className="search-icon-title">Select Area</span>
                                                <div className="search-panel-card">
                                                    <input type="search" name="" placeholder={savedAreaName} />
                                                    <span className="search-map-icon"></span>
                                                    <span className="drop-btn-card">My Area</span>
                                                </div>
                                            </div>
                                            <div className="feature-srch-btn-wrap">
                                                <button className="srch-save-btn">SAVE</button>
                                            </div>
                                            */}
                    </div>
                  )
                ) : null}

                {isWordpressStory === true ? (
                  <div className="text_dis">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: storyData.boardCardUse.configuration.storyText,
                      }}
                    />
                  </div>
                ) : null}
                {isWordpressStory === false ? (
                  <div>
                    <div onClick={this.hidePopUp}>
                      {data.map((section, index) => {
                        // NOTE: Render each section of a user-published collection below
                        const layout = JSON.parse(section.node.layout);
                        const packedLayouts =
                          this.buildAllSectionLayouts(layout);
                        const sectionDescription =
                          section.node.description === ""
                            ? null
                            : JSON.parse(section.node.description);
                        return (
                          <div key={index} className="custom-page-wrap">
                            {section.node.sectionLayout ===
                              "bannerWithText" && (
                              <div className="p-0 col-12 d-flex flex-md-row flex-column mt-5 custom-page-section1">
                                <div
                                  className="col-md-3 col-12 pr-0 custom-page-image2 d-flex p-4"
                                  style={{ backgroundColor: buttonColor }}
                                >
                                  <span className="m-auto">
                                    {this.HtmlParser(section.node.name)}
                                  </span>
                                </div>
                                <div className="col-lg-2 col-md-3 col-12 p-0">
                                  <img
                                    src={
                                      section.node.image1
                                        ? section.node.image1.url
                                        : placeHolderImage
                                    }
                                    alt=""
                                    className="h-100 custom-page-image w-100"
                                  />
                                </div>
                                <div className="col-lg-7 col-md-6 col-12 custom-page-info p-4 h-100">
                                  <div className="banner-with-text-description">
                                    <Editor
                                      placeholder=""
                                      value={
                                        sectionDescription == null
                                          ? Value.create()
                                          : Value.fromJSON(sectionDescription)
                                      }
                                      renderNode={this.renderNodeDesc}
                                      renderMark={this.renderMark}
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {section.node.sectionLayout ===
                              "bannerWithImage" && (
                              <div className="col-12 d-flex flex-md-row flex-column mt-5 p-0">
                                <div
                                  className="col-md-4 col-12 d-flex flex-column align-items-center align-items-sm-baseline
                                                                     justify-content-center pl-0 order-2 order-sm-1"
                                >
                                  <h3 className="textbx-story-title">
                                    {this.HtmlParser(section.node.name)}
                                  </h3>
                                  <span className="sub-title">
                                    <Editor
                                      placeholder=""
                                      value={
                                        sectionDescription == null
                                          ? Value.create()
                                          : Value.fromJSON(sectionDescription)
                                      }
                                      renderNode={this.renderNodeDesc}
                                      renderMark={this.renderMark}
                                      readOnly={true}
                                    />
                                  </span>
                                </div>
                                <div className="col-md-8 col-12 p-0 order-1 order-sm-2 pb-4">
                                  <img
                                    style={{
                                      maxWidth: "818px",
                                      maxHeight: "255px",
                                      width: "100%",
                                    }}
                                    src={
                                      section.node.image1
                                        ? section.node.image1.url
                                        : placeHolderBannerImage
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}

                            {section.node.sectionLayout === "text" && (
                              <div key={index} className="pt-5">
                                <div className="editor-description p-5">
                                  <h3 className="mb-4">
                                    {this.HtmlParser(section.node.name)}
                                  </h3>
                                  <Editor
                                    placeholder=""
                                    value={
                                      sectionDescription == null
                                        ? Value.create()
                                        : Value.fromJSON(sectionDescription)
                                    }
                                    renderNode={this.renderNodeDesc}
                                    renderMark={this.renderMark}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            )}

                            {section.node.sectionLayout !== "bannerWithImage" &&
                              section.node.sectionLayout !== "bannerWithText" &&
                              section.node.sectionLayout !== "text" && (
                                <div className="mt-5">
                                  <div className="text_dis pb-5">
                                    <h3 className="textbx-story-title">
                                      {this.HtmlParser(section.node.name)}
                                    </h3>
                                    <Editor
                                      placeholder=""
                                      value={
                                        sectionDescription == null
                                          ? Value.create()
                                          : Value.fromJSON(sectionDescription)
                                      }
                                      renderNode={this.renderNodeDesc}
                                      renderMark={this.renderMark}
                                      readOnly={true}
                                    />
                                  </div>
                                  <div className="community-card-wrap pt-2 text-center">
                                    <ResponsiveReactGridLayout
                                      className={
                                        isLeftMenuOpen
                                          ? "rgl-menu-open"
                                          : "rgl-menu-closed"
                                      }
                                      cols={{
                                        lg: 5,
                                        md: 4,
                                        sm: 3,
                                        xs: 2,
                                        xxs: 1,
                                      }}
                                      breakpoints={{
                                        lg: 1350,
                                        md: 1050,
                                        sm: 768,
                                        xs: 480,
                                        xxs: 0,
                                      }}
                                      rowHeight={30}
                                      layouts={{
                                        lg: packedLayouts[3],
                                        md: packedLayouts[3],
                                        sm: packedLayouts[2],
                                        xs: packedLayouts[1],
                                        xxs: packedLayouts[0],
                                      }}
                                      onLayoutChange={this.handleLayoutChange}
                                      onBreakpointChange={
                                        this.onBreakpointChange
                                      }
                                      isDraggable={false}
                                      margin={[35, 10]}
                                      containerPadding={[0, 0]}
                                      autoSize={true}
                                      {...this.props}
                                    >
                                      {section.node.boardCardUses.edges.map(
                                        (item, index) => {
                                          if (
                                            item.node.frontFacingComponent
                                              .componentType === "ReportCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <ReportCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  onCloseUpView={
                                                    this.handleCloseUpView
                                                  }
                                                  handleSelectThemePopUp={
                                                    this.handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    this
                                                      .handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType === "AdiMapCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <AdiMapCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  subComponentType={
                                                    item.node.subComponentType
                                                  }
                                                  statistics={
                                                    item.node.statistics
                                                  }
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  handleSelectThemePopUp={
                                                    this.handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    this
                                                      .handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  handleUpdateBoard={
                                                    handleUpdateBoard
                                                  }
                                                  isMapVisible={true}
                                                  userData={userData}
                                                  openCardFamilyPopupWithUrl={
                                                    openCardFamilyPopupWithUrl
                                                  }
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  handleWideCardVisible={
                                                    this.handleWideCardVisible
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType ===
                                            "ServiceAreaMapCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <ServiceAreaMapCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  subComponentType={
                                                    item.node.subComponentType
                                                  }
                                                  statistics={
                                                    item.node.statistics
                                                  }
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  handleSelectThemePopUp={
                                                    this.handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    this
                                                      .handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  handleUpdateBoard={
                                                    handleUpdateBoard
                                                  }
                                                  isMapVisible={true}
                                                  userData={userData}
                                                  openCardFamilyPopupWithUrl={
                                                    openCardFamilyPopupWithUrl
                                                  }
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  handleWideCardVisible={
                                                    this.handleWideCardVisible
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType ===
                                            "IndicatorMapCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <IndicatorMapCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  subComponentType={
                                                    item.node.subComponentType
                                                  }
                                                  statistics={
                                                    item.node.statistics
                                                  }
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  onCloseUpView={
                                                    handleCloseUpView
                                                  }
                                                  handleSelectThemePopUp={
                                                    handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  isMapcardsVisible={true}
                                                  addRemoveFavourite={
                                                    addRemoveFavourite
                                                  }
                                                  userData={userData}
                                                  handleLoginPopUp={
                                                    handleLoginPopUp
                                                  }
                                                  history={history}
                                                  openCardFamilyPopupWithUrl={
                                                    openCardFamilyPopupWithUrl
                                                  }
                                                  isMapVisible={true}
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  handleWideCardVisible={
                                                    this.handleWideCardVisible
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType === "DotMapCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <DotMapCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  subComponentType={
                                                    item.node.subComponentType
                                                  }
                                                  statistics={
                                                    item.node.statistics
                                                  }
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  onCloseUpView={
                                                    handleCloseUpView
                                                  }
                                                  handleSelectThemePopUp={
                                                    handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  isMapcardsVisible={true}
                                                  addRemoveFavourite={
                                                    addRemoveFavourite
                                                  }
                                                  userData={userData}
                                                  handleLoginPopUp={
                                                    handleLoginPopUp
                                                  }
                                                  history={history}
                                                  openCardFamilyPopupWithUrl={
                                                    openCardFamilyPopupWithUrl
                                                  }
                                                  isMapVisible={true}
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  handleWideCardVisible={
                                                    this.handleWideCardVisible
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType === "ChartCard"
                                          ) {
                                            // else if (true) {
                                            const { subComponentType } =
                                              item.node;
                                            const reportToolCard =
                                              reportToolCardArray.indexOf(
                                                subComponentType
                                              ) > -1;
                                            if (reportToolCard) {
                                              return (
                                                <div
                                                  key={item.node.id}
                                                  className="grid-card"
                                                >
                                                  <ReportToolCardContainer
                                                    {...this.props}
                                                    changeCardHeight={
                                                      changeCardHeight
                                                    }
                                                    isEditingEnabled={false}
                                                    subComponentType={
                                                      item.node.subComponentType
                                                    }
                                                    statistics={
                                                      item.node.statistics
                                                    }
                                                    isHomeFeed={true}
                                                    isCloseUpView={false}
                                                    index={index}
                                                    key={item.node.id}
                                                    data={item}
                                                    hideUnHidePopUp={
                                                      this.hideUnHidePopUp
                                                    }
                                                    showToolTip={
                                                      this.showToolTip
                                                    }
                                                    hideToolTip={
                                                      this.hideToolTip
                                                    }
                                                    onCloseUpView={
                                                      handleCloseUpView
                                                    }
                                                    handleSelectThemePopUp={
                                                      handleSelectThemePopUp
                                                    }
                                                    handleSaveCardsPopUp={
                                                      this.handleSaveCardsPopUp
                                                    }
                                                    newTagsForCardObject={
                                                      newTagsForCardObject
                                                    }
                                                    arrayOfTagsOfCardObject={
                                                      arrayOfTagsOfCardObject
                                                    }
                                                    handleArrayOfTagsOfCardObject={
                                                      handleArrayOfTagsOfCardObject
                                                    }
                                                    selectedCardForMutation={
                                                      selectedCardForMutation
                                                    }
                                                    isEditHashTagPopUpVisible={
                                                      isEditHashTagPopUpVisible
                                                    }
                                                    isMapcardsVisible={true}
                                                    addRemoveFavourite={
                                                      addRemoveFavourite
                                                    }
                                                    userData={userData}
                                                    handleLoginPopUp={
                                                      handleLoginPopUp
                                                    }
                                                    history={history}
                                                    openCardFamilyPopupWithUrl={
                                                      openCardFamilyPopupWithUrl
                                                    }
                                                    isSavedTooltip={
                                                      isSavedTooltip
                                                    }
                                                    savedCardId={savedCardId}
                                                    handleReRenderCard={
                                                      this.handleReRenderCard
                                                    }
                                                    onCardButtonClick={
                                                      this.onCardButtonClick
                                                    }
                                                  />
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div key={item.node.id}>
                                                  <ChartCardContainer
                                                    {...this.props}
                                                    changeCardHeight={
                                                      changeCardHeight
                                                    }
                                                    isEditingEnabled={false}
                                                    subComponentType={
                                                      item.node.subComponentType
                                                    }
                                                    statistics={
                                                      item.node.statistics
                                                    }
                                                    isHomeFeed={true}
                                                    isCloseUpView={false}
                                                    index={index}
                                                    key={item.node.id}
                                                    data={item}
                                                    hideUnHidePopUp={
                                                      this.hideUnHidePopUp
                                                    }
                                                    showToolTip={
                                                      this.showToolTip
                                                    }
                                                    hideToolTip={
                                                      this.hideToolTip
                                                    }
                                                    onCloseUpView={
                                                      handleCloseUpView
                                                    }
                                                    handleSelectThemePopUp={
                                                      handleSelectThemePopUp
                                                    }
                                                    handleSaveCardsPopUp={
                                                      this.handleSaveCardsPopUp
                                                    }
                                                    newTagsForCardObject={
                                                      newTagsForCardObject
                                                    }
                                                    arrayOfTagsOfCardObject={
                                                      arrayOfTagsOfCardObject
                                                    }
                                                    handleArrayOfTagsOfCardObject={
                                                      handleArrayOfTagsOfCardObject
                                                    }
                                                    selectedCardForMutation={
                                                      selectedCardForMutation
                                                    }
                                                    isEditHashTagPopUpVisible={
                                                      isEditHashTagPopUpVisible
                                                    }
                                                    isMapcardsVisible={true}
                                                    addRemoveFavourite={
                                                      addRemoveFavourite
                                                    }
                                                    userData={userData}
                                                    handleLoginPopUp={
                                                      handleLoginPopUp
                                                    }
                                                    history={history}
                                                    openCardFamilyPopupWithUrl={
                                                      openCardFamilyPopupWithUrl
                                                    }
                                                    isSavedTooltip={
                                                      isSavedTooltip
                                                    }
                                                    savedCardId={savedCardId}
                                                    handleReRenderCard={
                                                      this.handleReRenderCard
                                                    }
                                                    handleWideCardVisible={
                                                      this.handleWideCardVisible
                                                    }
                                                    isStoryEditing={
                                                      isStoryEditing
                                                    }
                                                    onCardButtonClick={
                                                      this.onCardButtonClick
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          } else if (
                                            item.node.frontFacingComponent
                                              .componentType === "MapCard"
                                          ) {
                                            return (
                                              <div key={item.node.id}>
                                                <StoryCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  onCloseUpView={
                                                    handleCloseUpView
                                                  }
                                                  handleSelectThemePopUp={
                                                    handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  isMapcardsVisible={true}
                                                  addRemoveFavourite={
                                                    addRemoveFavourite
                                                  }
                                                  userData={userData}
                                                  handleLoginPopUp={
                                                    handleLoginPopUp
                                                  }
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  handleReRenderCard={
                                                    this.handleReRenderCard
                                                  }
                                                  handleWideCardVisible={
                                                    this.handleWideCardVisible
                                                  }
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                />
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div key={item.node.id}>
                                                <StoryCardContainer
                                                  {...this.props}
                                                  changeCardHeight={
                                                    changeCardHeight
                                                  }
                                                  isEditingEnabled={false}
                                                  isHomeFeed={true}
                                                  isCloseUpView={false}
                                                  index={index}
                                                  key={item.node.id}
                                                  data={item}
                                                  hideUnHidePopUp={
                                                    this.hideUnHidePopUp
                                                  }
                                                  showToolTip={this.showToolTip}
                                                  hideToolTip={this.hideToolTip}
                                                  onCloseUpView={
                                                    this.handleCloseUpView
                                                  }
                                                  handleSelectThemePopUp={
                                                    this.handleSelectThemePopUp
                                                  }
                                                  handleSaveCardsPopUp={
                                                    this.handleSaveCardsPopUp
                                                  }
                                                  newTagsForCardObject={
                                                    newTagsForCardObject
                                                  }
                                                  arrayOfTagsOfCardObject={
                                                    arrayOfTagsOfCardObject
                                                  }
                                                  handleArrayOfTagsOfCardObject={
                                                    this
                                                      .handleArrayOfTagsOfCardObject
                                                  }
                                                  selectedCardForMutation={
                                                    selectedCardForMutation
                                                  }
                                                  isEditHashTagPopUpVisible={
                                                    isEditHashTagPopUpVisible
                                                  }
                                                  openStoryPopup={
                                                    openStoryPopup
                                                  }
                                                  openStoryPopupWithUrl={
                                                    openStoryPopupWithUrl
                                                  }
                                                  isSavedTooltip={
                                                    isSavedTooltip
                                                  }
                                                  savedCardId={savedCardId}
                                                  onCardButtonClick={
                                                    this.onCardButtonClick
                                                  }
                                                  userData={userData}
                                                />
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    </ResponsiveReactGridLayout>
                                  </div>
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <ToolTip
          x={xOrigin}
          y={yOrigin}
          isVisible={isToolTipVisible}
          text={text}
        />
        {showCardButtonModal || isSaveCardsPopUpVisible ? (
          <CardButtonsPopUp
            history={history}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleReRenderCard={this.handleReRenderCard}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            isCollections={false}
            disabledDownloadButton={disabledDownloadButton}
            displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
            isFavorites={true}
            publishedStoryId={publishedStoryId}
            isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
            cardId={selectedCardForMutation}
            selectedCard={selectedCard}
            closePopUp={this.onCloseCardButtonModal}
            handleShareCardPopUp={this.handleShareCardPopUp}
          />
        ) : null}
        {showWideCardPopup && (
          <WideCardPopup
            {...this.props}
            cardId={cardId}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            publishedStoryId={publishedStoryId}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            history={history}
            handleReRenderCard={this.handleReRenderCard}
            isEditingEnabled={false}
            isHomeFeed={true}
          />
        )}

        {showTwitterCardPopup && !disabledDownloadButton && (
          <WideCardPopup
            cardId={cardId}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            showFullPage={true}
            showTwitterCardPopup={showTwitterCardPopup}
            history={history}
            handleReRenderCard={this.handleReRenderCard}
            isEditingEnabled={false}
            isHomeFeed={true}
          />
        )}

        {showShareModal && (
          <ShareStoryPopUp
            closePopUp={this.onCloseShareModal}
            history={history}
            cardId={publishedStoryBoard.sourceBoard.id}
            userData={userData}
            featureImageId={featureImageId}
            existingCollab={existingCollab}
            existingInvitations={existingInvitations}
            privateLinkCollaborators={privateLinkCollaborators}
            visitedCollaborators={visitedCollaborators}
            duplicateCheckArr={duplicateCheckArr}
            ownerData={authorData}
            hasEditPermission={hasEditPermission}
            privateLink={privateLink}
            invitationLinkRole={
              publishedStoryBoard.sourceBoard.invitationLinkRole
            }
            collaboratorRole={publishedStoryBoard.sourceBoard.collaboratorRole}
            onUpdateCollaborators={this.handleUpdate}
            handleRemovecollabFromList={this.handleRemovecollabFromList}
            updatingLeftMenuOnCopyCollection={updatingLeftMenuOnCopyCollection}
            onSettingsClick={this.onSettingsClick}
            data={{ board: publishedStoryBoard.sourceBoard }}
          />
        )}
        {showSettingsModal && (
          <StorySettingsPopUp
            history={history}
            goBack={this.onBackSettingsModal}
            closePopUp={this.onCloseSettingsModal}
            data={{ board: publishedStoryBoard.sourceBoard }}
            updatingLeftMenuOnCopyCollection={null}
            handleUpdateBoard={() => {
              this.onCloseSettingsModal();
              handleUpdateBoard(true);
            }}
            handleLoginPopUp={openLoginPopUp}
            userData={userData}
            privateLink={privateLink}
            existingCollab={existingCollab}
            existingInvitations={existingInvitations}
            privateLinkCollaborators={privateLinkCollaborators}
            duplicateCheckArr={duplicateCheckArr}
            onUpdateCollaborators={null}
            handleRemovecollabFromList={null}
            ownerData={authorData}
            handleTemplateCreated={null}
            handleViewPublishedVersion={null}
            handleCopyCollection={null}
            handleDeleteStory={null}
          />
        )}
        {boardType !== "MyPage" && (
          <div
            className="feature-wrap story-content d-flex justify-content-sm-start"
            id={"collab"}
          >
            <div className="s-media description-media">
              <div className="s-media-left">
                <figure>
                  {authorProfileImage === null ? (
                    <span className="media-no-object">
                      {authorName.charAt(0).toUpperCase()}
                    </span>
                  ) : (
                    <img
                      src={
                        authorProfileImage === null
                          ? null
                          : authorProfileImage.url
                      }
                      alt=""
                      className="media-object"
                    />
                  )}
                </figure>
              </div>
              <div className="s-media-body">
                <h4 className="s-media-heading">
                  SHARED BY <br />
                  <span style={{ color: buttonColor }}>{authorName}</span>
                </h4>
                <p>
                  {authorBriefBio}
                  {/*<a className="mailto" href="#">{authorContactInfo} 123456
                                                    </a>*/}
                </p>
              </div>
            </div>
          </div>
        )}
        <div
          className="feature-wrap thank-Contributors text-left"
          ref="collaborators"
        >
          {collaboratorsArray.length > 0 && boardType !== "MyPage" && (
            <div className="story_contributors_list footer-margin">
              {collaboratorsArray.length > 0 ? (
                <h3>Thank You Collaborators</h3>
              ) : null}
              <div className="d-flex flex-wrap">
                {collaboratorsArray.map((collaborator, index) => {
                  if (collaborator.node.profileImage === null) {
                    return (
                      <div className="meida-colom no-image" key={index}>
                        <div className="s-media">
                          <div className="s-media-left">
                            <figure>
                              <span className="media-no-object">
                                {collaborator.node.name.charAt(0).toUpperCase()}
                              </span>
                            </figure>
                          </div>
                          <div className="s-media-body">
                            <h4 className="s-media-heading">
                              <span style={{ color: buttonColor }}>
                                {collaborator.node.name}
                              </span>
                            </h4>
                            <p>
                              {collaborator.node.briefBio}
                              <a
                                className="mailto"
                                href="mailto:rebecca@gmail.com"
                              >
                                {collaborator.node.contactInfo}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="meida-colom" key={index}>
                        <div className="s-media">
                          <div className="s-media-left">
                            <figure>
                              <img
                                src={collaborator.node.profileImage.url}
                                alt=""
                                className="media-object"
                              />
                            </figure>
                          </div>
                          <div className="s-media-body">
                            <h4 className="s-media-heading">
                              <span style={{ color: buttonColor }}>
                                {collaborator.node.name}
                              </span>
                            </h4>
                            <p>
                              {collaborator.node.briefBio}
                              <a
                                className="mailto"
                                href="mailto:rebecca@gmail.com"
                              >
                                {collaborator.node.contactInfo}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
        </div>
        <FooterComponent isStory="true" />
      </Fragment>
    );
  }
}

export default withRouter(Story);
