import React, { Component } from "react";
import Switch from "react-switch";
import "../../css/components/Card/D3DotGridProgressChart.css";
import * as d3 from "d3";
import * as _ from "lodash";

class D3DotGridProgressChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 8, bottom: 10, left: 8 };
    this.clientWidth = 217;
    this.clientHeight = 105;
    this.toggleHeight = 15;
    this.textHeight = 60;
    this.state = {
      isToggle: false,
      ratio: 1,
    };
  }

  handleChange = (checked, e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isToggle: checked }, () => {
      this.init(this.props);
    });
  };

  componentWillReceiveProps(props) {
    this.init(props);
  }

  componentDidMount() {
    this.init(this.props);
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
  };

  init = (props) => {
    this.progress = d3.scaleLinear();
    this.createSvg(props);
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    this.svg = d3.select(this.parentEle).select("svg#" + id);

    const gSelection = this.svg.selectAll("g.g-container").data([id], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + id + "gcontainer");
    this.svgToggleG = this.svg.select("g#" + id + "toggle-g-container");
    this.autoAlignSVG(props);
    this.addProgressBars(props);
    this.setImage(props);
    this.updateCurrentProgress(props);
  };

  autoAlignSVG = (props) => {
    const { data } = props;
    //  Set the dimensions and margins of the diagram
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.textHeight / 2 -
      this.toggleHeight;

    const newValue = (this.size / 217) * 0.65;
    if (isWide && ratio !== newValue) {
      this.setState({
        ratio: newValue,
      });
    }

    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 -18 ${this.width + this.margin.left + this.margin.right}
            ${
              this.height +
              this.margin.top +
              this.margin.bottom +
              this.textHeight +
              this.toggleHeight
            }`
      );

    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.svgToggleG.attr(
      "transform",
      "translate(0," + (this.height + this.margin.top + 20) + ")"
    );
    this.progress.domain([data.minValue, data.maxValue]).range([0, 100]);
  };

  updateCurrentProgress(props) {
    const { data, id } = props;
    const { isToggle } = this.state;
    const svgId = id.replace(/=/g, "");
    let value = isToggle ? data.benchmarkValue : data.currentValue;
    value = value < 1 ? _.round(value, 1) : _.round(value);
    d3.select("#current-progress-" + svgId).html((value ? value : 0));
    d3.select("#current-progress-msg-" + svgId).html(
      isToggle ? data.manGridTextBenchmark : data.manGridText
    );
  }

  addProgressBars(props) {
    const { data } = props;
    const imagePadding = { top: 1, bottom: 1, left: 1, right: 1 };
    const { isToggle } = this.state;
    const value = isToggle ? data.benchmarkValue : data.currentValue;
    const x1Scale = d3
      .scaleLinear()
      .domain([0, 50])
      .range([0, this.width])
      .clamp(true);
    const x2Scale = d3
      .scaleLinear()
      .domain([50, 100])
      .range([0, this.width])
      .clamp(true);
    const pathData = [
      {
        value: this.width,
        color: data.defaultColor ? data.defaultColor : "#424B54",
        level: 0,
        bar: 0,
      },
      {
        value: this.width,
        color: data.defaultColor ? data.defaultColor : "#424B54",
        level: 1,
        bar: 1,
      },
      {
        value: x1Scale(this.progress(value)),
        color: data.progressColor ? data.progressColor : "#00939D",
        level: 0,
        bar: 2,
      },
      {
        value: x2Scale(this.progress(value)),
        color: data.progressColor ? data.progressColor : "#00939D",
        level: 1,
        bar: 3,
      },
    ];

    const g = this.svgG.selectAll("g.bar-container").data(
      (d) => [d],
      (d) => d.id
    );
    const newG = g.enter().append("g");
    newG.merge(g).attr("class", "bar-container");

    const progressSelection = newG
      .merge(g)
      .selectAll("rect.black-path")
      .data(pathData, (d) => d.bar);
    const newProgress = progressSelection.enter().append("rect");
    newProgress
      .merge(progressSelection)
      .attr("class", "black-path")
      .attr("x", imagePadding.left)
      .attr(
        "y",
        (d) =>
          d.level * (this.height) +
          imagePadding.top -
          (d.level > 0 ? imagePadding.top : 0)
      )
      .attr(
        "height",
        (this.height - imagePadding.top - imagePadding.bottom) 
      )
      .transition()
      .duration(700)
      .attr("width", (d) =>
        d.value - imagePadding.left - imagePadding.right > 0
          ? d.value - imagePadding.left - imagePadding.right
          : 0
      )
      .attr("fill", (d) => d.color);
    progressSelection.exit().remove();

    g.exit().remove();
  }

  setImage(props) {
    const { image } = props;

    const g = this.svgG.selectAll("g.image-container").data(
      (d) => [d],
      (d) => d.id
    );
    const newG = g.enter().append("g");
    newG.merge(g).attr("class", "image-container");

    const rectSelection = newG
      .merge(g)
      .selectAll("rect")
      .data([{ src: image }], (d) => d.src);
    const newRectEle = rectSelection.enter().append("rect");
    newRectEle
      .merge(rectSelection)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this.height*2)
      .attr("fill", "transparent")
      .attr("stroke", "#ffffff")
      .attr("stroke-width", "8px");
    rectSelection.exit().remove();

    const imageSelection = newG
      .merge(g)
      .selectAll("image")
      .data([{ src: image }], (d) => d.src);
    const imageForeignObject = imageSelection
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height*2);
    const imageEleDiv = imageForeignObject.append("xhtml:div");
    const imgEle = imageEleDiv.append("img");
    imgEle
      .merge(imageSelection)
      .attr("src", (d) => d.src)
      .attr("width", this.width)
      .attr("height", this.height*2);
    imageSelection.exit().remove();

    g.exit().remove();
  }

  render() {
    const { isToggle, ratio } = this.state;
    const switchTitle = {
      fontSize: "11px",
      fontFamily: "ProximaNova-Regular",
      lineHeight: 1,
      display: "block",
      color: "#989898",
    };
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    return (
      <div className="D3DotGridProgressChart d-flex flex-column h-100 w-100">
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
          style={{ padding: "0 16px 0 16px" }}
          ref={(ele) => (this.parentEle = ele)}
        >
          <svg id={svgId}>
            <g
              id={svgId + "toggle-g-container"}
              className={svgId + "toggle-g-container"}
            >
              <foreignObject x="0" y="-100" width="100%" height={this.textHeight/2}>
                <div
                  className="flex-grow-1 d-flex"
                  style={{ height: this.textHeight*0.4 }}
                >
                  <div
                    id={`current-progress-intro-${svgId}`}
                    className="w-100 details-text text-center"
                  >Out of every 10 deaths<br/> from suicide
                  </div>
                </div>
              </foreignObject>
              <foreignObject x="0" y="25" width="100%" height={this.textHeight}>
                <div
                  className="flex-grow-1 d-flex"
                  style={{ height: this.textHeight }}
                >
                  <div
                    id={`current-progress-parent${svgId}`}
                    className="w-100 details-text text-center"
                  ><span id={`current-progress-${svgId}`} 
                    className="main-text" />
                    <span id={`current-progress-msg-${svgId}`} 
                     className = "details-text" 
                    />
                  </div>
                </div>
              </foreignObject>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default D3DotGridProgressChart;
