/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BillingPageQueryVariables = {||};
export type BillingPageQueryResponse = {|
  +currentUser: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +username: ?string,
        +stripeCustomerId: ?string,
        +subscriptions: ?$ReadOnlyArray<?string>,
        +userSubscriptionLevel: number,
        +userSubscriptionTerm: ?string,
        +userSubscriptionExpiration: ?any,
        +creditAmount: number,
        +activeCouponCode: ?{|
          +subscriptionCouponCode: ?string
        |},
        +activePromoCode: ?string,
        +activePromoCodeMessage: ?string,
        +pauseSubscriptionStartDate: ?any,
        +pauseSubscriptionEndDate: ?any,
        +pausedUserSubscriptionLevel: number,
        +nextInvoice: ?{|
          +id: ?string,
          +currentPeriodEndFormatted: ?string,
          +object: ?string,
          +plan: ?{|
            +id: ?string,
            +amount: ?number,
            +currency: ?string,
            +interval: ?string,
            +object: ?string,
          |},
          +status: ?string,
          +total: ?number,
        |},
        +defaultPaymentMethod: ?{|
          +id: ?string,
          +object: ?string,
          +type: ?string,
          +card: ?{|
            +brand: ?string,
            +country: ?string,
            +last4: ?string,
            +expMonth: ?string,
            +expYear: ?string,
          |},
        |},
        +groupAccounts: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +name: string,
              +billingdocumentSet: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +documentType: string,
                    +invoiceNumber: ?string,
                    +summary: string,
                    +documentDate: any,
                    +documentUrl: string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type BillingPageQuery = {|
  variables: BillingPageQueryVariables,
  response: BillingPageQueryResponse,
|};
*/


/*
query BillingPageQuery {
  currentUser {
    edges {
      node {
        username
        stripeCustomerId
        subscriptions
        userSubscriptionLevel
        userSubscriptionTerm
        userSubscriptionExpiration
        creditAmount
        activeCouponCode {
          subscriptionCouponCode
          id
        }
        activePromoCode
        activePromoCodeMessage
        pauseSubscriptionStartDate
        pauseSubscriptionEndDate
        pausedUserSubscriptionLevel
        nextInvoice {
          id
          currentPeriodEndFormatted
          object
          plan {
            id
            amount
            currency
            interval
            object
          }
          status
          total
        }
        defaultPaymentMethod {
          id
          object
          type
          card {
            brand
            country
            last4
            expMonth
            expYear
          }
        }
        groupAccounts {
          edges {
            node {
              name
              billingdocumentSet {
                edges {
                  node {
                    id
                    documentType
                    invoiceNumber
                    summary
                    documentDate
                    documentUrl
                  }
                }
              }
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeCustomerId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionLevel",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionTerm",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionExpiration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creditAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionCouponCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activePromoCode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activePromoCodeMessage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pauseSubscriptionStartDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pauseSubscriptionEndDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pausedUserSubscriptionLevel",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "object",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "StripeSubscriptionNode",
  "kind": "LinkedField",
  "name": "nextInvoice",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentPeriodEndFormatted",
      "storageKey": null
    },
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeSubscriptionPlanNode",
      "kind": "LinkedField",
      "name": "plan",
      "plural": false,
      "selections": [
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "StripePaymentMethodNode",
  "kind": "LinkedField",
  "name": "defaultPaymentMethod",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePaymentMethodCardNode",
      "kind": "LinkedField",
      "name": "card",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expYear",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingDocumentNodeConnection",
  "kind": "LinkedField",
  "name": "billingdocumentSet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingDocumentNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingDocumentNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "documentType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "summary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "documentDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "documentUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNode",
                    "kind": "LinkedField",
                    "name": "activeCouponCode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupAccountNodeConnection",
                    "kind": "LinkedField",
                    "name": "groupAccounts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GroupAccountNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GroupAccountNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNode",
                    "kind": "LinkedField",
                    "name": "activeCouponCode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupAccountNodeConnection",
                    "kind": "LinkedField",
                    "name": "groupAccounts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GroupAccountNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GroupAccountNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "BillingPageQuery",
    "operationKind": "query",
    "text": "query BillingPageQuery {\n  currentUser {\n    edges {\n      node {\n        username\n        stripeCustomerId\n        subscriptions\n        userSubscriptionLevel\n        userSubscriptionTerm\n        userSubscriptionExpiration\n        creditAmount\n        activeCouponCode {\n          subscriptionCouponCode\n          id\n        }\n        activePromoCode\n        activePromoCodeMessage\n        pauseSubscriptionStartDate\n        pauseSubscriptionEndDate\n        pausedUserSubscriptionLevel\n        nextInvoice {\n          id\n          currentPeriodEndFormatted\n          object\n          plan {\n            id\n            amount\n            currency\n            interval\n            object\n          }\n          status\n          total\n        }\n        defaultPaymentMethod {\n          id\n          object\n          type\n          card {\n            brand\n            country\n            last4\n            expMonth\n            expYear\n          }\n        }\n        groupAccounts {\n          edges {\n            node {\n              name\n              billingdocumentSet {\n                edges {\n                  node {\n                    id\n                    documentType\n                    invoiceNumber\n                    summary\n                    documentDate\n                    documentUrl\n                  }\n                }\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7daec7ba734785a1e20b5ba42243c15f';

module.exports = node;
