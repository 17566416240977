import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  getCreditReferralInfo,
  getSubscriptionInfo,
  subscriptionInfoSelector,
  creditReferralInfoSelector,
} from "../../actions/user";

import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { connect } from "react-redux";
import environment from "../../RelayEnvironment";
import { toast } from "react-toastify";

import {
  TwitterShareButton,
  FacebookShareButton,
  TwitterIcon,
  FacebookIcon,
} from "react-share";

const shareString = `@BroadStreet_io
BroadStreet is the new way of looking at health indicators and strives to make data accessible to all. Use my referral link to check out BroadStreet's tool! `;
const ReferFriends = ({
  subscriptionInfo,
  creditReferralInfo,
  getSubscriptionInfo,
  getCreditReferralInfo,
}) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [copied, setCopied] = useState(false);
  const [invited, setInvited] = useState("Send invite");
  const refLink = useRef();

  const copyLink = (e) => {
    e.preventDefault();
    refLink.current.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 10000);
  };

  const refreshPage = () => {
    getSubscriptionInfo();
    getCreditReferralInfo();
  };
  useEffect(() => {
    refreshPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendInvite = () => {
    const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      inviteEmail
    );
    if (inviteEmail && isValidEmail) {
      const mutation = graphql`
        mutation ReferFriendsCreateInvitationMutation(
          $input: CreateInvitationInput!
        ) {
          createInvitation(input: $input) {
            success
          }
        }
      `;
      const variables = {
        input: {
          email: inviteEmail,
        },
      };

      setSending(true);
      setInvited("Sending");
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          setSending(false);
          if (
            response &&
            response.createInvitation &&
            response.createInvitation.success
          ) {
            setInvited("Invite Sent");
            setTimeout(() => {
              setInvited("Send invite");
            }, 10000);
          }
        },
        onError: (err) => {
          console.log("error", err);
          setSending(false);
        },
      });
    } else {
      toast.error("Please enter valid email address!");
    }
  };
  return (
    <>
      <div className="tab-pane fade active show" id="accordion-2">
        {/* <div className="text-left mb-5">
          <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
        <div className="ibox-head mb-5">
          <h3 className="font-weight-bold">Credit & Referrals</h3>
        </div>
        <div className="text-left mb-5">
          <div className="font-weight-bold mb-4">
            Get $10 in credit for every person you refer to BroadStreet
          </div>
          <div className="mb-4">
            <p>
              Receive credit once a person you invite signs up and verifies
              their email. You can use this credit towards your{" "}
              <Link to="/accountSetting/billing">next invoice</Link>. Credit can
              also be used to <Link to="/accountSetting/sponsor">sponsor</Link>{" "}
              the membership of a friend
            </p>
            <p>
              If you would like to collaborate with someone, add them as a
              collaborator on a story. If you wish to simply spread the word
              about BroadStreet, send the invite or referral link below.
            </p>
          </div>
          <div className="referral-link-form">
            <div className="mb-4">
              <label className="font-weight-bold d-block mb-2">
                My Referral Link
              </label>
              <input
                ref={refLink}
                readOnly
                value={
                  creditReferralInfo ? creditReferralInfo.referralLink : ""
                }
                onClick={(e) => {
                  copyLink(e);
                }}
                className="w-100"
              />
              <Link
                to="#"
                onClick={(e) => {
                  copyLink(e);
                }}
              >
                {copied ? "Link Copied" : "Copy Link"}
              </Link>
            </div>
            <div className="mb-4">
              <label className="font-weight-bold d-block d-block mb-2">
                Email an Invite
              </label>
              <div className="d-flex align-items-center">
                <input
                  value={inviteEmail}
                  onChange={(e) => {
                    setInviteEmail(e.target.value);
                  }}
                  className="flex-grow-1 mr-3"
                />
                <button
                  className={
                    invited === "Invite Sent"
                      ? "button-large button-done"
                      : "button-large button-important"
                  }
                  disabled={sending}
                  onClick={sendInvite}
                >
                  {invited}
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="font-weight-bold d-block mb-2">Share</label>
              <div className="d-flex">
                <TwitterShareButton
                  url={
                    shareString +
                    (creditReferralInfo ? creditReferralInfo.referralLink : "")
                  }
                  className="mr-3"
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <FacebookShareButton
                  url={
                    creditReferralInfo ? creditReferralInfo.referralLink : ""
                  }
                  quote={shareString}
                  className="mr-3"
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                {/* <a href="#" className="share-link twitter mr-3">
                  Tweet
                </a>
                <a href="#" className="share-link facebook">
                  Share
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-left mb-5">
          <h4 className="mb-3">My Credits</h4>
          <div className="font-weight-bold mb-3">
            You currently have $
            {subscriptionInfo ? subscriptionInfo.creditAmount : 0} in credit
          </div>
          <div className="descriptions">
            {creditReferralInfo &&
              creditReferralInfo.referrals.edges.map((item, index) => (
                <div className="d-flex align-items-center mb-2" key={index}>
                  <div className="text-success font-weight-bold mr-3">
                    ${item.node.creditAmount}
                  </div>
                  <div className="text-muted">
                    {item.node.historyDescription}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state),
  creditReferralInfo: creditReferralInfoSelector(state),
});

const mapDispatch = {
  getSubscriptionInfo,
  getCreditReferralInfo,
};
export default connect(mapStatus, mapDispatch)(ReferFriends);
