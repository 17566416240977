import React, { Component } from "react";
import Switch from "react-switch";
import * as d3 from "d3";
import * as _ from "lodash";

class D3SimpleGaugeChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 8 };
    /*this.angle = {min: -90, max: 90};*/
    this.scale = d3.scaleLinear().range([0, 1]);
    this.size = 200;
    this.arcInset = 150;
    this.arcWidth = 60;
    this.clientWidth = 217;
    this.clientHeight = 115;
    this.state = {
      isToggle: false,
      data: [],
      hideAnimation: false,
      ratio: 1,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.chartSize =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.chartSize / 217) {
      this.setState({
        ratio: this.chartSize / 217,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { hideAnimation, data } = this.state;
    if (!_.isEqual(prevState.data, data) && !hideAnimation) {
      this.init();
    } else if (!_.isEqual(prevState.data, data) && hideAnimation) {
      this.updateProgress(this.props);
    }
  }

  componentWillReceiveProps(newProps) {
    const { simpleGaugeChartData } = newProps;
    if (!_.isEqual(simpleGaugeChartData, this.propssimpleGaugeChartData)) {
      this.createChartData(simpleGaugeChartData);
    }
  }

  init = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.createSvg();
    this.chartSize =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.chartSize / 217) {
      this.setState({
        ratio: this.chartSize / 217,
      });
    }
  };

  handleChange = (checked, e) => {
    const { simpleGaugeChartData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isToggle: checked, hideAnimation: true }, () => {
      this.createChartData(simpleGaugeChartData);
    });
  };

  createChartData = (simpleGaugeChartData) => {
    const { isToggle } = this.state;
    if (isToggle) {
      this.setState({
        data: simpleGaugeChartData.data.map((o) => {
          const obj = {};
          obj.title = simpleGaugeChartData.pieTypes[1].title;
          if (o[simpleGaugeChartData.pieTypes[1].pieType] != null) {
            obj.value = parseFloat(
              o[simpleGaugeChartData.pieTypes[1].pieType].toFixed(2)
            );
          } else {
            obj.value = 0.0;
          }
          obj.name = o.name;
          obj.name2 = o.name2;
          obj.color = o.color;
          return obj;
        }),
      });
    } else {
      this.setState({
        data: simpleGaugeChartData.data.map((o) => {
          const obj = {};
          obj.title = simpleGaugeChartData.pieTypes[0].title;
          if (o[simpleGaugeChartData.pieTypes[0].pieType] != null) {
            obj.value = parseFloat(
              o[simpleGaugeChartData.pieTypes[0].pieType].toFixed(2)
            );
          } else {
            obj.value = 0.0;
          }
          obj.name = o.name;
          obj.name2 = o.name2;
          obj.color = o.color;
          return obj;
        }),
      });
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3SimpleGaugeChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");

    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    this.chartSize =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.chartSize / 217) {
      this.setState({
        ratio: this.chartSize / 217,
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.svg
      .append("svg:rect")
      .attr("width", this.width + this.margin.left + this.margin.right) // the whole width of
      // g/svg
      .attr("height", this.height + this.margin.top + this.margin.bottom) // the whole heigh of
      // g/svg
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseover", () => {
        this.count = this.count + 1;
        if (this.count === 1) {
          const { max } = this.props;
          let num = this.deg2rad(Math.floor((0 * 180) / max - 90));
          this.foreground
            .transition()
            .delay(250)
            .ease(d3.easeQuad)
            .duration(300)
            .styleTween("fill", function () {
              return d3.interpolate("#424B54", "#424B54");
            })
            .call(this.arcTween, num);
          this.updateTransition(this.props);
        }
      })
      .on("mousemove", () => {
        d3.event.stopPropagation();
      })
      .on("mouseout", () => {
        this.count = 0;
        d3.event.stopPropagation();
      });
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + (this.margin.top - 10) + ")"
    );
  };

  update = () => {
    const { min, max } = this.props;
    this.scale.domain([min, max]);
    this.autoAlignSVG();
    this.createGuage();
    this.updateTransition(this.props);
  };

  deg2rad = (deg) => {
    return (deg * Math.PI) / 180;
  };

  // Update animation
  arcTween = (transition, newAngle) => {
    transition.attrTween("d", (d) => {
      var interpolate = d3.interpolate(d.endAngle, newAngle);
      return (t) => {
        d.endAngle = interpolate(t);
        return this.arc(d);
      };
    });
  };

  createGuage = () => {
    const { max, min } = this.props;
    let outerRadius = this.size - this.arcInset;
    let innerRadius = this.size - outerRadius - this.arcWidth;

    // Arc Defaults
    this.arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(this.deg2rad(-90));

    // Place svg element
    const gaugeSelection = this.svgG.selectAll("svg").data(
      (d) => [d],
      (d) => d
    );
    this.gaugeSvg = gaugeSelection
      .enter()
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr(
        "transform",
        "translate(" + this.size / 2 + "," + this.size / 2 + ")"
      );

    this.gaugeSvg
      .append("path")
      .datum({
        endAngle: this.deg2rad(90),
      })
      .attr("class", "gaugeBackground")
      .attr("d", this.arc)
      .on("mousemove", () => {
        d3.event.stopPropagation();
      });

    // Append foreground arc to svg
    this.foreground = this.gaugeSvg
      .append("path")
      .datum({
        endAngle: this.deg2rad(-90),
      })
      .style("fill", "#ddd")
      .attr("d", this.arc)
      .on("mousemove", () => {
        d3.event.stopPropagation();
      })
      .on("mouseover", () => {
        d3.event.stopPropagation();
      });

    // Display Max value
    this.gaugeSvg
      .append("text")
      .attr(
        "transform",
        "translate(" + (innerRadius + (outerRadius - innerRadius) / 2) + ",15)"
      ) // Set
      .attr("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "13")
      .style("opacity", 1)
      .style("color", "#424B54")
      .text(max + "%");

    // Display Min value
    this.gaugeSvg
      .append("text")
      .attr(
        "transform",
        "translate(" + -(innerRadius + (outerRadius - innerRadius) / 2) + ",15)"
      ) // Set between inner and outer Radius
      .attr("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "13")
      .style("color", "#424B54")
      .text(min);
  };

  updateTransition = (props) => {
    // const value = 75
    const { max } = props;
    const { data } = this.state;

    if (data.length && data[0].value && max) {
      let numPi = this.deg2rad(Math.floor((data[0].value * 180) / max - 90));
      this.foreground
        .transition()
        .delay(800)
        .ease(d3.easeElasticOut)
        .duration(2000)
        .styleTween("fill", function () {
          return d3.interpolate("#424B54", "#424B54");
        })
        .call(this.arcTween, numPi);
    }
  };

  updateProgress = (props) => {
    // const value = 75
    const { max } = props;
    const { data } = this.state;
    this.setState({ hideAnimation: false });

    if (data.length && data[0].value && max) {
      let numPi = this.deg2rad(Math.floor((data[0].value * 180) / max - 90));
      this.foreground
        .transition()
        .ease(d3.easeBackInOut)
        .duration(500)
        .styleTween("fill", function () {
          return d3.interpolate("#424B54", "#424B54");
        })
        .call(this.arcTween, numPi);
    }
  };

  render() {
    const { isWide, simpleGaugeChartData } = this.props;
    const { pieTypes } = simpleGaugeChartData;
    const { isToggle, data, ratio } = this.state;
    const titleStyle = {
      fontSize: "2.5rem",
      fontFamily: "ProximaNova-Bold",
      marginBottom: 0,
      position: "relative",
      marginTop: "-16px",
      bottom: "14px",
      lineHeight: "18px",
      color: "#424B54",
    };
    const subTitleDivStyle = {
      position: "relative",
      bottom: "5px",
    };
    const subTitleStyle = {
      fontSize: "15px",
      fontFamily: "ProximaNova-Bold",
      width: "100%",
      marginBottom: 0,
      lineHeight: 1.2,
      color: "#424B54",
    };
    const unitStyle = {
      fontSize: "25px",
    };
    const switchTitle = {
      fontSize: "11px",
      fontFamily: "ProximaNova-Regular",
      color: "#989898",
      lineHeight: 1,
      display: "block",
    };
    let name = data.length && data[0].name;
    let name2 = data.length && data[0].name2;
    let value = data.length && data[0].value;

    return (
      <div
        ref={(ele) => (this.mainEle = ele)}
        className="D3SimpleGaugeChart d-flex justify-content-center h-100 w-100 align-items-center"
      >
        <div
          className="d-flex flex-column"
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="mx-0 d-flex justify-content-center align-items-center">
            <div
              className="d-flex justify-content-center align-items-center"
              ref={(ele) => (this.parentEle = ele)}
            />
          </div>
          <div className="text-center" style={titleStyle}>
            <span>
              {_.round(value)}
              <span style={unitStyle}>%</span>
            </span>
          </div>
          <div className="mx-0 px-3 text-center py-1 " style={subTitleDivStyle}>
            <p style={subTitleStyle}>
              <span className="pr-1">{name}</span>
              <span>{name2}</span>
            </p>
          </div>
          <div
            className="d-flex justify-content-center align-items-center pt-2"
            style={{
              height: "55px",
              marginLeft: "-10px",
              transform: `scale(${isWide ? "0.85" : 1})`,
            }}
          >
            <div>
              <small className={"text-right"} style={switchTitle}>
                {pieTypes.length && pieTypes[0].title}
              </small>
            </div>
            <div className="px-2 d-flex align-items-center justify-content-center">
              <Switch
                onChange={this.handleChange}
                checked={isToggle}
                className="react-switch"
                id="togglePie"
                height={18}
                width={35}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#989898"
                onHandleColor="#fff"
              />
            </div>
            <div>
              <small className={"text-left"} style={switchTitle}>
                {pieTypes.length && pieTypes[1].title}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3SimpleGaugeChart;
