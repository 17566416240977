import React, { useState } from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { STRIPE_PUBLIC_KEY } from "../../config";
import { userSelector, subscriptionInfoSelector } from "../../actions/user";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const STEP_1 = "Step 1";
const STEP_2 = "Step 2";
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        backgroundColor: "white",
        /*color: '#403f4c',*/
        borderRadius: "3px",
        "::placeholder": {
          color: "#989898",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const _CheckOutForm = ({
  onSuccess,
  creditAmount,
  defaultQuantity,
  buyedClientSecret,
  buyedDiscountText,
  buyedPaymentIntentId,
  buyedOrderAmount,
  email,
  stripe,
  elements,
}) => {
  const [zipCode, setZipCode] = useState("");
  const [showZipCode, setShowZipCode] = useState(false);

  const [updating, setUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [quantity, setQuantity] = useState(defaultQuantity);
  const [discountText, setDiscountText] = useState(buyedDiscountText);
  const [orderAmount, setOrderAmount] = useState(buyedOrderAmount);
  const [applying, setApplying] = useState(false);

  const [applied, setApplied] = useState(false);
  /*const [appliedCoupons, setAppliedCoupons] = useState(0);*/

  const [changing, setChanging] = useState(false);

  const currency = (amount) => {
    return amount.toFixed(2);
  };

  const handleChange = ({ error }) => {
    if (error) {
      setErrorMsg(error.message);
    }
  };

  const handleSubmit = () => {
    if (!stripe) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    setUpdating(true);
    setErrorMsg("");
    setSuccessMsg("");

    const cardElement = elements.getElement(CardNumberElement);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
        },
      })
      .then((result) => {
        // confirm PaymentIntent and charge in Stripe here
        stripe
          .confirmCardPayment(buyedClientSecret, {
            payment_method: result.paymentMethod.id,
          })
          .then(function (result) {
            setUpdating(false);
            console.log(result);
            if (result.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              setErrorMsg(result.error.message + ": " + result.error.code);
              //You cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed.');
            } else {
              if (result.paymentIntent.status === "succeeded") {
                setSuccessMsg("Vouchers have been added to your account");
                onSuccess();
              } else {
                setErrorMsg("Failed purchasing the vouchers.");
              }
            }
          });
      })
      .catch((err) => {
        if (err && err.errors) {
          setErrorMsg(err.errors[0].message);
        }
        setUpdating(false);
      });
  };

  const applyCredit = (paymentIntent, applyCredit) => {
    setErrorMsg("");
    setApplying(true);
    const mutation = graphql`
      mutation PurchaseCouponsPopupUpdateOrderMutation(
        $input: UpdateOrderInput!
      ) {
        updateOrder(input: $input) {
          clientSecret
          discountText
          orderAmount
          paymentIntentId
          success
        }
      }
    `;
    let variables = {
      input: {
        paymentIntentId: paymentIntent,
        applyCredit: applyCredit,
      },
    };
    setApplied(false);
    setDiscountText("");
    setOrderAmount(0);

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.updateOrder.success) {
          setDiscountText(response.updateOrder.discountText);
          setOrderAmount(response.updateOrder.orderAmount);
          setApplied(true);
          /*setAppliedCoupons(defaultQuantity);*/
        }
        setApplying(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setApplying(false);
      },
    });
  };

  const updateQtyWithInput = (qty, paymentIntent) => {
    setErrorMsg("");
    // setUpdating(true);
    const mutation = graphql`
      mutation PurchaseCouponsPopupUpdateOrderwithInputQtyMutation(
        $input: UpdateOrderInput!
      ) {
        updateOrder(input: $input) {
          clientSecret
          discountText
          orderAmount
          paymentIntentId
          success
        }
      }
    `;
    let variables = {
      input: {
        qty: Number(qty),
        paymentIntentId: paymentIntent,
      },
    };

    /*setAppliedCoupons(qty);*/

    // setDiscountText('');
    // setOrderAmount(0);
    setChanging(true);

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.updateOrder.success) {
          setDiscountText(response.updateOrder.discountText);
          setOrderAmount(response.updateOrder.orderAmount);
        }
        setUpdating(false);
        setChanging(false);
        /*setAppliedCoupons(qty);*/
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setUpdating(false);
      },
    });
  };

  return (
    <div>
      {errorMsg && (
        <div className="alert alert-danger mb-3 rounded" role="alert">
          {errorMsg}
        </div>
      )}
      {successMsg && (
        <div className="alert alert-success mb-3 rounded" role="alert">
          {successMsg}
        </div>
      )}
      <div className="mb-4 d-flex align-items-center">
        <select
          value={quantity}
          style={{ width: 90 }}
          className="mr-2"
          onChange={(e) => {
            setQuantity(e.target.value);
            updateQtyWithInput(e.target.value, buyedPaymentIntentId);
          }}
        >
          {quantities.map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </select>
        <div> membership(s)</div>
      </div>
      <div className="checkout-form">
        <div className="card-elements mb-3">
          <div
            className="input-group"
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <div className="pre-icon">
              <img alt="" src={require("../../images/iconBilling.svg")} />
            </div>
            <div className="d-flex align-items-center flex-grow-1">
              <CardNumberElement
                className="flex-grow-1"
                options={createOptions()}
                onChange={handleChange}
                onFocus={() => setShowZipCode(true)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="input-group"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <div className="pre-icon">
                <img alt="" src={require("../../images/iconDate.svg")} />
              </div>
              <div className="d-flex align-items-center flex-grow-1">
                <CardExpiryElement
                  className="flex-grow-1"
                  options={createOptions()}
                  onChange={handleChange}
                  onFocus={() => setShowZipCode(true)}
                />
              </div>
            </div>
            <div
              className="input-group"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <div className="pre-icon">
                <img alt="" src={require("../../images/iconLock.svg")} />
              </div>
              <div className="d-flex align-items-center flex-grow-1">
                <CardCvcElement
                  className="flex-grow-1"
                  onFocus={() => setShowZipCode(true)}
                  options={createOptions()}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={"input-group mb-4 zip-code" + (showZipCode ? " show" : "")}
        >
          <div className="pre-icon">
            <img alt="" src={require("../../images/iconLock.svg")} />
          </div>
          <input
            type="text"
            placeholder="ZIP Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-3 d-flex align-items-center">
        <div className="mr-3 font-weight-bold">
          You have{" "}
          <span style={{ textDecoration: applied ? "line-through" : "none" }}>
            ${currency(creditAmount)}
          </span>{" "}
          in credit.
        </div>
        <button
          className="button button-subtle"
          onClick={() => applyCredit(buyedPaymentIntentId, true)}
          disabled={applying}
        >
          {applying ? "Working" : "Apply"}
        </button>
      </div>
      <div className="mb-3 text-center" style={{ minHeight: 48 }}>
        {!changing && (
          <>
            <div>
              <span className="font-weight-bold">${currency(orderAmount)}</span>{" "}
              for {quantity} membership{quantity > 1 && "s"}
            </div>
            <div className="text-success">{discountText}</div>
          </>
        )}
      </div>
      <button
        className="button-large button-important w-100"
        disabled={updating}
        onClick={() => handleSubmit()}
      >
        {updating && (
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {updating ? "Working..." : "Submit"}
      </button>
    </div>
  );
};

const mapState = (state) => ({
  email: userSelector(state).username,
  creditAmount: subscriptionInfoSelector(state).creditAmount || 0,
});

const CheckOutForm = connect(mapState)(_CheckOutForm);

const InjectedCheckoutForm = ({
  onSuccess,
  defaultQuantity,
  buyedPaymentIntentId,
  buyedDiscountText,
  buyedOrderAmount,
  buyedClientSecret,
}) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => {
        return (
          <CheckOutForm
            elements={elements}
            stripe={stripe}
            defaultQuantity={defaultQuantity}
            buyedPaymentIntentId={buyedPaymentIntentId}
            buyedDiscountText={buyedDiscountText}
            buyedOrderAmount={buyedOrderAmount}
            buyedClientSecret={buyedClientSecret}
            onSuccess={onSuccess}
          />
        );
      }}
    </ElementsConsumer>
  );
};

const PurchaseCouponsPopup = ({
  username,
  sponsorCode,
  closePopUp,
  onSuccess,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEP_1);
  const [quantity, setQuantity] = useState(1);
  const [couponCode, setCouponCode] = useState(sponsorCode);

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [discountText, setDiscountText] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);

  const buySubscriptionCoupons = () => {
    setErrorMsg("");
    if (!quantity && !couponCode) {
      setErrorMsg("Full fill the input fields.");
      return;
    }

    setLoading(true);
    const mutation = graphql`
      mutation PurchaseCouponsPopupMutation(
        $input: BuySubscriptionCouponsInput!
      ) {
        buySubscriptionCoupons(input: $input) {
          clientSecret
          discountText
          orderAmount
          paymentIntentId
          success
        }
      }
    `;

    const variables = {
      input: {
        qty: quantity,
        subscriptionCouponCode: couponCode,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // handleSubscription(response.buySubscriptionCoupons);
        setPaymentIntentId(response.buySubscriptionCoupons.paymentIntentId);
        setDiscountText(response.buySubscriptionCoupons.discountText);
        setOrderAmount(response.buySubscriptionCoupons.orderAmount);
        setClientSecret(response.buySubscriptionCoupons.clientSecret);

        setStep(STEP_2);
        setLoading(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setLoading(false);
      },
    });
  };
  const enterKeyDown = (e) => {
    if (e.key === "Enter") {
      buySubscriptionCoupons();
    }
  };

  const elementProvider = () => {
    return (
      step === STEP_2 && (
        <div className="px-3">
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm
              defaultQuantity={quantity}
              buyedPaymentIntentId={paymentIntentId}
              buyedDiscountText={discountText}
              buyedOrderAmount={orderAmount}
              buyedClientSecret={clientSecret}
              onSuccess={onSuccess}
            />
          </Elements>
        </div>
      )
    );
  };

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "sponsor memberships",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "purchase-coupons",
      formContainerClass: "grid-2-col-min-content",
      formItems: [
        {
          type: "alert",
          alertMsg: errorMsg,
          alertClass: "alert-danger",
        },
        {
          type: "select",
          style: { width: 90 },
          fieldContainerClass: "mr-2 grid-1-2",
          onChange: (e) => {
            setQuantity(e.target.value);
          },
          options: quantities,
        },
        {
          type: "string",
          text: "membership(s)",
          fieldContainerClass: "grid-2-3 mt-1",
        },
        {
          type: "text",
          label: "My Sponsor Code",
          labelClassName: "p-b-2 mt-3",
          placeholder: "New Sponsor Code",
          value: couponCode,
          fieldContainerClass: "grid-1-3 mb-5",
          onKeyDown: enterKeyDown,
          onChange: (e) => {
            setCouponCode(e.target.value);
          },
        },
        {
          type: "button",
          buttonType: "important",
          isLarge: true,
          buttonTitle: loading ? "Working ..." : "Next",
          fieldContainerClass: "grid-1-3",
          className: "button-large w-100",
          handleOnClick: () => {
            setCurrentPage(pagesArray[1]);
            buySubscriptionCoupons();
          },
          disabled: !(couponCode && quantity > 0) || loading,
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "sponsor memberships",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "purchase-coupons-checkout",
      formItems: [
        {
          type: "stripeElements",
          renderStripeElements: elementProvider,
        },
      ],
    },
  ];
  const [currentPage, setCurrentPage] = useState(pagesArray[0]);

  return (
    <>
      {pagesArray.length && (
        <StandardTallModalPopup
          pages={pagesArray}
          currentPage={currentPage}
          closePopUp={closePopUp}
          goBack={closePopUp}
        />
      )}
    </>
  );
};

const mapStatus = (state) => ({
  username: state.user.data.username,
});
export default connect(mapStatus)(PurchaseCouponsPopup);
