import React, { Component } from 'react';
import WideExportADIDataCardContainer from '../Card/WideExportADIDataCardContainer';
import '../../css/components/CardDetailsText/CardDetailsText.css';

class CardDetailsText extends Component {
  render() {
    const {description, title, dataSourceDescription, dataDownloadDescription} = this.props;
    return (<div className='w-100'>
        <div className='cardDetailsText'>
          <h2 className='cardDetailsTextTitle'>{title}</h2>
          <p className='cardDetailsTextDescr'>{description}</p>
          <h3>Indicators within card</h3>
          <p className='cardDetailsTextDescr'>The card includes the following attributes and/or
            indicators: </p>
          <table
            className="cardDetailsTextIndicatorTable table table-hover table-sm table-bordered">
            <thead>
            <tr>
              <th scope="col">Indicators for the Family #ADI</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Area Deprivation Index</td>
            </tr>
            </tbody>
          </table>
          <h4>About Data Source</h4>
          <p className="cardDetailsTextDataSource">{dataSourceDescription}</p>
          <h3>Data Download</h3>
          <section className="cardDetailsTextDownload"
                   dangerouslySetInnerHTML={{__html: dataDownloadDescription}}/>

        </div>
        <div className="export-adi-wide-card">
          <WideExportADIDataCardContainer createDataDownloadCard={true}
                                          isHomeFeed={true} {...this.props}/>
        </div>
      </div>
    );
  }

}

export default CardDetailsText;

/*
Socioeconomic Status: Card #2304

Percent of population by Area Deprivation Index Score by Census Block Group in our selected {SA_Count}-{SA_geo} area (BroadStreet 2018). The scores are based on a national average and are calculated for individual indicators. Together, 17 indicators comprise the Area Deprivation Index.

Indicators within card

The card includes the following attributes and/or indicators:

About Data Source

The Area Deprivation Project provides the Area Deprivation Index which is calculated using U.S. Census Bureau American Community Surey Data 5-year estimates.

Data Download

Broadstreet (2018) has provided a calculation and if you wish to download data, then please see the informational card below.

Important Note: The Area Deprivation Index is calculated at the Census Block Group Level. Not all ZIP codes perfectly align with Census Block Groups. Therefore, ADI calculations of indicators may be slightly different than, say, service areas comprised of ZIP codes.
*/
