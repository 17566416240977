import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { longTimeoutEnvironment } from "../../RelayEnvironment";
import "../../css/components/Card/StoryCardContainer.css";
import withCardContainer from "./CardContainer";
import { withRouter } from "react-router";
import { ButtonComponent } from "common-components";
import {
  apiBaseUrl,
  arrayOfMapPlaceholders,
  arrayOfStoryPlaceholders,
} from "../../utility/utilities";

class StoryCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      mapPlaceholder:
        (props.id.charCodeAt(props.id.length - 1) +
          props.id.charCodeAt(props.id.length - 2)) %
        arrayOfMapPlaceholders.length,
      storyPlaceholder:
        (props.id.charCodeAt(props.id.length - 1) +
          props.id.charCodeAt(props.id.length - 2)) %
        arrayOfStoryPlaceholders.length,
    };
    this.maps = React.createRef();
  }

  // get hyperlink for opening new tab/window with this story
  getNewTabLink = () => {
    const { data } = this.props;
    const absolutePermalink = data.boardCardUse.configuration.permaLink;
    if (absolutePermalink == null) {
      return "/board/pubboard/" + escape(data.boardCardUse.id);
    }
    return absolutePermalink;
  };

  handleStoryVisiblity = (e) => {
    e.stopPropagation();
    const {
      data,
      isEditingEnabled,
      isFavorites,
      isHomeFeed,
      location,
      openStoryPopupWithUrl,
    } = this.props;
    const absolutePermalink = data.boardCardUse.configuration.permaLink;
    let storyRoute;
    if (absolutePermalink == null) {
      storyRoute = "/board/pubboard/" + escape(data.boardCardUse.id);
    } else {
      storyRoute = absolutePermalink.substring(
        absolutePermalink.indexOf("/board/")
      );
    }
    //TODO: keep track of card we just opened so we know to scroll back
    //        to it after closing full page story view
    // Either route to story URL with backtracking,
    //   or open no-URL popover within editable collection (outside edit mode)
    if (isHomeFeed === true) {
      openStoryPopupWithUrl(storyRoute, location);
    } else if (isFavorites === true) {
      openStoryPopupWithUrl(storyRoute, location);
    } else if (!isEditingEnabled) {
      openStoryPopupWithUrl(storyRoute, location);
      /*
      // Log virtual page view for story
      var url = absolutePermalink;
      if(url != null && url != '') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              'event': 'Pageview',
              'url': storyRoute,
              'originalLocation': document.location.protocol + '//' +
                  document.location.hostname +
                  document.location.pathname +
                  document.location.search
          });
          console.log('virtual page view issued', url);
      }*/
    }
    e.preventDefault();
    return false;
  };

  handleCardVisibility = (e) => {
    const { handleWideCardVisible, isEditingEnabled, data } = this.props;
    if (handleWideCardVisible === undefined) {
      return;
    }
    if (isEditingEnabled !== true && handleWideCardVisible) {
      handleWideCardVisible(data.boardCardUse.id);
    }
  };

  editStory = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data, history } = this.props;
    if (data.boardCardUse.configuration.publishedStoryBoard) {
      const {
        hasEditPermission,
        sourceBoard: { id, name },
      } = data.boardCardUse.configuration.publishedStoryBoard;
      if (hasEditPermission) {
        history.push("/BOARD/collection/" + escape(id) + "/" + escape(name));
      }
    }
  };

  deleteStory = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      data,
      userData,
      handleDeleteCollectionPopUpVisible,
      handleRemoveSelfFromCollaborators,
      setSelectedCardForMutation,
    } = this.props;
    const {
      sourceBoard: { id },
    } = data.boardCardUse.configuration.publishedStoryBoard;
    setSelectedCardForMutation(id);
    if (data.boardCardUse.configuration.author) {
      const currentUserData =
        userData.currentUser.edges[0].node.boardauthorSet.edges[0].node;
      if (data.boardCardUse.configuration.author.id === currentUserData.id) {
        handleDeleteCollectionPopUpVisible();
      } else {
        handleRemoveSelfFromCollaborators(currentUserData);
      }
    }
  };

  handleAreaTallModalPopUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, showCardAreaPopUp } = this.props;
    const savedAreaId = data && data.boardCardUse.configuration.savedArea;
    showCardAreaPopUp(data.boardCardUse.id, savedAreaId);
  };

  showAreaToolTip = () => {
    const { showToolTip } = this.props;
    const bounds = this.maps ? this.maps.current.getBoundingClientRect() : null;
    showToolTip(bounds.x + 40, bounds.y + 30, "Select New Area");
  };

  render() {
    const {
      data,
      data: {
        boardCardUse: {
          id,
          configuration: { title },
        },
      },
      handleDeleteCollectionPopUpVisible,
      fromPage,
      handleEditMapPopUpVisible,
      isEditingEnabled,
      isPublishedStory,
      hideToolTip,
      isMapcardsVisible,
    } = this.props;
    const { mapPlaceholder, storyPlaceholder } = this.state;
    const areaTag =
      data &&
      data.boardCardUse &&
      data.boardCardUse.configuration &&
      data.boardCardUse.configuration["savedArea"];
    let hasEditPermission;
    if (data.boardCardUse.configuration.publishedStoryBoard) {
      hasEditPermission =
        data.boardCardUse.configuration.publishedStoryBoard.hasEditPermission;
    }
    const titleLength =
      data.boardCardUse.configuration.title != null
        ? data.boardCardUse.configuration.title.length
        : 0;
    const newTabLink = this.getNewTabLink();
    return (
      <div
        onClick={
          isMapcardsVisible
            ? this.handleCardVisibility
            : this.handleStoryVisiblity
        }
      >
        {isMapcardsVisible === true ? (
          <div
            className={
              isEditingEnabled
                ? "storyCardContainer movable"
                : "storyCardContainer clickable"
            }
            onClick={fromPage === "maps" ? this.handleCardVisibility : null}
          >
            <QueryRenderer
              environment={longTimeoutEnvironment}
              query={graphql`
                query StoryCardContainerMapQuery($input: ID!) {
                  boardCardUse(id: $input) {
                    id
                    mapImage
                  }
                }
              `}
              variables={{ input: this.props.id }}
              render={({ error, props }) => {
                if (error) {
                  console.error(error);
                  const mapImageUrl = arrayOfMapPlaceholders[mapPlaceholder];
                  return (
                    <div className="storyCardContainer-map-img">
                      <div onClick={this.handleCardVisibility}>
                        <img src={mapImageUrl} alt="" />
                      </div>
                    </div>
                  );
                }
                if (!props) {
                  return (
                    <div>
                      <div className="storyCardContainer-map-img">
                        <br />
                        <br />
                        Loading...
                      </div>
                    </div>
                  );
                }
                if (props.boardCardUse == null) {
                  return (
                    <div>
                      <br />
                      (Map Not Available)
                    </div>
                  );
                }
                const usingPlaceholderImage =
                  props.boardCardUse.mapImage === null;
                const mapImageUrl = usingPlaceholderImage
                  ? arrayOfMapPlaceholders[mapPlaceholder]
                  : apiBaseUrl.replace(/\/$/, "") + props.boardCardUse.mapImage;
                return (
                  <div className="storyCardContainer-map-img">
                    <div onClick={this.handleCardVisibility}>
                      <img src={mapImageUrl} alt="" />
                      {!usingPlaceholderImage ? (
                        <div className="watermrk-img">
                          <img
                            src={require("../../images/favicon1.svg")}
                            alt="BroadStreet"
                          />
                        </div>
                      ) : null}
                    </div>
                    {!usingPlaceholderImage ? (
                      <div className="mapboxgl-ctrl-bottom-right">
                        <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact">
                          <div className="mapboxgl-ctrl-attrib-inner">
                            <a
                              href="https://www.maptiler.com/copyright/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              © MapTiler{" "}
                            </a>
                            <a
                              href="https://www.openstreetmap.org/copyright"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              © OpenStreetMap contributors
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              }}
            />
            <div
              className={
                titleLength > 70
                  ? "storyCardContainer-title title-spacing storyCardContainer-map-title long-story-title"
                  : "storyCardContainer-title title-spacing storyCardContainer-map-title"
              }
            >
              <div className={"row px-3"}>
                <p className={"mb-0"}>{title}</p>
              </div>
              <div
                className={
                  "row d-flex justify-content-center align-items-center"
                }
              >
                {
                  <div
                    className={"text-center"}
                    onMouseOver={
                      !isEditingEnabled &&
                      isPublishedStory &&
                      this.showAreaToolTip
                    }
                    onMouseOut={
                      !isEditingEnabled && isPublishedStory && hideToolTip
                    }
                    ref={this.maps}
                  >
                    {fromPage !== "stories" ? (
                      !isEditingEnabled ? (
                        <ButtonComponent
                          buttonType={
                            !isEditingEnabled && isPublishedStory
                              ? "select"
                              : "link"
                          }
                          className={"btn-dashed"}
                          isShuffleModeOn={isEditingEnabled}
                          isAreaTag={true}
                          iconClass={
                            !isEditingEnabled && isPublishedStory
                              ? "icon-arrow-down"
                              : ""
                          }
                          buttonTitle={
                            areaTag !== null ? areaTag.name : "United States"
                          }
                          onClick={
                            !isEditingEnabled && isPublishedStory
                              ? this.handleAreaTallModalPopUp
                              : this.handleCardVisibility
                          }
                        />
                      ) : (
                        <p
                          className={
                            "text-center d-flex justify-content-center align-items-center"
                          }
                        >
                          {areaTag ? areaTag.name : "United States"}
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                }
              </div>
            </div>
            {fromPage === "maps" && (
              <div className="btn-container">
                <div className="d-flex">
                  <ButtonComponent
                    iconClass="icon-edit mx-2"
                    buttonType="link"
                    className={"card-btn"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEditMapPopUpVisible(id, title);
                    }}
                  />
                  <ButtonComponent
                    iconClass="icon-bin mx-2"
                    buttonType="link"
                    className={"card-btn ml-1"}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDeleteCollectionPopUpVisible(id);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              isEditingEnabled
                ? "storyCardContainer movable"
                : "storyCardContainer clickable"
            }
          >
            <div className="storyCardContainer-img">
              <img
                src={
                  data.boardCardUse.configuration.thumbnailImage === null
                    ? arrayOfStoryPlaceholders[storyPlaceholder]
                    : data.boardCardUse.configuration.thumbnailImage.url
                }
                alt=""
              />
            </div>
            <div
              className={
                titleLength > 70
                  ? "storyCardContainer-title title-spacing long-story-title"
                  : "storyCardContainer-title title-spacing"
              }
            >
              <div className={"row px-3"}>
                <p className={"text-center mb-0"}>
                  <a href={newTabLink} onClick={this.handleStoryVisiblity}>
                    {data.boardCardUse.configuration.title}
                  </a>
                </p>
              </div>
              <div
                className={
                  "row d-flex justify-content-center align-items-center"
                }
              >
                {
                  <div
                    className={"text-center"}
                    onMouseOver={
                      !isEditingEnabled &&
                      isPublishedStory &&
                      this.showAreaToolTip
                    }
                    onMouseOut={
                      !isEditingEnabled && isPublishedStory && hideToolTip
                    }
                    ref={this.maps}
                  >
                    {fromPage !== "stories" ? (
                      !isEditingEnabled ? (
                        <ButtonComponent
                          buttonType={
                            !isEditingEnabled && isPublishedStory
                              ? "select"
                              : "link"
                          }
                          className={"btn-dashed"}
                          isShuffleModeOn={isEditingEnabled}
                          isAreaTag={true}
                          iconClass={
                            !isEditingEnabled && isPublishedStory
                              ? "icon-arrow-down"
                              : ""
                          }
                          buttonTitle={areaTag ? areaTag.name : "United States"}
                          onClick={
                            !isEditingEnabled && isPublishedStory
                              ? this.handleAreaTallModalPopUp
                              : null
                          }
                        />
                      ) : (
                        <p
                          className={
                            "text-center d-flex justify-content-center align-items-center"
                          }
                        >
                          {areaTag ? areaTag.name : "United States"}
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                }
              </div>
            </div>
            {["areas", "stories"].indexOf(fromPage) > -1 && (
              <div className="btn-container">
                {hasEditPermission && (
                  <div className="d-flex">
                    <ButtonComponent
                      iconClass="icon-edit mx-2"
                      buttonType="link"
                      className={"card-btn"}
                      onClick={this.editStory}
                    />
                    <ButtonComponent
                      iconClass="icon-bin mx-2"
                      buttonType="link"
                      className={"card-btn ml-1"}
                      onClick={this.deleteStory}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withCardContainer(withRouter(StoryCardContainer));
export let StoryCardContainerEmbed = withCardContainer(StoryCardContainer);
