/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CancelPauseSubscriptionInput = {|
  clientMutationId?: ?string
|};
export type AccountOverviewCancelPauseSubacriptionMutationVariables = {|
  input: CancelPauseSubscriptionInput
|};
export type AccountOverviewCancelPauseSubacriptionMutationResponse = {|
  +cancelPauseSubscription: ?{|
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
    +success: ?boolean,
  |}
|};
export type AccountOverviewCancelPauseSubacriptionMutation = {|
  variables: AccountOverviewCancelPauseSubacriptionMutationVariables,
  response: AccountOverviewCancelPauseSubacriptionMutationResponse,
|};
*/


/*
mutation AccountOverviewCancelPauseSubacriptionMutation(
  $input: CancelPauseSubscriptionInput!
) {
  cancelPauseSubscription(input: $input) {
    validationErrors {
      key
      message
    }
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CancelPauseSubscriptionInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelPauseSubscriptionPayload",
    "kind": "LinkedField",
    "name": "cancelPauseSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "validationErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountOverviewCancelPauseSubacriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountOverviewCancelPauseSubacriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountOverviewCancelPauseSubacriptionMutation",
    "operationKind": "mutation",
    "text": "mutation AccountOverviewCancelPauseSubacriptionMutation(\n  $input: CancelPauseSubscriptionInput!\n) {\n  cancelPauseSubscription(input: $input) {\n    validationErrors {\n      key\n      message\n    }\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d0c68629ea3ae8d75e23d6ad3ed25b7';

module.exports = node;
