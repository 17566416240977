import React, { Component } from "react";
import "../../css/components/CommonComponents/ShareCardPopUp.css";

class ShareCardPopUp extends Component {
  handleChild = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible, x, y } = this.props;
    return (
      <div
        className="SharelistContainer"
        style={{
          top: y,
          left: Math.max(0, x - 30),
          zIndex: 9999,
        }}
        onClick={this.handleChild}
      >
        {visible ? (
          <div className="sharecard-div">
            <div className="comingSoon-tag">
              <a href={"#comingSoon"}>Coming Soon!</a>
            </div>
            <ul className="shareoptionlist">
              <li>Share this Card</li>
              <li className="sharelist">
                <a href={"#facebookShare"} ref="facebookShare">
                  <img src={require("../../images/socialFB.svg")} alt="" />
                </a>
                <a href={"#socialTwitter"}>
                  <img src={require("../../images/socialTwitter.svg")} alt="" />
                </a>
                <a href={"#enbeddedShare"} ref="enbeddedShare">
                  <img src={require("../../images/socialEmbed.svg")} alt="" />
                </a>
                <a href={"#socialLogo"}>
                  <img src={require("../../images/socialLogo.svg")} alt="" />
                </a>
                <a href={"#socialLink"}>
                  <img src={require("../../images/socialLink.svg")} alt="" />
                </a>
              </li>
              <li className="sendto">or send to</li>
              <li className="nameemail">
                <input type="text" placeholder="Name or Email" />
              </li>
              <li className="email">tflood131@gmail.com</li>
              <li className="email">james131@gmail.com</li>
              <li className="email">tom131@gmail.com</li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

class ShareCardWithSocial extends Component {
  handleChild = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible, x, y } = this.props;
    return (
      <div
        className="SharepopupContainer"
        style={{ top: y, left: x }}
        onClick={this.handleChild}
      >
        {visible ? (
          <ul className="sharepopuplist">
            <li>Share</li>
            <li className="question">
              <div>
                <span>Link to share on facebook and twitter.</span>
              </div>
            </li>
            <li className="copy">
              <button>Copy</button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

class ShareCardWithEmbedded extends Component {
  handleChild = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible, x, y } = this.props;
    return (
      <div
        className="EmbededpopupContainer"
        style={{ top: y, left: x }}
        onClick={this.handleChild}
      >
        {visible ? (
          <ul className="embdedpopuplist">
            <li>Share</li>
            <li className="question">
              <div>
                <code>
                  blockquote class="">lorem ipsom code here html and whatnot
                </code>
              </div>
            </li>
            <li className="copy">
              <button>Copy Embed Code</button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export { ShareCardPopUp, ShareCardWithSocial, ShareCardWithEmbedded };
