/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCardFromBoardInput = {|
  boardCardUseId: string,
  clientMutationId?: ?string,
|};
export type MapsMutationVariables = {|
  input: DeleteCardFromBoardInput
|};
export type MapsMutationResponse = {|
  +deleteCardFromBoard: ?{|
    +cardDeleted: ?boolean
  |}
|};
export type MapsMutation = {|
  variables: MapsMutationVariables,
  response: MapsMutationResponse,
|};
*/


/*
mutation MapsMutation(
  $input: DeleteCardFromBoardInput!
) {
  deleteCardFromBoard(input: $input) {
    cardDeleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCardFromBoardInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteCardFromBoardPayload",
    "kind": "LinkedField",
    "name": "deleteCardFromBoard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardDeleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MapsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MapsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MapsMutation",
    "operationKind": "mutation",
    "text": "mutation MapsMutation(\n  $input: DeleteCardFromBoardInput!\n) {\n  deleteCardFromBoard(input: $input) {\n    cardDeleted\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '287e65b87a9841186d5c8b196b4e575a';

module.exports = node;
