import React, { useState, useEffect, useRef } from "react";
import "../../css/components/Card/D3TextLayoutBabyChart.css";
import * as d3 from "d3";
import HeartFilledImg from "../../images/iconHeartFilled.svg";
import FilledHeartImg from '../../images/iconFilledHeart.svg';

const D3HeartChart = (props) => {
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const clientWidth = 220;
  const clientHeight = 220;
  const centerSpace = 30;
  const parentEle = useRef(null);
  let svg,
    svgG,
    width,
    height = null;
  const [ratio, setRatio] = useState(1);
  const [mounted, setMounted] = useState(true);
  const { isWide, chartData } = props;

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    setMounted(true);

    return () => {
      setMounted(false);
      window.removeEventListener("resize", onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData) {
      init(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const onWindowResize = () => {
    if (!mounted) return;
    init(props);

    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
  };

  const init = (props) => {
    createSvg(props);
  };

  const createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    svg = d3.select(parentEle.current).select("svg#" + id);
    svgG = d3.select("g#" + id + "-g-container");

    updateInfo(props);
    autoAlignSVG();
  };

  const autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
    //  Set the dimensions and margins of the diagram
    width = clientWidth - margin.left - margin.right;
    height = clientHeight - margin.top - margin.bottom;
    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right}
                    ${height + margin.top + margin.bottom}`
      );
    svgG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svgG
      .select("foreignObject")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svgG
      .select("foreignObject")
      .select("#parentHeartDivElement")
      .style("height", height + margin.top + margin.bottom + "px");
  };

  const updateInfo = (props) => {
    const { chartData } = props;
    if (chartData) {
      updateIconAndLabel(chartData, true);
      updateIconAndLabel(chartData, false);
    }
  };
  const updateIconAndLabel = (data, isActive) => {
    let value = isActive ? data.value : data.value2;
    const arrayData = Array.from(new Array(Math.round(value)), (x, i) =>
      value >= i + 1 ? 1 : i + 1 - value
    );
    const parentActiveHeartElement = svgG
      .select("foreignObject")
      .select(isActive ? "#activeHeart" : "#heart");
    const allActiveHeartIcon = parentActiveHeartElement
      .select(".icons")
      .selectAll(".icon")
      .data(arrayData);
    allActiveHeartIcon
      .enter()
      .append('div')
      .merge(allActiveHeartIcon)
      .attr('class', 'icon test-class')
      .style('height', '18px')
      .style('margin', '1px')
      .style('width', '20px')
      .style('background-image', `url(${isActive ? FilledHeartImg : HeartFilledImg})`)
      .style('background-repeat', 'round')
      .style('background-size', 'cover')
      .style('background-repeat', 'no-repeat');

    allActiveHeartIcon.exit().remove();
    const activeHeartLabelEle = parentActiveHeartElement.select(".labels");
    const activeHeartLabelHtml = isActive
      ? "<span>" +
        chartData.label +
        "</span><br/><span style='color:#D35721;'>" +
        chartData.label2 +
        " </span><span>" +
        chartData.label3 +
        "</span>"
      : "<span >" + chartData.label4 + "</span>";
    activeHeartLabelEle.html(activeHeartLabelHtml);
  };
  const id = props.id.replace(/=/g, "");
  return (
    <div
      className="D3TextLayoutBabyChart d-flex flex-column justify-content-center align-items-center h-100 w-100"
      ref={parentEle}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "245px",
          width: "245px",
          padding: "0 16px",
          transform: `scale(${ratio})`,
        }}
      >
        <svg id={id}>
          <g id={id + "-g-container"} className="g-container">
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div
                id="parentHeartDivElement"
                className="d-flex align-items-center justify-content-center flex-column w-100"
              >
                <div
                  id="activeHeart"
                  className="active-heart flex-grow-1 justify-content-end  flex-column d-flex w-100"
                >
                  <div
                    className="icons d-flex flex-wrap-reverse justify-content-center"
                    style={{ padding: '5px 35px' }}
                  />
                  <div className="labels main-text text-center fs-15"/>
                </div>
                <div className="d-flex" style={{ height: centerSpace }}/>
                <div
                  id="heart"
                  className="heart flex-grow-1 flex-column d-flex w-100"
                >
                  <div
                    className="icons d-flex flex-wrap-reverse justify-content-center"
                    style={{ padding: '5px 35px' }}
                  />
                  <div className="labels main-text text-center fs-15"/>
                </div>
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default D3HeartChart;
