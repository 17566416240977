import React, { Component } from "react";
import LogDataDownloadForUserMutation from "../../mutations/LogDataDownloadForUserMutation";
import "../../css/components/Card/DownloadDataCard.css";

class DownloadDataCard extends Component {
  constructor(props) {
    super(props);
    this.clientWidth = 245;
    this.clientHeight = 230;
    this.state = {
      ratio: 1,
    };
  }

  componentDidMount() {
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 230) {
      this.setState({
        ratio: this.size / 230,
      });
    }
    this.mounted = true;
  }

  componentDidUpdate() {
    window.addEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (!this.mounted) return;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 230) {
      this.setState({
        ratio: this.size / 230,
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportedFileName = fileTitle + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    this.logDataDownloadForUser();
  }

  logDataDownloadForUser() {
    const { id } = this.props;
    LogDataDownloadForUserMutation(id, () => {
      console.log("logged download");
    });
  }

  download(familyADI) {
    const headers = {
      indicatorId: "Indicator Id",
      indicatorName: "Indicator Name",
      percentile: "Percentile",
      valueText: "Value",
      unit: "Unit",
    };

    const itemsFormatted = [];

    // format the data
    familyADI.forEach((item) => {
      itemsFormatted.push({
        indicatorId: item.indicatorId,
        indicatorName: item.indicatorName.replace(/,/g, ""), // remove commas to avoid errors
        percentile: item.percentile,
        valueText: item.valueText.replace(/,/g, ""), // remove commas to avoid errors
        unit: item.unit,
      });
    });

    const fileTitle = "family-adi-export"; // or 'my-unique-title'
    // call the exportCSVFile() function to process the JSON and trigger the download
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }

  render() {
    const { familyADI } = this.props;
    const { ratio } = this.state;
    const tr = familyADI.map((o, i) => {
      return (
        <tr key={i}>
          <td>{o.indicatorName}</td>
        </tr>
      );
    });
    return (
      <div
        className="DownloadDataCard h-100 w-100 d-flex flex-grow-1 flex-column justify-content-center align-items-center"
        ref={(ele) => (this.mainParentEle = ele)}
      >
        <div
          style={{
            height: "230px",
            width: "250px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="row mx-2 px-1">
            <table className="table border table-sm m-0 text-left">
              <thead>
                <tr>
                  <th scope="col">Indicators for the Family #ADI</th>
                </tr>
              </thead>
              <tbody>{tr}</tbody>
            </table>
          </div>
          <div className="row mx-2 mt-2 px-1">
            <button
              type="button"
              className="download-btn"
              onClick={() => this.download(familyADI)}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadDataCard;
