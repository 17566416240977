import React, { Component } from "react";
import sponsors1 from "../../images/sponsors/sponsor1.png";
import sponsors2 from "../../images/sponsors/sponsor2.png";
import sponsors3 from "../../images/sponsors/sponsor3.png";
import sponsors4 from "../../images/sponsors/sponsor4.png";
import sponsors5 from "../../images/sponsors/sponsor5.png";
import "../../css/components/CommonComponents/FooterComponent.css";

class FooterComponent extends Component {
  state = {
    sponsorsImageList: [
      {
        image: sponsors1,
        alt: "sponsors1",
      },
      {
        image: sponsors2,
        alt: "sponsors2",
      },
      {
        image: sponsors3,
        alt: "sponsors3",
      },
      {
        image: sponsors4,
        alt: "sponsors4",
      },
      {
        image: sponsors5,
        alt: "sponsors5",
      },
    ],
  };
  startHelp = () => {
    if (window.drift != null) {
      window.drift.api.startInteraction({ interactionId: 50743 });
    }
  };

  render() {
    const { isStory } = this.props;
    const { sponsorsImageList } = this.state;
    return (
      <div className={"footer-container" + (isStory ? " feature-wrap" : "")}>
        <div className="story-footer text-left">
          <div className="ftr-wrapper">
            <div className="ftr-row px-5">
              <div className="col-lg-4 p-5">
                <h3>Our Mission</h3>
                <p>
                  The BroadStreet Network is a cooperative that believes in the
                  power of working together for the common good. By achieving
                  victories in every community across the country, we will leave
                  a better world for the next generation.
                </p>
              </div>
              <div className="col-lg-4 p-5">
                <h3>Behind The Scenes</h3>
                <p>
                  All public good tools are possible thanks to the valuable
                  sponsors, partners, and members of the BroadStreet Network
                  such as&nbsp;
                  <a
                    target="ccshare"
                    href="https://www.franciscanhealth.org"
                    rel="noopener noreferrer"
                  >
                    Franciscan Health
                  </a>
                  . To learn more about how to get involved, visit our&nbsp;
                  <a
                    target="ccshare"
                    href="https://help.broadstreet.io/article/purchase-sponsor-code/"
                    rel="noopener noreferrer"
                  >
                    sponsor page
                  </a>
                  .
                </p>
              </div>
              <div className="col-lg-4 p-5">
                <h3>Our Dedication to You</h3>
                <p>
                  The BroadStreet Network is dedicated to providing tools and
                  the best data possible to those who are working to improve
                  their community. Run a &nbsp;
                  <a
                    target="ccshare"
                    href="https://www.broadstreet.io/board/RUNAREPORT"
                    rel="noopener noreferrer"
                  >
                    report
                  </a>
                  . Explore&nbsp;
                  <a
                    target="ccshare"
                    href="https://www.broadstreet.io/board/EXPLOREMAPS"
                  >
                    maps & data
                  </a>
                  . The&nbsp;
                  <a
                    target="ccshare"
                    href="https://www.broadstreet.io/board/browseindicators"
                  >
                    indicator list
                  </a>
                  &nbsp;is always growing and we are open to requests and
                  collaboration.
                </p>
              </div>
            </div>
            <div className="ftr-row w-100 p-4">
              <div className="col-12 d-flex justify-content-center col-xl-4 justify-content-xl-start align-items-center">
                <a
                  target="ccterms"
                  href={"https://www.broadstreet.io"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../images/logo1.svg")}
                    alt=""
                    className=""
                    height="35px"
                  />
                </a>
              </div>
              <div className="col-12 d-flex  justify-content-center col-xl-8 justify-content-xl-end">
                <ul className="nav ftr-links flex-column flex-md-row">
                  <li className="nav-item p-1">
                    <a
                      className="nav-link"
                      target="aboutip3"
                      rel="noopener noreferrer"
                      href="https://www.broadstreet.io/about-us/"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item p-1">
                    <a
                      className="nav-link"
                      href="#help"
                      tabIndex={0}
                      onClick={this.startHelp}
                    >
                      Help
                    </a>
                  </li>
                  <li className="nav-item p-1">
                    <a
                      className="nav-link"
                      target="ccterms"
                      rel="noopener noreferrer"
                      href="https://learn.broadstreet.io/legal/"
                    >
                      Terms & Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ftr-row w-100 p-4">
              <div className="col-12 d-flex justify-content-center col-xl-3 justify-content-xl-start text-black-50 py-2">
                THANK YOU SPONSORS
              </div>
              <div className="col-12 d-flex  justify-content-center col-xl-9 justify-content-xl-end py-3">
                <ul className="nav justify-content-center flex-md-row">
                  {sponsorsImageList.map((d) => (
                    <li className="nav-item sponsors-image" key={d.alt}>
                      <a
                        className="nav-link p-0"
                        target="ccterms"
                        rel="noopener noreferrer"
                        href="https://help.broadstreet.io/article/purchase-sponsor-code/"
                        key={d.alt}
                      >
                        <img
                          className={"sponsors-image"}
                          key={d.alt}
                          src={d.image}
                          alt={d.alt}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="black-bg" />
        </div>
      </div>
    );
  }
}

export default FooterComponent;
