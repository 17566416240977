import React, { Component } from 'react';
import D3PopulationBlockChart from '../Card/D3PopulationBlockChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3PopulationBlockWideChart extends Component {
  render() {
    const { id, populationBlock, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3PopulationBlockWideChart d-flex w-100 h-100'>
        {(populationBlock && populationBlock.data) &&
        <D3PopulationBlockChart
          id={id + 'wide'}
          key={id + 'populationWideBlock'}
          blockData={populationBlock}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3PopulationBlockWideChart;
