import React, { useState, useEffect, useRef } from 'react';
import '../../css/components/Card/D3TextLayoutBabyChart.css';
import Switch from 'react-switch';
import * as d3 from 'd3';
import FilledHeartImg from '../../images/iconFilledHeart.svg';

const D3HeartChart2 = (props) => {
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const clientWidth = 220;
  const clientHeight = 220;
  const textDetailsHeight = 64;
  const labelTextWidth = 100;
  const toggleHeight = 20;
  const parentEle = useRef(null);
  let svg,
    svgG,
    width,
    height = null;
  const [ratio, setRatio] = useState(1);
  const [mounted, setMounted] = useState(true);
  const [isToggle, setIsToggle] = useState(false);
  const { isWide, chartData } = props;

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    setMounted(true);

    return () => {
      setMounted(false);
      window.removeEventListener("resize", onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData) {
      init(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    // update call
    init(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggle]);

  const onWindowResize = () => {
    if (!mounted) return;
    init(props);

    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
  };

  const init = (props) => {
    createSvg(props);
  };

  const createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    svg = d3.select(parentEle.current).select("svg#" + id);
    svgG = d3.select("g#" + id + "-g-container");
    updateInfo(props);
    autoAlignSVG();
  };

  const autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
    //  Set the dimensions and margins of the diagram
    width = clientWidth - margin.left - margin.right;
    height = clientHeight - margin.top - margin.bottom;
    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right}
                    ${height + margin.top + margin.bottom}`
      );
    svgG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svgG
      .select("foreignObject")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svgG
      .select("foreignObject")
      .select("#parentHeartDivElement")
      .style("height", height + margin.top + margin.bottom + "px");
  };

  const updateInfo = (props) => {
    const { chartData } = props;

    if (chartData) {
      updateTextDetails(chartData);
      updateIconsAndLabels(chartData, true);
      updateIconsAndLabels(chartData, false);
    }
  };
  const updateTextDetails = (data) => {
    const textElement = svgG.select("foreignObject").select("#detailsText");
    const textDetailsHtml =
      "<span>" +
      data.label +
      " </span><span class='main-text fs-15'>" +
      (isToggle ? data.uslabel2 : data.label2) +
      " </span><span>" +
      (isToggle ? data.uslabel3 : data.label3) +
      "</span>";
    textElement.html(textDetailsHtml);
  };
  const updateIconsAndLabels = (data, isOne) => {
    let value = isOne
      ? isToggle
        ? data.usvalue
        : data.value
      : isToggle
      ? data.usvalue2
      : data.value2;
    const arrayData = Array.from(new Array(Math.round(value)), (x, i) =>
      value >= i + 1 ? 1 : i + 1 - value
    );
    const parentHeartElement = svgG
      .select("foreignObject")
      .select(isOne ? "#heart1" : "#heart2");
    const allHeartIconElements = parentHeartElement
      .select(".icons")
      .selectAll(".icon")
      .data(arrayData);
    allHeartIconElements
      .enter()
      .append("div")
      .merge(allHeartIconElements)
      .attr('class', 'icon')
      .style('height', '18px')
      .style('margin', '1px')
      .style('width', (d) => (d === 1 ? '20px' : '10px'))
      .style('background', `url(${FilledHeartImg})`)
      .style('background-size', 'cover')
      .style('background-repeat', 'no-repeat');

    allHeartIconElements.exit().remove();
    const HeartLabelEle = parentHeartElement.select(".labels");
    const labelsHtml =
      "<span class='main-text fs-15'>" +
      (isOne ? data.label4 : data.label6) +
      " </span><br/><span class='details-text' >" +
      (isOne ? data.label5 : data.label7) +
      "</span>";
    HeartLabelEle.html(labelsHtml);
  };
  const id = props.id.replace(/=/g, "");
  const switchTitle = {
    fontSize: "11px",
    fontFamily: "ProximaNova-Regular",
    lineHeight: 1,
    display: "block",
    color: "#989898",
  };
  const handleChange = (checked, e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsToggle(checked);
  };
  return (
    <div
      className="D3TextLayoutBabyChart d-flex flex-column justify-content-center align-items-center h-100 w-100"
      ref={parentEle}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "245px",
          width: "245px",
          padding: "0 16px",
          transform: `scale(${ratio})`,
        }}
      >
        <svg id={id}>
          <g id={id + "-g-container"} className="g-container">
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div
                id="parentHeartDivElement"
                className="d-flex align-items-center justify-content-center flex-column w-100"
              >
                <div
                  id="detailsText"
                  className="details-text w-100 text-left"
                  style={{ height: textDetailsHeight }}
                />
                <div
                  id="heart1"
                  className="heart1 flex-grow-1 justify-content-center align-items-center d-flex w-100"
                >
                  <div
                    style={{ width: labelTextWidth }}
                    className="labels text-left fs-15"
                  />
                  <div
                    style={{ width: clientHeight - labelTextWidth }}
                    className="icons flex-grow-1  d-flex flex-wrap-reverse"
                  />
                </div>

                <div
                  id="heart2"
                  className="heart1 flex-grow-1 d-flex w-100 justify-content-center align-items-center"
                >
                  <div
                    style={{ width: labelTextWidth }}
                    className="labels text-left fs-15"
                  />
                  <div
                    style={{ width: clientHeight - labelTextWidth }}
                    className="icons flex-grow-1 d-flex flex-wrap-reverse"
                  />
                </div>
                <div
                  className="d-flex flex-grow-1 align-items-center justify-content-center"
                  style={{ height: toggleHeight + "px" }}
                >
                  <div className="flex-grow-1 w-100 fs-15 text-right">
                    <small style={switchTitle} className={"text-right"}>
                      Our Community
                    </small>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center w-100 px-2 h-100">
                    <Switch
                      className="react-switch"
                      onChange={handleChange}
                      checked={isToggle}
                      id="togglePie"
                      height={18}
                      width={35}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#989898"
                      onHandleColor="#fff"
                    />
                  </div>
                  <div className="flex-grow-1 w-100 fs-15 text-left">
                    <small style={switchTitle} className={"text-left"}>
                      U.S.
                    </small>
                  </div>
                </div>
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default D3HeartChart2;
