/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CardEmbedRootQueryVariables = {|
  input: string
|};
export type CardEmbedRootQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +jsonid: ?string,
    +cardPosition: number,
    +frontFacingComponent: {|
      +componentType: string,
      +name: string,
    |},
    +statistics: ?{|
      +id: string,
      +jsonData: string,
    |},
    +isFavorite: ?boolean,
    +subComponentType: ?string,
  |}
|};
export type CardEmbedRootQuery = {|
  variables: CardEmbedRootQueryVariables,
  response: CardEmbedRootQueryResponse,
|};
*/


/*
query CardEmbedRootQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    jsonid
    cardPosition
    frontFacingComponent {
      componentType
      name
      id
    }
    statistics {
      id
      jsonData
    }
    isFavorite
    subComponentType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardCardStatisticsNode",
  "kind": "LinkedField",
  "name": "statistics",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsonData",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CardEmbedRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CardEmbedRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CardEmbedRootQuery",
    "operationKind": "query",
    "text": "query CardEmbedRootQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    jsonid\n    cardPosition\n    frontFacingComponent {\n      componentType\n      name\n      id\n    }\n    statistics {\n      id\n      jsonData\n    }\n    isFavorite\n    subComponentType\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f9e00928abb1af13184e433a1548a75';

module.exports = node;
