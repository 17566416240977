/* /src/components/BroadStreetServiceAreaMap.js
   Maps for BroadStreet Service Area Selector */
/* Adapted from work we did for the
     BroadStreet Community Health Dashboard v0.16.5 -
     exporting service area map for a CHNA report */
/* To use, pass three arrays of GeoJSON feature strings like this:
   <BroadStreetServiceAreaMap zipcodes={this.state.zipGeoJsonArray}
     counties={this.state.countyGeoJsonArray}
     places={this.state.placeGeoJsonArray} />

   GeoJSON feature strings come from our API calls
   getZctaForCounties, getZctaGeoJsonQuery,
   getCountyGeoJsonQuery, getPlaceGeoJsonQuery

   Example data are in files testCounties.json, testPlaces.json,
     testZipcodes.json to use:
     import testCounties from '../maps/testCounties.json';
     import testZipcodes from '../maps/testZipcodes.json';
     import testPlaces from '../maps/testPlaces.json';

   Mapbox GL mapping library must be loaded within the html page for the app
*/
import React from 'react';
import PropTypes from 'prop-types';
import '../../css/components/BroadStreetServiceAreaMap/BroadStreetServiceAreaMap.css';

const colors_def = [
  'rgba(0, 142, 170, 1)',
  'rgba(0, 143, 170, 0.4)',
  'rgba(140, 179, 105, 1)',
  'rgba(140, 179, 105, 0.44)',
  'rgba(66, 75, 84, 1)',
  'rgba(66, 75, 84, 0.15)'
];

/* Utility function to convert from array of Relay GraphQL nodes
   returned from a query in an edges property
   into array of embedded GeoJSON features strings.
   Also works with flat array of GeoJSON feature strings */
function extractGeoJSONfeaturesFromEdges(edges) {
  if (edges != null && edges[0] != null) {
    if (edges && edges[0].node) {
      // parse GeoJSON property within each node object
      edges = edges.map(x => JSON.parse(x.node.geojsonFeature));
    }
    else if (edges && edges[0]) {
      // handle a flat array of GeoJSON features as strings also
      edges = edges.map(x => JSON.parse(x));
    }
  }

  /* return original array if no embedded features found */
  //console.log(edges);
  return edges;
}

class BroadStreetServiceAreaMap extends React.Component {
  constructor(props) {
    super(props);
    this.zipcodes = [];
    this.places = [];
    this.counties = [];
    this.map = null;
    this.renderMap = this.renderMap.bind(this);
  }

  componentDidUpdate() {
    /* called after a new render (e.g. parent updated data passed to props) */
    const {isEditingArea, zipcodes, places, counties} = this.props;
    if (isEditingArea) {
      this.zipcodes = extractGeoJSONfeaturesFromEdges(zipcodes);
      this.places = extractGeoJSONfeaturesFromEdges(places);
      this.counties = extractGeoJSONfeaturesFromEdges(counties);
      this.renderMap();
    }
  }

  componentDidMount() {
    /* called after component is first rendered to the DOM */
    const {isEditingArea, zipcodes, places, counties} = this.props;
    if (!isEditingArea) {
      this.zipcodes = extractGeoJSONfeaturesFromEdges(zipcodes);
      this.places = extractGeoJSONfeaturesFromEdges(places);
      this.counties = extractGeoJSONfeaturesFromEdges(counties);
    }
    this.renderMap();
  }

  componentWillUnmount() {
    if (this.map != null) {
      this.map.remove();
    }
  }

  render() {
    const {id} = this.props;
    return (
      <div id={'serviceAreaMap' + id} className="broadStreetServiceAreaMap"/>
    );
  }

  renderMap() {
    const mapboxgl = window.mapboxgl;
    const {id} = this.props;
    if (mapboxgl) {
      if (this.map == null) {
        this.map = new mapboxgl.Map({
          container: 'serviceAreaMap' + id,
          style: 'https://api.maptiler.com/maps/positron/style.json?key=92mrAH6NBXO3jvPRMmT9',
          center: [-98.585522, 39.8333333],
          zoom: 2.2,
          preserveDrawingBuffer: true
        });
        this.map.on('load', this.addShapes);
      } else {
        if (this.map.loaded()) {
          this.addShapes();
        }
        else {
          this.map.on('render', this.addShapesAfterNextFrame);
        }
      }
    }
  }

  addShapesAfterNextFrame = () => {
    this.map.off('render', this.addShapesAfterNextFrame);
    this.addShapes();
  };

  /*addCountyLayer = (z) => {
      var map = this.map;
      if (map.getSource("counties") == null) {
          map.addSource("counties", {
              "type": "geojson",
              "data": {
                  "type": "FeatureCollection",
                  "features": []
              }
          });
          map.addLayer({
              "id": "counties",
              "type": "fill",
              "source": "counties",
              'layout': {},
              'paint': {
                  'fill-outline-color': colors_def[z * 2],
                  'fill-color': colors_def[z * 2 + 1]
              }
          }, "highway_name_other");
          map.addLayer({
              "id": "counties_outline",
              "type": "line",
              "source": "counties",
              'layout': {},
              'paint': {
                  'line-color': colors_def[z * 2],
                  'line-width': 2
              }
          }, "highway_name_other");
      }
  };*/

  addLayer = (source, z, before) => {
    const map = this.map;
    if (map.getSource(source) == null) {
      map.addSource(source, {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.addLayer({
        'id': source,
        'type': 'fill',
        'source': source,
        'layout': {},
        'paint': {
          'fill-outline-color': colors_def[z * 2],
          'fill-color': colors_def[z * 2 + 1]
        }
      }, 'highway_name_other');
      map.addLayer({
        'id': source + '_outline',
        'type': 'line',
        'source': source,
        'layout': {},
        'paint': {
          'line-color': colors_def[z * 2],
          'line-width': 2
        }
      }, 'highway_name_other');
    } else {
      const fill_layer = map.getLayer(source);
      const outline_layer = map.getLayer(source + '_outline');
      if (fill_layer != null) {
        map.setPaintProperty(source, 'fill-outline-color', colors_def[z * 2]);
        map.setPaintProperty(source, 'fill-color', colors_def[z * 2 + 1]);
      }
      if (outline_layer != null) {
        map.setPaintProperty(source + '_outline', 'line-color', colors_def[z * 2]);
      }
      if (before != null) {
        map.moveLayer(source, before);
        map.moveLayer(source + '_outline', before);
      } else {
        map.moveLayer(source);
        map.moveLayer(source + '_outline');
      }
    }
  };

  addShapes = () => {
    const {typeOfArea} = this.props;
    const mapboxgl = window.mapboxgl;
    const map = this.map;
    const bounds = new mapboxgl.LngLatBounds();
    //var colors_def = ["#018EAA", '#5A5A5A', '#8CB369', '#424B54'];
    //var center;
    const paths = [];

    // render ZCTA shapes on map
    if (!this.zipcodes) {
      this.zipcodes = [];
    }

    // initialize layers for ZCTA, counties, places
    // order and color based on area primary geography type
    if (typeOfArea === 'countylist') {
      this.addLayer('zipcodes', 2, 'counties');
      this.addLayer('places', 1, 'counties');
      this.addLayer('counties', 0);
    } else if (typeOfArea === 'placelist') {
      this.addLayer('counties', 2, 'places');
      this.addLayer('zipcodes', 1, 'places');
      this.addLayer('places', 0);
    } else {
      this.addLayer('counties', 2, 'zipcodes');
      this.addLayer('places', 1, 'zipcodes');
      this.addLayer('zipcodes', 0);
    }

    const placesFeatureCollection = {
      'type': 'FeatureCollection',
      'features': []
    };

    const zctaFeatureCollection = {
      'type': 'FeatureCollection',
      'features': []
    };

    const countiesFeatureCollection = {
      'type': 'FeatureCollection',
      'features': []
    };

    let coordinates, path_m, i, k;
    this.zipcodes.forEach(function(object, index) {
      //var boundsZip = new google.maps.LatLngBounds();
      zctaFeatureCollection.features.push(object);
      if (object.geometry.type === 'MultiPolygon') {
        // process each shape in a MultiPolygon feature separately
        for (k = 0; k < object.geometry.coordinates.length; k++) {
          path_m = object.geometry.coordinates[k];
          for (var l = 0; l < path_m.length; l++) {
            const path = path_m[l];
            coordinates = [];
            for (i = 0; i < path.length; i++) {
              coordinates.push({lat: parseFloat(path[i][1]), lon: parseFloat(path[i][0])});
              if (i !== 0) {
                bounds.extend(new mapboxgl.LngLat(parseFloat(path[i][0]), parseFloat(path[i][1])));
                //boundsZip.extend(new
                // google.maps.LatLng(parseFloat(path[i][1]),parseFloat(path[i][0])));
              }

            }
            paths.push(coordinates);
          }
        }
      } else {
        // regular Polygon features contain only one shape
        coordinates = [];
        for (k = 0; k < object.geometry.coordinates.length; k++) {
          path_m = object.geometry.coordinates[k];
          for (i = 0; i < path_m.length; i++) {
            coordinates.push({lat: parseFloat(path_m[i][1]), lon: parseFloat(path_m[i][0])});
            if (i !== 0) {
              bounds.extend(new mapboxgl.LngLat(parseFloat(path_m[i][0]), parseFloat(path_m[i][1])));
              //boundsZip.extend(new
              // google.maps.LatLng(parseFloat(path_m[i][1]),parseFloat(path_m[i][0])));
            }
          }
        }
        //var center = {};
        paths.push(coordinates);
      }
      // draw name of ZCTA over shape?  messy for small ZCTA around cities
      // console.log({x: boundsZip.getCenter().lat(), y: boundsZip.getCenter().lng(), "label":
      // data_zip[index] });
      /* var marker = new google.maps.Marker({
         position: {lat: boundsZip.getCenter().lat(), lng: boundsZip.getCenter().lng()},
         map: map,
         title: 'Hello World!'
       });*/
      // data_map.push({x: boundsZip.getCenter().lat(), y: boundsZip.getCenter().lng(), "label":
      // data_zip[index]});
    });
    const zctaSource = map.getSource('zipcodes');
    zctaSource.setData(zctaFeatureCollection);

    /*


      paths.forEach(function(item){
        var polygon = new google.maps.Polygon({
          paths: item,
          strokeColor: colors_def[0],
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: colors_def[0],
          fillOpacity: 0.6
        });
        polygon.setMap(map);
      });
      */
    // render place shapes on map
    if (!this.places) {
      this.places = [];
    }

    this.places.forEach(function(object) {
      placesFeatureCollection.features.push(object);
    });
    const placesSource = map.getSource('places');
    placesSource.setData(placesFeatureCollection);

    /*
    if(object.geometry.type=="MultiPolygon"){
      for(var m=0; m<object.geometry.coordinates.length;m++){
        var coordinates = Array();
        for(var i=0; i<object.geometry.coordinates[m][0].length;i++){
          coordinates.push({lat:parseFloat(object.geometry.coordinates[m][0][i][1]),lon:parseFloat(object.geometry.coordinates[m][0][i][0])});
          bounds.extend(new google.maps.LatLng(parseFloat(object.geometry.coordinates[m][0][i][1]),parseFloat(object.geometry.coordinates[m][0][i][0])));
        }
        var polygon = new google.maps.Polygon({
          paths: coordinates,
          strokeColor: colors_def[2],
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: colors_def[2],
          fillOpacity: 0.44
        });
        polygon.setMap(map);
        polygon.zIndex=1;
      }
    }
    else {
      var coordinates = Array();
      for(var i=0; i< object.geometry.coordinates[0].length;i++){
        coordinates.push({lat:parseFloat(object.geometry.coordinates[0][i][1]),lon:parseFloat(object.geometry.coordinates[0][i][0])});
        bounds.extend(new google.maps.LatLng(parseFloat(object.geometry.coordinates[0][i][1]),parseFloat(object.geometry.coordinates[0][i][0])));
      }
      var polygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: colors_def[2],
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: colors_def[2],
        fillOpacity: 0.4
      });
      polygon.setMap(map);
      polygon.zIndex=1;

    }
  });

*/

    // render county shapes on map
    if (!this.counties) {
      this.counties = [];
    }
    this.counties.forEach(function(object) {
      countiesFeatureCollection.features.push(object);
    });
    const countiesSource = map.getSource('counties');
    countiesSource.setData(countiesFeatureCollection);
    /*
    if(object.geometry.type=="MultiPolygon"){
      for(var m=0; m<object.geometry.coordinates.length;m++){
        var coordinates = Array();
        for(var i=0; i<object.geometry.coordinates[m][0].length;i++){
          coordinates.push({lat:parseFloat(object.geometry.coordinates[m][0][i][1]),lon:parseFloat(object.geometry.coordinates[m][0][i][0])});
          bounds.extend(new google.maps.LatLng(parseFloat(object.geometry.coordinates[m][0][i][1]),parseFloat(object.geometry.coordinates[m][0][i][0])));
        }
        var polygon = new google.maps.Polygon({
          paths: coordinates,
          strokeColor: colors_def[1],
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: 'transparent',
          fillOpacity: 0.3
        });
        polygon.setMap(map);
        polygon.zIndex=1;
      }
    }
    else {
      var coordinates = Array();
      for(var i=0; i<object.geometry.coordinates[0].length;i++){
        coordinates.push({lat:parseFloat(object.geometry.coordinates[0][i][1]),lon:parseFloat(object.geometry.coordinates[0][i][0])});
        bounds.extend(new google.maps.LatLng(parseFloat(object.geometry.coordinates[0][i][1]),parseFloat(object.geometry.coordinates[0][i][0])));
      }
      var polygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: colors_def[1],
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: 'transparent',
        fillOpacity: 0.3
      });
      polygon.setMap(map);
      polygon.zIndex=1;

    }
  });
  */
    // zoom and center map around bounds of rendered shapes
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  };
}

BroadStreetServiceAreaMap.propTypes = {
  zipcodes: PropTypes.array,
  counties: PropTypes.array,
  places: PropTypes.array
};
export default BroadStreetServiceAreaMap;
