/* /src/components/IndicactorMap.js
   Maps for BroadStreet Indicators
   Fill maps with 1-4 categories of shaded polygons
   Bubble maps with a continuous (sqrt scale) circle radius */
/* Adapted from work we did for the
     BroadStreet Community Health Dashboard v0.16.5 -
     exporting service area map for a CHNA report */
/* To use, pass two arrays of GeoJSON feature strings like this:
   <IndicatorMap
     id="indicatorMapCard1234"
     indicatorShapes={this.state.indicatorGeoJsonArray}
     areaPrimaryShapes={this.state.areaGeoJsonArray}
     palette={['#CCCCCC', '#D6EDEF', '#7AC6CC', '#00939D', '#005D63']}
     typeOfMap={"bubbleMap"|"fillMap"}
     extent={[-100, 42, -99.5, 42.1]}
   />

   GeoJSON feature strings come from our GraphQL API calls

   Mapbox GL mapping library must be loaded within the html page for the app
*/
import React from "react";
import * as d3 from "d3";
import { fetchIndicatorDataForArea } from "../../modules/mapboxgl/fetchIndicatorData";
import "../../css/components/IndicatorMap/IndicatorMap.css";

/* Utility function to convert from array of Relay GraphQL nodes
   returned from a query in an edges property
   into array of embedded GeoJSON features strings.
   Also works with flat array of GeoJSON feature strings */
function extractGeoJSONfeaturesFromEdges(edges) {
  if (edges != null && edges[0] != null) {
    if (edges && edges[0].node) {
      // parse GeoJSON property within each node object
      if (edges[0].node.geojsonWithProperties) {
        edges = edges.map((x) => JSON.parse(x.node.geojsonWithProperties));
      } else {
        edges = edges.map((x) => JSON.parse(x.node.geojsonFeature));
      }
    } else if (edges && edges[0]) {
      // handle a flat array of GeoJSON features as strings also
      edges = edges.map((x) => JSON.parse(x));
    }
  }

  /* return original array if no embedded features found */
  return edges;
}

class IndicactorMap extends React.Component {
  constructor(props) {
    super(props);

    this.indicator_shapes = [];
    this.counties = [];
    this.map = null;
    this.renderMap = this.renderMap.bind(this);
  }

  componentDidUpdate() {
    /* called after a new render (e.g. parent updated data passed to props) */
    //this.renderMap();
  }

  componentDidMount() {
    const { indicatorShapes, areaPrimaryShapes, palette } = this.props;
    /* called after component is first rendered to the DOM */
    this.indicator_shapes = extractGeoJSONfeaturesFromEdges(indicatorShapes);
    this.area_primary_shapes =
      extractGeoJSONfeaturesFromEdges(areaPrimaryShapes);
    this.renderMap();
    const { updateLegends, indicators } = this.props;
    if (indicators && indicators.length) {
      const legends =
        indicators[0].node.indicator.mapLegend !== null
          ? indicators[0].node.indicator.mapLegend.edges
          : null;
      const colors = palette;
      const unitOfMeasure =
        indicators[0].node.indicator.dataDictionaryVariable != null
          ? indicators[0].node.indicator.dataDictionaryVariable.unitOfMeasure
          : null;
      if (updateLegends) {
        updateLegends(legends, colors, unitOfMeasure);
      }
    }
  }

  componentWillUnmount() {
    if (this.map != null) {
      this.map.remove();
    }
  }

  render() {
    const { id } = this.props;
    return <div id={id} className="indicatorMap"></div>;
  }

  renderMap() {
    const { id, extent } = this.props;
    var mapboxgl = window.mapboxgl;
    if (this.map == null) {
      var extents = extent;
      if (extents != null) {
        var bounds = [
          [extents[0], extents[1]],
          [extents[2], extents[3]],
        ];
        var center = [
          (extents[0] + extents[2]) / 2,
          (extents[1] + extents[3]) / 2,
        ];
        this.map = new mapboxgl.Map({
          container: id,
          style:
            "https://api.maptiler.com/maps/positron/style.json?key=92mrAH6NBXO3jvPRMmT9",
          center: center,
          zoom: 7,
          bounds: bounds,
          preserveDrawingBuffer: true,
        });
      } else {
        this.map = new mapboxgl.Map({
          container: id,
          style:
            "https://api.maptiler.com/maps/positron/style.json?key=92mrAH6NBXO3jvPRMmT9",
          center: [-98.585522, 39.8333333],
          zoom: 9,
          preserveDrawingBuffer: true,
        });
      }
      this.map.on("load", this.addShapes);
    } else {
      if (this.map.loaded()) {
        this.addShapes();
      } else {
        this.map.on("render", this.addShapesAfterNextFrame);
      }
    }
  }

  scaleDataForBubbles = (indicatorDataForArea) => {
    // find min and max observed value for indicator
    // we want min to be no more than zero for now
    var i_min = 0.0,
      i_max = 0.0;
    for (var i = 0; i < indicatorDataForArea.length; i++) {
      if (i_min > indicatorDataForArea[i][1]) {
        i_min = indicatorDataForArea[i][1];
      } else if (i_max < indicatorDataForArea[i][1]) {
        i_max = indicatorDataForArea[i][1];
      }
    }
    // build sqrt scale from observations and desired circle radius range
    var indicatorScale = d3
      .scaleLinear()
      .domain([i_min, i_max])
      .range([0.1, 9]);
    //console.log(i_min, i_max);
    //var indicatorScale = d3.scaleSqrt().domain([i_min, i_max]).range([0.1, 9]);
    // apply scale to indicator data for area
    return indicatorDataForArea.map((x, i) => {
      return [x[0], indicatorScale(x[1])];
    });
  };

  addShapesAfterNextFrame = () => {
    this.map.off("render", this.addShapesAfterNextFrame);
    this.addShapes();
  };

  addShapes = async () => {
    const { extent, palette, savedAreaId, indicators, typeOfMap } = this.props;
    /*var mapboxgl = window.mapboxgl;*/
    var map = this.map;
    //var bounds = new mapboxgl.LngLatBounds();
    var extents = extent;
    //var color_palette = ["#018EAA", '#5A5A5A', '#8CB369'];
    var color_palette = palette;
    /*
    [
        "#D3D3D3",
        "#78A22F",
        "#6A8D73",
        "#D5DED6",
        "#FFA987",
        "#FF6B35",
        ];
    */
    /*var paths = [];*/
    var indicatorId, savedAreaID;
    savedAreaID = savedAreaId;
    if (indicators != null && indicators[0] != null) {
      indicatorId = indicators[0].node.indicator.id;

      // initialize layers for area shapes and indicator shapes
      if (map.getSource("indicator_shape_source") == null) {
        map.addSource("indicator_shape_source", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
          /* NOTE: Setting tolerance too high discards small complex shapes completely */
          tolerance: 0.05,
        });

        if (typeOfMap === "fillMap") {
          var indicatorDataForArea = await fetchIndicatorDataForArea(
            indicatorId,
            savedAreaID,
            true
          );
          if (indicatorDataForArea == null) {
            indicatorDataForArea = [["0"], ["1"], ["2"], ["3"], ["4"]];
            console.log("No data found for fillMap");
          }
          if (color_palette == null) {
            color_palette = [
              "#CCCCCC",
              "#D6EDEF",
              "#7AC6CC",
              "#00939D",
              "#005D63",
            ];
            console.log("Color palette missing for fillMap");
          }
          map.addLayer(
            {
              id: "indicator_fill",
              type: "fill",
              source: "indicator_shape_source",
              layout: {},
              paint: {
                /*
              NOTE: fill-outline-color is outline single-pixel line only, does not scale properly
              'fill-outline-color':
              ,*/
                "fill-opacity": 0.4,
                "fill-color": [
                  "match",
                  ["get", "AFFGEOID"],
                  indicatorDataForArea[0],
                  color_palette[0],
                  indicatorDataForArea[1],
                  color_palette[1],
                  indicatorDataForArea[2],
                  color_palette[2],
                  indicatorDataForArea[3],
                  color_palette[3],
                  indicatorDataForArea[4],
                  color_palette[4],
                  /* other */ "transparent",
                ],
              },
            },
            "highway_name_other"
          );
          map.addLayer(
            {
              id: "indicator_outline",
              type: "line",
              source: "indicator_shape_source",
              layout: {},
              paint: {
                "line-color": [
                  "match",
                  ["get", "AFFGEOID"],
                  indicatorDataForArea[0],
                  color_palette[0],
                  indicatorDataForArea[1],
                  color_palette[1],
                  indicatorDataForArea[2],
                  color_palette[2],
                  indicatorDataForArea[3],
                  color_palette[3],
                  indicatorDataForArea[4],
                  color_palette[4],
                  /* other */ "transparent",
                ],
                "line-width": 1,
              },
            },
            "highway_name_other"
          );
        } else {
          // indicator_shape_source is centroid point features
          //   tagged with AFFGEOID
          indicatorDataForArea = await fetchIndicatorDataForArea(
            indicatorId,
            savedAreaID,
            false
          );
          var circle_list = this.scaleDataForBubbles(indicatorDataForArea);
          //console.log(circle_list);
          var circle_radius_expression = ["match", ["get", "AFFGEOID"]];
          var circle_visibility_expression = ["match", ["get", "AFFGEOID"]];
          // add pairs of geoid and circle radius to match expression
          circle_list.forEach((x) => {
            circle_radius_expression.push(x[0]);
            circle_radius_expression.push(x[1]);
            if (x[1] > 0) {
              circle_visibility_expression.push(x[0]);
              circle_visibility_expression.push(1.0);
            }
          });
          // add default circle radius to end expression
          circle_radius_expression.push(0.1);
          circle_visibility_expression.push(0.0);
          var zoom_interpolate_expression = [
            "let",
            "v_circle_rad",
            circle_radius_expression,
            [
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              ["*", 3, ["var", "v_circle_rad"]],
              20,
              ["*", 10, ["var", "v_circle_rad"]],
            ],
          ];
          //console.log(circle_radius_expression);
          map.addLayer(
            {
              id: "indicator_circles",
              type: "circle",
              source: "indicator_shape_source",
              layout: {},
              paint: {
                "circle-stroke-color": "#424B54",
                "circle-stroke-opacity": circle_visibility_expression,
                "circle-stroke-width": 1,
                "circle-blur": 0,
                "circle-radius": zoom_interpolate_expression,
                "circle-opacity": 0.7,
                "circle-color": "#424B54",
              },
            },
            "highway_name_other"
          );
        }
        // render indicator data shapes on map
        var indicatorSource = map.getSource("indicator_shape_source");
        if (!this.indicator_shapes) {
          this.indicator_shapes = [];
        }
        var indicatorFeatureCollection = {
          type: "FeatureCollection",
          features: this.indicator_shapes,
        };
        indicatorSource.setData(indicatorFeatureCollection);
      }
    }

    // set up layer with styles for primary geography shapes on map
    if (map.getSource("area_shape_source") == null) {
      map.addSource("area_shape_source", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        tolerance: 0.05,
      });
      map.addLayer(
        {
          id: "area_shape_line",
          type: "line",
          source: "area_shape_source",
          layout: {},
          paint: {
            "line-color": "rgba(152, 152, 152, 1)",
            "line-width": 2,
          },
        },
        "highway_name_other"
      );
    }

    // render area primary geography shapes on map
    if (!this.area_primary_shapes) {
      this.area_primary_shapes = [];
    }
    var areaFeatureCollection = {
      type: "FeatureCollection",
      features: this.area_primary_shapes,
    };
    var areaSource = map.getSource("area_shape_source");
    areaSource.setData(areaFeatureCollection);

    // zoom and center map around bounds of rendered shapes
    if (extents != null) {
      map.fitBounds([
        [extents[0], extents[1]],
        [extents[2], extents[3]],
      ]);
    }
  };
}

export default IndicactorMap;
