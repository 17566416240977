/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BuySubscriptionCouponsInput = {|
  qty: number,
  subscriptionCouponCode: string,
  paymentMethod?: ?string,
  useCreditAmount?: ?number,
  clientMutationId?: ?string,
|};
export type PurchaseCouponsPopupMutationVariables = {|
  input: BuySubscriptionCouponsInput
|};
export type PurchaseCouponsPopupMutationResponse = {|
  +buySubscriptionCoupons: ?{|
    +clientSecret: ?string,
    +discountText: ?string,
    +orderAmount: ?number,
    +paymentIntentId: ?string,
    +success: ?boolean,
  |}
|};
export type PurchaseCouponsPopupMutation = {|
  variables: PurchaseCouponsPopupMutationVariables,
  response: PurchaseCouponsPopupMutationResponse,
|};
*/


/*
mutation PurchaseCouponsPopupMutation(
  $input: BuySubscriptionCouponsInput!
) {
  buySubscriptionCoupons(input: $input) {
    clientSecret
    discountText
    orderAmount
    paymentIntentId
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BuySubscriptionCouponsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BuySubscriptionCouponsPayload",
    "kind": "LinkedField",
    "name": "buySubscriptionCoupons",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentIntentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseCouponsPopupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchaseCouponsPopupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PurchaseCouponsPopupMutation",
    "operationKind": "mutation",
    "text": "mutation PurchaseCouponsPopupMutation(\n  $input: BuySubscriptionCouponsInput!\n) {\n  buySubscriptionCoupons(input: $input) {\n    clientSecret\n    discountText\n    orderAmount\n    paymentIntentId\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec6aed7e1f53d7c594147ce29179649e';

module.exports = node;
