import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "../../css/components/CommonComponents/MoreOptionPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

class MoreOptionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favouriteText: "",
      isFavourite: true,
      isLoading: false,
    };
  }

  handleChild = (e) => {
    e.stopPropagation();
  };

  shareButtonClick = () => {
    const { handleShareCardPopUp, x, y } = this.props;
    handleShareCardPopUp(x, y - 43);
  };

  testForLogin = () => {
    const { userData, handleLoginPopUp } = this.props;
    if (
      (userData == null ||
        userData.currentUser == null ||
        userData.currentUser.edges.length === 0) &&
      handleLoginPopUp != null
    ) {
      // prompt for login if needed
      handleLoginPopUp();
      return true;
    }
    return false;
  };

  handleCopyCardToCollection = () => {
    const { copyCardToCollection, x, y } = this.props;
    if (this.testForLogin()) {
      return;
    }
    copyCardToCollection(x, y);
  };

  handleCardRemoveFromFavourites = () => {
    const { cardId, handleReRenderCard } = this.props;
    const { isLoading, isFavourite } = this.state;
    if (this.testForLogin()) {
      return;
    }
    if (isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    if (isFavourite) {
      // Remove from favorites
      const mutation = graphql`
        mutation MoreOptionPopupAMutation(
          $input: RemoveCardFromFavoritesInput!
        ) {
          removeCardFromFavorites(input: $input) {
            cardRemoved
          }
        }
      `;
      const variables = { input: { boardCardUseId: cardId } };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          this.setState({
            isLoading: false,
          });
          handleReRenderCard(cardId);
        },
        onError: (err) => console.error(err),
      });
    }
  };

  handleCopyCardToFavourites = () => {
    const { cardId, handleReRenderCard } = this.props;
    const { isLoading, isFavourite } = this.state;
    if (this.testForLogin()) {
      return;
    }
    if (isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    if (isFavourite) {
      // Add to favorites
      const mutation = graphql`
        mutation MoreOptionPopupBMutation($input: CopyCardToFavoritesInput!) {
          copyCardToFavorites(input: $input) {
            boardCardUseNode {
              id
            }
          }
        }
      `;
      const variables = { input: { boardCardUseId: cardId } };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          this.setState({
            isLoading: false,
          });
          handleReRenderCard(cardId);
        },
        onError: (err) => console.error(err),
      });
    }
  };

  handleCardRemoveFromCollections = () => {
    const { removeCardFromCollections } = this.props;
    const box = ReactDOM.findDOMNode(
      this.refs["removeCardButton"]
    ).getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft + 50;
    removeCardFromCollections(left, top);
  };

  ReportErrorClick = () => {
    const { handleReportErrorPopUp, x, y } = this.props;
    handleReportErrorPopUp(x - 60, y);
  };

  //isEditHashTagPopUpVisible
  render() {
    const {
      isFavorite,
      isFavorites,
      isVisible,
      isCollections,
      x,
      y,
      isEditingEnabled,
      isDeleteOption,
      onDeleteClick,
      onDeleteAndReplaceClick,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div
        className="MoreoptionlistContainer"
        style={{ top: y + 70, left: x - 55, zIndex: 9999 }}
        onClick={this.handleChild}
      >
        {isVisible ? (
          <div>
            {
              <ul className={`moreoptionlist`}>
                {isDeleteOption ? (
                  <Fragment>
                    <li onClick={() => onDeleteClick()}>Delete</li>
                    <li onClick={() => onDeleteAndReplaceClick()}>
                      Delete and Replace
                    </li>
                  </Fragment>
                ) : (
                  <Fragment>
                    <li
                      className={isEditingEnabled ? "disable-option-list" : ""}
                      ref="shareButton"
                      onClick={this.shareButtonClick}
                    >
                      Share this Card
                    </li>
                    <li
                      className={isEditingEnabled ? "disable-option-list" : ""}
                      onClick={this.handleCopyCardToCollection}
                    >
                      Save Card
                    </li>
                    {isFavorites ? (
                      isFavorite ? (
                        <li
                          className={
                            isEditingEnabled
                              ? "disable-option-list"
                              : "" + (isLoading ? " disable-heart-option" : "")
                          }
                          onClick={this.handleCardRemoveFromFavourites}
                        >
                          Remove from Favorites
                        </li>
                      ) : (
                        <li
                          className={
                            isEditingEnabled
                              ? "disable-option-list"
                              : "" + (isLoading ? " disable-heart-option" : "")
                          }
                          onClick={this.handleCopyCardToFavourites}
                        >
                          Add Card to Favorites
                        </li>
                      )
                    ) : null}
                    {isCollections ? (
                      <li
                        className={
                          isEditingEnabled ? "disable-option-list" : ""
                        }
                        ref="removeCardButton"
                        onClick={this.handleCardRemoveFromCollections}
                      >
                        Remove Card
                      </li>
                    ) : null}
                    <li
                      className={isEditingEnabled ? "disable-option-list" : ""}
                      ref="reportErrorButton"
                      onClick={this.ReportErrorClick}
                    >
                      Report Error or Abuse
                    </li>
                    {/*<li>Download-<strong>Coming Soon!</strong></li>*/}
                  </Fragment>
                )}
              </ul>
            }
          </div>
        ) : null}
      </div>
    );
  }
}

export default MoreOptionPopup;
