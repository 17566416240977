import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3WordCloudChart from './D3WordCloudChart';
import cloud from '../../images/comment-black-oval-bubble-shape.svg';

class D3WordCloudWideChart extends Component {
  render() {
    const { id, wordCloudChartData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3WordCloudWideChart d-flex w-100 h-100 position-relative'>
        {(wordCloudChartData && wordCloudChartData.data) &&
        <D3WordCloudChart
          id={id + 'wide'}
          image={cloud}
          isWide={true}
          imageHeight={200}
          wordCloudChartData={wordCloudChartData}
          imageWidth={200}/>
        }
      </div>

    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3WordCloudWideChart;
