import React, { Component } from 'react';
import D3AreaAndLineChart from '../Card/D3AreaAndLineChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3AreaAndLineWideChart extends Component {
  render() {
    const { id, areaLineData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3AreaAndLineWideChart d-flex w-100 h-100 '>
        {(areaLineData && areaLineData.data) &&
        <D3AreaAndLineChart
          key={id + 'areaLineWideChart'}
          id={id + 'wide'}
          areaLineData={areaLineData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3AreaAndLineWideChart;
