import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3MultipleStackHorizontalBarChart from './D3MultipleStackHorizontalBarChart';

class D3MultipleStackHorizontalBarWideChart extends Component {
  render() {
    const { id, msbData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3MultiForceLayoutWideChart d-flex w-100 h-100'>
        <D3MultipleStackHorizontalBarChart
          id={id + 'multipleSectionBarWideChart'}
          key={id + 'multipleSectionBarWideChart'}
          msbData={msbData}
          isWide={true}/>
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3MultipleStackHorizontalBarWideChart;
