import React, { Component } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

class D3MultipleStackHorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = {top: 10, right: 10, bottom: 10, left: 10};
    this.barHeight = 30;
    this.clientWidth = 217;
    this.clientHeight = 150;
    this.state = {};
    this.chartName = 'msbChart';
    this.state = {
      ratio: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    const {msbData} = this.props;
    if (!_.isEqual(msbData, nextProps.msbData)) {
      this.init(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.mounted = true;
    this.init(this.props);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const {isWide} = this.props;
    const {ratio} = this.state;
    this.size = this.mainParentEle.clientWidth > this.mainParentEle.clientHeight ? this.mainParentEle.clientHeight : this.mainParentEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217
      });
    }
  };

  init = (props) => {
    const {msbData, id, isWide} = props;
    const {ratio} = this.state;
    this.x = d3.scaleLinear();
    this.y = d3.scaleLinear();
    if (msbData && id) {
      this.createSvg(props);
    }
    this.size = this.mainParentEle.clientWidth > this.mainParentEle.clientHeight ? this.mainParentEle.clientHeight : this.mainParentEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217
      });
    }
  };

  createSvg = (props) => {
    const id = props.id.replace(/=/g, '');

    const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3MultipleSectionHorizontalBarChart').attr('id', d => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select('svg#' + id);

    const gSelection = this.svg.selectAll('g.g-container').data((d) => [d], (d) => d);
    gSelection.enter().append('g').merge(gSelection).attr('class', 'g-container').attr('id', (d) => d + 'g-container');
    gSelection.exit().remove();
    this.svgG = this.svg.select('g#' + id + 'g-container');

    const gRectSelection = this.svgG.selectAll('g.rect-container').data((d) => [d], (d) => d);
    gRectSelection.enter().append('g').merge(gRectSelection).attr('class', 'rect-container').attr('id', (d) => d + 'rect-container');
    gRectSelection.exit().remove();
    this.svgRectG = d3.select('g#' + id + 'rect-container');

    const gRectSelection2 = this.svgG.selectAll('g.rect-container2').data((d) => [d], (d) => d);
    gRectSelection2.enter().append('g').merge(gRectSelection).attr('class', 'rect-container').attr('id', (d) => d + 'rect-container2');
    gRectSelection2.exit().remove();
    this.svgRectG2 = d3.select('g#' + id + 'rect-container2');

    const divTooltipSelection = d3.select('body').selectAll('div.tooltip').data([1]);
    divTooltipSelection.enter().append('div').attr('class', 'tooltip');
    divTooltipSelection.exit();
    this.divTooltip = d3.select('body').select('div.tooltip');

    let svgLegend = d3.select(this.mainParentEle).select('#legendMultipleStackBarChart')
      .selectAll('svg').data([id], (d) => d);

    svgLegend.enter().append('svg').merge(svgLegend).attr('class', 'legend').attr('id', d => d)
      .attr('width', '100%')
      .attr('height', 60);

    svgLegend.exit().remove();
    this.svgLegend = d3.select(this.mainParentEle).select('#legendMultipleStackBarChart').select('svg#' + id);

    const legendG = this.svgLegend.selectAll('g.legend-container').data((d) => [d], (d) => d);
    legendG.enter().append('g').merge(legendG).attr('id', (d) => d + 'legendcontainer').attr('class', 'legend-container');
    legendG.exit().remove();
    this.legendG = this.svgLegend.select('g#' + id + 'legendcontainer');

    this.barScale = d3.scaleLinear().domain([0, 100]);
    this.y = d3.scaleBand();
    this.x = d3.scaleLinear();
    this.autoAlignSVG(props);
    this.createRect(props);
  };

  autoAlignSVG = (props) => {
    this.barHeight = 30;
    const {msbData: {data}, isWide} = props;

    const {ratio} = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = (this.clientHeight < (data.length) ?
      (data.length) :
      (this.clientHeight - 10))
      - this.margin.top - this.margin.bottom;
    this.size = this.mainParentEle.clientWidth > this.mainParentEle.clientHeight ? this.mainParentEle.clientHeight : this.mainParentEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg.attr('width', (this.width + this.margin.left + this.margin.right))
      .attr('height', (this.height + this.margin.top + this.margin.bottom));
    this.svgG
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.svgRectG.attr('transform', 'translate( 0 ,10)');
    this.svgRectG2.attr('transform', 'translate(0,' + (this.barHeight) + ')');
    this.y.domain(data.map(d => d.tooltip.replace(/ /g, '-'))).rangeRound([0, this.height]).padding(0);
    this.x.domain([0, 100]).range([0, this.width]);
    this.barScale.range([0, this.width]);
  };

  groupData = (data, total, key) => {
    // use scale to get percent values
    const percent = d3.scaleLinear()
      .domain([0, total])
      .range([0, 100]);
    // filter out data that has zero values
    // also get mapping for next placement
    // (save having to format data for d3 stack)
    let cumulative = 0;
    const _data = data.map(d => {
      cumulative += d[key];
      return {
        value: d[key],
        // want the cumulative to prior value (start of rect)
        cumulative: cumulative - d[key],
        label: d.tooltip,
        percent: percent(d[key]),
        backgroundColor: d.backgroundColor,
        tooltip: d.tooltip,
        legend: d.legend,
        patternClassName: d.patternClassName,
        patternFillColor: d.patternFillColor
      };
    }).filter(d => d.value >= 0);
    return _data;
  };

  fillColor = (d) => {
    if (d.patternClassName && d.patternFillColor) {
      const selectPattern = d3.select(this.mainParentEle).select('#' + d.patternClassName + this.chartName);
      if (selectPattern) {
        selectPattern.select('rect').style('fill', d.backgroundColor);
        selectPattern.select('circle').style('fill', d.patternFillColor);
        selectPattern.select('path').style('fill', d.patternFillColor);
      }
      return 'url(#' + d.patternClassName + this.chartName + ') ';
    } else {
      return d.backgroundColor;
    }
  };

  createRect = (props) => {
    const {msbData: {data, pieTypes}} = props;
    pieTypes.forEach((item, i) => {
      this.barScale = d3.scaleLinear().domain([0, d3.sum(data, d => d[item.pieType])]).range([0, this.width]);

      const totalOther = d3.sum(data, d => d[item.pieType]);
      const _data = this.groupData(data, totalOther, item.pieType);

      let selection = this.svgRectG;
      if (i === 1) {
        selection = this.svgRectG2;
      }
      const self = this;
      selection.selectAll('rect')
        .data(_data)
        .enter().append('rect')
        .attr('id', d => d.tooltip.replace(/ /g, ''))
        .attr('class', 'rect-stacked')
        .attr('x', d => this.barScale(d.cumulative))
        .attr('y', this.barHeight / 2 + (i * 50))
        .attr('height', this.barHeight)
        .attr('width', d => {
          return this.barScale(d.value);
        })
        .style('fill', (d, i) => {
          return this.fillColor(d);
        })
        .on('mouseover', (data) => {
          self.onMouseOverLegend(data, this.barScale(data.cumulative) + (this.barScale(data.value) / 2));
        }).on('mousemove', function() {
        const currentEl = d3.select(this);
        currentEl.attr('r', 7);

      }).on('mouseout', function(data) {
        d3.select('#arcSelection').remove();
        self.svg.selectAll(`rect[id=${data.tooltip.replace(/ /g, '')}]`)
          .attr('stroke', '')
          .attr('stroke-width', 0);

        self.divTooltip.style('display', 'none');
      });

      if (_data && _data.length) {
        selection.append('text')
          .attr('class', 'text-value')
          .attr('x', d => (this.barScale(_data[0].cumulative)))
          .attr('y', ((this.barHeight / 2) + (i * 50) - ((i + 1) * 10 / (i + 1))))
          .text(d => item.title)
          .style('fill', '#424B54')
          .style('font-family', 'ProximaNova-Bold')
          .style('font-size', 15);
      }
    });

    this.createLegends(props);

  };

  onMouseOverLegend = (tooltipData) => {
    const {msbData: {data, pieTypes, mouseoverColor}} = this.props;
    const {ratio} = this.state;
    let dataInPoverty = this.groupData(data, d3.sum(data, d => pieTypes[0].pieType), pieTypes[0].pieType);
    let dataNotInPoverty = this.groupData(data, d3.sum(data, d => pieTypes[1].pieType), pieTypes[1].pieType);
    const mPos = d3.mouse(d3.select('body').node());
    const xPos = d3.mouse(d3.select(this.mainParentEle).node());
    let inPovertyItem;
    let notInPovertyItem;
    this.divTooltip.style('left', mPos[0] + 10 + 'px');
    this.divTooltip.style('top', parseInt(mPos[1], 10) + 10 + 'px');
    this.divTooltip.style('transform', `translate(${ xPos[0] + 10 > (this.mainParentEle.clientWidth / 2) ? '-100%' : '0%'},0%)`);
    this.divTooltip.style('display', 'inline-block');
    this.divTooltip.style('opacity', '1');
    this.divTooltip.style('min-width', '120px')
      .style('background-color', '#FFFFFF')
      .style('padding', '5px')
      .style('color', '#424B54')
      .style('font-size', ratio ? (10 * ratio) + 'px' : '10px')
      .style('font-family', 'ProximaNova-Regular')
      .style('border', '1px solid #424B54');

    this.svg.selectAll(`rect[id=${tooltipData.tooltip.replace(/ /g, '')}]`)
      .attr('stroke', mouseoverColor || '#424B54')
      .attr('stroke-width', 2);

    // sort rects
    this.svgRectG.selectAll('rect').sort(function(a, b) {
      if (a.tooltip !== tooltipData.tooltip) return -1;
      else return 1;
    });

    this.svgRectG2.selectAll('rect').sort(function(a, b) {
      if (a.tooltip !== tooltipData.tooltip) return -1;
      else return 1;
    });

    inPovertyItem = _.find(dataInPoverty, p => p.tooltip === tooltipData.tooltip);
    notInPovertyItem = _.find(dataNotInPoverty, p => p.tooltip === tooltipData.tooltip);
    
    if(inPovertyItem == null) {
        inPovertyItem = {value: 0};
    }
    if(notInPovertyItem == null) {
        notInPovertyItem = {value: 0};
    }
    this.divTooltip.html(_.round(inPovertyItem.value, 1).toFixed(1) + '% ' + pieTypes[0].tooltip1 + '<br>' +
      pieTypes[0].tooltip2 + '<br><b>' + inPovertyItem.tooltip + '</b><br><br>' +
      _.round(notInPovertyItem.value, 1).toFixed(1) + '% ' + pieTypes[1].tooltip1 + '<br>' +
      pieTypes[1].tooltip2 + '<br><b>' + notInPovertyItem.tooltip + '</b>'
    );

  };

  onMouseOutLegend = (data) => {
    d3.select('#arcSelection').remove();
    this.svg.selectAll(`rect[id=${data.tooltip.replace(/ /g, '')}]`)
      .attr('stroke', '')
      .attr('stroke-width', 0);

    this.divTooltip.style('display', 'none');
  };

  createLegends = (props) => {
    const {msbData: {data}} = props;
    // legends

    const rectHeight = 5;
    const rectWidth = 14;
    const spaceBetweenLegeds = 13;

    if (data && data.length) {
      const legendG = this.legendG.selectAll('g.legend').data(data, (d) => d && d.legend);
      const newLegendG = legendG.enter().append('g');
      newLegendG.merge(legendG).attr('class', 'legend')
        .attr('transform', (d, i) => {
          if (i < 4) {
            return `translate(${this.margin.left},${(spaceBetweenLegeds * i) + 12})`;
          } else {
            return `translate(${this.margin.left + 100},${(spaceBetweenLegeds * (i - 4)) + 12})`;
          }
        });

      const blankRect = newLegendG.merge(legendG).selectAll('rect.blank').data((d) => [d], (d) => d && d.name2);
      const newBlankRect = blankRect.enter().append('rect');
      newBlankRect.merge(blankRect)
        .attr('width', 90) // the whole width of g/svg
        .attr('height', 15) // the whole heigh of g/svg
        .attr('fill', 'none')
        .attr('class', 'blank')
        .attr('transform', `translate(0,-${(rectHeight / 2) + 2})`)
        .attr('pointer-events', 'all')
        .on('mouseover', (d) => {
          this.onMouseOverLegend(d);
        })
        .on('mouseout', (d) => {
          this.onMouseOutLegend(d);
        });
      legendG.exit().remove();

      const legendRect = newLegendG.merge(legendG).selectAll('rect.filled').data((d) => [d], (d) => d && d.legend);
      const newRect = legendRect.enter().append('rect');
      newRect.merge(legendRect)
        .attr('x', 0)
        .attr('y', 0)
        .attr('pointer-events', 'visibleStroke')
        .attr('class', 'filled')
        .attr('width', rectWidth)
        .attr('height', rectHeight)
        .attr('rx', (rectHeight / 2))
        .attr('ry', (rectWidth / 2))
        .attr('transform', `translate(0,-${rectHeight / 2})`)
        .style('fill', (d) => this.fillColor(d))
        .on('mouseover', (d, i) => {
          this.onMouseOverLegend(d, i > 0 ? 105 : 50);
        })
        .on('mouseout', (d) => {
          this.onMouseOutLegend(d);
        });
      legendRect.exit().remove();

      // legends text enter, update, exit
      const legendText = newLegendG.merge(legendG).selectAll('text').data((d) => [d], (d) => d && d.legend);
      const newText = legendText.enter().append('text');
      newText.merge(legendText)
        .attr('x', (rectWidth + 5))
        .attr('y', 0)
        .attr('pointer-events', 'visibleStroke')
        .style('font-size', '10px')
        .attr('text-anchor', 'start')
        .attr('dy', '4px')
        .style('color', '#424B54')
        .style('font-family', 'ProximaNova-Regular')
        .text((d) => d.legend)
        .on('mouseover', (d, i) => {
          this.onMouseOverLegend(d, i > 0 ? 105 : 50);
        })
        .on('mouseout', (d) => {
          this.onMouseOutLegend(d);
        });
      legendText.exit().remove();
    }

  };

  render() {
    const {ratio} = this.state;
    return (
      <div ref={(ele) => this.mainParentEle = ele}
           className='D3MultipleStackHorizontalBarChart d-flex justify-content-center h-100 w-100 align-items-center'>
        <div className='d-flex flex-column'
             style={{height: '217px', width: '217px', transform: `scale(${ratio})`}}>
          <div className='d-flex mx-0'>
            <div className='w-100 h-100' ref={(ele) => this.parentEle = ele}/>
          </div>
          <div className='mx-0 px-1 py-1' style={{height: '60px'}}>
            <div id="legendMultipleStackBarChart"/>
          </div>
          <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <pattern id={'circles-1' + this.chartName}
                       width="5" height="5"
                       patternUnits="userSpaceOnUse">
                <rect width='10' height='10' fill='#fff'/>
                <circle cx="1" cy="1" r="1" style={{stroke: 'none', fill: '#000'}}/>
              </pattern>
            </defs>
          </svg>
          <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <pattern id={'diagonal-stripe-1' + this.chartName}
                       width="10" height="10"
                       patternUnits="userSpaceOnUse">
                <rect width='10' height='10' fill='white'/>
                <path d='M-1,1 l2,-2
                                             M0,10 l10,-10
                                             M9,11 l2,-2'
                      stroke='black'
                      strokeWidth='1'/>
              </pattern>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

export default D3MultipleStackHorizontalBarChart;
