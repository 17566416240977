import React, { Component, Fragment } from "react";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import ReportCardContainer from "../Card/ReportCardContainer";
import StoryCardContainer from "../Card/StoryCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import IndicatorMapCardContainer from "../Card/IndicatorMapCardContainer";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import { reportToolCardArray } from "../../utility/utilities";

/*const ResponsiveReactGridLayout = WidthProvider(Responsive);*/

class HomeFeedSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCardsArray: [],
    };
  }

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    var escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    var el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + cardHeight / 40;

    var newOpenedCardsArray = openedCardsArray;
    var pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({
          i: cardId,
          h: cardHeightInGrid,
        });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  handleNextPage = () => {
    const { data, handleNextPage, pageNumber } = this.props;
    let nextPageCursor =
      data.publicBoard.sections.edges[0].node.boardCardUses.pageInfo.endCursor;
    handleNextPage(nextPageCursor, pageNumber + 1);
  };

  render() {
    const {
      data,
      sectionData,
      userData,
      history,
      hideUnHidePopUp,
      onCardButtonClick,
      handleLoginPopUp,
      openStoryPopup,
      handleStoryVisiblity,
      openStoryPopupWithUrl,
      isShowMoreVisible,
      savedCardId,
      showToolTip,
      isSavedTooltip,
      hideToolTip,
      handleCloseUpView,
      handleSelectThemePopUp,
      handleUpdateBoard,
      handleSaveCardsPopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      addRemoveFavourite,
      isEditHashTagPopUpVisible,
      openCardFamilyPopupWithUrl,
      handleArrayOfTagsOfCardObject,
    } = this.props;
    var hasNextPage =
      data.publicBoard.sections.edges[0].node.boardCardUses.pageInfo
        .hasNextPage;
    return (
      <Fragment>
        <div className="grid-container d-flex flex-wrap justify-content-around justify-content-md-between">
          {sectionData.map((item, index) => {
            if (item.node.frontFacingComponent.componentType === "ReportCard") {
              // if (true) {
              return (
                <div className={"grid-card"} key={item.node.id}>
                  <ReportCardContainer
                    changeCardHeight={this.changeCardHeight}
                    isEditingEnabled={false}
                    isHomeFeed={true}
                    isCloseUpView={false}
                    index={index}
                    key={item.node.id}
                    data={item}
                    hideUnHidePopUp={hideUnHidePopUp}
                    showToolTip={showToolTip}
                    hideToolTip={hideToolTip}
                    onCloseUpView={handleCloseUpView}
                    handleSelectThemePopUp={handleSelectThemePopUp}
                    handleSaveCardsPopUp={handleSaveCardsPopUp}
                    newTagsForCardObject={newTagsForCardObject}
                    arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                    handleArrayOfTagsOfCardObject={
                      handleArrayOfTagsOfCardObject
                    }
                    selectedCardForMutation={selectedCardForMutation}
                    isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                    addRemoveFavourite={addRemoveFavourite}
                    userData={userData}
                    onCardButtonClick={onCardButtonClick}
                    handleLoginPopUp={handleLoginPopUp}
                    isSavedTooltip={isSavedTooltip}
                    savedCardId={savedCardId}
                  />
                </div>
              );
            } else if (
              item.node.frontFacingComponent.componentType === "ChartCard"
            ) {
              // else if (true) {
              const { subComponentType } = item.node;
              const reportToolCard =
                reportToolCardArray.indexOf(subComponentType) > -1;
              if (reportToolCard) {
                return (
                  <div key={item.node.id} className="grid-card">
                    <ReportToolCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={hideUnHidePopUp}
                      showToolTip={showToolTip}
                      hideToolTip={hideToolTip}
                      onCloseUpView={handleCloseUpView}
                      handleSelectThemePopUp={handleSelectThemePopUp}
                      handleSaveCardsPopUp={handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={true}
                      addRemoveFavourite={addRemoveFavourite}
                      userData={userData}
                      history={history}
                      onCardButtonClick={onCardButtonClick}
                      handleLoginPopUp={handleLoginPopUp}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                    />
                  </div>
                );
              } else {
                return (
                  <div className={"grid-card"} key={item.node.id}>
                    <ChartCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={hideUnHidePopUp}
                      showToolTip={showToolTip}
                      hideToolTip={hideToolTip}
                      onCloseUpView={handleCloseUpView}
                      handleSelectThemePopUp={handleSelectThemePopUp}
                      handleSaveCardsPopUp={handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={true}
                      addRemoveFavourite={addRemoveFavourite}
                      userData={userData}
                      history={history}
                      onCardButtonClick={onCardButtonClick}
                      handleLoginPopUp={handleLoginPopUp}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                    />
                  </div>
                );
              }
            } else if (
              item.node.frontFacingComponent.componentType === "MapCard"
            ) {
              // else if (true) {
              return (
                <div className={"grid-card"} key={item.node.id}>
                  <StoryCardContainer
                    handleStoryVisiblity={handleStoryVisiblity}
                    changeCardHeight={this.changeCardHeight}
                    isEditingEnabled={false}
                    isHomeFeed={true}
                    isCloseUpView={false}
                    index={index}
                    key={item.node.id}
                    data={item}
                    hideUnHidePopUp={hideUnHidePopUp}
                    showToolTip={showToolTip}
                    hideToolTip={hideToolTip}
                    onCloseUpView={handleCloseUpView}
                    handleSelectThemePopUp={handleSelectThemePopUp}
                    handleSaveCardsPopUp={handleSaveCardsPopUp}
                    newTagsForCardObject={newTagsForCardObject}
                    arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                    handleArrayOfTagsOfCardObject={
                      handleArrayOfTagsOfCardObject
                    }
                    selectedCardForMutation={selectedCardForMutation}
                    isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                    isMapcardsVisible={true}
                    addRemoveFavourite={addRemoveFavourite}
                    userData={userData}
                    onCardButtonClick={onCardButtonClick}
                    handleLoginPopUp={handleLoginPopUp}
                    isSavedTooltip={isSavedTooltip}
                    savedCardId={savedCardId}
                  />
                </div>
              );
            } else if (
              item.node.frontFacingComponent.componentType ===
              "IndicatorMapCard"
            ) {
              return (
                <div className={"grid-card"} key={item.node.id}>
                  <IndicatorMapCardContainer
                    changeCardHeight={this.changeCardHeight}
                    isEditingEnabled={false}
                    subComponentType={item.node.subComponentType}
                    statistics={item.node.statistics}
                    isHomeFeed={true}
                    isCloseUpView={false}
                    index={index}
                    key={item.node.id}
                    data={item}
                    hideUnHidePopUp={this.hideUnHidePopUp}
                    showToolTip={showToolTip}
                    hideToolTip={hideToolTip}
                    handleSelectThemePopUp={this.handleSelectThemePopUp}
                    handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                    newTagsForCardObject={newTagsForCardObject}
                    arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                    handleArrayOfTagsOfCardObject={
                      this.handleArrayOfTagsOfCardObject
                    }
                    selectedCardForMutation={selectedCardForMutation}
                    isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                    handleUpdateBoard={handleUpdateBoard}
                    isMapVisible={true}
                    userData={userData}
                    onCardButtonClick={onCardButtonClick}
                    openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                    isSavedTooltip={isSavedTooltip}
                    savedCardId={savedCardId}
                  />
                </div>
              );
            } else {
              return (
                <div className={"grid-card"} key={item.node.id}>
                  <StoryCardContainer
                    changeCardHeight={this.changeCardHeight}
                    isEditingEnabled={false}
                    isHomeFeed={true}
                    isCloseUpView={false}
                    index={index}
                    key={item.node.id}
                    data={item}
                    hideUnHidePopUp={hideUnHidePopUp}
                    showToolTip={showToolTip}
                    hideToolTip={hideToolTip}
                    onCloseUpView={handleCloseUpView}
                    handleSelectThemePopUp={handleSelectThemePopUp}
                    handleSaveCardsPopUp={handleSaveCardsPopUp}
                    newTagsForCardObject={newTagsForCardObject}
                    arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                    handleArrayOfTagsOfCardObject={
                      handleArrayOfTagsOfCardObject
                    }
                    selectedCardForMutation={selectedCardForMutation}
                    isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                    isMapcardsVisible={false}
                    addRemoveFavourite={addRemoveFavourite}
                    userData={userData}
                    onCardButtonClick={onCardButtonClick}
                    handleLoginPopUp={handleLoginPopUp}
                    openStoryPopup={openStoryPopup}
                    openStoryPopupWithUrl={openStoryPopupWithUrl}
                    isSavedTooltip={isSavedTooltip}
                    savedCardId={savedCardId}
                  />
                </div>
              );
            }
          })}
        </div>
        <button
          className={
            hasNextPage && isShowMoreVisible ? " show-more-feed-btn" : "d-none"
          }
          onClick={this.handleNextPage}
        >
          Show More
        </button>
      </Fragment>
    );
  }
}

export default HomeFeedSection;
