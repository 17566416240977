import React, { Component } from "react";
import D3VerticalBarChart from "./D3VerticalBarChart";

class D3DisparitiesDeprivationChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratio: 1,
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
    };
  }

  handleChange = (e) => {
    const { vbData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      selectedType: e.currentTarget.value,
      ourcommunity_data: vbData.ourcommunity_data.map(
        (o) => o[e.currentTarget.value]
      ),
      statebenchmark_data: vbData.statebenchmark_data.map(
        (o) => o[e.currentTarget.value]
      ),
      unitedstates_data: vbData.unitedstates_data.map(
        (o) => o[e.currentTarget.value]
      ),
    });
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (!this.mounted) return;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 218) {
      this.setState({
        ratio: this.size / 218,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { vbData } = this.props;
    if (nextProps.vbData !== vbData) {
      this.createChartData(nextProps.vbData);
      window.addEventListener("resize", this.onWindowResize);
      this.mounted = true;
    }
  }

  createChartData = (vbData) => {
    const { selectedType, ratio } = this.state;
    const { isWide } = this.props;
    this.setState({
      ourcommunity_data: vbData.ourcommunity_data.map((o) => o[selectedType]),
      statebenchmark_data: vbData.statebenchmark_data.map(
        (o) => o[selectedType]
      ),
      unitedstates_data: vbData.unitedstates_data.map((o) => o[selectedType]),
    });
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 218) {
      this.setState({
        ratio: this.size / 218,
      });
    }
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { vbData, id } = this.props;
    if (vbData && vbData.columns && vbData.columns.length && id) {
      this.createChartData(vbData);
      window.addEventListener("resize", this.onWindowResize);
      this.mounted = true;
    }
  };

  render() {
    const { vbData, id } = this.props;
    const {
      selectedType,
      ourcommunity_data,
      statebenchmark_data,
      unitedstates_data,
      ratio,
    } = this.state;
    const style = { fontSize: "10px", lineHeight: "13px" };
    let selectedTypeLabel = "";
    const radioOptions = vbData.columns.map((o, i) => {
      if (o.value === selectedType) {
        selectedTypeLabel = o.name;
        selectedTypeLabel = selectedTypeLabel.replace(/ ONLY/gi, "");
      }
      return (
        <div
          className="form-check"
          key={i + selectedType}
          style={style}
          onClick={(e) => e.stopPropagation()}
        >
          <label className="custom-radio-broadstreet">
            {" "}
            {o.name.replace(/ ONLY/gi, "")}
            <input
              type="radio"
              name={"ourcommunity-radio-" + id}
              onChange={this.handleChange}
              value={o.value}
              checked={o.value === selectedType}
            />
            <span className={"radio-checkmark"} />
          </label>
        </div>
      );
    });

    return (
      <div
        className="D3DisparitiesDeprivationChart flex-grow-1 h-100 w-100 d-flex flex-column justify-content-center align-items-center"
        ref={(ele) => {
          this.mainParentEle = ele;
        }}
      >
        <div
          className="d-flex flex-column"
          style={{
            height: "226px",
            width: "218px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="row m-0" style={{ height: "40px" }}>
            <small className="text-left text-uppercase" style={style}>
              PERCENT (%) OF THE{" "}
              <span style={{ fontWeight: 800 }}>{selectedTypeLabel}</span>{" "}
              POPULATION OF OUR COMMUNITY BY DEPRIVATION SCORE
            </small>
          </div>
          <div className="row m-0" style={{ height: "calc(100% - 165px)" }}>
            <D3VerticalBarChart
              id={"dd_ourcommunity" + id}
              key={"dd_ourcommunity" + id}
              name={"ourcommunity"}
              data={ourcommunity_data}
            />
          </div>
          <div
            className="row m-0 justify-content-between"
            style={{ height: "25px" }}
          >
            <small className="text-left" style={style}>
              Most
              <br />
              Affluent
            </small>
            <small className="text-right" style={style}>
              Most
              <br />
              Deprived
            </small>
          </div>
          <div className="row m-0 mt-3" style={{ height: "40px" }}>
            <div className="col-6 h-100 pl-0">
              <div className="text-left text-uppercase" style={style}>
                {vbData.stateName}
              </div>
              <div style={{ height: "calc(100% - 10px)" }}>
                <D3VerticalBarChart
                  id={"dd_statebenchmark" + id}
                  key={"dd_statebenchmark" + id}
                  name={"statebenchmark"}
                  data={statebenchmark_data}
                />
              </div>
            </div>
            <div className="col-6 h-100 pr-0">
              <div className="text-left" style={style}>
                UNITED STATES
              </div>
              <div style={{ height: "calc(100% - 10px)" }}>
                <D3VerticalBarChart
                  id={"dd_unitedstates" + id}
                  key={"dd_unitedstates" + id}
                  name={"unitedstates"}
                  data={unitedstates_data}
                />
              </div>
            </div>
          </div>
          <div className="text-left pl-2" style={{ height: "45px" }}>
            <div className="d-inline-flex flex-column pt-1">{radioOptions}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3DisparitiesDeprivationChart;
