import React, { createContext, useState } from "react";

export const DownloadContext = createContext();
export const DownloadConsumer = DownloadContext.Consumer;
// This context provider is passed to any component requiring the context
export const DownloadProvider = ({ children }) => {
  const [id, setCardId] = useState("");
  const [cardType, setCardType] = useState("chart");

  return (
    <DownloadContext.Provider
      value={{
        id,
        setCardId,
        cardType,
        setCardType
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
};
