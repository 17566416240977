/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FavoritesQueryVariables = {|
  searchCategory?: ?string,
  search?: ?string,
|};
export type FavoritesQueryResponse = {|
  +favoritesBoard: ?{|
    +sections: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardCardUses: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cardPosition: number,
                +frontFacingComponent: {|
                  +componentType: string,
                  +name: string,
                |},
                +subComponentType: ?string,
              |}
            |}>
          |},
          +id: string,
          +name: string,
          +description: ?string,
          +layout: string,
          +sectionPosition: number,
        |}
      |}>
    |},
    +id: string,
    +name: string,
    +subtitle: ?string,
    +theme: {|
      +jsonStyleProperties: string
    |},
  |}
|};
export type FavoritesQuery = {|
  variables: FavoritesQueryVariables,
  response: FavoritesQueryResponse,
|};
*/


/*
query FavoritesQuery(
  $searchCategory: String
  $search: String
) {
  favoritesBoard {
    sections {
      edges {
        node {
          boardCardUses(searchCategory: $searchCategory, search: $search) {
            edges {
              node {
                id
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                  id
                }
                subComponentType
              }
            }
          }
          id
          name
          description
          layout
          sectionPosition
        }
      }
    }
    id
    name
    subtitle
    theme {
      jsonStyleProperties
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchCategory",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search",
    "type": "String"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "searchCategory",
    "variableName": "searchCategory"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layout",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionPosition",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoritesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "favoritesBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v5/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FavoritesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "favoritesBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v5/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v5/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FavoritesQuery",
    "operationKind": "query",
    "text": "query FavoritesQuery(\n  $searchCategory: String\n  $search: String\n) {\n  favoritesBoard {\n    sections {\n      edges {\n        node {\n          boardCardUses(searchCategory: $searchCategory, search: $search) {\n            edges {\n              node {\n                id\n                cardPosition\n                frontFacingComponent {\n                  componentType\n                  name\n                  id\n                }\n                subComponentType\n              }\n            }\n          }\n          id\n          name\n          description\n          layout\n          sectionPosition\n        }\n      }\n    }\n    id\n    name\n    subtitle\n    theme {\n      jsonStyleProperties\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd02c9b7328f913361d55949977e52b92';

module.exports = node;
