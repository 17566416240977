import React, { useEffect, useState } from "react";
import { AnnouncementPopup } from "common-components";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const PauseMembershipPopup = ({ closePopUp }) => {
  const [pausing, setPausing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [pauseForDays, setPauseForDays] = useState(30);

  const pauseMembership = () => {
    if (pausing === true) {
      return;
    }
    setPausing(true);
    setErrorMsg("");
    setSuccessMsg("");
    const mutation = graphql`
      mutation PauseMembershipPopupMutation(
        $input: SchedulePauseSubscriptionInput!
      ) {
        schedulePauseSubscription(input: $input) {
          validationErrors {
            key
            message
          }
          success
        }
      }
    `;
    const variables = {
      input: {
        pauseForDays: pauseForDays,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.schedulePauseSubscription.success) {
          setSuccessMsg("Paused your membership");
          closePopUp();
        } else {
          setErrorMsg("Failed to pause your membership");
        }
        setPausing(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setPausing(false);
      },
    });
  };

  const enterKeyDown = (e) => {
    if (e.key === "Enter") {
      setShowConfirmation(true);
    }
  };

  const pages = [
    {
      showTitle: true,
      title: "Pause Membership",
      showPageItem: true,
      name: "pause-membership",
      formContainerClass: "row d-flex align-items-center",
      formItems: [
        {
          type: "alert",
          alertMsg: errorMsg,
          alertClass: "alert-danger rounded",
        },
        {
          type: "alert",
          alertMsg: successMsg,
          alertClass: "alert-success rounded",
        },
        {
          type: "number",
          placeholder: "Days",
          value: pauseForDays,
          onKeyDown: enterKeyDown,
          onChange: (e) => {
            setPauseForDays(e.target.value);
          },
          fieldContainerClass: "col-5",
        },
        {
          type: "string",
          text: "Pause for days",
          className: "mb-0",
          fieldContainerClass: "col-7",
        },
        {
          type: "button",
          multipleButtons: true,
          fieldContainerClass: "w-100 mt-5",
          componentWrapperClass: "d-flex justify-content-around",
          buttons: [
            {
              buttonType: "link",
              buttonTitle: "Cancel",
              handleOnClick: closePopUp,
            },
            {
              buttonTitle: pausing ? "Pausing" : "Pause",
              disabled: pausing,
              handleOnClick: () => setShowConfirmation(true),
            },
          ],
        },
      ],
    },
  ];

  const [pagesArray, setPagesArray] = useState(pages);
  const currentPage = pagesArray[0];

  useEffect(() => {
    setPagesArray(pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pausing, pauseForDays]);

  if (showConfirmation === true) {
    // Prompt to confirm pause
    return (
      <>
        <AnnouncementPopup
          closePopUp={closePopUp}
          onOkClick={pauseMembership}
          announcementMessage={
            errorMsg
              ? errorMsg
              : "This will pause your membership with BroadStreet.  While none of your data will be deleted, it may cause your public stories to become unpublished and may impact your collaborators."
          }
          announcementPrompt={successMsg ? successMsg : "Are you sure?"}
          closeButtonLabel="Close"
          okButtonLabel="PAUSE"
          title="PAUSE YOUR ACCOUNT"
        ></AnnouncementPopup>
      </>
    );
  } else {
    // Prompt for days to pause
    return (
      <StandardTallModalPopup
        goBack={closePopUp}
        closePopUp={closePopUp}
        pages={pagesArray}
        currentPage={currentPage}
      />
    );
  }
};

export default PauseMembershipPopup;
