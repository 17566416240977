import React, { Component, Fragment } from "react";
import environment from "../../RelayEnvironment";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Helmet } from "react-helmet";
import { Editor } from "slate-react";
import "../../css/components/CustomHomePage/CustomHomePage.css";
import FooterComponent from "../CommonComponents/FooterComponent";
import { Value } from "slate";
import ReportCardContainer from "../Card/ReportCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import IndicatorMapCardContainer from "../Card/IndicatorMapCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import StoryCardContainer from "../Card/StoryCardContainer";
import HomeFeed from "../HomeFeed/HomeFeed";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import { Responsive } from "react-grid-layout";
import { reportToolCardArray } from "../../utility/utilities";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class CustomHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      pageUrl,
      updateCustomPage,
      isLeftMenuOpen,
      refreshLayout,
      onRefreshLayout,
      selectedBoardId,
      selectedBoardName,
      handleBrowseAllStories,
      handleExploreMapsAndData,
      handleLoginPopUp,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      openStoryPopup,
      userData,
      filterTag,
      permaTag,
      fromLeftMenu,
      homeClick,
      history,
    } = this.props;
    const customHomePageQuery = graphql`
      query CustomHomePageQuery {
        currentUser {
          edges {
            node {
              homepages(isPrimaryHomepage: true) {
                edges {
                  node {
                    board {
                      id
                      name
                      subtitle
                      boardType
                      buttonColor
                      customDomain
                      faviconImage {
                        url
                      }
                      sections {
                        edges {
                          node {
                            image1 {
                              url
                            }
                            image2 {
                              url
                            }
                            image3 {
                              url
                            }
                            id
                            layout
                            sectionLayout
                            sectionPosition
                            name
                            description
                            boardCardUses {
                              edges {
                                node {
                                  id
                                  cardPosition
                                  frontFacingComponent {
                                    componentType
                                    name
                                  }
                                  isFavorite
                                  subComponentType
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const customHomePagePublicQuery = graphql`
      query CustomHomePagePublicQuery($input: String!) {
        currentUser {
          edges {
            node {
              id
            }
          }
        }
        page(permaLink: $input) {
          id
          name
          subtitle
          boardType
          buttonColor
          customDomain
          faviconImage {
            url
          }
          headerColor
          headerImage {
            url
          }
          sections {
            edges {
              node {
                image1 {
                  url
                }
                image2 {
                  url
                }
                image3 {
                  url
                }
                id
                layout
                sectionLayout
                sectionPosition
                name
                description
                boardCardUses {
                  edges {
                    node {
                      id
                      cardPosition
                      frontFacingComponent {
                        componentType
                        name
                      }
                      isFavorite
                      subComponentType
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return (
      <Fragment>
        <QueryRenderer
          key={"customHomePage"}
          environment={environment}
          query={
            pageUrl == null ? customHomePageQuery : customHomePagePublicQuery
          }
          variables={{
            input: pageUrl,
            updateCustomPage,
          }}
          render={({ error, props }) => {
            if (error) {
              console.error(error);
              return <div>Error!</div>;
            }
            if (!props) {
              return <div />;
            }
            const isLoggedIn =
              props &&
              props.currentUser.edges &&
              props.currentUser.edges.length > 0;
            if (!isLoggedIn) {
              return (
                <div className="custom-page-wrap">
                  <HomeFeed
                    isLeftMenuOpen={isLeftMenuOpen}
                    refreshLayout={refreshLayout}
                    onRefreshLayout={onRefreshLayout}
                    selectedBoardId={selectedBoardId}
                    selectedBoardName={selectedBoardName}
                    userData={userData}
                    handleBrowseAllStories={handleBrowseAllStories}
                    handleExploreMapsAndData={handleExploreMapsAndData}
                    filterTag={filterTag}
                    permaTag={permaTag}
                    fromLeftMenu={fromLeftMenu}
                    homeClick={homeClick}
                    handleLoginPopUp={handleLoginPopUp}
                    onRef={(ref) => (this.child = ref)}
                    openStoryPopup={openStoryPopup}
                    openStoryPopupWithUrl={openStoryPopupWithUrl}
                    openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                    history={history}
                  />
                </div>
              );
            }

            var pageData;
            if (pageUrl != null && props.page == null) {
              pageData = false;
            } else {
              pageData = props.page
                ? { node: { board: props.page } }
                : props.currentUser.edges &&
                  props.currentUser.edges[0].node.homepages.edges[0];
            }
            if (!pageData) {
              return (
                <div className="custom-page-wrap">
                  <HomeFeed
                    isLeftMenuOpen={isLeftMenuOpen}
                    refreshLayout={refreshLayout}
                    onRefreshLayout={onRefreshLayout}
                    selectedBoardId={selectedBoardId}
                    selectedBoardName={selectedBoardName}
                    userData={userData}
                    handleBrowseAllStories={handleBrowseAllStories}
                    handleExploreMapsAndData={handleExploreMapsAndData}
                    filterTag={filterTag}
                    permaTag={permaTag}
                    fromLeftMenu={fromLeftMenu}
                    homeClick={homeClick}
                    handleLoginPopUp={handleLoginPopUp}
                    onRef={(ref) => (this.child = ref)}
                    openStoryPopup={openStoryPopup}
                    openStoryPopupWithUrl={openStoryPopupWithUrl}
                    openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                    history={history}
                  />
                </div>
              );
            }
            return <CustomHomePageInner {...this.props} pageData={pageData} />;
          }}
        />
      </Fragment>
    );
  }
}

export default CustomHomePage;

class CustomHomePageInner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { pageData, hideUnHidePopUp } = this.props;
    let selectedCard = null;
    pageData.node.board.sections.edges.map((section, index) => {
      return section.node.boardCardUses.edges.map((item, index) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
        return selectedCard;
      });
    });
    hideUnHidePopUp(x, y, popUpType, cardId, selectedCard);
  };

  renderNodeDesc = (props) => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case "block-quote":
        return <blockquote {...attributes}>{children}</blockquote>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "heading-one":
        return <h1 {...attributes}>{children}</h1>;
      case "heading-two":
        return <h2 {...attributes}>{children}</h2>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return (
          <ul>
            <ol {...attributes}>{children}</ol>
          </ul>
        );
      case "link": {
        const { data } = node;
        const href = data.get("href");
        return (
          <a {...attributes} href={href}>
            {children}
          </a>
        );
      }
      default:
        break;
    }
  };

  renderMark = (props) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "code":
        return <code {...attributes}>{children}</code>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      default:
        break;
    }
  };

  applyOpenedCardHeights = (section) => {
    var sectionLayouts = this.buildAllSectionLayouts(
      section.node.boardCardUses.edges
    );
    var arrayCardPos = sectionLayouts.map((layout, j) => {
      return layout.map((item) => {
        return item;
      });
    });
    return arrayCardPos;
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      const { subComponentType } = edge.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;

      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h: reportToolCard ? 5.3 : 11,
        i: edge.node.id,
      };
    });
    return layout;
  };

  HtmlParser = (data) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = data;
    return textArea.innerText;
  };

  render() {
    const {
      pageData,
      pageUrl,
      isLeftMenuOpen,
      changeCardHeight,
      handleSaveCardsPopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      openCardFamilyPopupWithUrl,
      handleArrayOfTagsOfCardObject,
      showToolTip,
      handleCloseUpView,
      handleSelectThemePopUp,
      openStoryPopupWithUrl,
      handleLoginPopUp,
      addRemoveFavourite,
      handleUpdateBoard,
      openStoryPopup,
      hideToolTip,
      userData,
      history,
    } = this.props;
    const publishedStoryBoard = pageData.node.board;
    const favicon =
      publishedStoryBoard.faviconImage && publishedStoryBoard.faviconImage.url;
    const publishedStoryBoardName = publishedStoryBoard.name;
    const storyPermaLink =
      "https://www.broadstreet.io" + pageUrl.replace("/my/", "/story/");
    return (
      <div className="custom-page-wrap container">
        <Fragment>
          <Helmet>
            <title>{publishedStoryBoardName + " - BroadStreet"}</title>
            <meta name="description" content={publishedStoryBoardName} />
            <meta property="og:title" content={publishedStoryBoardName} />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={publishedStoryBoardName} />
            <meta
              property="og:image"
              content="https://www.broadstreet.io/images/BroadStreet_Logo2.png"
            />
            <meta property="og:url" content={storyPermaLink} />
            <meta itemprop="name" content="BroadStreet" />
            <meta
              itemprop="image"
              content="https://www.broadstreet.io/images/BroadStreet_Logo2.png"
            />
            <meta name="twitter:title" content={publishedStoryBoardName} />
            <meta
              name="twitter:description"
              content={publishedStoryBoardName}
            />
            {favicon && <link rel="icon" type="image/x-icon" href={favicon} />}
            {favicon && (
              <link rel="shortcut icon" type="image/x-icon" href={favicon} />
            )}
          </Helmet>
          <div className="text-left my-5">
            {pageData.node.board.sections.edges.map((section, index) => {
              let layoutArray = this.applyOpenedCardHeights(section);
              if (section.node.sectionLayout === "bannerWithImage") {
                const sectionDescription = JSON.parse(section.node.description);
                const imageUrl = section.node.image1
                  ? section.node.image1.url
                  : "";
                return (
                  <div key={index} className="d-flex flex-md-row flex-column">
                    <div className="col-md-4 col-12 d-flex flex-column justify-content-center">
                      <h3 className="main-title">
                        {this.HtmlParser(section.node.name)}
                      </h3>
                      <span className="sub-title">
                        <Editor
                          placeholder=""
                          value={
                            sectionDescription == null
                              ? Value.create()
                              : Value.fromJSON(sectionDescription)
                          }
                          renderNode={this.renderNodeDesc}
                          renderMark={this.renderMark}
                          readOnly={true}
                        />
                      </span>
                    </div>
                    <div className="col-md-8 col-12">
                      <img src={imageUrl} alt="" />
                    </div>
                  </div>
                );
              } else if (section.node.sectionLayout === "bannerWithText") {
                const sectionDescription = JSON.parse(section.node.description);
                const imageUrl = section.node.image1
                  ? section.node.image1.url
                  : "";
                return (
                  <div
                    key={index}
                    className="d-flex mt-5 flex-md-row flex-column custom-page-section1"
                  >
                    <div
                      className="col-md-3 col-12 pr-0 custom-page-image2 d-flex"
                      style={{
                        backgroundColor: pageData.node.board.buttonColor,
                      }}
                    >
                      <span className="m-auto">
                        {this.HtmlParser(section.node.name)}
                      </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-12 p-0">
                      <img
                        id="img1"
                        className="h-100 custom-page-image"
                        alt=""
                        src={imageUrl}
                      />
                    </div>
                    <div
                      className="col-lg-7 col-md-6 col-12 custom-page-info pl-3 pt-1 pr-2 h-100"
                      style={{ "--color": pageData.node.board.buttonColor }}
                    >
                      <Editor
                        placeholder=""
                        value={
                          sectionDescription == null
                            ? Value.create()
                            : Value.fromJSON(sectionDescription)
                        }
                        renderNode={this.renderNodeDesc}
                        renderMark={this.renderMark}
                        readOnly={true}
                      />
                    </div>
                  </div>
                );
              } else if (section.node.sectionLayout === "default") {
                return (
                  <div key={index} className="pt-5">
                    <h3 className="section-title">
                      {this.HtmlParser(section.node.name)}
                    </h3>
                    <ResponsiveReactGridLayout
                      className={
                        isLeftMenuOpen ? "rgl-menu-open" : "rgl-menu-closed"
                      }
                      cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
                      breakpoints={{
                        lg: 1350,
                        md: 1050,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={{
                        lg: layoutArray[3],
                        md: layoutArray[3],
                        sm: layoutArray[2],
                        xs: layoutArray[1],
                        xxs: layoutArray[0],
                      }}
                      isDraggable={false}
                      isResizable={false}
                      onLayoutChange={this.handleLayoutChange}
                      onBreakpointChange={this.onBreakpointChange}
                      margin={[10, 10]}
                      autoSize={true}
                      containerPadding={[0, 0]}
                      {...this.props}
                    >
                      {section.node.boardCardUses.edges.map((item, index) => {
                        if (
                          item.node.frontFacingComponent.componentType ===
                          "ReportCard"
                        ) {
                          return (
                            <div key={item.node.id}>
                              <ReportCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                onCloseUpView={this.handleCloseUpView}
                                handleSelectThemePopUp={
                                  this.handleSelectThemePopUp
                                }
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  this.handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                              />
                            </div>
                          );
                        } else if (
                          item.node.frontFacingComponent.componentType ===
                          "AdiMapCard"
                        ) {
                          return (
                            <div key={item.node.id}>
                              <AdiMapCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                subComponentType={item.node.subComponentType}
                                statistics={item.node.statistics}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                handleSelectThemePopUp={
                                  this.handleSelectThemePopUp
                                }
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  this.handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                                handleUpdateBoard={handleUpdateBoard}
                                isMapVisible={true}
                                userData={userData}
                                openCardFamilyPopupWithUrl={
                                  openCardFamilyPopupWithUrl
                                }
                              />
                            </div>
                          );
                        } else if (
                          item.node.frontFacingComponent.componentType ===
                          "ServiceAreaMapCard"
                        ) {
                          return (
                            <div key={item.node.id}>
                              <ServiceAreaMapCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                subComponentType={item.node.subComponentType}
                                statistics={item.node.statistics}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                handleSelectThemePopUp={
                                  this.handleSelectThemePopUp
                                }
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  this.handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                                handleUpdateBoard={handleUpdateBoard}
                                openCardFamilyPopupWithUrl={
                                  openCardFamilyPopupWithUrl
                                }
                                isMapVisible={true}
                                userData={userData}
                              />
                            </div>
                          );
                        } else if (
                          item.node.frontFacingComponent.componentType ===
                          "IndicatorMapCard"
                        ) {
                          return (
                            <div key={item.node.id}>
                              <IndicatorMapCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                subComponentType={item.node.subComponentType}
                                statistics={item.node.statistics}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                onCloseUpView={handleCloseUpView}
                                handleSelectThemePopUp={handleSelectThemePopUp}
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                                isMapcardsVisible={true}
                                addRemoveFavourite={addRemoveFavourite}
                                userData={userData}
                                handleLoginPopUp={handleLoginPopUp}
                                history={history}
                                openCardFamilyPopupWithUrl={
                                  openCardFamilyPopupWithUrl
                                }
                                isMapVisible={true}
                              />
                            </div>
                          );
                        } else if (
                          item.node.frontFacingComponent.componentType ===
                          "ChartCard"
                        ) {
                          const { subComponentType } = item.node;
                          const reportToolCard =
                            reportToolCardArray.indexOf(subComponentType) > -1;
                          if (reportToolCard) {
                            return (
                              <div key={item.node.id}>
                                <ReportToolCardContainer
                                  changeCardHeight={changeCardHeight}
                                  isEditingEnabled={false}
                                  subComponentType={item.node.subComponentType}
                                  statistics={item.node.statistics}
                                  isHomeFeed={true}
                                  isCloseUpView={false}
                                  index={index}
                                  key={item.node.id}
                                  data={item}
                                  hideUnHidePopUp={this.hideUnHidePopUp}
                                  showToolTip={showToolTip}
                                  hideToolTip={hideToolTip}
                                  onCloseUpView={handleCloseUpView}
                                  handleSelectThemePopUp={
                                    handleSelectThemePopUp
                                  }
                                  handleSaveCardsPopUp={handleSaveCardsPopUp}
                                  newTagsForCardObject={newTagsForCardObject}
                                  arrayOfTagsOfCardObject={
                                    arrayOfTagsOfCardObject
                                  }
                                  handleArrayOfTagsOfCardObject={
                                    handleArrayOfTagsOfCardObject
                                  }
                                  selectedCardForMutation={
                                    selectedCardForMutation
                                  }
                                  isEditHashTagPopUpVisible={
                                    isEditHashTagPopUpVisible
                                  }
                                  isMapcardsVisible={true}
                                  addRemoveFavourite={addRemoveFavourite}
                                  userData={userData}
                                  handleLoginPopUp={handleLoginPopUp}
                                  history={history}
                                  openCardFamilyPopupWithUrl={
                                    openCardFamilyPopupWithUrl
                                  }
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div key={item.node.id}>
                                <ChartCardContainer
                                  changeCardHeight={changeCardHeight}
                                  isEditingEnabled={false}
                                  subComponentType={item.node.subComponentType}
                                  statistics={item.node.statistics}
                                  isHomeFeed={true}
                                  isCloseUpView={false}
                                  index={index}
                                  key={item.node.id}
                                  data={item}
                                  hideUnHidePopUp={this.hideUnHidePopUp}
                                  showToolTip={showToolTip}
                                  hideToolTip={hideToolTip}
                                  onCloseUpView={handleCloseUpView}
                                  handleSelectThemePopUp={
                                    handleSelectThemePopUp
                                  }
                                  handleSaveCardsPopUp={handleSaveCardsPopUp}
                                  newTagsForCardObject={newTagsForCardObject}
                                  arrayOfTagsOfCardObject={
                                    arrayOfTagsOfCardObject
                                  }
                                  handleArrayOfTagsOfCardObject={
                                    handleArrayOfTagsOfCardObject
                                  }
                                  selectedCardForMutation={
                                    selectedCardForMutation
                                  }
                                  isEditHashTagPopUpVisible={
                                    isEditHashTagPopUpVisible
                                  }
                                  isMapcardsVisible={true}
                                  addRemoveFavourite={addRemoveFavourite}
                                  userData={userData}
                                  handleLoginPopUp={handleLoginPopUp}
                                  history={history}
                                  openCardFamilyPopupWithUrl={
                                    openCardFamilyPopupWithUrl
                                  }
                                />
                              </div>
                            );
                          }
                        } else if (
                          item.node.frontFacingComponent.componentType ===
                          "MapCard"
                        ) {
                          return (
                            <div key={item.node.id}>
                              <StoryCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                onCloseUpView={handleCloseUpView}
                                handleSelectThemePopUp={handleSelectThemePopUp}
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                                isMapcardsVisible={true}
                                addRemoveFavourite={addRemoveFavourite}
                                userData={userData}
                                handleLoginPopUp={handleLoginPopUp}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div key={item.node.id}>
                              <StoryCardContainer
                                changeCardHeight={changeCardHeight}
                                isEditingEnabled={false}
                                isHomeFeed={true}
                                isCloseUpView={false}
                                index={index}
                                key={item.node.id}
                                data={item}
                                hideUnHidePopUp={this.hideUnHidePopUp}
                                showToolTip={showToolTip}
                                hideToolTip={hideToolTip}
                                onCloseUpView={this.handleCloseUpView}
                                handleSelectThemePopUp={
                                  this.handleSelectThemePopUp
                                }
                                handleSaveCardsPopUp={handleSaveCardsPopUp}
                                newTagsForCardObject={newTagsForCardObject}
                                arrayOfTagsOfCardObject={
                                  arrayOfTagsOfCardObject
                                }
                                handleArrayOfTagsOfCardObject={
                                  this.handleArrayOfTagsOfCardObject
                                }
                                selectedCardForMutation={
                                  selectedCardForMutation
                                }
                                isEditHashTagPopUpVisible={
                                  isEditHashTagPopUpVisible
                                }
                                openStoryPopup={openStoryPopup}
                                openStoryPopupWithUrl={openStoryPopupWithUrl}
                              />
                            </div>
                          );
                        }
                      })}
                    </ResponsiveReactGridLayout>
                  </div>
                );
              } else if (section.node.sectionLayout === "text") {
                const sectionDescription = JSON.parse(section.node.description);
                return (
                  <div key={index} className="pt-5">
                    <div className="editor-description p-5">
                      <h3 className="mb-4">
                        {this.HtmlParser(section.node.name)}
                      </h3>
                      <Editor
                        placeholder=""
                        value={
                          sectionDescription == null
                            ? Value.create()
                            : Value.fromJSON(sectionDescription)
                        }
                        renderNode={this.renderNodeDesc}
                        renderMark={this.renderMark}
                        readOnly={true}
                      />
                    </div>
                  </div>
                );
              } else {
                return <div key={index} />;
              }
            })}
          </div>
          <FooterComponent />
        </Fragment>
      </div>
    );
  }
}
