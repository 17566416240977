/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVerifyEmailRequestInput = {|
  signupStep: string,
  userEmail: string,
  verificationKey: string,
  fullName?: ?string,
  temporaryPassword?: ?string,
  fips5?: ?string,
  contribution?: ?string,
  clientMutationId?: ?string,
|};
export type SignupPopUpMutationVariables = {|
  input: UpdateVerifyEmailRequestInput
|};
export type SignupPopUpMutationResponse = {|
  +updateVerifyEmail: ?{|
    +sessionid: ?string,
    +verifyEmailRequestNode: ?{|
      +signupStep: number,
      +userEmail: string,
    |},
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
  |}
|};
export type SignupPopUpMutation = {|
  variables: SignupPopUpMutationVariables,
  response: SignupPopUpMutationResponse,
|};
*/


/*
mutation SignupPopUpMutation(
  $input: UpdateVerifyEmailRequestInput!
) {
  updateVerifyEmail(input: $input) {
    sessionid
    verifyEmailRequestNode {
      signupStep
      userEmail
      id
    }
    validationErrors {
      key
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateVerifyEmailRequestInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signupStep",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userEmail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "validationErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupPopUpMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateVerifyEmailRequestPayload",
        "kind": "LinkedField",
        "name": "updateVerifyEmail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VerifyEmailRequestNode",
            "kind": "LinkedField",
            "name": "verifyEmailRequestNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupPopUpMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateVerifyEmailRequestPayload",
        "kind": "LinkedField",
        "name": "updateVerifyEmail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VerifyEmailRequestNode",
            "kind": "LinkedField",
            "name": "verifyEmailRequestNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SignupPopUpMutation",
    "operationKind": "mutation",
    "text": "mutation SignupPopUpMutation(\n  $input: UpdateVerifyEmailRequestInput!\n) {\n  updateVerifyEmail(input: $input) {\n    sessionid\n    verifyEmailRequestNode {\n      signupStep\n      userEmail\n      id\n    }\n    validationErrors {\n      key\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f20589fd76a0de3534e48398148b941';

module.exports = node;
