import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { longTimeoutEnvironment } from "../../RelayEnvironment";
import "../../css/components/Card/D3DisparitiesDeprivationWideChart.css";
import "../../css/components/Card/WideServiceAreaMapCardContainer.css";
import { apiBaseUrl, arrayOfMapPlaceholders } from "../../utility/utilities";
import { ButtonComponent } from "common-components";
import WideCardWrapper from "../WideCard/WideCardWrapper";
import Panzoom from "@panzoom/panzoom";
import { DownloadConsumer } from "../Root/downloadContext";

class WideSavedMapCardContainer extends Component {
  panzoom = null;
  mapImage = null;

  constructor(props) {
    super(props);
    this.state = {
      mapPlaceholder:
        (props.id.charCodeAt(props.id.length - 1) +
          props.id.charCodeAt(props.id.length - 2)) %
        arrayOfMapPlaceholders.length,
    };
  }

  routeToMapRoom = () => {
    const { id } = this.props;
    window.location.assign(`/map/?id=${id}`);
  };

  initalizePanZoom() {
    if (this.mapImage && !this.panzoom) {
      var yOffset = 0;
      if (this.mapImage.offsetWidth > 900) {
        yOffset = -(this.mapImage.offsetHeight / 6);
      } else if (this.mapImage.offsetWidth > 700) {
        yOffset = -(this.mapImage.offsetHeight / 10);
      }
      this.panzoom = Panzoom(this.mapImage, {
        minScale: 0.1,
        maxScale: 5,
        startY: yOffset,
      });
    }
  }

  render() {
    const { showTwitterCardPopup, isEditingEnabled, id } = this.props;
    const { mapPlaceholder } = this.state;
    const middleContent = (
      <div>
        <ButtonComponent
          isLarge={true}
          buttonType="outlined"
          className="mt-5"
          iconClass="view-icon"
          buttonTitle={"View in Map Room"}
          onClick={this.routeToMapRoom}
        />
      </div>
    );
    const self = this;
    const content = (
      <div
        className={
          "wideServiceAreaMapCardContainer h-100 " +
          (isEditingEnabled ? "movable" : "clickable")
        }
        onClick={this.handleClick}
      >
        <div className="wideServiceAreaMapCardContainer-map">
          <QueryRenderer
            environment={longTimeoutEnvironment}
            query={graphql`
              query WideSavedMapCardContainerQuery($input: ID!) {
                boardCardUse(id: $input) {
                  id
                  mapImage(highResolution: true)
                }
              }
            `}
            variables={{ input: id }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                const mapImageUrl = arrayOfMapPlaceholders[mapPlaceholder];
                return (
                  <div className="position-relative w-auto h-100">
                    <div>
                      <img src={mapImageUrl} alt="" />
                    </div>
                  </div>
                );
              }
              if (!props) {
                return (
                  <div>
                    <div className="storyCardContainer-map-img">
                      <br />
                      <br />
                      Loading...
                    </div>
                  </div>
                );
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    (Map Not Available)
                  </div>
                );
              }
              const usingPlaceholderImage =
                props.boardCardUse.mapImage === null;
              const mapImageUrl = usingPlaceholderImage
                ? arrayOfMapPlaceholders[mapPlaceholder]
                : apiBaseUrl.replace(/\/$/, "") + props.boardCardUse.mapImage;
              return (
                <div className="storyCardContainer-map-img">
                  {!showTwitterCardPopup ? (
                    <div className="d-flex float-right position-absolute zoomButton">
                      <button
                        onClick={() => {
                          this.panzoom.zoomIn();
                        }}
                      >
                        +
                      </button>
                      <button
                        onClick={() => {
                          this.panzoom.zoomOut();
                        }}
                      >
                        -
                      </button>
                    </div>
                  ) : null}
                  <div
                    className="w-100 map-image"
                    id="map-image"
                    ref={(me) => (self.mapImage = me)}
                    style={{
                      height: "1200px",
                    }}
                  >
                    <img
                      src={mapImageUrl}
                      alt=""
                      style={{ width: "100%" }}
                      ref={(me) => (self.imageTag = me)}
                      onLoad={() => this.initalizePanZoom()}
                    />
                    {!usingPlaceholderImage ? (
                      <div className="wideServiceAreaMapCardContainer-watermrk-img">
                        <img
                          src={require("../../images/favicon1.svg")}
                          alt="BroadStreet"
                        />
                      </div>
                    ) : null}
                  </div>
                  {!usingPlaceholderImage ? (
                    <div className="mapboxgl-ctrl-bottom-right">
                      <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib">
                        <div className="mapboxgl-ctrl-attrib-inner">
                          <a
                            href="https://www.maptiler.com/copyright/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            © MapTiler{" "}
                          </a>
                          <a
                            href="https://www.openstreetmap.org/copyright"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            © OpenStreetMap contributors
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }}
          />
        </div>
      </div>
    );
    return (
      <DownloadConsumer>
        {(context) => {
          context.setCardId(id);
          context.setCardType("map");
          return showTwitterCardPopup ? (
            content
          ) : (
            <WideCardWrapper middleContent={middleContent} {...this.props}>
              {content}
            </WideCardWrapper>
          );
        }}
      </DownloadConsumer>
    );
  }
}

export default WideSavedMapCardContainer;
