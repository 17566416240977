import React, { Component } from 'react';
import D3HorizontalStackedBarChart from '../Card/D3HorizontalStackedBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3HorizontalStackedBarWideChart extends Component {
  render() {
    const { id, povertyByRaceCard, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3HorizontalStackedBarWideChart d-flex w-100 h-100 '>
        {(povertyByRaceCard && povertyByRaceCard.data) &&
        <D3HorizontalStackedBarChart
          id={id + 'povertyByRaceWideCard'}
          key={id + 'povertyByRaceCard'}
          data={povertyByRaceCard}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3HorizontalStackedBarWideChart;
