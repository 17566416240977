import React, { Component } from "react";
import "../../css/components/Card/D3DonutProgressChart.css";
import * as d3 from "d3";
import * as _ from "lodash";

class D3DonutProgressChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 15, right: 0, bottom: 0, left: 0 };
    this.clientWidth = 217;
    this.clientHeight = 145;
    this.textHeight = 61;
    this.state = {
      percent: 0,
    };
  }

  componentDidMount() {
    this.init(this.props);
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
  };

  init = (props) => {
    this.arc = d3.arc();
    this.pie = d3
      .pie()
      .startAngle(Math.PI / 4)
      .endAngle(Math.PI * 2 + Math.PI / 4)
      .sort(null);
    this.createSvg(props);
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    this.svg = d3.select(this.parentEle).select("svg");

    const gSelection = this.svg.selectAll("g.g-container").data([id], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "-g-container")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select(this.parentEle).select("g#" + id + "-g-container");
    this.infoSVGG = this.svg.select("g#" + id + "-info-gcontainer");
    this.autoAlignSVG();
    this.updateInfo(props);
    this.updateProgress(props);
    this.setImage(props);
  };

  autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.textHeight / 2;
    this.radius = Math.min(this.width, this.height) / 1.4;
    this.arc
      .innerRadius(this.radius * 0.7)
      .outerRadius((d) => (d.index ? this.radius - 10 : this.radius));

    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right}
            ${
              this.height +
              this.margin.top +
              this.margin.bottom +
              this.textHeight
            }`
      );

    this.svgG.attr(
      "transform",
      "translate(" +
        (this.width / 2 + this.margin.left) +
        "," +
        (this.radius - this.margin.top) +
        ")"
    );
    this.infoSVGG.attr(
      "transform",
      "translate(0," + (this.height + this.margin.top + 20) + ")"
    );
  };

  updateInfo(props) {
    const { percent, text, id } = props;
    const svgId = id.replace(/=/g, "");
    let value = percent;
    value = value < 1 ? _.round(value, 1) : _.round(value);
    this.svg
      .select("#current-progress-" + svgId)
      .html((value ? value : 0) + "%");
    this.svg.select("#current-progress-msg-" + svgId).html(text);
  }

  updateProgress(props) {
    const { percent } = props;
    const g = this.svgG.selectAll("g.arc-container").data(
      (d) => [d],
      (d, i) => i
    );
    const newG = g.enter().append("g").merge(g); // new + update
    newG.attr("id", (d) => "arc-container-" + d).attr("class", "arc-container");

    const lowerPath = newG
      .selectAll("path.progress-path")
      .data(this.pie([percent, 100 - percent]), (d, i) => i);
    const newLowerPath = lowerPath.enter().append("path").merge(lowerPath);
    newLowerPath
      .attr("class", "progress-path")
      .attr("fill", (d, i) =>
        i ? "rgba(211, 87, 33, 0.333)" : "rgba(211, 87, 33, 1)"
      )
      .attr("d", (d) => this.arc(d));
    lowerPath.exit().remove();

    g.exit().remove();
  }

  setImage(props) {
    const { image } = props;

    const g = this.svgG.selectAll("g.image-container").data(
      (d) => [d],
      (d) => d.id
    );
    const newG = g.enter().append("g");
    const radius = this.radius - 10;
    newG
      .merge(g)
      .attr("class", "image-container")
      .attr("transform", "translate(-" + radius / 2 + ",-" + radius / 2 + ")");

    const imageSelection = newG
      .merge(g)
      .selectAll("img")
      .data([{ src: image }], (d) => d.src);
    const imageForeignObject = imageSelection
      .enter()
      .append("foreignObject")
      .attr("width", radius)
      .attr("height", radius);
    const imageEleDiv = imageForeignObject.append("xhtml:div");
    const imgEle = imageEleDiv.append("img");
    imgEle
      .merge(imageSelection)
      .attr("src", (d) => d.src)
      .attr("width", radius)
      .attr("height", radius);
    imageSelection.exit().remove();

    g.exit().remove();
  }

  render() {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    return (
      <div className="D3DonutProgressChart d-flex flex-column h-100 w-100">
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
          style={{ padding: "0 16px" }}
          ref={(ele) => (this.parentEle = ele)}
        >
          <svg id={svgId}>
            <g
              id={svgId + "-info-gcontainer"}
              className={svgId + "-info-gcontainer"}
            >
              <foreignObject x="0" y="0" width="100%" height={this.textHeight}>
                <div
                  className="flex-grow-1 d-flex align-items-start"
                  style={{ height: this.textHeight }}
                >
                  <div
                    id={`current-progress-${svgId}`}
                    className="main-text text-left"
                  />
                  <div
                    id={`current-progress-msg-${svgId}`}
                    className="w-75 details-text text-left pl-3"
                  />
                </div>
              </foreignObject>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default D3DonutProgressChart;
