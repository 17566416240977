/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WideSavedMapCardContainerQueryVariables = {|
  input: string
|};
export type WideSavedMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +mapImage: ?string,
  |}
|};
export type WideSavedMapCardContainerQuery = {|
  variables: WideSavedMapCardContainerQueryVariables,
  response: WideSavedMapCardContainerQueryResponse,
|};
*/


/*
query WideSavedMapCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    mapImage(highResolution: true)
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "input"
      }
    ],
    "concreteType": "BoardCardUseNode",
    "kind": "LinkedField",
    "name": "boardCardUse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "highResolution",
            "value": true
          }
        ],
        "kind": "ScalarField",
        "name": "mapImage",
        "storageKey": "mapImage(highResolution:true)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WideSavedMapCardContainerQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WideSavedMapCardContainerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "WideSavedMapCardContainerQuery",
    "operationKind": "query",
    "text": "query WideSavedMapCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    mapImage(highResolution: true)\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47988568fd61f1c9b6ef7ca4c3191e49';

module.exports = node;
