import React, { useEffect, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { StandardTallModalPopup } from "common-components";
import { commitMutation } from "react-relay";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";

const AccountEditPopup = (props) => {
  const {
    handleImageEditorPopUpVisible,
    closePopUp,
    subscriptionInfo,
    avatarEditor,
  } = props;

  const placeHolderImage = require("../../images-placeholder/profile1.png");
  const image = subscriptionInfo?.userImage
    ? subscriptionInfo?.userImage?.url
    : placeHolderImage;
  const [avatarImage, setAvatarImage] = useState(image);
  const [imageFile, setImageFile] = useState();
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (avatarEditor) {
      setAvatarImage(avatarEditor.getImageScaledToCanvas().toDataURL());
      fetch(avatarEditor.getImageScaledToCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          setImageFile({
            0: new File([blob], "ProjectImg.png", { type: "image/png" }),
          });
        });
    }
  }, [avatarEditor]);

  const ImageComponent = () => {
    return (
      <div className="col-lg-12 text-left px-5">
        <div className="gallery text-center mt-1">
          <img
            src={avatarImage}
            onError={(e) => {
              e.target.src = placeHolderImage;
              e.target.onerror = null;
            }}
            alt="profile1"
            data-toggle="modal"
            data-bigimage={avatarImage}
            data-target="#myModal"
            className="img-fluid"
            height="75px"
            width="75px"
            onClick={() =>
              handleImageEditorPopUpVisible("1:1", null, avatarImage, 260, {
                height: "auto",
                width: 210,
              })
            }
          />
        </div>
      </div>
    );
  };

  const [userFirstName, setUserFirstName] = useState(
    subscriptionInfo?.userFirstName
  );
  const [userLastName, setUserLastName] = useState(
    subscriptionInfo?.userLastName
  );
  const [userEmail, setUserEmail] = useState(subscriptionInfo?.email);
  const [saveStatus, setSaveStatus] = useState();

  const pagesArray = [
    {
      showTitle: true,
      title: "Account Info",
      showPageItem: true,
      showButton: true,
      buttonTitle: saveStatus === "working" ? "SAVING" : "SAVE",
      name: "account-info",
      modalCustomClass: "px-4 pt-3",
      handleOnClick: () => {
        updateAccountPageData(
          userFirstName,
          userLastName,
          userEmail,
          imageFile
        );
      },
      formItems: [
        {
          type: "alert",
          alertMsg: errorMsg,
          alertClass: "alert-danger alert-dismissible show-alert",
        },
        {
          type: "string",
          text: "Profile Picture",
          className: "media-heading mb-1",
        },
        {
          type: "string",
          className: "text-muted",
          text: (
            <>
              Set an image of yourself <br /> (square JPEG or PNG recommended)
            </>
          ),
        },
        {
          type: "image",
          component: <ImageComponent />,
        },
        {
          label: "First Name",
          labelClassName: "pb-2",
          type: "text",
          name: "userFirstName",
          value: userFirstName,
          parentClassName: "mb-4",
          onChange: (e) => setUserFirstName(e.target.value),
        },
        {
          label: "Last Name",
          labelClassName: "pb-2",
          type: "text",
          name: "userLastName",
          value: userLastName,
          parentClassName: "mb-4",
          onChange: (e) => setUserLastName(e.target.value),
        },
        {
          label: "Email for Sign In",
          labelClassName: "pb-2",
          type: "text",
          name: "userEmail",
          value: userEmail,
          parentClassName: "mb-4",
          onChange: (e) => setUserEmail(e.target.value),
        },
      ],
    },
  ];

  const updateAccountProfileImage = (imageId) => {
    const mutation = graphql`
      mutation AccountEditPopupImageMutation(
        $input: UpdateUserProfileImageInput!
      ) {
        updateUserProfileImage(input: $input) {
          userAccountNode {
            userImage {
              url
            }
            email
          }
          validationErrors {
            key
            message
          }
        }
      }
    `;
    let input = {
      imageId,
    };

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        // console.log('Profile update response from server.', JSON.stringify(response));
        // TODO : check if you are getting a user id then reload the component.
        closePopUp();
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        //alert('Profile update failed, try again.');
        // TODO: Show error message
      },
    });
  };

  const updateAccountPageData = (firstName, lastName, email, file, imageId) => {
    if (saveStatus === "working") {
      return;
    }
    setSaveStatus("working");
    const mutation = graphql`
      mutation AccountEditPopupMutation($input: UpdateUserAccountInput!) {
        updateUserAccount(input: $input) {
          userAccountNode {
            userImage {
              url
            }
            email
          }
          validationErrors {
            key
            message
          }
        }
      }
    `;
    const input = {
      file: null,
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
    };

    let uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    commitMutation(environment, {
      mutation,
      variables: { input },
      uploadables,
      onCompleted: (response, errors) => {
        // console.log('Profile update response from server.', JSON.stringify(response));
        // TODO : check if you are getting a user id then reload the component.
        setSaveStatus("saved");
        if (imageId) {
          updateAccountProfileImage(imageId);
        } else {
          closePopUp();
        }
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        //alert('Profile update failed, try again.');
        setSaveStatus("error");
        setErrorMsg("Failed to Save");
        // TODO: Show error message
      },
    });
  };

  return (
    <StandardTallModalPopup
      closePopUp={closePopUp}
      goBack={closePopUp}
      pages={pagesArray}
      currentPage={pagesArray[0]}
    />
  );
};

export default AccountEditPopup;
