import React, { useState, useEffect } from 'react';
import { DeleteAnnouncementPopup } from "common-components";
import '../../css/custom.css';
import '../../css/components/MemberShipPopup/MemberShipPopup.css';
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import { subscriptionInfoSelector } from '../../actions/user';
import { connect } from 'react-redux';
const DeleteAccountPopup = ({
                              closePopUp,
                              subscriptionInfo
                            }) => {
  const [deleting, setDeleting] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [deletedAccount, setDeletedAccount] = useState(false);
  const deleteAccount = () => {
    setDeleting(true);
    setErrorMsg('');
    setSuccessMsg('');
    const mutation = graphql`
    mutation DeleteAccountPopupMutation($input: ScheduleDeleteAccountInput!) {
      scheduleDeleteAccount(input: $input) {
        deleteByDate
        success
      }
    }
  `;
    const variables = {
      input: {
        'clientMutationId': null
      }
    };
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.scheduleDeleteAccount.success) {
            setDeletedAccount(true);
            setSuccessMsg('Deleted your account');
          } else {
            setErrorMsg('Failed to delete your account');
          }
          setDeleting(false);
        },
        onError: (err) => {
          console.log('error', err);
          setErrorMsg(err.errors[0].message);
          setDeleting(false);
        }
      }
    );
  };
  const cancelDeleteAccoount = () => {
    setDeleting(true);
    setErrorMsg('');
    setSuccessMsg('');
    const mutation = graphql`
    mutation DeleteAccountPopupUndoDeleteAccountMutation($input: CancelDeleteAccountInput!) {
      cancelDeleteAccount(input: $input) {
        success
      }
    }
  `;
    const variables = {
      input: {
        'clientMutationId': null
      }
    };
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.cancelDeleteAccount.success) {
            setSuccessMsg('Canceled deleting your account, successfully.');
            setDeletedAccount(false);
          } else {
            setErrorMsg('Could not cancel deleting your account.');
          }
          setDeleting(false);
        },
        onError: (err) => {
          console.log('error', err);
          setErrorMsg(err.errors[0].message);
          setDeleting(false);
        }
      }
    );
  };

  useEffect(() => {
    if (subscriptionInfo) {
      setDeletedAccount(subscriptionInfo.isDeletedAccount);
    }

  }, [subscriptionInfo]);

  return (
    <>
      <DeleteAnnouncementPopup closePopUp={closePopUp} goBack={closePopUp}>
        <div className="p-4">
          <h3 className="my-5 text-center">DELETE<br/> YOUR ACCOUNT</h3>
          <div className="px-4">
            <p className="text-center">
              Are you sure you want to delete your account? This will erase your stories, your maps,
              your saved areas and all of your work for you and your collaborators.
            </p>
            <p className="text-center">In 30 days, this action will become final.</p>
          </div>
          {errorMsg && <div className="alert alert-danger mb-3 rounded" role="alert">
            {errorMsg}
          </div>}
          {successMsg && <div className="alert alert-success mb-3 rounded" role="alert">
            {successMsg}
          </div>}
          <div className="d-flex justify-content-center mt-5 pt-5">
            <button className="button button-floating mr-4" onClick={() => closePopUp()}>Cancel
            </button>
            {deletedAccount ?
              <button className="button button-subtle" onClick={() => cancelDeleteAccoount()}
                      disabled={deleting}
              >{deleting ? 'Canceling' : 'Undo Delete Account'}</button>
              :
              <button className="button button-subtle" onClick={() => deleteAccount()}
                      disabled={deleting}
              >{deleting ? 'Deleting' : 'DELETE'}</button>
            }
          </div>
        </div>
      </DeleteAnnouncementPopup>
    </>
  );
};

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state)
});

export default connect(mapStatus, null)(DeleteAccountPopup);
