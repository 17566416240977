import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
  mutation CopyCardToFavouritesMutation($input:CopyCardToFavoritesInput!)
    {  copyCardToFavorites(input:  $input)
        {  boardCardUseNode
            {
                id
            }
        }
    }
`;

export default (cardId, callback, errorCallback, createDataDownloadCard) => {
  const variables = {'input': {'boardCardUseId': cardId,'createDataDownloadCard': createDataDownloadCard === true}};
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // console.log('Response from server section layouts update.',JSON.stringify(response))
        // console.log('Response from server section layouts update.',(response))
        callback();
      },
      onError: (err) => {
        console.error('Error from server section layouts update sectionLayoutMutation', JSON.stringify(err));
        errorCallback(err);
      }
    }
  );
}
