import React, { Component } from "react";
import WideCardContainer from "../WideCard/WideCardWrapper";
import "../../css/components/CloseUpView/CloseUp.css";

class CloseUp extends Component {
  render() {
    const {
      previousCardView,
      isVisible,
      onCloseUpView,
      data,
      showToolTip,
      hideToolTip,
      handleSaveCardsPopUp,
      hideUnHidePopUp,
      nextCardView,
    } = this.props;
    return (
      <div>
        {isVisible ? (
          <div className="closeUpContainer">
            <div className="wideCardContainerWrap">
              <span className="wideCardPopupClosebtn">
                <a href={"#close"} onClick={onCloseUpView}>
                  CLOSE ✕
                </a>
              </span>
              <span
                className="fa fa-angle-left wideCardPopupLeftbtn"
                onClick={previousCardView}
              />
              <span
                className="fa fa-angle-right wideCardPopupRightbtn"
                onClick={nextCardView}
              />
              <WideCardContainer
                data={data}
                hideUnHidePopUp={hideUnHidePopUp}
                showToolTip={showToolTip}
                hideToolTip={hideToolTip}
                handleSaveCardsPopUp={handleSaveCardsPopUp}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CloseUp;
