import React, { Component } from "react";
import "../../css/components/Card/ReportToolCardContainer.css";
import ReactDOM from "react-dom";
import * as $ from "jquery";

class ReportImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  componentDidMount() {
    const { hideToolTip } = this.props;
    if ($(".tooltipsvisibleContainer")) {
      const button = ReactDOM.findDOMNode(this.cardBottom);
      if (button) {
        button.addEventListener("wheel", (event) => {
          if ($(".tooltipsvisibleContainer")) {
            hideToolTip();
          }
        });
      }
    }
  }

  componentWillUnmount() {
    const button = ReactDOM.findDOMNode(this.cardBottom);
    button.removeEventListener(
      "wheel",
      (event) => console.info(event.deltaY),
      false
    );
  }

  onMouseOverImage = (e) => {
    this.titleDiv.classList.add("subDivStyleHover");
    this.setState({ isHovered: true });
  };

  onMouseOutImage = (e) => {
    this.titleDiv.classList.remove("subDivStyleHover");
    this.setState({ isHovered: false });
  };

  showToolTipMore = () => {
    const { showToolTip } = this.props;
    const bounds = ReactDOM.findDOMNode(
      this.refs["more"]
    ).getBoundingClientRect();
    if (showToolTip != null) {
      showToolTip(bounds.x - 11, bounds.y + 30, "More");
    }
  };

  testForLogin = (allowPopUp) => {
    const { userData, handleLoginPopUp } = this.props;
    if (
      userData == null ||
      userData.currentUser == null ||
      userData.currentUser.edges.length === 0
    ) {
      // prompt for login if needed
      if (
        allowPopUp &&
        handleLoginPopUp &&
        typeof handleLoginPopUp === "function"
      ) {
        handleLoginPopUp();
      }
      return true;
    } else {
      return false;
    }
  };

  checkLogin = (cardId, e) => {
    const { checkUserLogin, onCardButtonClick } = this.props;
    const isLoggedIn = !this.testForLogin(false);
    if (isLoggedIn) {
      onCardButtonClick(cardId, e);
      if (checkUserLogin) {
        checkUserLogin(false);
      }
    } else {
      if (checkUserLogin) {
        checkUserLogin(true);
      }
    }
  };

  render() {
    const {
      link,
      icon,
      title,
      detail,
      onCardClick,
      popupType,
      onCardButtonClick,
      data,
      hideToolTip,
    } = this.props;
    const cardId = data && data.boardCardUse && data.boardCardUse.id;
    const { isHovered } = this.state;
    const divStyle = {
      backgroundSize: "cover",
      height: "175px",
      position: "relative",
      overflow: "hidden",
      borderRadius: "3px",
      top: "-1px",
      backgroundImage: isHovered
        ? "linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1) 90%) , url(" +
          icon +
          ")"
        : "linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 90%) , url(" +
          icon +
          ")",
    };
    const card = data.boardCardUse;
    const defaultProfileImage = [
      "acesToolCard",
      "infantMortalityToolCard",
      "preventingViolenceToolCard",
    ].includes(card.subComponentType)
      ? require("../../images/favicon-franciscan-health.png")
      : require("../../images/favicon1.svg");
    const profileIcon =
      card.subComponentType === "ToolCard" &&
      card.configuration.author &&
      card.configuration.author.profileImage
        ? card.configuration.author.profileImage.url
        : defaultProfileImage;

    const isLoggedIn = !this.testForLogin(false);

    return (
      <div
        className={
          "ReportToolCard w-100 " + (onCardClick ? "clickable" : "movable")
        }
        onMouseOver={this.onMouseOverImage}
        onMouseOut={this.onMouseOutImage}
        onClick={() =>
          onCardClick
            ? isLoggedIn
              ? onCardClick(link, popupType)
              : null
            : null
        }
      >
        <div className="w-100 text-decoration-none" style={divStyle}>
          <div
            className="d-flex flex-column text-left subDivStyle"
            ref={(me) => (this.titleDiv = me)}
          >
            <div className="title">{title}</div>
            <div
              className={
                detail != null && detail.length > 70
                  ? "detail detail-long pt-2"
                  : "detail pt-2"
              }
            >
              {detail}
            </div>
            <div className="CardCitationSub pt-2 m-0">
              <img
                className="citationIcon"
                src={profileIcon}
                onError={(e) => {
                  e.target.src = defaultProfileImage;
                  e.target.onerror = null;
                }}
                alt=""
              />
              {data && card.configuration.citation}
            </div>
          </div>
          <div className="verticalElipse" ref={(me) => (this.cardBottom = me)}>
            <a
              href="#moreButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onCardButtonClick) {
                  this.checkLogin(cardId, e);
                }
              }}
              ref="moreButton"
            >
              <img
                className="cardBottomButtonsImages"
                src={require("../../images/iconElipse-new.svg")}
                alt=""
                onMouseOver={this.showToolTipMore}
                onMouseOut={hideToolTip}
                ref="more"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportImageCard;
