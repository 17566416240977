import React, { useState, useEffect, useRef } from "react";
import "../../css/components/ImageEditorPopup/ImageEditorPopup.css";
import AvatarEditor from "react-avatar-editor";
import { StandardTallModalPopup } from "common-components";

const addimage = require("../../images/uni-featured.png");

const SelectFeaturedImagePopup = (props) => {
  const {
    avatarImage,
    onSelectFeaturedImage,
    closePopUp,
    goBack,
    data,
    avatarHeight,
    style,
    avatarEditorRatio,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      if (avatarImage) {
        setImageBase(avatarImage);
      } else if (data) {
        const featureImage = data.board.featureImage
          ? data.board.featureImage.url
          : "";
        setImageBase(featureImage);
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarImage, data?.board?.featureImage]);

  const editorRef = useRef();

  const [imageBase, setImageBase] = useState();

  const [featureImageId, setFeatureImageId] = useState("");

  const type =
    avatarEditorRatio === "16:5"
      ? "banner"
      : avatarEditorRatio === "3:2"
      ? "co"
      : "squarefeature";

  const onClickNext = () => {
    onSelectFeaturedImage(featureImageId, imageBase);
    goBack();
  };

  const handleFeatureImageSelection = (featureImageId, imageBase) => {
    setFeatureImageId(featureImageId);
    setImageBase(imageBase);
  };

  let width = 683;
  let height = avatarHeight;
  if (height) {
    const ratio = avatarEditorRatio.split(":");
    width = (ratio[0] * height) / ratio[1];
  } else {
    const ratio = avatarEditorRatio.split(":");
    height = (width * ratio[1]) / ratio[0];
  }
  const scale = 1;
  const rotate = 0;
  const customStyle = style ? style : { height: "188px", width: "283px" };

  const renderAvatarEditor = () => (
    <>
      <div className="row mb-0">
        <div className="col-12 p-0 text-center">
          <AvatarEditor
            ref={editorRef}
            image={imageBase ? imageBase : addimage}
            width={width}
            height={height}
            border={30}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={scale}
            rotate={rotate}
            style={customStyle}
          />
        </div>
      </div>
    </>
  );

  const pages = [
    {
      showTitle: true,
      title: "Select Image",
      showPageItem: true,
      showButton: true,
      buttonTitle: "NEXT",
      name: "select-image",
      modalCustomClass: "overflow-x px-3",
      selectImage: true,
      handleOnClick: () => {
        onClickNext();
      },
      props: {
        renderAvatarEditor,
        handleFeatureImageSelection,
        type,
        featureImageId,
      },
    },
  ];

  const [pagesArray, setPagesArray] = useState(pages);

  useEffect(() => {
    setPagesArray(pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageBase]);

  return (
    <StandardTallModalPopup
      goBack={closePopUp}
      closePopUp={closePopUp}
      pages={pagesArray}
      currentPage={pagesArray[0]}
    />
  );
};

export default SelectFeaturedImagePopup;
