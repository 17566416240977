import React, { Component } from "react";
import "../../css/components/Card/D3DisparitiesDeprivationWideChart.css";
import D3DisparitiesDeprivationChart from "./D3DisparitiesDeprivationChart";
import WideCardWrapper from "../WideCard/WideCardWrapper";

class D3DisparitiesDeprivationWideChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
      legends: [
        { label: "Most Affluent", color: "#78A22F" },
        { label: "", color: "#6A8D73" },
        { label: "Average", color: "#D5DED6" },
        { label: "", color: "#FFA987" },
        { label: "Most Deprived", color: "#FF6B35" },
      ],
    };
  }

  handleChange = (e) => {
    const { vbData } = this.props;
    this.setState({ selectedType: e.currentTarget.value }, () =>
      this.createChartData(vbData)
    );
  };

  componentWillReceiveProps(nextProps) {
    const { vbData } = this.props;
    if (nextProps.vbData !== vbData) {
      this.createChartData(nextProps.vbData);
    }
  }

  createChartData = (vbData) => {
    const { selectedType } = this.state;
    this.setState({
      ourcommunity_data: vbData.ourcommunity_data.map((o) => o[selectedType]),
    });
    this.setState({
      statebenchmark_data: vbData.statebenchmark_data.map(
        (o) => o[selectedType]
      ),
    });
    this.setState({
      unitedstates_data: vbData.unitedstates_data.map((o) => o[selectedType]),
    });
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { vbData, id } = this.props;
    if (vbData && vbData.columns && vbData.columns.length && id) {
      this.createChartData(vbData);
    }
  };

  render() {
    const { vbData, id, showTwitterCardPopup } = this.props;
    const { legends } = this.state;
    const middleContent = (
      <div className="row mx-0 mt-0 mt-md-3">
        <div className="col-6 col-md-12 px-0">
          <ul className="list-group mt-0 mt-sm-5 legend">
            {legends.map((legend, index) => {
              return (
                <li key={index} className="list-group-item p-1">
                  <div style={{ background: legend.color }} />
                  {legend.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
    const wideChartId =
      id +
      (showTwitterCardPopup
        ? "d3DisparitiesDeprivationWideChart"
        : "d3DisparitiesDeprivationTwitterChart");
    const content = (
      <div className="D3DisparitiesDeprivationWideChart d-flex w-100 h-100 ">
        {vbData && (
          <D3DisparitiesDeprivationChart
            id={wideChartId}
            key={wideChartId}
            isWide={true}
            vbData={vbData}
          />
        )}
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? (
          content
        ) : (
          <WideCardWrapper
            {...this.props}
            fromChart={true}
            middleContent={middleContent}
          >
            {content}
          </WideCardWrapper>
        )}
      </>
    );
  }
}

export default D3DisparitiesDeprivationWideChart;
