import React, { Component } from 'react';
import D3SimpleGaugeChart from '../Card/D3SimpleGaugeChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3SimpleGaugeWideChart extends Component {
  render() {
    const { id, simpleGaugeChartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'simpleGaugeWideChart' : 'simpleGaugeTwitterChart');
    const content = (
      <div className='D3SimpleGaugeWideChart d-flex w-100 h-100'>
        {simpleGaugeChartData &&
        <D3SimpleGaugeChart
          id={wideChartId}
          key={wideChartId}
          simpleGaugeChartData={simpleGaugeChartData}
          min={0}
          max={100}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3SimpleGaugeWideChart;
