/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type userSubscriptionInfoQueryVariables = {||};
export type userSubscriptionInfoQueryResponse = {|
  +currentUser: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +userImage: ?{|
          +id: string,
          +url: ?string,
        |},
        +username: ?string,
        +userFirstName: string,
        +userLastName: string,
        +userReportName: string,
        +briefBio: ?string,
        +contactInfo: ?string,
        +email: ?string,
        +activePromoCode: ?string,
        +activePromoCodeMessage: ?string,
        +deleteByDate: ?any,
        +isDeletedAccount: boolean,
        +isOverSubscriptionLimits: boolean,
        +pauseSubscriptionStartDate: ?any,
        +pauseSubscriptionEndDate: ?any,
        +pausedUserSubscriptionLevel: number,
        +pausedDaysTotal: number,
        +pausesUsed: ?number,
        +stripeCustomerId: ?string,
        +subscriptions: ?$ReadOnlyArray<?string>,
        +subscriptionCoupons: number,
        +subscriptionCouponCode: ?string,
        +usageCountStories: ?number,
        +usageMaxStories: ?number,
        +usageCountCards: ?number,
        +usageMaxCards: ?number,
        +userSubscriptionExpiration: ?any,
        +userSubscriptionLevel: number,
        +userSubscriptionTerm: ?string,
        +activeCouponCode: ?{|
          +subscriptionCouponCode: ?string,
          +created: any,
        |},
        +creditAmount: number,
        +defaultPaymentMethod: ?{|
          +id: ?string,
          +object: ?string,
          +type: ?string,
          +card: ?{|
            +brand: ?string,
            +country: ?string,
            +last4: ?string,
            +expMonth: ?string,
            +expYear: ?string,
          |},
        |},
        +couponsGiven: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +activator: ?{|
                +username: ?string
              |},
              +subscriptionCouponCode: ?string,
              +created: any,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type userSubscriptionInfoQuery = {|
  variables: userSubscriptionInfoQueryVariables,
  response: userSubscriptionInfoQueryResponse,
|};
*/


/*
query userSubscriptionInfoQuery {
  currentUser {
    edges {
      node {
        id
        userImage {
          id
          url
        }
        username
        userFirstName
        userLastName
        userReportName
        briefBio
        contactInfo
        email
        activePromoCode
        activePromoCodeMessage
        deleteByDate
        isDeletedAccount
        isOverSubscriptionLimits
        pauseSubscriptionStartDate
        pauseSubscriptionEndDate
        pausedUserSubscriptionLevel
        pausedDaysTotal
        pausesUsed
        stripeCustomerId
        subscriptions
        subscriptionCoupons
        subscriptionCouponCode
        usageCountStories
        usageMaxStories
        usageCountCards
        usageMaxCards
        userSubscriptionExpiration
        userSubscriptionLevel
        userSubscriptionTerm
        activeCouponCode {
          subscriptionCouponCode
          created
          id
        }
        creditAmount
        defaultPaymentMethod {
          id
          object
          type
          card {
            brand
            country
            last4
            expMonth
            expYear
          }
        }
        couponsGiven {
          edges {
            node {
              activator {
                username
                id
              }
              subscriptionCouponCode
              created
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileImageNode",
  "kind": "LinkedField",
  "name": "userImage",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userFirstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userLastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userReportName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "briefBio",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactInfo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activePromoCode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activePromoCodeMessage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleteByDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDeletedAccount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverSubscriptionLimits",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pauseSubscriptionStartDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pauseSubscriptionEndDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pausedUserSubscriptionLevel",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pausedDaysTotal",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pausesUsed",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeCustomerId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptions",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionCoupons",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionCouponCode",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageCountStories",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageMaxStories",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageCountCards",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usageMaxCards",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionExpiration",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionLevel",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSubscriptionTerm",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creditAmount",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "StripePaymentMethodNode",
  "kind": "LinkedField",
  "name": "defaultPaymentMethod",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "object",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePaymentMethodCardNode",
      "kind": "LinkedField",
      "name": "card",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expYear",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userSubscriptionInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNode",
                    "kind": "LinkedField",
                    "name": "activeCouponCode",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v30/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v31/*: any*/),
                  (v32/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNodeConnection",
                    "kind": "LinkedField",
                    "name": "couponsGiven",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CouponActivationNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CouponActivationNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserAccountNode",
                                "kind": "LinkedField",
                                "name": "activator",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v22/*: any*/),
                              (v30/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userSubscriptionInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNode",
                    "kind": "LinkedField",
                    "name": "activeCouponCode",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v30/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v31/*: any*/),
                  (v32/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponActivationNodeConnection",
                    "kind": "LinkedField",
                    "name": "couponsGiven",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CouponActivationNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CouponActivationNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserAccountNode",
                                "kind": "LinkedField",
                                "name": "activator",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v22/*: any*/),
                              (v30/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "userSubscriptionInfoQuery",
    "operationKind": "query",
    "text": "query userSubscriptionInfoQuery {\n  currentUser {\n    edges {\n      node {\n        id\n        userImage {\n          id\n          url\n        }\n        username\n        userFirstName\n        userLastName\n        userReportName\n        briefBio\n        contactInfo\n        email\n        activePromoCode\n        activePromoCodeMessage\n        deleteByDate\n        isDeletedAccount\n        isOverSubscriptionLimits\n        pauseSubscriptionStartDate\n        pauseSubscriptionEndDate\n        pausedUserSubscriptionLevel\n        pausedDaysTotal\n        pausesUsed\n        stripeCustomerId\n        subscriptions\n        subscriptionCoupons\n        subscriptionCouponCode\n        usageCountStories\n        usageMaxStories\n        usageCountCards\n        usageMaxCards\n        userSubscriptionExpiration\n        userSubscriptionLevel\n        userSubscriptionTerm\n        activeCouponCode {\n          subscriptionCouponCode\n          created\n          id\n        }\n        creditAmount\n        defaultPaymentMethod {\n          id\n          object\n          type\n          card {\n            brand\n            country\n            last4\n            expMonth\n            expYear\n          }\n        }\n        couponsGiven {\n          edges {\n            node {\n              activator {\n                username\n                id\n              }\n              subscriptionCouponCode\n              created\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '873057b6fe0e8f14caa22cead1cc055e';

module.exports = node;
