/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomHomePageQueryVariables = {||};
export type CustomHomePageQueryResponse = {|
  +currentUser: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +homepages: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +board: {|
                +id: string,
                +name: string,
                +subtitle: ?string,
                +boardType: ?string,
                +buttonColor: ?string,
                +customDomain: ?string,
                +faviconImage: ?{|
                  +url: ?string
                |},
                +sections: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +image1: ?{|
                        +url: ?string
                      |},
                      +image2: ?{|
                        +url: ?string
                      |},
                      +image3: ?{|
                        +url: ?string
                      |},
                      +id: string,
                      +layout: string,
                      +sectionLayout: string,
                      +sectionPosition: number,
                      +name: string,
                      +description: ?string,
                      +boardCardUses: ?{|
                        +edges: $ReadOnlyArray<?{|
                          +node: ?{|
                            +id: string,
                            +cardPosition: number,
                            +frontFacingComponent: {|
                              +componentType: string,
                              +name: string,
                            |},
                            +isFavorite: ?boolean,
                            +subComponentType: ?string,
                          |}
                        |}>
                      |},
                    |}
                  |}>
                |},
              |}
            |}
          |}>
        |}
      |}
    |}>
  |}
|};
export type CustomHomePageQuery = {|
  variables: CustomHomePageQueryVariables,
  response: CustomHomePageQueryResponse,
|};
*/


/*
query CustomHomePageQuery {
  currentUser {
    edges {
      node {
        homepages(isPrimaryHomepage: true) {
          edges {
            node {
              board {
                id
                name
                subtitle
                boardType
                buttonColor
                customDomain
                faviconImage {
                  url
                  id
                }
                sections {
                  edges {
                    node {
                      image1 {
                        url
                        id
                      }
                      image2 {
                        url
                        id
                      }
                      image3 {
                        url
                        id
                      }
                      id
                      layout
                      sectionLayout
                      sectionPosition
                      name
                      description
                      boardCardUses {
                        edges {
                          node {
                            id
                            cardPosition
                            frontFacingComponent {
                              componentType
                              name
                              id
                            }
                            isFavorite
                            subComponentType
                          }
                        }
                      }
                    }
                  }
                }
              }
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "isPrimaryHomepage",
    "value": true
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boardType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonColor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customDomain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layout",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionLayout",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionPosition",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v17 = [
  (v7/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomHomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v0/*: any*/),
                    "concreteType": "BoardMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "homepages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardMembershipNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardMembershipNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardNode",
                                "kind": "LinkedField",
                                "name": "board",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BoardImageNode",
                                    "kind": "LinkedField",
                                    "name": "faviconImage",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BoardSectionNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "sections",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BoardSectionNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BoardSectionNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image1",
                                                "plural": false,
                                                "selections": (v8/*: any*/),
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image2",
                                                "plural": false,
                                                "selections": (v8/*: any*/),
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image3",
                                                "plural": false,
                                                "selections": (v8/*: any*/),
                                                "storageKey": null
                                              },
                                              (v1/*: any*/),
                                              (v9/*: any*/),
                                              (v10/*: any*/),
                                              (v11/*: any*/),
                                              (v2/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardCardUseNodeConnection",
                                                "kind": "LinkedField",
                                                "name": "boardCardUses",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BoardCardUseNodeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BoardCardUseNode",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v1/*: any*/),
                                                          (v13/*: any*/),
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FrontFacingComponentNode",
                                                            "kind": "LinkedField",
                                                            "name": "frontFacingComponent",
                                                            "plural": false,
                                                            "selections": [
                                                              (v14/*: any*/),
                                                              (v2/*: any*/)
                                                            ],
                                                            "storageKey": null
                                                          },
                                                          (v15/*: any*/),
                                                          (v16/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "homepages(isPrimaryHomepage:true)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomHomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAccountNodeConnection",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v0/*: any*/),
                    "concreteType": "BoardMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "homepages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardMembershipNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardMembershipNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardNode",
                                "kind": "LinkedField",
                                "name": "board",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BoardImageNode",
                                    "kind": "LinkedField",
                                    "name": "faviconImage",
                                    "plural": false,
                                    "selections": (v17/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BoardSectionNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "sections",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BoardSectionNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BoardSectionNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image1",
                                                "plural": false,
                                                "selections": (v17/*: any*/),
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image2",
                                                "plural": false,
                                                "selections": (v17/*: any*/),
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardImageNode",
                                                "kind": "LinkedField",
                                                "name": "image3",
                                                "plural": false,
                                                "selections": (v17/*: any*/),
                                                "storageKey": null
                                              },
                                              (v1/*: any*/),
                                              (v9/*: any*/),
                                              (v10/*: any*/),
                                              (v11/*: any*/),
                                              (v2/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BoardCardUseNodeConnection",
                                                "kind": "LinkedField",
                                                "name": "boardCardUses",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BoardCardUseNodeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BoardCardUseNode",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v1/*: any*/),
                                                          (v13/*: any*/),
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FrontFacingComponentNode",
                                                            "kind": "LinkedField",
                                                            "name": "frontFacingComponent",
                                                            "plural": false,
                                                            "selections": [
                                                              (v14/*: any*/),
                                                              (v2/*: any*/),
                                                              (v1/*: any*/)
                                                            ],
                                                            "storageKey": null
                                                          },
                                                          (v15/*: any*/),
                                                          (v16/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "homepages(isPrimaryHomepage:true)"
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CustomHomePageQuery",
    "operationKind": "query",
    "text": "query CustomHomePageQuery {\n  currentUser {\n    edges {\n      node {\n        homepages(isPrimaryHomepage: true) {\n          edges {\n            node {\n              board {\n                id\n                name\n                subtitle\n                boardType\n                buttonColor\n                customDomain\n                faviconImage {\n                  url\n                  id\n                }\n                sections {\n                  edges {\n                    node {\n                      image1 {\n                        url\n                        id\n                      }\n                      image2 {\n                        url\n                        id\n                      }\n                      image3 {\n                        url\n                        id\n                      }\n                      id\n                      layout\n                      sectionLayout\n                      sectionPosition\n                      name\n                      description\n                      boardCardUses {\n                        edges {\n                          node {\n                            id\n                            cardPosition\n                            frontFacingComponent {\n                              componentType\n                              name\n                              id\n                            }\n                            isFavorite\n                            subComponentType\n                          }\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5c38b2741190de50d242e4a1a333008';

module.exports = node;
