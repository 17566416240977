import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
  mutation CardFeedbackMutation($input: CreateBoardCardFeedbackInput!)
  {
    createBoardCardFeedback(input: $input)
    {
      success
    }
  }
`;

export default (cardId, commentText, callback, errorCallback) => {
  const variables = {
    'input':
      {
        'boardCardUse': cardId,
        'message': commentText
      }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // console.log('Response from server section layouts update.',JSON.stringify(response))
        // console.log('Response from server section layouts update.',(response))
        callback();
      },
      onError: (err) => {
        console.error('Error from server section layouts update sectionLayoutMutation', JSON.stringify(err));
        errorCallback(err);
      }
    }
  );
}
