import React, { Component } from "react";
import * as d3 from "d3";

class TextLayoutType2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratio: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.init(nextProps);
    }
  }

  componentDidMount() {
    this.init(this.props);
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentDidUpdate() {
    this.init(this.props);
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (!this.mounted) return;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  init = (nextProps) => {
    const { mainTitle, isWide } = nextProps;
    const { ratio } = this.state;
    this.animateNumber(this.mainTitleEle, mainTitle);
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  animateNumber = (ele, value) => {
    if (value) {
      d3.select(ele)
        .transition()
        .duration(700)
        .on("start", function repeat() {
          const format = value.includes(".")
            ? d3.format(".3n")
            : value.includes(",")
            ? d3.format(",d")
            : d3.format(".3n");
          d3.active(this).tween("text", function () {
            const that = d3.select(this),
              i = d3.interpolateNumber(that.text().replace(/,/g, ""), value);
            return function (t) {
              that.text(format(i(t)));
            };
          });
        });
    }
  };

  render() {
    const { mainSubTitle, title, title1 } = this.props;
    const { ratio } = this.state;
    return (
      <div
        ref={(ele) => (this.mainEle = ele)}
        className={
          "d-flex justify-content-center h-100 w-100 align-items-center"
        }
      >
        <div
          className="TextLayoutType2 type-2 d-flex flex-column justify-content-center"
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <p className="text-card-title px-5 mb-2">{title}</p>
          <p
            className="text-card-title-1"
            ref={(ele) => (this.mainTitleEle = ele)}
          />
          <p className="text-card-sub-title-1">{mainSubTitle}</p>
          <hr className="text-card-sub-title-hr" />
          <div className="d-flex text-card-2-3-container">
            <div
              className="text-card-title-2"
              dangerouslySetInnerHTML={{ __html: title1 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TextLayoutType2;
