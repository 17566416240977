import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3ProgressBarChart from './D3ProgressBarChart';

class D3ProgressBarWideChart extends Component {
  render() {
    const { id, chartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'progressBarWideChart' : 'progressBarTwitterChart');
    const content = (
      <div className='D3ProgressBarWideChart d-flex w-100 h-100'>
        {chartData &&
        <D3ProgressBarChart
          id={wideChartId}
          key={wideChartId}
          data={chartData.horizontalBarData}
          subText={chartData.subText}
          min={chartData.minValue} max={chartData.maxValue}
          refData={chartData.refData}
          isWide={true}/>
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3ProgressBarWideChart;
