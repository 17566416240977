import * as $ from "jquery";
import React, { useState } from "react";
import { StandardTallModalPopup } from "common-components";

const CollaboratorsWorkflow = (props) => {
  const { closePopUp } = props;

  const goBack = () => {
    if (currentPage.props.visibleModal.collaborators) {
      closePopUp();
    } else if (currentPage.props.visibleModal.addCollaborators) {
      setCollaboratorsModal("collaborators");
    } else {
      setCollaboratorsModal("addCollaborators");
    }
  };

  const setCollaboratorsModal = (key) => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[0];
    collaboratorsPage.props.visibleModal = { [key]: true };
    switch (key) {
      case "collaborators":
        collaboratorsPage.title = "Collaborators";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Add";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () =>
          setCollaboratorsModal("addCollaborators");
        break;
      case "addCollaborators":
        collaboratorsPage.title = "Add Collaborators";
        collaboratorsPage.showButton = false;
        break;
      case "sharePrivateLink":
        collaboratorsPage.title = "Share a Private Link";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Copy";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () => copyPrivateLink();
        break;
      case "inviteViaEmail":
        collaboratorsPage.title = "Invite via Email";
        collaboratorsPage.showButton = false;
        break;
      default:
        break;
    }
    setPagesArray(pages);
  };

  const copyPrivateLink = () => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[0];
    collaboratorsPage.buttonTitle = "Copied";
    collaboratorsPage.buttonState = "saved";
    setPagesArray(pages);
    navigator.clipboard.writeText($("#PrivateLink").val());
    $("#PrivateLink").focus().select();
  };

  const pages = [
    {
      showTitle: true,
      showButton: true,
      buttonTitle: "ADD",
      title: "Collaborators",
      parent: "share",
      name: "collaborators",
      collaborators: true,
      props: {
        ...props,
        setCollaboratorsModal,
        visibleModal: { collaborators: true },
      },
      handleOnClick: () => {
        setCollaboratorsModal("addCollaborators");
      },
    },
  ];

  const [pagesArray, setPagesArray] = useState(pages);
  const currentPage = pages[0];

  return (
    <StandardTallModalPopup
      goBack={goBack}
      closePopUp={closePopUp}
      pages={pagesArray}
      currentPage={currentPage}
    />
  );
};

export default CollaboratorsWorkflow;
