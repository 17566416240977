import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/components/Card/IndicatorMapCardContainer.css";
import withCardContainer from "../Card/CardContainer";
import { withRouter } from "react-router";
import { arrayOfMapPlaceholders } from "../../utility/utilities";
import { ButtonComponent } from "common-components";

class IndicatorMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPlaceholder:
        (props.id.charCodeAt(props.id.length - 1) +
          props.id.charCodeAt(props.id.length - 2)) %
        arrayOfMapPlaceholders.length,
    };
    this.maps = React.createRef();
  }

  handleClick = () => {
    const { isEditingEnabled, handleWideCardVisible, data } = this.props;
    if (isEditingEnabled !== true && handleWideCardVisible !== undefined) {
      handleWideCardVisible(data.boardCardUse.id);
    }
  };

  handleAreaTallModalPopUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, showCardAreaPopUp } = this.props;
    const savedAreaId = data && data.boardCardUse.configuration.savedArea;
    showCardAreaPopUp(data.boardCardUse.id, savedAreaId);
  };

  showAreaToolTip = () => {
    const { showToolTip } = this.props;
    const bounds = this.maps ? this.maps.current.getBoundingClientRect() : null;
    showToolTip(bounds.x + 40, bounds.y + 30, "Select New Area");
  };

  render() {
    const {
      isEditingEnabled,
      isPublishedStory,
      data,
      hideToolTip,
      isMapVisible,
      id,
      subComponentType,
    } = this.props;
    const { mapPlaceholder } = this.state;
    const areaTag =
      data &&
      data.boardCardUse &&
      data.boardCardUse.configuration.savedArea.name;
    const titleLength =
      data.boardCardUse.configuration.title != null
        ? data.boardCardUse.configuration.title.length
        : 0;
    return (
      <div>
        <div
          className={
            isEditingEnabled
              ? "adiMapCardContainer movable"
              : "adiMapCardContainer clickable"
          }
          onClick={this.handleClick}
        >
          <div className="adiMapCardContainer-map">
            {isMapVisible === true ? (
              <QueryRenderer
                environment={environment}
                query={graphql`
                  query IndicatorMapCardContainerQuery(
                    $input: ID!
                    $centroidInput: Boolean
                  ) {
                    boardCardUse(id: $input) {
                      id
                      mapImage
                      configuration {
                        indicators {
                          edges {
                            node {
                              alpha
                              indicator {
                                id
                              }
                              palette
                            }
                          }
                        }
                        palette
                        savedArea {
                          id
                          extent
                          primaryShapes {
                            edges {
                              node {
                                geojsonFeature
                              }
                            }
                          }
                          indicatorShapes(
                            id: $input
                            centroidsOnly: $centroidInput
                          ) {
                            edges {
                              node {
                                geojsonFeature
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `}
                variables={{
                  input: id,
                  centroidInput: subComponentType === "bubbleMap",
                }}
                render={({ error, props }) => {
                  if (error) {
                    console.error(error);
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return (
                      <div>
                        <br />
                        <br />
                        Loading...
                      </div>
                    );
                  }
                  // console.log("project board ------> ", JSON.stringify(props))
                  if (props.boardCardUse == null) {
                    return (
                      <div>
                        <br />
                        You need to log in as a user with permissions to access
                        this content.
                      </div>
                    );
                  }
                  const { savedArea } = props.boardCardUse.configuration;
                  if (
                    props.boardCardUse.mapImage == null ||
                    (savedArea.indicatorShapes &&
                      !savedArea.indicatorShapes.edges.length)
                  ) {
                    return (
                      <div>
                        <br />
                        (Map Not Available)
                      </div>
                    );
                  }
                  return (
                    <div>
                      <img
                        src={
                          "https://www.broadstreet.io" +
                          props.boardCardUse.mapImage
                        }
                        alt=""
                      />
                      <div className="mapboxgl-ctrl-bottom-right">
                        <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact">
                          <div className="mapboxgl-ctrl-attrib-inner">
                            <a
                              href="https://www.maptiler.com/copyright/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              © MapTiler{" "}
                            </a>
                            <a
                              href="https://www.openstreetmap.org/copyright"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              © OpenStreetMap contributors
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="adiMapCardContainer-watermrk-img">
                        <img
                          src={require("../../images/favicon1.svg")}
                          alt="BroadStreet"
                        />
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              <img src={arrayOfMapPlaceholders[mapPlaceholder]} alt="" />
            )}
          </div>
          <div
            className={
              titleLength > 95
                ? "adiMapCardContainer-title very-long-story-title title-spacing"
                : titleLength > 70
                ? "adiMapCardContainer-title long-story-title title-spacing"
                : "title-spacing adiMapCardContainer-title"
            }
          >
            <div
              className={
                "row px-3 d-flex justify-content-center align-items-center"
              }
            >
              <p className={"text-center"}>
                {data.boardCardUse.configuration.title}
              </p>
            </div>
            <div
              className={"row d-flex justify-content-center align-items-center"}
            >
              <div
                className={"text-center"}
                onMouseOver={
                  !isEditingEnabled && isPublishedStory && this.showAreaToolTip
                }
                onMouseOut={
                  !isEditingEnabled && isPublishedStory && hideToolTip
                }
                ref={this.maps}
              >
                {!isEditingEnabled ? (
                  <ButtonComponent
                    buttonType={
                      !isEditingEnabled && isPublishedStory
                        ? "select"
                        : isEditingEnabled
                        ? ""
                        : "link"
                    }
                    className={"btn-dashed"}
                    isPublishedStory={isPublishedStory}
                    isShuffleModeOn={isEditingEnabled}
                    isAreaTag={true}
                    iconClass={
                      !isEditingEnabled && isPublishedStory
                        ? "icon-arrow-down"
                        : ""
                    }
                    buttonTitle={areaTag ? areaTag : "United States"}
                    onClick={
                      !isEditingEnabled && isPublishedStory
                        ? this.handleAreaTallModalPopUp
                        : null
                    }
                  />
                ) : (
                  <p
                    className={
                      "text-center d-flex justify-content-center align-items-center"
                    }
                  >
                    {areaTag ? areaTag : "United States"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCardContainer(withRouter(IndicatorMapCardContainer));
