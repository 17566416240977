import React, { useState } from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const RemoveCardPopup = ({ closePopUp }) => {
  const [deleting, setDeleting] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const removeCard = () => {
    setDeleting(true);
    setErrorMsg("");
    setSuccessMsg("");
    const mutation = graphql`
      mutation RemoveCardPopupMutation(
        $input: RemoveDefaultPaymentMethodInput!
      ) {
        removeDefaultPaymentMethod(input: $input) {
          success
        }
      }
    `;
    const variables = {
      input: {
        clientMutationId: null,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.removeDefaultPaymentMethod.success) {
          setSuccessMsg("Removed your card");
        } else {
          setErrorMsg("Failed to remove your card");
        }
        setDeleting(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setDeleting(false);
      },
    });
  };

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "remove card",
      titleClass: "text-center mb-4 font-weight-bold text-uppercase",
      name: "remove-card",
      formItems: [
        {
          type: "string",
          text: "If you do not have any card on file at the time of your next invoice, your membership may be paused.",
          className: "mb-5 text-center",
        },
        {
          type: "alert",
          alertMsg: errorMsg,
          alertClass: "alert-danger rounded",
        },
        {
          type: "alert",
          alertMsg: successMsg,
          alertClass: "alert-success rounded",
        },
        {
          type: "button",
          multipleButtons: true,
          componentWrapperClass: "d-flex justify-content-around",
          buttons: [
            {
              buttonTitle: "Cancel",
              buttonType: "link",
              className: "button button-floating",
              handleOnClick: () => closePopUp(),
            },
            {
              buttonTitle: deleting ? "Removing" : "Remove",
              disabled: deleting,
              handleOnClick: () => removeCard(),
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      {pagesArray.length && (
        <StandardTallModalPopup
          pages={pagesArray}
          currentPage={pagesArray[0]}
          closePopUp={closePopUp}
          goBack={closePopUp}
        />
      )}
    </>
  );
};

export default RemoveCardPopup;
