import React, { Component } from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import "../../css/components/SignInSignUpForgotPassword/SignInSignUpForgotPassword.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import Dropin from "braintree-web-drop-in";

class SignupPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: props.match.params.email,
      password: "",
      isLoginPopUpVisible: false,
      newMember: true,
      communityCommonsPopupVisible: false,
      thankYouPopup: false,
      donatepropmt: false,
      percent: 20,
      isForgotPasswordPopUpVisible: false,
      handleForgotPasswordPopUp: false,
      isSignUpCalled: false,
      paymentError: null,
      isPaymentValid: false,
      isFnameValid: false,
      lname: "",
      isLnameValid: false,
      warningMessage: "",
    };
  }

  onSlide = (render, handle, value, un, percent) => {
    const percent_value = parseInt(percent[0], 10);
    if (percent_value === 0) {
      this.setState({ percent: 1 });
    } else if (percent_value >= 16 && percent_value < 32) {
      this.setState({ percent: 10 });
    } else if (percent_value >= 32 && percent_value < 48) {
      this.setState({ percent: 20 });
    } else if (percent_value >= 48 && percent_value < 64) {
      this.setState({ percent: 40 });
    } else if (percent_value >= 64 && percent_value < 80) {
      this.setState({ percent: 60 });
    } else if (percent_value >= 80 && percent_value < 100) {
      this.setState({ percent: 120 });
    } else if (percent_value === 100) {
      this.setState({ percent: 240 });
    }
  };

  submitBraintreePayment = (payment_nonce) => {
    this.setState({
      isPaymentValid: true,
      isPaymentInProgress: true,
      paymentError: null,
    });
    const mutation = graphql`
      mutation SignupPopUpBtSubmitPaymentMutation(
        $input: CreateSubscriptionInput!
      ) {
        createSubscription(input: $input) {
          success
          validationErrors {
            key
            message
          }
        }
      }
    `;

    const { percent } = this.state;
    const variables = {
      input: {
        // TODO: fill in amount from slider
        // "amount": "2001",
        amount: percent,
        clientNonce: payment_nonce,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO: first check if response returns success=true then hit the callback method
        if (response.createSubscription.success === false) {
          this.setState({ isPaymentValid: false });
          this.setState({
            isPaymentInProgress: false,
            paymentError:
              "We were unable to process your payment. Please verify and try again.",
          });
        } else {
          this.setState({
            thankYouPopup: true,
            isPaymentInProgress: false,
            donatepropmt: false,
            communityCommonsPopupVisible: false,
            newMember: false,
          });
        }
      },
      onError: (err) => {
        console.error(err);
        this.setState({ isPaymentValid: false });

        if (err) {
          this.setState({
            isPaymentInProgress: false,
            paymentError:
              err.errors[0].message.replace(/^'(.*)'$/, "$1") +
              ". Please try again.",
          });
        }
        console.log("err", err);
      },
    });
  };

  startBraintreeDropIn = () => {
    const mutation = graphql`
      mutation SignupPopUpBtGetTokenMutation($input: CreateClientTokenInput!) {
        createClientToken(input: $input) {
          clientToken
        }
      }
    `;

    const variables = {
      input: {
        clientVersion: "1.0",
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (response.createClientToken.clientToken == null) {
          //TODO: handle error
        }
        this.setState({
          thankYouPopup: false,
          communityCommonsPopupVisible: false,
          newMember: false,
          donatepropmt: true,
          brainTreeClientKey: response.createClientToken.clientToken,
        });
        var button = document.querySelector("#submit4");
        this.braintreeDropinInstance = null;
        Dropin.create(
          {
            authorization: response.createClientToken.clientToken,
            container: "#dropin-container",
            paypal: {
              flow: "vault",
            },
          },
          (createErr, instance) => {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.
              console.error(createErr);
              return;
            }
            this.braintreeDropinInstance = instance;
            this.setState({ isPaymentValid: true });
            button.addEventListener("click", () => {
              instance.requestPaymentMethod(
                (requestPaymentMethodErr, payload) => {
                  if (requestPaymentMethodErr) {
                    // No payment method is available.
                    // An appropriate error will be shown in the UI.
                    console.error(requestPaymentMethodErr);
                    return;
                  }

                  // Submit payload.nonce to your server
                  this.submitBraintreePayment(payload.nonce);
                }
              );
            });
          }
        );
      },
      onError: (err) => {
        console.log("startBraintreeDropIn", err);
      },
    });
  };

  SignUp = (event) => {
    event.preventDefault();
    const { isSignUpCalled } = this.state;
    if (isSignUpCalled === true) {
      return;
    }
    this.setState({ isSignUpCalled: true }, () => {
      const mutation = graphql`
        mutation SignupPopUpMutation($input: UpdateVerifyEmailRequestInput!) {
          updateVerifyEmail(input: $input) {
            sessionid
            verifyEmailRequestNode {
              signupStep
              userEmail
            }
            validationErrors {
              key
              message
            }
          }
        }
      `;
      const { email, name, lname, password } = this.state;
      const { match, handleSignupPopUp } = this.props;
      const variables = {
        input: {
          signupStep: "2",
          userEmail: email,
          verificationKey: match.params.token,
          fullName: name + " " + lname,
          temporaryPassword: password,
        },
      };

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          handleSignupPopUp();
          // Hide as per issue #36 People will Log In immediately after Step 2: Select Name and
          // Password. The following pages will be removed: TODO first check if response returns
          // true then hit the callback method this.setState({ thankYouPopup: false,
          // communityCommonsPopupVisible: true, newMember: false, donatepropmt: false })
        },
        onError: (err) => {
          console.error(err);
          this.setState({ isSignUpCalled: false });
          alert(err.errors[0].message);
        },
      });
    });
  };

  handleNames(name, e) {
    let pattern = e.target.value.length;

    name === "lname"
      ? pattern > 1
        ? this.setState({ lname: e.target.value, isLnameValid: true })
        : this.setState({ lname: "", isLnameValid: false })
      : pattern > 1
      ? this.setState({
          name: e.target.value,
          isFnameValid: true,
          warningMessage: "",
        })
      : this.setState({
          name: "",
          isFnameValid: false,
          warningMessage: "First name should contain atleast 2 characters.",
        });
  }

  render() {
    const {
      percent,
      warningMessage,
      communityCommonsPopupVisible,
      newMember,
      isLnameValid,
      isFnameValid,
      email,
      password,
      isSignUpCalled,
      donatepropmt,
      name,
      paymentError,
      isPaymentValid,
      thankYouPopup,
      isPaymentInProgress,
    } = this.state;
    const { loginPopUpType, handleSignupPopUp } = this.props;
    return (
      <div
        className={loginPopUpType === "Action" ? "wer" : "wer"}
        style={{ backgroundColor: "#a2a2a2de" }}
      >
        <div
          className={
            loginPopUpType === "Action"
              ? "container  loginPop"
              : "container loginwer1"
          }
          style={{
            maxWidth: "100%",
            top: 40,
            height: "100%",
            paddingBottom: 80,
          }}
        >
          <div className="main-content login-before" style={{ height: "100%" }}>
            <div
              className="signup-content text-center"
              style={{ width: "100%", height: "100%" }}
            >
              {/* Sign up popup */}

              <div
                style={{
                  display: newMember ? "block" : "none",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer onesignupWidth"
                  }
                >
                  <div className="col-md-12" style={{ paddingBottom: 20 }}>
                    <img
                      id="logos"
                      src={require("../../images/logo1.svg")}
                      style={{ width: 263.37 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <h2 className="form-title" style={{ marginBottom: "20%" }}>
                      WELCOME TO BROADSTREET
                    </h2>
                  </div>

                  <div className="col-md-12" style={{ padding: 0 }}>
                    <h3 className="form-title">
                      Please enter name and password below:
                    </h3>
                  </div>

                  <div className="col-12">
                    <div className="mb-3 newfrom signup-input-group">
                      <input
                        type="name"
                        className="form-input"
                        name="name"
                        id="name"
                        placeholder="First Name"
                        onChange={(e) => {
                          this.handleNames("name", e);
                        }}
                        style={{ width: "49%" }}
                      />
                      <input
                        type="lname"
                        className="form-input"
                        name="lname"
                        id="lname"
                        placeholder="Last Name"
                        onChange={(e) => {
                          this.handleNames("lname", e);
                        }}
                        style={{ width: "49%", marginLeft: "2%" }}
                      />
                      {isFnameValid && isLnameValid ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-3 newfrom signup-input-group">
                      {/* <input type="email" className="form-input" name="email" id="email" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value }) }} /> */}
                      <input
                        type="email"
                        className="form-input w-100"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        readOnly
                      />
                      {email ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3 newfrom signup-input-group">
                      <input
                        type="password"
                        className="form-input w-100"
                        name="password"
                        id="password"
                        placeholder="Password"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                      {password ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12">
                    {name && email && password && isSignUpCalled === false ? (
                      <input
                        type="button"
                        name="submit"
                        id="submit1"
                        className="form-submit submit-click"
                        value="SIGN UP"
                        onClick={(event) => {
                          this.SignUp(event);
                        }}
                      />
                    ) : (
                      <input
                        type="button"
                        name="submit"
                        id="submit1"
                        className="form-submit  submit-btn disabled"
                        value={
                          isSignUpCalled === false ? "SIGN UP" : "WORKING..."
                        }
                      />
                    )}
                  </div>
                  <p className="wrong-password">{warningMessage}</p>
                </form>

                <div className="warnings Signupwer">
                  <p>
                    By clicking above, you agree to the{" "}
                    <a
                      href="https://www.broadstreet.io/legal/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                    <span> and </span>
                    <a
                      href="https://www.broadstreet.io/legal/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    . BroadStreet may send emails and the BroadStreet platform
                    does use cookies.
                  </p>
                </div>
              </div>
              {/* Sign up popup */}

              {/* BroadStreet popup */}

              <div
                style={{
                  display: communityCommonsPopupVisible ? "block" : "none",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer signupWidth"
                  }
                >
                  <div
                    onClick={handleSignupPopUp}
                    className="close-arrow"
                    style={{ top: 15, right: 10, cursor: "pointer" }}
                  >
                    CLOSE
                    <img
                      id="close"
                      src={require("../../images/closeCross.svg")}
                      style={{ width: 12, marginLeft: 8, marginTop: -2 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12" style={{ paddingBottom: 20 }}>
                    <img
                      id="logos"
                      src={require("../../images/logo1.svg")}
                      style={{ width: 263.37 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12">
                    <h2 className="form-title set-h">
                      BroadStreet <br />
                      is a <span className="Signup-border-bottom">
                        Free
                      </span>{" "}
                      Resource
                    </h2>
                  </div>

                  <div className="col-md-12">
                    <p className="p-color">
                      As a member of BroadStreet, you are connected to thousands
                      of voices and a commitment to improving health and
                      wellbeing for all.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="p-color">
                      Your contribution ensures that BroadStreet remains a
                      trusted and innovative resource for changemakers like you.
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p className="p-color">
                      Become a sustaining Commons contributor today.
                    </p>
                  </div>
                  <div
                    className="col-md-7"
                    style={{ marginTop: 15, margin: "auto" }}
                  >
                    <div className="setslider">
                      {percent && <div className="colorpicker">${percent}</div>}
                    </div>

                    <Nouislider
                      accessibility
                      start={85}
                      range={{
                        min: [1],
                        max: [240],
                      }}
                      onSlide={this.onSlide}
                    />
                  </div>

                  <div>
                    <input
                      type="submit"
                      name="submit"
                      id="submit3"
                      className="form-submit submit-click"
                      value="Contribute"
                      style={{ width: 272, marginTop: 40 }}
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState(
                          {
                            donatepropmt: true,
                            thankYouPopup: false,
                            communityCommonsPopupVisible: false,
                            newMember: false,
                          },
                          () => {
                            this.startBraintreeDropIn();
                          }
                        );
                      }}
                    />
                  </div>
                </form>
              </div>

              {/* BroadStreet popup */}

              {/* Donate Prompt popup */}
              <div
                style={{
                  display: donatepropmt ? "block" : "none",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer signupWidth"
                  }
                >
                  <div
                    onClick={handleSignupPopUp}
                    className="close-arrow"
                    style={{ top: 15, right: 10, cursor: "pointer" }}
                  >
                    CLOSE
                    <img
                      id="close"
                      src={require("../../images/closeCross.svg")}
                      style={{ width: 12, marginLeft: 8, marginTop: -2 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12" style={{ paddingBottom: 20 }}>
                    <img
                      id="logos"
                      src={require("../../images/logo1.svg")}
                      style={{ width: 263.37 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12">
                    <h2 className="form-title set-h">Make a Donation</h2>
                  </div>
                  <div className="col-md-12">
                    <h3 className="form-title set-h">
                      ${percent} Annual Contribution to BroadStreet.
                    </h3>
                  </div>
                  <a
                    href="#brainTree"
                    tabIndex={0}
                    onClick={() => {
                      if (this.braintreeDropinInstance != null) {
                        this.braintreeDropinInstance.teardown((err) => {
                          if (err) {
                            console.error("drop-in teardown error", err);
                          }
                        });
                      }
                      this.setState({
                        thankYouPopup: false,
                        communityCommonsPopupVisible: true,
                        newMember: false,
                        donatepropmt: false,
                      });
                    }}
                  >
                    {" "}
                    change amount
                  </a>

                  <div className="col-md-12">
                    <h3>
                      <span style={{ color: "#CA2A2A" }}>{paymentError}</span>
                    </h3>
                  </div>

                  <div id="dropin-container" />

                  <div>
                    {isPaymentValid ? (
                      <input
                        type="button"
                        name="submit"
                        id="submit4"
                        className="form-submit submit-click "
                        value={
                          isPaymentInProgress ? "Working..." : "Contribute"
                        }
                        style={{ width: 272 }}
                      />
                    ) : (
                      <input
                        type="button"
                        name="submit"
                        id="submit4"
                        className="form-submit submit-btn disabled"
                        value="Contribute"
                        style={{ width: 272 }}
                      />
                    )}
                  </div>
                </form>
              </div>
              {/* Donate Prompt popup */}

              {/* Thank you popup */}

              <div
                style={{
                  display: thankYouPopup ? "block" : "none",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer signupWidth-thank"
                  }
                >
                  <div
                    onClick={handleSignupPopUp}
                    className="close-arrow"
                    style={{ top: 15, right: 10, cursor: "pointer" }}
                  >
                    CLOSE
                    <img
                      id="close"
                      src={require("../../images/closeCross.svg")}
                      style={{ width: 12, marginLeft: 8, marginTop: -2 }}
                      alt=""
                    />
                  </div>

                  <div className="col-md-12">
                    <h2 className="form-title thank-h2 ">
                      <span className="thank-border-bottom">Thank you</span> so
                      much <br />
                      for your contribution!
                    </h2>
                  </div>

                  <div className="col-md-12">
                    <p className="p-thank">
                      Your generous support will help us provide the tools and
                      resources to changemakers across the nation.
                    </p>
                  </div>

                  <div className="col-md-12"></div>

                  <div>
                    <input
                      type="button"
                      name="submit"
                      id="submit5"
                      className="form-group form-submit submit-btn thank-you-continue-btn"
                      value="Continue to BroadStreet"
                      style={{ width: 272 }}
                      onClick={handleSignupPopUp}
                    />
                  </div>
                </form>
              </div>

              {/* Thank you popup */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupPopUp;
