import React, { Component } from 'react';
import '../../css/admin.settings.css';
import '../../css/themify-icons/css/themify-icons.css';
import { QueryRenderer, commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';

class MyProfilePageContent extends Component {
  constructor() {
    super();
    this.state = {
      isWorking: false,
      saveKey: 0,
      wasError: false,
      wasSaved: false,
      wasWarning: false
    };
  }

  updateData = (reportName, briefBio, contactInfo, isProfilePublic) => {
    const { isWorking } = this.state;
    if (isWorking) {
      return;
    }
    this.setState({ isWorking: true, wasSaved: false, wasError: false, wasWarning: false });
    const mutation = graphql`
          mutation  
          MyProfilePageContent1Mutation($input: UpdateUserAccountInput!)  
            {  updateUserAccount (input:$input)  
              {  userAccountNode 
                { 
                userImage
                {
                    url
                }
                email
              }
                validationErrors  
                {  
                  key           
                  message  
                } 
              }  
            } 
        `;
    const input = {
      'userReportName': reportName,
      'briefBio': briefBio,
      'contactInfo': contactInfo,
      'isProfilePublic': isProfilePublic
    };

    commitMutation(
      environment,
      {
        mutation,
        variables: { input },
        onCompleted: (response, errors) => {
          console.log('Profile update response from server.', JSON.stringify(response));
          // check if you are getting error messages then reload the component.
          if (response.updateUserAccount.validationErrors != null &&
            response.updateUserAccount.validationErrors.length > 0) {
            this.setState(prevState => {
              return { isWorking: false, wasError: true, wasSaved: false, wasWarning: false };
            });
          } else {
            this.setState(prevState => {
              return {
                isWorking: false,
                saveKey: prevState.saveKey + 1,
                wasSaved: true,
                wasError: false,
                wasWarning: false
              };
            });
          }
        },
        onError: (err) => {
          console.error(JSON.stringify(err));
          //alert('Profile update failed, try again.');
          this.setState(prevState => {
            return { isWorking: false, wasError: true, wasSaved: false, wasWarning: false };
          });
          // TODO: Show error message
        }
      }
    );
  };

  render() {
    const { saveKey, isWorking, wasError, wasSaved, wasWarning } = this.state;
    return (<QueryRenderer key={saveKey}
      environment={environment}
      query={graphql`
                  query MyProfilePageContentSettingsQuery
                  {
                    currentUser
                    {
                        edges {
                            node {
                                userReportName
                                briefBio
                                contactInfo
                                isProfilePublic
                            }
                        }
                    }
                  }
              `}
      render={({ error, props }) => {
        if (error) {
          console.error(error);
          return <div>Error!</div>;
        }
        if (!props) {
          return <div />;
        }
        if (props.currentUser == null || props.currentUser.edges == null || props.currentUser.edges[0] == null) {
          return <div>You must log in to edit your account settings.</div>;
        }
        const userProps = props.currentUser.edges[0].node;
        return (<MyProfilePageForm key={saveKey} userProps={userProps}
          onSave={this.updateData}
          isWorking={isWorking}
          wasError={wasError}
          wasSaved={wasSaved}
          wasWarning={wasWarning} />);
      }
      } />);
  }
}

class MyProfilePageForm extends Component {
  constructor(props) {
    super(props);
    const { userProps } = props;
    this.state = {
      briefBio: userProps.briefBio ? userProps.briefBio : '',
      contactInfo: userProps.contactInfo ? userProps.contactInfo : '',
      isProfilePublic: userProps.isProfilePublic,
      userReportName: userProps.userReportName
    };
  }

  updateFormData = () => {
    const { userReportName, briefBio, contactInfo, isProfilePublic } = this.state;
    const { onSave } = this.props;
    onSave(userReportName, briefBio, contactInfo, isProfilePublic);
  };

  handleChange = (event) => {
    if (event.target.name === 'isProfilePublic') {
      this.setState({
        isProfilePublic: event.target.checked
      });
    }
    else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  render() {
    const { wasSaved, wasWarning, isWorking, wasError } = this.props;
    const { userReportName, briefBio, contactInfo, isProfilePublic } = this.state;
    return (
      <div className="tab-pane fade active show" id="accordion-3">
        {/* <div className="text-left mb-5">
          <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
        <div className="ibox-head">
          <h3 className="font-weight-bold">My Public Profile</h3>
          <button className="buttonsy save-btn"
            onClick={this.updateFormData}>{isWorking ? 'SAVING' : 'Save'}</button>
        </div>
        <br />
        {wasSaved ?
          <div className="alert alert-success alert-dismissible show-alert" role="alert">Saved</div>
          : null}
        {wasWarning ?
          <div className="alert alert-warning alert-dismissible show-alert"
            role="alert">Warning</div>
          : null}
        {wasError ?
          <div className="alert alert-danger alert-dismissible show-alert" role="alert">Failed to
            Save</div>
          : null}
        <div className="row myprofileForm">
          <div className="col-lg-3">
            <div className="flex-1">
              <div className="media-heading">About You</div>
              <small className="text-muted">
                Share your unique information
              </small>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-4">
              <label>Display Name</label>
              <input className="form-control" type="text" name="userReportName"
                value={userReportName} onChange={this.handleChange} />
            </div>
            <div className="form-group mb-4">
              <label>Short Bio</label>
              <textarea onChange={this.handleChange} name="briefBio" className="form-control"
                rows="3"
                style={{'height': '60px'}}
                value={briefBio} />
            </div>
            <div className="form-group mb-4">
              <label>Contact Info</label> <label className="emailTiwtterClass">(eg.
              Email, Twitter, Linkedin, Website)</label>
              <input onChange={this.handleChange} name="contactInfo" className="form-control"
                type="text"
                placeholder="Contact Info" value={contactInfo} />
            </div>
            <div className="form-group mb-4">
              <label>Profile Privacy</label><br />
              <label
                className={'ui-switch switch-large' + (isProfilePublic ? ' switch-solid-info' : ' switch-solid-info-off')}>
                Ready to share your profile? turn this on &nbsp;&nbsp; <input name="isProfilePublic"
                  onChange={this.handleChange}
                  type="checkbox"
                  checked={isProfilePublic} />
                <span />
              </label><br />
              <label className="myprofilePrivacyNote">This will make your information available to other members of the BroadStreet Network.</label>
            </div>
          </div>
          <div className="col-lg-3">
          </div>
        </div>
      </div>);
  }
}

export default MyProfilePageContent;
