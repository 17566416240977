import {
  Environment,
  Network,
  RecordSource,
  Store
} from 'relay-runtime';
import fetchWithRetries from 'fbjs/lib/fetchWithRetries';
import { RequestNode, Variables, CacheConfig, UploadableMap } from 'relay-runtime';
import { isEmpty } from 'lodash';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { apiBaseUrl } from './utility/utilities';

const isMutation = (request: RequestNode) => request.operationKind === 'mutation';

const fetchFunction = async (
  request: RequestNode,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables: ?UploadableMap
) => {
  let body;
  let headers;
  // check if request includes file upload
  if (!isEmpty(uploadables)) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }
    // construct multipart/form-data body for API post
    const formData = new FormData();
    let operations = {'query': request.text, 'variables': variables};
    formData.append('operations', JSON.stringify(operations));
    formData.append('map', '{ "0": ["variables.input.file"] }');
    //formData.append('query', request.text);
    //formData.append('variables', JSON.stringify(variables));

    Object.keys(uploadables).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
    headers = {
      Accept: 'application/json',
      authorization: 'AIzaSyCbMEN223iJ2r-GhBcPHda0V7mo3vz1uRo'
    };
  }
  else {
    // construct application/json body for API post
    body = JSON.stringify({
      query: request.text, // GraphQL text from input
      variables
    });
    headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      authorization: 'AIzaSyCbMEN223iJ2r-GhBcPHda0V7mo3vz1uRo'
    };
  }

  //TODO: use process.env.GRAPHQL_ENDPOINT to define API URL
  const response = await fetchWithRetries(apiBaseUrl + 'graphql', {
    //const response = await fetchWithRetries('https://www.communitycommons.org/graphql', {
    method: 'POST',
    credentials: 'include',
    headers,
    body,
    fetchTimeout: 20000,
    //    retryDelays: [1000, 3000, 5000, 10000],
    retryDelays: [1000]
  });

  const data = await response.json();

  if (isMutation(request) && data.errors) {
    throw data;
  }

  return data;
};

const fetchFunctionLongTimeout = async (
  request: RequestNode,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables: ?UploadableMap
) => {
  let body;
  let headers;
  // check if request includes file upload
  if (!isEmpty(uploadables)) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }
    // construct multipart/form-data body for API post
    const formData = new FormData();
    let operations = {'query': request.text, 'variables': variables};
    formData.append('operations', JSON.stringify(operations));
    formData.append('map', '{ "0": ["variables.input.file"] }');

    Object.keys(uploadables).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
    headers = {
      Accept: 'application/json',
      authorization: 'AIzaSyCbMEN223iJ2r-GhBcPHda0V7mo3vz1uRo'
    };
  }
  else {
    // construct application/json body for API post
    body = JSON.stringify({
      query: request.text, // GraphQL text from input
      variables
    });
    headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      authorization: 'AIzaSyCbMEN223iJ2r-GhBcPHda0V7mo3vz1uRo'
    };
  }

  const response = await fetchWithRetries(apiBaseUrl + 'graphql', {
    method: 'POST',
    credentials: 'include',
    headers,
    body,
    fetchTimeout: 60000,
    retryDelays: [1000]
  });

  const data = await response.json();

  if (isMutation(request) && data.errors) {
    throw data;
  }

  return data;
};

const setupSubscription = (config, variables, cacheConfig, observer) => {
  const query = config.text;
  console.log('==query==', query);

  const subscriptionClient = new SubscriptionClient(apiBaseUrl + 'graphql', {reconnect: true});
  subscriptionClient.subscribe({query, variables}, (error, result) => {
    observer.onNext({data: result});
  });
  console.log('==subscriptionClient==', subscriptionClient);
};

const environment = new Environment({
  network: Network.create(fetchFunction, setupSubscription),
  // network: Network.create(fetchFunction),
  store: new Store(new RecordSource())
});

export const longTimeoutEnvironment = new Environment({
  network: Network.create(fetchFunctionLongTimeout, setupSubscription),
  store: new Store(new RecordSource())
});

export default environment;
