/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SavedAreaTypeOfArea = "COUNTYLIST" | "PLACELIST" | "ZIPLIST" | "%future added value";
export type ServiceAreaMapCardContainerQueryVariables = {|
  input: string
|};
export type ServiceAreaMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +mapImage: ?string,
    +statistics: ?{|
      +id: string,
      +jsonData: string,
    |},
    +configuration: {|
      +savedArea: ?{|
        +id: string,
        +name: string,
        +typeOfArea: SavedAreaTypeOfArea,
        +reportstatisticsSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +saZctaCount: number,
              +saGeo: string,
              +saStateList: string,
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type ServiceAreaMapCardContainerQuery = {|
  variables: ServiceAreaMapCardContainerQueryVariables,
  response: ServiceAreaMapCardContainerQueryResponse,
|};
*/


/*
query ServiceAreaMapCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    mapImage
    statistics {
      id
      jsonData
    }
    configuration {
      savedArea {
        id
        name
        typeOfArea
        reportstatisticsSet {
          edges {
            node {
              id
              saZctaCount
              saGeo
              saStateList
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mapImage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardCardStatisticsNode",
  "kind": "LinkedField",
  "name": "statistics",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsonData",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SavedAreaNode",
  "kind": "LinkedField",
  "name": "savedArea",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfArea",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportStatisticsNodeConnection",
      "kind": "LinkedField",
      "name": "reportstatisticsSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportStatisticsNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportStatisticsNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saZctaCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saGeo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saStateList",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceAreaMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceAreaMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ServiceAreaMapCardContainerQuery",
    "operationKind": "query",
    "text": "query ServiceAreaMapCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    mapImage\n    statistics {\n      id\n      jsonData\n    }\n    configuration {\n      savedArea {\n        id\n        name\n        typeOfArea\n        reportstatisticsSet {\n          edges {\n            node {\n              id\n              saZctaCount\n              saGeo\n              saStateList\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5923ecb32dcf6837d2bec25d3badb4a4';

module.exports = node;
