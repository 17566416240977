import React, { Component, Fragment } from "react";
import ReportCardContainer from "../Card/ReportCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import DotMapCardContainer from "../Card/DotMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import IndicatorMapCardContainer from "../Card/IndicatorMapCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import StoryCardContainer from "../Card/StoryCardContainer";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import { reportToolCardArray } from "../../utility/utilities";

class SectionCard extends Component {
  render() {
    const {
      item,
      index,
      changeCardHeight,
      isEditingEnabled,
      hideUnHidePopUp,
      showToolTip,
      hideToolTip,
      handleCloseUpView,
      handleSelectThemePopUp,
      handleSaveCardsPopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      handleArrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      handleUpdateBoard,
      userData,
      openCardFamilyPopupWithUrl,
      handleWideCardVisible,
      handleLoginPopUp,
      history,
      openStoryPopup,
      openStoryPopupWithUrl,
      isSavedTooltip,
      savedCardId,
      handleReRenderCard,
      onCardButtonClick,
      showCardAreaPopUp,
      isPublishedStory,
    } = this.props;

    const componentType = item.node.frontFacingComponent.componentType;
    const isComponentType =
      componentType !== "ReportCard" &&
      componentType !== "AdiMapCard" &&
      componentType !== "ServiceAreaMapCard" &&
      componentType !== "IndicatorMapCard" &&
      componentType !== "ChartCard" &&
      componentType !== "MapCard" &&
      componentType !== "DotMapCard";

    const { subComponentType } = item.node;
    const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;

    return (
      <Fragment>
        {componentType === "ReportCard" && (
          <ReportCardContainer
            isHomeFeed={false}
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            onCloseUpView={handleCloseUpView}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
          />
        )}
        {componentType === "AdiMapCard" && (
          <AdiMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
            handleWideCardVisible={handleWideCardVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
            onCardButtonClick={onCardButtonClick}
          />
        )}
        {componentType === "DotMapCard" && (
          <DotMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
            handleWideCardVisible={handleWideCardVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
            onCardButtonClick={onCardButtonClick}
          />
        )}
        {componentType === "ServiceAreaMapCard" && (
          <ServiceAreaMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
            handleWideCardVisible={handleWideCardVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
          />
        )}
        {componentType === "IndicatorMapCard" && (
          <IndicatorMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
            handleWideCardVisible={handleWideCardVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
          />
        )}
        {componentType === "ChartCard" && !reportToolCard && (
          <ChartCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapcardsVisible={true}
            userData={userData}
            handleLoginPopUp={handleLoginPopUp}
            history={history}
            openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
            handleWideCardVisible={handleWideCardVisible}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
          />
        )}

        {componentType === "ChartCard" && reportToolCard && (
          <div key={item.node.id} className="grid-card">
            <ReportToolCardContainer
              changeCardHeight={changeCardHeight}
              isEditingEnabled={isEditingEnabled}
              subComponentType={item.node.subComponentType}
              statistics={item.node.statistics}
              isHomeFeed={false}
              isCloseUpView={false}
              index={index}
              key={item.node.id}
              data={item}
              hideUnHidePopUp={hideUnHidePopUp}
              showToolTip={showToolTip}
              hideToolTip={hideToolTip}
              handleSelectThemePopUp={handleSelectThemePopUp}
              handleSaveCardsPopUp={handleSaveCardsPopUp}
              newTagsForCardObject={newTagsForCardObject}
              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
              handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
              selectedCardForMutation={selectedCardForMutation}
              isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
              handleUpdateBoard={handleUpdateBoard}
              isMapcardsVisible={true}
              userData={userData}
              handleLoginPopUp={handleLoginPopUp}
              history={history}
              openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
              handleWideCardVisible={handleWideCardVisible}
              isSavedTooltip={isSavedTooltip}
              savedCardId={savedCardId}
              handleReRenderCard={handleReRenderCard}
              onCardButtonClick={onCardButtonClick}
              showCardAreaPopUp={showCardAreaPopUp}
              isPublishedStory={isPublishedStory}
            />
          </div>
        )}

        {componentType === "MapCard" && (
          <StoryCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            isHomeFeed={false}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            onCloseUpView={handleCloseUpView}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            isMapcardsVisible={true}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
            handleWideCardVisible={handleWideCardVisible}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
            userData={userData}
          />
        )}
        {isComponentType && (
          <StoryCardContainer
            isHomeFeed={false}
            changeCardHeight={changeCardHeight}
            isEditingEnabled={isEditingEnabled}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={hideUnHidePopUp}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            onCloseUpView={handleCloseUpView}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            isMapcardsVisible={false}
            openStoryPopup={openStoryPopup}
            openStoryPopupWithUrl={openStoryPopupWithUrl}
            isSavedTooltip={isSavedTooltip}
            savedCardId={savedCardId}
            handleReRenderCard={handleReRenderCard}
            onCardButtonClick={onCardButtonClick}
            showCardAreaPopUp={showCardAreaPopUp}
            isPublishedStory={isPublishedStory}
            userData={userData}
          />
        )}
      </Fragment>
    );
  }
}

export default SectionCard;
