import React, { Component } from 'react';
import { commitMutation, graphql, QueryRenderer } from 'react-relay';
import environment from '../../RelayEnvironment';

class HomePageContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 'default'
    };
  }

  updateHomePage = () => {
    const {selectedValue} = this.state;
    const {updatedBoard} = this.props;
    const mutation = graphql`
            mutation HomePageContentMutation($input:SetPrimaryHomepageInput!) {
                setPrimaryHomepage(input: $input) {
                    boardNode {
                        id
                    }
                } 
            }
            `;
    if (selectedValue !== 'default') {
      const variables = {'input': {'boardId': selectedValue}};
      commitMutation(
        environment,
        {
          mutation,
          variables,
          onCompleted: (response, errors) => {
            updatedBoard();
          },
          onError: (err) => console.error(err)
        }
      );
    } else {
      const variable = {'input': {'boardId': null}};
      commitMutation(
        environment,
        {
          mutation,
          variables: variable,
          onCompleted: (response, errors) => {
            updatedBoard();
          },
          onError: (err) => console.error(err)
        }
      );
    }

  };

  onRadioValChange = (e) => {
    this.setState({selectedValue: e.currentTarget.value});
  };

  render() {
    const {selectedValue} = this.state;
    const {isWorking} = this.props;
    return (
      <div className="tab-pane fade active show" id="accordion-3">
        <div className="ibox-head">
          <div className="ibox-title">Homepage</div>
          <button className="buttonsy save-btn" onClick={this.updateHomePage}>
            {isWorking ? 'SAVING' : 'Save'}
          </button>
        </div>
        <br/>
        <div className="row homepage">
          <div className="col-lg-3 pt-2">
            <div className="flex-1">
              <div className="media-heading">Select a Homepage</div>
              <small className="text-muted">
                <p className="mb-0 mt-1">When you first log in,</p>
                <p className="mb-0">which homepage would</p>
                <p className="mb-0">you like to see?</p>
              </small>
            </div>
          </div>
          <div className="col-lg-9 pt-2 text-left">
            <div>
              <label>Please Select One:</label>
              <QueryRenderer
                key={'homePageContent'}
                environment={environment}
                query={graphql`
                                    query HomePageContentQuery {
                                    currentUser {
                                        edges {
                                            node {
                                                homepages {
                                                    edges {
                                                        node {
                                                            board {
                                                                id
                                                                name
                                                                faviconImage {
                                                                    url
                                                                }
                                                            }
                                                        isPrimaryHomepage
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                }`
                }
                render={({error, props}) => {
                  if (error) {
                    console.error(error);
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return <div/>;
                  }
                  const pageData = props.currentUser.edges && props.currentUser.edges[0].node.homepages.edges;
                  let isPrimaryPageSelected = false;
                  return (
                    <div>
                      {pageData && pageData.map((page) => {
                          isPrimaryPageSelected = !isPrimaryPageSelected && page.node.isPrimaryHomepage ? true : false;
                          const imageUrl = page.node.board.faviconImage ? page.node.board.faviconImage.url : '';
                          return (
                            <div className="radio" key={page.node.board.id}>
                              <div><input type="radio" className="form-check-input mr-2"
                                          value={page.node.board.id} id={page.node.board.id}
                                          name="optradio"
                                          onChange={(e) => this.onRadioValChange(e)}
                                          defaultChecked={page.node.isPrimaryHomepage}/>
                                <label htmlFor={page.node.board.id} className="checkmark"/>
                                <div className="d-flex">
                                  <div className="radio-content1 text-center pt-3">
                                    <img src={imageUrl} width={32} height={32}
                                         alt=""/>
                                  </div>
                                  <div className="radio-content2 text-center pt-3">
                                    {page.node.board.name}
                                  </div>
                                </div>
                              </div>
                            </div>);
                        }
                      )}
                      <div className="radio">
                        <div><input type="radio" className="form-check-input mr-2"
                                    value='default' id='default'
                                    name="optradio"
                                    onChange={(e) => this.onRadioValChange(e)}
                                    defaultChecked={!isPrimaryPageSelected && selectedValue === 'default'}/>
                          <label htmlFor='default' className="checkmark"/>
                          <div className="d-flex">
                            <div className="radio-content1 text-center pt-3">
                              <img src=''
                                   alt=""/>
                            </div>
                            <div className="radio-content2 text-center pt-3">
                              BroadStreet (default)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePageContent;
