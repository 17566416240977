import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3NumberCard from './D3NumberCard';

class D3NumberWideCard extends Component {
  render() {
    const { numberChildrenCard, id, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'numberChildrenWideCard' : 'numberChildrenWideCardTwitter');
    const content = (
      <div className='D3NumberWideCard d-flex w-100 h-100'>
        <D3NumberCard id={wideChartId} key={wideChartId}
                      title={numberChildrenCard.title}
                      tooltip={numberChildrenCard.tooltip}
                      subTittle={numberChildrenCard.subTitle}
                      description={numberChildrenCard.description}
                      min={numberChildrenCard.min}
                      max={numberChildrenCard.max}
                      value={numberChildrenCard.value}
                      usTitle={numberChildrenCard.usTitle}
                      usValue={numberChildrenCard.usValue}
                      isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3NumberWideCard;
