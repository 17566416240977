import React from "react";
import "../../css/components/MySavedAreasPopup/MySavedAreasPopup.css";
import BroadStreetServiceAreaMap from "../BroadStreetServiceAreaMap/BroadStreetServiceAreaMap";
import ReactDOM from "react-dom";
import environment from "../../RelayEnvironment";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { fetchApi } from "../../fetchApi";
import {
  WideModalPopup,
  ToolTip,
  SelectAreaPopup,
  ButtonComponent,
} from "common-components";

const infoIconImg = require("../../images/iconInfo.svg");

const toolTipMapText = (
  <span>
    <h1>Why are there so many types of geographies listed?</h1>
    <p>
      Data are sometimes available only at the county level or only at the ZIP
      code level. When this is the case, we use the geography of the dataset.
      <br />
    </p>
    <p>
      Whenever possible, we will use your preferred way of describing the area
      (ex. list of ZIP codes).
    </p>
  </span>
);

class MySavedAreasPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaType: "ziplist",
      toolTipX: 0,
      toolTipY: 0,
      isToolTipVisible: false,
      isBtnDisabled: false,
      isSaving: false,
      selectedAreaId: "",
      mapZipcodes: [],
      mapCounties: [],
      mapPlaces: [],
      searchText: "",
      selectedCounties: [],
      selectedPlaces: [],
      selectedZipcodes: [],
    };
  }

  onTextChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  closePopUp = () => {
    const { location, history } = this.props;
    const closeURL = location.state && location.state.closeURL;
    if (closeURL) {
      history.push(closeURL.replace("/board", ""));
    }
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  showToolTipMap = () => {
    const bounds = ReactDOM.findDOMNode(
      this.refs["maptip"]
    ).getBoundingClientRect();
    this.showToolTip(bounds.x - 15, bounds.y - 190, "maptip");
  };

  // show tooltip on various element
  showToolTip = (x, y, toolTipToShow) => {
    // console.log("home tool tip", text , x , y)
    this.setState({
      isToolTipVisible: true,
      toolTipX: x,
      toolTipY: y,
      toolTipShown: toolTipToShow,
    });
  };

  hideToolTip = () => {
    this.setState({ isToolTipVisible: false });
  };

  handleSelectedArea = (selectedArea) => {
    const { location, history, match } = this.props;
    const state = location.state;
    state.selectedAreaId = selectedArea;
    history.push("/areaselector/" + match.params.filterTag, state);
  };

  loadArea = async (id) => {
    if (id != null) {
      this.setState({ isBtnDisabled: false });
      let graphqlQuery = {
        query: `
          query ServiceAreaSelectorQuery($input: ID!)
          {  
            savedArea(id: $input) {
                id
                name
                typeOfArea
                counties {
                  edges {
                    node {
                      id
                      name
                      fips5
                      geojsonFeature
                      state {
                        stateAb
                      }
                    }
                  }
                }
                places {
                  edges {
                    node {
                      id
                      geoId
                      displayName
                      geojsonFeature
                    }
                  }
                }
                zipcodes {
                  edges {
                    node {
                      id
                      zip
                      zcta {
                        zcta
                        geojsonFeature
                      }
                    }
                  }
                }
            }
          }
      `,
        variables: { input: id },
      };
      try {
        this.setState({ isRefreshing: true });
        let response = await fetchApi("graphql", JSON.stringify(graphqlQuery));
        let savedArea = response.data.savedArea;
        this.setState({
          name: savedArea.name,
          areaType: savedArea.typeOfArea.toLowerCase(),
          selectedCounties: savedArea.counties.edges.map((edge) => {
            return {
              value: edge.node.fips5,
              label: edge.node.name + ", " + edge.node.state.stateAb,
            };
          }),
          mapCounties: savedArea.counties.edges.map((edge) => {
            return edge.node.geojsonFeature;
          }),
          mapZipcodes: savedArea.zipcodes.edges.map((edge) => {
            return edge.node.zcta.geojsonFeature;
          }),
          mapPlaces: savedArea.places.edges.map((edge) => {
            return edge.node.geojsonFeature;
          }),
          selectedZipcodes: savedArea.zipcodes.edges.map((edge) => {
            return edge.node.zip;
          }),
          selectedPlaces: savedArea.places.edges.map((edge) => {
            return { value: edge.node.geoId, label: edge.node.displayName };
          }),
          isBtnDisabled: true,
          selectedAreaId: id,
        });
      } catch (err) {
        console.log("loadArea failed");
        console.log(err);
      } finally {
        this.setState({ isRefreshing: false, isBtnDisabled: true });
      }
    }
  };

  saveAreaForExploreAndMaps = () => {
    const {
      selectedAreaId,
      name,
      areaType,
      selectedCounties,
      selectedZipcodes,
      selectedPlaces,
    } = this.state;
    const mutation = graphql`
      mutation MySavedAreasPopupCreateMutation(
        $input: CreateOrUpdateServiceAreaInput!
      ) {
        createOrUpdateServiceArea(input: $input) {
          savedAreaNode {
            id
            name
          }
        }
      }
    `;

    const input = {
      savedAreaId: selectedAreaId,
      name: name,
      counties: selectedCounties.map((item) => {
        return item.value;
      }),
      zipcodes: selectedZipcodes,
      places: selectedPlaces.map((item) => {
        return item.value;
      }),
      typeOfArea: areaType,
      crosswalk: false,
      benchmarks: true,
    };

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        // check if you are getting error messages then reload the component
        if (response.createOrUpdateServiceArea.savedAreaNode == null) {
          this.setState((prevState) => {
            return {
              isSaving: false,
              wasError: true,
              wasSaved: false,
              wasWarning: false,
              errorMessage: "Error Saving Area",
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              isSaving: false,
              wasSaved: true,
              wasError: false,
              wasWarning: false,
            };
          });
          window.location.assign(
            "/map/" + response.createOrUpdateServiceArea.savedAreaNode.id
          );
        }
      },
      onError: (err) => {
        console.error(err);
        this.setState((prevState) => {
          return {
            isSaving: false,
            wasError: true,
            wasSaved: false,
            wasWarning: false,
            errorMessage: "Failed to Save Area",
          };
        });
        // TODO: Show error message
      },
    });
  };

  saveAreaForReport = () => {
    const { match, onClose } = this.props;
    const { selectedAreaId } = this.state;
    const mutation = graphql`
      mutation MySavedAreasPopupMutation($input: CreateIndicatorReportInput!) {
        createIndicatorReport(input: $input) {
          boardNode {
            id
            name
          }
        }
      }
    `;
    const input = {
      savedAreaId: selectedAreaId,
      reportType: match.params.filterTag,
    };

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        // check if you are getting error messages then reload the component
        if (response.createIndicatorReport.boardNode == null) {
          this.setState((prevState) => {
            return {
              isSaving: false,
              wasError: true,
              wasSaved: false,
              wasWarning: false,
              errorMessage: "Error Creating Report",
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              isSaving: false,
              wasSaved: true,
              wasError: false,
              wasWarning: false,
            };
          });
          // close area and go to report
          onClose(response.createIndicatorReport.boardNode.id);
        }
      },
      onError: (err) => {
        console.error(err);
        this.setState((prevState) => {
          return {
            isSaving: false,
            wasError: true,
            wasSaved: false,
            wasWarning: false,
            errorMessage: "Failed to Create Report",
          };
        });
        // TODO: Show error message
      },
    });
  };

  saveArea = () => {
    const { location } = this.props;
    const { isSaving } = this.state;
    if (isSaving) {
      return;
    }
    this.setState({
      isSaving: true,
      wasSaved: false,
      wasError: false,
      wasWarning: false,
    });
    if (
      this.props &&
      location &&
      location.state &&
      location.state.popupType === "EXPLOREMAPS"
    ) {
      this.saveAreaForExploreAndMaps();
    } else {
      this.saveAreaForReport();
    }
  };

  render() {
    const {
      wasSaved,
      isBtnDisabled,
      isSaving,
      toolTipX,
      toolTipY,
      mapZipcodes,
      mapCounties,
      mapPlaces,
      areaType,
      isToolTipVisible,
    } = this.state;
    const { userData, showLogin, location, history, match, handleLoginPopUp } =
      this.props;
    if (userData == null) {
      return (
        <WideModalPopup
          goBack={this.goBack}
          className="MySavedAreasPopup"
          closePopUp={this.closePopUp}
        >
          <div className="MySavedAreasPopup-top-break" />
          <div className="MySavedAreasPopup-login">
            <img
              src={require("../../images/areaselector/download.png")}
              alt="Mappy the Community Map"
              title="Mappy the Community Map"
            />
            Please{" "}
            <span className="MySavedAreasPopup-login-link" onClick={showLogin}>
              Log In or Sign Up
            </span>{" "}
            to create indicator reports.
          </div>
        </WideModalPopup>
      );
    }
    return (
      <WideModalPopup
        goBack={this.goBack}
        className="MySavedAreasPopup"
        closePopUp={this.closePopUp}
      >
        <ToolTip
          x={toolTipX}
          y={toolTipY}
          isVisible={isToolTipVisible}
          text={toolTipMapText}
        />
        <div className="MySavedAreasPopup-top-break" />
        <div className="mainDiv row m-0">
          <div className="col-sm-6 col-lg-4 col-12">
            <BroadStreetServiceAreaMap
              isEditingArea={true}
              zipcodes={mapZipcodes}
              counties={mapCounties}
              places={mapPlaces}
              typeOfArea={areaType}
            />
            <p className="MySavedAreasPopup-map-note">
              Preview of area by county, ZIP code, and place&nbsp;
              <img
                src={infoIconImg}
                width={10}
                height={10}
                onMouseOver={this.showToolTipMap}
                onMouseOut={this.hideToolTip}
                ref="maptip"
                alt="info"
              />
            </p>
          </div>
          <div className="col-sm-6 col-lg-8 col-12 MySavedAreasPopup-form-parent">
            <div className="MySavedAreasPopup-title">
              <h1>My Saved Areas</h1>
              <ButtonComponent
                buttonTitle={isSaving ? "WORKING..." : "NEXT"}
                className="save-btn"
                buttonType="subtle"
                disabled={!isBtnDisabled || wasSaved}
                buttonState={wasSaved ? "saved" : ""}
                isLarge={true}
                onClick={wasSaved || !isBtnDisabled ? null : this.saveArea}
              />
            </div>
            <div className="MySavedAreasPopup-title-break" />
            <SelectAreaPopup
              isVisible={true}
              openCloseServiceSelector={this.openCloseServiceSelector}
              x={0}
              y={0}
              userData={userData}
              location={location}
              history={history}
              match={match}
              showLogin={handleLoginPopUp}
              fromSavedAreaPopup={true}
              handleSelectedArea={this.handleSelectedArea}
              loadArea={this.loadArea}
            />
          </div>
        </div>
      </WideModalPopup>
    );
  }
}

export default MySavedAreasPopup;
