import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import LunchChart from './LunchChartCard';

class D3LunchWideChart extends Component {
  render() {
    const { id, lunchChartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'lunchWideChart' : 'lunchTwitterChart');
    const content = (
      <div className='D3LunchWideChart d-flex w-100 h-100'>
        <LunchChart
          id={wideChartId}
          key={wideChartId}
          lunchData={lunchChartData}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3LunchWideChart;
