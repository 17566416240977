/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WideDotMapCardContainerQueryVariables = {|
  input: string
|};
export type WideDotMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +configuration: {|
      +savedArea: ?{|
        +counties: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fips5: string,
              +geojsonFeature: string,
            |}
          |}>
        |},
        +extent: ?$ReadOnlyArray<?number>,
        +jsonid: ?string,
      |}
    |},
    +subComponentType: ?string,
  |}
|};
export type WideDotMapCardContainerQuery = {|
  variables: WideDotMapCardContainerQueryVariables,
  response: WideDotMapCardContainerQueryResponse,
|};
*/


/*
query WideDotMapCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    configuration {
      savedArea {
        counties {
          edges {
            node {
              id
              fips5
              geojsonFeature
            }
          }
        }
        extent
        jsonid
        id
      }
      id
    }
    subComponentType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "WizardCountyNodeConnection",
  "kind": "LinkedField",
  "name": "counties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WizardCountyNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WizardCountyNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fips5",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geojsonFeature",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extent",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WideDotMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WideDotMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "WideDotMapCardContainerQuery",
    "operationKind": "query",
    "text": "query WideDotMapCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    configuration {\n      savedArea {\n        counties {\n          edges {\n            node {\n              id\n              fips5\n              geojsonFeature\n            }\n          }\n        }\n        extent\n        jsonid\n        id\n      }\n      id\n    }\n    subComponentType\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65b5f7f2266b229cc84afc1752d84d4a';

module.exports = node;
