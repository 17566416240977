/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WideCardContainerQueryVariables = {|
  input: string
|};
export type WideCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +additionalConfiguration: ?{|
      +jsonProperties: string
    |},
    +chartImage: ?string,
    +configuration: {|
      +author: ?{|
        +name: ?string
      |},
      +citation: ?string,
      +endYear: ?number,
      +internalOrderList: string,
      +jsonStyleProperties: string,
      +organization: ?{|
        +id: string,
        +organizationName: string,
      |},
      +permaLink: ?string,
      +report: ?{|
        +id: string,
        +jsonid: ?string,
      |},
      +savedArea: ?{|
        +id: string,
        +jsonid: ?string,
        +name: string,
      |},
      +startYear: ?number,
      +storyLink: string,
      +storyPublishedDate: ?any,
      +storyUpdatedDate: ?any,
      +thumbnailImage: ?{|
        +id: string,
        +url: ?string,
      |},
      +title: ?string,
      +toolLink: string,
    |},
    +cardPosition: number,
    +frontFacingComponent: {|
      +componentType: string,
      +name: string,
    |},
    +subComponentType: ?string,
    +statistics: ?{|
      +name: string,
      +jsonData: string,
    |},
    +isFavorite: ?boolean,
    +sharingStatus: number,
    +tags: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardTag: {|
            +name: string,
            +permaTag: number,
          |}
        |}
      |}>
    |},
  |}
|};
export type WideCardContainerQuery = {|
  variables: WideCardContainerQueryVariables,
  response: WideCardContainerQueryResponse,
|};
*/


/*
query WideCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    additionalConfiguration {
      jsonProperties
      id
    }
    chartImage
    configuration {
      author {
        name
        id
      }
      citation
      endYear
      internalOrderList
      jsonStyleProperties
      organization {
        id
        organizationName
      }
      permaLink
      report {
        id
        jsonid
      }
      savedArea {
        id
        jsonid
        name
      }
      startYear
      storyLink
      storyPublishedDate
      storyUpdatedDate
      thumbnailImage {
        id
        url
      }
      title
      toolLink
      id
    }
    cardPosition
    frontFacingComponent {
      componentType
      name
      id
    }
    subComponentType
    statistics {
      name
      jsonData
      id
    }
    isFavorite
    sharingStatus
    tags {
      edges {
        node {
          boardTag {
            name
            permaTag
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonProperties",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartImage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "citation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalOrderList",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationNode",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permaLink",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "WizardReportNode",
  "kind": "LinkedField",
  "name": "report",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "SavedAreaNode",
  "kind": "LinkedField",
  "name": "savedArea",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v12/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyLink",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyPublishedDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storyUpdatedDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardImageNode",
  "kind": "LinkedField",
  "name": "thumbnailImage",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toolLink",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonData",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sharingStatus",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permaTag",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WideCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardAdditionalConfigurationNode",
            "kind": "LinkedField",
            "name": "additionalConfiguration",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNode",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v23/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardStatisticsNode",
            "kind": "LinkedField",
            "name": "statistics",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v25/*: any*/)
            ],
            "storageKey": null
          },
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardTagUseNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTagUseNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardTagUseNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardTagNode",
                        "kind": "LinkedField",
                        "name": "boardTag",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v28/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WideCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardAdditionalConfigurationNode",
            "kind": "LinkedField",
            "name": "additionalConfiguration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNode",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FrontFacingComponentNode",
            "kind": "LinkedField",
            "name": "frontFacingComponent",
            "plural": false,
            "selections": [
              (v23/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardStatisticsNode",
            "kind": "LinkedField",
            "name": "statistics",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v25/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardTagUseNodeConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTagUseNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardTagUseNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardTagNode",
                        "kind": "LinkedField",
                        "name": "boardTag",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v28/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "WideCardContainerQuery",
    "operationKind": "query",
    "text": "query WideCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    additionalConfiguration {\n      jsonProperties\n      id\n    }\n    chartImage\n    configuration {\n      author {\n        name\n        id\n      }\n      citation\n      endYear\n      internalOrderList\n      jsonStyleProperties\n      organization {\n        id\n        organizationName\n      }\n      permaLink\n      report {\n        id\n        jsonid\n      }\n      savedArea {\n        id\n        jsonid\n        name\n      }\n      startYear\n      storyLink\n      storyPublishedDate\n      storyUpdatedDate\n      thumbnailImage {\n        id\n        url\n      }\n      title\n      toolLink\n      id\n    }\n    cardPosition\n    frontFacingComponent {\n      componentType\n      name\n      id\n    }\n    subComponentType\n    statistics {\n      name\n      jsonData\n      id\n    }\n    isFavorite\n    sharingStatus\n    tags {\n      edges {\n        node {\n          boardTag {\n            name\n            permaTag\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d4f4bd538cb6aab2ad34e1c097d1fe8';

module.exports = node;
