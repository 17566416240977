import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";

class D3GaugeChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.angle = { min: -90, max: 90 };
    this.clientWidth = 217;
    this.clientHeight = 120;
    this.scale = d3.scaleLinear().range([0, 1]);
    this.state = {
      ratio: 1,
    };
  }

  componentDidUpdate() {
    this.update();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  init = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.createSvg();
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3GaugeChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");

    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
  };

  update = () => {
    const { min, max } = this.props;
    this.scale.domain([min, max]);
    this.autoAlignSVG();
    this.makeGauge();
    this.makeNeedle();
  };

  makeGauge = () => {
    const { gauge } = this.props;
    const imgGauge = this.svgG
      .selectAll("img.gauge-img")
      .data([{ src: gauge }], (d) => d.src);
    const imageForeignObject = imgGauge
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height);

    const imageEleDiv = imageForeignObject
      .append("xhtml:div")
      .attr("class", "lunch-img-div")
      .style("position", "relative");

    const newImageEle = imageEleDiv.append("img");
    newImageEle
      .merge(imgGauge)
      .attr("src", (d) => d.src)
      .attr("class", "gauge-img")
      .attr("width", this.width)
      .attr("height", this.height);
    imgGauge.exit().remove();
  };

  makeNeedle = () => {
    //const value = 75
    const { needle, value } = this.props;
    const height = this.height / 2 + 4;
    const ratio = this.scale(value);
    const newAngle = this.angle.min + ratio * (this.angle.max - this.angle.min);
    const translateX = 0;
    const img = this.svgG
      .selectAll("img.gauge-needle-img")
      .data([{ src: needle }], (d) => d.src);
    const imageForeignObject = img
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height);

    const imageEleDiv = imageForeignObject
      .append("xhtml:div")
      .attr("class", "lunch-img-div")
      .style("position", "relative");

    const newImageEle = imageEleDiv.append("img");
    newImageEle
      .merge(img)
      .attr("class", "gauge-needle-img")
      .attr("src", (d) => d.src)
      .attr("width", this.width)
      .attr("height", height)
      .style(
         "transform-origin",
         `50% 90%`)
      .style(
        "transform",
        `translate(${translateX}px, ${
        height * 0.58}px) rotate(${isNaN(newAngle) ? 0 : newAngle}deg)`
      );

    img.exit().remove();
  };

  render() {
    const { valueText, text, unit } = this.props;
    const { ratio } = this.state;
    const titleStyle = {
      fontSize: "50px",
      fontFamily: "ProximaNova-Bold",
      marginBottom: 0,
      width: "100%",
      color: "#424B54",
      lineHeight: 1,
    };
    const subTitleStyle = {
      fontSize: "18px",
      fontFamily: "Proxima Nova, Light",
      width: "100%",
      marginBottom: 0,
      lineHeight: 1,
      color: "#424B54",
    };
    let value = valueText;
    if (value && value.indexOf("%") > -1) {
      value = value.replace("%", "");
    }
    value =
      !isNaN(parseFloat(value)) && unit === "%"
        ? (parseFloat(value) < 1
            ? _.round(parseFloat(value), 1)
            : _.round(parseFloat(value))) + "%"
        : valueText;
    return (
      <div
        ref={(ele) => (this.mainEle = ele)}
        className="D3GaugeChart h-100 w-100 d-flex justify-content-center align-items-center"
      >
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="d-flex justify-content-center align-items-center mx-0">
            <div
              className="d-flex justify-content-center align-items-center"
              ref={(ele) => (this.parentEle = ele)}
            />
          </div>
          <div className="d-flex mx-0 text-center">
            <div style={titleStyle}>{value}</div>
          </div>
          <div className="d-flex mt-2 mb-3 text-center">
            <p style={subTitleStyle}>{text}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default D3GaugeChart;
