import React from "react";
import "../../css/components/CommonComponents/SaveCardsPopUp.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { AnnouncementPopup } from "common-components";

const RemoveCardPopUp = (props) => {
  const { isVisible, hidePopUp, handleRenderOnRemove, cardId } = props;

  const handleRemove = () => {
    if (true) {
      const variables = {
        input: { boardCardUseId: cardId },
      };
      const mutation = graphql`
        mutation RemoveCardPopUpMutation($input: DeleteCardFromBoardInput!) {
          deleteCardFromBoard(input: $input) {
            cardDeleted
            boardSection {
              id
              layout
            }
          }
        }
      `;
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          const layout = JSON.parse(
            response.deleteCardFromBoard.boardSection.layout
          );
          if (handleRenderOnRemove && layout) {
            handleRenderOnRemove(layout);
          }
          hidePopUp(false);
        },
        onError: (err) => console.error(JSON.stringify(err)),
      });
    }
  };

  return (
    <div>
      {isVisible ? (
        <AnnouncementPopup
          closePopUp={hidePopUp}
          onOkClick={handleRemove}
          announcementMessage={
            "This will permanently remove <br/><br/> this card for you and your collaborators."
          }
          announcementPrompt={"Are you sure?"}
          okButtonLabel={"FINISH"}
          closeButtonLabel={"Cancel"}
          title={"REMOVE CARD"}
        />
      ) : null}
    </div>
  );
};

export default RemoveCardPopUp;
