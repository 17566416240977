import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3CheckboxCard from './D3CheckboxCard';

class D3CheckboxWideCard extends Component {
  render() {
    const { checkboxCard, id, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'numberChildrenWideCard' : 'numberChildrenWideCardTwitter');
    const content = (
      <div className='D3CheckboxWideCard d-flex w-100 h-100'>
        <D3CheckboxCard id={wideChartId} key={wideChartId}
                      title={checkboxCard.title}
                      tooltip={checkboxCard.tooltip}
                      subTittle={checkboxCard.subTitle}
                      description={checkboxCard.description}
                      min={checkboxCard.min}
                      max={checkboxCard.max}
                      value={checkboxCard.value}
                      usTitle={checkboxCard.usTitle}
                      usValue={checkboxCard.usValue}
                      isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3CheckboxWideCard;
