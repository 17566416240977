/* /src/components/AdiMap.js
   Maps for BroadStreet Area Deprivation Index */
/* Adapted from work we did for the
     BroadStreet Community Health Dashboard v0.16.5 -
     exporting service area map for a CHNA report */
/* To use, pass three arrays of GeoJSON feature strings like this:
   <AdiMap blockgroups={this.state.blockGroupGeoJsonArray}
     counties={this.state.countyGeoJsonArray} />

   GeoJSON feature strings come from our GraphQL API calls

   Mapbox GL mapping library must be loaded within the html page for the app
*/
import React from 'react';
import PropTypes from 'prop-types';
import '../../css/components/AdiMap/AdiMap.css';

/* Utility function to convert from array of Relay GraphQL nodes
   returned from a query in an edges property
   into array of embedded GeoJSON features strings.
   Also works with flat array of GeoJSON feature strings */
function extractGeoJSONfeaturesFromEdges(edges) {
  if (edges != null && edges[0] != null) {
    if (edges && edges[0].node) {
      // parse GeoJSON property within each node object
      if (edges[0].node.geojsonWithProperties) {
        edges = edges.map(x => JSON.parse(x.node.geojsonWithProperties));
      }
      else {
        edges = edges.map(x => JSON.parse(x.node.geojsonFeature));
      }
    }
    else if (edges && edges[0]) {
      // handle a flat array of GeoJSON features as strings also
      edges = edges.map(x => JSON.parse(x));
    }
  }

  /* return original array if no embedded features found */
  return edges;
}

class AdiMap extends React.Component {
  constructor(props) {
    super(props);

    this.blockgroups = [];
    this.zipcodes = [];
    this.places = [];
    this.counties = [];
    this.map = null;
    this.renderMap = this.renderMap.bind(this);
  }

  componentDidUpdate() {
    /* called after a new render (e.g. parent updated data passed to props) */
    //this.renderMap();
  }

  componentDidMount() {
    /* called after component is first rendered to the DOM */
    const {blockgroups, counties} = this.props;
    this.blockgroups = extractGeoJSONfeaturesFromEdges(blockgroups);
    this.counties = extractGeoJSONfeaturesFromEdges(counties);
    this.renderMap();
  }

  componentWillUnmount() {
    if (this.map != null) {
      this.map.remove();
    }
  }

  render() {
    const {id} = this.props;
    return (
      <div id={id} className="adiMap">
      </div>
    );
  }

  renderMap() {
    const mapboxgl = window.mapboxgl;
    const {id} = this.props;
    if (mapboxgl) {
      if (this.map == null) {
        this.map = new mapboxgl.Map({
          container: id,
          style: 'https://api.maptiler.com/maps/d311b63e-9caf-4f91-8ebb-f675244e474f/style.json?key=92mrAH6NBXO3jvPRMmT9',
          //style: 'https://api.maptiler.com/maps/positron/style.json?key=92mrAH6NBXO3jvPRMmT9',
          center: [-98.585522, 39.8333333],
          zoom: 9,
          preserveDrawingBuffer: true
        });
        this.map.on('load', this.addShapes);
      } else {
        if (this.map.loaded()) {
          this.addShapes();
        }
        else {
          this.map.on('render', this.addShapesAfterNextFrame);
        }
      }
    }
  }

  addShapesAfterNextFrame = () => {
    this.map.off('render', this.addShapesAfterNextFrame);
    this.addShapes();
  };

  addShapes = () => {
    const mapboxgl = window.mapboxgl;
    const map = this.map;
    const bounds = new mapboxgl.LngLatBounds();
    //var colors_def = ["#018EAA", '#5A5A5A', '#8CB369'];
    const colors_def = [
      '#D3D3D3',
      '#78A22F',
      '#6A8D73',
      '#D5DED6',
      '#FFA987',
      '#FF6B35'
    ];
    const colors_def_transparent = [
      'rgba(211, 211, 211, 0.4)',
      'rgba(120, 162, 47, 0.4)',
      'rgba(106, 141, 115, 0.4)',
      'rgba(213, 222, 214, 0.4)',
      'rgba(255, 169, 135, 0.4)',
      'rgba(255, 107, 53, 0.4)'
    ];
    let paths = [];

    // render CBG shapes on map
    if (!this.blockgroups) {
      this.blockgroups = [];
    }

    // initialize layers for CBG, counties
    if (map.getSource('blockgroups') == null) {
      map.addSource('blockgroups', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        },
        /* NOTE: Setting tolerance too high discards small complex shapes completely */
        'tolerance': 0.05
      });

      map.addLayer({
        'id': 'blockgroups',
        'type': 'fill',
        'source': 'blockgroups',
        'layout': {},
        'paint': {
          /*
          NOTE: fill-outline-color is outline single-pixel line only, does not scale properly
          'fill-outline-color':
          ,*/
          'fill-color': [
            'match',
            ['get', 'quintile'],
            '1', colors_def_transparent[1],
            '2', colors_def_transparent[2],
            '3', colors_def_transparent[3],
            '4', colors_def_transparent[4],
            '5', colors_def_transparent[5],
            /* other */ colors_def_transparent[0]
          ]
        }
      }, 'highway_name_other');
      /*
                  map.addLayer({
                      "id": "blockgroups_circles",
                      "type": "circle",
                      "source": "c",
                      'layout': {},
                      'paint': {
                          'circle-radius':
                              [
                                  'match',
                                  ['get', 'quintile'],
                                  '1', 1.75,
                                  '2', 3,
                                  '3', 5,
                                  '4', 7,
                                  '5', 9,
                                  1
                              ],
                          'circle-color': [
                              'match',
                              ['get', 'quintile'],
                              '1', colors_def_transparent[1],
                              '2', colors_def_transparent[2],
                              '3', colors_def_transparent[3],
                              '4', colors_def_transparent[4],
                              '5', colors_def_transparent[5],
                              colors_def_transparent[0]
                          ]
                      }
                  }, "highway_name_other");*/
      map.addLayer({
        'id': 'blockgroups_outline',
        'type': 'line',
        'source': 'blockgroups',
        'layout': {},
        'paint': {
          'line-color': [
            'match',
            ['get', 'quintile'],
            '1', colors_def[1],
            '2', colors_def[2],
            '3', colors_def[3],
            '4', colors_def[4],
            '5', colors_def[5],
            colors_def[0]
          ],
          'line-width': 1
        }
      }, 'highway_name_other');
    }
    const cbgFeatureCollection = {
      'type': 'FeatureCollection',
      'features': []
    };
    if (map.getSource('counties') == null) {
      map.addSource('counties', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        },
        'tolerance': 0.2
      });
      map.addLayer({
        'id': 'counties',
        'type': 'line',
        'source': 'counties',
        'layout': {},
        'paint': {
          'line-color': 'rgba(90, 90, 90, 1)',
          'line-width': 2
        }
      }, 'highway_name_other');
    }
    const countiesFeatureCollection = {
      'type': 'FeatureCollection',
      'features': []
    };
    let coordinates, path_m, i, k;
    this.blockgroups.forEach(function(object, index) {
      cbgFeatureCollection.features.push(object);
      if (object.geometry.type === 'MultiPolygon') {
        // process each shape in a MultiPolygon feature separately
        for (k = 0; k < object.geometry.coordinates.length; k++) {
          path_m = object.geometry.coordinates[k];
          for (var l = 0; l < path_m.length; l++) {
            const path = path_m[l];
            coordinates = [];
            for (i = 0; i < path.length; i++) {
              coordinates.push({lat: parseFloat(path[i][1]), lon: parseFloat(path[i][0])});
              if (i !== 0) {
                bounds.extend(new mapboxgl.LngLat(parseFloat(path[i][0]), parseFloat(path[i][1])));
              }
            }
            paths.push(coordinates);
          }
        }
      } else {
        // regular Polygon features contain only one shape
        coordinates = [];
        for (k = 0; k < object.geometry.coordinates.length; k++) {
          path_m = object.geometry.coordinates[k];
          for (i = 0; i < path_m.length; i++) {
            coordinates.push({lat: parseFloat(path_m[i][1]), lon: parseFloat(path_m[i][0])});
            if (i !== 0) {
              bounds.extend(new mapboxgl.LngLat(parseFloat(path_m[i][0]), parseFloat(path_m[i][1])));
            }
          }
        }
        paths.push(coordinates);
      }
    });
    const cbgSource = map.getSource('blockgroups');
    cbgSource.setData(cbgFeatureCollection);

    // render county shapes on map
    if (!this.counties) {
      this.counties = [];
    }
    this.counties.forEach(function(object) {
      countiesFeatureCollection.features.push(object);
    });
    const countiesSource = map.getSource('counties');
    countiesSource.setData(countiesFeatureCollection);

    // zoom and center map around bounds of rendered shapes
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  };
}

AdiMap.propTypes = {
  blockgroups: PropTypes.array,
  counties: PropTypes.array
};
export default AdiMap;
