/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddBoardCollaboratorInput = {|
  boardId: string,
  boardAuthorId?: ?string,
  email?: ?string,
  clientMutationId?: ?string,
|};
export type ProjectBoardGMutationVariables = {|
  input: AddBoardCollaboratorInput
|};
export type ProjectBoardGMutationResponse = {|
  +addBoardCollaborator: ?{|
    +isDuplicate: ?boolean,
    +isInvitation: ?boolean,
    +boardNode: ?{|
      +id: string,
      +collaborators: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +userAccount: ?{|
              +email: ?string
            |},
            +profileImage: ?{|
              +id: string,
              +caption: ?string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +invitations: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +email: string
          |}
        |}>
      |},
    |},
  |}
|};
export type ProjectBoardGMutation = {|
  variables: ProjectBoardGMutationVariables,
  response: ProjectBoardGMutationResponse,
|};
*/


/*
mutation ProjectBoardGMutation(
  $input: AddBoardCollaboratorInput!
) {
  addBoardCollaborator(input: $input) {
    isDuplicate
    isInvitation
    boardNode {
      id
      collaborators {
        edges {
          node {
            id
            name
            userAccount {
              email
              id
            }
            profileImage {
              id
              caption
              url
            }
          }
        }
      }
      invitations {
        edges {
          node {
            email
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddBoardCollaboratorInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDuplicate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInvitation",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardImageNode",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBoardGMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBoardCollaboratorPayload",
        "kind": "LinkedField",
        "name": "addBoardCollaborator",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNodeConnection",
                "kind": "LinkedField",
                "name": "collaborators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardAuthorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardAuthorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserAccountNode",
                            "kind": "LinkedField",
                            "name": "userAccount",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollaboratorInvitationNodeConnection",
                "kind": "LinkedField",
                "name": "invitations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollaboratorInvitationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollaboratorInvitationNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBoardGMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBoardCollaboratorPayload",
        "kind": "LinkedField",
        "name": "addBoardCollaborator",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNodeConnection",
                "kind": "LinkedField",
                "name": "collaborators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardAuthorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardAuthorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserAccountNode",
                            "kind": "LinkedField",
                            "name": "userAccount",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollaboratorInvitationNodeConnection",
                "kind": "LinkedField",
                "name": "invitations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollaboratorInvitationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollaboratorInvitationNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectBoardGMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectBoardGMutation(\n  $input: AddBoardCollaboratorInput!\n) {\n  addBoardCollaborator(input: $input) {\n    isDuplicate\n    isInvitation\n    boardNode {\n      id\n      collaborators {\n        edges {\n          node {\n            id\n            name\n            userAccount {\n              email\n              id\n            }\n            profileImage {\n              id\n              caption\n              url\n            }\n          }\n        }\n      }\n      invitations {\n        edges {\n          node {\n            email\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ab0ca13cbbb90a86ef35bfb9f40909b';

module.exports = node;
