import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3TextLayoutMotherChart from './D3TextLayoutMotherChart';

class D3TextLayoutMotherWideChart extends Component {
  render() {
    const { id, chartData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3TextLayoutMotherWideChart' : 'D3TextLayoutMotherTwitterChart');
    const content = (
      <div className='D3TextLayoutMotherWideChart d-flex w-100 h-100'>
        <D3TextLayoutMotherChart
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3TextLayoutMotherWideChart;
