import React, { Component } from "react";
import * as d3 from "d3";

class D3VerticalBar extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 2.5, right: 0, bottom: 2.5, left: 0 };
    this.duration = 1000;
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (nextProps.data !== data) {
      this.update();
    }
  }

  componentDidUpdate() {
    this.update();
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { id } = this.props;
    this.x = d3.scaleBand();
    this.y = d3.scaleLinear();
    this.color = d3
      .scaleOrdinal()
      .domain([1, 2, 3, 4, 5])
      .range(["#78A22F", "#6A8D73", "#D5DED6", "#FFA987", "#FF6B35"]);
    if (id) {
      this.createSvg();
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3VerticalChartSVG")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);
    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "g-container");

    const bars = this.svgG.selectAll(".bars").data(
      (d) => [d],
      (d) => d
    );
    bars
      .enter()
      .append("g")
      .merge(bars)
      .attr("class", "bars")
      .attr("id", (d) => d + "bars");
    bars.exit().remove();
    this.bars = d3.select("g#" + svgId + "bars");

    const xAxis = this.svgG.selectAll(".x.axis").data(
      (d) => [d],
      (d) => d
    );
    xAxis
      .enter()
      .append("g")
      .merge(xAxis)
      .attr("class", "x axis")
      .attr("id", (d) => d + "xAxis")
      .style("display", "none");
    xAxis.exit().remove();
    this.xAxis = d3.select("g#" + svgId + "xAxis");

    const yAxis = this.svgG.selectAll(".y.axis").data(
      (d) => [d],
      (d) => d
    );
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("class", "y axis")
      .attr("id", (d) => d + "yAxis")
      .style("display", "none");
    yAxis.exit().remove();
    this.yAxis = d3.select("g#" + svgId + "yAxis");

    this.autoAlignSVG();
  };

  autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    this.width =
      this.parentEle.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.parentEle.clientHeight - this.margin.top - this.margin.bottom;
    this.barHeight = this.height - this.margin.top;
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.xAxis.attr("transform", "translate(0, " + this.barHeight + ")");
    this.x.range([0, this.width]).padding(0.1);
    this.y.range([this.barHeight, 0]);
  };

  update = () => {
    const { data, name } = this.props;
    if (data.length) {
      this.x.domain(d3.range(data.length));
      this.y.domain([0, d3.max(data)]);
      this.xAxis.selectAll("text").remove();
      this.yAxis.selectAll("text").remove();
      this.xAxis
        .transition()
        .duration(this.duration)
        .call(d3.axisBottom(this.x));
      this.yAxis.transition().duration(this.duration).call(d3.axisLeft(this.y));

      const bars = this.bars.selectAll(".bar").data(data);
      bars
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("width", this.x.bandwidth())
        .attr("height", 0)
        .attr("y", this.barHeight)
        .style("fill", (d, i) => {
          if (name === "unitedstates") {
            if (i < 20) return this.color(1);
            if (i < 40) return this.color(2);
            if (i < 60) return this.color(3);
            if (i < 80) return this.color(4);
            if (i < 100) return this.color(5);
          } else {
            if (i < 21) return this.color(1);
            if (i < 41) return this.color(2);
            if (i < 61) return this.color(3);
            if (i < 81) return this.color(4);
            if (i < 101) return this.color(5);
          }
        })
        .merge(bars)
        .attr("x", (d, i) => this.x(i))
        .attr("width", this.x.bandwidth())
        .transition()
        .duration(this.duration)
        .attr("height", (d) => this.barHeight - this.y(d))
        .attr("y", (d) => this.y(d));
      bars
        .exit()
        .transition()
        .duration(this.duration)
        .attr("height", 0)
        .attr("y", this.barHeight)
        .remove();
    }
  };

  render() {
    return (
      <div
        className="D3VerticalBar h-100 w-100"
        ref={(ele) => (this.parentEle = ele)}
      />
    );
  }
}

export default D3VerticalBar;
