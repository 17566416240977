import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";

class D3BarChart extends Component {
  resizeStyle = {
    xyTickSize: 10,
    legend: { width: 15, height: 6, radius: 12, text: 10 },
    tooltipText: 10,
  };
  data = [];
  svgId;
  selectedStyle;
  svgText;
  svg;
  xAxis;
  legendHeight = 40;
  legendTopMargin = 20;
  xAxisTopMargin = 10;
  yAxis;
  barChartG;
  x0 = d3.scaleBand().paddingInner(0.15);
  y = d3.scaleLinear().domain([0, 100]).nice();
  x1 = d3.scaleBand().paddingInner(0.15);
  color = d3.scaleOrdinal();

  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 15, bottom: 10, left: 15 };
    this.axisMargin = { bottom: 20, left: 25 };
    this.clientWidth = 215;
    this.clientHeight = 215;
    this.selectedStyle = this.resizeStyle;
  }

  componentDidMount() {
    this.init();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentWillReceiveProps(newProps) {
    const { dualVerticalBarChartData } = this.props;
    if (
      !_.isEqual(newProps.dualVerticalBarChartData, dualVerticalBarChartData)
    ) {
      this.autoAlignChart(newProps);
    }
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.autoAlignChart(this.props);
  };

  init = () => {
    this.createChart();
    window.addEventListener("resize", this.onWindowResize);
  };

  setChartData = (dualVerticalBarChartData) => {
    let data = [];
    if (dualVerticalBarChartData.data) {
      data = dualVerticalBarChartData.data.map((d) => {
        d.newname = d.name + " " + d.name2;
        return d;
      });
    }
    return data;
  };

  styleOnResize = (isWide, newProps) => {
    const { dualVerticalBarChartData } = newProps;
    const barTypes = dualVerticalBarChartData.barTypes;
    this.svgText
      .style("font-family", "ProximaNova-Regular")
      .text("100% 9999")
      .call(function (t) {
        t.each(function (d) {
          // for each one
          const self = d3.select(this);
          const s = self.text().split(" "); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "0")
            .attr("dy", (d, i) => {
              return i === 0 ? "0.5em" : "1em";
            })
            .text((d) => d);
          sSelection.exit().remove();
        });
      });
    this.fontSize = Math.min(this.width, this.height) / 17;
    //todo: Remove or comment below line to scale font size greater than 25px
    this.fontSize = this.fontSize > 25 ? 25 : this.fontSize;
    this.svgText.attr("font-size", this.fontSize);

    const bbox = this.svgText.node().getBBox();
    this.axisMargin.bottom = bbox.height;
    this.legendHeight =
      (bbox.height / 2) * barTypes.length + 5 * barTypes.length;
    this.axisMargin.left = bbox.width + 5;
    this.resizeStyle.tooltipText = this.fontSize;
    this.resizeStyle.legend.text = this.fontSize;
    this.resizeStyle.legend.height = this.fontSize * 0.5;
    this.resizeStyle.legend.width = this.fontSize * 1.2;
    this.resizeStyle.legend.radius = this.fontSize / 4;
    this.resizeStyle.xyTickSize = this.fontSize;
    this.xAxisTopMargin = this.fontSize;
    this.legendTopMargin = this.fontSize;
    this.selectedStyle = this.resizeStyle;
    this.margin = { top: bbox.height / 4 + 5, right: 5, bottom: 0, left: 5 };
  };
  createChart = () => {
    const { id } = this.props;
    this.svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([this.svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3VerticalBarChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + this.svgId);
    this.svgText = this.svg
      .append("text")
      .attr("class", "random")
      .attr("y", -5000)
      .attr("x", -5000);
    const selectionG = this.svg.selectAll("g.g").data([this.svgId], (d) => d);

    selectionG
      .enter()
      .append("g")
      .merge(selectionG)
      .attr("class", (d) => "g")
      .attr("id", (d) => "g" + d);
    selectionG.exit().remove();
    this.svgG = d3.select(this.parentEle).select(`g#g${this.svgId}`);
    const selectionLegendG = this.svg
      .selectAll("g.svgLegendG")
      .data([this.svgId], (d) => d);

    selectionLegendG
      .enter()
      .append("g")
      .merge(selectionLegendG)
      .attr("class", (d) => "svgLegendG")
      .attr("id", (d) => "svgLegendG" + d);
    selectionLegendG.exit().remove();
    this.svgG = d3.select(this.parentEle).select(`g#g${this.svgId}`);
    this.svgLegendG = d3
      .select(this.parentEle)
      .select(`g#svgLegendG${this.svgId}`);
    this.barChartG = this.svgG.append("g").attr("id", "d3BarChart");
    this.xAxis = this.svgG.append("g");
    this.yAxis = this.svgG.append("g");

    const divTooltipSelection = d3
      .select("body")
      .selectAll("div.tooltip")
      .data([1]);
    divTooltipSelection.enter().append("div").attr("class", "tooltip");
    divTooltipSelection.exit();
    this.divTooltip = d3.select("body").select("div.tooltip");
    this.autoAlignChart(this.props);
  };

  autoAlignChart = (newProps) => {
    const { dualVerticalBarChartData, isWide } = newProps;
    this.divTooltip.style("display", "none");
    this.isWide = isWide;
    this.size =
      this.parentEle.clientWidth > this.parentEle.clientHeight
        ? this.parentEle.clientHeight
        : this.parentEle.clientWidth;
    this.width =
      (isWide ? this.size : this.clientWidth) -
      this.margin.left -
      this.margin.right;
    this.height =
      (isWide ? this.size : this.clientHeight) -
      this.margin.top -
      this.margin.bottom;
    this.styleOnResize(isWide, newProps);
    this.height =
      (isWide ? this.size : this.clientHeight) -
      this.margin.top -
      this.margin.bottom -
      this.legendTopMargin -
      this.xAxisTopMargin;
    this.data = this.setChartData(dualVerticalBarChartData);
    this.column = [];
    if (dualVerticalBarChartData.data.length > 0) {
      Object.keys(dualVerticalBarChartData.data[0]).forEach((val) => {
        if (typeof this.data[0][val] === "number") {
          this.column.push(val);
        }
      });
    }
    this.groupKey = "newname";

    this.x0
      .domain(this.data.map((d) => d[this.groupKey]))
      .rangeRound([this.axisMargin.left, this.width]);

    this.keys = this.column;
    this.x1.domain(this.keys).rangeRound([0, this.x0.bandwidth()]);
    this.y.rangeRound([
      this.height - this.axisMargin.bottom - this.legendHeight,
      0,
    ]);
    const color = dualVerticalBarChartData.barTypes.map((barType) => {
      return barType.color;
    });
    this.color.range(color);

    this.svg
      .style("width", this.width + this.margin.left + this.margin.right)
      .style(
        "height",
        this.height +
          this.margin.top +
          this.margin.bottom +
          this.legendTopMargin +
          this.xAxisTopMargin
      );
    this.svgG.attr(
      "transform",
      `translate(${this.margin.left},${this.margin.top})`
    );
    this.svgLegendG.attr(
      "transform",
      `translate(${this.margin.left + this.axisMargin.left},${
        this.margin.top +
        this.height +
        this.legendTopMargin +
        this.xAxisTopMargin -
        this.legendHeight
      })`
    );

    this.xAxis.attr(
      "transform",
      `translate(0,${
        this.height +
        this.xAxisTopMargin -
        this.axisMargin.bottom -
        this.legendHeight
      })`
    );
    this.yAxis.attr("transform", `translate(${this.axisMargin.left},0)`);

    this.createBars(newProps);
    this.createXAxis();
    this.createYAxis();
    this.createLegend(newProps);
  };
  createBars = (newProps) => {
    const { dualVerticalBarChartData } = newProps;
    const barTypes = dualVerticalBarChartData.barTypes;

    let selection = this.barChartG.selectAll("g").data(this.data);
    selection
      .enter()
      .append("g")
      .merge(selection)
      .attr("transform", (d) => `translate(${this.x0(d[this.groupKey])},0)`)
      .on("mouseover", () => {
        const mPos = d3.mouse(d3.select("body").node());
        this.divTooltip.style("left", mPos[0] + 10 + "px");
        this.divTooltip.style("top", mPos[1] - 15 + "px");
        this.divTooltip.style("display", "inline-block");
        this.divTooltip
          .style("opacity", "0.9")
          .style("background-color", "#FFFFFF")
          .style("padding", "2px")
          .style("color", "#424B54")
          .style("font-size", this.selectedStyle.tooltipText + "px")
          .style("font-family", "ProximaNova-Regular")
          .style("border", "1px solid #424B54");
        const elements = document.querySelectorAll(":hover");
        const l = elements.length - 2;
        const elementData = elements[l].__data__;
        const tooltip1 =
          barTypes[0] && barTypes[0].tooltip1 ? barTypes[0].tooltip1 : "";
        const tooltip2 =
          barTypes[0] && barTypes[0].tooltip2 ? barTypes[0].tooltip2 : "";
        const title = barTypes[0] && barTypes[0].title ? barTypes[0].title : "";
        const tooltip3 =
          barTypes[1] && barTypes[1].tooltip1 ? barTypes[1].tooltip1 : "";
        this.divTooltip.html(
          _.round(elementData.ourcommunity, 1).toFixed(1) +
            "% " +
            tooltip1 +
            "<br><b>" +
            title +
            "</b> " +
            tooltip2 +
            "<br>" +
            elementData.tooltip +
            "<br><br>" +
            _.round(elementData.unitedstates, 1).toFixed(1) +
            "% " +
            tooltip3
        );
      })
      .on("mouseout", () => {
        this.divTooltip.style("display", "none");
      });
    selection.exit().remove();
    selection = this.barChartG.selectAll("g").data(this.data);
    const selectionRect = selection
      .selectAll("rect.grey")
      .data((d) => this.keys.map((key) => ({ key, value: d[key] })));
    selectionRect
      .enter()
      .append("rect")
      .merge(selectionRect)
      .attr("class", "grey")
      .attr("x", (d) => this.x1(d.key))
      .attr("y", (d) => this.y(this.y.domain()[1]))
      .attr("width", this.x1.bandwidth())
      .attr("height", (d) => this.y(0) - this.y(this.y.domain()[1]))
      .attr("fill", "#e8e8e8");
    selectionRect.exit().remove();

    const selectionRectColor = selection
      .selectAll("rect.color")
      .data((d) => this.keys.map((key) => ({ key, value: d[key] })));
    selectionRectColor
      .enter()
      .append("rect")
      .merge(selectionRectColor)
      .attr("x", (d) => this.x1(d.key))
      .attr("class", "color")
      .attr("y", (d) => this.y(d.value))
      .attr("width", this.x1.bandwidth())
      .attr("height", (d) => this.y(0) - this.y(d.value))
      .attr("fill", (d) => {
        return this.color(d.key);
      });
    selectionRectColor.exit().remove();
  };

  createXAxis = () => {
    this.xAxis
      .call(d3.axisBottom(this.x0).tickSizeOuter(0))
      .selectAll(".tick text")
      .style("font-size", this.selectedStyle.xyTickSize)
      .style("fill", "#424B54")
      .style("font-family", "ProximaNova-Regular")
      .call(function (t) {
        t.each(function (d) {
          // for each one
          const self = d3.select(this);
          const s = self.text().split(" "); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "0")
            .attr("dy", (d, i) => {
              return i === 0 ? "0.5em" : "1em";
            })
            .text((d) => d);
          sSelection.exit().remove();
        });
      });
    this.xAxis.select(".domain").remove();
    this.xAxis.selectAll(".tick").selectAll("line").remove();
  };

  createYAxis = () => {
    this.yAxis
      .call(
        d3
          .axisLeft(this.y)
          .ticks(2)
          .tickFormat((d) => d + "%")
      )
      .style("color", "#424B54")
      .style("font-size", this.selectedStyle.xyTickSize)
      .style("font-family", "ProximaNova-Regular");
    this.yAxis
      .select(".tick:last-of-type text")
      .clone()
      .attr("x", 3)
      .attr("text-anchor", "start")
      .attr("font-weight", "bold");
    this.yAxis.select(".domain").remove();
  };
  createLegend = (newProps) => {
    const { dualVerticalBarChartData } = newProps;
    const barTypes = dualVerticalBarChartData.barTypes;

    let selection = this.svgLegendG.selectAll("g").data(barTypes);
    const newAdded = selection.enter().append("g");
    newAdded.append("rect");
    newAdded.append("text");
    const old = newAdded
      .merge(selection)
      .attr(
        "transform",
        (d, i) => `translate(0,${(i * this.legendHeight) / barTypes.length})`
      );

    old
      .select("text")
      .attr("x", (d) => this.selectedStyle.legend.width + 8)
      .attr("y", (d) => "0.72em")
      .text((d) => d.title)
      .style("font-size", this.selectedStyle.legend.text)
      .style("font-family", "ProximaNova-Regular")
      .style("fill", "#424b54");
    const self = this;
    old
      .select("rect")
      .attr("y", function () {
        const box = d3.select(this.parentNode).select("text").node().getBBox();
        return box.y + box.height / 2 - self.selectedStyle.legend.height / 2;
      })
      .attr("x", (d) => 0)
      .attr("fill", (d) => d.color)
      .attr("width", this.selectedStyle.legend.width)
      .attr("height", this.selectedStyle.legend.height)
      .attr("rx", this.selectedStyle.legend.radius)
      .attr("ry", this.selectedStyle.legend.radius);

    selection.exit().remove();
  };

  render() {
    const { isWide } = this.props;
    return (
      <div
        className="D3BarChart h-100 w-100"
        style={{ padding: isWide ? "" : "0 16px" }}
      >
        <div
          className="w-100 d-flex justify-content-center h-100"
          ref={(ele) => (this.parentEle = ele)}
        />
      </div>
    );
  }
}

export default D3BarChart;
