/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SavedAreaTypeOfArea = "COUNTYLIST" | "PLACELIST" | "ZIPLIST" | "%future added value";
export type WideServiceAreaMapCardContainerQueryVariables = {|
  input: string
|};
export type WideServiceAreaMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +mapImage: ?string,
    +statistics: ?{|
      +id: string,
      +jsonData: string,
    |},
    +configuration: {|
      +savedArea: ?{|
        +id: string,
        +name: string,
        +typeOfArea: SavedAreaTypeOfArea,
        +counties: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: string,
              +fips5: string,
              +geojsonFeature: string,
              +state: {|
                +stateAb: string
              |},
            |}
          |}>
        |},
        +places: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +geoId: string,
              +name: string,
              +geojsonFeature: string,
            |}
          |}>
        |},
        +zipcodes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +zip: string,
              +zcta: {|
                +zcta: string,
                +geojsonFeature: string,
              |},
            |}
          |}>
        |},
        +reportstatisticsSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +saZctaCount: number,
              +saGeo: string,
              +saStateList: string,
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type WideServiceAreaMapCardContainerQuery = {|
  variables: WideServiceAreaMapCardContainerQueryVariables,
  response: WideServiceAreaMapCardContainerQueryResponse,
|};
*/


/*
query WideServiceAreaMapCardContainerQuery(
  $input: ID!
) {
  boardCardUse(id: $input) {
    id
    mapImage
    statistics {
      id
      jsonData
    }
    configuration {
      savedArea {
        id
        name
        typeOfArea
        counties {
          edges {
            node {
              id
              name
              fips5
              geojsonFeature
              state {
                stateAb
                id
              }
            }
          }
        }
        places {
          edges {
            node {
              id
              geoId
              name
              geojsonFeature
            }
          }
        }
        zipcodes {
          edges {
            node {
              id
              zip
              zcta {
                zcta
                geojsonFeature
                id
              }
            }
          }
        }
        reportstatisticsSet {
          edges {
            node {
              id
              saZctaCount
              saGeo
              saStateList
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mapImage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardCardStatisticsNode",
  "kind": "LinkedField",
  "name": "statistics",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsonData",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfArea",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fips5",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "geojsonFeature",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stateAb",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UsPlaceNodeConnection",
  "kind": "LinkedField",
  "name": "places",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UsPlaceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UsPlaceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "geoId",
              "storageKey": null
            },
            (v5/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zcta",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ReportStatisticsNodeConnection",
  "kind": "LinkedField",
  "name": "reportstatisticsSet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportStatisticsNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportStatisticsNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saZctaCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saGeo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saStateList",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WideServiceAreaMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WizardCountyNodeConnection",
                    "kind": "LinkedField",
                    "name": "counties",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WizardCountyNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WizardCountyNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WizardStateNode",
                                "kind": "LinkedField",
                                "name": "state",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WizardZipCodeNodeConnection",
                    "kind": "LinkedField",
                    "name": "zipcodes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WizardZipCodeNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WizardZipCodeNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WizardZipCodeTabulationAreaNode",
                                "kind": "LinkedField",
                                "name": "zcta",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WideServiceAreaMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedAreaNode",
                "kind": "LinkedField",
                "name": "savedArea",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WizardCountyNodeConnection",
                    "kind": "LinkedField",
                    "name": "counties",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WizardCountyNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WizardCountyNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WizardStateNode",
                                "kind": "LinkedField",
                                "name": "state",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WizardZipCodeNodeConnection",
                    "kind": "LinkedField",
                    "name": "zipcodes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WizardZipCodeNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WizardZipCodeNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WizardZipCodeTabulationAreaNode",
                                "kind": "LinkedField",
                                "name": "zcta",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v8/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "WideServiceAreaMapCardContainerQuery",
    "operationKind": "query",
    "text": "query WideServiceAreaMapCardContainerQuery(\n  $input: ID!\n) {\n  boardCardUse(id: $input) {\n    id\n    mapImage\n    statistics {\n      id\n      jsonData\n    }\n    configuration {\n      savedArea {\n        id\n        name\n        typeOfArea\n        counties {\n          edges {\n            node {\n              id\n              name\n              fips5\n              geojsonFeature\n              state {\n                stateAb\n                id\n              }\n            }\n          }\n        }\n        places {\n          edges {\n            node {\n              id\n              geoId\n              name\n              geojsonFeature\n            }\n          }\n        }\n        zipcodes {\n          edges {\n            node {\n              id\n              zip\n              zcta {\n                zcta\n                geojsonFeature\n                id\n              }\n            }\n          }\n        }\n        reportstatisticsSet {\n          edges {\n            node {\n              id\n              saZctaCount\n              saGeo\n              saStateList\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49f673620bb27776d6aa1c3a15b9af15';

module.exports = node;
