import React, { useState, useEffect, useRef } from "react";
import "../../css/components/Card/D3TextLayoutBabyChart.css";
import * as d3 from "d3";
import FilledHeartImg from '../../images/iconFilledHeart.svg';
import HeartFilledImg from "../../images/iconHeartFilled.svg";

const D3HeartChart3 = (props) => {
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const clientWidth = 220;
  const clientHeight = 220;
  const textDetailsHeight = 60;
  const labelTextHeight = 35;
  const centerSpace = 30;
  const parentEle = useRef(null);
  let svg,
    svgG,
    width,
    height = null;
  const [ratio, setRatio] = useState(1);
  const [mounted, setMounted] = useState(true);
  const { isWide, chartData } = props;

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    setMounted(true);

    return () => {
      setMounted(false);
      window.removeEventListener("resize", onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData) {
      init(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const onWindowResize = () => {
    if (!mounted) return;
    init(props);

    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
  };

  const init = (props) => {
    createSvg(props);
  };

  const createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    svg = d3.select(parentEle.current).select("svg#" + id);
    svgG = d3.select("g#" + id + "-g-container");

    updateInfo(props);
    autoAlignSVG();
  };

  const autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
    //  Set the dimensions and margins of the diagram
    width = clientWidth - margin.left - margin.right;
    height = clientHeight - margin.top - margin.bottom;
    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right}
                    ${height + margin.top + margin.bottom}`
      );
    svgG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svgG
      .select("foreignObject")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svgG
      .select("foreignObject")
      .select("#parentHeartDivElement")
      .style("height", height + margin.top + margin.bottom + "px");
  };

  const updateInfo = (props) => {
    const { chartData } = props;
    if (chartData) {
      updateTextDetails(chartData);
      updateIconAndLabel(chartData, true);
      updateIconAndLabel(chartData, false);
    }
  };
  const updateTextDetails = (data) => {
    const textElement = svgG.select("foreignObject").select("#detailsText");
    const textDetailsHtml =
      "<span>" +
      data.label3 +
      " </span><span class='main-text fs-15'>" +
      data.label4 +
      " </span>";
    textElement.html(textDetailsHtml);
  };
  const updateIconAndLabel = (data, isHeart1) => {
    let value = isHeart1 ? data.value : data.value2;
    const arrayData = Array.from(new Array(Math.round(value)), (x, i) =>
      value >= i + 1 ? 1 : i + 1 - value
    );
    const parentActiveHeartElement = svgG
      .select("foreignObject")
      .select(isHeart1 ? "#heart1" : "#heart2");
    const allActiveHeartIcon = parentActiveHeartElement
      .select(".icons")
      .selectAll(".icon")
      .data(arrayData);
    allActiveHeartIcon
      .enter()
      .append("div")
      .merge(allActiveHeartIcon)
      .attr("class", "icon")
      .style("height", "21px")
      .style("margin", "1px")
      .style("width", "20px")
      .style('background-image', `url(${isHeart1 ? HeartFilledImg : FilledHeartImg})`)
      .style('background-repeat', 'round')
      .style('background-size', 'cover')
      .style('background-repeat', 'no-repeat');

    allActiveHeartIcon.exit().remove();
    const activeHeartLabelEle = parentActiveHeartElement.select(".labels");
    const activeHeartLabelHtml =
      "<span>" + (isHeart1 ? chartData.label : chartData.label2) + "</span>";
    activeHeartLabelEle.html(activeHeartLabelHtml);
    const newWidth =
      Math.round(value) / 5 > 2 ? Math.ceil(Math.round(value) / 5) * 30 : 60;
    parentActiveHeartElement.style("width", newWidth - 1 + "px");
  };

  const id = props.id.replace(/=/g, "");
  return (
    <div
      className="D3TextLayoutBabyChart d-flex flex-column h-100 w-100 justify-content-center align-items-center"
      ref={parentEle}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "245px",
          width: "245px",
          padding: "0 16px",
          transform: `scale(${ratio})`,
        }}
      >
        <svg id={id}>
          <g id={id + "-g-container"} className="g-container">
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div
                id="parentHeartDivElement"
                className="d-flex align-items-center justify-content-center flex-column w-100"
              >
                <div
                  className="d-flex flex-grow-1 align-items-center justify-content-center"
                  style={{
                    height: clientHeight - textDetailsHeight,
                  }}
                >
                  <div
                    id="heart1"
                    className="heart1 d-flex flex-grow-1 flex-column align-items-center justify-content-center"
                  >
                    <div
                      className="icons w-100 d-flex flex-wrap flex-column-reverse align-items-center"
                      style={{
                        height:
                          clientHeight - textDetailsHeight - labelTextHeight,
                      }}
                    />
                    <div
                      style={{
                        height: labelTextHeight,
                        fontSize: "12px",
                        fontFamily: "ProximaNova-Regular",
                        lineHeight: 1,
                      }}
                      className="labels w-100 text-center d-flex justify-content-center pt-1"
                    />
                  </div>
                  <div
                    className="d-flex flex-grow-1  h-100 w-100"
                    style={{ maxWidth: centerSpace }}
                  />
                  <div
                    id="heart2"
                    className="heart2 flex-grow-1 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div
                      className="icons w-100 d-flex flex-wrap flex-column-reverse align-items-center"
                      style={{
                        height:
                          clientHeight - textDetailsHeight - labelTextHeight,
                      }}
                    />
                    <div
                      className="pt-1 labels w-100 text-center d-flex justify-content-center"
                      style={{
                        height: labelTextHeight,
                        fontSize: "12px",
                        fontFamily: "ProximaNova-Regular",
                        lineHeight: 1,
                      }}
                    />
                  </div>
                </div>
                <div
                  id="detailsText"
                  className="details-text text-center w-100 align-items-center justify-content-center"
                  style={{ height: textDetailsHeight }}
                />
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default D3HeartChart3;
