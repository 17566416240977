import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';
import { commitMutation, QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import FooterComponent from '../CommonComponents/FooterComponent';
import PageHeaderSection from '../CommonComponents/PageHeaderSection';
import { reportToolCardArray } from '../../utility/utilities';
import WidthProvider from '../ProjectBoard/ProjectBoardWidthProvider';
import { Responsive } from 'react-grid-layout';
import ServiceAreaMapCardContainer from '../Card/ServiceAreaMapCardContainer';
import { ToolTip, AnnouncementPopup, SelectAreaPopup } from 'common-components';
import CardButtonsPopUp from '../CommonComponents/CardButtonsPopUp';
import WideCardPopup from '../CommonComponents/WideCardPopup';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

function AreasSection(props) {
  return (
    <div>
      {props.data.areasBoard.sections.edges.map((section, index) => {
        return (
          <div key={index}>
            {
              index === 0 ? null : <div className="recomm-wrapper">
                <div className="recomm-header-wrap">
                  <h2 className="recomm-hd-title">{section.name}</h2>
                </div>
              </div>
            }
            <AreasCards
              section={section}
              openStoryPopup={props.openStoryPopup}
              openStoryPopupWithUrl={props.openStoryPopupWithUrl}
              openCardFamilyPopupWithUrl={props.openCardFamilyPopupWithUrl}
              handleUpdateBoard={props.handleUpdateBoard}
              isLeftMenuOpen={props.isLeftMenuOpen}
              userData={props.userData}
              location={props.location}
              history={props.history}
              match={props.match}
              onSelectArea={props.onSelectArea}
              isAreaSelectorVisible={props.isAreaSelectorVisible}
              areaSelected={props.areaSelected}
              handleLoginPopUp={props.handleLoginPopUp}/>
          </div>);
      })}

    </div>
  );
}

class Areas extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
    refreshPage: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: '',
      isUpdateBoard: false, // Change this to call the board list api
      openedCardsArray: [],
      boardUpdates: 0,
      showMyTemplates: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isAreaSelectorVisible} = this.props;
    if (nextProps.isAreaSelectorVisible !== undefined &&
      !!nextProps.newCreatedAreaId &&
      !nextProps.isAreaSelectorVisible && isAreaSelectorVisible) {
      this.handleUpdateBoard(true);
    }
  }

  handleUpdateBoard = (refreshPage = false) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: !prevState.isUpdateBoard,
        boardUpdates: prevState.boardUpdates + 1,
        refreshPage
      };
    });
  };

  onTextChange = (value) => {
    this.setState({searchTerm: value});
  };

  handleCreateNewArea = (e) => {
    const {onSelectArea} = this.props;
    onSelectArea();
  };

  render() {
    const {refreshPage, searchTerm, isUpdateBoard} = this.state;
    const {
      userData,
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      isLeftMenuOpen,
      location,
      history,
      match,
      onSelectArea,
      isAreaSelectorVisible,
      areaSelected,
    } = this.props;
    if (userData.currentUser.edges.length === 0) {
      return <div><p>You need to log in as a user with permissions to create favorites.</p></div>;
    }

    return (
      <Fragment>
        <div className="stories footer-margin projectBoard-right-component pb-0">
          <div className="project-board-home-wrapper container">
            <PageHeaderSection
              titleIcon="icon-area"
              title="My Saved Areas"
              onTextChange={this.onTextChange}
              showButton={true}
              onClickLink={this.handleCreateNewArea}
              placeholderText="Search My Saved Areas"
            />
            <QueryRenderer dataFrom={refreshPage ? 'STORE_THEN_NETWORK' : ''}
                           environment={environment}
                           query={graphql`
                                    query  AreasQuery($search:String)
                                    {  areasBoard
                                        {  sections
                                            {
                                                edges
                                                {
                                                    node
                                                    {
                                                        boardCardUses(search: $search)
                                                        {
                                                            edges
                                                            {
                                                                node
                                                                {
                                                                    id
                                                                    cardPosition
                                                                    frontFacingComponent
                                                                    {
                                                                        componentType
                                                                        name
                                                                        id
                                                                    }
                                                                    isFavorite
                                                                    subComponentType
                                                                    configuration {
                                                                      savedArea {
                                                                        id
                                                                        name
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        id
                                                        name
                                                        description
                                                        layout
                                                        sectionPosition
                                                    }
                                                }
                                            }
                                            id
                                            name
                                            subtitle
                                            theme
                                            {
                                                jsonStyleProperties
                                            }
                                        }
                                    }
                                `}
                           variables={{
                             isUpdateBoard: isUpdateBoard,
                             search: searchTerm
                           }}
                           render={({error, props}) => {
                             if (error) {
                               console.error(error);
                               return <div>Error!</div>;
                             }
                             if (!props) {
                               return <div>Loading...</div>;
                             }
                             return <AreasSection
                               data={props}
                               openStoryPopup={openStoryPopup}
                               openStoryPopupWithUrl={openStoryPopupWithUrl}
                               openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                               handleUpdateBoard={this.handleUpdateBoard}
                               isLeftMenuOpen={isLeftMenuOpen}
                               userData={userData}
                               location={location}
                               history={history}
                               match={match}
                               onSelectArea={onSelectArea}
                               isAreaSelectorVisible={isAreaSelectorVisible}
                               areaSelected={areaSelected}
                               handleLoginPopUp={this.handleLoginPopUp}/>;
                           }}
            />
          </div>
        </div>
        <FooterComponent/>
      </Fragment>
    );
  }
}

export default Areas;

class AreasCards extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: '',

      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: '',

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isMorePopUpVisible: false,
      xOriginForMorePopUp: 0,
      yOriginForMorePopUp: 0,

      isEditHashTagPopUpVisible: false,
      xOriginForEditHashTagPopUp: 0,
      yOriginForEditHashTagPopUp: 0,

      saveCardFromMore: false,
      data: [],
      openedCardsArray: [],

      isSavedTooltip: false,
      savedCardId: '',

      isDeleteMeAsACollaboratorPopUpVisible: false,
      isDeleteCollectionPopUpVisible: false,

      isOpenServiceSelector: false,
      isPopUpVisible: false,
      isDisplayDeleteModel: false,
      isDisplayDeleteAndReplaceModel: false,
      topPopup: 0,
      leftPopup: 0,
      isClickOutside: false,
      areaIdToRemove: null,

      cardCount: {
        mapCardsCount: 0,
        storiesCount: 0
      }
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    const {section} = this.props;
    const cardsArray = [];
    section.node.boardCardUses.edges.map((item) => {
      let mutableObject = Object.assign({}, item);
      mutableObject.isUpdate = false;
      cardsArray.push(mutableObject);
      return cardsArray;
    });
    this.setState({
      data: cardsArray
    });
    document.addEventListener('mousedown', this.handleFavPage, false);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedCardForMutation } = this.state;
    const { isAreaSelectorVisible, section } = this.props;
    if (nextProps.isAreaSelectorVisible !== undefined &&
      !nextProps.isAreaSelectorVisible && isAreaSelectorVisible) {
      this.handleReRenderCard(selectedCardForMutation);
    }
    if (!_.isEqual(nextProps.section, section)) {
      let cardsArray = [];
      nextProps.section.node.boardCardUses.edges.map((item) => {
        let mutableObject = Object.assign({}, item);
        mutableObject.isUpdate = false;
        cardsArray.push(mutableObject);
        return cardsArray;
      });
      this.setState({
        data: cardsArray
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleFavPage, false);
  }

  handleFavPage = (e) => {
    if (this.favPageNode.contains(e.target)) {
      return;
    } else {
    }
    this.handleClickOutsideFavPage();
  };

  handleClickOutsideFavPage = (event) => {
    this.hidePopUp();
  };

  buildAllSectionLayouts = (cardEdges) => {
    const sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    const layout = cardEdges.map((edge, index) => {
      const {subComponentType} = edge.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;
      return {
        'x': index % columns,
        'y': Math.floor(index / columns),
        'w': 1,
        'h': reportToolCard ? 5.3 : 11,
        'i': edge.node.id
      };
    });
    return layout;
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({isToolTipVisible: true, xOrigin: x, yOrigin: y, text: text});
  };

  hideToolTip = () => {
    this.setState({isToolTipVisible: false, isSavedTooltip: false, savedCardId: ''});
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const {openedCardsArray} = this.state;
    const escapedCardId = cardId.replace(/=/g, '\\=').replace('/', '\\/').replace('+', '\\+');
    const el = document.querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    const cardHeight = el.height;
    const cardHeightInGrid = 1 + (cardHeight / 40);

    let newOpenedCardsArray = openedCardsArray;
    let pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({i: cardId, h: cardHeightInGrid});
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({openedCardsArray: newOpenedCardsArray});
  };

  applyOpenedCardHeights = () => {
    const {data, openedCardsArray} = this.state;
    const sectionLayouts = this.buildAllSectionLayouts(data);
    const arrayCardPos = sectionLayouts.map((layout, j) => {
      return layout.map((item, index) => {
        for (var i = 0; i < openedCardsArray.length; i++) {
          if (openedCardsArray[i].i === item.i) {
            return {i: item.i, w: item.w, h: openedCardsArray[i].h, x: item.x, y: item.y};
          }
        }
        return item;
      });
    });
    return arrayCardPos;
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const {isSaveCardsPopUpVisible} = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard: this.getSelectedCard(cardId)
      });
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isOpenServiceSelector,
      isMorePopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;

    if (isPopUpVisible && !isOpenServiceSelector) {
      this.setState({isPopUpVisible: false, selectedCardForMutation: null, areaIdToRemove: null});
    }
    if (isMorePopUpVisible) {
      this.setState({isMorePopUpVisible: false});
    }
    if (isShareCardPopUpVisible) {
      this.setState({isShareCardPopUpVisible: false});
    }
    if (isShareCardWithSocialVisible) {
      this.setState({isShareCardWithSocialVisible: false});
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({isShareCardWithEmbeddedVisible: false});
    }
    if (isSelectThemePopUpVisible) {
      this.setState({isSelectThemePopUpVisible: false});
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({isSaveCardsPopUpVisible: false, isSavedTooltip, savedCardId});
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({isEditHashTagPopUpVisible: false});
    }
    if (isReportErrorPopUpVisible) {
      this.setState({isReportErrorPopUpVisible: false});
    }
    this.setState({isClickOutside: true});
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const {section} = this.props;
    if (popUpType === 'CloseTag') {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId
      });
    } else if (popUpType === 'MoreInfo') {
      let selectedCard = null;
      section.node.boardCardUses.edges.forEach((item, index) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
      });

      this.setState({
        selectedCard,
        isMorePopUpVisible: true,
        xOriginForMorePopUp: x,
        yOriginForMorePopUp: y,
        isToolTipVisible: false,
        selectedCardForMutation: cardId
      });
    }
    else if (popUpType === 'EditTags') {
      this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId
      });
    }
  };

  handleReRenderCard = (cardId) => {
    const {data} = this.state;
    const arrayData = [];
    const checkId = cardId;
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === checkId) {
        const changedObject = data[i];
        changedObject.isUpdate === true ? changedObject.isUpdate = false : changedObject.isUpdate = true;
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState({
      data: arrayData,
      selectedCardForMutation: null
    }, () => this.hidePopUp());
  };

  setSelectedCardForMutation = (id) => {
    this.setState({selectedCardForMutation: id});
  };

  openCloseServiceSelector = (isOpenServiceSelector) => {
    this.setState({isOpenServiceSelector});
  };

  crossTagPopUp = (y, x, show) => {
    if (show === true) {
      this.setState({
          xOriginNewCrossTagPopUp: x,
          yOriginNewCrossTagPopUp: y,
          isNewCrossTagPopUpVisible: true,
          isPopUpVisible: false
        }
      );
    }
  };

  handleAreaReplacement = (selectedArea, areaName) => {
    const mutation = graphql`
          mutation AreasRemoveSavedAreaMutation($input:RemoveSavedAreaInput!) {
                        removeSavedArea(input: $input) {
                            savedArea
                            {
                                id
                            }
                        }
                        }
        `;
    const {areaIdToRemove} = this.state;
    const {handleUpdateBoard} = this.props;
    if (selectedArea != null && areaIdToRemove != null) {
      const variables = {
        input: {
          'savedAreaId': areaIdToRemove,
          'replacementSavedAreaId': selectedArea
        }
      };

      commitMutation(
        environment,
        {
          mutation,
          variables,
          onCompleted: (response, errors) => {
            handleUpdateBoard(true);
          },
          onError: (err) => console.error(err)
        }
      );
    }
  };

  onDeleteClick = (id, cardCount) => {
    this.setState((state) => {
      state.selectedCardForMutation = id;
      state.isDisplayDeleteModel = true;
      state.cardCount = cardCount;
      return state;
    });
  };

  onDeleteAndReplaceButtonClick = (id, cardCount) => {
    const transform = document.getElementById(id).parentNode.style.transform;
    const translateX = transform && transform.replace('translate(', '').replace(')', '').split(',');
    const isSmallScreen = window.innerWidth < 768;
    const leftPopUp = (!isSmallScreen) ? (translateX[0] + 75) : translateX ?
      (JSON.parse(translateX[0].split('px')[0] + 250)) : 0;
    const topPopup = translateX[1] ? JSON.parse(translateX[1].split('px')[0]) + 250 : 0;
    this.setState((state) => {
      state.selectedCardForMutation = id;
      state.cardCount = cardCount;
      state.isDisplayDeleteAndReplaceModel = true;
      state.xOriginTagCrossPopUp = leftPopUp;
      state.yOriginTagCrossPopUp = topPopup;
      state.popupTop = topPopup;
      state.leftPopUp = leftPopUp;
      return state;
    });
  };

  onCancelClick = (name) => {
    this.setState((state) => {
      state[name] = false;
      return state;
    });
  };

  handleReplaceSection = () => {
    const {selectedCardForMutation, popupTop, leftPopUp, data} = this.state;
    let idToRemove;
    for (let i = 0; i < data.length; i++) {
      if (data[i].node.id === selectedCardForMutation) {
        let changedObject = data[i];
        if (changedObject && changedObject.node.configuration.savedArea.id) {
          idToRemove = changedObject.node.configuration.savedArea.id;
        }
      }
    }
    this.setState({
      isDisplayDeleteAndReplaceModel: false,
      areaIdToRemove: idToRemove
    });
    this.hideUnHidePopUp(leftPopUp, popupTop, 'CloseTag', selectedCardForMutation);
  };

  handleDeleteSection = () => {
    const {data, selectedCardForMutation} = this.state;
    const {handleUpdateBoard} = this.props;
    let idToRemove = null;
    const mutation = graphql`
          mutation AreasCascadingDeleteSavedAreaMutation($input:CascadingDeleteSavedAreaInput!) {
                        cascadingDeleteSavedArea(input: $input) {
                            savedArea
                            {
                                id
                            }
                        }
                        }
        `;
    for (let i = 0; i < data.length; i++) {
      if (data[i].node.id === selectedCardForMutation) {
        let changedObject = data[i];
        if (changedObject && changedObject.node.configuration.savedArea.id) {
          idToRemove = changedObject.node.configuration.savedArea.id;
        }
      }
    }
    const variables = {
      input: {
        'savedAreaId': idToRemove
      }
    };

    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          this.setState((state) => {
            state.isDisplayDeleteModel = false;
            state.selectedCardForMutation = null;
            return state;
          }, () => {
            handleUpdateBoard(true);
          });
        },
        onError: (err) => {
          console.error(err);
          this.setState((state) => {
            state.isDisplayDeleteModel = false;
            state.selectedCardForMutation = null;
            return state;
          });
        }
      }
    );
  };

  getSelectedCard = (cardId) => {
    const { section } = this.props;
    let selectedCard = null;
    if (section && section.node && section.node.boardCardUses && section.node.boardCardUses.edges) {
      selectedCard = section.node.boardCardUses.edges.find((item) => item.node.id === cardId);
    }
    return selectedCard;
  };

  onCardButtonClick = (cardId) => {
    this.setState({
      showCardShareModal: false,
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId)
    });
  };

  onCloseCardButtonModal = () => {
    this.setState({showCardButtonModal: false, isSaveCardsPopUpVisible: false});
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId) => {
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId
    });
  };

  render() {
    const {
      cardCount,
      areaIdToRemove,
      data,
      selectedCardForMutation,
      isClickOutside,
      isMorePopUpVisible,
      xOriginForMorePopUp,
      yOriginForMorePopUp,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
      isSaveCardsPopUpVisible,
      isPopUpVisible,
      xOriginTagCrossPopUp,
      yOriginTagCrossPopUp,
      isDisplayDeleteModel,
      isDisplayDeleteAndReplaceModel,
      showCardButtonModal,
      selectedCard,
      showTwitterCardPopup,
    } = this.state;

    const {
      isLeftMenuOpen,
      isAreaSelectorVisible,
      areaSelected,
      handleUpdateBoard,
      userData,
      openCardFamilyPopupWithUrl,
      onSelectArea,
      location,
      history,
      match,
      handleLoginPopUp,
    } = this.props;
    let layoutArray = this.applyOpenedCardHeights();
    return (
      <div onClick={() => this.hidePopUp()} ref={favPageNode => this.favPageNode = favPageNode}>
        {data.length === 0 ? <div>No Cards found.</div> :
          <ResponsiveReactGridLayout
            className={isLeftMenuOpen ? 'rgl-menu-open' : 'rgl-menu-closed'}
            cols={{lg: 5, md: 4, sm: 3, xs: 2, xxs: 1}}
            breakpoints={{lg: 1350, md: 1050, sm: 768, xs: 480, xxs: 0}}
            rowHeight={30}
            layouts={{
              lg: layoutArray[3],
              md: layoutArray[3],
              sm: layoutArray[2],
              xs: layoutArray[1],
              xxs: layoutArray[0]
            }}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            isDraggable={false}
            margin={[15, 10]}
            containerPadding={[0, 0]}
            {...this.props}
          >
            {
              data.map((item, index) => {
                if (item.node.frontFacingComponent.componentType === 'ServiceAreaMapCard') {
                  return (
                    <div key={item.node.id} id={selectedCardForMutation}>
                      <ServiceAreaMapCardContainer
                        onRef={ref => (this.child = ref)}
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isAreas={true}
                        isHomeFeed={true}
                        isFavorites={false}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        isClickOutside={isClickOutside}
                        isMorePopUpVisible={isMorePopUpVisible}
                        isAreaSelectorVisible={isAreaSelectorVisible}
                        areaSelected={areaSelected}
                        data={item}
                        deleteButtonClick={this.onDeleteClick}
                        deleteAndReplaceButtonClick={this.onDeleteAndReplaceButtonClick}
                        xOriginForMorePopUp={xOriginForMorePopUp}
                        yOriginForMorePopUp={yOriginForMorePopUp}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleReRenderCard={this.handleReRenderCard}
                        onSelectArea={onSelectArea}
                        setSelectedCardForMutation={this.setSelectedCardForMutation}
                        onCardButtonClick={this.onCardButtonClick}
                        fromPage={'areas'}
                      />
                    </div>
                  );
                } else return null;
              })
            }
          </ResponsiveReactGridLayout>
        }
        <ToolTip x={xOrigin}
                 y={yOrigin}
                 isVisible={isToolTipVisible}
                 text={text}
        />
        {showCardButtonModal || isSaveCardsPopUpVisible ? <CardButtonsPopUp
          history={history}
          showToolTip={this.showToolTip}
          hideToolTip={this.hideToolTip}
          handleReRenderCard={this.handleReRenderCard}
          handleTwitterCardPopup={this.handleTwitterCardPopup}
          isCollections={false}
          isArea={true}
          displayPageName={isSaveCardsPopUpVisible ? 'share-card' : ''}
          isFavorites={true}
          isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
          cardId={selectedCardForMutation}
          selectedCard={selectedCard}
          closePopUp={this.onCloseCardButtonModal}
          handleShareCardPopUp={this.handleShareCardPopUp}
        /> : null
        }
        {showTwitterCardPopup &&
          <WideCardPopup
            cardId={selectedCardForMutation}
            userData={userData}
            showFullPage={true}
            showTwitterCardPopup={showTwitterCardPopup}
            history={history}
            isEditingEnabled={false}
            isHomeFeed={true}
            isArea={true}
          />
        }
        {isPopUpVisible === true ?
          <SelectAreaPopup hidePopUp={this.hidePopUp}
                           isVisible={isPopUpVisible}
                           openCloseServiceSelector={this.openCloseServiceSelector}
                           x={xOriginTagCrossPopUp}
                           y={yOriginTagCrossPopUp}
                           selectedCardId={selectedCardForMutation}
                           crossTagPopUp={this.crossTagPopUp}
                           handleReRenderCard={this.handleReRenderCard}
                           userData={userData}
                           location={location}
                           history={history}
                           match={match}
                           showLogin={handleLoginPopUp}
                           title="Select Area as a Replacement"
                           handleAreaSelection={this.handleAreaReplacement}
                           popUpRenderAt="PublishBoardAsStoryPopUp"
                           okButtonTitle="Save"
                           areaIdToRemove={areaIdToRemove}
                           onCardButtonClick={this.onCardButtonClick}
          /> : null}
        {isDisplayDeleteModel ?
          <AnnouncementPopup
            closePopUp={() => this.onCancelClick('isDisplayDeleteModel')}
            onOkClick={this.handleDeleteSection}
            announcementMessage={`<div style='color: #F78E69'>There are ${cardCount.storiesCount} stories and ${cardCount.mapCardsCount} maps associated with this saved area.</div><br />` +
            `<div>Deleting this area will delete all associated stories and maps.</div>`}
            announcementPrompt="Are you sure?"
            okButtonLabel="Delete"
            closeButtonLabel="Cancel"
            title="DELETE MY SAVED AREA"
          /> : null
        }
        {isDisplayDeleteAndReplaceModel ?
          <AnnouncementPopup
            closePopUp={() => this.onCancelClick('isDisplayDeleteAndReplaceModel')}
            onOkClick={this.handleReplaceSection}
            announcementMessage={`<div style='color: #F78E69'>There are ${cardCount.storiesCount} stories and ${cardCount.mapCardsCount} maps associated with this saved area.</div><br />` +
            `<div>Replacing this area will replace all associated stories and maps.</div>`}
            announcementPrompt="Are you sure?"
            okButtonLabel="Replace"
            closeButtonLabel="Cancel"
            title="REPLACE MY SAVED AREA"
          /> : null
        }
      </div>
    );
  }
}
