/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveHashtagFromCardInput = {|
  boardCardUseId: string,
  tagName: string,
  clientMutationId?: ?string,
|};
export type CardBottomComponentsMutationVariables = {|
  input: RemoveHashtagFromCardInput
|};
export type CardBottomComponentsMutationResponse = {|
  +removeHashtagFromCard: ?{|
    +boardTagNode: ?{|
      +id: string,
      +name: string,
    |}
  |}
|};
export type CardBottomComponentsMutation = {|
  variables: CardBottomComponentsMutationVariables,
  response: CardBottomComponentsMutationResponse,
|};
*/


/*
mutation CardBottomComponentsMutation(
  $input: RemoveHashtagFromCardInput!
) {
  removeHashtagFromCard(input: $input) {
    boardTagNode {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveHashtagFromCardInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveHashtagFromCardPayload",
    "kind": "LinkedField",
    "name": "removeHashtagFromCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTagNode",
        "kind": "LinkedField",
        "name": "boardTagNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CardBottomComponentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CardBottomComponentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CardBottomComponentsMutation",
    "operationKind": "mutation",
    "text": "mutation CardBottomComponentsMutation(\n  $input: RemoveHashtagFromCardInput!\n) {\n  removeHashtagFromCard(input: $input) {\n    boardTagNode {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '887ee6c86bc96ac625722d5ce06119c3';

module.exports = node;
