import React, { Component } from "react";
import "../../css/admin.settings.css";
import "../../css/themify-icons/css/themify-icons.css";
import "../../css/custom.css";
import "../../css/components/AccountSettings/AccountOverview.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import MemberShipPopup from "../MemberShipPopup/MemberShipPopup";
import AccountEditPopup from "./AccountEditPopup";
import DeleteAccountPopup from "./DeleteAccountPopup";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSubscriptionInfo,
  subscriptionInfoSelector,
  userSelector,
} from "../../actions/user";
import UpdatePaymentPopup from "./UpdatePaymentPopup";
import RemoveCardPopUp from "./RemoveCardPopup";
import PauseMembershipPopup from "./PauseMembershipPopup";
import CreditCardImages from "./CreditCardImages";

const placeHolderImage = require("../../images-placeholder/profile1.png");

class AccountOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: "",
      profilePic: {},
      isWorking: false,
      saveKey: 0,
      wasError: false,
      wasSaved: false,
    };
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    this.refreshPage();
  }

  refreshPage() {
    const { getSubscriptionInfo } = this.props;
    getSubscriptionInfo();
  }

  updateAccountPageData = (firstName, lastName, email, file, imageId) => {
    const { isWorking } = this.state;
    if (isWorking) {
      return;
    }
    this.setState({ isWorking: true, wasSaved: false, wasError: false });
    const mutation = graphql`
      mutation AccountOverviewMutation($input: UpdateUserAccountInput!) {
        updateUserAccount(input: $input) {
          userAccountNode {
            userImage {
              url
            }
            email
          }
          validationErrors {
            key
            message
          }
        }
      }
    `;
    const input = {
      file: null,
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
    };

    let uploadables = null;
    if (file !== null) {
      uploadables = file;
    }

    commitMutation(environment, {
      mutation,
      variables: { input },
      uploadables,
      onCompleted: (response, errors) => {
        this.setState((prevState) => {
          return {
            isWorking: false,
            saveKey: prevState.saveKey + 1,
            wasSaved: true,
            wasError: false,
          };
        });
        if (imageId) {
          this.updateAccountProfileImage(imageId);
        }
      },
      onError: (err) => {
        this.setState((prevState) => {
          return { isWorking: false, wasError: true, wasSaved: false };
        });
        // TODO: Show error message
      },
    });
  };

  updateAccountProfileImage = (imageId) => {
    const mutation = graphql`
      mutation AccountOverview1Mutation($input: UpdateUserProfileImageInput!) {
        updateUserProfileImage(input: $input) {
          userAccountNode {
            userImage {
              url
            }
            email
          }
          validationErrors {
            key
            message
          }
        }
      }
    `;
    let input = {
      imageId,
    };

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        // console.log('Profile update response from server.', JSON.stringify(response));
        // TODO : check if you are getting a user id then reload the component.
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        //alert('Profile update failed, try again.');
        // TODO: Show error message
      },
    });
  };

  render() {
    const { saveKey, isWorking, wasError, wasSaved } = this.state;
    const {
      subscriptionInfo,
      handleImageEditorPopUpVisible,
      imageId,
      avatarImage,
      avatarEditor,
      history,
      userData,
      handleMembershipActivated,
      isNewSignup,
    } = this.props;
    if (!subscriptionInfo) {
      return <div />;
    }
    return (
      <AccountPageForm
        key={saveKey}
        subscriptionInfo={subscriptionInfo}
        onSave={this.updateAccountPageData}
        isWorking={isWorking}
        wasError={wasError}
        wasSaved={wasSaved}
        handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
        handleMembershipActivated={handleMembershipActivated}
        imageId={imageId}
        avatarImage={avatarImage}
        avatarEditor={avatarEditor}
        history={history}
        refreshPage={() => this.refreshPage()}
        userData={userData}
        isNewSignup={isNewSignup}
      />
    );
  }
}

class AccountPageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userEmail: props.subscriptionInfo.email,
      userFirstName: props.subscriptionInfo.userFirstName,
      userLastName: props.subscriptionInfo.userLastName,
      stripeCustomerId: props.subscriptionInfo.stripeCustomerId,
      subscriptions: props.subscriptionInfo.subscriptions,
      profilePic: null,
      croppedImage: null,
      isEditAccount: false,
      isVisibleMemberShipPopup: props.isNewSignup,
      isDeletePopup: false,

      showUpdatePaymentPopup: false,
      showRemoveCardPopup: false,
      showPauseMembershipPopup: false,

      canceling: false,
      errorMsg: "",
    };
  }

  updateAccountPageData = (firstName, lastName, email) => {
    const { imageId, profilePic } = this.state;
    const { onSave } = this.props;
    onSave(firstName, lastName, email, profilePic, imageId ? imageId : null);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  pauseMemberShip = () => {
    this.setState({ showPauseMembershipPopup: true });
  };

  cancelPauseSubscription = () => {
    const { refreshPage } = this.props;
    this.setState({
      canceling: true,
      errorMsg: "",
    });
    const mutation = graphql`
      mutation AccountOverviewCancelPauseSubacriptionMutation(
        $input: CancelPauseSubscriptionInput!
      ) {
        cancelPauseSubscription(input: $input) {
          validationErrors {
            key
            message
          }
          success
        }
      }
    `;
    const variables = {
      input: {
        clientMutationId: null,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.cancelPauseSubscription.success) {
          this.setState({
            canceling: false,
            errorMsg: "",
          });
          refreshPage();
        } else {
          this.setState({
            canceling: false,
            errorMsg: "Failed to resume membership",
          });
        }
      },
      onError: (err) => {
        this.setState({
          canceling: false,
          errorMsg: err.errors[0].message,
        });
      },
    });
  };

  componentWillReceiveProps(nextProps) {
    const { avatarEditor, imageId } = this.props;
    if (nextProps.avatarEditor && avatarEditor !== nextProps.avatarEditor) {
      const newProfileImage = nextProps.avatarEditor.getImageScaledToCanvas();
      const newProfileImageDataUrl = newProfileImage.toDataURL();
      fetch(newProfileImageDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          this.setState({
            croppedImage: newProfileImageDataUrl,
            profilePic: {
              0: new File([blob], "ProjectImg.png", { type: "image/png" }),
            },
          });
        });
    } else if (nextProps.imageId && nextProps.imageId !== imageId) {
      this.setState({
        croppedImage: nextProps.avatarImage,
        imageId: nextProps.imageId,
        // profilePic: {0: new File([nextProps.avatarImage.blob()], "ProjectImg.png", { type:
        // "image/png"})}
      });
    }
  }

  handleMembershipActivated = (subscriptionType) => {
    const { handleMembershipActivated } = this.props;
    this.setState({ isVisibleMemberShipPopup: false });
    handleMembershipActivated(subscriptionType);
  };

  render() {
    const {
      isEditAccount,
      userFirstName,
      userLastName,
      userEmail,
      isVisibleMemberShipPopup,
      isDeletePopup,
      showUpdatePaymentPopup,
      showRemoveCardPopup,
      showPauseMembershipPopup,
    } = this.state;
    const {
      wasSaved,
      wasError,
      handleImageEditorPopUpVisible,
      avatarImage,
      imageId,
      avatarEditor,
      refreshPage,
      subscriptionInfo,
      history,
    } = this.props;
    const avtarImage =
      subscriptionInfo != null && subscriptionInfo.userImage != null
        ? subscriptionInfo.userImage.url
        : placeHolderImage;
    var cardImage = CreditCardImages.getDefaultImage();
    if (subscriptionInfo && subscriptionInfo.defaultPaymentMethod != null) {
      cardImage = CreditCardImages.getImage(
        subscriptionInfo.defaultPaymentMethod.card.brand
      );
    }
    var userSubscriptionText = [
      "FREE TRIAL",
      "STANDARD MEMBERSHIP",
      "PREMIUM MEMBERSHIP",
      "ENTERPRISE MEMBERSHIP",
    ][subscriptionInfo.userSubscriptionLevel];
    if (subscriptionInfo.userSubscriptionLevel === 1) {
      userSubscriptionText =
        subscriptionInfo.userSubscriptionTerm === "monthly"
          ? "MONTHLY MEMBERSHIP"
          : "ANNUAL MEMBERSHIP";
    }
    return (
      <div className="tab-pane fade active show" id="accordion-1">
        <div className="ibox-head">
          <h3 className="font-weight-bold">Account Overview</h3>
        </div>
        <br />
        {wasSaved ? (
          <div
            className="alert alert-success alert-dismissible show-alert"
            role="alert"
          >
            Saved
          </div>
        ) : null}
        {wasError ? (
          <div
            className="alert alert-danger alert-dismissible show-alert"
            role="alert"
          >
            Failed to Save
          </div>
        ) : null}
        <div className="text-left">
          <div className="d-inline-block position-relative mb-4">
            <img
              src={avtarImage}
              onError={(e) => {
                e.target.src = placeHolderImage;
                e.target.onerror = null;
              }}
              alt=""
              data-toggle="modal"
              data-bigimage={avtarImage}
              data-target="#myModal"
              className="img-fluid rounded-circle"
              height="70px"
              width="70px"
              onClick={() => this.setState({ isEditAccount: true })}
            />
          </div>
          {!isEditAccount ? (
            <div className="">
              <div className="mb-4">
                <div className="small-text font-weight-bold">
                  {userFirstName} {userLastName}
                </div>
                <div className="small-text">{userEmail}</div>
              </div>
              <button
                className="button long-button button-outline"
                onClick={() => this.setState({ isEditAccount: true })}
              >
                Edit Info
              </button>
            </div>
          ) : (
            <AccountEditPopup
              avatarImage={avatarImage}
              imageId={imageId}
              handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
              avatarEditor={avatarEditor}
              subscriptionInfo={subscriptionInfo}
              closePopUp={() => {
                this.setState({ isEditAccount: false });
                refreshPage();
              }}
            />
          )}
          <div className="my-5">
            <h5 className="mb-3 font-weight-normal">
              Credit & Coupons{" "}
              <img
                src={require("../../images/iconStar.svg")}
                className="ml-2"
                alt=""
              />
            </h5>
            <div className="small-text font-weight-bold mb-3">
              You have{" "}
              <span
                style={{
                  background: "#403F4C",
                  padding: "5px 8px",
                  borderRadius: "4px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                $ {subscriptionInfo ? subscriptionInfo.creditAmount : 0}
              </span>{" "}
              in credit.
            </div>
            <p className="small-text mb-3">
              <Link to="/accountSetting/referFriends" className="mr-1">
                Credit
              </Link>
              from referrals can be used towards your
              <Link to="/accountSetting/billing" className="ml-1">
                next invoice
              </Link>
              . Want to share BroadStreet with others? Use credits to
              <Link to="/accountSetting/sponsor" className="mx-1">
                sponsor
              </Link>
              another person's membership
            </p>
            <button
              className="button-outline long-button button"
              onClick={() => {
                history.push("/accountSetting/referFriends");
              }}
            >
              My Credits
              <img
                src={require("../../images/iconLongArrowAltRight.svg")}
                className="ml-2"
                alt=""
              />
            </button>
          </div>
          <div className="my-5">
            <h5 className="font-weight-normal mb-4">Payment</h5>
            {subscriptionInfo && subscriptionInfo.defaultPaymentMethod ? (
              <>
                <img
                  src={cardImage}
                  className="mb-3"
                  alt=""
                  style={{ height: "50px" }}
                />
                <div className="small-text">
                  * * * * {subscriptionInfo.defaultPaymentMethod.card.last4}{" "}
                  {subscriptionInfo.defaultPaymentMethod.card.expMonth}/
                  {subscriptionInfo.defaultPaymentMethod.card.expYear}
                </div>
                <Link
                  to="#"
                  className="d-block"
                  onClick={() => {
                    this.setState({ showUpdatePaymentPopup: true });
                  }}
                >
                  Update payment details
                </Link>
                <Link
                  to="#"
                  className="d-block mb-4"
                  onClick={() => {
                    this.setState({ showRemoveCardPopup: true });
                  }}
                >
                  Remove card
                </Link>
              </>
            ) : (
              <Link
                to="#"
                className="d-block"
                onClick={() => {
                  this.setState({ showUpdatePaymentPopup: true });
                }}
              >
                Add payment details
              </Link>
            )}
            <button
              className="button-outline long-button button"
              onClick={() => {
                history.push("/accountSetting/billing");
              }}
            >
              Billing{" "}
              <img
                src={require("../../images/iconLongArrowAltRight.svg")}
                className="ml-2"
                alt=""
              />
            </button>
          </div>

          <div className="my-5">
            <h5 className="mb-3 font-weight-normal">Usage</h5>
            <div className="d-flex align-items-center">
              <span className="progress-label">
                {subscriptionInfo.usageCountStories || 0} story boards
              </span>
              {subscriptionInfo && (
                <div className="progress-background">
                  <div
                    className="progress-bar "
                    style={{
                      width: `${Math.round(
                        (subscriptionInfo.usageCountStories * 100) /
                          subscriptionInfo.usageMaxStories
                      )}%`,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <span className="progress-label">
                {subscriptionInfo.usageCountCards || 0} cards
              </span>
              <div className="progress-background">
                <div
                  className="progress-bar error"
                  style={{
                    width: `${Math.round(
                      (subscriptionInfo.usageCountCards * 100) /
                        subscriptionInfo.usageMaxCards
                    )}%`,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-5">
            <h5 className="mb-3 font-weight-normal">Membership</h5>
            <p className="small-text">
              You are on the {userSubscriptionText}
              {subscriptionInfo.isOverSubscriptionLimits && (
                <span className="text-warning ml-1">
                  and are over limits. To continue creating new stories, please
                  start your membership.
                </span>
              )}
            </p>
            <button
              className="button-outline long-button button mb-3"
              onClick={() => {
                this.setState({ isVisibleMemberShipPopup: true });
              }}
            >
              {subscriptionInfo.userSubscriptionLevel ? "Update" : "Activate"}{" "}
              Membership
            </button>
            {/*
            <hr className="my-4" />
            <div>
              <h5 className="mb-3 font-weight-normal">Subscriptions</h5>
              <div className="">
                {
                  subscriptionInfo.pauseSubscriptionStartDate == null && subscriptionInfo.userSubscriptionLevel !== 0 &&
                  <button className="button-outline long-button button"
                    onClick={() => this.pauseMemberShip()}>
                    Pause Membership
                  </button>
                }
                {
                  subscriptionInfo.pauseSubscriptionStartDate !== null &&
                  <div>
                    <div className="text-warning mb-3">Your membership is paused
                      through {(new Date(subscriptionInfo.pauseSubscriptionEndDate)).toLocaleDateString()}</div>
                    <button className="button-outline long-button button mb-3"
                      onClick={() => this.cancelPauseSubscription()}
                      disabled={canceling}>
                      {canceling ? 'Working ...' : 'Resume Membership'}
                    </button>

                    {errorMsg &&
                      <div className="alert alert-danger alert-dismissible show-alert mb-3"
                        role="alert">{errorMsg}</div>}
                  </div>
                }
              </div>
            </div>
            */}
            <hr className="my-4" />
            {subscriptionInfo && (
              <>
                {subscriptionInfo.isDeletedAccount ? (
                  <>
                    <div className="text-waring mb-3">
                      You have requested to delete your BroadStreet account.
                      Your account will be deleted on{" "}
                      {new Date(
                        subscriptionInfo.deleteByDate
                      ).toLocaleDateString()}
                    </div>
                    <button
                      className="button-outline long-button button mb-3"
                      onClick={() => {
                        this.setState({ isDeletePopup: true });
                      }}
                    >
                      Undo Delete Account
                    </button>
                  </>
                ) : (
                  <button
                    className="button-outline long-button button mb-3"
                    onClick={() => {
                      this.setState({ isDeletePopup: true });
                    }}
                  >
                    Delete Account
                  </button>
                )}
              </>
            )}
          </div>
          {isVisibleMemberShipPopup && (
            <MemberShipPopup
              closePopUp={() => {
                this.setState({ isVisibleMemberShipPopup: false });
                refreshPage();
              }}
              onSuccess={this.handleMembershipActivated}
            />
          )}
          {isDeletePopup && (
            <DeleteAccountPopup
              closePopUp={() => {
                this.setState({ isDeletePopup: false });
                refreshPage();
              }}
            />
          )}

          {showUpdatePaymentPopup && (
            <UpdatePaymentPopup
              closePopUp={() => {
                this.setState({ showUpdatePaymentPopup: false });
                refreshPage();
              }}
            />
          )}
          {showRemoveCardPopup && (
            <RemoveCardPopUp
              closePopUp={() => {
                this.setState({ showRemoveCardPopup: false });
                refreshPage();
              }}
            />
          )}
          {showPauseMembershipPopup && (
            <PauseMembershipPopup
              closePopUp={() => {
                this.setState({ showPauseMembershipPopup: false });
                refreshPage();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state),
  userData: userSelector(state),
});

const mapDispatch = {
  getSubscriptionInfo,
};
export default connect(mapStatus, mapDispatch)(withRouter(AccountOverview));
