/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HomeFeedQueryVariables = {|
  pageSize?: ?number,
  filterTag?: ?string,
  permaTag?: ?number,
  cursor?: ?string,
|};
export type HomeFeedQueryResponse = {|
  +publicBoard: ?{|
    +sections: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardCardUses: ?{|
            +pageInfo: {|
              +startCursor: ?string,
              +endCursor: ?string,
              +hasNextPage: boolean,
            |},
            +edges: $ReadOnlyArray<?{|
              +cursor: string,
              +node: ?{|
                +id: string,
                +cardPosition: number,
                +frontFacingComponent: {|
                  +componentType: string,
                  +name: string,
                |},
                +isFavorite: ?boolean,
                +subComponentType: ?string,
              |},
            |}>,
          |},
          +id: string,
          +name: string,
          +description: ?string,
          +sectionPosition: number,
        |}
      |}>
    |},
    +id: string,
    +name: string,
    +subtitle: ?string,
    +theme: {|
      +jsonStyleProperties: string
    |},
  |}
|};
export type HomeFeedQuery = {|
  variables: HomeFeedQueryVariables,
  response: HomeFeedQueryResponse,
|};
*/


/*
query HomeFeedQuery(
  $pageSize: Int
  $filterTag: String
  $permaTag: Int
  $cursor: String
) {
  publicBoard {
    sections {
      edges {
        node {
          boardCardUses(first: $pageSize, tag: $filterTag, permaTag: $permaTag, after: $cursor) {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                  id
                }
                isFavorite
                subComponentType
              }
            }
          }
          id
          name
          description
          sectionPosition
        }
      }
    }
    id
    name
    subtitle
    theme {
      jsonStyleProperties
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterTag",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "permaTag",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "permaTag",
    "variableName": "permaTag"
  },
  {
    "kind": "Variable",
    "name": "tag",
    "variableName": "filterTag"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionPosition",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeFeedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "publicBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v7/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeFeedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "publicBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v7/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "HomeFeedQuery",
    "operationKind": "query",
    "text": "query HomeFeedQuery(\n  $pageSize: Int\n  $filterTag: String\n  $permaTag: Int\n  $cursor: String\n) {\n  publicBoard {\n    sections {\n      edges {\n        node {\n          boardCardUses(first: $pageSize, tag: $filterTag, permaTag: $permaTag, after: $cursor) {\n            pageInfo {\n              startCursor\n              endCursor\n              hasNextPage\n            }\n            edges {\n              cursor\n              node {\n                id\n                cardPosition\n                frontFacingComponent {\n                  componentType\n                  name\n                  id\n                }\n                isFavorite\n                subComponentType\n              }\n            }\n          }\n          id\n          name\n          description\n          sectionPosition\n        }\n      }\n    }\n    id\n    name\n    subtitle\n    theme {\n      jsonStyleProperties\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12aa243442e4884ec49bb2208697c5e5';

module.exports = node;
