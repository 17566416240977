import React, { Component } from "react";
import * as d3 from "d3";
import "../../css/components/Card/D3NumberCard.css";

class D3NumberCard extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 10, bottom: 10, left: 10 };
    this.rectHeight = 15;
    this.duration = 1000;
    this.clientWidth = 215;
    this.clientHeight = 105;
    this.state = {
      ratio: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.update(nextProps);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  init = () => {
    this.createSvg();
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3NumberCard")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);
    this.svgRect = this.svg.append("svg:rect");

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");

    const tooltipRect = this.svgG.selectAll("rect.tooltip-rect").data(
      (d) => [d],
      (d) => d
    );
    tooltipRect
      .enter()
      .append("rect")
      .merge(tooltipRect)
      .attr("id", (d) => d + "tooltip-rect")
      .attr("class", "tooltip-rect")
      .style("opacity", 0);
    tooltipRect.exit().remove();

    const gProgress = this.svgG.selectAll("g.g-progress").data(
      (d) => [d],
      (d) => d
    );
    gProgress
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "g-progress")
      .attr("class", "g-progress");
    gProgress.exit().remove();
    this.progressG = d3.select("g#" + svgId + "g-progress");

    const gDashPathSelection = this.svgG
      .selectAll("g.dash-path-container")
      .data(
        (d) => [d],
        (d) => d
      );
    gDashPathSelection
      .enter()
      .append("g")
      .merge(gDashPathSelection)
      .attr("class", "dash-path-container")
      .attr("id", (d) => d + "dash-path-container");
    gDashPathSelection.exit().remove();
    this.svgDashPathG = d3.select("g#" + svgId + "dash-path-container");

    this.tooltipRect = this.svgG
      .select("rect#" + svgId + "tooltip-rect")
      .attr("pointer-events", "all")
      .on("mousemove", () => {
        d3.event.stopPropagation();
        this.drawTooltip();
      })
      .on("mouseout", () => {
        d3.event.stopPropagation();
        this.drawTooltip(true);
      });
  };

  autoAlignSVG = (props) => {
    const { isWide } = props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;

    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.svgRect
      .attr("width", this.width + this.margin.left + this.margin.right) // the whole width of
      // g/svg
      .attr("height", this.height + this.margin.top + this.margin.bottom) // the whole heigh of
      // g/svg
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseover", () => {
        this.count = this.count + 1;
        if (this.count === 1) {
          this.createProgress(props);
        }
      })
      .on("mousemove", () => {
        d3.event.stopPropagation();
      })
      .on("mouseout", () => {
        this.count = 0;
        d3.event.stopPropagation();
      });
    this.svgDashPathG.attr("transform", "translate(0,0)");
    this.scaleLinear.range([this.rectHeight, this.width]);
    this.tooltipRect
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this.height);
  };

  update = (props) => {
    const { title, min, max } = props;
    this.scaleLinear = d3.scaleLinear().domain([min, max]);
    this.autoAlignSVG(props);
    this.animateNumber(this.mainTitleEle, title);
    this.createProgress(props);
    this.createDashPath(props);
  };

  animateNumber = (ele, value) => {
    if (value) {
      const val = d3
        .select(ele)
        .selectAll("span.animate-title")
        .data([value], (d) => d);
      const newVal = val.enter().append("span");
      newVal
        .merge(val)
        .text(0)
        .attr("class", "animate-title")
        .transition()
        .duration(this.duration)
        .style("color", "#424B54")
        .tween("text", function (d) {
          const format = d3.format(",d");
          const that = d3.select(this),
            i = d3.interpolateNumber(that.text().replace(/,/g, ""), d);
          return function (t) {
            that.text(format(i(t)));
          };
        });
      val.exit().remove();
    }
  };

  createProgress = (props) => {
    const { value, max } = props;
    this.progressG.selectAll("rect.pb-rect").remove();
    const pb = this.progressG
      .selectAll("rect.pb-rect")
      .data([max, value], (d) => d);
    const newBP = pb.enter().append("rect");
    newBP
      .merge(pb)
      .attr("class", "pb-rect")
      .attr("rx", this.rectHeight / 2)
      .attr("ry", this.rectHeight / 2)
      .attr("fill", (d, i) => (i ? "#424B54" : "#E3E3E3"))
      .attr("x", 0)
      .attr("y", this.height / 3)
      .attr("height", this.rectHeight)
      .attr("width", 0)
      .transition()
      .duration(this.duration)
      .attr("width", (d) => this.scaleLinear(d));
    pb.exit().remove();
  };

  createDashPath(props) {
    const { usTitle, usValue, value } = props;
    this.svgDashPathG
      .attr("transform", `translate(0, ${this.height / 3})`)
      .transition()
      .duration(this.duration)
      .attr(
        "transform",
        `translate(${this.scaleLinear(usValue)}, ${this.height / 3})`
      );

    const lineG = this.svgDashPathG
      .selectAll("path.dash")
      .data([{ usValue }, { usValue }], (d) => d.usValue);
    const newLineG = lineG.enter().append("path");
    newLineG
      .merge(lineG)
      .attr("class", "dash")
      .attr("d", (d, i) => {
        if (i === 0) {
          return "M0," + (this.rectHeight + 5) + " L0,-5";
        } else if (i === 1) {
          return "M0,0 L0," + this.rectHeight;
        }
      })
      .style("stroke", (d, i) =>
        i === 1 && usValue < value ? "#ffffff" : "#424B54"
      )
      .style("stroke-width", "1px")
      .style("stroke-dasharray", "3,3");
    lineG.exit().remove();
    const textLabel = this.svgDashPathG.selectAll("text.us-title").data(
      [
        {
          usTitle,
          usValue,
        },
      ],
      (d) => d.usTitle
    );
    const newTextLabel = textLabel.enter().append("text");
    newTextLabel
      .merge(textLabel)
      .text((d) => d.usTitle)
      .attr("class", "us-title")
      .attr("dx", (d) => {
        if (d.usValue <= 25) {
          return -5;
        } else if (d.usValue >= 75) {
          return 5;
        } else {
          return 0;
        }
      })
      .attr("dy", this.rectHeight * 2)
      .attr("fill", "#424B54")
      .style("text-anchor", (d) => {
        if (d.usValue <= 25) {
          return "start";
        } else if (d.usValue >= 75) {
          return "end";
        } else {
          return "middle";
        }
      })
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "10px")
      .style("color", "#424b54");
    textLabel.exit().remove();
  }

  drawTooltip = (isRemove) => {
    const { tooltip } = this.props;
    const { ratio } = this.state;
    const makeTooltip = (data, mPos) => {
      const tooltip = d3
        .select("body")
        .selectAll("div.d3-tooltip")
        .data(data, (d) => d);
      const newTooltip = tooltip.enter().append("div");
      newTooltip
        .merge(tooltip)
        .attr("class", "d3-tooltip")
        .style("display", "block")
        .style("position", "absolute")
        .style("font-size", ratio ? 10 * ratio + "px" : "10px")
        .style("font-family", "ProximaNova-Regular")
        .style("font-weight", "bolder")
        .style("background-color", "rgba(255, 255, 255, 1)")
        .style("border", "1px solid #000000")
        .style("padding", "5px")
        .style("text-align", "left")
        .style("left", mPos[0] + 10 + "px")
        .style("top", mPos[1] - 10 + "px")
        .style("transform", "translate(-100%,-100%)");

      const div = newTooltip
        .merge(tooltip)
        .selectAll("div.d3-tooltip-div")
        .data(
          (d) =>
            Object.keys(d).map((key, i) => {
              return { id: i, text: d[key] };
            }),
          (d) => d.id
        );
      const newDiv = div.enter().append("div");
      newDiv
        .merge(div)
        .attr("class", "d3-tooltip-div")
        .style("font-weight", (d, i) => !i && "bold")
        .style("color", (d, i) => (!i ? "#424B54" : "#989898"))
        .style("font-size", (d, i) =>
          !i && ratio ? 12 * ratio + "px" : "12px"
        )
        .html((d) => d.text);
      div.exit().remove();
      tooltip.exit().remove();
    };

    if (isRemove) {
      makeTooltip([], [0, 0]);
    } else {
      const mPos = d3.mouse(d3.select("body").node());
      makeTooltip(
        [
          {
            title: tooltip[0].title,
            description: tooltip[0].ourcommunity,
            usTitle: tooltip[0].us,
          },
        ],
        mPos
      );
    }
  };

  render() {
    const { subTittle, description } = this.props;
    const { ratio } = this.state;
    return (
      <div
        className="D3NumberCard d-flex flex-column justify-content-center align-items-center h-100 w-100"
        ref={(ele) => (this.mainEle = ele)}
      >
        <div
          className="d-flex flex-column"
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div className="number-row">
            <div
              className="main-title mb-0 text-center"
              ref={(ele) => (this.mainTitleEle = ele)}
            />
            <div className="subTitle mb-0 text-center">{subTittle}</div>
          </div>
          <div className="number-row">
            <div ref={(ele) => (this.parentEle = ele)} />
          </div>
          <div className="number-row">
            <p
              className="details mb-0 text-center"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default D3NumberCard;
