/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserAccountInput = {|
  username?: ?string,
  userEmail?: ?string,
  userFirstName?: ?string,
  userLastName?: ?string,
  userOldPassword?: ?string,
  userNewPassword?: ?string,
  userReportName?: ?string,
  userDegrees?: ?string,
  useCompanyInSignature?: ?boolean,
  userSecondaryEmail?: ?string,
  userBillingFirstName?: ?string,
  userBillingLastName?: ?string,
  userBillingAddress?: ?string,
  userOrganizationName?: ?string,
  userOrganizationTitle?: ?string,
  userOrganizationAddress?: ?string,
  userOrganizationCity?: ?string,
  userOrganizationState?: ?string,
  userOrganizationZip?: ?string,
  userOrganizationEmail?: ?string,
  userOrganizationPhone?: ?string,
  briefBio?: ?string,
  contactInfo?: ?string,
  file?: ?any,
  isProfilePublic?: ?boolean,
  userTitle?: ?string,
  subscriptionCouponCode?: ?string,
  clientMutationId?: ?string,
|};
export type SponsorUpdateCouponMutationVariables = {|
  input: UpdateUserAccountInput
|};
export type SponsorUpdateCouponMutationResponse = {|
  +updateUserAccount: ?{|
    +success: ?boolean
  |}
|};
export type SponsorUpdateCouponMutation = {|
  variables: SponsorUpdateCouponMutationVariables,
  response: SponsorUpdateCouponMutationResponse,
|};
*/


/*
mutation SponsorUpdateCouponMutation(
  $input: UpdateUserAccountInput!
) {
  updateUserAccount(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserAccountInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserAccountPayload",
    "kind": "LinkedField",
    "name": "updateUserAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SponsorUpdateCouponMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SponsorUpdateCouponMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SponsorUpdateCouponMutation",
    "operationKind": "mutation",
    "text": "mutation SponsorUpdateCouponMutation(\n  $input: UpdateUserAccountInput!\n) {\n  updateUserAccount(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b09c9a95e02f789d8a1aa841ea6f881';

module.exports = node;
