/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FavoriteToolsQueryVariables = {||};
export type FavoriteToolsQueryResponse = {|
  +favoriteTools: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +frontFacingComponent: {|
          +componentType: string,
          +name: string,
        |},
        +subComponentType: ?string,
        +isFavorite: ?boolean,
        +configuration: {|
          +title: ?string,
          +citation: ?string,
        |},
      |}
    |}>
  |}
|};
export type FavoriteToolsQuery = {|
  variables: FavoriteToolsQueryVariables,
  response: FavoriteToolsQueryResponse,
|};
*/


/*
query FavoriteToolsQuery {
  favoriteTools {
    edges {
      node {
        id
        frontFacingComponent {
          componentType
          name
          id
        }
        subComponentType
        isFavorite
        configuration {
          title
          citation
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "citation",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoriteToolsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardCardUseNodeConnection",
        "kind": "LinkedField",
        "name": "favoriteTools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardUseNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardUseNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FrontFacingComponentNode",
                    "kind": "LinkedField",
                    "name": "frontFacingComponent",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardConfigurationNode",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FavoriteToolsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardCardUseNodeConnection",
        "kind": "LinkedField",
        "name": "favoriteTools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardUseNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardUseNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FrontFacingComponentNode",
                    "kind": "LinkedField",
                    "name": "frontFacingComponent",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardConfigurationNode",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FavoriteToolsQuery",
    "operationKind": "query",
    "text": "query FavoriteToolsQuery {\n  favoriteTools {\n    edges {\n      node {\n        id\n        frontFacingComponent {\n          componentType\n          name\n          id\n        }\n        subComponentType\n        isFavorite\n        configuration {\n          title\n          citation\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3879428c69881b4e508385b0d9f91c4';

module.exports = node;
