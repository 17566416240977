import React, { Component, Fragment } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/components/RunAReport/RunAReport.css";
import ReportCardContainer from "../Card/ReportCardContainer";
import StoryCardContainer from "../Card/StoryCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import { ToolTip } from "common-components";
import SaveCardsPopUp from "../CommonComponents/SaveCardsPopUp";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import { Responsive } from "react-grid-layout";
import "../../css/components/FavoriteTools/FavoriteTools.css";
import { reportToolCardArray } from "../../utility/utilities";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class FavoriteToolsSection extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      xOriginSaveCardsPopUp: 0,
      yOriginSaveCardsPopUp: 0,
      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isEditHashTagPopUpVisible: false,
      xOriginForEditHashTagPopUp: 0,
      yOriginForEditHashTagPopUp: 0,

      saveCardFromMore: false,
      data: [],
      openedCardsArray: [],
      isSavedTooltip: false,
      savedCardId: "",
      showCardButtonModal: false,
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    document.addEventListener("mousedown", this.handleFavPage, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleFavPage, false);
  }

  handleFavPage = (e) => {
    if (this.favPageNode.contains(e.target)) {
      return;
    } else {
    }
    this.handleClickOutsideFavPage();
  };

  handleClickOutsideFavPage = (event) => {
    this.hidePopUp();
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedTooltip: false,
      savedCardId: "",
    });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    var escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    var el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + cardHeight / 40;

    var newOpenedCardsArray = openedCardsArray;
    var pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({
          i: cardId,
          h: cardHeightInGrid,
        });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const { isSaveCardsPopUpVisible } = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.setState({
        xOriginSaveCardsPopUp: x,
        yOriginSaveCardsPopUp: y,
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
      });
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;
    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    if (popUpType === "CloseTag") {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "MoreInfo") {
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "EditTags") {
      this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId,
      });
    }
  };

  // show  ShareCardPopUp
  handleShareCardPopUp = (x, y) => {
    const { isShareCardPopUpVisible } = this.state;
    if (isShareCardPopUpVisible === false) {
      this.setState({
        isShareCardPopUpVisible: true,
        xOriginShareCardPopUp: x,
        yOriginShareCardPopUp: y,
      });
    }
  };

  //ReportErrorPopup
  handleReportErrorPopUp = (x, y) => {
    const { isReportErrorPopUpVisible } = this.state;
    if (isReportErrorPopUpVisible === false) {
      this.setState({
        xOriginReportErrorPopUp: x,
        yOriginReportErrorPopUp: y,
        isReportErrorPopUpVisible: true,
      });
    }
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h: 5,
        i: edge.node.id,
      };
    });
    return layout;
  };

  applyOpenedCardHeights = () => {
    const { data } = this.props;
    const sectionLayouts = this.buildAllSectionLayouts(
      data.favoriteTools.edges
    );
    /*var arrayCardPos = sectionLayouts.map((layout, j) => {
        return layout.map((item, index) => {
            return item;
        });
    });*/
    return sectionLayouts;
  };

  handleReRenderCard = (id) => {
    const { handleUpdateBoard } = this.props;
    handleUpdateBoard(true);
  };

  onCardButtonClick = (cardId, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const { data } = this.props;
    let selectedCard = null;
    data &&
      data.favoriteTools &&
      data.favoriteTools.edges &&
      data.favoriteTools.edges.forEach((item, index) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
        return selectedCard;
      });
    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard,
    });
  };

  onCloseCardButtonModal = () => {
    const { history } = this.props;
    this.setState({ showCardButtonModal: false });
    history.replace({ ...history.location.pathname });
  };

  render() {
    const layoutArray = this.applyOpenedCardHeights();
    const {
      history,
      data,
      userData,
      isLeftMenuOpen,
      handleUpdateBoard,
      handleLoginPopUp,
      openStoryPopup,
      isToolTipVisible,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      xOrigin,
      yOrigin,
      selectedCard,
      text,
    } = this.props;
    const {
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      xOriginSaveCardsPopUp,
      yOriginSaveCardsPopUp,
      selectedCardForMutation,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
      saveCardFromMore,
    } = this.state;
    return (
      <div
        className="favoriteTools"
        onClick={() => this.hidePopUp()}
        ref={(favPageNode) => (this.favPageNode = favPageNode)}
      >
        {data &&
        data.favoriteTools &&
        data.favoriteTools.edges.length &&
        data.favoriteTools.edges.length ? (
          <ResponsiveReactGridLayout
            className={isLeftMenuOpen ? "rgl-menu-open" : "rgl-menu-closed"}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            breakpoints={{ lg: 1350, md: 1050, sm: 768, xs: 480, xxs: 0 }}
            rowHeight={30}
            layouts={{
              lg: layoutArray[3],
              md: layoutArray[3],
              sm: layoutArray[2],
              xs: layoutArray[1],
              xxs: layoutArray[0],
            }}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            isDraggable={false}
            margin={[15, 10]}
            containerPadding={[0, 0]}
            {...this.props}
          >
            {data.favoriteTools.edges.map((item, index) => {
              if (
                item.node.frontFacingComponent.componentType === "ReportCard"
              ) {
                return (
                  <div key={item.node.id} className="grid-card">
                    <ReportCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        this.handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (
                item.node.frontFacingComponent.componentType === "AdiMapCard"
              ) {
                return (
                  <div key={item.node.id} className="grid-card">
                    <AdiMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        this.handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (
                item.node.frontFacingComponent.componentType ===
                "ServiceAreaMapCard"
              ) {
                return (
                  <div key={item.node.id} className="grid-card">
                    <ServiceAreaMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        this.handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (
                item.node.frontFacingComponent.componentType === "ChartCard"
              ) {
                // else if (true) {
                const { subComponentType } = item.node;
                const reportToolCard =
                  reportToolCardArray.indexOf(subComponentType) > -1;
                if (reportToolCard) {
                  return (
                    <div key={item.node.id} className="grid-card">
                      <ReportToolCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        // isEditingEnabled={false}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapcardsVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        handleLoginPopUp={handleLoginPopUp}
                        history={history}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={item.node.id} className="grid-card">
                      <ChartCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        // isEditingEnabled={false}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapcardsVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        handleLoginPopUp={handleLoginPopUp}
                        history={history}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                }
              } else if (
                item.node.frontFacingComponent.componentType === "MapCard"
              ) {
                // else if (true) {
                return (
                  <div key={item.node.id} className="grid-card">
                    <StoryCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        this.handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={true}
                      handleUpdateBoard={handleUpdateBoard}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={item.node.id} className="grid-card">
                    <StoryCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={
                        this.handleArrayOfTagsOfCardObject
                      }
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={false}
                      handleUpdateBoard={handleUpdateBoard}
                      openStoryPopup={openStoryPopup}
                      openStoryPopupWithUrl={openStoryPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              }
            })}
          </ResponsiveReactGridLayout>
        ) : (
          <div>
            Add tools & reports here!{" "}
            <a
              target="bshelp"
              href="https://help.broadstreet.io/article/my-favorites/"
              rel="noopener noreferrer"
            >
              Learn how >
            </a>
          </div>
        )}
        <ToolTip
          x={xOrigin}
          y={yOrigin}
          isVisible={isToolTipVisible}
          text={text}
        />
        {isSaveCardsPopUpVisible ? (
          <SaveCardsPopUp
            isVisible={isSaveCardsPopUpVisible}
            history={history}
            x={xOriginSaveCardsPopUp}
            y={yOriginSaveCardsPopUp}
            cardId={selectedCardForMutation}
            hidePopUp={this.hidePopUp}
            saveCardFromMore={saveCardFromMore}
          />
        ) : null}
        {showCardButtonModal ? (
          <CardButtonsPopUp
            history={history}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleReRenderCard={this.handleReRenderCard}
            isCollections={false}
            disabledDownloadButton={true}
            isFavorites={true}
            isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
            cardId={selectedCardForMutation}
            selectedCard={selectedCard}
            closePopUp={this.onCloseCardButtonModal}
            handleShareCardPopUp={this.handleShareCardPopUp}
          />
        ) : null}
      </div>
    );
  }
}

class FavoriteTools extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",
      isUpdateBoard: false, // Change this to call the board list api
      openedCardsArray: [],
      boardUpdates: 0,
      refresh: false,
    };
  }

  /*removeCard = () => {
      this.setState({
          arrayData: []
      });
  };*/

  handleStoryVisiblity = (storyId) => {
    const { isStoryVisible } = this.state;
    if (isStoryVisible === true) {
      this.setState({
        isStoryVisible: false,
        storyId: null,
      });
    } else {
      this.setState({
        isStoryVisible: true,
        storyId: storyId,
      });
    }
  };

  handleUpdateBoard = (refresh = false) => {
    const { updatePage } = this.props;
    this.setState((prevState) => {
      return {
        isUpdateBoard: prevState.isUpdateBoard === true ? false : true,
        boardUpdates: prevState.boardUpdates + 1,
        refresh,
      };
    });
    updatePage();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateFavorites } = this.props;
    if (nextProps.updateFavorites !== updateFavorites) {
      this.handleUpdateBoard(true);
    }
  }

  render() {
    const { refresh, isStoryVisible, isUpdateBoard } = this.state;
    const {
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      handleLoginPopUp,
      history,
      userData,
      isLeftMenuOpen,
    } = this.props;

    return (
      <Fragment>
        <div
          className={
            "pb-0 " +
            (isStoryVisible === true
              ? "projectBoard-right-component withStory"
              : "projectBoard-right-component")
          }
        >
          <QueryRenderer
            dataFrom={refresh ? "STORE_THEN_NETWORK" : ""}
            environment={environment}
            query={graphql`
              query FavoriteToolsQuery {
                favoriteTools {
                  edges {
                    node {
                      id
                      frontFacingComponent {
                        componentType
                        name
                      }
                      subComponentType
                      isFavorite
                      configuration {
                        title
                        citation
                      }
                    }
                  }
                }
              }
            `}
            variables={{ isUpdateBoard: isUpdateBoard }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return <div>Loading...</div>;
              }
              return (
                <FavoriteToolsSection
                  data={props}
                  openStoryPopup={openStoryPopup}
                  openStoryPopupWithUrl={openStoryPopupWithUrl}
                  openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                  handleUpdateBoard={this.handleUpdateBoard}
                  handleLoginPopUp={handleLoginPopUp}
                  history={history}
                  userData={userData}
                  isLeftMenuOpen={isLeftMenuOpen}
                />
              );
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default FavoriteTools;
