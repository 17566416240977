import React from 'react';
import { AnnouncementPopup } from 'common-components';
import '../../css/custom.css';

const MembershipActivatedPopup = ({
    closePopUp,
    viewBilling,
    subscriptionType
}) => {
    return (
        <>
            <AnnouncementPopup closePopUp={closePopUp} onOkClick={viewBilling}
                announcementMessage="Congratulations! You are now a full member of BroadStreet. Enjoy unlimited access to stories, data, and maps."
                announcementPrompt="Thank you for supporting the BroadStreet mission."
                closeButtonLabel="Close" okButtonLabel="VIEW" title="SUCCESSFUL PAYMENT">
            </AnnouncementPopup>
        </>
    );
};

export default MembershipActivatedPopup;
