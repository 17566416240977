import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3LineChart from './D3LineChart';

class D3LineWideChart extends Component {
  render() {
    const { id, lineData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3LineWideChart d-flex w-100 h-100 position-relative'>
        {(id && lineData) ?
          <D3LineChart id={id} key={id + 'lineChart'} isWide={true} lineData={lineData}/> : ''
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3LineWideChart;
