import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
  mutation PostTweetMutation($input: PostTweetInput!)
  {
    postTweet(input: $input)
    {
      resultMessage
      success
    }
  }
`;

export default (file, commentText, callback, errorCallback) => {
  let uploadables = null;
  if (file != null) {
    uploadables = file;
  }
  const variables = {
    'input':
      {
        'file': null,
        'tweetText': commentText
      }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      uploadables,
      onCompleted: (response, errors) => {
        // console.log('Response from server section layouts update.',JSON.stringify(response))
        // console.log('Response from server section layouts update.',(response))
        callback(response);
      },
      onError: (err) => {
        console.error('Error from server section layouts update sectionLayoutMutation', JSON.stringify(err));
        errorCallback(err);
      }
    }
  );
}
