import React, { Component } from 'react';
import airParticles from './../../images/airParticles.svg';
import * as d3 from 'd3';

class D3PollutionChart extends Component {

  constructor(props) {
    super(props);
    this.margin = {top: 5, right: 10, bottom: 5, left: 10};
    this.clientWidth = 230;
    this.clientHeight = 215;
    this.spaceBetweenLegeds = 16;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.init(this.props);
    }
  }

  componentDidUpdate() {
    this.init(this.props);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
  };

  componentDidMount() {
    this.init(this.props);
    window.addEventListener('resize', this.onWindowResize);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResize);
  }

  init = (props) => {
    const {pollutionData, id} = props;
    if (pollutionData && id) {
      d3.select(this.parentEle).selectAll('svg').filter((d) => d === id).remove();
      this.createSvg(props);
    }
  };

  createSvg = (props) => {
    const id = props.id.replace(/=/g, '');
    const selection = d3.select(this.parentEle).selectAll('svg').data([id], (d) => d);
    selection.enter().append('svg').merge(selection).attr('class', 'D3PollutionChart').attr('id', d => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select('svg#' + id);

    const gSelection = this.svg.selectAll('g.g-container').data((d) => [d], (d) => d);
    gSelection.enter().append('g').merge(gSelection).attr('class', 'g-container').attr('id', (d) => d + 'g-container');
    gSelection.exit().remove();
    this.svgG = d3.select('g#' + id + 'g-container');

    const legendG = this.svgG.selectAll('g.legend-container').data((d) => [d], (d) => d);
    legendG.enter().append('g').merge(legendG).attr('id', (d) => d + 'legendcontainer').attr('class', 'legend-container');
    legendG.exit().remove();
    this.legendG = this.svgG.select('g#' + id + 'legendcontainer');

    const imageSelection = this.svgG.selectAll('image.image-container').data((d) => [d], (d) => d);
    imageSelection.enter().append('image').merge(imageSelection).attr('class', 'image-container').attr('id', (d) => d + 'image-container');
    imageSelection.exit().remove();
    this.bgImage = d3.select('image#' + id + 'image-container');
    const rotationSelection = this.svgG.selectAll('g.rotation-container').data((d) => [d], (d) => d);
    rotationSelection.enter().append('g').merge(rotationSelection).attr('class', 'rotation-container').attr('id', (d) => d + 'rotation-container');
    rotationSelection.exit().remove();
    this.rotationG = d3.select('g#' + id + 'rotation-container');
    const circleSelection = this.rotationG.selectAll('g.circle-container').data((d) => [d], (d) => d);
    circleSelection.enter().append('g').merge(circleSelection).attr('class', 'circle-container').attr('id', (d) => d + 'circle-container');
    circleSelection.exit().remove();
    this.circleG = d3.select('g#' + id + 'circle-container');
    const legendSelection = this.svgG.selectAll('g.legend-container').data((d) => [d], (d) => d);
    legendSelection.enter().append('g').merge(legendSelection).attr('class', 'legend-container').attr('id', (d) => d + 'legend-container');
    legendSelection.exit().remove();
    this.autoAlignSVG(props);
  };

  autoAlignSVG = (props) => {
    const {pollutionData: {legends}, isWide} = this.props;
    this.legendHeight = (this.spaceBetweenLegeds * legends.length);
    if (isWide) {
      this.size = Math.min(this.parentEle.clientWidth, this.parentEle.clientHeight);
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom - this.legendHeight;
    //  moves the 'group' element to the top left margin
    this.svg
      .attr('width', this.size ? this.size : '100%')
      .attr('height', this.size ? this.size : '100%')
      .attr('viewBox', `0 0 ${(this.width + this.margin.left + this.margin.right)} ${(this.height + this.margin.top + this.margin.bottom + this.legendHeight)}`);

    this.svgG.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.bgImage.attr('xlink:href', airParticles)
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', this.height)
      .attr('width', this.width);
    this.packLayout = d3.pack()
      .size([this.width, this.height])
      .padding(10);
    this.color = d3.scaleOrdinal().domain(legends.map(d => d.fieldtitle)).range(legends.map(d => d.color));
    this.legendG.attr('transform', 'translate(' + this.margin.left + ',' + (this.height + this.margin.top + this.margin.bottom) + ')');
    this.createCircle(props);
    this.updateLegends(props);
  };

  createCircle = (props) => {
    const {pollutionData: {data}} = props;
    const rootNode = d3.hierarchy({
      'name': 'root',
      'children': data.sort((a, b) => a.value - b.value)
    });

    rootNode.sum(function(d) {
      return d.value;
    });

    this.packLayout(rootNode);
    const circleGSelection = this.circleG
      .selectAll('g.circle')
      .data(rootNode.descendants().filter(d => d.depth !== 0), d => d.depth);
    const newCircleG = circleGSelection
      .enter()
      .append('g');
    newCircleG.merge(circleGSelection)
      .attr('transform', d => `translate(${d.x},${d.y})`);

    circleGSelection
      .exit().remove();

    const circleSelection = newCircleG.merge(circleGSelection).selectAll('circle').data(d => [d], d => d.depth);
    const newCircle = circleSelection.enter().append('circle');
    newCircle.merge(circleSelection)
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', function(d) {
        return d.r;
      })
      .attr('fill', d => this.color(d.data.field));

    const textSelection = newCircleG.merge(circleGSelection).selectAll('text.title').data(d => [d], d => d.depth);
    const newText = textSelection.enter().append('text');
    newText.merge(textSelection)
      .attr('class', 'title')
      .text(function(d) {
        return d.data.title;
      })
      .style('font-size', function(d) {
        return Math.min(2 * d.r, (2 * d.r - 8) / this.getComputedTextLength() * 11) + 'px';
      })
      .attr('dy', '0.05em')
      .style('text-anchor', 'middle')
      .style('font-family', 'ProximaNova-Bold')
      .style('fill', '#ffffff')
      .style('fill-opacity', '0.5');
    const subtextSelection = newCircleG.merge(circleGSelection).selectAll('text.subtitle').data(d => [d], d => d.depth);
    const newSubtext = subtextSelection.enter().append('text');
    newSubtext.merge(subtextSelection)
      .attr('class', 'subtitle')
      .text(function(d) {
        return d.data.subtitle;
      })
      .style('font-size', function(d) {
        return Math.min(2 * d.r, (2 * d.r - 8) / this.getComputedTextLength() * 12) + 'px';
      })
      .attr('dy', '1.4em')
      .style('text-anchor', 'middle')
      .style('font-family', 'ProximaNova-Bold')
      .style('fill', '#ffffff')
      .style('fill-opacity', '0.5');
  };

  updateLegends = (props) => {
    const {pollutionData: {legends}} = props;
    // legends G enter, update, exit
    const rectHeight = 6;
    const rectWidth = 14;
    const legendG = this.legendG.selectAll('g.legend').data(legends, (d) => d && d.title);
    const newLegendG = legendG.enter().append('g');
    newLegendG.merge(legendG).attr('class', 'legend')
      .attr('transform', (d, i) => `translate(${this.margin.left},${this.spaceBetweenLegeds * i})`);
    legendG.exit().remove();
    // legends rect enter, update, exit
    const legendRect = newLegendG.merge(legendG).selectAll('rect').data((d) => [d], (d) => d && d.title);
    const newRect = legendRect.enter().append('rect');
    newRect.merge(legendRect)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', rectWidth)
      .attr('height', rectHeight)
      .attr('rx', (rectHeight / 2))
      .attr('ry', (rectWidth / 2))
      .attr('transform', `translate(0,-${rectHeight / 2})`)
      .style('fill', (d) => d.color);
    legendRect.exit().remove();
    // legends text enter, update, exit
    const legendText = newLegendG.merge(legendG).selectAll('text').data((d) => [d], (d) => d && d.title);
    const newText = legendText.enter().append('text');
    newText.merge(legendText)
      .attr('x', (rectWidth + 5))
      .attr('y', 0)
      .style('font-size', '12px')
      .attr('text-anchor', 'start')
      .attr('dy', '4px')
      .style('fill', '#424B54')
      .style('color', '#424B54')
      .style('font-family', 'ProximaNova-Regular')
      .text((d) => d.title && d.title);
    legendText.exit().remove();
  };

  render() {
    return (
      <div className='D3PollutionChart d-flex flex-column h-100 w-100'>
        <div className='d-flex justify-content-center align-items-center h-100 w-100'
             ref={(ele) => this.parentEle = ele}/>
      </div>
    );
  }
}

export default D3PollutionChart;
