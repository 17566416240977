import React, { Component } from 'react';
import D3AreaDeprivationScore from './D3AreaDeprivationScore';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3AreaDeprivationScoreWideChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legends: [{ label: 'Most Affluent', color: '#78A22F', range: [0, 20] },
        { label: '', color: '#6A8D73', range: [20, 40] },
        { label: 'Average', color: '#D5DED6', range: [40, 60] },
        { label: '', color: '#FFA987', range: [60, 80] },
        { label: 'Most Deprived', color: '#FF6B35', range: [80, 100] }
      ]
    };
  }

  render() {
    const { areaDeprivationScoreData, id, showTwitterCardPopup } = this.props;
    const { legends } = this.state;
    const middleContent = (
      <ul className="list-group mt-0 mt-md-5 legend">
        {legends.map((legend, index) => {
          return (
            <li key={index} className="list-group-item p-1">
              <div style={{ background: legend.color }}/>
              {legend.label}
            </li>
          );
        })}
      </ul>
    );

    const wideChartId = id + (showTwitterCardPopup ? 'D3AreaDeprivationScoreWideChart' : 'D3AreaDeprivationScoreTwitterChart');
    const content = (
      <div className='D3AreaDeprivationScoreWideChart d-flex w-100 h-100 '>
        <D3AreaDeprivationScore
          id={wideChartId} key={wideChartId}
          value={areaDeprivationScoreData.value}
          mainTitle={areaDeprivationScoreData.mainTitle}
          mainSubTitle={areaDeprivationScoreData.mainSubTitle}
          isWide={true}/>
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} middleContent={middleContent}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3AreaDeprivationScoreWideChart;
