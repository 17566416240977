import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  CopyCardToFavouritesMutation,
  RemoveCardFromFavouritesMutation,
} from "../../mutations";
import "../../css/animate.css";
import "../../css/bootstrap.min.css";
import "../../css/jquery-ui.css";

const heartImg = require("../../images/iconHeart-new.svg");
const heartFilled = require("../../images/iconHeartFilled-new.svg");

class CardBottom extends Component {
  listenScroll;

  constructor(props) {
    super(props);
    const isFavorite =
      props.bottomData.boardCardUse.isFavorite || props.isFavorite;
    this.state = {
      isFavourite: isFavorite,
      favouriteText:
        isFavorite === true ? "Saved to Favorites" : "Add to Favorites",
      animationBounceClass: "",
      isLoading: false,
      isLoginPopUpVisible: false,
    };
  }

  componentDidMount() {
    /*const button = ReactDOM.findDOMNode(this.cardBottom);*/
    const { hideToolTip } = this.props;
    const scrollDiv = document.querySelectorAll(".react-grid-layout");
    if (scrollDiv.length) {
      [].forEach.call(scrollDiv, (item) => {
        if (item && item.getAttribute("listener") !== "true") {
          item.setAttribute("listener", "true");
          this.listenScroll = item.addEventListener("wheel", (event) => {
            if (
              document.querySelector(".tooltipsvisibleContainer") &&
              hideToolTip
            ) {
              hideToolTip();
            }
          });
        }
      });
    }
  }

  componentWillUnmount() {
    /*const button = ReactDOM.findDOMNode(this.cardBottom);*/
    const scrollDiv = document.querySelectorAll(".react-grid-layout");
    if (scrollDiv.length) {
      [].forEach.call(scrollDiv, (item) => {
        item.setAttribute("listener", "");
        item.removeEventListener(
          "wheel",
          (event) => console.info(event.deltaY),
          false
        );
      });
    }
  }

  handleFavourite = () => {
    const { isLoading, isFavourite } = this.state;
    const {
      bottomData,
      createDataDownloadCard,
      isFavorites,
      handleUpdateBoard,
      handleReRenderCard,
      showToolTip,
      handleLoginPopUp,
    } = this.props;
    const cardId = bottomData.boardCardUse.id;
    if (this.testForLogin(true)) {
      return;
    }
    if (isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    if (isFavourite) {
      // Remove from favourites
      this.setState(
        {
          isFavourite: false,
          isLoading: false,
          favouriteText: "Add to Favorites",
        },
        () => {
          const bounds = ReactDOM.findDOMNode(
            this.refs["heart"]
          ).getBoundingClientRect();
          if (showToolTip != null) {
            showToolTip(bounds.x - 15, bounds.y + 30, "Add to Favorites");
          }
        }
      );
      RemoveCardFromFavouritesMutation(
        cardId,
        () => {
          if (isFavorites) {
            handleUpdateBoard();
          }
          if (handleReRenderCard) {
            handleReRenderCard(cardId);
          }
        },
        (err) => {
          if (
            err.errors &&
            err.errors.length &&
            err.errors[0].message === "UserLoginRequired"
          ) {
            //show login popup
            handleLoginPopUp();
          }
        },
        createDataDownloadCard
      );
    } else {
      this.setState({
        animationBounceClass: "animated bounceIn",
      });
      // Add to favourites
      this.setState(
        {
          isFavourite: true,
          isLoading: false,
          favouriteText: "Saved to Favorites",
        },
        () => {
          const bounds = ReactDOM.findDOMNode(
            this.refs["heart"]
          ).getBoundingClientRect();
          if (showToolTip != null) {
            showToolTip(bounds.x - 23, bounds.y + 21, "Saved to Favorites");
          }
        }
      );
      CopyCardToFavouritesMutation(
        cardId,
        () => {
          if (handleReRenderCard) {
            handleReRenderCard(cardId);
          }
        },
        (err) => {
          console.error(err);
          if (
            err.errors &&
            err.errors.length &&
            err.errors[0].message === "UserLoginRequired"
          ) {
            //show login popup
            handleLoginPopUp();
          }
        },
        createDataDownloadCard
      );
    }
  };
  showToolTipHeart = () => {
    const { showToolTip } = this.props;
    const { favouriteText } = this.state;
    const bounds = ReactDOM.findDOMNode(
      this.refs["heart"]
    ).getBoundingClientRect();
    if (showToolTip != null) {
      showToolTip(bounds.x - 15, bounds.y + 30, favouriteText);
    }
  };

  showToolTipAddMore = () => {
    const { isStoryCard, bottomData, data, showToolTip } = this.props;
    const id = isStoryCard ? bottomData.boardCardUse.id : data.node.id;
    const bounds = ReactDOM.findDOMNode(
      this.refs["addMore" + id]
    ).getBoundingClientRect();
    if (showToolTip != null) {
      showToolTip(bounds.x - 20, bounds.y + 30, "Share Card");
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSavedTooltip, showToolTip, hideToolTip } = this.props;
    if (nextProps.isSavedTooltip !== isSavedTooltip) {
      if (nextProps.isSavedTooltip) {
        if (
          ReactDOM.findDOMNode(this.refs["addMore" + nextProps.savedCardId])
        ) {
          const bounds = ReactDOM.findDOMNode(
            this.refs["addMore" + nextProps.savedCardId]
          ).getBoundingClientRect();
          if (showToolTip != null) {
            showToolTip(bounds.x - 20, bounds.y + 30, "Saved!");
          }
          setTimeout(() => {
            hideToolTip();
          }, 2000);
        }
      }
    }
  }

  showToolTipMore = () => {
    const { showToolTip } = this.props;
    const bounds = ReactDOM.findDOMNode(
      this.refs["more"]
    ).getBoundingClientRect();
    if (showToolTip != null) {
      showToolTip(bounds.x - 11, bounds.y + 30, "More");
    }
  };

  tagCrossClick = () => {
    const { isStoryCard, bottomData, data, hideUnHidePopUp } = this.props;
    const box = ReactDOM.findDOMNode(
      this.refs["moreButton"]
    ).getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;
    if (hideUnHidePopUp != null) {
      const id = isStoryCard ? bottomData.boardCardUse.id : data.node.id;
      const isSmallScreen = window.innerWidth < 768;
      const leftPopUp = isStoryCard && !isSmallScreen ? left + 75 : left - 100;
      hideUnHidePopUp(leftPopUp, top - 45, "MoreInfo", id);
    }
  };

  handleSelectThemePopUp = () => {
    const { handleSelectThemePopUp } = this.props;
    const box = ReactDOM.findDOMNode(
      this.refs["selectThemeButton"]
    ).getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    handleSelectThemePopUp(left, top);
  };

  handleSaveCardsPopUp = () => {
    const {
      handleSaveCardsPopUp,
      isStoryCard,
      bottomData,
      data,
      createDataDownloadCard,
    } = this.props;
    if (this.testForLogin(true)) {
      return;
    }
    const box = ReactDOM.findDOMNode(
      this.refs["saveCardButton"]
    ).getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    let left = box.left + scrollLeft - clientLeft;
    if (handleSaveCardsPopUp != null) {
      const id = isStoryCard ? bottomData.boardCardUse.id : data.node.id;
      const isSmallScreen = window.innerWidth < 768;
      left = isStoryCard && !isSmallScreen ? left + 230 : left;
      handleSaveCardsPopUp(left, top, id, null, createDataDownloadCard);
    }
  };

  testForLogin = (allowPopUp) => {
    const { userData, handleLoginPopUp } = this.props;
    if (
      userData == null ||
      userData.currentUser == null ||
      userData.currentUser.edges.length === 0
    ) {
      // prompt for login if needed
      if (
        allowPopUp &&
        handleLoginPopUp &&
        typeof handleLoginPopUp === "function"
      ) {
        handleLoginPopUp();
      }
      return true;
    } else {
      return false;
    }
  };

  checkLogin = (method, cardId, e) => {
    const { checkUserLogin } = this.props;
    const isLoggedIn = !this.testForLogin(false);
    if (isLoggedIn) {
      method(cardId, e);
      if (checkUserLogin) {
        checkUserLogin(false);
      }
    } else {
      if (checkUserLogin) {
        checkUserLogin(true);
      }
    }
  };

  render() {
    const { isFavourite, animationBounceClass, isLoading } = this.state;
    const {
      isStoryCard,
      bottomData,
      data,
      isEditingEnabled,
      className,
      hideToolTip,
      onCardButtonClick,
      showFullPage,
    } = this.props;
    const cardId = isStoryCard
      ? bottomData && bottomData.boardCardUse && bottomData.boardCardUse.id
      : data.node.id;
    const animateClass = isFavourite ? animationBounceClass : "";
    const id = isStoryCard ? bottomData.boardCardUse.id : data.node.id;

    return (
      <div
        className={
          "cardBottom text-center " +
          (isEditingEnabled ? " movable " : " clickable ") +
          (className ? className : "")
        }
        ref={(me) => (this.cardBottom = me)}
      >
        {!showFullPage ? (
          <div className="cardBottomButtons cardBottomSave">
            <span
              onClick={
                !isEditingEnabled
                  ? (e) => this.checkLogin(this.handleSaveCardsPopUp, cardId, e)
                  : null
              }
              className={"clickable " + (isEditingEnabled ? " movable" : "")}
              ref="saveCardButton"
            >
              <img
                className={
                  "cardBottomButtonsImages" +
                  (isEditingEnabled ? " disable-option-list" : "")
                }
                src={require("../../images/iconShare.svg")}
                alt=""
                onMouseOver={!isEditingEnabled ? this.showToolTipAddMore : null}
                onMouseOut={!isEditingEnabled ? hideToolTip : null}
                ref={"addMore" + id}
              />
            </span>
          </div>
        ) : null}

        {!showFullPage ? (
          <div className="cardBottomButtons cardBottomfavourites">
            <span
              onClick={
                !isEditingEnabled
                  ? (e) => this.checkLogin(this.handleFavourite, cardId, e)
                  : null
              }
              className={"clickable" + (isEditingEnabled ? " movable" : "")}
            >
              <img
                className={`cardBottomButtonsImages ${animateClass} ${
                  isEditingEnabled ? " disable-option-list" : ""
                }
                          ${isLoading ? " disable-heart-button" : ""}`}
                src={isFavourite ? heartFilled : heartImg}
                alt=""
                onMouseOver={!isEditingEnabled ? this.showToolTipHeart : null}
                onMouseOut={!isEditingEnabled ? hideToolTip : null}
                ref="heart"
              />
            </span>
          </div>
        ) : null}
        <div className="cardBottomButtons cardBottomMore">
          <span
            onClick={(e) => {
              if (!isEditingEnabled && onCardButtonClick) {
                this.checkLogin(onCardButtonClick, cardId, e);
              }
            }}
            className={"clickable" + (isEditingEnabled ? " movable" : "")}
            ref="moreButton"
          >
            <img
              className={
                "cardBottomButtonsImages" +
                (isEditingEnabled ? " disable-option-list" : "")
              }
              src={require("../../images/iconElipse-new.svg")}
              alt=""
              onMouseOver={!isEditingEnabled ? this.showToolTipMore : null}
              onMouseOut={!isEditingEnabled ? hideToolTip : null}
              ref="more"
            />
          </span>
        </div>
      </div>
    );
  }
}

export default CardBottom;
