import React, { Component } from "react";
import * as d3 from "d3";
import "../../css/components/Card/D3ProgressBarChart.css";

class D3ProgressBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 10, bottom: 0, left: 10 };
    this.rectHeight = 30;
    this.offSet = 5;
    this.clientWidth = 245;
    this.clientHeight = 135;
    this.duration = 1000;
    this.state = {
      data: {},
      ratio: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    const previousValue = data ? Math.round(data[1].value) : 0;
    const nextValue = nextProps.data ? Math.round(nextProps.data[1].value) : 0;
    if (previousValue !== nextValue) {
      const obj = nextProps.data[1];
      obj.value = Math.round(obj.value);
      this.setState({ data: obj }, () => {
        this.update(nextProps);
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 247) {
      this.setState({
        ratio: this.size / 247,
      });
    }
  };

  init = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.createSvg();
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 247) {
      this.setState({
        ratio: this.size / 247,
      });
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3ProgressBarChartSVG")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");

    const gProgress = this.svgG.selectAll("g.g-progress").data(
      (d) => [d],
      (d) => d
    );
    gProgress
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "g-progress")
      .attr("class", "g-progress");
    gProgress.exit().remove();
    this.progressG = d3.select("g#" + svgId + "g-progress");

    const gDashPathSelection = this.svgG
      .selectAll("g.dash-path-container")
      .data(
        (d) => [d],
        (d) => d
      );
    gDashPathSelection
      .enter()
      .append("g")
      .merge(gDashPathSelection)
      .attr("class", "dash-path-container")
      .attr("id", (d) => d + "dash-path-container");
    gDashPathSelection.exit().remove();
    this.svgDashPathG = d3.select("g#" + svgId + "dash-path-container");
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 247) {
      this.setState({
        ratio: this.size / 247,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.scaleLinear.range([this.rectHeight, this.width - this.rectHeight / 2]);
  };

  update = (props) => {
    const { min, max } = props;
    const minValue = min ? min : 0;
    const maxValue = max ? max : 100;
    this.scaleLinear = d3
      .scaleLinear()
      .domain([minValue, Math.round(maxValue)]);
    this.autoAlignSVG();
    this.updateCurrentProgress();
    this.createProgress(maxValue);
    this.createDashPath(props, maxValue);
  };

  animateNumber = (ele, value) => {
    const val = d3
      .select(ele)
      .selectAll("span.animate-title")
      .data([value], (d) => d);
    const newVal = val.enter().append("span");
    newVal
      .merge(val)
      .text(0)
      .attr("class", "animate-title")
      .transition()
      .duration(this.duration)
      .tween("text", function (d) {
        const self = this;
        const i = d3.interpolate(this.textContent, d);
        const prec = (d + "").split(".");
        const round = prec.length > 1 ? Math.pow(10, prec[1].length) : 1;

        return function (t) {
          d3.select(self).text(Math.round(i(t) * round) / round);
        };
      });
    val.exit().remove();
  };

  updateCurrentProgress() {
    const { value } = this.state.data;
    this.animateNumber(this.currentProgress, value);
  }

  roundedRect(x, y, width, height, rtr, rbr, rbl, rtl) {
    const radiusTopRight = rtr ? rtr : 0;
    const radiusBottomRight = rbr ? rbr : 0;
    const radiusBottomLeft = rbl ? rbl : 0;
    const radiusTopLeft = rtl ? rtl : 0;
    return (
      "M" +
      x +
      "," +
      y +
      "h" +
      (width - radiusTopRight) +
      "a" +
      radiusTopRight +
      "," +
      radiusTopRight +
      " 0 0 1 " +
      radiusTopRight +
      "," +
      radiusTopRight +
      "v" +
      (height - 2 * radiusBottomRight) +
      "a" +
      radiusBottomRight +
      "," +
      radiusBottomRight +
      " 0 0 1 " +
      -radiusBottomRight +
      "," +
      radiusBottomRight +
      "h" +
      (radiusBottomLeft - width) +
      "a" +
      radiusBottomLeft +
      "," +
      radiusBottomLeft +
      " 0 0 1 " +
      -radiusBottomLeft +
      "," +
      -radiusBottomLeft +
      "v" +
      -(height - 2 * radiusTopLeft) +
      "a" +
      radiusTopLeft +
      "," +
      radiusTopLeft +
      " 0 0 1 " +
      radiusTopLeft +
      "," +
      -radiusTopLeft +
      "z"
    );
  }

  createProgress = (max) => {
    const { data } = this.state;
    const { value } = data;
    const newData = value ? [max, value] : [max];
    this.progressG.attr("transform", `translate(${0}, ${this.height / 4})`);
    const pb = this.progressG.selectAll("path.pb-path").data(newData, (d) => d);
    const newBP = pb.enter().append("path");
    newBP
      .merge(pb)
      .attr("class", "pb-path")
      .attr("d", (d, i) => {
        const offSet = i ? this.offSet : 0;
        let x = this.rectHeight / 2 + offSet / 4;
        const y = 0 + offSet / 2;
        const h = this.rectHeight - offSet;
        const w = this.scaleLinear(this.scaleLinear.domain()[0]) + offSet;
        const radius = this.rectHeight / 2 - offSet / 2;
        const radiusRight =
          i === 0
            ? radius
            : this.scaleLinear(d) ===
              this.scaleLinear(this.scaleLinear.domain()[1])
            ? radius
            : 0;
        x = radiusRight === 0 ? offSet / 2 : x;
        return this.roundedRect(
          x,
          y,
          w,
          h,
          radiusRight,
          radiusRight,
          radius,
          radius
        );
      })
      .transition()
      .duration((d, i) => (i ? this.duration : 0))
      .attr("d", (d, i) => {
        const offSet = i ? this.offSet : 0;
        let x = this.rectHeight / 2 + offSet / 4;
        const y = 0 + offSet / 2;
        const h = this.rectHeight - offSet;
        const w = this.scaleLinear(d) - offSet;
        const radius = this.rectHeight / 2 - offSet / 2;
        const radiusRight =
          i === 0
            ? radius
            : this.scaleLinear(d) ===
              this.scaleLinear(this.scaleLinear.domain()[1])
            ? radius
            : 0;
        x = radiusRight === 0 ? offSet / 2 : x;
        return this.roundedRect(
          x,
          y,
          w,
          h,
          radiusRight,
          radiusRight,
          radius,
          radius
        );
      })
      .style("fill", (d, i) => (i ? "#00939D" : "none"))
      .style("stroke", (d, i) => (i ? "none" : "#000000"))
      .style("stroke-width", (d, i) => (i ? "0" : "1px"));
    pb.exit().remove();

    const valLabel = this.progressG
      .selectAll("text.val")
      .data([{ value }], (d) => d.value);
    const newValLabel = valLabel.enter().append("text");
    newValLabel
      .merge(valLabel)
      .text((d) => d.value + "%")
      .attr("class", "val")
      .attr("x", (d) => {
        return this.rectHeight / 2;
      })
      .attr("y", this.rectHeight / 2)
      .attr("dy", 1)
      .style("fill", "#83ccd1")
      .style("text-anchor", "start")
      .style("dominant-baseline", "middle")
      .style("font-family", "ProximaNova-Bold")
      .style("font-size", "16px");
    valLabel.exit().remove();
  };

  createDashPath(props) {
    if (props.refData) {
      const { label, value } = props.refData;
      const y = this.rectHeight * 2;
      this.svgDashPathG
        .attr("transform", `translate(0, ${this.rectHeight})`)
        .transition()
        .duration(this.duration)
        .attr(
          "transform",
          `translate(${this.scaleLinear(value) - 3}, ${this.rectHeight})`
        );
      const lineG = this.svgDashPathG
        .selectAll("path.dash")
        .data([{ value }], (d) => d.value);
      const newLineG = lineG.enter().append("path");
      newLineG
        .merge(lineG)
        .attr("class", "dash")
        .attr("d", `M0,${-2} L0,${y}`)
        .style("stroke", "#424B54")
        .style("stroke-width", "1px")
        .style("stroke-dasharray", "3,3");
      lineG.exit().remove();
      const valLabel = this.svgDashPathG
        .selectAll("text.val")
        .data([{ value }], (d) => d.value);
      const newValLabel = valLabel.enter().append("text");
      newValLabel
        .merge(valLabel)
        .text((d) => Math.round(d.value) + "%")
        .attr("class", "val")
        .attr("dx", 5)
        .attr("dy", y + 15)
        .attr("fill", "#424B54")
        .style("text-anchor", "middle")
        .style("font-family", "ProximaNova-Regular")
        .style("font-size", "15px");
      valLabel.exit().remove();
      const titleLabel = this.svgDashPathG
        .selectAll("text.title")
        .data([{ label }], (d) => d.label);
      const newTitleLabel = titleLabel.enter().append("text");
      newTitleLabel
        .merge(titleLabel)
        .text((d) => d.label)
        .attr("class", "title")
        .attr("dx", 0)
        .attr("dy", y + 28)
        .style("fill", "#424B54")
        .style("text-anchor", "middle")
        .style("font-family", "ProximaNova-Regular")
        .style("font-size", "12px");
      titleLabel.exit().remove();
    }
  }

  render() {
    const { subText } = this.props;
    const { ratio } = this.state;
    const valueDiv = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return (
      <div
        ref={(ele) => (this.mainEle = ele)}
        className="D3ProgressBarChart d-flex justify-content-center h-100 w-100 align-items-center"
      >
        <div
          className="d-flex flex-column"
          style={{
            height: "217px",
            width: "247px",
            transform: `scale(${ratio})`,
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            ref={(ele) => (this.parentEle = ele)}
          />
          <div className="d-flex mx-0">
            <div className="h-100 w-50 pr-4" style={valueDiv}>
              <div className="main-text text-center">
                <span ref={(ele) => (this.currentProgress = ele)} />
                <span>%</span>
              </div>
            </div>
            <div className="h-100 w-50 pr-4" style={valueDiv}>
              <div
                className="details-text text-left"
                dangerouslySetInnerHTML={{ __html: subText }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3ProgressBarChart;
