import React, { Component, Fragment } from 'react';
import { Responsive } from 'react-grid-layout';
import WidthProvider from '../ProjectBoard/ProjectBoardWidthProvider';
import { commitMutation, QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import ReportCardContainer from '../Card/ReportCardContainer';
import AdiMapCardContainer from '../Card/AdiMapCardContainer';
import DotMapCardContainer from '../Card/DotMapCardContainer';
import ServiceAreaMapCardContainer from '../Card/ServiceAreaMapCardContainer';
import IndicatorMapCardContainer from '../Card/IndicatorMapCardContainer';
import ChartCardContainer from '../Card/ChartCardContainer';
import StoryCardContainer from '../Card/StoryCardContainer';
import { ToolTip, AnnouncementPopup } from 'common-components';
import FooterComponent from '../CommonComponents/FooterComponent';
import ReportToolCardContainer from '../Card/ReportToolCardContainer';
import '../../css/components/Stories/Stories.css';
import { reportToolCardArray } from '../../utility/utilities';
import PageHeaderSection from '../CommonComponents/PageHeaderSection';
import RunAReport from '../RunAReport/RunAReport';
import CardButtonsPopUp from '../CommonComponents/CardButtonsPopUp';
import * as _ from 'lodash';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const linkArray = [
  { name: 'stories', label: 'My Stories', redirectLink: '#stories' },
  { name: 'sharedStories', label: 'Shared Stories', redirectLink: '#sharedStories' },
  { name: 'templates', label: 'Templates', redirectLink: '#templates' }
];

class Stories extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
    refreshPage: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: '',
      isUpdateBoard: false, // Change this to call the board list api
      openedCardsArray: [],
      boardUpdates: 0,
      showMyTemplates: false
    };
  }

  handleStoryVisiblity = (storyId) => {
    const { isStoryVisible } = this.state;
    if (isStoryVisible === true) {
      this.setState({
        isStoryVisible: false,
        storyId: null
      });
    } else {
      this.setState({
        isStoryVisible: true,
        storyId: storyId
      });
    }
  };

  handleUpdateBoard = (refreshPage = false) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: prevState.isUpdateBoard === true ? false : true,
        boardUpdates: prevState.boardUpdates + 1,
        refreshPage
      };
    });
  };

  onTextChange = (value) => {
    this.setState({ searchTerm: value });
  };

  storyList = (showMyTemplates, sharedWithMe) => {
    const { refreshPage, searchTerm, boardUpdates, isUpdateBoard } = this.state;
    const {
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      isLeftMenuOpen,
      userData,
      history
    } = this.props;
    return (
      <QueryRenderer key={boardUpdates + String(showMyTemplates) + String(sharedWithMe)}
                     dataFrom={refreshPage ? 'STORE_THEN_NETWORK' : ''}
                     environment={environment}
                     query={graphql`
                                    query  StoriesQuery($reportTemplatesOnly: Boolean, $excludeReportTemplates: Boolean, $search:String, $sharedWithMe:Boolean)
                                    {  storiesBoard
                                        {  sections
                                            {
                                                edges
                                                {
                                                    node
                                                    {
                                                        boardCardUses(reportTemplatesOnly: $reportTemplatesOnly, excludeReportTemplates: $excludeReportTemplates, search: $search, sharedWithMe: $sharedWithMe)
                                                        {
                                                            edges
                                                            {
                                                                node
                                                                {
                                                                    id
                                                                    cardPosition
                                                                    frontFacingComponent
                                                                    {
                                                                        componentType
                                                                        name
                                                                        id
                                                                    }
                                                                    isFavorite
                                                                    subComponentType
                                                                }
                                                            }
                                                        }
                                                        id
                                                        name
                                                        description
                                                        layout
                                                        sectionPosition
                                                    }
                                                }
                                            }
                                            id
                                            name
                                            subtitle
                                            theme
                                            {
                                                jsonStyleProperties
                                            }
                                        }
                                    }
                                `}
                     variables={{
                       isUpdateBoard,
                       reportTemplatesOnly: showMyTemplates === true,
                       excludeReportTemplates: showMyTemplates !== true,
                       search: searchTerm,
                       sharedWithMe: sharedWithMe
                     }}
                     render={({ error, props }) => {
                       if (error) {
                         console.error(error);
                         return <div>Error!</div>;
                       }
                       if (!props) {
                         return <div>Loading...</div>;
                       }
                       return <StoriesSection
                         data={props}
                         openStoryPopup={openStoryPopup}
                         openStoryPopupWithUrl={openStoryPopupWithUrl}
                         openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                         handleUpdateBoard={this.handleUpdateBoard}
                         isLeftMenuOpen={isLeftMenuOpen}
                         userData={userData}
                         history={history}
                         isTemplate={showMyTemplates}
                         searchTerm={searchTerm}
                         handleLoginPopUp={this.handleLoginPopUp}/>;
                     }}
      />
    );
  };

  render() {
    const {
      isLeftMenuOpen,
      handleBrowseAllStories,
      handleExploreMapsAndData,
      userData,
      openStoryPopup,
      openCardFamilyPopupWithUrl,
      openStoryPopupWithUrl,
      handleLoginPopUp,
      history,
    } = this.props;
    const { searchTerm, isStoryVisible } = this.state;
    if (userData.currentUser.edges.length === 0) {
      return <div><p>You need to log in as a user with permissions to create favorites.</p></div>;
    }

    return (
      <Fragment>
        <div
          className={isStoryVisible === true ? 'stories footer-margin projectBoard-right-component withStory pb-0' : 'stories footer-margin projectBoard-right-component pb-0'}>
          {/* <button onClick={this.removeCard}>Remove card</button> */}
          <div className="project-board-home-wrapper container">
            <PageHeaderSection
              linkArray={linkArray}
              titleIcon="icon-save"
              title="Stories"
              placeholderText="Search Stories & Templates"
              onTextChange={this.onTextChange}
            />

            <h2 className="section-header-title d-block text-left pb-5 pl-5 pl-sm-0" id="stories">
              My Stories
            </h2>

            {this.storyList(false, false)}

            <h2 className="section-header-title d-block text-left pb-5 pt-5 pl-5 pl-sm-0"
                id="sharedStories">
              Stories Shared with Me
            </h2>

            {this.storyList(false, true)}

            <PageHeaderSection
              titleIcon="icon-template"
              title="Templates"
              id="templates"
              hideSearch={true}
            />

            <h4 className="section-header-title d-block text-left pb-5 pl-5 pl-sm-0"
                id="sharedStories">
              My Templates
            </h4>
            {this.storyList(true, false)}
            <h4 className="section-header-title d-block text-left pb-5 pt-5 pl-5 pl-sm-0"
                id="sharedStories">
              BroadStreet Templates
            </h4>
            <RunAReport
              isLeftMenuOpen={isLeftMenuOpen}
              handleBrowseAllStories={handleBrowseAllStories}
              handleExploreMapsAndData={handleExploreMapsAndData}
              userData={userData}
              openStoryPopup={openStoryPopup}
              openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
              openStoryPopupWithUrl={openStoryPopupWithUrl}
              handleLoginPopUp={handleLoginPopUp}
              handleUpdate={this.handleUpdateFavorites}
              history={history}
              searchTerm={searchTerm}
            />
          </div>
        </div>
        <FooterComponent/>
      </Fragment>
    );
  }
}

export default Stories;

class StoriesSection extends Component {

  render() {
    const {
      data,
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      handleUpdateBoard,
      isLeftMenuOpen,
      userData,
      history,
      isTemplate,
      handleLoginPopUp
    } = this.props;
    return (
      <div>
        {data && data.storiesBoard && data.storiesBoard.sections.edges.map((section, index) => {
          return (
            <div key={index}>
              {
                index === 0 ? null : <div className="recomm-wrapper">
                  <div className="recomm-header-wrap">
                    <h2 className="recomm-hd-title">{section.name}</h2>
                  </div>
                </div>
              }
              <StoriesCards
                section={section}
                openStoryPopup={openStoryPopup}
                openStoryPopupWithUrl={openStoryPopupWithUrl}
                openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                handleUpdateBoard={handleUpdateBoard}
                isLeftMenuOpen={isLeftMenuOpen}
                userData={userData}
                history={history}
                handleLoginPopUp={handleLoginPopUp}
                isTemplate={isTemplate}
              />
            </div>);
        })}

      </div>
    );
  }
}

class StoriesCards extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: '',

      xOriginSaveCardsPopUp: 0,
      yOriginSaveCardsPopUp: 0,
      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: '',

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isEditHashTagPopUpVisible: false,
      xOriginForEditHashTagPopUp: 0,
      yOriginForEditHashTagPopUp: 0,

      saveCardFromMore: false,
      data: [],
      openedCardsArray: [],

      isSavedTooltip: false,
      savedCardId: '',

      isDeleteMeAsACollaboratorPopUpVisible: false,
      isDeleteCollectionPopUpVisible: false
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    const cardsArray = [];
    const { section } = this.props;
    section && section.node.boardCardUses && section.node.boardCardUses.edges.map((item) => {
      const mutableObject = Object.assign({}, item);
      mutableObject.isUpdate = false;
      cardsArray.push(mutableObject);
      return cardsArray;
    });
    this.setState({
      data: cardsArray
    });
    document.addEventListener('mousedown', this.handleFavPage, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { section } = this.props;
    if (!_.isEqual(nextProps.section, section)) {
      let cardsArray = [];
      nextProps.section.node.boardCardUses.edges.map((item) => {
        let mutableObject = Object.assign({}, item);
        mutableObject.isUpdate = false;
        cardsArray.push(mutableObject);
        return cardsArray;
      });
      this.setState({
        data: cardsArray
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleFavPage, false);
  }

  handleFavPage = (e) => {
    if (this.favPageNode.contains(e.target)) {
      return;
    } else {
    }
    this.handleClickOutsideFavPage();
  };

  handleClickOutsideFavPage = (event) => {
    this.hidePopUp();
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      const { subComponentType } = edge.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;

      return {
        'x': index % columns,
        'y': Math.floor(index / columns),
        'w': 1,
        'h': reportToolCard ? 5.3 : 11,
        'i': edge.node.id
      };
    });
    return layout;
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({ isToolTipVisible: true, xOrigin: x, yOrigin: y, text: text });
  };

  hideToolTip = () => {
    this.setState({ isToolTipVisible: false, isSavedTooltip: false, savedCardId: '' });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    var escapedCardId = cardId.replace(/=/g, '\\=').replace('/', '\\/').replace('+', '\\+');
    var el = document.querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + (cardHeight / 40);

    var newOpenedCardsArray = openedCardsArray;
    var pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({ i: cardId, h: cardHeightInGrid });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  applyOpenedCardHeights = () => {
    const { data, openedCardsArray } = this.state;
    var sectionLayouts = this.buildAllSectionLayouts(data);
    var arrayCardPos = sectionLayouts.map((layout, j) => {
      return layout.map((item, index) => {
        for (var i = 0; i < openedCardsArray.length; i++) {
          if (openedCardsArray[i].i === item.i) {
            return {
              i: item.i,
              w: item.w,
              h: openedCardsArray[i].h,
              x: item.x,
              y: item.y
            };
          }
        }
        return item;
      });
    });
    return arrayCardPos;
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    this.setState({
      isSaveCardsPopUpVisible: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId)
    });
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;

    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({ isSaveCardsPopUpVisible: false, isSavedTooltip, savedCardId });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { section } = this.props;
    if (popUpType === 'CloseTag') {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId
      });
    } else if (popUpType === 'MoreInfo') {
      let selectedCard = null;
      section.node.boardCardUses.edges.forEach((item, index) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
      });

      this.setState({
        selectedCard,
        isToolTipVisible: false,
        selectedCardForMutation: cardId
      });
    }
    else if (popUpType === 'EditTags') {
      this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId
      });
    }
  };

  handleReRenderCard = (cardId) => {
    const { data } = this.state;
    const arrayData = [];
    const checkId = cardId;
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === checkId) {
        var changedObject = data[i];
        changedObject.isUpdate === true ? changedObject.isUpdate = false : changedObject.isUpdate = true;
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState({
      data: arrayData
    }, () => this.hidePopUp());
  };

  setSelectedCardForMutation = (id) => {
    this.setState({ selectedCardForMutation: id });
  };

  handleDeleteMeAsACollaboratorPopUpVisible = () => {
    const { isDeleteMeAsACollaboratorPopUpVisible } = this.state;
    if (isDeleteMeAsACollaboratorPopUpVisible === true) {
      this.setState({
        isDeleteMeAsACollaboratorPopUpVisible: false
      });
    } else {
      this.setState({
        isDeleteMeAsACollaboratorPopUpVisible: true,
      });
    }
  };

  handleRemoveSelfFromCollaborators = (object, index) => {
    this.handleDeleteMeAsACollaboratorPopUpVisible();
    this.setState({
      deleteMeAsACollaboratorObject: object,
      deleteMeAsACollaboratorIndex: index
    });
  };

  handleDeleteMeAsACollaborator = () => {
    const { deleteMeAsACollaboratorObject, selectedCardForMutation } = this.state;
    const { handleUpdateBoard } = this.props;
    const mutation = graphql`
          mutation StoriesHMutation($input: DeleteBoardCollaboratorInput!) {
            deleteBoardCollaborator(input: $input) {
              boardNode {
                id
                collaborators {
                  edges {
                    node {
                      id
                      name
                      userAccount {
                        email
                      }
                      profileImage {
                        id
                        caption
                        url
                      }
                    }
                  }
                }
                invitations {
                  edges {
                    node {
                      email
                    }
                  }
                }
              }
            }
          }
        `;

    let boardAuthorId = deleteMeAsACollaboratorObject.id;
    const variables = {
      input: {
        boardId: selectedCardForMutation,
        boardAuthorId: boardAuthorId,
        email: deleteMeAsACollaboratorObject.userAccount.email
      }
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.setState({ selectedCardForMutation: '' });
        handleUpdateBoard(true);
      },
      onError: err => console.error(' Collaborators error', err)
    });
  };

  handleDeleteCollectionPopUpVisible = () => {
    const { isDeleteCollectionPopUpVisible } = this.state;
    if (isDeleteCollectionPopUpVisible === true) {
      this.setState({
        isDeleteCollectionPopUpVisible: false
      });
    } else {
      this.setState({
        isDeleteCollectionPopUpVisible: true,
      });
    }
  };

  handleDeleteCollection = () => {
    const { selectedCardForMutation } = this.state;
    const { handleUpdateBoard } = this.props;
    const mutation = graphql`
      mutation StoriesCMutation($input: DeleteBoardInput!) {
        deleteBoard(input: $input) {
          boardDeleted
        }
      }
    `;
    const variables = { input: { boardId: selectedCardForMutation } };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.setState({
          isDeleteCollectionPopUpVisible: false,
          selectedCardForMutation: ''
        });
        handleUpdateBoard(true);
      },
      onError: err => console.error(err)
    });
  };

  getSelectedCard = (cardId) => {
    const { section } = this.props;
    let selectedCard = null;
    if (section && section.node && section.node.boardCardUses && section.node.boardCardUses.edges) {
      selectedCard = section.node.boardCardUses.edges.find((item) => item.node.id === cardId);
    }
    return selectedCard;
  };

  onCardButtonClick = (cardId) => {
    this.setState({
      showCardShareModal: false,
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId)
    });
  };

  onCloseCardButtonModal = () => {
    this.setState({ showCardButtonModal: false,isSaveCardsPopUpVisible: false });
  };

  render() {
    const {
      isTemplate,
      isLeftMenuOpen,
      handleUpdateBoard,
      openCardFamilyPopupWithUrl,
      userData,
      handleLoginPopUp,
      history,
      openStoryPopupWithUrl,
      openStoryPopup
    } = this.props;
    const {
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      selectedCard,
      data,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
      isDeleteCollectionPopUpVisible,
      isDeleteMeAsACollaboratorPopUpVisible
    } = this.state;
    let layoutArray = this.applyOpenedCardHeights();
    return (
      <div onClick={() => this.hidePopUp()} ref={favPageNode => this.favPageNode = favPageNode}>
        {data.length ? <ResponsiveReactGridLayout
            className={isLeftMenuOpen ? 'rgl-menu-open' : 'rgl-menu-closed'}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            breakpoints={{ lg: 1350, md: 1050, sm: 768, xs: 480, xxs: 0 }}
            rowHeight={30}
            layouts={{
              lg: layoutArray[3],
              md: layoutArray[3],
              sm: layoutArray[2],
              xs: layoutArray[1],
              xxs: layoutArray[0]
            }}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            isDraggable={false}
            margin={[15, 10]}
            containerPadding={[0, 0]}
            {...this.props}
          >
          {
            data.map((item, index) => {
              if (item.node.frontFacingComponent.componentType === 'ReportCard') {
                return (
                  <div key={item.node.id}>
                    <ReportCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={false}
                      isFavorites={true}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id} data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      onCardButtonClick={this.onCardButtonClick}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                    />
                  </div>
                );
              } else if (item.node.frontFacingComponent.componentType === 'AdiMapCard') {
                return (
                  <div key={item.node.id}>
                    <AdiMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (item.node.frontFacingComponent.componentType === 'ServiceAreaMapCard') {
                return (
                  <div key={item.node.id}>
                    <ServiceAreaMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (item.node.frontFacingComponent.componentType === 'IndicatorMapCard') {
                return (
                  <div key={item.node.id}>
                    <IndicatorMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (item.node.frontFacingComponent.componentType === 'DotMapCard') {
                return (
                  <div key={item.node.id}>
                    <DotMapCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapVisible={true}
                      userData={userData}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else if (item.node.frontFacingComponent.componentType === 'ChartCard') {
                // else if (true) {
                const { subComponentType } = item.node;
                const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;
                if (reportToolCard) {
                  return (<div key={item.node.id} className="grid-card">
                    <ReportToolCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      subComponentType={item.node.subComponentType}
                      statistics={item.node.statistics}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      handleUpdateBoard={handleUpdateBoard}
                      isMapcardsVisible={true}
                      userData={userData}
                      history={history}
                      handleLoginPopUp={handleLoginPopUp}
                      openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      setSelectedCardForMutation={this.setSelectedCardForMutation}
                      handleDeleteCollectionPopUpVisible={this.handleDeleteCollectionPopUpVisible}
                      handleRemoveSelfFromCollaborators={this.handleRemoveSelfFromCollaborators}
                      onCardButtonClick={this.onCardButtonClick}
                      fromPage={'stories'}
                    />
                  </div>);
                } else {
                  return (
                    <div key={item.node.id}>
                      <ChartCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isHomeFeed={true}
                        isFavorites={false}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapcardsVisible={true}
                        userData={userData}
                        history={history}
                        handleLoginPopUp={handleLoginPopUp}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleReRenderCard={this.handleReRenderCard}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                }
              } else if (item.node.frontFacingComponent.componentType === 'MapCard') {
                // else if (true) {
                return (
                  <div key={item.node.id}>
                    <StoryCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id}
                      data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={true}
                      handleUpdateBoard={handleUpdateBoard}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      userData={userData}
                      onCardButtonClick={this.onCardButtonClick}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={item.node.id}>
                    <StoryCardContainer
                      changeCardHeight={this.changeCardHeight}
                      isEditingEnabled={false}
                      isHomeFeed={true}
                      isFavorites={false}
                      isCloseUpView={false}
                      index={index}
                      key={item.node.id} data={item}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      onCloseUpView={this.handleCloseUpView}
                      handleSelectThemePopUp={this.handleSelectThemePopUp}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      newTagsForCardObject={newTagsForCardObject}
                      arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                      handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
                      selectedCardForMutation={selectedCardForMutation}
                      isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                      isMapcardsVisible={false}
                      handleUpdateBoard={handleUpdateBoard}
                      openStoryPopup={openStoryPopup}
                      openStoryPopupWithUrl={openStoryPopupWithUrl}
                      isSavedTooltip={isSavedTooltip}
                      savedCardId={savedCardId}
                      handleReRenderCard={this.handleReRenderCard}
                      userData={userData}
                      setSelectedCardForMutation={this.setSelectedCardForMutation}
                      handleDeleteCollectionPopUpVisible={this.handleDeleteCollectionPopUpVisible}
                      handleRemoveSelfFromCollaborators={this.handleRemoveSelfFromCollaborators}
                      onCardButtonClick={this.onCardButtonClick}
                      fromPage={'stories'}
                    />
                  </div>
                );
              }
            })
          }
            </ResponsiveReactGridLayout>
            :
            <div>No Cards found.</div>
        }
        <ToolTip x={xOrigin}
        y={yOrigin}
        isVisible={isToolTipVisible}
        text={text}
        />
        {showCardButtonModal || isSaveCardsPopUpVisible ? <CardButtonsPopUp
          history={history}
          showToolTip={this.showToolTip}
          hideToolTip={this.hideToolTip}
          handleReRenderCard={this.handleReRenderCard}
          isCollections={false}
          displayPageName={isSaveCardsPopUpVisible ? 'share-card' : ''}
          disabledDownloadButton={true}
          isFavorites={true}
          isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
          cardId={selectedCardForMutation}
          selectedCard={selectedCard}
          closePopUp={this.onCloseCardButtonModal}
          handleShareCardPopUp={this.handleShareCardPopUp}
        /> : null
        }
        {isDeleteCollectionPopUpVisible === true ? (
          <AnnouncementPopup
            closePopUp={this.handleDeleteCollectionPopUpVisible}
            onOkClick={this.handleDeleteCollection}
            announcementMessage={isTemplate ? 'This will permanently delete this template for you and others.' : 'This will permanently delete this story for you, your collaborators, and all embedded links.'}
            announcementPrompt={'Are you sure?'}
            okButtonLabel={'Delete'}
            closeButtonLabel={'Cancel'}
            title={isTemplate ? 'Delete Template' : 'Delete Story'}
          />
        ) : null
        }

        {isDeleteMeAsACollaboratorPopUpVisible === true ? (
          <AnnouncementPopup
            closePopUp={this.handleDeleteMeAsACollaboratorPopUpVisible}
            onOkClick={this.handleDeleteMeAsACollaborator}
            announcementMessage={'Only the owner of a story can delete the story. <br/><br/> This will delete you as a collaborator.'}
            announcementPrompt={'Are you sure?'}
            okButtonLabel={'Yes'}
            closeButtonLabel={'Cancel'}
            title={'Delete Me As Collaborator'}
          />
        ) : null}

      </div>
    );
  }
}
