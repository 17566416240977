import React, { Component } from 'react';
import '../../css/admin.settings.css';
import '../../css/themify-icons/css/themify-icons.css';
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';

class PasswordPageContent extends Component {
  constructor() {
    super();
    this.state = {
      isWorking: false,
      saveKey: 0,
      wasError: false,
      wasSaved: false,
      wasWarning: false
    };
  }

  updateData = (pass, newpass1, newpass2) => {
    const { isWorking } = this.state;
    if (isWorking) {
      return;
    }

    if (!newpass1 || !newpass2 || !pass) {
      this.setState({ isWorking: false, wasSaved: false, wasError: false, wasWarning: false, wasPasswordError: true });
      return;
    }

    if (newpass1 !== newpass2) {
      this.setState({ isWorking: false, wasSaved: false, wasError: true, wasWarning: true });
      return;
    }
    this.setState({ isWorking: true, wasSaved: false, wasError: false, wasWarning: false, wasPasswordError: false });
    const mutation = graphql`
          mutation  
          PasswordPageContent1Mutation($input: UpdateUserAccountInput!)  
            {  updateUserAccount (input:$input)  
              {  userAccountNode 
                { 
                userImage
                {
                    url
                }
                email
              }
                validationErrors  
                {  
                  key           
                  message  
                } 
              }  
            } 
        `;
    const input = {
      'userNewPassword': newpass1,
      'userOldPassword': pass
    };

    commitMutation(
      environment,
      {
        mutation,
        variables: { input },
        onCompleted: (response, errors) => {
          // console.log('Profile update response from server.', JSON.stringify(response));
          // check if you are getting error messages then reload the component.
          if (response.updateUserAccount.validationErrors != null &&
            response.updateUserAccount.validationErrors.length > 0) {
            this.setState(prevState => {
              return { isWorking: false, wasError: true, wasSaved: false, wasWarning: false };
            });
          } else {
            this.setState(prevState => {
              return {
                isWorking: false,
                saveKey: prevState.saveKey + 1,
                wasSaved: true,
                wasError: false,
                wasWarning: false
              };
            });
          }
        },
        onError: (err) => {
          console.error(JSON.stringify(err));
          //alert('Profile update failed, try again.');
          this.setState(prevState => {
            return { isWorking: false, wasError: true, wasSaved: false, wasWarning: false };
          });
          // TODO: Show error message
        }
      }
    );
  };

  render() {
    const { saveKey, wasError, wasWarning, wasSaved, isWorking, wasPasswordError } = this.state;
    return (<PasswordPageForm key={saveKey} onSave={this.updateData} isWorking={isWorking}
      wasError={wasError} wasSaved={wasSaved} wasPasswordError={wasPasswordError}
      wasWarning={wasWarning} />);

  }
}

class PasswordPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass: '',
      newpass1: '',
      newpass2: ''
    };
  }

  updateFormData = () => {
    const { onSave } = this.props;
    const { pass, newpass1, newpass2 } = this.state;
    onSave(pass, newpass1, newpass2);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { pass, newpass1, newpass2 } = this.state;
    const { wasSaved, wasError, wasWarning, isWorking, wasPasswordError } = this.props;
    return (
      <div className="tab-pane fade active show" id="accordion-2">
        {/* <div className="text-left mb-5">
          <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
        <div className="ibox-head">
          <h3 className="font-weight-bold">Password</h3>
        </div>
        <br />
        {wasSaved ?
          <div className="alert alert-success alert-dismissible show-alert" role="alert">Saved</div>
          : null}
        {wasError ?
          <div className="alert alert-danger alert-dismissible show-alert" role="alert">Failed to
            Save</div>
          : null}
        {wasPasswordError && (!pass || !newpass1 || !newpass2) ?
          <div className="alert alert-danger alert-dismissible show-alert p-3" role="alert">
            {`Please enter ${!pass ? 'current password' : !newpass1 ? 'new password' : !newpass2 ? 'confirm new password' : ''}`}
          </div>
          : null}
        {wasWarning ?
          <div className="alert alert-warning alert-dismissible show-alert" role="alert">Passwords
          do not match</div>
          : null}
        <div className="row passwordForm">
          <div className="col-lg-3">
            <div className="flex-1">
              <div className="media-heading">Change Password</div>
              <small className="text-muted">
                Reset your current password to a new one
              </small>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-6 form-group mb-4">
                <label>Current Password</label>
                <input onChange={this.handleChange} name="pass" className="form-control"
                  type="password" />
              </div>
              <div className="col-sm-6 form-group mb-4">
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group mb-4">
                <label>New Password</label>
                <input onChange={this.handleChange} name="newpass1" className="form-control"
                  type="password" />
              </div>
              <div className="col-sm-6 form-group mb-4">
                <label>Confirm New Password</label>
                <input onChange={this.handleChange} name="newpass2" className="form-control"
                  type="password" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <button className="btn btn-outline-blue btn-fix"
                  onClick={this.updateFormData}>{isWorking ? 'SAVING' : 'Change Password'}</button>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div className="row">
          <div className="col-sm-1">
          </div>
          <div className="col-sm-10">
            {/*
                    <div className="social-login">
                        <a href="#"> <img src={require("../../images/google.png")}/></a>&nbsp; Login
                        with Google </div>
                    <br/>
                    <div className="social-login">
                        <button className="btn btn-soc-facebook btn-icon-only btn-circle"><i className="fa fa-facebook"></i></button>&nbsp;
                        Login with Facebook </div>
                */}
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordPageContent;
