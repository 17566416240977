/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserProfileImageInput = {|
  imageId?: ?string,
  clientMutationId?: ?string,
|};
export type AccountOverview1MutationVariables = {|
  input: UpdateUserProfileImageInput
|};
export type AccountOverview1MutationResponse = {|
  +updateUserProfileImage: ?{|
    +userAccountNode: ?{|
      +userImage: ?{|
        +url: ?string
      |},
      +email: ?string,
    |},
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
  |}
|};
export type AccountOverview1Mutation = {|
  variables: AccountOverview1MutationVariables,
  response: AccountOverview1MutationResponse,
|};
*/


/*
mutation AccountOverview1Mutation(
  $input: UpdateUserProfileImageInput!
) {
  updateUserProfileImage(input: $input) {
    userAccountNode {
      userImage {
        url
        id
      }
      email
      id
    }
    validationErrors {
      key
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserProfileImageInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "validationErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountOverview1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserProfileImagePayload",
        "kind": "LinkedField",
        "name": "updateUserProfileImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNode",
            "kind": "LinkedField",
            "name": "userAccountNode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileImageNode",
                "kind": "LinkedField",
                "name": "userImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountOverview1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserProfileImagePayload",
        "kind": "LinkedField",
        "name": "updateUserProfileImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNode",
            "kind": "LinkedField",
            "name": "userAccountNode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileImageNode",
                "kind": "LinkedField",
                "name": "userImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountOverview1Mutation",
    "operationKind": "mutation",
    "text": "mutation AccountOverview1Mutation(\n  $input: UpdateUserProfileImageInput!\n) {\n  updateUserProfileImage(input: $input) {\n    userAccountNode {\n      userImage {\n        url\n        id\n      }\n      email\n      id\n    }\n    validationErrors {\n      key\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca1d486b6731be25b0fdd76315e7dce6';

module.exports = node;
