/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateBoardSectionInput = {|
  boardId: string,
  name: string,
  description?: ?string,
  sectionLayout?: ?string,
  sectionPosition?: ?number,
  clientMutationId?: ?string,
|};
export type ProjectBoardAMutationVariables = {|
  input: CreateBoardSectionInput
|};
export type ProjectBoardAMutationResponse = {|
  +createBoardSection: ?{|
    +boardSectionNode: ?{|
      +id: string,
      +sectionPosition: number,
    |}
  |}
|};
export type ProjectBoardAMutation = {|
  variables: ProjectBoardAMutationVariables,
  response: ProjectBoardAMutationResponse,
|};
*/


/*
mutation ProjectBoardAMutation(
  $input: CreateBoardSectionInput!
) {
  createBoardSection(input: $input) {
    boardSectionNode {
      id
      sectionPosition
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBoardSectionInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBoardSectionPayload",
    "kind": "LinkedField",
    "name": "createBoardSection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardSectionNode",
        "kind": "LinkedField",
        "name": "boardSectionNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sectionPosition",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBoardAMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBoardAMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectBoardAMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectBoardAMutation(\n  $input: CreateBoardSectionInput!\n) {\n  createBoardSection(input: $input) {\n    boardSectionNode {\n      id\n      sectionPosition\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61df695d5b48ea079c02748d367830af';

module.exports = node;
