// Card component container HOC , this componet is used as the container to all the cards we are
// using in our app. Must be used as container to every type of card.

import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import ReportCardContainer from "../Card/ReportCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import ReportToolCardContainer from "./ReportToolCardContainer";
import { StoryCardContainerEmbed } from "../Card/StoryCardContainer";
import { reportToolCardArray } from "../../utility/utilities";

class CardEmbedRoot extends Component {
  render() {
    const { cardId } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query CardEmbedRootQuery($input: ID!) {
            boardCardUse(id: $input) {
              id
              jsonid
              cardPosition
              frontFacingComponent {
                componentType
                name
              }
              statistics {
                id
                jsonData
              }
              isFavorite
              subComponentType
            }
          }
        `}
        variables={{ input: unescape(cardId) }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          // console.log("CardFamily data ", JSON.stringify(props))
          return <CardEmbedRootInner cardData={props} {...this.props} />;
        }}
      />
    );
  }
}

class CardEmbedRootInner extends Component {
  render() {
    const {
      cardData,
      changeCardHeight,
      handleSaveCardsPopUp,
      handleUpdateBoard,
      userData,
      handleCloseUpView,
      handleSelectThemePopUp,
      handleArrayOfTagsOfCardObject,
      addRemoveFavourite,
      history,
      handleLoginPopUp,
      isSavedTooltip,
      openStoryPopup,
      openStoryPopupWithUrl,
    } = this.props;

    const {
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
    } = this.state;
    let item = { node: cardData.boardCardUse };
    let index = 1;
    if (item.node.frontFacingComponent.componentType === "ReportCard") {
      return (
        <div key={item.node.id}>
          <ReportCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={false}
            isHomeFeed={true}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            onCloseUpView={this.handleCloseUpView}
            handleSelectThemePopUp={this.handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            storyCard={true}
          />
        </div>
      );
    } else if (item.node.frontFacingComponent.componentType === "AdiMapCard") {
      return (
        <div key={item.node.id}>
          <AdiMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={false}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={true}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleSelectThemePopUp={this.handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            storyCard={true}
          />
        </div>
      );
    } else if (
      item.node.frontFacingComponent.componentType === "ServiceAreaMapCard"
    ) {
      return (
        <div key={item.node.id}>
          <ServiceAreaMapCardContainer
            changeCardHeight={changeCardHeight}
            isEditingEnabled={false}
            subComponentType={item.node.subComponentType}
            statistics={item.node.statistics}
            isHomeFeed={true}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleSelectThemePopUp={this.handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            handleUpdateBoard={handleUpdateBoard}
            isMapVisible={true}
            userData={userData}
            storyCard={true}
          />
        </div>
      );
    } else if (item.node.frontFacingComponent.componentType === "ChartCard") {
      const { subComponentType } = item.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;
      if (reportToolCard) {
        return (
          <div key={item.node.id} className="grid-card">
            <ReportToolCardContainer
              changeCardHeight={changeCardHeight}
              isEditingEnabled={false}
              subComponentType={item.node.subComponentType}
              statistics={item.node.statistics}
              isHomeFeed={true}
              isCloseUpView={false}
              index={index}
              key={item.node.id}
              data={item}
              hideUnHidePopUp={this.hideUnHidePopUp}
              showToolTip={this.showToolTip}
              hideToolTip={this.hideToolTip}
              onCloseUpView={handleCloseUpView}
              handleSelectThemePopUp={handleSelectThemePopUp}
              handleSaveCardsPopUp={handleSaveCardsPopUp}
              newTagsForCardObject={newTagsForCardObject}
              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
              handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
              selectedCardForMutation={selectedCardForMutation}
              isEditHashTagPopUpVisible={this.props.isEditHashTagPopUpVisible}
              isMapcardsVisible={true}
              addRemoveFavourite={addRemoveFavourite}
              userData={userData}
              history={history}
              handleLoginPopUp={handleLoginPopUp}
              storyCard={true}
            />
          </div>
        );
      } else {
        return (
          <div key={item.node.id}>
            <ChartCardContainer
              changeCardHeight={changeCardHeight}
              isEditingEnabled={false}
              subComponentType={item.node.subComponentType}
              statistics={item.node.statistics}
              isHomeFeed={true}
              isCloseUpView={false}
              index={index}
              key={item.node.id}
              data={item}
              hideUnHidePopUp={this.hideUnHidePopUp}
              showToolTip={this.showToolTip}
              hideToolTip={this.hideToolTip}
              onCloseUpView={handleCloseUpView}
              handleSelectThemePopUp={handleSelectThemePopUp}
              handleSaveCardsPopUp={handleSaveCardsPopUp}
              newTagsForCardObject={newTagsForCardObject}
              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
              handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
              selectedCardForMutation={selectedCardForMutation}
              isEditHashTagPopUpVisible={this.props.isEditHashTagPopUpVisible}
              isMapcardsVisible={true}
              addRemoveFavourite={addRemoveFavourite}
              userData={userData}
              history={history}
              handleLoginPopUp={handleLoginPopUp}
              storyCard={true}
            />
          </div>
        );
      }
    } else if (item.node.frontFacingComponent.componentType === "MapCard") {
      // else if (true) {
      return (
        <div key={item.node.id}>
          <StoryCardContainerEmbed
            changeCardHeight={changeCardHeight}
            isEditingEnabled={false}
            isHomeFeed={true}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            onCloseUpView={handleCloseUpView}
            handleSelectThemePopUp={handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={this.props.isEditHashTagPopUpVisible}
            isMapcardsVisible={true}
            addRemoveFavourite={addRemoveFavourite}
            userData={userData}
            handleLoginPopUp={handleLoginPopUp}
            isSavedToolTip={isSavedTooltip}
            storyCard={true}
          />
        </div>
      );
    } else {
      return (
        <div key={item.node.id}>
          <StoryCardContainerEmbed
            changeCardHeight={changeCardHeight}
            isEditingEnabled={false}
            isHomeFeed={true}
            isCloseUpView={false}
            index={index}
            key={item.node.id}
            data={item}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            onCloseUpView={this.handleCloseUpView}
            handleSelectThemePopUp={this.handleSelectThemePopUp}
            handleSaveCardsPopUp={handleSaveCardsPopUp}
            newTagsForCardObject={newTagsForCardObject}
            arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
            handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
            selectedCardForMutation={selectedCardForMutation}
            isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
            openStoryPopup={openStoryPopup}
            openStoryPopupWithUrl={openStoryPopupWithUrl}
            isSavedToolTip={isSavedTooltip}
            userData={userData}
            storyCard={true}
          />
        </div>
      );
    }
  }
}

export default CardEmbedRoot;
