/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoicePagePopupQueryVariables = {|
  input: string
|};
export type InvoicePagePopupQueryResponse = {|
  +billingDocument: ?{|
    +id: string,
    +documentType: string,
    +summary: string,
    +documentDate: any,
    +documentUrl: string,
    +invoiceNumber: ?string,
    +invoiceCreatedDate: string,
    +invoiceDueDate: string,
    +invoiceBillingLine1: string,
    +invoiceBillingLine2: string,
    +invoiceBillingLine3: string,
    +invoicePaymentMethod: string,
    +invoicePaymentNumber: string,
    +invoiceItem1: string,
    +invoiceItem2: string,
    +invoicePrice1: number,
    +invoicePrice2: number,
    +invoiceTotal: number,
  |}
|};
export type InvoicePagePopupQuery = {|
  variables: InvoicePagePopupQueryVariables,
  response: InvoicePagePopupQueryResponse,
|};
*/


/*
query InvoicePagePopupQuery(
  $input: ID!
) {
  billingDocument(id: $input) {
    id
    documentType
    summary
    documentDate
    documentUrl
    invoiceNumber
    invoiceCreatedDate
    invoiceDueDate
    invoiceBillingLine1
    invoiceBillingLine2
    invoiceBillingLine3
    invoicePaymentMethod
    invoicePaymentNumber
    invoiceItem1
    invoiceItem2
    invoicePrice1
    invoicePrice2
    invoiceTotal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "input"
      }
    ],
    "concreteType": "BillingDocumentNode",
    "kind": "LinkedField",
    "name": "billingDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "summary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceCreatedDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceDueDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceBillingLine1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceBillingLine2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceBillingLine3",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoicePaymentMethod",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoicePaymentNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceItem1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceItem2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoicePrice1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoicePrice2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceTotal",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicePagePopupQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoicePagePopupQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvoicePagePopupQuery",
    "operationKind": "query",
    "text": "query InvoicePagePopupQuery(\n  $input: ID!\n) {\n  billingDocument(id: $input) {\n    id\n    documentType\n    summary\n    documentDate\n    documentUrl\n    invoiceNumber\n    invoiceCreatedDate\n    invoiceDueDate\n    invoiceBillingLine1\n    invoiceBillingLine2\n    invoiceBillingLine3\n    invoicePaymentMethod\n    invoicePaymentNumber\n    invoiceItem1\n    invoiceItem2\n    invoicePrice1\n    invoicePrice2\n    invoiceTotal\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a3cc8cb3e3fa554e0ac023be99a54a3';

module.exports = node;
