import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/components/SubNav/SubNav.css';
import runReport from '../../images/run-report-button.svg';
import makeMap from '../../images/make-map-button.svg';
import galleryButton from '../../images/galleryButton.svg';
import { appBaseUrl } from '../../utility/utilities';
import * as d3 from 'd3';

function SubNav() {
  const handleLinkClick = () => {
    scrollToElem(1500, 7500, '#report');
  };

  const scrollTween = (offset) => {
    return function() {
      let i = d3.interpolateNumber(window.pageYOffset || document.documentElement.scrollTop, offset);
      return function(t) {
        window.scrollTo(0, i(t));
      };
    };
  };

  const scrollToElem = (delay, duration, selector) => {
    const box = document.querySelector(selector).getBoundingClientRect();
    let offset = box.top - window.scrollY - 10;

    d3.transition()
      .delay(200)
      .duration(2000)
      .ease(d3.easeCubicInOut)
      .tween('scroll', scrollTween(offset));
  };

  return (
    <nav className="sub-nav nav flex-row justify-content-center align-items-center">
      <a href="#story" className="flex-fill sub-nav-container text-center nav-link"
         onClick={handleLinkClick}>
        <div className={'sub-nav-img-hover'}>
          <img src={runReport} alt=""/>
        </div>
        <div className="nav-title">Create a New Story</div>
      </a>
      <a className="flex-fill sub-nav-container text-center nav-link" href={appBaseUrl + 'map/'}
         rel="noopener noreferrer" target="_blank">
        <div className={'sub-nav-img-hover'}>
          <img src={makeMap} alt=""/>
        </div>
        <div className="nav-title">Go to Map Room</div>
      </a>
      <Link className="flex-fill sub-nav-container text-center nav-link"
            to="/NEWS/member%20gallery/1">
        <div className={'sub-nav-img-hover'}>
          <img src={galleryButton} alt=""/>
        </div>
        <div className="nav-title">Member Gallery</div>
      </Link>
    </nav>
  );
}

export default SubNav;
