import { apiBaseUrl } from '../src/utility/utilities';

export function fetchApi(action, formdata) {
  return new Promise((resolve, reject) => {
    fetch(apiBaseUrl + action, {
      method: 'POST',
      body: formdata,
      credentials: 'include',
      headers:
        {
          'Content-Type': 'application/json',
          'Authorization': 'AIzaSyCbMEN223iJ2r-GhBcPHda0V7mo3vz1uRo'
        }
    })
      .then((response) => response.json())
      .then((res) => {
        return resolve(res);
        // console.log(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
