import React, { Component } from "react";
import "../../css/components/Card/D3HorizontalBarChart.css";
import * as d3 from "d3";
import * as _ from "lodash";

class D3HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    const { horizontalBarData, noCommunityBar } = this.props;
    this.margin = { top: 5, right: 5, bottom: 5, left: 5 };
    this.clientWidth = 215;
    this.clientHeight = 215;
    this.fontTopOffset = 0;
    this.fontCountTopOffset = 0;
    let data = horizontalBarData;
    if (noCommunityBar) {
      data = data.filter((d) => d.label !== "Our Community");
    }
    this.state = {
      horizontalBarData: data,
      horizontalBar: props.horizontalBar || {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { horizontalBarData } = this.state;
    if (!_.isEqual(prevState.horizontalBarData, horizontalBarData)) {
      this.init(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps, this.props)) {
      if (nextProps.horizontalBarData) {
        let data = nextProps.horizontalBarData;
        if (nextProps.noCommunityBar) {
          data = data.filter((d) => d.label !== "Our Community");
        }
        this.setState({ horizontalBarData: data });
      }
    }
  }

  componentDidMount() {
    this.init(this.props);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
  };

  init = (nextProps) => {
    const { id } = nextProps;
    const { horizontalBarData } = this.state;
    if (horizontalBarData && id) {
      d3.select(this.parentEle)
        .selectAll("svg")
        .filter((d) => {
          return d !== id;
        })
        .remove();
      this.createSvg(nextProps);
      window.addEventListener("resize", this.onWindowResize);
    }
  };

  createSvg = (nextProps) => {
    const { horizontalBarData } = this.state;
    const id = nextProps.id.replace(/=/g, "");
    const { minValue, maxValue } = nextProps;
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([id], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3PieChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + id);
    this.svgText = this.svg
      .append("text")
      .attr("class", "random")
      .style("fill", "transparent")
      .attr("y", 0)
      .attr("x", 0);

    const g = this.svg.selectAll("g.container").data(
      (d) => [d],
      (d) => d
    );
    g.enter()
      .append("g")
      .merge(g)
      .attr("class", "container")
      .attr("id", (d) => d + "container");
    g.exit().remove();
    this.svgG = this.svg.select("g#" + id + "container");

    const gTitleSelection = this.svgG.selectAll("g.title-g-container").data(
      (d) => [d],
      (d) => d
    );
    gTitleSelection
      .enter()
      .append("g")
      .merge(gTitleSelection)
      .attr("class", "title-g-container")
      .attr("id", (d) => d + "title-g-container");
    gTitleSelection.exit().remove();
    this.svgTitleG = this.svgG.select("g#" + id + "title-g-container");

    const gSelection = this.svgG.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgBarContainerG = this.svgG.select("g#" + id + "g-container");

    const gBarSelection = this.svgBarContainerG
      .selectAll("g.bar-container")
      .data(
        (d) => [d],
        (d) => d
      );
    gBarSelection
      .enter()
      .append("g")
      .merge(gBarSelection)
      .attr("class", "bar-container")
      .attr("id", (d) => d + "bar-container");
    gBarSelection.exit().remove();
    this.svgBarG = this.svgBarContainerG.select("g#" + id + "bar-container");

    const gDashPathSelection = this.svgBarContainerG
      .selectAll("g.dash-path-container")
      .data(
        (d) => [d],
        (d) => d
      );
    gDashPathSelection
      .enter()
      .append("g")
      .merge(gDashPathSelection)
      .attr("class", "dash-path-container")
      .attr("id", (d) => d + "dash-path-container");
    gDashPathSelection.exit().remove();
    this.svgDashPathG = this.svgBarContainerG.select(
      "g#" + id + "dash-path-container"
    );

    const gLabels = this.svgBarContainerG.selectAll("g.labels-container").data(
      (d) => [d],
      (d) => d
    );
    gLabels
      .enter()
      .append("g")
      .merge(gLabels)
      .attr("class", "labels-container")
      .attr("id", (d) => d + "labels-container");
    gLabels.exit().remove();
    this.svgLabelsG = this.svgBarContainerG.select(
      "g#" + id + "labels-container"
    );

    this.yBarHeight = d3
      .scaleBand()
      .domain(horizontalBarData.map((d) => d.label))
      .round(true)
      .padding(horizontalBarData.length === 1 ? 0.33 : 0.05);
    this.y = d3
      .scaleBand()
      .domain(horizontalBarData.map((d) => d.label))
      .round(true)
      .padding(horizontalBarData.length === 1 ? 0.33 : 0.05);
    this.x = d3.scaleLinear().domain([minValue, maxValue]);
    this.autoAlignSVG();
    this.createTitle(nextProps);
    this.createBar();
    this.createDashPath(nextProps);
    this.createLabels();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    this.count = 0;
    //  Set the dimensions and margins of the diagram
    this.size =
      this.parentEle.clientWidth > this.parentEle.clientHeight
        ? this.parentEle.clientHeight
        : this.parentEle.clientWidth;
    this.chartWidth =
      (isWide ? this.clientWidth : this.clientWidth) -
      this.margin.left -
      this.margin.right;
    this.chartHeight =
      (isWide ? this.clientHeight : this.clientHeight) -
      this.margin.top -
      this.margin.bottom;
    this.titleHeight = (92 / 216) * this.chartHeight;
    this.titleWidth = this.chartWidth;

    this.height = this.chartHeight - this.titleHeight;
    this.width = this.chartWidth;
    this.calculateFontSize();
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", isWide && this.size ? this.size : "100%")
      .attr("height", isWide && this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 
        ${this.chartWidth + this.margin.left + this.margin.right + 15}
        ${this.chartHeight + this.margin.top + this.margin.bottom}`
      );
    this.svg
      .append("svg:rect")
      .attr(
        "width",
        this.chartWidth + this.margin.left + this.margin.right + 15
      ) // the whole
      // width of
      // g/svg
      .attr("height", this.chartHeight + this.margin.top + this.margin.bottom) // the whole
      // height of g/svg
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseover", () => {
        this.count = this.count + 1;
        if (this.count === 1) {
          this.createBar();
        }
      })
      .on("mousemove", () => {
        d3.event.stopPropagation();
      })
      .on("mouseout", () => {
        this.count = 0;
        d3.event.stopPropagation();
      });
    this.svgG.attr(
      "transform",
      `translate(${this.margin.left},${this.margin.top})`
    );
    this.svgBarContainerG.attr("transform", `translate(0,${this.titleHeight})`);
    this.svgTitleG.attr("transform", "translate(0,0)");
    this.y.range([this.height - this.refDataHeight, 0]);
    this.yBarHeight.range([this.height - this.refDataHeight, 0]);
    this.svgBarG.attr("transform", "translate(0,0)");
    this.svgDashPathG.attr("transform", "translate(0,0)");
    this.svgLabelsG.attr("transform", "translate(0,0)");
    this.x.range([0, this.width]);
  };

  calculateFontSize = () => {
    this.svgText.style("font-family", "arial").text("A9ZI");
    this.fontSize = 9;
    /*this.CountFontSize = 15;*/

    // this.barInnerPadding = 12;
    // this.refDataHeight = (cbbox.height) + this.barInnerPadding;
    this.barInnerPadding = 15;
    this.refDataHeight = 25;
  };

  createTitle(nextProps) {
    const { subText, mainText } = nextProps;
    const lineG = this.svgTitleG
      .selectAll("g.main-text-g")
      .data([mainText], (d) => d);
    const newLineG = lineG.enter().append("g");
    newLineG.merge(lineG).attr("class", "main-text-g");
    lineG.exit().remove();
    const textLabel = newLineG
      .merge(lineG)
      .selectAll("text")
      .data(
        (d) => [d],
        (d) => d
      );
    textLabel
      .enter()
      .append("text")
      .merge(textLabel)
      // .text(d => 0)
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Bold")
      .style("font-size", "48px")
      // .style('font-weight', '400')
      .style("fill", "#424B54")
      .attr("dy", "0.72em")
      .attr("x", this.chartWidth / 2)
      .transition()
      .duration(700)
      .tween("text", function () {
        const format =
          mainText && mainText.includes(".") && mainText.indexOf(".") < 2
            ? d3.format(",.1~r")
            : d3.format(",.0f");
        if (
          mainText &&
          !mainText.includes("$") &&
          !mainText.includes("%") &&
          !mainText.includes("No")
        ) {
          const that = d3.select(this),
            i = d3.interpolateNumber(that.text().replace(/,/g, ""), mainText);
          return function (t) {
            that.text(format(i(t)));
          };
        } else {
          const that = d3.select(this);
          return function (t) {
            that.text(mainText);
          };
        }
      });
    textLabel.exit().remove();
    const subTextG = this.svgTitleG
      .selectAll("g.sub-text-g")
      .data([subText], (d) => d);
    const newSubG = subTextG.enter().append("g");
    newSubG
      .merge(subTextG)
      .attr("class", "sub-text-g")
      .attr("transform", "translate(0,48)");
    subTextG.exit().remove();
    const subTextLabel = newSubG
      .merge(subTextG)
      .selectAll("text")
      .data(
        (d) => [d],
        (d) => d
      );
    subTextLabel
      .enter()
      .append("text")
      .merge(textLabel)
      .text((d) => d)
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "15px")
      .style("fill", "#424B54")
      .attr("dy", "0.72em")
      .attr("x", this.chartWidth / 2);
    subTextLabel.exit().remove();
  }

  createLabels() {
    const { horizontalBarData } = this.state;
    const lineG = this.svgLabelsG
      .selectAll("g.labels-g")
      .data(horizontalBarData, (d) => d.label);
    const newLineG = lineG.enter().append("g");
    newLineG.merge(lineG).attr("class", "labels-g");
    lineG.exit().remove();
    const textLabel = newLineG
      .merge(lineG)
      .selectAll("text.label")
      .data(
        (d) => [d],
        (d) => d.label
      );
    textLabel
      .enter()
      .append("text")
      .merge(textLabel)
      .text((d) => d.label.toUpperCase())
      .attr("class", "label")
      .style("text-anchor", "start")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "9px")
      .attr("dy", "0")
      .attr("fill", (d) => (d.color ? d.color : "#424B54"))
      .attr("transform", (d) => {
        return (
          "translate(0," +
          (this.yBarHeight(d.label) +
            (this.yBarHeight.bandwidth() - this.barInnerPadding > 0
              ? this.yBarHeight.bandwidth() - this.barInnerPadding
              : 0) /
              2 -
            this.yBarHeight.bandwidth() / 2 +
            3) +
          ")"
        );
      });
    textLabel.exit().remove();
    const textValues = newLineG
      .merge(lineG)
      .selectAll("text.values")
      .data(
        (d) => [d],
        (d) => d.label
      );
    textValues
      .enter()
      .append("text")
      .merge(textValues)
      .text((d) => {
        d.value = d.value < 1 ? _.round(d.value, 1) : _.round(d.value);
        return d.valueLabel
          ? !isNaN(parseFloat(d.valueLabel))
            ? parseFloat(d.valueLabel) < 1
              ? _.round(parseFloat(d.valueLabel), 1)
              : _.round(parseFloat(d.valueLabel))
            : d.valueLabel
          : d.value;
      })
      .attr("class", "values")
      .attr("y", "2")
      .style("text-anchor", "start")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "15px")
      .attr("dy", "4px")
      .attr("fill", "rgba(255, 255, 255, 0.5)")
      .attr("transform", (d) => {
        return (
          "translate(5," +
          (this.yBarHeight(d.label) +
            (this.yBarHeight.bandwidth() - this.barInnerPadding > 0
              ? this.yBarHeight.bandwidth() - this.barInnerPadding
              : 0) /
              2) +
          ")"
        );
      });
    textValues.exit().remove();
  }

  createBar() {
    const { horizontalBarData } = this.state;
    this.svgBarG.selectAll("g.bar-g").remove();
    const rectG = this.svgBarG
      .selectAll("g.bar-g")
      .data(horizontalBarData, (d) => d.label);

    const newRectG = rectG.enter().append("g");
    newRectG
      .merge(rectG)
      .attr("class", "bar-g")
      .attr("transform", (d) => {
        return (
          "translate(0," + (this.y(d.label) + this.y.bandwidth() / 2) + ")"
        );
      });
    rectG.exit().remove();
    const rect = newRectG
      .merge(rectG)
      .selectAll("rect.bar")
      .data(
        (d) => [d],
        (d) => d.label
      )
      .attr("pointer-events", "all");
    rect
      .enter()
      .append("rect")
      .merge(rect)
      .attr("class", "bar")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("x", 0)
      .attr(
        "height",
        this.y.bandwidth() - this.barInnerPadding > 0
          ? this.y.bandwidth() - this.barInnerPadding
          : 0
      )
      .attr("y", -(this.yBarHeight.bandwidth() / 2))
      .style("fill", (d) => (d.color ? d.color : "#424B54"))
      .transition()
      .duration(1000)
      .attr("width", (d) => this.x(d.value));
    rect.exit().remove();
  }

  createDashPath(nextProps) {
    const lineG = this.svgDashPathG
      .selectAll("path.dash")
      .data([nextProps.refData.value]);
    const newLineG = lineG.enter().append("path");
    newLineG
      .merge(lineG)
      .attr("class", "dash")
      .attr("d", (d) => {
        d = d < 1 ? _.round(d, 1) : _.round(d);
        return (
          "M" +
          this.x(d) +
          ",-5 L" +
          this.x(d) +
          "," +
          (this.height - this.refDataHeight - 5)
        );
      })
      .style("stroke", (d) => (d.color ? d.color : "#424B54"))
      .style("stroke-width", " 1px")
      .style("stroke-dasharray", "5,4");
    lineG.exit().remove();
    const textLabel = this.svgDashPathG
      .selectAll("text.label")
      .data([nextProps.refData], (d) => d.label);
    textLabel
      .enter()
      .append("text")
      .merge(textLabel)
      .text((d) => d.label.toUpperCase())
      .attr("class", "label")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "10px")
      .attr("dy", "4px")
      .attr("fill", (d) => (d.color ? d.color : "#424B54"))
      .attr("transform", (d) => {
        d.value = d.value < 1 ? _.round(d.value, 1) : _.round(d.value);
        return (
          "translate(" +
          Math.min(Math.max(this.x(d.value), 37), 180) +
          "," +
          (5 +
            (this.height - this.refDataHeight) +
            (this.y.bandwidth() - this.barInnerPadding > 0
              ? this.y.bandwidth() - this.barInnerPadding
              : 0) /
              2) +
          ")"
        );
      });
    textLabel.exit().remove();
    const textValues = this.svgDashPathG
      .selectAll("text.values")
      .data([nextProps.refData], (d) => d.label);
    textValues
      .enter()
      .append("text")
      .merge(textValues)
      .text((d) => {
        d.value = d.value < 1 ? _.round(d.value, 1) : _.round(d.value);
        let label = d.valueLabel;
        return d.valueLabel
          ? !isNaN(parseFloat(label))
            ? _.round(parseFloat(label))
            : d.valueLabel
          : d.value;
      })
      .attr("class", "values")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "15px")
      .attr("dy", "4px")
      .attr("fill", (d) => (d.color ? d.color : "#424B54"))
      .attr("transform", (d) => {
        return (
          "translate(" +
          this.x(d.value) +
          "," +
          (5 +
            (this.height -
              this.refDataHeight +
              (this.y.bandwidth() - this.barInnerPadding > 0
                ? this.y.bandwidth() - this.barInnerPadding
                : 0) /
                2) -
            15) +
          ")"
        );
      });

    textValues.exit().remove();
  }

  render() {
    return (
      <div
        className="D3HorizontalBarChart d-flex justify-content-center align-items-center h-100 w-100"
        style={{ padding: "0px 5px" }}
      >
        <div
          className="w-100 d-flex justify-content-center align-items-center h-100"
          ref={(ele) => (this.parentEle = ele)}
        />
      </div>
    );
  }
}

export default D3HorizontalBarChart;
