import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";

class D3ClickableGaugeChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.angle = { min: -90, max: 90 };
    this.clientWidth = 217;
    this.clientHeight = 114;
    this.scale = d3.scaleLinear().range([0, 1]);
    this.size = 200;
    this.arcInset = 150;
    this.arcWidth = 60;
    this.arc = [];
    this.foreground = [];
    this.state = {
      selectedData: {},
      ratio: 1,
    };
    this.spaceBetweenLegeds = 13;
    this.chartName = "clickableGaugeChart";
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentWillReceiveProps(newProps) {
    const { clickableGaugeChartData } = this.props;
    if (!_.isEqual(clickableGaugeChartData, newProps.clickableGaugeChartData)) {
      const data = newProps.clickableGaugeChartData.data;
      this.setState({ selectedData: data[data.length - 1] });
      this.createGuage(newProps);
    }
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.parentSize =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.parentSize / 217) {
      this.setState({
        ratio: this.parentSize / 217,
      });
    }
  };

  init = () => {
    this.createSvg();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3GaugeChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "gcontainer");

    let svg2 = d3
      .select(this.mainParentEle)
      .select("#legend")
      .selectAll("svg")
      .data([svgId], (d) => d);

    svg2
      .enter()
      .append("svg")
      .merge(svg2)
      .attr("class", "legend")
      .attr("id", (d) => d)
      .attr("width", "100%")
      .attr("height", 60);

    svg2.exit().remove();
    this.svg2 = d3
      .select(this.mainParentEle)
      .select("#legend")
      .select("svg#" + svgId);

    const legendG = this.svg2.selectAll("g.legend-container").data(
      (d) => [d],
      (d) => d
    );
    legendG
      .enter()
      .append("g")
      .merge(legendG)
      .attr("id", (d) => d + "legendcontainer")
      .attr("class", "legend-container");
    legendG.exit().remove();
    this.legendG = this.svg2.select("g#" + svgId + "legendcontainer");

    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    this.parentSize =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.parentSize / 217) {
      this.setState({
        ratio: this.parentSize / 217,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
  };

  update = () => {
    const { min, max } = this.props;
    this.scale.domain([min, max]);
    this.autoAlignSVG();
    this.createGuage(this.props);
  };

  deg2rad = (deg) => {
    return (deg * Math.PI) / 180;
  };

  onMouseOverLegend = (d) => {
    this.setState({ selectedData: d });
    this.gaugeSvg
      .append("path")
      .attr("d", this.svg.select(`#${d.name2.replace(/ /g, "")}`).attr("d"))
      .attr("id", "arcSelection")
      .style("fill", "none")
      .style("stroke", "#424B54")
      .style("stroke-width", 2);
  };

  onMouseOutLegend = (self, data) => {
    this.setState({ selectedData: data[data.length - 1] });
    d3.select(self).style("stroke", "").style("stroke-width", "");
    d3.select("#arcSelection").remove();
  };

  createGuage = (props) => {
    let outerRadius = this.size - this.arcInset;
    let innerRadius = this.size - outerRadius - this.arcWidth;

    if (!_.isEmpty(props.clickableGaugeChartData.data)) {
      const { data } = props.clickableGaugeChartData;
      // const data = [{"name": "of children under 18 yrs are", "name2": "Black or African
      // American", "legend": "Black or AA", "ourcommunity": 20.0, "patternClassName": "",
      // "patternFillColor": "", "backgroundColor": "#424B54"}, {"name": "of children under 18 yrs
      // are", "name2": "Asian", "legend": "Asian", "ourcommunity": 6.0, "patternClassName": "",
      // "patternFillColor": "", "backgroundColor": "#00939D"}, {"name": "of children under 18 yrs
      // are", "name2": "Native American", "legend": "Native American", "ourcommunity": 9.0,
      // "patternClassName": "", "patternFillColor": "", "backgroundColor": "#B3CC87"}, {"name":
      // "of children under 18 yrs are", "name2": "Native Hawaiian", "legend": "Native Hawaiian",
      // "ourcommunity": 10.0, "patternClassName": "circles-1", "patternFillColor": "#B3CC87",
      // "backgroundColor": "#78A22F"}, {"name": "of children under 18 yrs are", "name2": "other
      // race", "legend": "Other Race", "ourcommunity": 8.0, "patternClassName": "diagonal-stripe-1", "patternFillColor": "#424B54", "backgroundColor": "#D35721"}, {"name": "of children under 18 yrs are", "name2": "two or more races", "legend": "2 or More Races", "ourcommunity": 10.0, "patternClassName": "", "patternFillColor": "", "backgroundColor": "#FF6B35"}, {"name": "of children under 18 yrs are", "name2": "White", "legend": "White", "ourcommunity": 35.0, "patternClassName": "", "patternFillColor": "", "backgroundColor": "#E8E8E8"}] Place svg element
      const gaugeSelection = this.svgG.selectAll("svg").data(
        (d) => [d],
        (d) => d
      );
      this.gaugeSvg = gaugeSelection
        .enter()
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height)
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + (this.height - 16) + ")"
        );

      let bgArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(this.deg2rad(-90));
      this.gaugeSvg
        .append("path")
        .datum({
          endAngle: this.deg2rad(this.angle.max),
        })
        .attr("class", "gaugeBackground")
        .attr("d", bgArc);

      let totalPercent = 0;
      let arcStartRad = this.deg2rad(this.angle.min);
      let arcEndRad = 0;
      data.forEach((item, index) => {
        // Arc Defaults

        let num = (item.ourcommunity * 180) / 100; // convert num to degree
        arcEndRad = arcStartRad + this.deg2rad(num);
        let arc = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(arcStartRad)
          .endAngle(arcEndRad);

        this.arc.push(arc);

        // Append foreground arc to svg
        const self = this;
        this.foreground.push(
          this.gaugeSvg
            .append("path")
            .style("fill", this.fillColor(item))
            .attr("d", arc)
            .attr("id", item.name2.replace(/ /g, ""))
            .on("click", function () {
              self.setState({ selectedData: item });
              d3.select(this)
                .style("stroke", "#424B54")
                .style("stroke-width", 2);
            })
            .on("mouseover", function (d) {
              self.onMouseOverLegend(item);
            })
            .on("mouseout", function () {
              self.onMouseOutLegend(this, data);
            })
        );

        arcStartRad = arcEndRad;
        totalPercent = totalPercent + item.ourcommunity;
      });

      // Fixed residual
      if (totalPercent < 100) {
        let num = ((100 - totalPercent) * 180) / 100;
        arcEndRad = arcStartRad + this.deg2rad(num);
        let arc = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(arcStartRad)
          .endAngle(arcEndRad);

        this.arc.push(arc);

        // Append foreground arc to svg
        this.foreground.push(
          this.gaugeSvg
            .append("path")
            .style("fill", "#989898")
            .attr("d", arc)
            .attr("id", "residual")
        );
      }

      // Display Max value
      this.gaugeSvg
        .append("text")
        .attr(
          "transform",
          "translate(" +
            (innerRadius + (outerRadius - innerRadius) / 2) +
            ",12)"
        ) // Set
        .attr("text-anchor", "middle")
        .style("font-family", "ProximaNova-Regular")
        .style("font-size", "9")
        .style("opacity", 1)
        .style("color", "#424B54")
        .text("PERCENT");

      // legends
      const rectHeight = 5;
      const rectWidth = 14;
      const self = this;
      const legendG = this.legendG
        .selectAll("g.legend")
        .data(data, (d) => d && d.name2);
      const newLegendG = legendG.enter().append("g");
      newLegendG
        .merge(legendG)
        .attr("class", "legend")
        .attr("transform", (d, i) => {
          if (i < 4) {
            return `translate(${this.margin.left},${
              this.spaceBetweenLegeds * i + 12
            })`;
          } else {
            return `translate(${this.margin.left + 114},${
              this.spaceBetweenLegeds * (i - 4) + 12
            })`;
          }
        });
      const blankRect = newLegendG
        .merge(legendG)
        .selectAll("rect.blank")
        .data(
          (d) => [d],
          (d) => d && d.name2
        );
      const newBlankRect = blankRect.enter().append("rect");
      newBlankRect
        .merge(blankRect)
        .attr("width", 90) // the whole width of g/svg
        .attr("height", 15) // the whole heigh of g/svg
        .attr("fill", "none")
        .attr("class", "blank")
        .attr("transform", `translate(0,-${rectHeight / 2 + 2})`)
        .attr("pointer-events", "all")
        .on("mouseover", function (d) {
          self.onMouseOverLegend(d);
        })
        .on("mouseout", function () {
          self.onMouseOutLegend(this, data);
        });
      legendG.exit().remove();

      const legendRect = newLegendG
        .merge(legendG)
        .selectAll("rect.filled")
        .data(
          (d) => [d],
          (d) => d && d.name2
        );
      const newRect = legendRect.enter().append("rect");
      newRect
        .merge(legendRect)
        .attr("x", 0)
        .attr("y", 0)
        .attr("pointer-events", "visibleStroke")
        .attr("class", "filled")
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .attr("rx", rectHeight / 2)
        .attr("ry", rectWidth / 2)
        .attr("transform", `translate(0,-${rectHeight / 2})`)
        .style("fill", (d) => this.fillColor(d))
        .on("mouseover", function (d) {
          self.onMouseOverLegend(d);
        })
        .on("mouseout", function () {
          self.onMouseOutLegend(this, data);
        });
      legendRect.exit().remove();

      // legends text enter, update, exit
      const legendText = newLegendG
        .merge(legendG)
        .selectAll("text")
        .data(
          (d) => [d],
          (d) => d && d.name2
        );
      const newText = legendText.enter().append("text");
      newText
        .merge(legendText)
        .attr("x", rectWidth + 5)
        .attr("y", 0)
        .attr("pointer-events", "visibleStroke")
        .style("font-size", "10px")
        .attr("text-anchor", "start")
        .attr("dy", "4px")
        .style("fill", "#424B54")
        .style("color", "#424B54")
        .style("font-family", "ProximaNova-Regular")
        .style("cursor", "default")
        .text((d) => d.legend)
        .on("mouseover", function (d) {
          self.onMouseOverLegend(d);
        })
        .on("mouseout", function () {
          self.onMouseOutLegend(this, data);
        });
      legendText.exit().remove();
    }
  };

  fillColor = (d) => {
    if (d.patternClassName && d.patternFillColor) {
      const selectPattern = d3
        .select(this.mainParentEle)
        .select("#" + d.patternClassName + this.chartName);
      if (selectPattern) {
        selectPattern.select("rect").style("fill", d.backgroundColor);
        selectPattern.select("circle").style("fill", d.patternFillColor);
        selectPattern.select("path").style("fill", d.patternFillColor);
      }
      return "url(#" + d.patternClassName + this.chartName + ") ";
    } else {
      return d.backgroundColor;
    }
  };

  render() {
    const { selectedData, ratio } = this.state;
    const titleStyle = {
      fontSize: "2.5rem",
      fontFamily: "ProximaNova-Bold",
      marginBottom: 0,
      width: "100%",
      position: "relative",
    };
    const subTitleDivStyle = {
      position: "relative",
    };
    const subTitleStyle = {
      fontSize: "16px",
      fontFamily: "ProximaNova-Bold",
      width: "100%",
      marginBottom: 0,
      lineHeight: 1.2,
      color: "#424B54",
    };
    const unitStyle = {
      fontSize: "25px",
    };
    return (
      <div
        className="D3ClickableGaugeChart h-100 w-100 d-flex justify-content-center align-items-center"
        ref={(ele) => {
          this.mainParentEle = ele;
        }}
      >
        <div
          className={"d-flex flex-column"}
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div
            className="w-100"
            style={{ height: "114px" }}
            ref={(ele) => (this.parentEle = ele)}
          />
          <div
            className="text-center"
            style={{ marginTop: "-32px", color: "#424B54" }}
          >
            <svg
              height="10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <defs>
                <pattern
                  id={"circles-1" + this.chartName}
                  width="5"
                  height="5"
                  patternUnits="userSpaceOnUse"
                >
                  <rect width="10" height="10" fill="#fff" />
                  <circle
                    cx="1"
                    cy="1"
                    r="1"
                    style={{ stroke: "none", fill: "#000" }}
                  />
                </pattern>
              </defs>
            </svg>
            <span style={titleStyle}>
              {selectedData && _.round(selectedData.ourcommunity)}
              <span style={unitStyle}>%</span>
            </span>
            <svg
              height="10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <defs>
                <pattern
                  id={"diagonal-stripe-1" + this.chartName}
                  width="10"
                  height="10"
                  patternUnits="userSpaceOnUse"
                >
                  <rect width="10" height="10" fill="white" />
                  <path
                    d="M-1,1 l2,-2
                                         M0,10 l10,-10
                                         M9,11 l2,-2"
                    stroke="black"
                    strokeWidth="1"
                  />
                </pattern>
              </defs>
            </svg>
          </div>
          <div className="text-center" style={subTitleDivStyle}>
            <p style={subTitleStyle}>{selectedData && selectedData.name}</p>
            <p style={subTitleStyle}>{selectedData && selectedData.name2}</p>
          </div>
          <div style={{ height: "60px", padding: "0 5px" }}>
            <div id="legend" />
          </div>
        </div>
      </div>
    );
  }
}

export default D3ClickableGaugeChart;
