/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StoriesQueryVariables = {|
  reportTemplatesOnly?: ?boolean,
  excludeReportTemplates?: ?boolean,
  search?: ?string,
  sharedWithMe?: ?boolean,
|};
export type StoriesQueryResponse = {|
  +storiesBoard: ?{|
    +sections: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardCardUses: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cardPosition: number,
                +frontFacingComponent: {|
                  +componentType: string,
                  +name: string,
                  +id: string,
                |},
                +isFavorite: ?boolean,
                +subComponentType: ?string,
              |}
            |}>
          |},
          +id: string,
          +name: string,
          +description: ?string,
          +layout: string,
          +sectionPosition: number,
        |}
      |}>
    |},
    +id: string,
    +name: string,
    +subtitle: ?string,
    +theme: {|
      +jsonStyleProperties: string
    |},
  |}
|};
export type StoriesQuery = {|
  variables: StoriesQueryVariables,
  response: StoriesQueryResponse,
|};
*/


/*
query StoriesQuery(
  $reportTemplatesOnly: Boolean
  $excludeReportTemplates: Boolean
  $search: String
  $sharedWithMe: Boolean
) {
  storiesBoard {
    sections {
      edges {
        node {
          boardCardUses(reportTemplatesOnly: $reportTemplatesOnly, excludeReportTemplates: $excludeReportTemplates, search: $search, sharedWithMe: $sharedWithMe) {
            edges {
              node {
                id
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                  id
                }
                isFavorite
                subComponentType
              }
            }
          }
          id
          name
          description
          layout
          sectionPosition
        }
      }
    }
    id
    name
    subtitle
    theme {
      jsonStyleProperties
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportTemplatesOnly",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "excludeReportTemplates",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sharedWithMe",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardSectionNodeConnection",
  "kind": "LinkedField",
  "name": "sections",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardSectionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardSectionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "excludeReportTemplates",
                  "variableName": "excludeReportTemplates"
                },
                {
                  "kind": "Variable",
                  "name": "reportTemplatesOnly",
                  "variableName": "reportTemplatesOnly"
                },
                {
                  "kind": "Variable",
                  "name": "search",
                  "variableName": "search"
                },
                {
                  "kind": "Variable",
                  "name": "sharedWithMe",
                  "variableName": "sharedWithMe"
                }
              ],
              "concreteType": "BoardCardUseNodeConnection",
              "kind": "LinkedField",
              "name": "boardCardUses",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BoardCardUseNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BoardCardUseNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cardPosition",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FrontFacingComponentNode",
                          "kind": "LinkedField",
                          "name": "frontFacingComponent",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "componentType",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isFavorite",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "subComponentType",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "layout",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sectionPosition",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "storiesBoard",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "storiesBoard",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "StoriesQuery",
    "operationKind": "query",
    "text": "query StoriesQuery(\n  $reportTemplatesOnly: Boolean\n  $excludeReportTemplates: Boolean\n  $search: String\n  $sharedWithMe: Boolean\n) {\n  storiesBoard {\n    sections {\n      edges {\n        node {\n          boardCardUses(reportTemplatesOnly: $reportTemplatesOnly, excludeReportTemplates: $excludeReportTemplates, search: $search, sharedWithMe: $sharedWithMe) {\n            edges {\n              node {\n                id\n                cardPosition\n                frontFacingComponent {\n                  componentType\n                  name\n                  id\n                }\n                isFavorite\n                subComponentType\n              }\n            }\n          }\n          id\n          name\n          description\n          layout\n          sectionPosition\n        }\n      }\n    }\n    id\n    name\n    subtitle\n    theme {\n      jsonStyleProperties\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '647f4facbf44af6278be6740824b5363';

module.exports = node;
