import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CardEmbedRoot from './components/Card/CardEmbedRoot';
import { Provider } from 'react-redux';
import createStore from './store/createStore';
//Sentry.init({dsn: "https://21dc52bb58354a05a10cc8b50c8d8c5d@sentry.io/1532158"});
// DEMO Sentry.init({dsn: "https://065d07433a1d4539a8f688b15579e9e7@sentry.io/1532164"});
// PROD Sentry.init({dsn: "https://21dc52bb58354a05a10cc8b50c8d8c5d@sentry.io/1532158"});
//import registerServiceWorker from './registerServiceWorker';

// =========================================
// Store Instantiation
// =========================================
const initialState = {};
const store = createStore(initialState);

// =========================================
// Render
// =========================================
const composeApp = (App) => {
  return (
    <Provider store={store}>
      <App/>
    </Provider>
  );
};

/* Connect full app root for general use */

const renderApp = () => {
  const App = require('./App').default;

  let broadStreetStoryRoot = document.getElementById('BroadStreetStoryRoot');
  if (broadStreetStoryRoot != null) {
    ReactDOM.render(composeApp(App), broadStreetStoryRoot);
  } else {
    ReactDOM.render(composeApp(App), document.getElementById('root'));
  }
};

renderApp();

if (module.hot) {
  module.hot.accept('./App', renderApp);
}

/* Embed a card in another web page */
let widgetEmbeds = document.querySelectorAll('[id*="BroadStreetDataEmbed"]');
var i;
for (i = 0; i < widgetEmbeds.length; i++) {
  const widgetType = widgetEmbeds[i].dataset.broadstreetWidget;
  if (widgetType === 'card') {
    console.log('Card Embed', widgetEmbeds[i].id);
    //widgetEmbeds[i].style.backgroundColor = "red";
    const cardId = widgetEmbeds[i].dataset.broadstreetId;
    console.log('dataset.broadstreetId', cardId);
    ReactDOM.render(<CardEmbedRoot cardId={cardId}/>, widgetEmbeds[i]);
  }
}

//ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(
    function(registrations) {
      var unregistered = false;
      for (let registration of registrations) {
        registration.unregister();
        unregistered = true;
      }
      if (unregistered) {
        window.location.reload(true);
      }
    }
  );
}
