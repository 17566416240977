import React, { Component } from "react";
import "../../css/components/CommonComponents/SelectThemePopUp.css";

class SelectThemePopUp extends Component {
  handleChild = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible, x, y } = this.props;
    return (
      <div
        style={{
          left: x,
          top: y,
          position: "absolute",
          zIndex: 1,
        }}
        onClick={this.handleChild}
      >
        {visible ? (
          <div className="previewthemes_popupcontain">
            <form
              method=""
              action=""
              className="previewthemesform"
              name="previewthemes_selectionpopup"
            >
              <label className="search">
                <input type="input" name="search" placeholder="Search Themes" />
              </label>
              <label className="organization">Themes</label>
              <label className="Memorials labelhover">
                <input type="radio" name="startagSelection" />
                <span />
                <p>
                  Flowers <button>Apply</button>
                </p>
              </label>
              <label className="Franciscan labelhover">
                <input type="radio" name="startagSelection" />
                <span />
                <p>
                  Classic <button>Apply</button>
                </p>
              </label>
              <label className="weight labelhover">
                <input type="radio" name="startagSelection" />
                <span />
                <p>
                  Retro Explosion <button>Apply</button>
                </p>
              </label>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SelectThemePopUp;
