import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3GaugeChart from './D3GaugeChart';

class D3GaugeWideChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legends: [{ label: 'Most Affluent', color: '#78A22F' },
        { label: '', color: '#6A8D73' },
        { label: 'Average', color: '#D5DED6' },
        { label: '', color: '#FFA987' },
        { label: 'Most Deprived', color: '#FF6B35' }
      ]
    };
  }

  render() {
    const { id, text, valueText, gauge, needle, min, max, value, unit, showTwitterCardPopup } = this.props;
    const { legends } = this.state;
    const middleContent = (
      <ul className="list-group mt-5 legend">
        {legends.map((legend, index) => {
          return (
            <li key={index} className="list-group-item p-1">
              <div style={{ background: legend.color }}/>
              {legend.label}
            </li>
          );
        })}
      </ul>
    );
    const wideChartId = id + (showTwitterCardPopup ? 'singleParentsWithChildWide' : 'singleParentsWithChildWideTwitte');
    const content = (
      <div className='D3GaugeWideChart d-flex w-100 h-100 '>
        <D3GaugeChart
          id={wideChartId}
          key={wideChartId}
          gauge={gauge}
          needle={needle}
          min={min}
          max={max}
          value={value}
          valueText={valueText}
          text={text}
          unit={unit}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}
                            middleContent={middleContent}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3GaugeWideChart;
