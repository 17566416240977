import React, { Component, Fragment } from "react";
import { commitMutation, QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Responsive } from "react-grid-layout";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import environment from "../../RelayEnvironment";
import StoryCardContainer from "../Card/StoryCardContainer";
import {
  ToolTip,
  AnnouncementPopup,
  ButtonComponent,
  TallModalPopup,
} from "common-components";
import FooterComponent from "../CommonComponents/FooterComponent";
import WideCardPopup from "../CommonComponents/WideCardPopup";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import "../../css/components/Stories/Stories.css";
import { reportToolCardArray } from "../../utility/utilities";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";
import PageHeaderSection from "../CommonComponents/PageHeaderSection";
import * as _ from "lodash";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const linkArray = [
  { name: "mapTools", label: "Map Tools", redirectLink: "#mapTools" },
  { name: "savedMaps", label: "My Maps", redirectLink: "#savedMaps" },
];

class MapsSection extends Component {
  render() {
    const {
      data,
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      handleUpdateBoard,
      isLeftMenuOpen,
      userData,
      history,
    } = this.props;
    const sections =
      (data &&
        data.mapsBoard &&
        data.mapsBoard.sections &&
        data.mapsBoard.sections.edges &&
        data.mapsBoard.sections.edges) ||
      [];
    return (
      <div>
        {sections.map((section, index) => {
          return (
            <div key={index}>
              {index === 0 ? null : (
                <div className="recomm-wrapper">
                  <div className="recomm-header-wrap">
                    <h2 className="recomm-hd-title">{section.name}</h2>
                  </div>
                </div>
              )}
              <MapsCards
                section={section}
                openStoryPopup={openStoryPopup}
                openStoryPopupWithUrl={openStoryPopupWithUrl}
                openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                handleUpdateBoard={handleUpdateBoard}
                isLeftMenuOpen={isLeftMenuOpen}
                userData={userData}
                history={history}
                handleLoginPopUp={this.handleLoginPopUp}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

class Maps extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
    refreshPage: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",
      isUpdateBoard: false, // Change this to call the board list api
      openedCardsArray: [],
      boardUpdates: 0,
      searchTerm: "",
    };
  }

  handleUpdateBoard = (refreshPage = false) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: prevState.isUpdateBoard === true ? false : true,
        boardUpdates: prevState.boardUpdates + 1,
        refreshPage,
      };
    });
  };

  onTextChange = (value) => {
    this.setState({ searchTerm: value });
  };

  render() {
    const { refreshPage, searchTerm, isUpdateBoard } = this.state;
    const {
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      isLeftMenuOpen,
      userData,
      history,
    } = this.props;
    return (
      <Fragment>
        <div className="stories footer-margin projectBoard-right-component pb-0">
          <div className="project-board-home-wrapper container">
            <PageHeaderSection
              linkArray={linkArray}
              titleIcon="icon-map"
              title="Maps"
              placeholderText="Search My Maps"
              onTextChange={this.onTextChange}
            />
            <QueryRenderer
              dataFrom={refreshPage ? "STORE_THEN_NETWORK" : ""}
              environment={environment}
              query={graphql`
                query MapsQuery($search: String) {
                  mapsBoard {
                    sections {
                      edges {
                        node {
                          boardCardUses(search: $search) {
                            edges {
                              node {
                                id
                                cardPosition
                                frontFacingComponent {
                                  componentType
                                  name
                                }
                                isFavorite
                                subComponentType
                              }
                            }
                          }
                          id
                          name
                          description
                          layout
                          sectionPosition
                        }
                      }
                    }
                    id
                    name
                    subtitle
                    theme {
                      jsonStyleProperties
                    }
                  }
                }
              `}
              variables={{
                isUpdateBoard,
                search: searchTerm,
              }}
              render={({ error, props }) => {
                if (error) {
                  console.error(error);
                  return <div>Error!</div>;
                }
                if (!props) {
                  return <div>Loading...</div>;
                }
                return (
                  <MapsSection
                    data={props}
                    openStoryPopup={openStoryPopup}
                    openStoryPopupWithUrl={openStoryPopupWithUrl}
                    openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                    handleUpdateBoard={this.handleUpdateBoard}
                    isLeftMenuOpen={isLeftMenuOpen}
                    userData={userData}
                    history={history}
                    handleLoginPopUp={this.handleLoginPopUp}
                  />
                );
              }}
            />
          </div>
        </div>
        <FooterComponent />
      </Fragment>
    );
  }
}

export default Maps;

class MapsCards extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isEditHashTagPopUpVisible: false,
      xOriginForEditHashTagPopUp: 0,
      yOriginForEditHashTagPopUp: 0,

      saveCardFromMore: false,
      data: [],
      openedCardsArray: [],

      isSavedTooltip: false,
      isDeleteMapPopUpVisible: false,
      isMapTitleEditPopUpVisible: false,
      savedCardId: "",
      mapTitle: "",
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    const { section } = this.props;
    let cardsArray = [];
    section &&
      section.node.boardCardUses.edges.map((item) => {
        let mutableObject = Object.assign({}, item);
        mutableObject.isUpdate = false;
        cardsArray.push(mutableObject);
        return cardsArray;
      });
    this.setState({
      data: cardsArray,
    });
    document.addEventListener("mousedown", this.handleFavPage, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { section } = this.props;
    if (!_.isEqual(nextProps.section, section)) {
      let cardsArray = [];
      nextProps.section.node.boardCardUses.edges.map((item) => {
        let mutableObject = Object.assign({}, item);
        mutableObject.isUpdate = false;
        cardsArray.push(mutableObject);
        return cardsArray;
      });
      this.setState({
        data: cardsArray,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleFavPage, false);
  }

  handleFavPage = (e) => {
    if (this.favPageNode.contains(e.target)) {
      return;
    } else {
    }
    this.handleClickOutsideFavPage();
  };

  handleClickOutsideFavPage = (event) => {
    this.hidePopUp();
  };

  buildAllSectionLayouts = (cardEdges) => {
    const sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    const layout = cardEdges.map((edge, index) => {
      const { subComponentType } = edge.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;

      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h: reportToolCard ? 5.3 : 11,
        i: edge.node.id,
      };
    });
    return layout;
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedTooltip: false,
      savedCardId: "",
    });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    const escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    const el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    const cardHeight = el.height;
    const cardHeightInGrid = 1 + cardHeight / 40;

    let newOpenedCardsArray = openedCardsArray;
    let pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({
          i: cardId,
          h: cardHeightInGrid,
        });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  applyOpenedCardHeights = () => {
    const { data, openedCardsArray } = this.state;
    const sectionLayouts = this.buildAllSectionLayouts(data);
    const arrayCardPos = sectionLayouts.map((layout, j) => {
      return layout.map((item, index) => {
        for (var i = 0; i < openedCardsArray.length; i++) {
          if (openedCardsArray[i].i === item.i) {
            return {
              i: item.i,
              w: item.w,
              h: openedCardsArray[i].h,
              x: item.x,
              y: item.y,
            };
          }
        }
        return item;
      });
    });
    return arrayCardPos;
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const { isSaveCardsPopUpVisible } = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard: this.getSelectedCard(cardId),
      });
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;
    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { section } = this.props;
    if (popUpType === "CloseTag") {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "MoreInfo") {
      let selectedCard = null;
      section &&
        section.node.boardCardUses.edges.forEach((item, index) => {
          if (item.node.id === cardId) {
            selectedCard = item;
          }
        });

      this.setState({
        selectedCard,
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "EditTags") {
      this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId,
      });
    }
  };

  handleReRenderCard = (cardId) => {
    const { data } = this.state;
    const arrayData = [];
    const checkId = cardId;
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === checkId) {
        const changedObject = data[i];
        changedObject.isUpdate === true
          ? (changedObject.isUpdate = false)
          : (changedObject.isUpdate = true);
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState(
      {
        data: arrayData,
      },
      () => this.hidePopUp()
    );
  };

  handleDeleteMapPopUpVisible = (id) => {
    const { isDeleteMapPopUpVisible } = this.state;
    if (isDeleteMapPopUpVisible === true) {
      this.setState({
        isDeleteMapPopUpVisible: false,
        selectedCardForMutation: id,
      });
    } else {
      this.setState({
        isDeleteMapPopUpVisible: true,
        selectedCardForMutation: id,
      });
    }
  };

  handleEditMapPopUpVisible = (id, mapTitle) => {
    const { isMapTitleEditPopUpVisible } = this.state;
    if (isMapTitleEditPopUpVisible === true) {
      this.setState({
        isMapTitleEditPopUpVisible: false,
        selectedCardForMutation: id,
        mapTitle,
      });
    } else {
      this.setState({
        isMapTitleEditPopUpVisible: true,
        selectedCardForMutation: id,
        mapTitle,
      });
    }
  };

  handleDeleteMap = () => {
    const { handleUpdateBoard } = this.props;
    const { selectedCardForMutation } = this.state;
    const mutation = graphql`
      mutation MapsMutation($input: DeleteCardFromBoardInput!) {
        deleteCardFromBoard(input: $input) {
          cardDeleted
        }
      }
    `;
    const variables = {
      input: { boardCardUseId: selectedCardForMutation },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.setState({
          isDeleteMapPopUpVisible: false,
          selectedCardForMutation: "",
        });
        handleUpdateBoard(true);
      },
      onError: (err) => console.error(err),
    });
  };

  handleEditMap = (mapTitle) => {
    const { selectedCardForMutation } = this.state;
    // ToDo: Add mutation here
    const mutation = graphql`
      mutation MapsEditMutation($input: UpdateBoardCardUseInput!) {
        updateBoardCardUse(input: $input) {
          boardCardUseNode {
            id
          }
        }
      }
    `;

    const variables = {
      input: {
        boardCardUseId: selectedCardForMutation,
        title: mapTitle,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.handleReRenderCard(selectedCardForMutation);
        this.setState({
          isMapTitleEditPopUpVisible: false,
          selectedCardForMutation: "",
          mapTitle: "",
        });
      },
      onError: (err) => console.error(err),
    });
  };

  closeWideCardPopup = () => {
    this.setState({
      showWideCardPopup: false,
      cardId: "",
    });
  };

  handleWideCardVisible = (cardId) => {
    this.setState({
      showWideCardPopup: true,
      cardId,
    });
  };

  mapsList = () => {
    const {
      handleUpdateBoard,
      userData,
      history,
      handleLoginPopUp,
      openCardFamilyPopupWithUrl,
    } = this.props;
    const {
      data,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
    } = this.state;
    return data.map((item, index) => {
      if (item.node.frontFacingComponent.componentType === "ChartCard") {
        // else if (true) {
        const { subComponentType } = item.node;
        const reportToolCard =
          reportToolCardArray.indexOf(subComponentType) > -1;
        if (reportToolCard) {
          return (
            <div key={item.node.id} className="grid-card">
              <ReportToolCardContainer
                changeCardHeight={this.changeCardHeight}
                isEditingEnabled={false}
                subComponentType={item.node.subComponentType}
                statistics={item.node.statistics}
                isHomeFeed={false}
                isFavorites={false}
                isCloseUpView={false}
                index={index}
                data={item}
                key={item.node.id}
                hideUnHidePopUp={this.hideUnHidePopUp}
                showToolTip={this.showToolTip}
                hideToolTip={this.hideToolTip}
                handleSelectThemePopUp={this.handleSelectThemePopUp}
                handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                newTagsForCardObject={newTagsForCardObject}
                arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                handleArrayOfTagsOfCardObject={
                  this.handleArrayOfTagsOfCardObject
                }
                selectedCardForMutation={selectedCardForMutation}
                isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                handleUpdateBoard={handleUpdateBoard}
                isMapcardsVisible={true}
                userData={userData}
                history={history}
                handleLoginPopUp={handleLoginPopUp}
                openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                isSavedTooltip={isSavedTooltip}
                savedCardId={savedCardId}
                handleReRenderCard={this.handleReRenderCard}
                onCardButtonClick={this.onCardButtonClick}
                fromPage={"maps"}
              />
            </div>
          );
        }
      } else if (item.node.frontFacingComponent.componentType === "MapCard") {
        // else if (true) {
        return (
          <div key={item.node.id}>
            <StoryCardContainer
              changeCardHeight={this.changeCardHeight}
              isEditingEnabled={false}
              isHomeFeed={true}
              isFavorites={false}
              isCloseUpView={false}
              index={index}
              key={item.node.id}
              data={item}
              hideUnHidePopUp={this.hideUnHidePopUp}
              showToolTip={this.showToolTip}
              hideToolTip={this.hideToolTip}
              onCloseUpView={this.handleCloseUpView}
              userData={userData}
              handleSelectThemePopUp={this.handleSelectThemePopUp}
              handleSaveCardsPopUp={this.handleSaveCardsPopUp}
              newTagsForCardObject={newTagsForCardObject}
              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
              handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
              selectedCardForMutation={selectedCardForMutation}
              isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
              isMapcardsVisible={true}
              handleUpdateBoard={handleUpdateBoard}
              isSavedTooltip={isSavedTooltip}
              savedCardId={savedCardId}
              handleReRenderCard={this.handleReRenderCard}
              handleDeleteCollectionPopUpVisible={
                this.handleDeleteMapPopUpVisible
              }
              handleEditMapPopUpVisible={this.handleEditMapPopUpVisible}
              onCardButtonClick={this.onCardButtonClick}
              handleWideCardVisible={this.handleWideCardVisible}
              fromPage={"maps"}
            />
          </div>
        );
      }
      return null;
    });
  };

  getSelectedCard = (cardId) => {
    const { section } = this.props;
    let selectedCard = null;
    if (
      section &&
      section.node &&
      section.node.boardCardUses &&
      section.node.boardCardUses.edges
    ) {
      selectedCard = section.node.boardCardUses.edges.find(
        (item) => item.node.id === cardId
      );
    }
    return selectedCard;
  };

  onCardButtonClick = (cardId, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId),
    });
  };

  onCloseCardButtonModal = () => {
    const { history } = this.props;
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
    history.replace({ ...history.location.pathname });
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId, e) => {
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId,
    });
  };

  render() {
    let layoutArray = this.applyOpenedCardHeights();
    const { section, userData, history, handleUpdateBoard } = this.props;
    const {
      isMapTitleEditPopUpVisible,
      mapTitle,
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      selectedCard,
      showTwitterCardPopup,
      selectedCardForMutation,
      isDeleteMapPopUpVisible,
      showWideCardPopup,
      isToolTipVisible,
      xOrigin,
      yOrigin,
      cardId,
      text,
      data,
      isLeftMenuOpen,
    } = this.state;
    const subComponentType =
      selectedCard && selectedCard.node && selectedCard.node.subComponentType;
    const disabledDownloadButton =
      reportToolCardArray.includes(subComponentType);
    return (
      <div
        onClick={() => this.hidePopUp()}
        ref={(favPageNode) => (this.favPageNode = favPageNode)}
      >
        <div>
          <h2
            className="section-header-title d-block text-left pb-5 pl-5 pl-sm-0"
            id={section.node.name === "Map Tools" ? "mapTools" : "savedMaps"}
          >
            {section.node.name === "My Saved Maps"
              ? "My Maps"
              : section.node.name}
          </h2>
        </div>
        {data.length === 0 ? (
          <div>No Cards found.</div>
        ) : (
          <ResponsiveReactGridLayout
            className={isLeftMenuOpen ? "rgl-menu-open" : "rgl-menu-closed"}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            breakpoints={{ lg: 1350, md: 1050, sm: 768, xs: 480, xxs: 0 }}
            rowHeight={30}
            layouts={{
              lg: layoutArray[3],
              md: layoutArray[3],
              sm: layoutArray[2],
              xs: layoutArray[1],
              xxs: layoutArray[0],
            }}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            isDraggable={false}
            margin={[15, 10]}
            containerPadding={[0, 0]}
            {...this.props}
          >
            {this.mapsList()}
          </ResponsiveReactGridLayout>
        )}
        <ToolTip
          x={xOrigin}
          y={yOrigin}
          isVisible={isToolTipVisible}
          text={text}
        />
        {showCardButtonModal || isSaveCardsPopUpVisible ? (
          <CardButtonsPopUp
            history={history}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleReRenderCard={this.handleReRenderCard}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            isCollections={false}
            displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
            disabledDownloadButton={disabledDownloadButton}
            isFavorites={true}
            fromMap={true}
            isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
            cardId={selectedCardForMutation}
            selectedCard={selectedCard}
            closePopUp={this.onCloseCardButtonModal}
            handleShareCardPopUp={this.handleShareCardPopUp}
          />
        ) : null}
        {showTwitterCardPopup && !disabledDownloadButton && (
          <WideCardPopup
            cardId={selectedCardForMutation}
            userData={userData}
            showFullPage={true}
            showTwitterCardPopup={showTwitterCardPopup}
            history={history}
            isEditingEnabled={false}
            isHomeFeed={true}
          />
        )}

        {showWideCardPopup && (
          <WideCardPopup
            cardId={cardId}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            onCardButtonClick={this.onCardButtonClick}
            history={history}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            handleReRenderCard={() => {
              handleUpdateBoard(true);
            }}
            handleUpdateBoard={handleUpdateBoard}
            isEditingEnabled={false}
            isHomeFeed={false}
          />
        )}

        {isDeleteMapPopUpVisible === true ? (
          <AnnouncementPopup
            closePopUp={this.handleDeleteMapPopUpVisible}
            onOkClick={this.handleDeleteMap}
            announcementMessage={"This will permanently delete this map."}
            announcementPrompt={"Are you sure?"}
            okButtonLabel={"Delete"}
            closeButtonLabel={"Cancel"}
            title={"Delete Map"}
          />
        ) : null}

        {isMapTitleEditPopUpVisible && (
          <MapTitlePopup
            onOkClick={this.handleEditMap}
            closePopUp={this.handleEditMapPopUpVisible}
            mapTitle={mapTitle}
          />
        )}
      </div>
    );
  }
}

class MapTitlePopup extends Component {
  constructor(props) {
    super();
    this.state = {
      mapTitle: props.mapTitle,
    };
  }

  onChangeTitle = (e) => {
    if (e.target.value) {
      this.setState({ mapTitle: e.target.value });
    } else {
      this.setState({ mapTitle: "" });
    }
  };

  render() {
    const { closePopUp, onOkClick } = this.props;
    const { mapTitle } = this.state;

    return (
      <TallModalPopup closePopUp={closePopUp} goBack={closePopUp}>
        <div className={"row mx-0 showMenu h-100"}>
          <div className={"col-12 left-nav h-100"}>
            <div className="title mt-4">
              <h3 className="font-20 pb-4">Map Title</h3>
              <div className="edit-card-right-menu">
                <ButtonComponent
                  disabled={!mapTitle}
                  buttonType="subtle"
                  buttonTitle="Save"
                  onClick={() => onOkClick(mapTitle)}
                />
              </div>
            </div>
            <div className="bsui-leftnavmenu-open">
              <ul>
                <li className="left-menu-header pb-4 pt-0 pl-0">
                  <span className="left-menu-title">Give your map a title</span>
                </li>
                <li className="left-menu-header pb-4 pt-0 pl-0">
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      name="cardTitle"
                      placeholder="Ex. “Health Indicators in Our Area”"
                      onChange={this.onChangeTitle}
                      value={mapTitle}
                      autoComplete="off"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </TallModalPopup>
    );
  }
}
