// collabl-profile-section
import React, { Component, Fragment } from "react";
import Section from "./Section";
import { QueryRenderer, commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { connect } from "react-redux";
import {
  getSubscriptionInfo,
  subscriptionInfoSelector,
  userSelector,
} from "../../actions/user";
import SectionLayoutsMutation from "../../mutations/ProjectBoardUpdateSectionLayoutsMutation";
import SectionContentsMutation from "../../mutations/ProjectBoardUpdateSectionContentsMutation";
import { Editor } from "slate-react";
import Plain from "slate-plain-serializer";
import * as _ from "lodash";
import FooterComponent from "../CommonComponents/FooterComponent";
import AddSectionPopUp from "../CommonComponents/AddSectionPopUp";
import StorySettingsPopUp from "../CommonComponents/StorySettingsPopUp";
import FeaturedImagePopup from "../CommonComponents/FeaturedImagePopup";
import { AnnouncementPopup, ButtonComponent } from "common-components";
import CollaboratorsWorkflow from "../CommonComponents/CollaboratorsWorkflow";
import * as $ from "jquery";

const placeHolderImage = require("../../images-placeholder/profile1.png");
const placeHolderFeaturedImage = require("../../images/createfeatured2.png");

/*const BLOCK_TAGS = {
    blockquote: 'quote',
    p: 'paragraph',
    pre: 'code'
};*/

/*const rules = [
    {
        deserialize(el, next) {
            const type = BLOCK_TAGS[el.tagName.toLowerCase()];
            if (type) {
                return {
                    object: 'block',
                    type: type,
                    data: {
                        className: el.getAttribute('class')
                    },
                    nodes: next(el.childNodes)
                };
            }
        },
        serialize(obj, children) {
            if (obj.object === 'block') {
                switch (obj.type) {
                    case 'paragraph':
                        return <p className={obj.data.get('className')}>{children}</p>;
                    default:
                        break;
                }
            }
        }
    }
];*/

// Create a new serializer instance with our `rules` from above.
//const html = new Html({ rules })

class Projectboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateBoard: false, // Change this to call the board list api
      refreshPage: false,
    };
  }

  componentDidUpdate() {
    const { refreshPage } = this.state;
    if (refreshPage) {
      this.setState({ refreshPage: false });
    }
  }

  handleUpdateBoard = (refreshPage = false, hasUnpublishedChanges = true) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: !prevState.isUpdateBoard,
        refreshPage,
      };
    });
  };

  refreshSubscriptionInfo = () => {
    const { getSubscriptionInfo } = this.props;
    getSubscriptionInfo();
  };

  render() {
    const { refreshPage, isUpdateBoard } = this.state;
    const { selectedBoardId } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        dataFrom={refreshPage ? "STORE_THEN_NETWORK" : ""}
        query={graphql`
          query ProjectBoardAQuery($input: ID!) {
            board(id: $input) {
              sections {
                edges {
                  node {
                    boardCardUses {
                      edges {
                        node {
                          id
                          cardPosition
                          frontFacingComponent {
                            componentType
                            name
                          }
                          isFavorite
                          subComponentType
                        }
                      }
                    }
                    image1 {
                      url
                    }
                    image2 {
                      url
                    }
                    image3 {
                      url
                    }
                    id
                    name
                    description
                    layout
                    sectionPosition
                    sectionLayout
                  }
                }
              }
              id
              collaboratorRole
              invitationLinkRole
              publishAsPublic
              publishOutsidePaywall
              allowCopy
              featureImage {
                id
                url
              }
              isPublishedStory
              hasUnpublishedChanges
              publishedStoryCard {
                id
              }
              name
              subtitle
              metaDescription
              permaLink
              organization
              logoLink
              customDomain
              boardType
              invitationLink
              publishAsPublic
              headerColor
              buttonColor
              headerImage {
                url
              }
              faviconImage {
                url
              }
              theme {
                jsonStyleProperties
              }
              owner {
                boardauthorSet {
                  edges {
                    node {
                      id
                      name
                      userAccount {
                        email
                        username
                      }
                      profileImage {
                        id
                        caption
                        url
                      }
                    }
                  }
                }
              }
              collaborators {
                edges {
                  node {
                    id
                    name
                    userAccount {
                      email
                      visited(boardId: $input) {
                        edges {
                          node {
                            created
                            lastModified
                          }
                        }
                      }
                    }
                    profileImage {
                      id
                      caption
                      url
                    }
                  }
                }
              }
              privateLinkCollaborators {
                edges {
                  node {
                    id
                    briefBio
                    contactInfo
                    name
                    userAccount {
                      email
                      visited {
                        edges {
                          node {
                            created
                            lastModified
                          }
                        }
                      }
                    }
                    profileImage {
                      url
                    }
                  }
                }
              }
              invitations {
                edges {
                  node {
                    email
                  }
                }
              }
              savedArea {
                id
                name
              }
            }
          }
        `}
        variables={{
          input: selectedBoardId,
          isUpdateBoard: isUpdateBoard,
        }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          if (props.board == null) {
            return (
              <div>
                <br />
                You need to log in as a user with permissions to edit this
                collection.
              </div>
            );
          }
          return (
            <ProjectboardConnectionList
              data={props}
              {...this.props}
              handleUpdateBoard={this.handleUpdateBoard}
              hasUnpublishedChanges={props.board.hasUnpublishedChanges}
              refreshSubscriptionInfo={this.refreshSubscriptionInfo}
            />
          );
        }}
      />
    );
  }
}

class ProjectboardConnectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollaboratorDesignVisible: false,
    };
  }

  handleCollaboratorsDesignVisibility = (value) => {
    this.setState({ isCollaboratorDesignVisible: value });
  };

  render() {
    const { isCollaboratorDesignVisible } = this.state;
    const { data, handleUpdateBoard, refreshSubscriptionInfo } = this.props;
    return (
      <div>
        <QueryRenderer
          environment={environment}
          query={graphql`
            query ProjectBoardBQuery {
              myBoards {
                edges {
                  node {
                    id
                    boardType
                    name
                  }
                }
              }
            }
          `}
          render={({ error, props }) => {
            if (error) {
              console.error(error);
              return <div>Error!</div>;
            }
            if (!props) {
              return <div />;
            }
            const collectionList = props;
            // console.error("My boards response from server" , JSON.stringify(props));
            return (
              <div>
                <QueryRenderer
                  environment={environment}
                  query={graphql`
                    query ProjectBoardCQuery {
                      myPages {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  `}
                  render={({ error, props }) => {
                    if (error) {
                      console.error(error);
                      return <div>Error!</div>;
                    }
                    if (!props) {
                      return <div />;
                    }
                    return (
                      <ProjectboardInner
                        {...this.props}
                        collectionList={collectionList}
                        pageList={props}
                        data={data}
                        handleUpdateBoard={handleUpdateBoard}
                        isCollaboratorDesignVisible={
                          isCollaboratorDesignVisible
                        }
                        handleCollaboratorsDesignVisibility={
                          this.handleCollaboratorsDesignVisibility
                        }
                        refreshSubscriptionInfo={refreshSubscriptionInfo}
                      />
                    );
                  }}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

class ProjectboardInner extends Component {
  viewer = null;

  constructor(props) {
    super(props);
    // NOTE: updatedSectionContents is not in state because it does not determine how components
    // render
    this.updatedSectionContents = {};
    this.state = {
      isEditingEnabled: false, // Change this to enable the drag and drop functionality and editing
      updatedSectionLayouts: {},
      value: Plain.deserialize(`${props.data.board.name}`), // Load the initial value from api.
      subtitleValue: Plain.deserialize(`${props.data.board.subtitle}`), // Load the initial value
      // from api.
      isMorePopUpVisible: false,
      isDeleteCollectionPopUpVisible: false,
      sectionArray: [].concat(props.data.board.sections.edges),
      isPublishedPopUpVisible: false,
      isTemplatePopUpVisible: false,
      isCopiedPopUpVisible: false,
      copiedCollection: null,
      isStoryVisible: false,
      publishedStoryId: null,
      collectionId: [],
      isUpdateBoardOnRemove: "",
      saveSectionLayoutApi: false,
      updateBoardApi: false,
      collaboratorFromMore: false,
      addEmail: false,
      collbEmail: "",
      collabEmailArr: [],
      collabSearchResult: null,
      existingCollabAndInvitations: [],
      duplicateCheckArr: [],
      emailAlreadyExistWarning: false,
      //add and delete collaborators
      deleteCollaboratorsEmail: "",
      deleteCollaboratorsId: "",
      addCollaboratorsEmail: "",
      addCollaboratorsId: "",
      isEmailValid: false,
      isDeleteMeAsACollaboratorPopUpVisible: false,
      ownerData: props.data.board.owner.boardauthorSet.edges,
      currentUserData: props.userData.currentUser
        ? props.userData.currentUser.edges[0].node.boardauthorSet.edges[0].node
        : null,
      isSectionPopUpOpen: false,
      showPageSettingPopUp: false,
      selectedValue: "default",
      featureImage: props.avatarEditor
        ? props.avatarEditor
        : props.data.board.featureImage
        ? props.data.board.featureImage.url
        : placeHolderFeaturedImage,
      featureImageId: props.data.board.featureImage
        ? props.data.board.featureImage.id
        : null,
      fromImagePopup: "",
      addNewSectionPosition: null,
      privateLinkCollaborators: [],
      existingInvitations: [],
      avatarEditorRatio: "3:2",
      avatarEditor: null,
      avtarImage: "",
      avtarHeight: 683,
      disableCopyButton: false,
    };
  }

  componentDidMount() {
    const collectionId = [];
    let privateLinkCollaborators = [];
    const { collectionList, history, data } = this.props;
    collectionList.myBoards &&
      collectionList.myBoards.edges.forEach((item, index) => {
        collectionId.push(item.node);
      });
    this.setState({
      collectionId: collectionId,
    });

    //Open story on new tab
    const location = history.location.pathname;
    const locationArr = location.split("&data=");
    if (locationArr[1] === null || locationArr[1] === undefined) {
    } else {
      this.handleStoryVisiblity(locationArr[1]);
    }

    let colabArray = [];
    let existingInvitations = [];
    let duplicateCheckEmail = [];
    duplicateCheckEmail.push(
      data.board.owner.boardauthorSet.edges[0].node.userAccount.email
    );
    data.board.collaborators.edges.map((object, index) => {
      colabArray.push(object.node);
      duplicateCheckEmail.push(object.node.userAccount.email);
      return colabArray && duplicateCheckEmail;
    });
    data.board.invitations.edges.map((object, index) => {
      let collabNoSearch = {
        id: null,
        name: null,
        userAccount: {
          email: object.node.email,
        },
        profileImage: {
          url: require("../../images/favicon1.svg"),
        },
      };
      colabArray.push(collabNoSearch);
      existingInvitations.push(collabNoSearch);
      duplicateCheckEmail.push(object.node.email);
      return colabArray && existingInvitations && duplicateCheckEmail;
    });

    data.board.privateLinkCollaborators.edges.map((object, index) => {
      privateLinkCollaborators.push(
        Object.assign({ privateLinkCollaborator: true }, object.node)
      );
      return privateLinkCollaborators;
    });

    this.setState({
      existingCollabAndInvitations: colabArray,
      duplicateCheckArr: duplicateCheckEmail,
      privateLinkCollaborators,
      existingInvitations,
    });

    // notificationSubscription();

    // open congratulations modal on creation of story
    if (history.location && history.location.state) {
      this.setState({ isPublishedPopUpVisible: true });
      history.replace({ ...history.location, state: null });
    }
    window.name = "bsedit-" + data.board.id;
    $("body").css({ overflow: "scroll" });
    setTimeout(() => {
      $("body").css({ overflow: "" });
    }, 0);
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { isEditingEnabled } = this.state;
    const { data, isLoginPopUpVisible } = this.props;
    if (
      !_.isEqual(nextprops.data.board.sections.edges, data.board.sections.edges)
    ) {
      this.setState({
        sectionArray: [].concat(nextprops.data.board.sections.edges),
      });
    }
    if (
      isLoginPopUpVisible &&
      !nextprops.isLoginPopUpVisible &&
      isEditingEnabled
    ) {
      window.addEventListener("mousedown", this.editCollectionClick, false);
    }

    if (nextprops.data.board.featureImage !== data.board.featureImage) {
      this.setState({ featureImage: nextprops.data.board.featureImage.url });
    }
  }

  featureImageUpdated = (newImageId) => {
    this.setState({
      featureImageId: newImageId,
    });
  };

  uploadFeatureImage = (file, currentUser) => {
    const { parentImageId } = this.props;
    const mutation = graphql`
      mutation ProjectBoardUploadFeatureImageMutation(
        $input: UploadBoardImageInput!
      ) {
        uploadBoardImage(input: $input) {
          success
          boardImageNode {
            id
            name
            url
          }
        }
      }
    `;
    const input = {
      parentImageId,
      file: null,
    };

    var uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    commitMutation(environment, {
      mutation,
      variables: { input },
      uploadables,
      onCompleted: (response, errors) => {
        if (response.uploadBoardImage.success) {
          // console.log('Profile update response from server.', JSON.stringify(response));
          // TODO : check if you are getting a user id then reload the component.
          this.setState({
            featureImageId: response.uploadBoardImage.boardImageNode.id,
          });
        } else {
          console.error(JSON.stringify(response));
          alert("Image upload failed, try again.");
        }
        /*   this.setState(prevState => {
                 return {isWorking: false, saveKey: prevState.saveKey + 1, wasSaved: true, wasError: false}
             });*/
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        alert("Image upload failed, try again.");
        /*  this.setState(prevState => {
                return {isWorking: false, wasError: true, wasSaved: false}
            });*/
        // TODO: Show error message
      },
    });
  };

  handlePublishedPopUp = () => {
    const { isPublishedPopUpVisible } = this.state;
    if (isPublishedPopUpVisible === true) {
      this.setState({
        isPublishedPopUpVisible: false,
      });
    } else {
      this.setState({
        isPublishedPopUpVisible: true,
      });
    }
  };

  handleCopiedPopUp = () => {
    const { isCopiedPopUpVisible } = this.state;
    if (isCopiedPopUpVisible === true) {
      this.setState({
        isCopiedPopUpVisible: false,
      });
    } else {
      this.setState({
        isCopiedPopUpVisible: true,
      });
    }
  };

  handleTemplateCreated = (templateCardId) => {
    this.setState({ isTemplatePopUpVisible: true, templateCardId });
  };

  handleTemplatePopUp = () => {
    const { isTemplatePopUpVisible } = this.state;
    if (isTemplatePopUpVisible === true) {
      this.setState({
        isTemplatePopUpVisible: false,
      });
    } else {
      this.setState({
        isTemplatePopUpVisible: true,
      });
    }
  };

  handleDeleteCollection = () => {
    const { collectionId } = this.state;
    const { data, updatingLeftMenu, history } = this.props;
    const mutation = graphql`
      mutation ProjectBoardCMutation($input: DeleteBoardInput!) {
        deleteBoard(input: $input) {
          boardDeleted
        }
      }
    `;
    const variables = { input: { boardId: data.board.id } };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //console.log('Response from server on board delete .',JSON.stringify(response))
        updatingLeftMenu();
        this.setState({
          isDeleteCollectionPopUpVisible: false,
        });

        if (collectionId.length === 0) {
          history.push("/HOME");
        } else {
          let indexOfCollection;
          collectionId.forEach((item, index) => {
            if (item.id === data.board.id) {
              indexOfCollection = index;
            }
          });
          if (
            indexOfCollection === 0 ||
            collectionId[indexOfCollection - 1] == null
          ) {
            history.push("/HOME");
          } else {
            const id = collectionId[indexOfCollection - 1].id;
            const name = collectionId[indexOfCollection - 1].name;
            history.push("/BOARD/collection/" + id + "/" + name);
          }
        }
      },
      onError: (err) => console.error(err),
    });
  };

  handleDeleteCollectionPopUpVisible = () => {
    const { isDeleteCollectionPopUpVisible } = this.state;
    if (isDeleteCollectionPopUpVisible === true) {
      this.setState({
        isDeleteCollectionPopUpVisible: false,
      });
    } else {
      this.setState({
        isDeleteCollectionPopUpVisible: true,
        isMorePopUpVisible: false,
      });
    }
  };

  handleCollaboratorPopUp = () => {
    const {
      isCollaboratorDesignVisible,
      handleCollaboratorsDesignVisibility,
      history,
    } = this.props;
    if (isCollaboratorDesignVisible === true) {
      window.removeEventListener("mousedown", this.pageClick, false);
      this.setState(
        {
          addEmail: false,
        },
        handleCollaboratorsDesignVisibility(false)
      );
      history.replace({ ...history.location.pathname });
    } else {
      window.addEventListener("mousedown", this.pageClick, false);
      this.setState(
        {
          isMorePopUpVisible: false,
        },
        handleCollaboratorsDesignVisibility(true)
      );
    }
  };

  handleCollaboratorPopUpFromEllipse = () => {
    this.handleCollaboratorPopUp();
    this.setState({
      collaboratorFromMore: false,
    });
  };

  hideAllPopUp = () => {
    const { handleCollaboratorsDesignVisibility } = this.props;
    this.setState(
      {
        isMorePopUpVisible: false,
        isDeleteCollectionPopUpVisible: false,
      },
      handleCollaboratorsDesignVisibility(false)
    );
  };
  handleUpdateBoardOnRemove = () => {
    const { isUpdateBoardOnRemove, saveSectionLayoutApi, updateBoardApi } =
      this.state;
    const { handleUpdateBoard } = this.props;
    if (
      isUpdateBoardOnRemove !== "" &&
      saveSectionLayoutApi === true &&
      updateBoardApi === true
    ) {
      handleUpdateBoard();
    }
  };

  handleUpdateBoard = (id) => {
    this.saveSectionLayouts();
    this.saveSectionContents();
    this.handleUpdateBoardApi();
    this.setState({
      isUpdateBoardOnRemove: id,
    });
    window.removeEventListener("mousedown", this.editCollectionClick, false);
  };

  renderNode = (props) => {
    switch (props.node.type) {
      case "paragraph":
        return <p {...props.attributes}>{props.children}</p>;
      default:
        break;
    }
  };

  onChangeEditableText = ({ value }) => {
    const { document } = this.state.value;
    // When the document changes, save the serialized HTML to API.
    if (value.document !== document) {
      const string = Plain.serialize(value);

      const stringWithoutTag = string.replace(/<(?:.|\n)*?>/gm, ""); // This regular expression
      // removes html tags from
      // string
      // TODO :  persist the value of string to the api
      if (stringWithoutTag.length > 50) {
        alert("Only 50 characters allowed!");
        return;
      }
    }
    this.setState({ value: value });
  };

  onChangeSubtitleText = ({ value }) => {
    const { subtitleValue } = this.state;
    // When the document changes, save the serialized HTML to API.
    if (value.document !== subtitleValue.document) {
      const string = Plain.serialize(value);

      const stringWithoutTag = string.replace(/<(?:.|\n)*?>/gm, ""); // This regular expression
      // removes html from string

      // TODO :  persist the value of string to the api
      if (stringWithoutTag.length > 100) {
        alert("Only 100 characters allowed!");
        return;
      }
    }
    this.setState({ subtitleValue: value });
  };

  handleAddSection = (sectionPosition = null) => {
    document.documentElement.scrollTop = 0;
    this.setState({
      addNewSectionPosition: sectionPosition,
      isSectionPopUpOpen: true,
      isEditingEnabled: true,
    });
  };

  closePopUp = () => {
    const { history } = this.props;
    this.setState({
      isSectionPopUpOpen: false,
      isImageEditorPopUpVisible: false,
    });
    history.replace({ ...history.location.pathname });
  };

  onSaveSectionLayout = (selectedValue) => {
    const { addNewSectionPosition } = this.state;
    const { handleUpdateBoard, openLoginPopUp, selectedBoardId } = this.props;
    this.setState({ selectedValue });

    const mutation = graphql`
      mutation ProjectBoardAMutation($input: CreateBoardSectionInput!) {
        createBoardSection(input: $input) {
          boardSectionNode {
            id
            sectionPosition
          }
        }
      }
    `;
    // find
    const variables = {
      input: {
        boardId: selectedBoardId,
        name: "Untitled Section",
        sectionPosition: addNewSectionPosition,
        sectionLayout: selectedValue,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.setState({ addNewSectionPosition: null });
        handleUpdateBoard(true);
        this.handleUpdateBoard();
        this.closePopUp();
      },
      onError: (err) => {
        console.error("project board mutation:", err);
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          //show login popup
          openLoginPopUp();
        }
      },
    });
  };

  // api call to update board
  handleUpdateBoardApi = () => {
    const {
      value,
      subtitleValue,
      valueString,
      subtitleValueString,
      featureImageId,
    } = this.state;
    const { selectedBoardId } = this.props;
    const titleString = Plain.serialize(value);
    const titleStringWithoutString = titleString.replace(/<(?:.|\n)*?>/gm, "");

    const subtitleString = Plain.serialize(subtitleValue);
    const subtitleStringWithoutString = subtitleString.replace(
      /<(?:.|\n)*?>/gm,
      ""
    );

    if (valueString !== "" || subtitleValueString !== "") {
      const mutation = graphql`
        mutation ProjectBoardBMutation($input: UpdateBoardInput!) {
          updateBoard(input: $input) {
            boardNode {
              id
              name
              subtitle
            }
          }
        }
      `;
      const variables = {
        input: {
          boardId: selectedBoardId,
          name: titleStringWithoutString,
          subtitle: subtitleStringWithoutString,
          featureImageId: featureImageId,
        },
      };

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          this.setState({ updateBoardApi: true });
        },
        onError: (err) => {
          console.error("update board Error from server", err);
        },
      });
    }
  };

  // called when section card layout changes
  handleSectionLayoutChange = (sectionId, layout) => {
    const { isEditingEnabled } = this.state;
    // ignore unless we are in edit layout mode
    if (isEditingEnabled === true) {
      // add new layout of section to pending layouts to save
      this.setState((prevState) => ({
        updatedSectionLayouts: {
          ...prevState.updatedSectionLayouts,
          [sectionId]: layout,
        },
      }));
    }
  };

  // called when section contents (text, images) changes
  onSectionUpdate = (sectionId, content, hasUnpublishedChanges = true) => {
    const { isEditingEnabled } = this.state;
    //console.log("called ProjectboardInner.onSectionUpdate");
    // ignore unless we are in edit layout mode
    if (isEditingEnabled === true) {
      // add new content of section to pending sections to save
      var new_content;
      if (this.updatedSectionContents[sectionId] != null) {
        new_content = this.updatedSectionContents[sectionId];
        if (content) {
          if (content.name != null) {
            new_content.name = content.name;
          }
          if (content.description != null) {
            new_content.description = content.description;
          }
          if (content.image1 != null) {
            new_content.image1 = content.image1;
          }
        }
        /*
        new_content = {
            "name": content.name != null ? content.name : prevState.updatedSectionContents[sectionId].name,
            "description": content.description != null ? content.description : prevState.updatedSectionContents[sectionId].description,
            "image1": content.image1 != null ? content.image1 : prevState.updatedSectionContents[sectionId].image1,
        }
        */
      } else {
        new_content = {
          name: content && content.name != null ? content.name : null,
          description:
            content && content.description != null ? content.description : null,
          image1: content && content.image1 != null ? content.image1 : null,
        };
        this.updatedSectionContents[sectionId] = new_content;
      }
      /*
      return {
          updatedSectionContents: {
              ...prevState.updatedSectionContents,
              [sectionId]: new_content
          }
      }
      */
    }
  };

  handleSliderValue = (e) => {
    const { isEditingEnabled } = this.state;
    if (isEditingEnabled === false) {
      this.setState({
        isEditingEnabled: true,
      });
      window.addEventListener("mousedown", this.editCollectionClick, false);
    } else {
      this.saveSectionLayouts();
      this.saveSectionContents();
      this.handleUpdateBoardApi();
      window.removeEventListener("mousedown", this.editCollectionClick, false);
    }
  };

  handleSaveButton = () => {
    const { isEditingEnabled } = this.state;
    if (isEditingEnabled === true) {
      this.saveSectionLayouts();
      this.saveSectionContents();
      this.handleUpdateBoardApi();
      window.removeEventListener("mousedown", this.editCollectionClick, false);
    }
  };

  editCollectionClick = (e) => {
    const { isEditingEnabled } = this.state;
    if (this.editNode) {
      if (this.editNode.contains(e.target)) {
        return;
      }
      if (
        (this.saveButtonNode && this.saveButtonNode.contains(e.target)) ||
        (this.editSliderNode && this.editSliderNode.contains(e.target))
      ) {
        return;
      }
      if (isEditingEnabled === true && e.target.tagName !== "HTML") {
        // this.handleSliderValue();
        this.saveSectionLayouts();
        this.saveSectionContents();
        this.handleUpdateBoardApi();
        window.removeEventListener(
          "mousedown",
          this.editCollectionClick,
          false
        );
        document
          .getElementById("titleDiv")
          .removeEventListener("mousedown", this.editCollectionClick, false);
      }
    }
  };

  // save all pending section layouts to API and leave edit layout mode
  saveSectionLayouts = () => {
    const { updatedSectionLayouts, openLoginPopUp } = this.state;
    SectionLayoutsMutation(
      JSON.stringify(updatedSectionLayouts),
      () => {
        this.setState({
          isEditingEnabled: false,
          updatedSectionLayouts: {},
          saveSectionLayoutApi: true,
        });
      },
      (err) => {
        console.error("error save section layout", err);
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          //show login popup
          openLoginPopUp();
        }
      }
    );
  };

  // save all pending section contents to API
  saveSectionContents = () => {
    const { handleUpdateBoard, openLoginPopUp, hasUnpublishedChanges } =
      this.props;
    if (
      this.updatedSectionContents &&
      !_.isEmpty(this.updatedSectionContents)
    ) {
      SectionContentsMutation(
        JSON.stringify(this.updatedSectionContents),
        () => {
          this.updatedSectionContents = {};
          handleUpdateBoard(true, hasUnpublishedChanges);
          /*
          this.setState({
              updatedSectionContents: {}
          });
          */
        },
        (err) => {
          if (
            err.errors &&
            err.errors.length &&
            err.errors[0].message === "UserLoginRequired"
          ) {
            openLoginPopUp();
          }
          console.log("error save section contents", err);
        }
      );
    }
  };

  onFocus = () => {
    const { isEditingEnabled } = this.state;
    if (!isEditingEnabled) {
      this.setState({
        isEditingEnabled: true,
      });
      window.addEventListener("mousedown", this.editCollectionClick, false);
    }
  };

  blockClickEvent = (e) => {
    e.stopPropagation();
  };

  handleStoryVisiblity = (storyId) => {
    const { isStoryVisible, publishedStoryId } = this.state;
    if (isStoryVisible === true) {
      this.setState({
        isStoryVisible: false,
        isPublishedPopUpVisible: false,
        publishedStoryId: null,
      });
    } else {
      this.setState({
        isStoryVisible: true,
        isPublishedPopUpVisible: false,
        // publishedStoryId: storyId,
        publishedStoryId:
          publishedStoryId === null ? storyId : publishedStoryId,
      });
    }
  };

  pageClick = (e) => {
    if (this.node !== null && this.node !== undefined) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.handleClickOutside();
    }
    if (this.more !== null && this.more !== undefined) {
      if (this.more.contains(e.target)) {
        return;
      }
      this.handleClickOutside();
    }
  };
  handleClickOutside = () => {
    const { handleCollaboratorsDesignVisibility } = this.props;
    this.setState(
      {
        isPublishChangesPopUpVisible: false,
        isMorePopUpVisible: false,
        addEmail: false,
      },
      () => handleCollaboratorsDesignVisibility(false)
    );
    window.removeEventListener("mousedown", this.pageClick, false);
  };

  handleViewPublishedVersion = () => {
    const { publishedStoryId } = this.state;
    const { data } = this.props;
    if (publishedStoryId !== null) {
      const storyRoute = "/board/pubboard/" + escape(publishedStoryId);
      this.viewer = window.open(storyRoute, "_blank");
    } else if (
      data.board.publishedStoryCard &&
      data.board.publishedStoryCard.id !== null
    ) {
      const storyRoute =
        "/board/pubboard/" + escape(data.board.publishedStoryCard.id);
      this.viewer = window.open(storyRoute, "_blank");
    }
  };

  testWithinSubscriptionLimit = () => {
    const { isOverSubscriptionLimits } = this.props.subscriptionInfo;
    if (isOverSubscriptionLimits === true) {
      return false;
    }
    return true;
  };

  handleCopyCollection = () => {
    const {
      forceShowMembershipPopup,
      data,
      updatingLeftMenuOnCopyCollection,
      refreshSubscriptionInfo,
    } = this.props;
    if (!this.testWithinSubscriptionLimit()) {
      forceShowMembershipPopup();
      return;
    }
    const { disableCopyButton } = this.state;
    if (disableCopyButton === true) {
      return;
    }
    this.setState(
      {
        disableCopyButton: true,
      },
      () => {
        const mutation = graphql`
          mutation ProjectBoardEMutation($input: CopyBoardInput!) {
            copyBoard(input: $input) {
              boardNode {
                id
                name
              }
            }
          }
        `;
        const variables = { input: { boardId: data.board.id } };
        commitMutation(environment, {
          mutation,
          variables,
          onCompleted: (response, errors) => {
            // console.log('Response from server on board delete .',JSON.stringify(response))
            // console.log('Response from server on board copy .',(response))
            if (response.copyBoard.boardNode.id !== "") {
              this.setState({
                copiedCollection: response.copyBoard.boardNode,
                isCopiedPopUpVisible: true,
                showPageSettingPopUp: false,
                disableCopyButton: false,
              });
              // console.log('Response from server on board copy .',(response.copyBoard.boardNode.id))
              updatingLeftMenuOnCopyCollection();
              refreshSubscriptionInfo();
            }
          },
          onError: (err) => {
            console.error(err);
            this.setState({
              disableCopyButton: false,
            });
          },
        });
      }
    );
  };

  handleEditCollaborators = () => {
    this.setState({
      collaboratorFromMore: true,
    });
    this.handleCollaboratorPopUp();
  };

  handleCancelCollaborators = () => {
    this.handleCollaboratorPopUp();
  };

  handleViewStory = () => {
    this.handlePublishedPopUp();
    this.handleViewPublishedVersion();
  };

  handleCopiedStoryView = () => {
    const { copiedCollection } = this.state;
    const { history } = this.props;
    this.handleCopiedPopUp();
    if (copiedCollection) {
      history.push(
        "/BOARD/collection/" + copiedCollection.id + "/" + copiedCollection.name
      );
    }
  };

  handleViewTemplate = () => {
    const { templateCardId } = this.state;
    const { history } = this.props;
    this.handleTemplatePopUp();
    if (templateCardId != null) {
      history.push("/HOME#cl" + templateCardId);
    }
  };

  addNewCollaborators = () => {
    this.setState({
      addEmail: true,
    });
  };

  // onCollbEmailChange = (e) => {
  //     this.setState({
  //         collbEmail: e.target.value
  //     })
  // }

  handleAddEmailToList = () => {
    const { emailAlreadyExistWarning, collabSearchResult, collbEmail } =
      this.state;
    const { data } = this.props;
    const mutation = graphql`
      mutation ProjectBoardGMutation($input: AddBoardCollaboratorInput!) {
        addBoardCollaborator(input: $input) {
          isDuplicate
          isInvitation
          boardNode {
            id
            collaborators {
              edges {
                node {
                  id
                  name
                  userAccount {
                    email
                  }
                  profileImage {
                    id
                    caption
                    url
                  }
                }
              }
            }
            invitations {
              edges {
                node {
                  email
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      input: {
        boardId: data.board.id,
        boardAuthorId: "",
        email: "",
      },
    };
    if (emailAlreadyExistWarning !== true) {
      if (collabSearchResult !== null) {
        this.setState((prevState) => {
          return {
            ...prevState,
            collabEmailArr: [...prevState.collabEmailArr, collabSearchResult],
            duplicateCheckArr: [
              ...prevState.duplicateCheckArr,
              collabSearchResult.userAccount.email,
            ],
            collabSearchResult: null,
            addEmail: false,
          };
        });
        variables.input.boardAuthorId = collabSearchResult.id;
        variables.input.email = "";
      } else {
        let collabNoSearch = {
          id: null,
          name: null,
          userAccount: {
            email: collbEmail,
          },
          profileImage: {
            url: require("../../images/favicon1.svg"),
          },
        };
        if (collbEmail.length > 2) {
          this.setState((prevState) => {
            return {
              ...prevState,
              collabEmailArr: [...prevState.collabEmailArr, collabNoSearch],
              duplicateCheckArr: [...prevState.duplicateCheckArr, collbEmail],
              collabSearchResult: null,
              addEmail: false,
            };
          });
          variables.input.email = collbEmail;
          variables.input.boardAuthorId = "";
        }
      }
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          // console.log('Response from server on Add Board Collaborators Input .',response);
          // console.log(' Collaborators
          // .',(response.updateBoardCollaborators.boardNode.collaborators.edges)) console.log('
          // invitations .',(response.updateBoardCollaborators.boardNode.invitations.edges))
        },
        onError: (err) => console.error(" Collaborators error", err),
      });
    }
  };

  // collab from the search list
  addCollabToList = (object) => {
    const { duplicateCheckArr } = this.state;
    for (let item of duplicateCheckArr) {
      if (item === object.userAccount.email) {
        this.setState({
          emailAlreadyExistWarning: true,
          isEmailValid: false,
        });
        break;
      } else {
        this.setState({
          // emailAlreadyExistWarning: false
        });
      }
    }
    this.setState({
      collabSearchResult: object,
      isEmailValid: true,
    });
  };

  checkEmailFormat = (email) => {
    // console.log('==checkEmailFormat==', email);
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(email) === false) {
      // console.log('Invalid Email Address');
      this.setState({
        isEmailValid: false,
      });
    } else {
      // console.log('Valid Email Address');
      this.setState({
        isEmailValid: true,
      });
    }
  };
  // email for invitation not found in list
  addCollabEmail = (email) => {
    const { duplicateCheckArr } = this.state;
    for (let item of duplicateCheckArr) {
      if (item === email) {
        this.setState({
          emailAlreadyExistWarning: true,
          collabSearchResult: null,
        });
        break;
      } else {
        this.setState({
          emailAlreadyExistWarning: false,
          collabSearchResult: null,
          isEmailValid: false,
        });
      }
    }

    this.checkEmailFormat(email);

    this.setState({
      collbEmail: email,
    });
  };

  handleRemoveSelfFromCollaborators = (object, index) => {
    this.handleDeleteMeAsACollaboratorPopUpVisible();
    this.setState({
      deleteMeAsACollaboratorObject: object,
      deleteMeAsACollaboratorIndex: index,
    });
  };

  handleRemoveCollaborator = (object, index) => {
    const { data } = this.state;
    const mutation = graphql`
      mutation ProjectBoardRemoveCollaboratorMutation(
        $input: DeleteBoardCollaboratorInput!
      ) {
        deleteBoardCollaborator(input: $input) {
          boardNode {
            id
            collaborators {
              edges {
                node {
                  id
                  name
                  userAccount {
                    email
                  }
                  profileImage {
                    id
                    caption
                    url
                  }
                }
              }
            }
            invitations {
              edges {
                node {
                  email
                }
              }
            }
          }
        }
      }
    `;

    let boardAuthorId = object.id;
    const variables = {
      input: {
        boardId: data.board.id,
        boardAuthorId: boardAuthorId,
        email: object.userAccount.email,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // console.log('Response from server on Update Board Collaborators Input
        // .',JSON.stringify(response)) console.log('Response from server on Delete Board
        // Collaborators Input .',response);
        this.handleRemovecollabFromList(object);
      },
      onError: (err) => console.error(" Collaborators error", err),
    });
  };

  handleRemovecollabFromList = (object) => {
    const {
      existingCollabAndInvitations,
      duplicateCheckArr,
      collabEmailArr,
      privateLinkCollaborators,
    } = this.state;
    let index = -1;
    if (existingCollabAndInvitations.length !== 0) {
      let existingCollabAndInvitation = existingCollabAndInvitations.slice();
      index = existingCollabAndInvitation.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        existingCollabAndInvitation.splice(index, 1);
        this.setState({
          existingCollabAndInvitations: existingCollabAndInvitation,
        });
      }
    }
    let duplicateCheckArray = duplicateCheckArr.slice();
    index = duplicateCheckArray.findIndex(
      (email) => email === object.userAccount.email
    );
    if (index >= 0) {
      duplicateCheckArray.splice(index, 1);
      this.setState({
        duplicateCheckArr: duplicateCheckArray,
      });
    }

    if (collabEmailArr.length !== 0) {
      let collabEmailArray = collabEmailArr.slice();
      index = collabEmailArray.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        collabEmailArray.splice(index, 1);
        this.setState({
          collabEmailArr: collabEmailArray,
        });
      }
    }
    if (privateLinkCollaborators.length !== 0) {
      let privateLinkCollaborator = privateLinkCollaborators.slice();
      index = privateLinkCollaborator.findIndex(
        (colab) => colab.userAccount.email === object.userAccount.email
      );
      if (index >= 0) {
        privateLinkCollaborator.splice(index, 1);
        this.setState({
          privateLinkCollaborators: privateLinkCollaborator,
        });
      }
    }
  };

  randerCollaboratorsImages = () => {
    let collabImages = [];
    let collabNames = [];
    const { ownerData } = this.state;
    const { userData, data } = this.props;
    if (
      ownerData[0].node.profileImage !== null &&
      ownerData[0].node.profileImage.url != null &&
      ownerData[0].node.profileImage.url !== ""
    ) {
      collabImages.push(ownerData[0].node.profileImage.url);
      collabNames.push(ownerData[0].node.name);
    } else if (
      userData.currentUser.edges[0].node.userImage != null &&
      userData.currentUser.edges[0].node.userImage.url != null
    ) {
      collabImages.push(userData.currentUser.edges[0].node.userImage.url);
    } else {
      collabImages.push("/images/placeholder/profile1.png");
    }

    if (data.board.collaborators.edges.length !== 0) {
      data.board.collaborators.edges.map((collaborators) => {
        collabNames.push(collaborators.node.name);
        if (collaborators.node.profileImage !== null) {
          collabImages.push(collaborators.node.profileImage.url);
        } else {
          return null;
        }
        return collabNames;
      });
    }

    let reverseArrayCollabImages = collabImages.reverse();
    return (
      <div className="collaborators-images">
        {reverseArrayCollabImages.map((images, index) => {
          //TODO: position must be fix
          return (
            <span key={index}>
              <img
                src={images}
                alt="Collaborators"
                onError={(e) => {
                  e.target.src = placeHolderImage;
                  e.target.onerror = null;
                }}
              />
            </span>
          );
        })}
        <div className="collabNames pb-3">
          {collabNames.map((name, index) => {
            if (index === 1) {
              return (
                <div key={index} className="pr-1">
                  {`, ${name}`}
                </div>
              );
            } else if (index === 2) {
              return (
                <div key={index}>
                  {"and " + (collabNames.length - 2) + " other(s)"}
                </div>
              );
            } else if (index === 0) {
              return <div key={index}>{name}</div>;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  };

  handleDeleteMeAsACollaboratorPopUpVisible = () => {
    const { isDeleteMeAsACollaboratorPopUpVisible } = this.state;
    if (isDeleteMeAsACollaboratorPopUpVisible === true) {
      this.setState({
        isDeleteMeAsACollaboratorPopUpVisible: false,
      });
    } else {
      this.setState({
        isDeleteMeAsACollaboratorPopUpVisible: true,
        isMorePopUpVisible: false,
      });
    }
  };

  handleDeleteMeAsACollaborator = () => {
    const { deleteMeAsACollaboratorObject } = this.state;
    const { data, updatingLeftMenu, history } = this.props;
    const mutation = graphql`
      mutation ProjectBoardHMutation($input: DeleteBoardCollaboratorInput!) {
        deleteBoardCollaborator(input: $input) {
          boardNode {
            id
            collaborators {
              edges {
                node {
                  id
                  name
                  userAccount {
                    email
                  }
                  profileImage {
                    id
                    caption
                    url
                  }
                }
              }
            }
            invitations {
              edges {
                node {
                  email
                }
              }
            }
          }
        }
      }
    `;

    let boardAuthorId = deleteMeAsACollaboratorObject.id;
    const variables = {
      input: {
        boardId: data.board.id,
        boardAuthorId: boardAuthorId,
        email: deleteMeAsACollaboratorObject.userAccount.email,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        updatingLeftMenu();
        history.push("/HOME");
      },
      onError: (err) => console.error(" Collaborators error", err),
    });
  };

  // Websocket update and notification
  notificationForCollaborators = () => {
    const { notificationUpdate } = this.props;
    notificationUpdate();
  };

  onPositionChange = (updatedPositions) => {
    const { sectionArray } = this.state;
    let sectionArr = JSON.parse(JSON.stringify(sectionArray));
    if (
      updatedPositions["boardSectionNode1"] &&
      updatedPositions["boardSectionNode2"]
    ) {
      sectionArr.forEach((item, index) => {
        if (
          item.node &&
          item.node.id &&
          item.node.id === updatedPositions["boardSectionNode1"].id
        ) {
          item.node.sectionPosition =
            updatedPositions["boardSectionNode1"].sectionPosition;
        } else if (
          item.node &&
          item.node.id &&
          item.node.id === updatedPositions["boardSectionNode2"].id
        ) {
          item.node.sectionPosition =
            updatedPositions["boardSectionNode2"].sectionPosition;
        }
      });
      this.setState({ sectionArray: sectionArr, isEditingEnabled: true });
    }
  };

  openPageSettingsPopup = () => {
    const { refreshSubscriptionInfo } = this.props;
    refreshSubscriptionInfo();
    this.setState({ showPageSettingPopUp: true });
  };

  closePageSettingsPopup = () => {
    const { history } = this.props;
    this.setState({ showPageSettingPopUp: false });
    history.replace({ ...history.location.pathname });
  };

  handleImageEditorPopUpVisible = (ratio, file, url, height, style) => {
    const { handleImageEditorPopUpVisible } = this.props;
    this.setState({
      isEditingEnabled: true,
    });
    document
      .getElementById("titleDiv")
      .addEventListener("mousedown", this.editCollectionClick, false);
    handleImageEditorPopUpVisible(ratio, file, url, height, style);
  };

  handleFeatureImageEditorPopUpVisible = (ratio, file, url, height, style) => {
    const { isImageEditorPopUpVisible } = this.state;
    const { history } = this.props;
    this.setState({
      isEditingEnabled: true,
    });
    history.push("#featuredLayout");
    document
      .getElementById("titleDiv")
      .addEventListener("mousedown", this.editCollectionClick, false);
    this.setState({
      isImageEditorPopUpVisible: !isImageEditorPopUpVisible,
      avatarEditorRatio: ratio,
      avatarEditor: file,
      avtarImage: url,
      featureImage: url,
      avtarHeight: height,
      avtarStyle: style,
    });
  };

  onSelectFeaturedImage = (featureImageId, url) => {
    this.setState({ featureImageId, avtarImage: url });
  };

  goBack = () => {
    const { history } = this.props;
    if (history && history.location && history.location.hash) {
      history.goBack();
    } else {
      this.closePopUp();
    }
  };

  handleFeatureImagePopUp = () => {
    const { featureImage } = this.state;
    document.documentElement.scrollTop = 0;
    this.setState(
      {
        fromImagePopup: "feature",
      },
      () => {
        this.handleFeatureImageEditorPopUpVisible(
          "3:2",
          null,
          featureImage,
          null,
          {
            height: "188px",
            width: "283px",
          }
        );
      }
    );
  };

  handleUpdate = (collbEmail) => {
    const { handleUpdateBoard } = this.props;
    let collabNoSearch = {
      id: null,
      name: null,
      userAccount: {
        email: collbEmail,
      },
      profileImage: {
        url: require("../../images/favicon1.svg"),
      },
    };
    if (collbEmail.length > 2) {
      //Update duplicate email List
      this.setState((prevState) => {
        return {
          ...prevState,
          existingInvitations: [
            ...prevState.existingInvitations,
            collabNoSearch,
          ],
          duplicateCheckArr: [...prevState.duplicateCheckArr, collbEmail],
          collabSearchResult: null,
          addEmail: false,
        };
      });
      handleUpdateBoard(true);
    }
  };

  handleIsImagePopUpVisible = (value) => {
    this.setState({ isImageEditorPopUpVisible: value });
  };

  render() {
    this.handleUpdateBoardOnRemove();
    const {
      isSectionPopUpOpen,
      showPageSettingPopUp,
      duplicateCheckArr,
      privateLinkCollaborators,
      existingInvitations,
      existingCollabAndInvitations,
      disableCopyButton,
      sectionArray,
      isEditingEnabled,
      isMorePopUpVisible,
      ownerData,
      currentUserData,
      isStoryVisible,
      value,
      subtitleValue,
      featureImage,
      isPublishedPopUpVisible,
      isTemplatePopUpVisible,
      isCopiedPopUpVisible,
      isDeleteCollectionPopUpVisible,
      isDeleteMeAsACollaboratorPopUpVisible,
      isImageEditorPopUpVisible,
      avatarEditorRatio,
      avtarImage,
      avtarHeight,
      featureImageId,
    } = this.state;
    const {
      data,
      selectedBoardType,
      updatingLeftMenuOnCopyCollection,
      history,
      userData,
      isCollaboratorDesignVisible,
      handleUpdateBoard,
      onCreateNewArea,
      openLoginPopUp,
      isLoginPopUpVisible,
      handleLoginPopUp,
    } = this.props;
    const sortedSectionArray = sectionArray
      .slice(0)
      .sort(
        (item1, item2) =>
          item1.node.sectionPosition - item2.node.sectionPosition
      );
    const privateLink =
      "https://" +
      window.location.hostname +
      "/board" +
      data.board.invitationLink;
    const {
      location: { hash },
    } = history;
    const publishedStoryId =
      data && data.board && data.board.publishedStoryCard
        ? data.board.publishedStoryCard.id
        : "";
    return (
      <Fragment>
        <div>
          <div id="titleDiv" className="title-of-component mx-0 position-fixed">
            <div className="d-flex justify-content-between w-100 flex-wrap">
              <div className="d-flex custom-page-title ml-4 edit-card-right-menu">
                <div className="mt-2 mx-1">
                  <ButtonComponent
                    isLarge={true}
                    buttonType="outlined"
                    iconClass="settings-icon"
                    buttonTitle={"Settings"}
                    onClick={this.openPageSettingsPopup}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div
                  className={
                    "comm-card-wrap d-flex align-items-center " +
                    (isEditingEnabled ? "m-0" : "")
                  }
                  ref={(editSliderNode) => {
                    this.editSliderNode = editSliderNode;
                  }}
                >
                  <label className="toggle-btn-wrap mb-0 mr-1">
                    <input
                      type="checkbox"
                      onChange={(e) => this.handleSliderValue(e)}
                      checked={isEditingEnabled}
                    />
                    <span
                      id="slidericon"
                      className={
                        isEditingEnabled ? "slider editMode" : "slider saveMode"
                      }
                    >
                      {isEditingEnabled ? "Shuffle Cards" : "Cards Locked"}
                    </span>
                  </label>
                </div>
                {data.board.isPublishedStory && (
                  <div className="d-flex custom-page-title edit-card-right-menu ml-1">
                    <div className="mt-2">
                      <ButtonComponent
                        isLarge={true}
                        buttonType="outlined"
                        iconClass="view-icon"
                        buttonTitle={"View"}
                        onClick={this.handleViewPublishedVersion}
                      />
                    </div>
                  </div>
                )}
                <div className="comm-card-wrap d-flex align-items-center ml-1 mt-2 mr-1 mb-2">
                  <div className="edit-card-right-menu">
                    <ButtonComponent
                      isLarge={true}
                      disabled={!isEditingEnabled}
                      buttonType={isEditingEnabled ? "subtle" : "outlined"}
                      onClick={this.handleSaveButton}
                      buttonTitle={isEditingEnabled ? "SAVE" : "SAVED"}
                      ref={(saveButtonNode) => {
                        this.saveButtonNode = saveButtonNode;
                      }}
                    />
                    {isMorePopUpVisible === true ? (
                      <div
                        className="collection-popup-list"
                        onClick={this.hideAllPopUp}
                        ref={(more) => (this.more = more)}
                      >
                        <div
                          className="collection-list-wrap"
                          onClick={this.blockClickEvent}
                        >
                          <ul>
                            {data.board.isPublishedStory === true ? (
                              <li onClick={this.handleViewPublishedVersion}>
                                <a
                                  href="#section"
                                  tabIndex={0}
                                  className="coll-list-section"
                                >
                                  View Published Version
                                </a>
                              </li>
                            ) : null}

                            <li
                              onClick={
                                selectedBoardType === "collection"
                                  ? this.handleCopyCollection
                                  : null
                              }
                            >
                              <a
                                href="#collection"
                                tabIndex={0}
                                className={
                                  selectedBoardType === "collection"
                                    ? "coll-list-section"
                                    : "coll-list-section disabled"
                                }
                              >
                                Make a Copy
                              </a>
                            </li>

                            <li onClick={this.handleEditCollaborators}>
                              <a
                                href="#collaborators"
                                tabIndex={0}
                                className="coll-list-section"
                              >
                                Edit Collaborators
                              </a>
                            </li>
                            <li
                              className="coll-delete-btn"
                              onClick={
                                ownerData[0].node.userAccount.username ===
                                userData.currentUser.edges[0].node.username
                                  ? selectedBoardType === "collection"
                                    ? this.handleDeleteCollectionPopUpVisible
                                    : null
                                  : () =>
                                      this.handleRemoveSelfFromCollaborators(
                                        currentUserData
                                      )
                              }
                            >
                              <a
                                href="#delete"
                                tabIndex={0}
                                className={
                                  ownerData[0].node.userAccount.username ===
                                    userData.currentUser.edges[0].node
                                      .username && selectedBoardType === "page"
                                    ? "disabled"
                                    : "coll-list-btn"
                                }
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "pt-5 footer-margin " +
              (isStoryVisible === true
                ? "projectBoard-right-component withStory"
                : "projectBoard-right-component")
            }
            ref={(editNode) => {
              this.editNode = editNode;
            }}
          >
            <div className="pt-sm-0 pt-5">
              <div className={"board-title-wrapper board-edit-wrap"}>
                <div className="row d-flex">
                  <div className="flex-1 pl-4">
                    <span className="board-edit-title-txt">
                      Title & Description
                    </span>
                    <div
                      className="board-edit-title"
                      onClick={this.blockClickEvent}
                    >
                      <Editor
                        value={value}
                        onChange={this.onChangeEditableText}
                        renderNode={this.renderNode}
                        onFocus={this.onFocus}
                      />
                    </div>
                    <div
                      className="board-edit-subtitle"
                      onClick={this.blockClickEvent}
                    >
                      <Editor
                        placeholder="Add subtitle or description!"
                        value={subtitleValue}
                        onChange={this.onChangeSubtitleText}
                        renderNode={this.renderNode}
                        onFocus={this.onFocus}
                      />
                      <span className="subtitle-cou" />
                    </div>
                    <div>
                      <span className="board-edit-title-txt pb-2">
                        Authors & Collaborators
                      </span>
                      <a href="#collaborators" tabIndex={0}>
                        <span
                          className="edit-profile-title blue-text-color pb-3"
                          onClick={this.handleCollaboratorPopUpFromEllipse}
                        >
                          {this.randerCollaboratorsImages()}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="px-4 projectboardMainContainer">
                    <span className="board-edit-title-txt">
                      {isEditingEnabled === true ? "Featured Image" : null}
                    </span>
                    <div className="feature-box image-box ">
                      <div
                        className={"d-flex banner-image clickable"}
                        onClick={() => this.handleFeatureImagePopUp()}
                      >
                        <img
                          src={featureImage}
                          alt=""
                          className="gallery"
                          style={{ height: "216px", width: "324px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isCollaboratorDesignVisible === true ? (
                  <CollaboratorsWorkflow
                    goBack={this.goBack}
                    hash={hash}
                    closePopUp={this.handleCancelCollaborators}
                    ownerData={data.board.owner.boardauthorSet.edges[0].node}
                    userData={userData}
                    onUpdateCollaborators={this.handleUpdate}
                    existingCollab={existingCollabAndInvitations.filter(
                      (item) =>
                        item.userAccount.visited &&
                        item.userAccount.visited.edges &&
                        item.userAccount.visited.edges.length > 0
                    )}
                    existingInvitations={existingInvitations.concat(
                      existingCollabAndInvitations.filter(
                        (item) =>
                          item.userAccount.visited == null ||
                          item.userAccount.visited.edges === null ||
                          item.userAccount.visited.edges.length === 0
                      )
                    )}
                    privateLinkCollaborators={privateLinkCollaborators}
                    duplicateCheckArr={duplicateCheckArr}
                    privateLink={privateLink}
                    handleRemovecollabFromList={this.handleRemovecollabFromList}
                    updatingLeftMenuOnCopyCollection={
                      updatingLeftMenuOnCopyCollection
                    }
                    history={history}
                    collaboratorRole={"edit"}
                    isHideModalTop={true}
                  />
                ) : null}
              </div>
              <div>
                <div className="Card-Wrap">
                  {sortedSectionArray.map((section, index) => {
                    return (
                      <Section
                        key={index + section.node.id}
                        index={index}
                        {...this.props}
                        sectionArray={sortedSectionArray}
                        isEditingEnabled={isEditingEnabled}
                        sectionData={section}
                        onCardsLayoutChange={this.handleSectionLayoutChange}
                        onFocus={this.onFocus}
                        publishedStoryId={publishedStoryId}
                        handleUpdate={handleUpdateBoard}
                        handleUpdateBoard={this.handleUpdateBoard}
                        handleStoryVisiblity={this.handleStoryVisiblity}
                        userData={userData}
                        onCreateNewArea={onCreateNewArea}
                        history={history}
                        handleLoginPopUp={openLoginPopUp}
                        onPositionChange={this.onPositionChange}
                        handleImageEditorPopUpVisible={
                          this.handleImageEditorPopUpVisible
                        }
                        onSectionUpdate={this.onSectionUpdate}
                        handleSliderValue={this.handleSliderValue}
                        isLoginPopUpVisible={isLoginPopUpVisible}
                        handleAddSection={this.handleAddSection}
                        isPublishedStory={
                          data ? data.board.isPublishedStory : null
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="ProjectBoard-name-section ProjectBoard-name-edit text-right">
                <ButtonComponent
                  buttonType="link"
                  iconClass="icon-addSection"
                  buttonTitle={"Add New Section Above"}
                  onClick={(e) => this.handleAddSection()}
                />
              </div>

              {isPublishedPopUpVisible === true ? (
                <AnnouncementPopup
                  closePopUp={this.handlePublishedPopUp}
                  onOkClick={this.handleViewStory}
                  announcementMessage={
                    "your story has been created and <br> is now available for viewing."
                  }
                  announcementPrompt={
                    "Did you want to VIEW your <br/> published story?"
                  }
                  closeButtonLabel={"Edit Story"}
                  title={"CONGRATS"}
                />
              ) : null}
              {isTemplatePopUpVisible === true ? (
                <AnnouncementPopup
                  closePopUp={this.handleTemplatePopUp}
                  onOkClick={this.handleViewTemplate}
                  announcementMessage={
                    "your template has been created and <br> is now available for viewing."
                  }
                  announcementPrompt={
                    "Did you want to VIEW your <br/> published template?"
                  }
                  closeButtonLabel={"Not Yet"}
                  title={"CONGRATS"}
                />
              ) : null}

              {isCopiedPopUpVisible === true ? (
                <AnnouncementPopup
                  closePopUp={this.handleCopiedPopUp}
                  onOkClick={this.handleCopiedStoryView}
                  announcementMessage={
                    'A copy has been created with the name "COPY OF" and is saved under "My Stories".'
                  }
                  announcementPrompt={""}
                  closeButtonLabel={"Close"}
                  title={"COPY MADE"}
                />
              ) : null}
            </div>
            {isSectionPopUpOpen && (
              <AddSectionPopUp
                closePopUp={this.closePopUp}
                onSaveSectionLayout={this.onSaveSectionLayout}
              />
            )}
            {showPageSettingPopUp && (
              <StorySettingsPopUp
                history={history}
                closePopUp={this.closePageSettingsPopup}
                data={data}
                updatingLeftMenuOnCopyCollection={
                  updatingLeftMenuOnCopyCollection
                }
                handleUpdateBoard={handleUpdateBoard}
                handleLoginPopUp={openLoginPopUp}
                userData={userData}
                privateLink={privateLink}
                existingCollab={existingCollabAndInvitations.filter(
                  (item) =>
                    item.userAccount.visited &&
                    item.userAccount.visited.edges &&
                    item.userAccount.visited.edges.length > 0
                )}
                existingInvitations={existingInvitations.concat(
                  existingCollabAndInvitations.filter(
                    (item) =>
                      item.userAccount.visited == null ||
                      item.userAccount.visited.edges === null ||
                      item.userAccount.visited.edges.length === 0
                  )
                )}
                privateLinkCollaborators={privateLinkCollaborators}
                duplicateCheckArr={duplicateCheckArr}
                onUpdateCollaborators={this.handleUpdate}
                handleRemovecollabFromList={this.handleRemovecollabFromList}
                ownerData={data.board.owner.boardauthorSet.edges[0].node}
                handleTemplateCreated={this.handleTemplateCreated}
                handleViewPublishedVersion={this.handleViewPublishedVersion}
                handleCopyCollection={this.handleCopyCollection}
                disableCopyButton={disableCopyButton}
                handleIsImagePopUpVisible={this.handleIsImagePopUpVisible}
                handleDeleteStory={
                  ownerData[0].node.userAccount.username ===
                  userData.currentUser.edges[0].node.username
                    ? this.handleDeleteCollectionPopUpVisible
                    : () =>
                        this.handleRemoveSelfFromCollaborators(currentUserData)
                }
              />
            )}
            {isDeleteCollectionPopUpVisible === true ? (
              <AnnouncementPopup
                closePopUp={this.handleDeleteCollectionPopUpVisible}
                onOkClick={this.handleDeleteCollection}
                announcementMessage={
                  "This will permanently delete this story for you, your collaborators, and all embedded links."
                }
                announcementPrompt={"Are you sure?"}
                okButtonLabel={"Delete"}
                closeButtonLabel={"Cancel"}
                title={"Delete Story"}
              />
            ) : null}
            {/*delete me as a collaborator popUp*/}
            {isDeleteMeAsACollaboratorPopUpVisible === true ? (
              <AnnouncementPopup
                closePopUp={this.handleDeleteMeAsACollaboratorPopUpVisible}
                onOkClick={this.handleDeleteMeAsACollaborator}
                announcementMessage={
                  "Only the owner of a story can delete the story. <br/><br/> This will delete you as a collaborator."
                }
                announcementPrompt={"Are you sure?"}
                okButtonLabel={"Yes"}
                closeButtonLabel={"Cancel"}
                title={"Delete Me As Collaborator"}
              />
            ) : null}

            {isImageEditorPopUpVisible && (
              <FeaturedImagePopup
                avatarEditorRatio={avatarEditorRatio}
                avatarImage={avtarImage}
                avatarHeight={avtarHeight}
                hash={hash}
                userData={userData}
                data={data}
                handleUpdateBoard={handleUpdateBoard}
                handleUpdate={this.handleUpdateBoard}
                handleLoginPopUp={handleLoginPopUp}
                featureImageId={featureImageId}
                uploadFeatureImage={this.uploadFeatureImage}
                featureImageUpdated={this.featureImageUpdated}
                closePopUp={() => {
                  this.handleIsImagePopUpVisible(false);
                }}
              />
            )}
          </div>
          <FooterComponent />
        </div>
      </Fragment>
    );
  }
}

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state),
  userDataRedux: userSelector(state),
});

const mapDispatch = {
  getSubscriptionInfo,
};
export default connect(mapStatus, mapDispatch)(Projectboard);
