/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOrderInput = {|
  paymentIntentId: string,
  qty?: ?number,
  applyCredit?: ?boolean,
  clientMutationId?: ?string,
|};
export type PurchaseCouponsPopupUpdateOrderwithInputQtyMutationVariables = {|
  input: UpdateOrderInput
|};
export type PurchaseCouponsPopupUpdateOrderwithInputQtyMutationResponse = {|
  +updateOrder: ?{|
    +clientSecret: ?string,
    +discountText: ?string,
    +orderAmount: ?number,
    +paymentIntentId: ?string,
    +success: ?boolean,
  |}
|};
export type PurchaseCouponsPopupUpdateOrderwithInputQtyMutation = {|
  variables: PurchaseCouponsPopupUpdateOrderwithInputQtyMutationVariables,
  response: PurchaseCouponsPopupUpdateOrderwithInputQtyMutationResponse,
|};
*/


/*
mutation PurchaseCouponsPopupUpdateOrderwithInputQtyMutation(
  $input: UpdateOrderInput!
) {
  updateOrder(input: $input) {
    clientSecret
    discountText
    orderAmount
    paymentIntentId
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrderInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrderPayload",
    "kind": "LinkedField",
    "name": "updateOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentIntentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseCouponsPopupUpdateOrderwithInputQtyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchaseCouponsPopupUpdateOrderwithInputQtyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PurchaseCouponsPopupUpdateOrderwithInputQtyMutation",
    "operationKind": "mutation",
    "text": "mutation PurchaseCouponsPopupUpdateOrderwithInputQtyMutation(\n  $input: UpdateOrderInput!\n) {\n  updateOrder(input: $input) {\n    clientSecret\n    discountText\n    orderAmount\n    paymentIntentId\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3821a7fda5d591e1b5e4f2e54aea1a1';

module.exports = node;
