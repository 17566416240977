import React, { Component } from 'react';
import withWideCardContainer from './WideCardContainer';
import WideExportFamilyADIDataCardContainer from './WideExportFamilyADIDataCardContainer';

class WideExportADIDataCardContainer extends Component {

  componentWillReceiveProps(nextProps) {

  }

  componentDidMount() {

  }

  getSubComponent = () => {
    return <WideExportFamilyADIDataCardContainer {...this.props}/>;
  };

  render() {
    return (
      <div className='card border'>
        {this.getSubComponent()}
      </div>
    );
  }
}

export default withWideCardContainer(WideExportADIDataCardContainer);
