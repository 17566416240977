import React, { Component, Fragment } from "react";
import { Responsive } from "react-grid-layout";
import WidthProvider from "../ProjectBoard/ProjectBoardWidthProvider";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import ReportCardContainer from "../Card/ReportCardContainer";
import AdiMapCardContainer from "../Card/AdiMapCardContainer";
import DotMapCardContainer from "../Card/DotMapCardContainer";
import ServiceAreaMapCardContainer from "../Card/ServiceAreaMapCardContainer";
import IndicatorMapCardContainer from "../Card/IndicatorMapCardContainer";
import ChartCardContainer from "../Card/ChartCardContainer";
import StoryCardContainer from "../Card/StoryCardContainer";
import { ToolTip } from "common-components";
import FooterComponent from "../CommonComponents/FooterComponent";
import ReportToolCardContainer from "../Card/ReportToolCardContainer";
import { reportToolCardArray } from "../../utility/utilities";
import PageHeaderSection from "../CommonComponents/PageHeaderSection";
import WideCardPopup from "../CommonComponents/WideCardPopup";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class FavouritesCards extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      xOriginForMorePopUp: 0,
      yOriginForMorePopUp: 0,

      isEditHashTagPopUpVisible: false,
      xOriginForEditHashTagPopUp: 0,
      yOriginForEditHashTagPopUp: 0,

      saveCardFromMore: false,
      data: [],
      openedCardsArray: [],

      isSavedTooltip: false,
      savedCardId: "",
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    var cardsArray = [];
    const { section } = this.props;
    section.node.boardCardUses.edges.map((item) => {
      var mutableObject = Object.assign({}, item);
      mutableObject.isUpdate = false;
      cardsArray.push(mutableObject);
      return cardsArray;
    });
    this.setState({
      data: cardsArray,
    });
    document.addEventListener("mousedown", this.handleFavPage, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleFavPage, false);
  }

  handleFavPage = (e) => {
    if (this.favPageNode.contains(e.target)) {
      return;
    } else {
    }
    this.handleClickOutsideFavPage();
  };

  handleClickOutsideFavPage = (event) => {
    this.hidePopUp();
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      const { subComponentType } = edge.node;
      const reportToolCard = reportToolCardArray.indexOf(subComponentType) > -1;

      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h: reportToolCard ? 5.3 : 11,
        i: edge.node.id,
      };
    });
    return layout;
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedTooltip: false,
      savedCardId: "",
    });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    var escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    var el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + cardHeight / 40;

    var newOpenedCardsArray = openedCardsArray;
    var pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({
          i: cardId,
          h: cardHeightInGrid,
        });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  applyOpenedCardHeights = () => {
    const { openedCardsArray } = this.state;
    const { section } = this.props;
    var sectionLayouts = this.buildAllSectionLayouts(
      section.node.boardCardUses.edges
    );
    var arrayCardPos = sectionLayouts.map((layout, j) => {
      return layout.map((item, index) => {
        for (var i = 0; i < openedCardsArray.length; i++) {
          if (openedCardsArray[i].i === item.i) {
            return {
              i: item.i,
              w: item.w,
              h: openedCardsArray[i].h,
              x: item.x,
              y: item.y,
            };
          }
        }
        return item;
      });
    });
    return arrayCardPos;
  };

  getSelectedCard = (cardId) => {
    const { section } = this.props;
    let selectedCard = null;
    if (
      section &&
      section.node &&
      section.node.boardCardUses &&
      section.node.boardCardUses.edges
    ) {
      selectedCard = section.node.boardCardUses.edges.find(
        (item) => item.node.id === cardId
      );
    }
    return selectedCard;
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
    const { isSaveCardsPopUpVisible } = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard: this.getSelectedCard(cardId),
      });
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      showAreaCardPopup,
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
    } = this.state;
    if (showAreaCardPopup) {
      this.setState({ showAreaCardPopup: false });
    }
    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    if (popUpType === "CloseTag") {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "MoreInfo") {
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "EditTags") {
      this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId,
      });
    }
  };

  handleWideCardVisible = (cardId) => {
    this.setState({
      showWideCardPopup: true,
      cardId,
    });
  };

  closeWideCardPopup = () => {
    this.setState({
      showWideCardPopup: false,
      cardId: "",
    });
  };

  onCardButtonClick = (cardId, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId),
    });
  };

  onCloseCardButtonModal = () => {
    const { history } = this.props;
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
    history.replace({ ...history.location.pathname });
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId) => {
    const { section } = this.props;
    let selectedCard = null;
    section &&
      section.node &&
      section.node.boardCardUses &&
      section.node.boardCardUses.edges &&
      section.node.boardCardUses.edges.forEach((item) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
        return selectedCard;
      });
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId,
      selectedCard,
    });
  };

  render() {
    let layoutArray = this.applyOpenedCardHeights();
    const {
      section,
      isLeftMenuOpen,
      handleUpdateBoard,
      userData,
      openCardFamilyPopupWithUrl,
      history,
      handleLoginPopUp,
      openStoryPopup,
      openStoryPopupWithUrl,
    } = this.props;
    const {
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      showTwitterCardPopup,
      selectedCard,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
      showWideCardPopup,
      cardId,
    } = this.state;
    const subComponentType =
      selectedCard && selectedCard.node && selectedCard.node.subComponentType;
    const disabledDownloadButton =
      selectedCard &&
      selectedCard.node &&
      selectedCard.node.frontFacingComponent &&
      (selectedCard.node.frontFacingComponent.componentType === "StoryCard" ||
        reportToolCardArray.includes(subComponentType));
    return (
      <div
        onClick={() => this.hidePopUp()}
        ref={(favPageNode) => (this.favPageNode = favPageNode)}
      >
        {section && section.node.boardCardUses.edges.length === 0 ? (
          <div>No Cards found.</div>
        ) : (
          <ResponsiveReactGridLayout
            className={isLeftMenuOpen ? "rgl-menu-open" : "rgl-menu-closed"}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            breakpoints={{ lg: 1350, md: 1050, sm: 768, xs: 480, xxs: 0 }}
            rowHeight={30}
            layouts={{
              lg: layoutArray[3],
              md: layoutArray[3],
              sm: layoutArray[2],
              xs: layoutArray[1],
              xxs: layoutArray[0],
            }}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            isDraggable={false}
            margin={[15, 10]}
            containerPadding={[0, 0]}
            {...this.props}
          >
            {section &&
              section.node.boardCardUses.edges.map((item, index) => {
                if (
                  item.node.frontFacingComponent.componentType === "ReportCard"
                ) {
                  return (
                    <div key={item.node.id}>
                      <ReportCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        onCloseUpView={this.handleCloseUpView}
                        onCardButtonClick={this.onCardButtonClick}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isSavedTooltip={isSavedTooltip}
                        userData={userData}
                        savedCardId={savedCardId}
                      />
                    </div>
                  );
                } else if (
                  item.node.frontFacingComponent.componentType === "AdiMapCard"
                ) {
                  return (
                    <div key={item.node.id}>
                      <AdiMapCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleWideCardVisible={this.handleWideCardVisible}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else if (
                  item.node.frontFacingComponent.componentType ===
                  "ServiceAreaMapCard"
                ) {
                  return (
                    <div key={item.node.id}>
                      <ServiceAreaMapCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleWideCardVisible={this.handleWideCardVisible}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else if (
                  item.node.frontFacingComponent.componentType ===
                  "IndicatorMapCard"
                ) {
                  return (
                    <div key={item.node.id}>
                      <IndicatorMapCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleWideCardVisible={this.handleWideCardVisible}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else if (
                  item.node.frontFacingComponent.componentType === "DotMapCard"
                ) {
                  return (
                    <div key={item.node.id}>
                      <DotMapCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        subComponentType={item.node.subComponentType}
                        statistics={item.node.statistics}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        handleUpdateBoard={handleUpdateBoard}
                        isMapVisible={true}
                        userData={userData}
                        openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleWideCardVisible={this.handleWideCardVisible}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else if (
                  item.node.frontFacingComponent.componentType === "ChartCard"
                ) {
                  // else if (true) {
                  const { subComponentType } = item.node;
                  const reportToolCard =
                    reportToolCardArray.indexOf(subComponentType) > -1;
                  if (reportToolCard) {
                    return (
                      <div key={item.node.id} className="grid-card">
                        <ReportToolCardContainer
                          changeCardHeight={this.changeCardHeight}
                          isEditingEnabled={false}
                          subComponentType={item.node.subComponentType}
                          statistics={item.node.statistics}
                          isHomeFeed={false}
                          isFavorites={true}
                          isCloseUpView={false}
                          index={index}
                          key={item.node.id}
                          data={item}
                          hideUnHidePopUp={this.hideUnHidePopUp}
                          showToolTip={this.showToolTip}
                          hideToolTip={this.hideToolTip}
                          handleSelectThemePopUp={this.handleSelectThemePopUp}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          newTagsForCardObject={newTagsForCardObject}
                          arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                          handleArrayOfTagsOfCardObject={
                            this.handleArrayOfTagsOfCardObject
                          }
                          selectedCardForMutation={selectedCardForMutation}
                          isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                          handleUpdateBoard={handleUpdateBoard}
                          isMapcardsVisible={true}
                          userData={userData}
                          history={history}
                          handleLoginPopUp={handleLoginPopUp}
                          openCardFamilyPopupWithUrl={
                            openCardFamilyPopupWithUrl
                          }
                          isSavedTooltip={isSavedTooltip}
                          savedCardId={savedCardId}
                          onCardButtonClick={this.onCardButtonClick}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={item.node.id}>
                        <ChartCardContainer
                          changeCardHeight={this.changeCardHeight}
                          isEditingEnabled={false}
                          subComponentType={item.node.subComponentType}
                          statistics={item.node.statistics}
                          isHomeFeed={false}
                          isFavorites={true}
                          isCloseUpView={false}
                          index={index}
                          key={item.node.id}
                          data={item}
                          hideUnHidePopUp={this.hideUnHidePopUp}
                          showToolTip={this.showToolTip}
                          hideToolTip={this.hideToolTip}
                          handleSelectThemePopUp={this.handleSelectThemePopUp}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          newTagsForCardObject={newTagsForCardObject}
                          arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                          handleArrayOfTagsOfCardObject={
                            this.handleArrayOfTagsOfCardObject
                          }
                          selectedCardForMutation={selectedCardForMutation}
                          isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                          handleUpdateBoard={handleUpdateBoard}
                          isMapcardsVisible={true}
                          userData={userData}
                          history={history}
                          handleLoginPopUp={handleLoginPopUp}
                          openCardFamilyPopupWithUrl={
                            openCardFamilyPopupWithUrl
                          }
                          isSavedTooltip={isSavedTooltip}
                          savedCardId={savedCardId}
                          handleWideCardVisible={this.handleWideCardVisible}
                          onCardButtonClick={this.onCardButtonClick}
                        />
                      </div>
                    );
                  }
                } else if (
                  item.node.frontFacingComponent.componentType === "MapCard"
                ) {
                  // else if (true) {
                  return (
                    <div key={item.node.id}>
                      <StoryCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        isHomeFeed={false}
                        isFavorites={true}
                        isCloseUpView={false}
                        index={index}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        onCloseUpView={this.handleCloseUpView}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        isMapcardsVisible={true}
                        userData={userData}
                        handleUpdateBoard={handleUpdateBoard}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        handleWideCardVisible={this.handleWideCardVisible}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={item.node.id}>
                      <StoryCardContainer
                        changeCardHeight={this.changeCardHeight}
                        isEditingEnabled={false}
                        isHomeFeed={false}
                        isFavorites={true}
                        isFavorite={true}
                        isCloseUpView={false}
                        index={index}
                        setFromPage={this.setFromPage}
                        key={item.node.id}
                        data={item}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        showToolTip={this.showToolTip}
                        hideToolTip={this.hideToolTip}
                        onCloseUpView={this.handleCloseUpView}
                        handleSelectThemePopUp={this.handleSelectThemePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        newTagsForCardObject={newTagsForCardObject}
                        arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                        handleArrayOfTagsOfCardObject={
                          this.handleArrayOfTagsOfCardObject
                        }
                        selectedCardForMutation={selectedCardForMutation}
                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                        userData={userData}
                        isMapcardsVisible={false}
                        handleUpdateBoard={handleUpdateBoard}
                        openStoryPopup={openStoryPopup}
                        openStoryPopupWithUrl={openStoryPopupWithUrl}
                        isSavedTooltip={isSavedTooltip}
                        savedCardId={savedCardId}
                        onCardButtonClick={this.onCardButtonClick}
                      />
                    </div>
                  );
                }
              })}
          </ResponsiveReactGridLayout>
        )}
        <ToolTip
          x={xOrigin}
          y={yOrigin}
          isVisible={isToolTipVisible}
          text={text}
        />
        {showCardButtonModal || isSaveCardsPopUpVisible ? (
          <CardButtonsPopUp
            history={history}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            handleReRenderCard={() => {
              handleUpdateBoard(true);
            }}
            isCollections={false}
            displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
            disabledDownloadButton={disabledDownloadButton}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            isFavorites={true}
            isFavorite={true}
            cardId={selectedCardForMutation}
            selectedCard={selectedCard}
            closePopUp={this.onCloseCardButtonModal}
            handleShareCardPopUp={this.handleShareCardPopUp}
          />
        ) : null}
        {showWideCardPopup && (
          <WideCardPopup
            cardId={cardId}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            onCardButtonClick={this.onCardButtonClick}
            history={history}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            handleReRenderCard={() => {
              handleUpdateBoard(true);
            }}
            handleUpdateBoard={handleUpdateBoard}
            isEditingEnabled={false}
            isHomeFeed={false}
            isFavorites={true}
          />
        )}

        {showTwitterCardPopup && !disabledDownloadButton && (
          <WideCardPopup
            cardId={cardId}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            onCardButtonClick={this.onCardButtonClick}
            history={history}
            showFullPage={true}
            showTwitterCardPopup={showTwitterCardPopup}
            handleReRenderCard={() => {
              handleUpdateBoard(true);
            }}
            handleUpdateBoard={handleUpdateBoard}
            isEditingEnabled={false}
            isHomeFeed={false}
            isFavorites={true}
          />
        )}
      </div>
    );
  }
}

class FavoritesSection extends Component {
  render() {
    const {
      data,
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      handleUpdateBoard,
      isLeftMenuOpen,
      userData,
      history,
    } = this.props;
    return (
      <div>
        {data.favoritesBoard.sections.edges.map((section, index) => {
          return (
            <div key={index}>
              {index === 0 ? null : (
                <div className="recomm-wrapper">
                  <div className="recomm-header-wrap">
                    <h2 className="recomm-hd-title">{section.name}</h2>
                  </div>
                </div>
              )}
              <FavouritesCards
                section={section}
                openStoryPopup={openStoryPopup}
                openStoryPopupWithUrl={openStoryPopupWithUrl}
                openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                handleUpdateBoard={handleUpdateBoard}
                isLeftMenuOpen={isLeftMenuOpen}
                userData={userData}
                history={history}
                handleLoginPopUp={this.handleLoginPopUp}
              >
                {" "}
              </FavouritesCards>
            </div>
          );
        })}
      </div>
    );
  }
}

const linkArray = [
  { name: "", label: "All" },
  { name: "data", label: "Data Cards" },
  { name: "maps", label: "Maps" },
];

class Favorites extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",
      isUpdateBoard: false, // Change this to call the board list api
      openedCardsArray: [],
      boardUpdates: 0,
      searchCategory: "",
      searchTerm: "",
    };
  }

  /*removeCard = () => {
      this.setState({
          arrayData: []
      });
  };*/

  handleStoryVisiblity = (storyId) => {
    const { isStoryVisible } = this.state;
    if (isStoryVisible === true) {
      this.setState({
        isStoryVisible: false,
        storyId: null,
      });
    } else {
      this.setState({
        isStoryVisible: true,
        storyId: storyId,
      });
    }
  };

  handleUpdateBoard = () => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: prevState.isUpdateBoard === true ? false : true,
        boardUpdates: prevState.boardUpdates + 1,
      };
    });
  };

  handleCategoryChanged = (category) => {
    const { searchCategory } = this.state;
    if (searchCategory !== category) {
      this.setState({ searchCategory: category });
    }
  };

  onTextChange = (value) => {
    this.setState({ searchTerm: value });
  };

  render() {
    const {
      searchCategory,
      searchTerm,
      isStoryVisible,
      boardUpdates,
      isUpdateBoard,
    } = this.state;
    const {
      userData,
      openStoryPopup,
      openStoryPopupWithUrl,
      openCardFamilyPopupWithUrl,
      isLeftMenuOpen,
      history,
    } = this.props;

    if (userData.currentUser.edges.length === 0) {
      return (
        <div>
          <p>
            You need to log in as a user with permissions to create favourites.
          </p>
        </div>
      );
    }

    return (
      <Fragment>
        <div
          className={
            isStoryVisible === true
              ? "footer-margin projectBoard-right-component withStory"
              : "footer-margin projectBoard-right-component"
          }
        >
          {/* <button onClick={this.removeCard}>Remove card</button> */}
          <div className="project-board-home-wrapper container">
            <PageHeaderSection
              linkArray={linkArray}
              titleIcon="icon-fav"
              title="My Favorites"
              searchCategory={searchCategory}
              onClickLink={this.handleCategoryChanged}
              placeholderText="Search Favorites"
              onTextChange={this.onTextChange}
            />

            <QueryRenderer
              key={boardUpdates}
              environment={environment}
              query={graphql`
                query FavoritesQuery($searchCategory: String, $search: String) {
                  favoritesBoard {
                    sections {
                      edges {
                        node {
                          boardCardUses(
                            searchCategory: $searchCategory
                            search: $search
                          ) {
                            edges {
                              node {
                                id
                                cardPosition
                                frontFacingComponent {
                                  componentType
                                  name
                                }
                                subComponentType
                              }
                            }
                          }
                          id
                          name
                          description
                          layout
                          sectionPosition
                        }
                      }
                    }
                    id
                    name
                    subtitle
                    theme {
                      jsonStyleProperties
                    }
                  }
                }
              `}
              variables={{
                isUpdateBoard: isUpdateBoard,
                searchCategory: searchCategory,
                search: searchTerm,
              }}
              render={({ error, props }) => {
                if (error) {
                  console.error(error);
                  return <div>Error!</div>;
                }
                if (!props) {
                  return <div>Loading...</div>;
                }
                return (
                  <FavoritesSection
                    data={props}
                    openStoryPopup={openStoryPopup}
                    openStoryPopupWithUrl={openStoryPopupWithUrl}
                    openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                    handleUpdateBoard={this.handleUpdateBoard}
                    isLeftMenuOpen={isLeftMenuOpen}
                    userData={userData}
                    history={history}
                    handleLoginPopUp={this.handleLoginPopUp}
                  />
                );
              }}
            />
          </div>
        </div>
        <FooterComponent />
      </Fragment>
    );
  }
}

export default Favorites;
