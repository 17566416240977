import React, { useState } from 'react';
import { ButtonComponent } from 'common-components';
import * as d3 from 'd3';
import '../../css/components/CommonComponents/PageHeaderSection.css';
import '../../css/components/CommonComponents/SearchboxComponent.css';

function PageHeaderSection(props) {

  const [searchText, setSearchText] = useState('');

  const onTextChange = (e) => {
    const {onTextChange} = props;
    setSearchText(e.target.value);
    onTextChange(e.target.value);
  };

  const clearSearch = (e) => {
    const {onTextChange} = props;
    setSearchText('');
    onTextChange('');
  };

  const handleLinkClick = (redirectLink) => {
    scrollToElem(1500, 7500, redirectLink);
  };

  const scrollTween = (offset) => {
    return function() {
      let i = d3.interpolateNumber(window.pageYOffset || document.documentElement.scrollTop, offset);
      return function(t) { window.scrollTo(0, i(t)); };
    };
  };

  const scrollToElem = (delay, duration, selector) => {
    const box = document.querySelector(selector).getBoundingClientRect();
    let offset = box.top - window.scrollY;

    d3.transition()
      .delay(200)
      .duration(2000)
      .ease(d3.easeCubicInOut)
      .tween('scroll', scrollTween(offset));
  };

  const handleAdvanceSearch = (e) => {
    const {handleAdvanceSearchVisibility} = props;
    handleAdvanceSearchVisibility(e);

  };
  const {title, titleIcon, searchCategory, linkArray, onClickLink, id, hideSearch, placeholderText, showButton} = props;

  const {searchTitle, isFullWidth, handleAdvanceSearchVisibility} = props;
  return (
    <div className="page-section-header" id={id}>
      <div className="page-section-header-wrap">
        <h2 className="section-header-title ml-auto ml-sm-0 mr-auto text-center text-sm-left"><span
          className={'title-icon ' + (titleIcon || '')}/>{title}</h2>
        <div className="d-inline-flex ml-auto mr-auto">
          <ul className="pl-0">
            {linkArray && linkArray.length && linkArray.map(item => {
              return (
                <li key={item.name}
                    className={'px-1 ' + (searchCategory === item.name ? 'active' : '')}>
                  <ButtonComponent
                    buttonType="link"
                    buttonTitle={item.label}
                    className={searchCategory === item.name ? 'active' : ''}
                    onClick={(e) => onClickLink ? onClickLink(item.name) :
                      item.redirectLink ? handleLinkClick(item.redirectLink) : null
                    }

                  />
                </li>
              );
            })
            }
          </ul>
          {showButton &&
          <div className="my-2">
            <ButtonComponent
              buttonType="outlined"
              iconClass="icon-plus-new"
              buttonTitle="Create New Area"
              className="w-auto"
              isLarge={true}
              onClick={onClickLink}/>
          </div>
          }
        </div>
        {!hideSearch &&
        <div className="ml-auto mr-sm-0 mr-auto pt-2 pt-sm-0">
          {/* <SearchboxComponent
                            placeholderText={placeholderText || "Search"}
                            onTextChange={props.onTextChange}
                        /> */}

          <div className="SearchBoxWrap">
            {searchTitle &&
            <label className="formlabel">{searchTitle || ''}</label>
            }
            <label className="search">
              <input type="input"
                     className={isFullWidth ? 'w-100' : ''}
                     name="search"
                     placeholder={placeholderText || 'Search'}
                     value={searchText}
                     onChange={(e) => {
                       onTextChange(e);
                     }}
              />
              {!!searchText &&
              <img alt="" src={require('../../images/iconClose.svg')} className="clickable"
                   onClick={clearSearch}/>
              }
              {handleAdvanceSearchVisibility &&
              <img alt="" src={require('../../images/caretDownSmall.svg')}
                   className="srch-for-ind-dropbox"
                   onClick={handleAdvanceSearch}/>
              }
            </label>
          </div>

        </div>
        }
      </div>
    </div>
  );
}

export default PageHeaderSection;
