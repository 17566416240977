import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
    mutation VerifyTwitterCredentialsMutation($input: VerifyTwitterCredentialsInput!)  
    {  verifyTwitterCredentials (input:  $input) {     
        resultMessage
        success
      }  
    }
  `;

export default (
  callback,
  errorCallback
) => {
  const variables = {
    'input':
      {
        'clientMutationId': null
      }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // console.log('Response from server section layouts update.',JSON.stringify(response))
        // console.log('Response from server section layouts update.',(response))
        callback(response);
      },
      onError: (err) => {
        console.error("Error from server ", JSON.stringify(err));
        errorCallback(err);
      }
    },
  );
}
