import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProjectBoard from "../ProjectBoard/ProjectBoard";
// import {MenuContainer} from '../../modules/broadstreet-ui-lib';
import { MenuContainer, ServiceAreaSelector, ToolTip } from "common-components";
import HomeFeed from "../HomeFeed/HomeFeed";
import Favorites from "../Favorites/Favorites";
import Stories from "../Stories/Stories";
import Maps from "../Maps/Maps";
import Areas from "../Areas/Areas";
import Story from "../Story/Story";
import { QueryRenderer, commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import LoginPopUp from "../LoginPopUp/LoginPopUp";
import SignupPopUp from "../SignupPopUp/SignupPopUp";
import SignUpPopUpStep1 from "../SignUpPopUpStep1/SignUpPopUpStep1";
import ForgotPasswordPopUp from "../ForgotPasswordPopUp/ForgotPasswordPopUp";
import ForgotPasswordPopUpStep2 from "../ForgotPasswordPopUpStep2/ForgotPasswordPopUpStep2";
import MapTheMovement from "../MapTheMovement/MapTheMovement";
import ChnaSplash from "../ChnaSplash/ChnaSplash";
import Error404 from "../Error404/Error404";
import AccountPage from "../AccountSettings/AccountPage";
import CardFamily from "../Card/CardFamily";
import SaveCardsPopUp from "../CommonComponents/SaveCardsPopUp";
import MoreOptionPopup from "../CommonComponents/MoreOptionPopup";
import ReportErrorPopUp from "../CommonComponents/ReportErrorPopUp";
import ChooseAreaPopup from "../ChooseAreaPopup/ChooseAreaPopup";
import MySavedAreasPopup from "../MySavedAreasPopup/MySavedAreasPopup";
import "../../css/media.css";
import ImageEditorPopup from "../ImageEditorPopup/ImageEditorPopup";
import CustomHomePage from "../CustomHomePage/CustomHomePage";
import InvitationLinkConfirm from "../InvitationLinkConfirm/InvitationLinkConfirm";
import { Indicators } from "browse-indicators";
import { apiBaseUrl } from "../../utility/utilities";
import MemberShipPopup from "../MemberShipPopup/MemberShipPopup";
import SubscriptionPage from "./../Subscription/Subscription";
import MembershipActivatedPopup from "../MembershipActivatedPopup/MembershipActivatedPopup";
import StartMemberShipPopup from "../StartMemberShipPopup/StartMemberShipPopup";
import { connect } from "react-redux";
import {
  setUserInfoAction,
  getSubscriptionInfo,
  startMembershipAction,
} from "../../actions/user";
import InvoicePage from "../AccountSettings/InvoicePage";
import { ToastContainer, Slide, toast } from "react-toastify";
import WideCardPopup from "../CommonComponents/WideCardPopup";
import { DownloadProvider, DownloadConsumer } from "./downloadContext";

const reportTypeUriArray = [
  "infantmortality",
  "covid19",
  "aces",
  "areadeprivationindex",
  "demographics",
  "foodassessment",
  "communityindicatoreport",
  "indicatorreport",
  "mortality",
  "sti",
  "violence",
  "suiprev",
];

class _ProjectBoardRoot extends Component {
  scrollPos = 0;

  constructor(props) {
    super(props);
    this.routes = [];
    this.state = {
      isHomeStoryVisible: false,
      isLeftMenuOpen: window.innerWidth > 425,
      refreshLayout: false,
      selectedBoardId: "",
      selectedBoardName: "",
      selectedComponentType: "",
      isUpdateLeftNavigation: false,
      isUpdateTopAndLeftMenu: false,

      isMemberShipPopUpVisible: false,

      isLoginPopUpVisible: false,
      loginPopUpType: "Action",
      fromLeftMenu: true,
      // loginPopUpType: "Action",
      // SignupPopUpType: "Action",
      // isSignupPopUpVisible: false,
      isForgotPasswordPopUpVisible: false,
      userId: "",
      isSignupPopUpStep1Visible: false,
      SignupPopUpStep1Type: "Action",
      homeClick: false,
      pageviews: 0,
      isLegacyUser: false,
      areaSelected: null,
      areaSelectorName: null,
      isAreaSelectorVisible: false,
      renderKey: 0,
      isStoryPopupVisible: false,
      storyCardSelectedStack: [],
      storyPopupTop: null,

      xOriginSaveCardsPopUp: 0,
      yOriginSaveCardsPopUp: 0,
      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isSaveCardsPopUpVisibleForHome: false,
      xOriginSaveCardsPopUpForHome: 0,
      yOriginSaveCardsPopUpForHome: 0,

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isMorePopUpVisible: false,
      xOriginForMorePopUp: 0,
      yOriginForMorePopUp: 0,

      saveCardFromMore: false,
      isEditHashTagPopUpVisible: false,
      openedCardsArray: [],
      data: [],

      createDataDownloadCard: false,

      showWideCardPopup: false,
      cardId: null,

      isImageEditorPopUpVisible: false,
      avatarEditor: null,
      avatarEditorRatio: null,
      isSavedTooltip: false,
      savedCardId: "",
      newCreatedAreaId: null,

      isShowStartMembershipPopup: false,
      isStartedMembership: false,
      isSubscriptionVisible: false,
    };
  }

  componentWillMount() {
    window.driftUserAttributes = { email: "", nickname: "" };
  }

  componentDidUpdate() {
    const { match } = this.props;
    if (match.path === "/ref/:code") {
      fetch(`https://www.broadstreet.io/api/refer/${match.params.code}/`)
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.error(error);
          }
        );
    }
  }

  componentDidMount() {
    const { location, match, history } = this.props;
    this.routes.push(location);
    this.setState({ previousLocation: location });
    // Attach an event handler to for window scrolling
    window.document.addEventListener("scroll", this.handleScrollWindow);

    if (match.params.component === "signup") {
      this.handleSignUpPopUpStep1();
    } else if (match.params.component === "signin") {
      this.handleLoginPopUp();
    } else if (match.params.component === "gsignin") {
      this.handleGoogleSigninPopUp();
    } else if (match.params.component === "membership") {
      this.handleMemberShipPopup();
    }

    this.handleLocationChange(history.location);
    this.unlisten = history.listen(this.handleLocationChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subscriptionInfo, setUserInfoAction, match } = this.props;

    // TODO: fix this showing "START YOUR MEMBERSHIP" popup inappropriately sometimes
    if (
      !subscriptionInfo &&
      nextProps.subscriptionInfo &&
      nextProps.subscriptionInfo.isOverSubscriptionLimits &&
      !nextProps.subscriptionInfo.userSubscriptionLevel
    ) {
      if (
        !(
          nextProps.match.params.component === "accountSetting" ||
          nextProps.match.params.component === "accountSetting/new" ||
          nextProps.match.params.component === "pubboard"
        )
      ) {
        this.setState({ isShowStartMembershipPopup: true });
      }
    } else {
      this.setState({ isShowStartMembershipPopup: false });
    }
    if (nextProps.match.params.component !== match.params.component) {
      if (nextProps.match.params.component === "signup") {
        this.handleSignUpPopUpStep1();
        setUserInfoAction(null);
      } else if (nextProps.match.params.component === "signin") {
        if (
          nextProps.cookies != null &&
          nextProps.location.state != null &&
          nextProps.location.state.requestedPage != null
        ) {
          nextProps.cookies.set(
            "requestedPage",
            nextProps.location.state.requestedPage,
            { path: "/", maxAge: 1800 }
          );
          console.log(
            "requestedPage cookie set",
            nextProps.location.state.requestedPage
          );
        }
        this.setState({ isLoginPopUpVisible: true, loginPopUpType: "Action" });
        setUserInfoAction(null);
      } else if (nextProps.match.params.component === "gsignin") {
        this.handleGoogleSigninPopUp();
        setUserInfoAction(null);
      }
    }
  }

  componentWillUnmount() {
    // Remove the event handler for window scrolling
    try {
      this.timer && this.timer.clear();
      this.unlisten();
    } catch (err) {
      console.log(err);
    }
    window.document.removeEventListener("scroll", this.handleScrollWindow);
  }

  handleLocationChange = (location) => {
    if (window.drift !== undefined) {
      try {
        window.drift.page();
        if (window.Appcues !== undefined) {
          window.Appcues.page();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleScrollWindow = (scrollEvent) => {
    // Delete any d3 tooltips left behind during scroll
    var toolTipNodes = document.querySelectorAll("div.d3-tooltip");
    if (toolTipNodes.forEach !== undefined) {
      toolTipNodes.forEach((item) => {
        item.remove();
      });
    } else {
      if (toolTipNodes.length > 0) {
        toolTipNodes[0].parentNode.removeChild(toolTipNodes[0]);
      }
    }
  };

  handleRememberPassword = () => {
    this.setState({
      isSignupPopUpStep1Visible: false,
      isForgotPasswordPopUpVisible: false,
      isLoginPopUpVisible: true,
      loginPopUpType: "Normal",
    });
  };

  // TODO: refactor to remove duplicate signin code from LoginPopUp.js
  handleGoogleSigninPopUp = () => {
    if (window.gapi != null) {
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            client_id:
              "733542641252-rb5iqhqusou1nq8r6kq82rq7ri1top9u.apps.googleusercontent.com",
          })
          .then(
            () => {
              window.gapi.signin2.render("g-signin2", {
                width: 190,
                height: 40,
                longtitle: true,
                theme: "dark",
                onsuccess: this.onGoogleSignIn,
              });
            },
            (error) => {
              console.error(error);
            }
          );
      });
    }
  };

  onGoogleSignIn = (googleUser) => {
    var profile = googleUser.getBasicProfile();
    console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log("Name: " + profile.getName());
    console.log("Image URL: " + profile.getImageUrl());
    console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
    // Encrypted OAuth2 ID token to pass to backend
    var idToken = googleUser.getAuthResponse().id_token;
    this.loginGoogleUserApi(idToken, "google");
  };

  loginGoogleUserApi(idToken, tokenSource) {
    const { userName } = this.state;
    this.setState({
      isLoginFormSubmitted: true,
      isBadPassword: false,
      isBadEmail: false,
    });

    const mutation = graphql`
      mutation RootGoogleMutation($input: LoginUserInput!) {
        loginUser(input: $input) {
          userAccountNode {
            id
          }
          success
          validationErrors {
            key
            message
          }
          clientMutationId
        }
      }
    `;
    const variables = {
      input: {
        username: userName,
        oauth2Token: idToken,
        oauth2Source: tokenSource,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (
          response.loginUser.success === true &&
          response.loginUser.userAccountNode !== null
        ) {
          this.onLogin(response.loginUser.userAccountNode.id);
          this.onUpdateTheTopLeftMenu();
        } else {
          this.handleLoginProblem(
            response.loginUser.validationErrors,
            userName
          );
        }
      },
      onError: (err) => {
        console.log("error", err);
        this.setState({ isLoginFormSubmitted: false });
        alert("Unexpected system error.  Please try again.");
      },
    });
  }

  handleMemberShipPopup = () => {
    const { getSubscriptionInfo } = this.props;
    getSubscriptionInfo();
    this.setState((prevState) => ({
      isMemberShipPopUpVisible: !prevState.isMemberShipPopUpVisible,
    }));
  };

  // NOTE: Membership is a single word in modern English
  handleMembershipActivated = (subscriptionTypeActivated) => {
    const { getSubscriptionInfo } = this.props;
    getSubscriptionInfo();
    this.setState({
      isMembershipActivatedVisible: true,
      isMemberShipPopUpVisible: false,
      subscriptionTypeActivated: subscriptionTypeActivated,
    });
  };

  handleLoginPopUp = () => {
    this.setState((prevState) => ({
      isLoginPopUpVisible: !prevState.isLoginPopUpVisible,
      loginPopUpType: "Action",
    }));
  };

  handleLoginPopUpType2 = () => {
    this.setState((prevState) => ({
      isLoginPopUpVisible: !prevState.isLoginPopUpVisible,
      loginPopUpType: "Normal",
    }));
  };

  handleLoginProblem = (validationErrors, userName) => {
    // TODO: updated login workflow HERE
    var errorKey = null;
    if (validationErrors != null && validationErrors[0] != null) {
      errorKey = validationErrors[0].key;
    }

    if (errorKey === "loginFailed") {
      /* existing user but wrong password */
      /* show forgot password popup version 1 (pre-populated) */
      this.setState((prevState) => {
        return {
          isForgotPasswordPopUpVisible: true,
          ForgotPasswordPopUpType: "Normal",
          isLegacyUser: false,
          isLoginPopUpVisible: false,
          isSignupPopUpVisible: false,
        };
      });
    } else if (errorKey === "userDoesNotExist") {
      /* user never signed up */
      /* show new signup popup (pre-populated) */
      this.openSignUpPopUp();
      //this.setState((prevState) => ({ isForgotPasswordPopUpVisible: false, loginPopUpType: "Normal", isLegacyUser: false, isLoginPopUpVisible: false, isSignupPopUpVisible: true }))
    } else if (errorKey === "inviteToSignup") {
      /* recognized legacy user from old site */
      /* show forgot password popup version 2 (pre-populated) */
      this.setState((prevState) => {
        return {
          isForgotPasswordPopUpVisible: true,
          ForgotPasswordPopUpType: "Normal",
          isLegacyUser: true,
          isLoginPopUpVisible: false,
          isSignupPopUpVisible: false,
        };
      });
    } else {
      alert("Unexpected system error. Please try again or contact support.");
    }
  };

  // This method is called when user deletes the board
  updatingLeftMenu = () => {
    this.setState((prevState) => {
      return {
        renderKey: (prevState.renderKey + 1) % 500,
        isUpdateLeftNavigation:
          prevState.isUpdateLeftNavigation === true ? false : true,
        selectedComponentType: "HOME",
      };
    });
  };

  updatingLeftMenuOnCopyCollection = () => {
    this.setState((prevState) => {
      return {
        renderKey: (prevState.renderKey + 1) % 500,
        isUpdateLeftNavigation:
          prevState.isUpdateLeftNavigation === true ? false : true,
      };
    });
  };

  onToggleLeftMenu = (isLeftMenuOpen) => {
    this.setState({ isLeftMenuOpen: isLeftMenuOpen, refreshLayout: true });
  };

  onRefreshLayout = () => {
    this.setState({ refreshLayout: false });
  };

  handleBoardSelection = (id, name, componentType, fromPage) => {
    const { history } = this.props;
    this.setState(
      {
        selectedBoardId: id,
        selectedBoardName: name,
        selectedComponentType: componentType,
      },
      () => {
        history.push(
          "/" +
            componentType +
            "/" +
            fromPage +
            "/" +
            escape(id) +
            "/" +
            escape(name)
        );
      }
    );
  };
  handleHomeSelection = (componentType, filterTag, isPermaTag) => {
    const { history } = this.props;
    this.setState(
      (prevState) => ({
        selectedComponentType: componentType,
        filterTag,
        isPermaTag: isPermaTag,
        redirectRoute: "/" + componentType,
        isHomeStoryVisible: !prevState.isHomeStoryVisible,
      }),
      () => {
        if (this.child !== undefined) {
          this.child.hideStory();
        }

        if (componentType !== "BOARD") {
          if (filterTag == null) {
            history.push("/" + componentType);
          } else {
            history.push(
              "/" + componentType + "/" + filterTag + "/" + (isPermaTag ? 1 : 0)
            );
          }
        }
      }
    );
  };
  handleBrowseAllStories = () => {
    this.handleHomeSelection("HOME", "commons stories", true);
  };
  handleExploreMapsAndData = () => {
    this.setState({
      redirect: true,
    });
  };
  routeToMaproom = () => {
    const { redirect } = this.state;
    if (redirect) {
      window.location.assign("/map/");
    }
  };
  goHome = () => {
    window.location.assign("/board/HOME");
  };
  onUpdateTheTopLeftMenu = () => {
    this.setState((prevState) => ({
      isUpdateTopAndLeftMenu: !prevState.isUpdateTopAndLeftMenu,
    }));
  };

  handleSignUpPopUpStep1 = () => {
    this.setState((prevState) => ({
      isSignupPopUpStep1Visible: !prevState.isSignupPopUpStep1Visible,
      SignupPopUpStep1Type: "Action",
    }));
  };
  handleSignUpPopUpStep1Type2 = () => {
    this.setState((prevState) => ({
      isSignupPopUpStep1Visible: !prevState.isSignupPopUpStep1Visible,
      SignupPopUpStep1Type: "Normal",
    }));
  };

  handleForgotPasswordPopUp = () => {
    this.setState((prevState) => ({
      isForgotPasswordPopUpVisible: !prevState.isForgotPasswordPopUpVisible,
      ForgotPasswordPopUpType: "Action",
    }));
  };
  handleForgotPasswordPopUpType2 = () => {
    this.setState((prevState) => ({
      isForgotPasswordPopUpVisible: !prevState.isForgotPasswordPopUpVisible,
      ForgotPasswordPopUpType: "Normal",
    }));
  };

  onLogin = (userId) => {
    const { match, cookies, history } = this.props;
    this.setState({
      userId: userId,
      isLegacyUser: false,
    });
    if (window.drift !== undefined) {
      window.drift.identify(userId);
    }
    if (window.Appcues !== undefined) {
      window.Appcues.identify(userId);
    }
    if (["signin", "gsignin"].includes(match.params.component)) {
      var requestedPage = null;
      if (cookies != null) {
        requestedPage = cookies.get("requestedPage", {
          doNotParse: true,
        });
        console.log("user logged in after requesting page", requestedPage);
      }
      if (requestedPage != null && requestedPage !== "") {
        // TODO: only remove cookie on logout, otherwise allow to expire
        //cookies.this.props.remove('requestedPage', { path: '/' });
        //console.log("removed", requestedPage);
        //this.props.history.push(requestedPage);
      } else {
        history.push("/NEWS");
      }
    }
  };

  openSignUpPopUp = () => {
    this.setState({
      isLoginPopUpVisible: false,
      isSignupPopUpStep1Visible: true,
      isForgotPasswordPopUpVisible: false,
    });
  };

  openForgotPasswordPopUp = () => {
    this.setState({
      isLoginPopUpVisible: false,
      isSignupPopUpStep1Visible: false,
      isForgotPasswordPopUpVisible: true,
    });
  };

  openLoginPopUp = (forceWelcomePrompt = false) => {
    if (forceWelcomePrompt === true) {
      this.setState({
        loginPopUpType: "Normal",
        isLoginPopUpVisible: true,
        isSignupPopUpStep1Visible: false,
        isForgotPasswordPopUpVisible: false,
      });
    } else {
      this.setState({
        isLoginPopUpVisible: true,
        isSignupPopUpStep1Visible: false,
        isForgotPasswordPopUpVisible: false,
      });
    }
  };

  closeMySavedAreapopup = (newBoardId) => {
    const { match, history } = this.props;
    this.setState({
      isAreaSelectorVisible: false,
      areaSelected: null,
      areaSelectorName: null,
    });
    if (["mysavedareas"].indexOf(match.params.component) > -1) {
      this.updatingLeftMenuOnCopyCollection();
      if (
        (reportTypeUriArray.indexOf(match.params.filterTag) > -1 ||
          (match.params.filterTag + "").startsWith("userQm9")) &&
        newBoardId != null
      ) {
        history.push("/BOARD/collection/" + newBoardId, "new");
      } else {
        history.push("/HOME");
      }
    }
  };

  closeServiceAreaSelector = (newBoardId) => {
    const { match, history, location } = this.props;
    this.setState({
      isAreaSelectorVisible: false,
      areaSelected: null,
      areaSelectorName: null,
      newCreatedAreaId: newBoardId,
    });
    if (match.params.component === "areaselector") {
      this.updatingLeftMenuOnCopyCollection();
      if (
        (reportTypeUriArray.indexOf(match.params.filterTag) > -1 ||
          (match.params.filterTag + "").startsWith("userQm9")) &&
        newBoardId != null
      ) {
        history.push("/BOARD/collection/" + newBoardId, "new");
      } else if (location && location.state && location.state.closeURL) {
        const closeURL = location.state.closeURL;
        history.push(closeURL.replace("/board", ""));
      } else {
        history.push("/HOME");
      }
    }
  };

  openAreaSelector = (newAreaName) => {
    this.setState(
      {
        isAreaSelectorVisible: true,
        areaSelected: null,
        areaSelectorName: newAreaName,
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  };

  closeStoryPopup = () => {
    const { storyCardSelectedStack, storyPopupTop } = this.state;
    const { location, history } = this.props;
    // If we saved location to return to when opening story then go back there
    if (location.state != null && location.state.returnTo != null) {
      history.push(location.state.returnTo);
    } else {
      // Go home from external links to stories
      if (storyCardSelectedStack.length === 0) {
        history.push("/HOME");
      }
      // Otherwise pop story off stack of no-URL story popups
      if (storyCardSelectedStack.length > 1) {
        this.setState((prevState) => {
          return {
            isStoryPopupVisible: true,
            storyCardSelectedStack: prevState.storyCardSelectedStack.slice(
              0,
              -1
            ),
            storyPopupTop:
              window.scrollY !== undefined
                ? window.scrollY
                : window.pageYOffset,
          };
        });
      } else {
        let top = 0;
        this.setState((prevState) => {
          if (storyPopupTop) {
            top = storyPopupTop;
          }
          return {
            isStoryPopupVisible: false,
            storyCardSelectedStack: [],
            storyPopupTop: null,
          };
        });

        setTimeout(() => {
          if (top) {
            window.scrollTo(0, top);
          }
        }, 1000);
      }
    }
  };

  openStoryPopupWithUrl = (storyRoute, returnTo) => {
    // change URL in browser when story is opened,
    //   closing story essentially becomes hitting browser back button
    //const {subscriptionInfo} = this.props;
    //if (subscriptionInfo && subscriptionInfo.isOverSubscriptionLimits === true) {
    //    this.setState({ isShowStartMembershipPopup: true });
    //}
    //else {
    window.open(storyRoute);
    //}
  };

  forceShowStartMembershipPopup = () => {
    this.setState({ isShowStartMembershipPopup: true });
  };

  openStoryPopup = (storyCardId) => {
    const { isStartedMembership } = this.state;
    this.setState((prevState) => {
      return {
        isShowStartMembershipPopup: true,
        isStartedMembership: true,
      };
    });
    if (isStartedMembership) {
      // open story as a popover on top of existing page
      this.setState((prevState) => {
        return {
          isStoryPopupVisible: true,
          storyCardSelectedStack:
            prevState.storyCardSelectedStack.concat(storyCardId),
          storyPopupTop:
            window.scrollY !== undefined ? window.scrollY : window.pageYOffset,
        };
      });
    }
  };

  onUpdatedBoard = () => {
    this.setState((prevState) => {
      return {
        renderKey: (prevState.renderKey + 1) % 500,
        isUpdateLeftNavigation:
          prevState.isUpdateLeftNavigation === true ? false : true,
      };
    });
  };

  openCardFamilyPopupWithUrl = (storyRoute) => {
    const { history, location } = this.props;
    history.push(storyRoute, { returnTo: location });
  };

  closeCardFamilyPopup = (returnToCollection) => {
    const { location, history } = this.props;
    // If we saved location to return to when opening story then go back there
    if (location.state != null && location.state.returnTo != null) {
      history.push(location.state.returnTo);
    } else {
      // TODO: Derive destination for parent collection of cardid
      //   for now we go to home
      if (returnToCollection != null) {
        history.push(returnToCollection);
      } else {
        history.push("/HOME");
      }
    }
  };

  handleAreaSaved = (id) => {
    this.closeServiceAreaSelector();
    this.onUpdateTheTopLeftMenu();
  };

  handleSelectArea = (id) => {
    const { subscriptionInfo } = this.props;
    if (subscriptionInfo && subscriptionInfo.userSubscriptionLevel > 0) {
      this.setState({ areaSelected: id, isAreaSelectorVisible: true });
    } else {
      this.setState({ isShowStartMembershipPopup: true });
    }
  };

  // Websocket update and notification
  notificationUpdate = () => {
    const { isUpdateLeftNavigation } = this.state;
    if (false) {
      this.setState({
        isUpdateLeftNavigation: isUpdateLeftNavigation === true ? false : true,
        // selectedComponentType: "HOME"
      });
    }
  };

  handleReRenderCard = (cardId) => {
    const { data, selectedCardForMutation, updateCustomPage } = this.state;
    var arrayData = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === selectedCardForMutation) {
        var changedObject = data[i];
        changedObject.isUpdate === true
          ? (changedObject.isUpdate = false)
          : (changedObject.isUpdate = true);
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState(
      {
        data: arrayData,
        updateCustomPage: !updateCustomPage,
      },
      this.hidePopUp()
    );
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId, popUpType, createDataDownloadCard) => {
    const { isSaveCardsPopUpVisibleForHome, isSaveCardsPopUpVisible } =
      this.state;
    if (popUpType === "BeforeYouCanDoThatPopUp") {
      if (isSaveCardsPopUpVisibleForHome === false) {
        this.setState({
          xOriginSaveCardsPopUpForHome: x,
          yOriginSaveCardsPopUpForHome: y,
          isSaveCardsPopUpVisibleForHome: true,
          selectedCardForMutation: cardId,
          createDataDownloadCard: false,
        });
      }
    } else {
      if (isSaveCardsPopUpVisible === false) {
        this.setState({
          xOriginSaveCardsPopUp: x,
          yOriginSaveCardsPopUp: y,
          isSaveCardsPopUpVisible: true,
          selectedCardForMutation: cardId,
          createDataDownloadCard: createDataDownloadCard,
        });
      } else {
        this.setState({
          isSaveCardsPopUpVisible: false,
        });
      }
    }
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isMorePopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isSaveCardsPopUpVisibleForHome,
      isReportErrorPopUpVisible,
      isEditHashTagPopUpVisible,
    } = this.state;
    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isMorePopUpVisible) {
      this.setState({ isMorePopUpVisible: false, selectedCard: null });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isSaveCardsPopUpVisibleForHome && closed) {
      this.setState({
        isSaveCardsPopUpVisibleForHome: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId, selectedCard = null) => {
    const { isPopUpVisible, isMorePopUpVisible } = this.state;
    if (popUpType === "CloseTag") {
      if (isPopUpVisible) {
        this.setState({
          isPopUpVisible: false,
        });
      } else {
        this.setState({
          isPopUpVisible: true,
          xOriginTagCrossPopUp: x,
          yOriginTagCrossPopUp: y,
          selectedCardForMutation: cardId,
        });
      }
    } else if (popUpType === "MoreInfo") {
      if (isMorePopUpVisible) {
        this.setState({
          isMorePopUpVisible: false,
        });
      } else {
        this.setState({
          isMorePopUpVisible: true,
          xOriginForMorePopUp: x,
          yOriginForMorePopUp: y,
          isToolTipVisible: false,
          selectedCardForMutation: cardId,
          selectedCard,
        });
      }
    }
  };

  // show  ShareCardPopUp
  handleShareCardPopUp = (x, y) => {
    const { isShareCardPopUpVisible } = this.state;
    if (isShareCardPopUpVisible === false) {
      this.setState({
        isShareCardPopUpVisible: true,
        isMorePopUpVisible: false,
        xOriginShareCardPopUp: x,
        yOriginShareCardPopUp: y,
      });
    }
  };

  //ReportErrorPopup
  handleReportErrorPopUp = (x, y) => {
    const { isReportErrorPopUpVisible } = this.state;
    if (isReportErrorPopUpVisible === false) {
      this.setState({
        xOriginReportErrorPopUp: x,
        yOriginReportErrorPopUp: y,
        isReportErrorPopUpVisible: true,
        isMorePopUpVisible: false,
      });
    }
  };

  copyCardToCollection = (x, y) => {
    const { match } = this.props;
    const { isMorePopUpVisible } = this.state;
    if (isMorePopUpVisible === true) {
      if (match.params.component === "story") {
        x = x + 100;
        y = y + 10;
      }
      this.setState({
        xOriginSaveCardsPopUpForHome: x + 35,
        yOriginSaveCardsPopUpForHome: y + 35,
        isSaveCardsPopUpVisibleForHome: true,
        isMorePopUpVisible: false,
        saveCardFromMore: true,
      });
    }
  };

  handleEditHashTagPopUp = () => {
    this.setState({
      isEditHashTagPopUpVisible: true,
    });
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedToolTip: false,
      savedCardId: "",
    });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { openedCardsArray } = this.state;
    var escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    var el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + cardHeight / 40;

    var newOpenedCardsArray = openedCardsArray;
    var pos = -1;
    openedCardsArray.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      if (pos > -1) {
        return;
      } else {
        newOpenedCardsArray = newOpenedCardsArray.concat({
          i: cardId,
          h: cardHeightInGrid,
        });
      }
    } else {
      if (pos > -1) {
        newOpenedCardsArray = newOpenedCardsArray.slice(0);
        newOpenedCardsArray.splice(pos, 1);
      } else {
        return;
      }
    }
    this.setState({ openedCardsArray: newOpenedCardsArray });
  };

  handleImageEditorPopUpVisible = (
    ratio,
    file,
    url,
    height,
    style,
    id = null,
    parentId = null
  ) => {
    const { isImageEditorPopUpVisible } = this.state;
    this.setState({
      isImageEditorPopUpVisible: !isImageEditorPopUpVisible,
      avatarEditorRatio: ratio,
      avatarEditor: file,
      avtarImage: url,
      avtarHeight: height,
      avtarStyle: style,
      imageId: id,
      parentImageId: parentId,
    });
  };

  // clear any temporary app cookies associated with user session
  clearTemporaryCookies = () => {
    const { cookies } = this.props;
    cookies.remove("requestedPage", { path: "/" });
    cookies.remove("requestedMapPage", { path: "/" });
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId) => {
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId,
    });
  };

  checkUserLogin = (isLoginPopUpVisible) => {
    this.setState({ isLoginPopUpVisible });
  };

  render() {
    const {
      isSaveCardsPopUpVisible,
      xOriginSaveCardsPopUp,
      yOriginSaveCardsPopUp,
      selectedCardForMutation,
      saveCardFromMore,
      createDataDownloadCard,
      isSaveCardsPopUpVisibleForHome,
      xOriginSaveCardsPopUpForHome,
      yOriginSaveCardsPopUpForHome,
      showTwitterCardPopup,
      isUpdateTopAndLeftMenu,
      previousLocation,
      isUpdateLeftNavigation,
      renderKey,
      isStoryPopupVisible,
      refreshLayout,
      isLeftMenuOpen,
      isLoginPopUpVisible,
      avatarEditor,
      imageId,
      parentImageId,
      avtarImage,
      selectedBoardId,
      selectedBoardName,
      fromLeftMenu,
      openedCardsArray,
      isSavedTooltip,
      savedCardId,
      updateCustomPage,
      isAreaSelectorVisible,
      newCreatedAreaId,
      areaSelected,
      isShowStartMembershipPopup,
      isMembershipActivatedVisible,
      subscriptionTypeActivated,
      storyCardSelectedStack,
      isEditHashTagPopUpVisible,
      isShareCardPopUpVisible,
      pageviews,
      areaSelectorName,
      loginPopUpType,
      isMemberShipPopUpVisible,
      isSignupPopUpStep1Visible,
      isForgotPasswordPopUpVisible,
      isImageEditorPopUpVisible,
      isLegacyUser,
      avatarEditorRatio,
      avtarHeight,
      avtarStyle,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      xOriginForMorePopUp,
      yOriginForMorePopUp,
      isMorePopUpVisible,
      isReportErrorPopUpVisible,
      xOriginReportErrorPopUp,
      yOriginReportErrorPopUp,
      selectedCard,
      text,
      isSubscriptionVisible,
    } = this.state;
    const {
      history,
      userData,
      setUserInfoAction,
      getSubscriptionInfo,
      location,
      cookies,
      match,
      startMembership,
      startMembershipAction,
      subscriptionInfo,
    } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query RootQuery {
            currentUser {
              edges {
                node {
                  id
                  jsonid
                  username
                  userFirstName
                  userReportName
                  stripeCustomerId
                  isOverSubscriptionLimits
                  userSubscriptionLevel
                  userImage {
                    url
                  }
                  boardauthorSet {
                    edges {
                      node {
                        id
                        name
                        userAccount {
                          email
                        }
                        profileImage {
                          id
                          caption
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        variables={{
          isUpdateTopAndLeftMenu: isUpdateTopAndLeftMenu,
        }}
        render={({ error, props }) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div />;
          }
          var userDataProps = props;
          if (
            userDataProps.currentUser != null &&
            userDataProps.currentUser.edges[0] != null &&
            userDataProps.currentUser.edges[0].node != null
          ) {
            if (
              !userData ||
              (userData &&
                userData.id !== userDataProps.currentUser.edges[0].node.id)
            ) {
              setUserInfoAction(userDataProps.currentUser.edges[0].node);
              getSubscriptionInfo();
            }
          }

          if (
            userDataProps.currentUser != null &&
            window.drift != null &&
            userDataProps.currentUser.edges[0] != null &&
            userDataProps.currentUser.edges[0].node.username.indexOf("@") !==
              -1 &&
            window.driftUserAttributes.email !==
              userDataProps.currentUser.edges[0].node.username
          ) {
            window.drift.on("ready", function () {
              window.driftUserAttributes = {
                email: userDataProps.currentUser.edges[0].node.username,
                nickname: userDataProps.currentUser.edges[0].node.userFirstName,
              };
              window.drift.api.setUserAttributes(window.driftUserAttributes);
              if (window.Appcues !== undefined) {
                window.Appcues.identify(
                  userDataProps.currentUser.edges[0].node.id,
                  {
                    firstName: window.driftUserAttributes.nickname,
                    email: window.driftUserAttributes.email,
                  }
                );
              }
            });
          }
          if (
            userDataProps &&
            userDataProps.currentUser &&
            userDataProps.currentUser.edges.length &&
            userDataProps.currentUser.edges[0].node &&
            userDataProps.currentUser.edges[0].node.userSubscriptionLevel < 1
          ) {
            // Deny access to everything except account management and public stories for free accounts
            if (location.pathname !== "/subscription" && 
                !location.pathname.startsWith("/accountSetting") &&
                !location.pathname.startsWith("/pubboard") &&
                !location.pathname.startsWith("/story") &&
                !location.pathname.startsWith("/card/") &&
                location.pathname !== "/signin" &&
                location.pathname !== "/signup" &&
                location.pathname !== "/gsignin"
            ) {
              history.push("/subscription");
              return null;
            }
          }
          if (
            (userDataProps.currentUser == null ||
              !userDataProps.currentUser.edges.length) &&
            location.pathname !== "/signin" &&
            location.pathname !== "/signup" &&
            location.pathname !== "/gsignin" &&
            !location.pathname.startsWith("/story/") &&
            !location.pathname.startsWith("/card/") &&
            !location.pathname.startsWith("/pubboard/")
          ) {
            let requestedPage = location.pathname;
            console.log("access denied to location", requestedPage);
            if (cookies != null) {
              let requestedMapPage = cookies.get("requestedMapPage", {
                doNotParse: true,
              });
              if (requestedMapPage != null) {
                requestedPage = requestedMapPage;
                cookies.set("requestedPage", requestedPage, {
                  path: "/",
                  maxAge: 1800,
                });
              }
              console.log("requested external path", requestedPage);
            }
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { requestedPage: requestedPage },
                }}
              />
            );
          }
          if (
            userDataProps.currentUser != null &&
            userDataProps.currentUser.edges.length &&
            (location.pathname === "/signin" ||
              location.pathname === "/signup" ||
              location.pathname === "/gsignin")
          ) {
            var requestedPage = null;
            if (cookies != null) {
              requestedPage = cookies.get("requestedPage", {
                doNotParse: true,
              });
            }
            if (requestedPage == null || requestedPage === "") {
              requestedPage = "/NEWS";
            }
            console.log("redirect to requested page", requestedPage);
            if (requestedPage.startsWith("/map/")) {
              window.location.assign(requestedPage);
              return null;
            } else {
              return <Redirect to={{ pathname: requestedPage }} />;
            }
          }

          /*var cameFromHomeFeed = false;*/
          if (
            previousLocation.pathname === "/" ||
            previousLocation.pathname === "/HOME"
          ) {
            /*cameFromHomeFeed = true;*/
          }
          let selectedAreaId = null;
          if (location && location.state && location.state.selectedAreaId) {
            selectedAreaId = location.state.selectedAreaId;
          } else if (
            match.params.filterTag !== "indicatorreport" &&
            !(location && location.state && location.state.closeURL)
          ) {
            selectedAreaId = match.params.filterTag;
          }
          if (match && match.params.component === "invoice") {
            return <InvoicePage {...this.props} />;
          }
          return (
            <MenuContainer
              onToggleLeftMenu={this.onToggleLeftMenu}
              handleBoardSelection={this.handleBoardSelection}
              handleHomeSelection={this.handleHomeSelection}
              isUpdateLeftNavigation={isUpdateLeftNavigation}
              renderKey={renderKey}
              userData={userDataProps}
              onUpdateTheTopLeftMenu={this.onUpdateTheTopLeftMenu}
              handleLoginPopUp={this.handleLoginPopUp}
              handleLoginPopUpType2={this.handleLoginPopUpType2}
              goHome={this.goHome}
              // handleSignupPopUp={this.handleSignupPopUp}
              // handleSignupPopUpType2={this.handleSignupPopUpType2}
              handleSignUpPopUpStep1={this.handleSignUpPopUpStep1}
              handleSignUpPopUpStep1Type2={this.handleSignUpPopUpStep1Type2}
              handleForgotPasswordPopUp={this.handleForgotPasswordPopUp}
              handleForgotPasswordType2={this.handleForgotPasswordType2}
              match={match}
              history={history}
              location={location}
              openLoginPopUp={this.openLoginPopUp}
              openSignUpPopUp={this.openSignUpPopUp}
              openForgotPasswordPopUp={this.openForgotPasswordPopUp}
              handleLoginProblem={this.handleLoginProblem}
              onSelectArea={this.handleSelectArea}
              clearTemporaryCookies={this.clearTemporaryCookies}
              isStoryPopupVisible={isStoryPopupVisible}
              color="#403f4c"
              bgColor="#ffffff"
              textColor="#7f7f7f"
            >
              {this.routeToMaproom()}
              <Helmet>
                <title>BroadStreet</title>
                <meta name="prerender-status-code" content="200" />

                <meta
                  name="description"
                  content="Data, tools, and stories to improve communities and inspire change."
                />
                <meta
                  name="keywords"
                  content="community, health, CHNA, dataviz, maps, indicators"
                />
                <meta name="copyright" content="BroadStreet Health LLC" />

                <meta property="og:locale" content="en_US" />
                <meta
                  property="og:site_name"
                  content="BroadStreet Data for a Purpose"
                />
                <meta property="og:title" content="Home - BroadStreet" />
                <meta property="og:type" content="website" />
                <meta
                  property="og:description"
                  content="Data, tools, and stories to improve communities and inspire change."
                />
                <meta
                  property="og:image"
                  content={apiBaseUrl + "images/BroadStreet_Logo2.png"}
                />
                <meta
                  property="og:url"
                  content={
                    "https://www.broadstreet.io/board" +
                    location.pathname +
                    location.search +
                    location.hash
                  }
                />
                <meta itemprop="name" content="BroadStreet" />
                <meta
                  itemprop="image"
                  content={apiBaseUrl + "images/BroadStreet_Logo2.png"}
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - BroadStreet" />
                <meta
                  name="twitter:description"
                  content="Data, tools, and stories to improve communities and inspire change."
                />
                <meta
                  name="twitter:image:src"
                  content={apiBaseUrl + "images/BroadStreet_Logo2.png"}
                />
              </Helmet>
              {match.params.component === "100mhl-mtm" ? (
                <Route
                  path="/100mhl-mtm"
                  render={() => (
                    <MapTheMovement
                      isLeftMenuOpen={isLeftMenuOpen}
                      updatingLeftMenu={this.updatingLeftMenu}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                    />
                  )}
                />
              ) : null}
              {match.path === "/" && !match.isExact ? <Error404 /> : null}
              {match.params.component === "BOARD" ? (
                <Route
                  path="/BOARD"
                  render={() => (
                    <DownloadConsumer>
                    {({id, setCardId}) => (
                    <ProjectBoard
                      isLeftMenuOpen={isLeftMenuOpen}
                      refreshLayout={refreshLayout}
                      checkUserLogin={this.checkUserLogin}
                      onRefreshLayout={this.onRefreshLayout}
                      selectedBoardId={unescape(match.params.filterTag)}
                      selectedBoardName={match.params.permaTag}
                      selectedBoardType={match.params.type}
                      updatingLeftMenu={this.updatingLeftMenu}
                      history={history}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      userData={userDataProps}
                      updatingLeftMenuOnCopyCollection={
                        this.updatingLeftMenuOnCopyCollection
                      }
                      notificationUpdate={this.notificationUpdate}
                      onCreateNewArea={this.openAreaSelector}
                      openStoryPopup={this.openStoryPopup}
                      openLoginPopUp={this.openLoginPopUp}
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      handleLoginPopUp={this.handleLoginPopUp}
                      isLoginPopUpVisible={isLoginPopUpVisible}
                      handleImageEditorPopUpVisible={
                        this.handleImageEditorPopUpVisible
                      }
                      avatarEditor={avatarEditor}
                      imageId={imageId}
                      parentImageId={parentImageId}
                      avatarImage={avtarImage}
                      match={match}
                      forceShowMembershipPopup={
                        this.forceShowStartMembershipPopup
                      }
                      setCardId={setCardId}
                    />
                    )}</DownloadConsumer>
                  )}
                />
              ) : null}
              {match.params.component === "sh" ? (
                <Route
                  path="/sh"
                  render={() => (
                    <InvitationLinkConfirm
                      isLeftMenuOpen={isLeftMenuOpen}
                      updatedBoard={this.onUpdatedBoard}
                      refreshLayout={refreshLayout}
                      onRefreshLayout={this.onRefreshLayout}
                      selectedBoardId={selectedBoardId}
                      selectedBoardName={selectedBoardName}
                      userData={userDataProps}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      handleLoginPopUp={this.handleLoginPopUp}
                      nodeType={match.params.nodeType}
                      invite={match.params.invite}
                      fromLeftMenu={fromLeftMenu}
                      homeClick={match.params.component}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "chna" ||
              match.params.component === "CHNA" ? (
                <Route
                  path="/chna"
                  render={() => (
                    <ChnaSplash
                      isLeftMenuOpen={isLeftMenuOpen}
                      refreshLayout={refreshLayout}
                      onRefreshLayout={this.onRefreshLayout}
                      selectedBoardId={selectedBoardId}
                      selectedBoardName={selectedBoardName}
                      userData={userDataProps}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      filterTag={match.params.filterTag}
                      permaTag={match.params.permaTag === "1" ? 1 : 0}
                      fromLeftMenu={fromLeftMenu}
                      homeClick={match.params.component}
                    />
                  )}
                />
              ) : null}

              {match.params.component === "my" ? (
                <Route
                  path="/my"
                  render={() => {
                    return (
                      <div>
                      <DownloadConsumer>
                       {({id, setCardId}) => (
                        <Story
                          pageUrl={"/my/" + match.params.homepage}
                          openStoryPopup={this.openStoryPopup}
                          checkUserLogin={this.checkUserLogin}
                          openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                          openCardFamilyPopupWithUrl={
                            this.openCardFamilyPopupWithUrl
                          }
                          closeStoryPopup={this.closeStoryPopup}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          storyId={null}
                          userData={userDataProps}
                          changeCardHeight={this.changeCardHeight}
                          showToolTip={this.showToolTip}
                          hideToolTip={this.hideToolTip}
                          hidePopUp={this.hidePopUp}
                          openedCardsArray={openedCardsArray}
                          history={history}
                          handleLoginPopUp={this.handleLoginPopUp}
                          isWordpressStory={false}
                          paramComponent={match.params.component}
                          isSavedToolTip={isSavedTooltip}
                          savedCardId={savedCardId}
                          setCardId={setCardId}
                          onRefreshLayout={this.onRefreshLayout}
                          updatingLeftMenuOnCopyCollection={
                            this.updatingLeftMenuOnCopyCollection
                          }
                        />
                       )}</DownloadConsumer>
                      </div>
                    );
                  }}
                />
              ) : null}
              {match.params.component === "story" ? (
                <Route
                  path="/story"
                  render={() => {
                    return (
                      <div>
                      <DownloadConsumer>
                       {({id, setCardId}) => (
                        <Story
                          pageUrl={"/my/" + match.params.homepage}
                          openStoryPopup={this.openStoryPopup}
                          checkUserLogin={this.checkUserLogin}
                          openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                          openCardFamilyPopupWithUrl={
                            this.openCardFamilyPopupWithUrl
                          }
                          closeStoryPopup={this.closeStoryPopup}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          storyId={null}
                          userData={userDataProps}
                          changeCardHeight={this.changeCardHeight}
                          showToolTip={this.showToolTip}
                          hideToolTip={this.hideToolTip}
                          hidePopUp={this.hidePopUp}
                          openedCardsArray={openedCardsArray}
                          history={history}
                          handleLoginPopUp={this.handleLoginPopUp}
                          isWordpressStory={false}
                          paramComponent={match.params.component}
                          isSavedToolTip={isSavedTooltip}
                          savedCardId={savedCardId}
                          setCardId={setCardId}
                          onRefreshLayout={this.onRefreshLayout}
                          updatingLeftMenuOnCopyCollection={
                            this.updatingLeftMenuOnCopyCollection
                          }
                        />
                       )}</DownloadConsumer>
                      </div>
                    );
                  }}
                />
              ) : null}
              {match.isExact &&
              (match.params.component === "HOME" ||
                match.params.component === "areaselector") ? (
                <Route
                  path="/HOME"
                  render={() => (
                    <CustomHomePage
                      updateCustomPage={updateCustomPage}
                      checkUserLogin={this.checkUserLogin}
                      isLeftMenuOpen={isLeftMenuOpen}
                      refreshLayout={refreshLayout}
                      selectedBoardId={selectedBoardId}
                      selectedBoardName={selectedBoardName}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      filterTag={match.params.filterTag}
                      permaTag={match.params.permaTag === "1" ? 1 : 0}
                      fromLeftMenu={fromLeftMenu}
                      homeClick={match.params.component}
                      openStoryPopup={this.openStoryPopup}
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      closeStoryPopup={this.closeStoryPopup}
                      handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                      storyId={match.params.cardid}
                      userData={userDataProps}
                      changeCardHeight={this.changeCardHeight}
                      showToolTip={this.showToolTip}
                      hideToolTip={this.hideToolTip}
                      hidePopUp={this.hidePopUp}
                      hideUnHidePopUp={this.hideUnHidePopUp}
                      openedCardsArray={openedCardsArray}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                      isWordpressStory={false}
                      paramComponent={match.params.component}
                    />
                  )}
                />
              ) : null}
              {match.path === "/" ||
              match.params.component == null ||
              match.params.component === "index.html" ||
              match.params.component === "EXPLOREMAPS" ||
              match.params.component === "RUNAREPORT" ||
              match.params.component === "NEWS" ? (
                <Route
                  path="/"
                  render={() => (
                    <HomeFeed
                      isLeftMenuOpen={isLeftMenuOpen}
                      refreshLayout={refreshLayout}
                      checkUserLogin={this.checkUserLogin}
                      onRefreshLayout={this.onRefreshLayout}
                      selectedBoardId={selectedBoardId}
                      selectedBoardName={selectedBoardName}
                      userData={userDataProps}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      filterTag={match.params.filterTag}
                      permaTag={match.params.permaTag === "1" ? 1 : 0}
                      fromLeftMenu={fromLeftMenu}
                      homeClick={match.params.component}
                      handleLoginPopUp={this.handleLoginPopUp}
                      onRef={(ref) => (this.child = ref)}
                      openStoryPopup={this.openStoryPopup}
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      history={history}
                      isSavedToolTip={isSavedTooltip}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "FAVORITES" ? (
                <Route
                  path="/FAVORITES"
                  render={() => (
                    <Favorites
                      isLeftMenuOpen={isLeftMenuOpen}
                      checkUserLogin={this.checkUserLogin}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "STORIES" ? (
                <Route
                  path="/STORIES"
                  render={() => (
                    <Stories
                      isLeftMenuOpen={isLeftMenuOpen}
                      checkUserLogin={this.checkUserLogin}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                      showMyTemplates={false}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "MAPS" ? (
                <Route
                  path="/MAPS"
                  render={() => (
                    <Maps
                      isLeftMenuOpen={isLeftMenuOpen}
                      checkUserLogin={this.checkUserLogin}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                      showMyTemplates={true}
                    />
                  )}
                />
              ) : null}

              {match.params.component === "TEMPLATES" ? (
                <Route
                  path="/TEMPLATES"
                  render={() => (
                    <Stories
                      checkUserLogin={this.checkUserLogin}
                      isLeftMenuOpen={isLeftMenuOpen}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                      showMyTemplates={true}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "AREAS" ? (
                <Route
                  path="/AREAS"
                  render={() => (
                    <Areas
                      checkUserLogin={this.checkUserLogin}
                      isLeftMenuOpen={isLeftMenuOpen}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openCardFamilyPopupWithUrl={
                        this.openCardFamilyPopupWithUrl
                      }
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                      showMyTemplates={false}
                      onSelectArea={this.handleSelectArea}
                      isAreaSelectorVisible={isAreaSelectorVisible}
                      newCreatedAreaId={newCreatedAreaId}
                      areaSelected={areaSelected}
                      handleCreateNewArea={this.handleHomeSelection}
                      match={match}
                    />
                  )}
                />
              ) : null}
              {match.params.component === "pubboard" ? (
                <Route
                  path="/pubboard"
                  render={() => {
                    return (
                      <div>
                      <DownloadConsumer>
                       {({id, setCardId}) => (
                        <Story
                          checkUserLogin={this.checkUserLogin}
                          openStoryPopup={this.openStoryPopup}
                          openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                          openCardFamilyPopupWithUrl={
                            this.openCardFamilyPopupWithUrl
                          }
                          closeStoryPopup={this.closeStoryPopup}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          storyId={match.params.cardid}
                          wideCardId={match.params.widecardid}
                          userData={userDataProps}
                          changeCardHeight={this.changeCardHeight}
                          showToolTip={this.showToolTip}
                          hideToolTip={this.hideToolTip}
                          hidePopUp={this.hidePopUp}
                          openedCardsArray={openedCardsArray}
                          history={history}
                          handleLoginPopUp={this.handleLoginPopUp}
                          isWordpressStory={false}
                          paramComponent={match.params.component}
                          isSavedToolTip={isSavedTooltip}
                          savedCardId={savedCardId}
                          setCardId={setCardId}
                          onRefreshLayout={this.onRefreshLayout}
                          updatingLeftMenuOnCopyCollection={
                            this.updatingLeftMenuOnCopyCollection
                          }
                        />
                       )}</DownloadConsumer>
                      </div>
                    );
                  }}
                />
              ) : null}
              {match.params.component === "card" ? (
                <Route
                  path="/card"
                  render={() => {
                    const cardId = match.params.cardid;
                    return (
                      <WideCardPopup
                        checkUserLogin={this.checkUserLogin}
                        cardId={cardId}
                        closeWideCardPopup={() => {
                          history.push("/");
                        }}
                        history={history}
                        userData={userDataProps}
                        handleTwitterCardPopup={this.handleTwitterCardPopup}
                      />
                    );
                  }}
                />
              ) : null}
              {showTwitterCardPopup && (
                <WideCardPopup
                  checkUserLogin={this.checkUserLogin}
                  cardId={match.params.cardid}
                  showFullPage={true}
                  showTwitterCardPopup={showTwitterCardPopup}
                  userData={userDataProps}
                  history={history}
                />
              )}
              {match.params.component === "story" &&
              match.params.year != null ? (
                <Route
                  path="/story"
                  render={() => (
                    <div>
                      <QueryRenderer
                        environment={environment}
                        query={graphql`
                          query RootStoryQuery($input: BlogStoryLinkInput!) {
                            cardForStory(storyLinkInfo: $input) {
                              id
                            }
                          }
                        `}
                        variables={{
                          input: {
                            year: match.params.year,
                            month: match.params.month,
                            slug: match.params.slug,
                          },
                        }}
                        render={({ error, props }) => {
                          if (error) {
                            console.error(error);
                            return <div>Error!</div>;
                          }
                          if (!props) {
                            return <div />;
                          }
                          // looks like legitimate story URL but not found, this should never happen
                          if (props.cardForStory == null) {
                            return (
                              <div className="rootModalPopover">
                                <Error404 />
                              </div>
                            );
                          }
                          return (
                          <DownloadConsumer>
                           {({id, setCardId}) => (
                            <Story
                              openStoryPopup={this.openStoryPopup}
                              checkUserLogin={this.checkUserLogin}
                              openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                              openCardFamilyPopupWithUrl={
                                this.openCardFamilyPopupWithUrl
                              }
                              closeStoryPopup={this.closeStoryPopup}
                              storyId={props.cardForStory.id}
                              userData={userDataProps}
                              handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                              changeCardHeight={this.changeCardHeight}
                              handleLoginPopUp={this.handleLoginPopUp}
                              openedCardsArray={openedCardsArray}
                              isSavedToolTip={isSavedTooltip}
                              savedCardId={savedCardId}
                              setCardId={setCardId}
                              onRefreshLayout={this.onRefreshLayout}
                            />
                           )}</DownloadConsumer>
                          );
                        }}
                      />
                    </div>
                  )}
                />
              ) : null}
              {(isShowStartMembershipPopup || startMembership) && (
                <StartMemberShipPopup
                  closePopUp={() => {
                    this.setState({
                      isShowStartMembershipPopup: false,
                    });
                    startMembershipAction(false);
                  }}
                  startMemberShip={() => {
                    this.setState({
                      isShowStartMembershipPopup: false,
                      isMemberShipPopUpVisible: true,
                    });
                    startMembershipAction(false);
                  }}
                  isPausedAccount={
                    subscriptionInfo &&
                    subscriptionInfo.pauseSubscriptionStartDate != null
                  }
                />
              )}
              {isMembershipActivatedVisible && (
                <MembershipActivatedPopup
                  subscriptionType={subscriptionTypeActivated}
                  closePopUp={() => {
                    this.setState({
                      isMembershipActivatedVisible: false,
                      subscriptionTypeActivated: null,
                    });
                  }}
                  viewBilling={() => {
                    history.push("/accountSetting/billing");
                  }}
                />
              )}
              {isStoryPopupVisible ? (
                <DownloadConsumer>
                {({id, setCardId}) => (
                <Story
                  checkUserLogin={this.checkUserLogin}
                  openStoryPopup={this.openStoryPopup}
                  openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                  openCardFamilyPopupWithUrl={this.openCardFamilyPopupWithUrl}
                  closeStoryPopup={this.closeStoryPopup}
                  storyId={
                    storyCardSelectedStack[storyCardSelectedStack.length - 1]
                  }
                  userData={userDataProps}
                  handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                  changeCardHeight={this.changeCardHeight}
                  handleLoginPopUp={this.handleLoginPopUp}
                  isSavedToolTip={isSavedTooltip}
                  savedCardId={savedCardId}
                  setCardId={setCardId}
                  onRefreshLayout={this.onRefreshLayout}
                  openedCardsArray={openedCardsArray}
                />
                )}</DownloadConsumer>
              ) : null}
              {isSubscriptionVisible ? <SubscriptionPage /> : null}

              {match.params.component === "card-family" ? (
                <Route
                  path="/card-family"
                  render={() => {
                    return (
                      <div>
                        <HomeFeed
                          isLeftMenuOpen={isLeftMenuOpen}
                          goBackOnClose={pageviews > 0}
                          checkUserLogin={this.checkUserLogin}
                          refreshLayout={refreshLayout}
                          onRefreshLayout={this.onRefreshLayout}
                          selectedBoardId={selectedBoardId}
                          selectedBoardName={selectedBoardName}
                          userData={userDataProps}
                          handleBrowseAllStories={this.handleBrowseAllStories}
                          handleExploreMapsAndData={
                            this.handleExploreMapsAndData
                          }
                          filterTag={""}
                          permaTag={1}
                          history={history}
                          fromLeftMenu={fromLeftMenu}
                          homeClick={"FAVORITES"}
                          handleLoginPopUp={this.handleLoginPopUp}
                          onRef={(ref) => (this.child = ref)}
                          openStoryPopup={this.openStoryPopup}
                          openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                          isSavedToolTip={isSavedTooltip}
                        />
                        <CardFamily
                          cardId={match.params.cardid}
                          updateCustomPage={updateCustomPage}
                          checkUserLogin={this.checkUserLogin}
                          handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                          closeCardFamilyPopup={this.closeCardFamilyPopup}
                          changeCardHeight={this.changeCardHeight}
                          isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                          isShareCardPopUpVisible={isShareCardPopUpVisible}
                          handleEditHashTagPopUp={this.handleEditHashTagPopUp}
                          userData={userDataProps}
                          history={history}
                          isSavedTooltip={isSavedTooltip}
                        />
                      </div>
                    );
                  }}
                />
              ) : null}
              {match.params.component === "accountSetting" ? (
                <AccountPage
                  {...this.props}
                  imageId={imageId}
                  avatarImage={avtarImage}
                  handleImageEditorPopUpVisible={
                    this.handleImageEditorPopUpVisible
                  }
                  avatarEditor={avatarEditor}
                  updatedBoard={this.onUpdatedBoard}
                  handleMembershipActivated={this.handleMembershipActivated}
                  render={() => (
                    <Favorites
                      isLeftMenuOpen={isLeftMenuOpen}
                      handleBrowseAllStories={this.handleBrowseAllStories}
                      handleExploreMapsAndData={this.handleExploreMapsAndData}
                      userData={userDataProps}
                      openStoryPopup={this.openStoryPopup}
                      openStoryPopupWithUrl={this.openStoryPopupWithUrl}
                      history={history}
                      handleLoginPopUp={this.handleLoginPopUp}
                    />
                  )}
                />
              ) : null}

              {match.params.component === "invoice" && (
                <InvoicePage {...this.props} />
              )}
              {match.params.component === "choosearea" ? (
                <ChooseAreaPopup
                  userData={
                    props.currentUser.edges.length > 0
                      ? props.currentUser
                      : null
                  }
                  {...this.props}
                />
              ) : null}

            {this.props.match.params.component === "browseindicators" ?
              (<div>
                <Indicators {...this.props} />
              </div>)
              : null}
              {match.params.component === "mysavedareas" ? (
                <MySavedAreasPopup
                  userData={
                    props.currentUser.edges.length > 0
                      ? props.currentUser
                      : null
                  }
                  onSelectArea={this.handleSelectArea}
                  checkUserLogin={this.checkUserLogin}
                  handleLoginPopup={this.handleLoginPopUp}
                  onClose={this.closeMySavedAreapopup}
                  {...this.props}
                />
              ) : null}
              {match.params.component === "areaselector" ? (
                <ServiceAreaSelector
                  {...this.props}
                  key={"sas" + match.params.filterTag}
                  savedAreaId={selectedAreaId}
                  checkUserLogin={this.checkUserLogin}
                  createMapOnClose={
                    ["makeamap"].indexOf(match.params.filterTag) > -1
                      ? true
                      : false
                  }
                  createIndicatorReportOnClose={
                    reportTypeUriArray.indexOf(match.params.filterTag) > -1 ||
                    (match.params.filterTag != null &&
                      match.params.filterTag.startsWith("user"))
                      ? true
                      : false
                  }
                  reportType={match.params.filterTag}
                  onClose={this.closeServiceAreaSelector}
                  onSave={this.handleAreaSaved}
                  showLogin={this.handleLoginPopUp}
                  userData={
                    props.currentUser.edges.length > 0
                      ? props.currentUser
                      : null
                  }
                />
              ) : isAreaSelectorVisible === true ? (
                <ServiceAreaSelector
                  {...this.props}
                  key={"sas" + areaSelected}
                  savedAreaId={areaSelected}
                  checkUserLogin={this.checkUserLogin}
                  onClose={this.closeServiceAreaSelector}
                  onSave={this.handleAreaSaved}
                  areaName={areaSelectorName}
                  showLogin={this.handleLoginPopUp}
                  userData={
                    props.currentUser.edges.length > 0
                      ? props.currentUser
                      : null
                  }
                />
              ) : null}
              {isLoginPopUpVisible === true ? (
                <LoginPopUp
                  handleLoginProblem={this.handleLoginProblem}
                  openSignUpPopUp={this.openSignUpPopUp}
                  openForgotPasswordPopUp={this.openForgotPasswordPopUp}
                  loginPopUpType={loginPopUpType}
                  onLogin={this.onLogin}
                  // userData={props}
                  onUpdateTheTopLeftMenu={this.onUpdateTheTopLeftMenu}
                  history={history}
                  location={location}
                  handleLoginPopUp={(e) => {
                    this.handleLoginPopUp();
                    if (e) {
                      this.setState({ isForgotPasswordPopUpVisible: true });
                    }
                  }}
                />
              ) : null}
              {isMemberShipPopUpVisible && (
                <MemberShipPopup
                  closePopUp={this.handleMemberShipPopup}
                  onSuccess={this.handleMembershipActivated}
                />
              )}
              {match.params.component === "gsignin" ? (
                <div className="signup-input-group mt-4 mb-3">
                  <div id="g-signin2" className="g-signin2">
                    {/* <button style={{height:50, width:190}} >Sign In with Google</button> */}
                  </div>
                </div>
              ) : null}
              {isSignupPopUpStep1Visible === true &&
              !userDataProps.currentUser.edges.length ? (
                <SignUpPopUpStep1
                  handleLoginPopUp={() => {
                    this.setState({
                      isSignupPopUpStep1Visible: false,
                      isForgotPasswordPopUpVisible: false,
                      isLoginPopUpVisible: true,
                      loginPopUpType: "Normal",
                    });
                  }}
                  openLoginPopUp={this.openLoginPopUp}
                  openForgotPasswordPopUp={this.openForgotPasswordPopUp}
                  SignupPopUpStep1Type={(e) => {
                    this.handleSignUpPopUpStep1();
                  }}
                  handleSignUpPopUpStep1={this.handleSignUpPopUpStep1}
                  onLogin={this.onLogin}
                  onUpdateTheTopLeftMenu={this.onUpdateTheTopLeftMenu}
                />
              ) : null}
              {isForgotPasswordPopUpVisible === true &&
              !userDataProps.currentUser.edges.length ? (
                <ForgotPasswordPopUp
                  isLegacyUser={isLegacyUser}
                  openLoginPopUp={this.openLoginPopUp}
                  openSignUpPopUp={this.openSignUpPopUp}
                  handleForgotPasswordPopUp={this.handleForgotPasswordPopUp}
                />
              ) : null}
              {isImageEditorPopUpVisible ? (
                <div>
                  <ImageEditorPopup
                    handleImageEditorPopUpVisible={
                      this.handleImageEditorPopUpVisible
                    }
                    avatarEditorRatio={avatarEditorRatio}
                    avatarImage={avtarImage}
                    avatarHeight={avtarHeight}
                    style={avtarStyle}
                    history={history}
                    userData={userDataProps}
                    closePopUp={() =>
                      this.setState({ isImageEditorPopUpVisible: false })
                    }
                  />
                </div>
              ) : null}
              <ToolTip
                x={xOrigin}
                y={yOrigin}
                isVisible={isToolTipVisible}
                text={text}
              />
              {isSaveCardsPopUpVisible ? (
                <SaveCardsPopUp
                  isVisible={isSaveCardsPopUpVisible}
                  x={xOriginSaveCardsPopUp}
                  y={yOriginSaveCardsPopUp}
                  cardId={selectedCardForMutation}
                  hidePopUp={this.hidePopUp}
                  history={history}
                  saveCardFromMore={saveCardFromMore}
                  createDataDownloadCard={createDataDownloadCard}
                />
              ) : null}

              {isSaveCardsPopUpVisibleForHome ? (
                <SaveCardsPopUp
                  isVisible={isSaveCardsPopUpVisibleForHome}
                  history={history}
                  x={xOriginSaveCardsPopUpForHome}
                  y={yOriginSaveCardsPopUpForHome}
                  cardId={selectedCardForMutation}
                  hidePopUp={this.hidePopUp}
                  isHomeFeed={true}
                  saveCardFromMore={saveCardFromMore}
                  createDataDownloadCard={createDataDownloadCard}
                />
              ) : null}
              <MoreOptionPopup
                x={xOriginForMorePopUp}
                y={yOriginForMorePopUp}
                isVisible={isMorePopUpVisible}
                handleShareCardPopUp={this.handleShareCardPopUp}
                handleEditHashTagPopUp={this.handleEditHashTagPopUp}
                handleReportErrorPopUp={this.handleReportErrorPopUp}
                isHomeFeed={false}
                isFavorites={true}
                isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
                isCollections={false}
                copyCardToCollection={this.copyCardToCollection}
                removeCardFromCollections={this.removeCardFromCollections}
                cardId={selectedCardForMutation}
                handleReRenderCard={this.handleReRenderCard}
                handleLoginPopUp={this.handleLoginPopUp}
                userData={userDataProps}
              />
              <ReportErrorPopUp
                isVisible={isReportErrorPopUpVisible}
                x={xOriginReportErrorPopUp}
                y={yOriginReportErrorPopUp}
                hidePopUp={this.hidePopUp}
                cardId={selectedCardForMutation}
                showToolTip={this.showToolTip}
                hideToolTip={this.hideToolTip}
              />
            </MenuContainer>
          );
        }}
      />
    );
  }
}

const mapStatusToProps = (state) => ({
  userData: state.user.data,
  subscriptionInfo: state.user.subscriptionInfo,
  startMembership: state.user.startMembership,
});

const mapDispatchToProps = {
  setUserInfoAction,
  getSubscriptionInfo,
  startMembershipAction,
};

const ProjectBoardRoot = connect(
  mapStatusToProps,
  mapDispatchToProps
)(_ProjectBoardRoot);

class Map extends Component {
  render() {
    return (
      <div>
        <h1>map room</h1>
      </div>
    );
  }
}

class SignUpRootComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignupPopUpVisible: true,
      isForgotPasswordPopUpVisible: false,
      SignupPopUpType: "Action",
      match: "",
      selectedCard: null,
      updateCustomPage: false,
    };
  }

  handleSignupPopUp = () => {
    const { cookies, history } = this.props;
    // go to previously-requested page after completing signup
    var requestedPage = "";
    if (cookies != null) {
      requestedPage = cookies.get("requestedPage", {
        doNotParse: true,
      });
    }
    if (requestedPage == null || requestedPage === "") {
      history.push("/HOME");
    } else {
      history.push(requestedPage);
    }
    this.setState((prevState) => ({
      isSignupPopUpVisible: !prevState.isSignupPopUpVisible,
      SignupPopUpType: "Action",
    }));
  };

  render() {
    const { isSignupPopUpVisible } = this.state;
    const { match } = this.props;
    return isSignupPopUpVisible === true ? (
      <SignupPopUp
        openForgotPasswordPopUp={() => {
          this.setState({
            isSignupPopUpVisible: false,
            isForgotPasswordPopUpVisible: true,
          });
        }}
        // SignupPopUpType={(e) => { this.handleSignUpPopUp }}
        handleSignupPopUp={this.handleSignupPopUp}
        match={match}
      />
    ) : null;
  }
}

class ForgotPasswordRootComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgotPasswordPopUpStep2Visible: true,
      isSignupPopUpStep1Visible: false,
      ForgotPasswordStep2PopUpType: "Action",
      match: "",
    };
  }

  handleForgotPasswordStep2PopUp = () => {
    const { history } = this.props;
    history.push("/HOME");
    this.setState((prevState) => ({
      isForgotPasswordPopUpStep2Visible:
        !prevState.isForgotPasswordPopUpStep2Visible,
      ForgotPasswordStep2PopUpType: "Action",
    }));
  };

  render() {
    const { isForgotPasswordPopUpStep2Visible } = this.state;
    const { match } = this.props;

    return isForgotPasswordPopUpStep2Visible === true ? (
      <ForgotPasswordPopUpStep2
        openSignUpPopUp={() => {
          this.setState({
            isForgotPasswordPopUpStep2Visible: false,
            isSignupPopUpStep1Visible: true,
          });
        }}
        handleForgotPasswordStep2PopUp={this.handleForgotPasswordStep2PopUp}
        match={match}
      />
    ) : null;
  }
}
class Root extends Component {
  constructor(props) {
    super(props);
    console.log("v0.22.9d");
  }

  render() {
    const { cookies } = this.props;

    return (
      <Router basename="/board/">
        <DownloadProvider>
          <div>
            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              hideProgressBar={true}
              toastClassName={"background-secondary"}
              autoClose={1500}
              transition={Slide}
              closeOnClick={true}
              enableMultiContainer={false}
            />
            <Switch>
              <Route
                exact
                path="/"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route exact path="/:component(RUNAREPORT|EXPLOREMAPS)">
                <Redirect to="/" />
              </Route>
              <Route
                exact
                path="/:component(HOME|index.html|FAVORITES|NEWS|STORIES|MAPS|TEMPLATES|AREAS|100mhl-mtm|invoice|accountSetting|areaselector|choosearea|mysavedareas|browseindicators)/:filterTag?/:permaTag?"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/signupProfile/:email/:token"
                render={(routeProps) => (
                  <SignUpRootComponent cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(signup)"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/ref/:code"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(gsignin)"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(signin)"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(subscription)"
                render={(routeProps) => (
                  <SubscriptionPage cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(membership)"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(chna)/"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(BOARD)/:type/:filterTag?/:permaTag?"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(sh)/:nodeType/:invite"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(my)/:homepage"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/:component(story)/:homepage"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/passwordreset/:email/:token"
                component={ForgotPasswordRootComponent}
              />
              <Route path="/map/:id" component={Map} />
              <Route
                exact
                path="/:component(story)/:year(2[0-9][0-9][0-9])/:month/:day?/:slug"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(pubboard)/:cardid/:widecardid?"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(card)/:cardid"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                path="/:component(card-family)/:cardid/:areaid?"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/invoice/:id"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/password"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/myProfile"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/referFriends"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/accountOverview"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/accountOverview/new"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                exact
                path="/accountSetting/billing"
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
              <Route
                render={(routeProps) => (
                  <ProjectBoardRoot cookies={cookies} {...routeProps} />
                )}
              />
            </Switch>
          </div>
        </DownloadProvider>
      </Router>
    );
  }
}

export default withCookies(Root);
