import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import AdiMap from "../AdiMap/AdiMap";
import "../../css/components/Card/D3DisparitiesDeprivationWideChart.css";
import "../../css/components/Card/WideAdiMapCardContainer.css";
import WideCardWrapper from "../WideCard/WideCardWrapper";
import { DownloadConsumer } from "../Root/downloadContext";

class WideAdiMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
      legends: [
        { label: "Most Affluent", color: "#78A22F" },
        { label: "", color: "#6A8D73" },
        { label: "Average", color: "#D5DED6" },
        { label: "", color: "#FFA987" },
        { label: "Most Deprived", color: "#FF6B35" },
      ],
    };
  }

  render() {
    const { legends } = this.state;
    const { showTwitterCardPopup, isEditingEnabled, id } = this.props;
    const middleContent = (
      <ul className="list-group mt-3 mt-md-5 legend text-left">
        {legends.map((legend, index) => {
          return (
            <li
              key={index}
              className="list-group-item p-1"
              style={{ lineHeight: 0.5 }}
            >
              <div style={{ background: legend.color }} />
              {legend.label}
            </li>
          );
        })}
      </ul>
    );
    const content = (
      <div
        className={
          "wideAdiMapCardContainer h-100 " +
          (isEditingEnabled ? " movable" : " clickable")
        }
        onClick={this.handleClick}
      >
        <div className="wideAdiMapCardContainer-map">
          <QueryRenderer
            environment={environment}
            query={graphql`
              query WideAdiMapCardContainerQuery($input: ID!) {
                boardCardUse(id: $input) {
                  id
                  statistics {
                    id
                    jsonData
                  }
                  configuration {
                    savedArea {
                      counties {
                        edges {
                          node {
                            id
                            fips5
                            geojsonFeature
                          }
                        }
                      }
                      reportstatisticsSet {
                        edges {
                          node {
                            name
                            areaDeprivationIndex
                            blockGroups {
                              edges {
                                node {
                                  id
                                  fips5
                                  fips12
                                  geojsonWithProperties
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            variables={{ input: id }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return (
                  <div>
                    <br />
                    <br />
                    Loading...
                  </div>
                );
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    You need to log in as a user with permissions to access this
                    content.
                  </div>
                );
              }
              const { savedArea } = props.boardCardUse.configuration;
              if (
                savedArea.reportstatisticsSet &&
                savedArea.reportstatisticsSet.edges.length === 0
              ) {
                return (
                  <div>
                    <br />
                    (Map Not Available)
                  </div>
                );
              }
              return (
                <div className="h-100">
                  <AdiMap
                    id={
                      "widemap" + id + (showTwitterCardPopup ? "twitter" : "")
                    }
                    counties={savedArea.counties.edges}
                    blockgroups={
                      savedArea.reportstatisticsSet.edges[0].node.blockGroups
                        .edges
                    }
                  />
                  <div className="wideAdiMapCardContainer-watermrk-img">
                    <img
                      src={require("../../images/favicon1.svg")}
                      alt="BroadStreet"
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
    return (
      <DownloadConsumer>
        {(context) => {
          context.setCardId(id);
          context.setCardType("map");
          return showTwitterCardPopup ? (
            content
          ) : (
            <WideCardWrapper middleContent={middleContent} {...this.props}>
              {content}
            </WideCardWrapper>
          );
        }}
      </DownloadConsumer>
    );
  }
}

export default WideAdiMapCardContainer;
