import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import AvatarEditor from "react-avatar-editor";
import SelectFeaturedImagePopup from "../CommonComponents/SelectFeaturedImagePopup";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { commitMutation } from "react-relay";
import { StandardTallModalPopup } from "common-components";
import "../../css/components/ImageEditorPopup/ImageEditorPopup.css";
import "../../css/components/CommonComponents/SelectFeaturedImagePopup.css";
import { Link } from "react-router-dom";

const ImageEditorPopup = (props) => {
  const {
    avatarHeight,
    avatarEditorRatio,
    handleImageEditorPopUpVisible,
    style,
    avatarImage,
    userData,
    closePopUp,
    handleUpdateBoard,
    handleLoginPopUp,
  } = props;

  const [imageBase, setImageBase] = useState(avatarImage ? avatarImage : "");
  const [imageName, setImageName] = useState("");
  const [rotate, setRotate] = useState(0);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [scale, setScale] = useState(1);
  const [parentImageId, setParentImageId] = useState();
  const [isFeatureImageModalVisible, setIsFeatureImageModalVisible] =
    useState(false);

  const max = 3;
  const min = 0.25;

  const addimage = require("../../images/uni-featured.png");
  const placeholderImageList = [
    require("../../images-placeholder/profile5.png"),
    require("../../images-placeholder/profile2.png"),
    require("../../images-placeholder/profile3.png"),
    require("../../images-placeholder/profile4.png"),
    require("../../images-placeholder/profile1.png"),
  ];

  const handleImageRandomize = () => {
    const num = Math.floor(Math.random() * placeholderImageList.length);
    const randomImage = placeholderImageList[num];
    setParentImageId(null);
    setImageBase(randomImage);
  };

  const addImage = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      // TODO: move reader to on upload mutation succcess
      reader.onload = () => {
        setImageBase(reader.result);
        setImageName(file.name);
        setParentImageId(null);
        fetch(reader.result)
          .then((res) => res.blob())
          .then((blob) => {
            handleUploadImage(
              { 0: new File([blob], "ProjectImg.png", { type: "image/png" }) },
              "original"
            );
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadImage = (file, imageList) => {
    const mutation = graphql`
      mutation ImageEditorPopupMutation($input: UploadBoardImageInput!) {
        uploadBoardImage(input: $input) {
          success
          boardImageNode {
            id
            name
            url
          }
        }
      }
    `;
    let uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    const variables = {
      input: {
        file: null,
        imageList: imageList,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      uploadables,
      onCompleted: (response, errors) => {
        setParentImageId(response.uploadBoardImage.boardImageNode.id);
        console.log("parent image uploaded");
      },
      onError: (err) => console.error(err),
    });
  };

  const rotateMethod = (value) => {
    setRotate(rotate + value);
    setWidth(height);
    setHeight(width);
  };

  const scaleMethod = (value) => {
    setScale(value);
  };

  useEffect(() => {
    let width = avatarEditorRatio === "16:5" ? 850 : 683;
    let height = avatarHeight;
    if (height) {
      const ratio = avatarEditorRatio.split(":");
      width = (ratio[0] * height) / ratio[1];
    } else {
      const ratio = avatarEditorRatio.split(":");
      height = (width * ratio[1]) / ratio[0];
    }
    setHeight(height);
    setWidth(width);
  }, [avatarEditorRatio, avatarHeight]);

  const onClickSave = () => {
    if (editorRef.current) {
      handleImageEditorPopUpVisible(
        avatarEditorRatio,
        editorRef.current,
        null,
        null,
        null,
        null,
        parentImageId
      );
      console.log("close popup on save");
    } else {
      console.log("failed to save");
    }
  };

  const onSelectFeaturedImage = (featureImageId, url) => {
    setParentImageId(featureImageId);
    setImageBase(url);
  };

  const editorRef = useRef();
  const customStyle = style ? style : {};

  const AvatarEditorComponent = () => {
    return (
      <>
        <div className="row mb-0">
          <div className="col-12 p-0 text-center">
            <AvatarEditor
              ref={editorRef}
              image={imageBase ? imageBase : addimage}
              width={width}
              height={height}
              border={30}
              color={[0, 0, 0, 0.6]} // RGBA
              scale={scale}
              rotate={rotate}
              style={customStyle}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-10 text-right">
            <Link
              style={{ textDecoration: "underline", color: "#008faa" }}
              className="btn-text"
              onClick={handleImageRandomize}
            >
              Placeholder
            </Link>
          </div>
        </div>
      </>
    );
  };

  const uploadImageRef = useRef();
  const pages = [
    {
      showTitle: true,
      title: "Edit Image",
      showPageItem: true,
      showButton: true,
      buttonTitle: "Save",
      name: "edit-image",
      modalCustomClass: "overflow-x px-3",
      handleOnClick: () => {
        onClickSave();
      },
      formItems: [
        {
          type: "image",
          component: <AvatarEditorComponent />,
        },
        {
          fieldContainerClass: "d-flex mt-5",
          label: "My Images:",
          labelClassName: "min-w-100",
          type: "button",
          buttonTitle: "SELECT",
          helperText: "Browse Images",
          helperTextClass: "notes",
          handleOnClick: () => {
            setIsFeatureImageModalVisible(true);
          },
        },
        {
          fieldContainerClass: "d-flex  mt-3",
          label: "Upload File:",
          labelClassName: "min-w-100",
          type: "button",
          buttonTitle: "UPLOAD",
          helperText: imageName ? imageName : "No File Selected",
          helperTextClass: "notes",
          handleOnClick: () =>
            ReactDOM.findDOMNode(uploadImageRef.current).click(),
        },
        {
          fieldContainerClass: "d-flex mt-3",
          type: "file",
          ref: uploadImageRef,
          accept: "image/png,image/x-png,image/gif,image/jpeg",
          multiple: false,
          className: "hidden",
          onChange: addImage,
        },
        {
          componentWrapperClass: "d-flex",
          fieldContainerClass: "d-flex mt-3",
          label: "Zoom:",
          labelClassName: "min-w-100",
          type: "slider",
          parentClassName: "w-75 mr-2 mt-2",
          min: min,
          max: max,
          scale: scale,
          onChange: scaleMethod,
        },
        {
          label: "Rotate:",
          labelClassName: "min-w-100",
          fieldContainerClass: "d-flex mt-3",
          componentWrapperClass: "d-flex",
          type: "button",
          multipleButtons: true,
          buttons: [
            {
              buttonTitle: "LEFT",
              handleOnClick: () => rotateMethod(-90),
            },
            {
              buttonTitle: "RIGHT",
              handleOnClick: () => rotateMethod(+90),
            },
          ],
        },
      ],
    },
  ];

  const [pagesArray, setPagesArray] = useState(pages);
  const currentPage = pages[0];
  useEffect(() => {
    setPagesArray(pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageBase, scale, rotate, imageName]);

  const imageType =
    avatarEditorRatio === "16:5"
      ? "banner"
      : avatarEditorRatio === "3:2"
      ? "co"
      : "squarefeature";

  return (
    <>
      {isFeatureImageModalVisible ? (
        <SelectFeaturedImagePopup
          avatarEditorRatio={avatarEditorRatio}
          avatarImage={avatarImage}
          avatarHeight={avatarHeight}
          hash={"#selectfeaturedimage"}
          userData={userData}
          handleUpdateBoard={handleUpdateBoard}
          handleLoginPopUp={handleLoginPopUp}
          onSelectFeaturedImage={onSelectFeaturedImage}
          goBack={() => setIsFeatureImageModalVisible(false)}
          style={customStyle}
          imageType={imageType}
          closePopUp={() => setIsFeatureImageModalVisible(false)}
        />
      ) : (
        <StandardTallModalPopup
          goBack={closePopUp}
          closePopUp={closePopUp}
          pages={pagesArray}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default ImageEditorPopup;
