import React, { Component, Fragment } from 'react';
import Subheader from '../Subheader/Subheader';

class MaptheMovement extends Component {

  render() {
    return (
      <Fragment>
        <div className="projectBoard-right-component widget">
          <div className="project-board-home-wrapper container">
            <div className="favorites-title">
              <Subheader/>
            </div>
            <div className="favorites-like-sectionssss">
              <div>
                <h2 className="favorites-like-hd pt-5">Map The Movement for Community Health,
                  Wellbeing,
                  and Equity</h2>
                Powered by 100MLives Partners &amp; Members, map provided by CARES
              </div>
            </div>
            <iframe className="widget-frame" height="575" title={'widget-frame'}
                    src="https://widget.engagementnetwork.org/100mhl-mtm/"/>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MaptheMovement;
