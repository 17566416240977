import { combineReducers } from "redux";
import userReducer from "../actions/user";

export const makeRootReducer = asyncReducers => {
  const reducers = {
    user: userReducer,
    ...asyncReducers
  };
  return combineReducers(reducers);
};

export default makeRootReducer;
