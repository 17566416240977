import React, { Component } from 'react';
import '../../css/components/Card/ReportCardContainer.css';
import withCardContainer from './CardContainer';
// import LightenDarkenColor from '../../utility/utilities'
const data = [{
  'title': 'XYZ',
  tags: ['sesdg', 'swgsgsgdg', 'jhgughfjkjgku', 'ethdhtdhhtth', 'sgsagdge', 'egethg', 'egegetgetgetg']
}];

class ReportCardContainer extends Component {
  // constructor(props) {
  //     super(props)
  //     //console.log("report card props --> ",
  // JSON.stringify(props.data.boardCardUse.configuration.toolLink)) }

  handleCardClick = (e) => {
    //e.stopPropagation();
    // console.log("handle card click event")
    const { onCloseUpView, index } = this.props;
    onCloseUpView(index);
  };

  render() {
    const {isEditingEnabled, isCloseUpView, data} = this.props;
    if (isCloseUpView === true) {
      return (
        <div className={'reportCardContainer ' + (isEditingEnabled ? 'movable' : 'clickable')}>
          <div className="reportCardTitle">
            <div className="reportCardTitle_sub">
              <div className="reportCardTitleTextContainerContainer"><img
                className="reportCardTitleImage"
                src={require('../../images/daycardkit.svg')}
                alt=""/></div>
              <div className="reportCardTitleTextContainer">
                <div className="reportCardTitleTextContainersub">
                  <span className="reportCardTitleHeaderText">CHNA</span>
                  <span className="reportCardTitleText">starter kit</span>
                  <span className="reportCardText">The beginning of a Community Health Needs Assessment</span>
                </div>
              </div>
            </div>
          </div>
          <div className="reportCardDownLoadButtonContainer">
            <div className="reportCardDownLoadButton">
              <span className="downloadReportButton">Click for Report</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'reportCardContainer ' + (isEditingEnabled ? ' movable' : ' clickable')}>
          <div className="reportCardTitle" onClick={this.handleCardClick}>
            <div className="reportCardTitle_sub">
              <div className="reportCardTitleTextContainerContainer"><img
                className="reportCardTitleImage"
                src={require('../../images/daycardkit.svg')}
                alt=""/></div>
              <div className="reportCardTitleTextContainer">
                <div className="reportCardTitleTextContainersub">
                  <span className="reportCardTitleHeaderText">CHNA</span>
                  <span className="reportCardTitleText">starter kit</span>
                  <span className="reportCardText">The beginning of a Community Health Needs Assessment</span>
                </div>
              </div>
            </div>
          </div>
          <div className="reportCardDownLoadButtonContainer">
            <div className="reportCardDownLoadButton">
              {/* <span className="downloadReportButton"><a className="downloadReportButton" href={props.boardCardUse.configuration.toolLink}>Download Report</a></span> */}
              <span className="downloadReportButton"><a
                href={data.boardCardUse.configuration.toolLink}
                className="downloadReportButton">Click for Report</a></span>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withCardContainer(ReportCardContainer, data);
