import React from "react";
import { Link } from "react-router-dom";
import "../../css/components/Subscription/Subscription.css";
const logo = require("../../images/logo1.svg");

const Subscription = () => {
  const subscribePlan = () => {
    //alert("Not Implement Yet.......");
  };

  return (
    <div className="container pt-5">
    <h3 className="s-main-title">Membership Updates</h3>
      <div className="pricing card-deck flex-column flex-md-row mb-1 justify-content-center">
        <p>With the goal of helping our shared community to improve and to grow, we successfully implemented a membership plan one year ago.</p>
        <p>As of January 24, 2022, everyone who has enjoyed the free version will be invited to become a full paid member of the cooperative.</p>
      </div>
      <div className="pricing card-deck flex-column flex-md-row mb-1 justify-content-center">
        <p className="clear"><Link to="/accountSetting/accountOverview/new">
            Please click to <br/>
            <button
              onClick={subscribePlan}
              type="button"
              className="justify-content-center align-items-center btn-outlined lg "
            >
              Activate
            </button> <br/>and to review your options.
            </Link>
        </p>
      </div>
      <div className="justify-content-center">
            <img src={logo} 
                style={{ width: "100%", maxWidth: "240px" }}
                alt="BroadStreet Logo" />
      </div>
    </div>
  );
};

export default Subscription;
