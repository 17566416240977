import React, { Component } from "react";
import Switch from "react-switch";
import * as d3 from "d3";
import "../../css/components/Card/D3ThermometerChart.css";

class D3ThermometerChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.state = {
      ratio: 1,
      isToggle: false,
      data: [],
    };
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentDidUpdate() {
    const { data } = this.state;
    if (this.svgImageG && data.length) {
      this.update();
    } else {
      this.init();
    }
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { thermoData } = this.props;
    if (thermoData.data.length) {
      // d3.select(this.parentEle).select('svg').remove();
      this.createSvg();
      window.addEventListener("resize", this.onWindowResize);
      this.mounted = true;
    }
  };

  handleChange = (checked, e) => {
    const { thermoData } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isToggle: checked }, () => {
      this.createChartData(thermoData);
    });
  };
  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { thermoData } = nextProps;
    this.createChartData(thermoData);
  }

  createChartData = (thermoData) => {
    const { isToggle } = this.state;
    if (isToggle) {
      this.setState({
        data: thermoData.data.map((o) => {
          const obj = {};
          obj.title = thermoData.barTypes[1].title;
          obj.value = o[thermoData.barTypes[1].barType];
          obj.color = o.color;
          obj.label = o.label;
          return obj;
        }),
      });
    } else {
      this.setState({
        data: thermoData.data.map((o) => {
          const obj = {};
          obj.title = thermoData.barTypes[0].title;
          obj.value = o[thermoData.barTypes[0].barType];
          obj.color = o.color;
          obj.label = o.label;
          return obj;
        }),
      });
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "thermometer-chart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "g-container");

    const barG = this.svgG.selectAll("g.bar-container").data(
      (d) => [d],
      (d) => d
    );
    barG
      .enter()
      .append("g")
      .merge(barG)
      .attr("class", "bar-container")
      .attr("id", (d) => d + "bar-container");
    barG.exit().remove();
    this.svgBarG = this.svg.select("g#" + svgId + "bar-container");

    const imageG = this.svgG.selectAll("g.image-container").data(
      (d) => [d],
      (d) => d
    );
    imageG
      .enter()
      .append("g")
      .merge(imageG)
      .attr("class", "image-container")
      .attr("id", (d) => d + "image-container");
    imageG.exit().remove();
    this.svgImageG = this.svg.select("g#" + svgId + "image-container");

    this.autoAlignSVG();
    this.setImage();
    this.update();
  };

  autoAlignSVG = () => {
    const { imageHeight, imageWidth } = this.props;
    const clientHeight = 210;
    this.height = clientHeight - this.margin.top - this.margin.bottom;
    const ratio = imageWidth / imageHeight;
    this.width = ratio * this.height;
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
  };

  setImage() {
    const { image } = this.props;
    const imageSelection = this.svgImageG
      .selectAll("img")
      .data([{ src: image }]);
    const imageForeignObject = imageSelection
      .enter()
      .append("foreignObject")
      .attr("width", this.width)
      .attr("height", this.height);

    const imageEleDiv = imageForeignObject
      .append("xhtml:div")
      .attr("class", "lunch-img-div")
      .style("position", "relative");

    const newImageEle = imageEleDiv.append("img");
    newImageEle
      .merge(imageSelection)
      .attr("src", (d) => d.src)
      .attr("width", this.width)
      .attr("height", this.height);
    imageSelection.exit().remove();
  }

  update = () => {
    const { data, ratio } = this.state;
    const { isWide } = this.props;
    this.total = d3.sum(data, (d) => d.value);
    this.data = this.groupData(data, this.total);
    this.size =
      this.mainEle.clientWidth > this.mainEle.clientHeight
        ? this.mainEle.clientHeight
        : this.mainEle.clientWidth;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
    const yScale = d3
      .scaleLinear()
      .domain([0, this.total])
      .range([0, this.height - 35]);

    const progressSelection = this.svgBarG
      .selectAll("rect.black-path")
      .data(this.data, (d, i) => {
        return i;
      });
    const newProgress = progressSelection.enter().append("rect");
    newProgress
      .merge(progressSelection)
      .attr("class", "black-path")
      .attr("x", this.width / 2 - this.width / 7.5 / 2)
      .attr("width", this.width / 7.5)
      .style("transform", "translate(0,15px)")
      .transition()
      .duration(300)
      .attr("y", (d) => yScale(d.cumulative))
      .attr("fill", (d) => d.data.color)
      .attr("height", (d) => yScale(d.value));
    progressSelection.exit().remove();
  };

  groupData = (data, total) => {
    const percent = d3.scaleLinear().domain([0, total]).range([0, 100]);
    let cumulative = 0;
    return data
      .map((d) => {
        cumulative += d.value;
        return {
          value: d.value,
          cumulative: cumulative - d.value,
          data: d,
          percent: percent(d.value),
        };
      })
      .filter((d) => d.value > 0);
  };

  render() {
    const { thermoData, isWide } = this.props;
    const { isToggle, data, ratio } = this.state;
    const leftSwitchTitleStyle = {
      fontSize: "10px",
      fontFamily: "ProximaNova-Regular",
      color: "#989898",
      textAlign: "right",
      textTransform: "uppercase",
      lineHeight: "1",
    };
    const rightSwitchTitleStyle = {
      fontSize: "10px",
      fontFamily: "ProximaNova-Regular",
      color: "#989898",
      textAlign: "left",
      textTransform: "uppercase",
      lineHeight: "1",
    };
    return (
      <div
        ref={(ele) => (this.mainEle = ele)}
        className="D3ThermometerChart d-flex justify-content-center h-100 w-100 align-items-center"
      >
        <div
          className={"d-flex"}
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div
            className="d-flex justify-content-end thermo-image h-100 px-2"
            ref={(ele) => (this.parentEle = ele)}
          />
          <div className="d-flex flex-column">
            <div
              className={
                "d-flex flex-column flex-grow-1 justify-content-around text-left pl-3"
              }
              style={{ height: "calc(100% - 30px)" }}
            >
              {data.map((d) => {
                return (
                  <div
                    className={
                      "legends d-flex flex-column justify-content-start"
                    }
                    key={d.label}
                  >
                    <div>{d.label}</div>
                    <span className={"percent"} style={{ color: d.color }}>
                      {d.value.toFixed(1)}%
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              className={"d-flex align-items-center justify-content-center"}
              style={{
                height: "30px",
                marginLeft: "-10px",
                transform: `scale(${isWide ? "0.85" : 1})`,
              }}
            >
              <div style={leftSwitchTitleStyle}>
                {thermoData.barTypes.length && thermoData.barTypes[0].title}
              </div>
              <div className="px-2 d-flex align-items-center justify-content-center">
                <Switch
                  onChange={this.handleChange}
                  checked={isToggle}
                  className="react-switch"
                  id="togglePie"
                  height={18}
                  width={35}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#989898"
                  onHandleColor="#fff"
                />
              </div>
              <div style={rightSwitchTitleStyle}>
                {thermoData.barTypes.length && thermoData.barTypes[1].title}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3ThermometerChart;
