import React from "react";
import withWideCardContainer from "./WideCardContainer";
import WideAdiMapCardContainer from "./WideAdiMapCardContainer";
import WideDotMapCardContainer from "./WideDotMapCardContainer";
import WideIndicatorMapCardContainer from "./WideIndicatorMapCardContainer";
import WideServiceAreaMapCardContainer from "./WideServiceAreaMapCardContainer";
import WideSavedMapCardContainer from "./WideSavedMapCardContainer";
import TwitterCardWrapper from "../WideCard/TwitterCardWrapper";
import "../../css/components/Card/WideMapCardContainer.css";

const WideMapCardContainer = (props) => {
  const getSubComponent = (data) => {
    if (data.frontFacingComponent.componentType === "AdiMapCard") {
      return (
        <WideAdiMapCardContainer
          {...props}
          id={data.id}
          key={data.id + "wideAdiMap"}
          isMapVisible={true}
        />
      );
    } else if (data.frontFacingComponent.componentType === "DotMapCard") {
      return (
        <WideDotMapCardContainer
          {...props}
          id={data.id}
          key={data.id + "wideDotMap"}
          isMapVisible={true}
        />
      );
    } else if (data.frontFacingComponent.componentType === "IndicatorMapCard") {
      return (
        <WideIndicatorMapCardContainer
          {...props}
          id={data.id}
          key={data.id + "wideIndicatorMap"}
          isMapVisible={true}
        />
      );
    } else if (
      data.frontFacingComponent.componentType === "ServiceAreaMapCard"
    ) {
      return (
        <WideServiceAreaMapCardContainer
          {...props}
          id={data.id}
          key={data.id + "wideSAMap"}
          isMapVisible={true}
        />
      );
    } else if (data.frontFacingComponent.componentType === "MapCard") {
      return (
        <WideSavedMapCardContainer
          {...props}
          id={data.id}
          key={data.id + "wideSavedMap"}
          isMapVisible={true}
        />
      );
    }
  };

  const { wideCardData, showTwitterCardPopup } = props;
  return (
    <div className="card h-100 pt-3">
      <div className="card-body h-100 py-0 px-sm-5 px-3">
        {showTwitterCardPopup ? (
          <div
            style={{ position: "absolute", left: "-30000px", zIndex: "999999" }}
          >
            <TwitterCardWrapper {...props} fromChart={true}>
              {getSubComponent(wideCardData.boardCardUse)}
            </TwitterCardWrapper>
          </div>
        ) : (
          getSubComponent(wideCardData.boardCardUse)
        )}
      </div>
    </div>
  );
};

export default withWideCardContainer(WideMapCardContainer);
