/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UseBoardInvitationLinkInput = {|
  linkText: string,
  clientMutationId?: ?string,
|};
export type InvitationLinkConfirmMutationVariables = {|
  input: UseBoardInvitationLinkInput
|};
export type InvitationLinkConfirmMutationResponse = {|
  +useBoardInvitationLink: ?{|
    +boardNode: ?{|
      +id: string,
      +name: string,
      +sourceBoard: ?{|
        +publishedStoryCard: ?{|
          +id: string
        |}
      |},
      +publishedStoryCard: ?{|
        +id: string
      |},
    |}
  |}
|};
export type InvitationLinkConfirmMutation = {|
  variables: InvitationLinkConfirmMutationVariables,
  response: InvitationLinkConfirmMutationResponse,
|};
*/


/*
mutation InvitationLinkConfirmMutation(
  $input: UseBoardInvitationLinkInput!
) {
  useBoardInvitationLink(input: $input) {
    boardNode {
      id
      name
      sourceBoard {
        publishedStoryCard {
          id
        }
        id
      }
      publishedStoryCard {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UseBoardInvitationLinkInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardCardUseNode",
  "kind": "LinkedField",
  "name": "publishedStoryCard",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationLinkConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UseBoardInvitationLinkPayload",
        "kind": "LinkedField",
        "name": "useBoardInvitationLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "sourceBoard",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationLinkConfirmMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UseBoardInvitationLinkPayload",
        "kind": "LinkedField",
        "name": "useBoardInvitationLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardNode",
                "kind": "LinkedField",
                "name": "sourceBoard",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvitationLinkConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationLinkConfirmMutation(\n  $input: UseBoardInvitationLinkInput!\n) {\n  useBoardInvitationLink(input: $input) {\n    boardNode {\n      id\n      name\n      sourceBoard {\n        publishedStoryCard {\n          id\n        }\n        id\n      }\n      publishedStoryCard {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab9b93881197f6cfc8b125b2938baf8e';

module.exports = node;
