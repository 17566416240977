/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateVerifyEmailRequestInput = {|
  userEmail: string,
  clientMutationId?: ?string,
|};
export type SignUpPopUpStep1MutationVariables = {|
  input: CreateVerifyEmailRequestInput
|};
export type SignUpPopUpStep1MutationResponse = {|
  +createVerifyEmail: ?{|
    +verifyEmailRequestNode: ?{|
      +signupStep: number,
      +userEmail: string,
    |},
    +success: ?boolean,
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
  |}
|};
export type SignUpPopUpStep1Mutation = {|
  variables: SignUpPopUpStep1MutationVariables,
  response: SignUpPopUpStep1MutationResponse,
|};
*/


/*
mutation SignUpPopUpStep1Mutation(
  $input: CreateVerifyEmailRequestInput!
) {
  createVerifyEmail(input: $input) {
    verifyEmailRequestNode {
      signupStep
      userEmail
      id
    }
    success
    validationErrors {
      key
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateVerifyEmailRequestInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signupStep",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userEmail",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "validationErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignUpPopUpStep1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateVerifyEmailRequestPayload",
        "kind": "LinkedField",
        "name": "createVerifyEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerifyEmailRequestNode",
            "kind": "LinkedField",
            "name": "verifyEmailRequestNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignUpPopUpStep1Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateVerifyEmailRequestPayload",
        "kind": "LinkedField",
        "name": "createVerifyEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerifyEmailRequestNode",
            "kind": "LinkedField",
            "name": "verifyEmailRequestNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SignUpPopUpStep1Mutation",
    "operationKind": "mutation",
    "text": "mutation SignUpPopUpStep1Mutation(\n  $input: CreateVerifyEmailRequestInput!\n) {\n  createVerifyEmail(input: $input) {\n    verifyEmailRequestNode {\n      signupStep\n      userEmail\n      id\n    }\n    success\n    validationErrors {\n      key\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f86b9353b5bba60e10d81608a9df2fbf';

module.exports = node;
