/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExploreMapsQueryVariables = {||};
export type ExploreMapsQueryResponse = {|
  +exploreMapsBoard: ?{|
    +sections: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +boardCardUses: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cardPosition: number,
                +frontFacingComponent: {|
                  +componentType: string,
                  +name: string,
                |},
                +isFavorite: ?boolean,
                +subComponentType: ?string,
              |}
            |}>
          |},
          +id: string,
          +name: string,
          +description: ?string,
          +layout: string,
          +sectionPosition: number,
        |}
      |}>
    |},
    +id: string,
    +name: string,
    +subtitle: ?string,
    +theme: {|
      +jsonStyleProperties: string
    |},
  |}
|};
export type ExploreMapsQuery = {|
  variables: ExploreMapsQueryVariables,
  response: ExploreMapsQueryResponse,
|};
*/


/*
query ExploreMapsQuery {
  exploreMapsBoard {
    sections {
      edges {
        node {
          boardCardUses {
            edges {
              node {
                id
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                  id
                }
                isFavorite
                subComponentType
              }
            }
          }
          id
          name
          description
          layout
          sectionPosition
        }
      }
    }
    id
    name
    subtitle
    theme {
      jsonStyleProperties
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layout",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionPosition",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonStyleProperties",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreMapsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "exploreMapsBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v3/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExploreMapsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "exploreMapsBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v3/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardStylePresetNode",
            "kind": "LinkedField",
            "name": "theme",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ExploreMapsQuery",
    "operationKind": "query",
    "text": "query ExploreMapsQuery {\n  exploreMapsBoard {\n    sections {\n      edges {\n        node {\n          boardCardUses {\n            edges {\n              node {\n                id\n                cardPosition\n                frontFacingComponent {\n                  componentType\n                  name\n                  id\n                }\n                isFavorite\n                subComponentType\n              }\n            }\n          }\n          id\n          name\n          description\n          layout\n          sectionPosition\n        }\n      }\n    }\n    id\n    name\n    subtitle\n    theme {\n      jsonStyleProperties\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f4c0f7c3812abbf00d20070d0443b11';

module.exports = node;
