import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FooterComponent from "../CommonComponents/FooterComponent";
import Subheader from "../Subheader/Subheader";

const notFoundImg = require("../../images/squirrel.png");

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "404error",
    });
    console.log("issued 404 error event");
    if (history) {
      history.push("#404");
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>404 Page Not Found</title>
          <meta name="prerender-status-code" content="404" />
          <meta description="The page you are looking for could not be found." />
        </Helmet>
        <div className="projectBoard-right-component">
          <div className="project-board-home-wrapper container">
            <Subheader />
            <div>
              <div>
                <div className="project-board-home-wrapper container mt-5">
                  <img
                    src={notFoundImg}
                    alt="404 Page Not Found"
                    title="404 Page Not Found"
                  />
                  <div>Page not found.</div>
                  <div>
                    <Link to="/HOME">Return to Home</Link>
                  </div>
                </div>
              </div>
              <FooterComponent />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error404;
