import React, { Component } from 'react';
import D3StackedBarChart from '../Card/D3StackedBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3StackedBarWideChart extends Component {
  render() {
    const { id, sbData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3StackedBarWideChart d-flex w-100 h-100 position-relative'>
        {(sbData) &&
        <D3StackedBarChart id={id + 'wide'} key={id + 'hispanicPopulationSBWideChart'}
                           isWide={true}
                           sbData={sbData}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3StackedBarWideChart;
