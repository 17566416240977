import React, { useEffect } from "react";
import "../../css/components/WideCard/TwitterCardWrapper.css";
import CardBottomComponents from "../Card/CardBottomComponents";

const TwitterCardWrapper = (props) => {
  const {
    wideCardData,
    children,
    title,
    fromChart,
    downloadWideVersion,
    isArea,
  } = props;
  const areaTag =
    wideCardData &&
    wideCardData.boardCardUse &&
    wideCardData.boardCardUse.configuration &&
    wideCardData.boardCardUse.configuration.savedArea &&
    wideCardData.boardCardUse.configuration.savedArea.name;
  const id = wideCardData && wideCardData.boardCardUse.id.replace(/=/g, "");
  let twitterCardTitle = title;
  if (
    wideCardData &&
    wideCardData.boardCardUse &&
    wideCardData.boardCardUse.configuration &&
    wideCardData.boardCardUse.configuration.title
  ) {
    twitterCardTitle = wideCardData.boardCardUse.configuration.title;
  }
  if (
    twitterCardTitle &&
    wideCardData &&
    wideCardData.boardCardUse.frontFacingComponent &&
    wideCardData.boardCardUse.frontFacingComponent.componentType &&
    wideCardData.boardCardUse.frontFacingComponent.componentType.includes(
      "MapCard"
    )
  ) {
    twitterCardTitle = twitterCardTitle.replace("in Our Community", " ");
    twitterCardTitle = twitterCardTitle.replace("Our Community in", " ");
    if (
      wideCardData.boardCardUse.frontFacingComponent.componentType ===
        "ServiceAreaMapCard" &&
      !isArea
    ) {
      twitterCardTitle = "";
    }
  }

  const setTwitterCardWrapperHeight = (selector1, selector2) => {
    const elementForHeight = document.querySelector(`#data-card ${selector1}`);
    const elementForOverflow = document.querySelector(
      `#data-card ${selector2}`
    );
    if (elementForHeight && elementForOverflow) {
      elementForOverflow.style.overflow = "visible";
      const twitterCardWrapper = document.querySelector(
        ".twitter-card-wrapper"
      );
      const elementHeight =
        elementForHeight.getBoundingClientRect().height + "px";
      twitterCardWrapper.style.height = `calc(${elementHeight} + 200px)`;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTwitterCardWrapperHeight(
        `#${children?.key?.replace("==", "").replace("=", "")}`,
        ".card-scrollbar"
      );
      const downloadBtn = document.querySelector("#data-card .download-btn");
      if (downloadBtn) {
        downloadBtn.style.display = "none";
        setTwitterCardWrapperHeight(
          ".DownloadDataCard table",
          ".DownloadDataCard .row:nth-child(1)"
        );
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`twitter-card-wrapper ${
        downloadWideVersion ? "twitter-map-card-wrapper " : " "
      }card chart-container`}
      style={{ height: "675px", width: "1200px" }}
      id={`card-family-${id}`}
    >
      <div className="card-body m-0 p-0 h-100">
        <div className="d-flex h-100 wide-card-contain align-items-center">
          <div className="wide-card-title my-4 px-3 text-center">
            <span>
              {`${twitterCardTitle} ${
                !isArea
                  ? " " +
                    (areaTag
                      ? (twitterCardTitle ? " in " : "") + areaTag
                      : " in United States")
                  : ""
              }`}
            </span>
          </div>
          <div
            className={
              "data-viz" + (fromChart ? " d-flex justify-content-center" : "")
            }
          >
            <div id={"data-card"} className="h-100 w-100">
              {children}
            </div>
          </div>
          <div className="left-content bottom-component bottom-left-component my-4">
            <CardBottomComponents
              bottomData={wideCardData}
              hideBottomComponent={true}
              isTwitterCard={true}
              isMapcardsVisible={false}
              y={"-100px"}
              isWide={true}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterCardWrapper;
