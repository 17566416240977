import React, { Component } from 'react';
import D3ClickableGaugeChart from '../Card/D3ClickableGaugeChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3ClickableGaugeWideChart extends Component {
  render() {
    const { id, clickableGaugeChartData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3ClickableGaugeWideChart d-flex w-100 h-100 '>
        <D3ClickableGaugeChart
          id={id + 'clickableGaugeWideChart'}
          key={id + 'clickableGaugeWideChart'}
          clickableGaugeChartData={clickableGaugeChartData}
          min={0}
          max={100}
          isWide={true}
        /></div>
    );
    return (
      <>
        {showTwitterCardPopup ? content :
          <WideCardWrapper  {...this.props} fromChart={true} isCenter={true}>
            {content}
          </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3ClickableGaugeWideChart;
