import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";
import Switch from "react-switch";
import "../../css/components/Card/D3MultiForceLayoutChart.css";

class D3MultiForceLayout extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    this.duration = 1000;
    this.clientWidth = 217;
    this.clientHeight = 150;
    this.forceStrength = 0.1;
    this.state = {
      ratio: 1,
      isToggle: false,
      nodes: [],
    };
  }

  handleChange = (checked, e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isToggle: checked }, () => {
      this.splitNodes(this.props);
    });
  };

  componentWillReceiveProps(nextProps) {
    const { nodes } = this.props;
    if (!_.isEqual(nodes, nextProps.nodes)) {
      const { nodes } = nextProps;
      this.setState({ nodes: _.cloneDeep(nodes) }, () => {
        this.makeSwitchLabel(nextProps);
        this.update();
      });
    }
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
  };

  init = () => {
    this.centerScale = d3.scalePoint().padding(1);
    this.simulation = d3
      .forceSimulation()
      .force(
        "collide",
        d3
          .forceCollide((d) => d.r)
          .iterations(1)
          .strength(1)
      )
      .force("charge", d3.forceManyBody().strength(0.5));
    this.createSvg();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3MultiForceLayoutSVG")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "gcontainer");
    this.makeSwitchLabel(this.props);
    this.update(this.props);
  };

  makeSwitchLabel = (props) => {
    const { switchLabel1 } = props;
    d3.select(this.switchLabel1).html(switchLabel1);
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;

    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }

    this.simulation
      .force("x", d3.forceX().x(this.width / 2))
      .force("y", d3.forceY().y(this.height / 2));
    this.centerScale.range([0, this.width]);

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
  };

  update = () => {
    this.autoAlignSVG();
    this.makeData();
    this.updateNodes();
    this.splitNodes();
  };

  makeData = () => {
    const { nodes } = this.state;
    nodes.forEach((d) => {
      d.x = this.width / 2;
      d.y = this.height / 2;
    });
  };

  updateNodes = () => {
    const { nodes } = this.state;

    let circles = this.svgG.selectAll("circle").data(nodes, (d) => d.id);
    const newCircles = circles.enter().append("circle");
    newCircles
      .merge(circles)
      .attr("r", (d) => d.r)
      .style("fill", (d) => (d.fillColor ? d.fillColor : "rgba(0, 0, 0, 0.5)"))
      .style("stroke", (d) => (d.strokeColor ? d.strokeColor : "#000000"))
      .style("stroke-width", "1px");
    circles.exit().remove();

    function ticked() {
      newCircles
        .merge(circles)
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y);
    }

    this.simulation.nodes(nodes).on("tick", ticked);
  };

  splitNodes = () => {
    const { nodes } = this.state;
    const { isToggle } = this.state;
    this.centerScale.domain(nodes.map((d) => (!isToggle ? "All" : d.group)));

    this.updateGroupTitle(this.centerScale);

    // @v4 Reset the 'x' force to draw the bubbles to their year centers
    this.simulation.force(
      "x",
      d3
        .forceX()
        .strength(this.forceStrength)
        .x((d) =>
          !isToggle ? this.centerScale("All") : this.centerScale(d.group)
        )
    );

    // @v4 We can reset the alpha value and restart the simulation
    this.simulation.alpha(2).restart();
  };

  updateGroupTitle = (scale) => {
    // Another way to do this would be to create
    // the year texts once and then just hide them.
    /*const {isWide} = this.props;*/
    const titles = this.svgG.selectAll(".title").data(scale.domain());
    const newTitles = titles.enter().append("text");
    newTitles
      .merge(titles)
      .attr("class", "title")
      .attr("x", (d) => scale(d))
      .attr("y", 15)
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Bold")
      .style("font-size", "12px")
      .style("fill", "#424B54")
      .style("opacity", 0)
      .transition()
      .duration(this.duration)
      .style("opacity", 1)
      .text((d) => d);
    titles.exit().remove();
  };

  render() {
    const { isToggle, ratio } = this.state;
    const { title, switchLabel2, isWide } = this.props;
    const leftSwitchTitleStyle = {
      fontSize: "10px",
      fontFamily: "ProximaNova-Regular",
      color: "#989898",
      textAlign: "right",
      lineHeight: "1",
    };
    const rightSwitchTitleStyle = {
      fontSize: "10px",
      fontFamily: "ProximaNova-Regular",
      color: "#989898",
      textAlign: "left",
      lineHeight: "1",
    };
    return (
      <div
        className="D3MultiForceLayout d-flex flex-column  align-items-center justify-content-center h-100 w-100"
        ref={(ele) => {
          this.mainParentEle = ele;
        }}
      >
        <div
          className={"d-flex flex-column"}
          style={{
            height: "217px",
            width: "217px",
            transform: `scale(${ratio})`,
          }}
        >
          <div
            className="w-100"
            style={{ height: "150px" }}
            ref={(ele) => (this.parentEle = ele)}
          />
          <div className="w-100 chart-title text-center flex-grow-1">
            {title}
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height: "28px",
              lineHeight: "0.8",
              transform: `scale(${isWide ? "0.85" : 1})`,
            }}
          >
            <div
              style={leftSwitchTitleStyle}
              ref={(ele) => (this.switchLabel1 = ele)}
            />
            <div className="px-2">
              <Switch
                onChange={this.handleChange}
                checked={isToggle}
                className="react-switch"
                id="togglePie"
                height={18}
                width={35}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#989898"
                onHandleColor="#fff"
              />
            </div>
            <div style={rightSwitchTitleStyle}>{switchLabel2}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3MultiForceLayout;
