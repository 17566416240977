import React, { useState, useEffect, useRef } from "react";
import "../../css/custom.css";
import "../../css/components/AccountSettings/invoice.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { fetchQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const logo = require("../../images/logo1.svg");
const InvoicePage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [invoiceId, setInvoiceId] = useState("");
  const invoiceRef = useRef();
  const [base64logo, setBase64logo] = useState("");
  const refreshInvoice = (id) => {
    const _query = graphql`
      query InvoicePagePopupQuery($input: ID!) {
        billingDocument(id: $input) {
          id
          documentType
          summary
          documentDate
          documentUrl
          invoiceNumber
          invoiceCreatedDate
          invoiceDueDate
          invoiceBillingLine1
          invoiceBillingLine2
          invoiceBillingLine3
          invoicePaymentMethod
          invoicePaymentNumber
          invoiceItem1
          invoiceItem2
          invoicePrice1
          invoicePrice2
          invoiceTotal
        }
      }
    `;
    const variables = {
      input: id,
    };
    fetchQuery(environment, _query, variables)
      .then((resp) => {
        if (resp.billingDocument) {
          setInvoice(resp.billingDocument);
        } else {
          setInvoice(null);
        }
      })
      .catch((err) => {
        setInvoice(null);
      });
  };

  function toDataURL(callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", logo);
    xhr.responseType = "blob";
    xhr.send();
  }

  useEffect(() => {
    toDataURL((dataUrl) => {
      setBase64logo(dataUrl);
    });
  }, []);
  const currency = (amount) => {
    return (amount / 100).toFixed(2);
  };
  const printInvoice = () => {
    setLoading(true);
    // var divToPrint = invoiceRef.current;
    //var win = window.open('', '');
    var win = window;

    //win.document.body.innerHTML = divToPrint.outerHTML;
    var css = `
      .invoice-box {
          max-width: 800px;
          margin: auto;
          padding: 30px;
          border: 1px solid #eee;
          box-shadow: 0 0 10px rgba(0, 0, 0, .15);
          font-size: 16px;
          line-height: 24px;
          font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
          color: #555;
      }

      .invoice-box table {
          width: 100%;
          line-height: inherit;
          text-align: left;
      }

      .invoice-box table td {
          padding: 5px;
          vertical-align: top;
      }

      .invoice-box table tr td:nth-child(2) {
          text-align: right;
      }

      .invoice-box table tr.top table td {
          padding-bottom: 20px;
      }

      .invoice-box table tr.top table td.title {
          font-size: 45px;
          line-height: 45px;
          color: #333;
      }

      .invoice-box table tr.information table td {
          padding-bottom: 40px;
      }

      .invoice-box table tr.heading td {
          background: #eee;
          /* background: #d4d4d4; */
          border-bottom: 1px solid #ddd;
          font-weight: bold;
      }

      .invoice-box table tr.details td {
          padding-bottom: 20px;
      }

      .invoice-box table tr.item td{
          border-bottom: 1px solid #eee;
      }

      .invoice-box table tr.item.last td {
          border-bottom: none;
      }

      .invoice-box table tr.total td:nth-child(2) {
          border-top: 2px solid #eee;
          font-weight: bold;
      }

      @media only screen and (max-width: 600px) {
          .invoice-box table tr.top table td {
              width: 100%;
              display: block;
              text-align: center;
          }

          .invoice-box table tr.information table td {
              width: 100%;
              display: block;
              text-align: center;
          }
      }

      /** RTL **/
      .rtl {
          direction: rtl;
          font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
      }

      .rtl table {
          text-align: right;
      }

      .rtl table tr td:nth-child(2) {
          text-align: left;
      }
    `,
      head = win.document.head || win.document.getElementsByTagName("head")[0],
      style = win.document.createElement("style");

    head.appendChild(style);

    style.type = "text/css";
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(win.document.createTextNode(css));
    }

    if (navigator.userAgent.match(/Trident\/\d.\d/)) {
      // IE needs to call this without a setTimeout
      win.print();
      setLoading(false);
      win.close();
    } else {
      setTimeout(() => {
        win.print();
        setLoading(false);
        win.close();
      }, 0);
    }
  };
  useEffect(() => {
    if (match && match.params.component === "invoice") {
      setInvoiceId(match.params.filterTag);
    }
  }, [match]);
  useEffect(() => {
    refreshInvoice(invoiceId);
  }, [invoiceId]);
  return (
    <>
      <div className="py-4 h-100">
        <div className="px-4 py-3 d-flex justify-content-between">
          <h3 className="m-0 border-0" aria-hidden="true" />
          <button
            className="button button-subtle"
            onClick={() => printInvoice()}
            disabled={loading}
          >
            {loading ? "Printing" : "Print"}
          </button>
        </div>
        {invoice && (
          <div
            className="invoice-box"
            ref={invoiceRef}
            style={{ maxHeight: "calc(100% - 110px)", overflow: "auto" }}
          >
            <div className="border rounded p-3">
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr className="top">
                    <td colSpan="2">
                      <table>
                        <tbody>
                          <tr>
                            <td className="title">
                              <img
                                src={base64logo}
                                style={{ width: "100%", maxWidth: "300px" }}
                                alt=""
                              />
                            </td>
                            <td>
                              Invoice #: {invoice.invoiceNumber}
                              <br />
                              Created: {invoice.invoiceCreatedDate}
                              <br />
                              Due: {invoice.invoiceDueDate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr className="information">
                    <td colSpan="2">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              BroadStreet Health, LLC
                              <br />
                              10437 W Innovation Drive STE B34
                              <br />
                              Milwaukee, WI 53226
                            </td>
                            <td>
                              {invoice.invoiceBillingLine1}
                              <br />
                              {invoice.invoiceBillingLine2}
                              <br />
                              {invoice.invoiceBillingLine3}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="heading">
                    <td>Payment Method</td>
                    <td>Card #</td>
                  </tr>

                  <tr className="details">
                    <td> {invoice.invoicePaymentMethod} </td>
                    <td> {invoice.invoicePaymentNumber} </td>
                  </tr>

                  <tr className="heading">
                    <td>Item</td>
                    <td>Price</td>
                  </tr>

                  <tr className="item">
                    <td>{invoice.invoiceItem1}</td>
                    <td>${currency(invoice.invoicePrice1)}</td>
                  </tr>

                  {invoice.invoiceItem2 && (
                    <tr className="item">
                      <td>{invoice.invoiceItem2}</td>
                      <td>${currency(invoice.invoicePrice2)}</td>
                    </tr>
                  )}

                  <tr className="total">
                    <td></td>
                    <td>Total: ${currency(invoice.invoiceTotal)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-around mt-4">
          <div />
          <div className="d-flex"></div>
        </div>
      </div>
    </>
  );
};

export default InvoicePage;
