/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomHomePagePublicQueryVariables = {|
  input: string
|};
export type CustomHomePagePublicQueryResponse = {|
  +currentUser: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string
      |}
    |}>
  |},
  +page: ?{|
    +id: string,
    +name: string,
    +subtitle: ?string,
    +boardType: ?string,
    +buttonColor: ?string,
    +customDomain: ?string,
    +faviconImage: ?{|
      +url: ?string
    |},
    +headerColor: ?string,
    +headerImage: ?{|
      +url: ?string
    |},
    +sections: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +image1: ?{|
            +url: ?string
          |},
          +image2: ?{|
            +url: ?string
          |},
          +image3: ?{|
            +url: ?string
          |},
          +id: string,
          +layout: string,
          +sectionLayout: string,
          +sectionPosition: number,
          +name: string,
          +description: ?string,
          +boardCardUses: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cardPosition: number,
                +frontFacingComponent: {|
                  +componentType: string,
                  +name: string,
                |},
                +isFavorite: ?boolean,
                +subComponentType: ?string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
|};
export type CustomHomePagePublicQuery = {|
  variables: CustomHomePagePublicQueryVariables,
  response: CustomHomePagePublicQueryResponse,
|};
*/


/*
query CustomHomePagePublicQuery(
  $input: String!
) {
  currentUser {
    edges {
      node {
        id
      }
    }
  }
  page(permaLink: $input) {
    id
    name
    subtitle
    boardType
    buttonColor
    customDomain
    faviconImage {
      url
      id
    }
    headerColor
    headerImage {
      url
      id
    }
    sections {
      edges {
        node {
          image1 {
            url
            id
          }
          image2 {
            url
            id
          }
          image3 {
            url
            id
          }
          id
          layout
          sectionLayout
          sectionPosition
          name
          description
          boardCardUses {
            edges {
              node {
                id
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                  id
                }
                isFavorite
                subComponentType
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "String!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccountNodeConnection",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "permaLink",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boardType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonColor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customDomain",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headerColor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layout",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionLayout",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionPosition",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardPosition",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "componentType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
},
v20 = [
  (v9/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomHomePagePublicQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardImageNode",
            "kind": "LinkedField",
            "name": "faviconImage",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardImageNode",
            "kind": "LinkedField",
            "name": "headerImage",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image1",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image2",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image3",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v4/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v16/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v18/*: any*/),
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomHomePagePublicQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BoardNode",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardImageNode",
            "kind": "LinkedField",
            "name": "faviconImage",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardImageNode",
            "kind": "LinkedField",
            "name": "headerImage",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardSectionNodeConnection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardSectionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardSectionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image1",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image2",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardImageNode",
                        "kind": "LinkedField",
                        "name": "image3",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v4/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardUseNodeConnection",
                        "kind": "LinkedField",
                        "name": "boardCardUses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BoardCardUseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BoardCardUseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v16/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FrontFacingComponentNode",
                                    "kind": "LinkedField",
                                    "name": "frontFacingComponent",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      (v4/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v18/*: any*/),
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CustomHomePagePublicQuery",
    "operationKind": "query",
    "text": "query CustomHomePagePublicQuery(\n  $input: String!\n) {\n  currentUser {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  page(permaLink: $input) {\n    id\n    name\n    subtitle\n    boardType\n    buttonColor\n    customDomain\n    faviconImage {\n      url\n      id\n    }\n    headerColor\n    headerImage {\n      url\n      id\n    }\n    sections {\n      edges {\n        node {\n          image1 {\n            url\n            id\n          }\n          image2 {\n            url\n            id\n          }\n          image3 {\n            url\n            id\n          }\n          id\n          layout\n          sectionLayout\n          sectionPosition\n          name\n          description\n          boardCardUses {\n            edges {\n              node {\n                id\n                cardPosition\n                frontFacingComponent {\n                  componentType\n                  name\n                  id\n                }\n                isFavorite\n                subComponentType\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7708a857ba78bffd210c504259c2420d';

module.exports = node;
