import React, { Component } from "react";
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as _ from "lodash";
import * as seedrandom from "seedrandom";
import "../../css/components/Card/D3WordCloudChart.css";

class D3WordCloudChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 5, right: 20, bottom: 5, left: 20 };
    this.clientWidth = 245;
    this.clientHeight = 230;
    Math.random = seedrandom("cloud");
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.autoAlignSVG(this.props);
  };

  init = () => {
    this.createSvg();
  };

  componentWillReceiveProps(nextProps) {
    const { wordCloudChartData, data } = this.props;
    if (
      !_.isEqual(nextProps.wordCloudChartData, wordCloudChartData) ||
      !_.isEqual(nextProps.data, data)
    ) {
      //const word_entries = this.setData(nextProps.wordCloudChartData.data)
      this.loadChartPng(nextProps.wordCloudChartData);
      //this.updateChart(word_entries);
    }
    this.image
      .attr(
        "src",
        "https://www.broadstreet.io" + nextProps.wordCloudChartData.chartImage
      )
      .on("mouseover", this.mouseover)
      .on("mousemove", this.mousemove)
      .on("mouseout", this.mouseout);
  }

  loadChartPng = (data) => {
    //console.log(data.chartImage);
  };

  setData = (wordCloudChart) => {
    let data = wordCloudChart;
    const word_count = {};
    if (data) {
      data = _.orderBy(data, ["weight"], ["asc"]);
      data.forEach(function (word, i) {
        const text = word.label;
        word.weight = i + 3;
        word_count[text] = word.weight;
      });
    }
    const word_entries = d3.entries(word_count);
    return word_entries;
  };

  createSvg = () => {
    const { image, wordCloudChartData, id } = this.props;
    const svgId = id.replace(/=/g, "");
    const word_entries = this.setData(wordCloudChartData.data);
    const xScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(word_entries, function (d) {
          return d.value;
        }),
      ])
      .range([1, 25]);

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3WordCloudChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const minRange = Math.max(-90, Math.min(90, -90));
    const maxRange = Math.max(-90, Math.min(90, 90));
    const hourScale = d3
      .scaleLinear()
      .domain([0, 2])
      .range([minRange, maxRange]);

    this.autoAlignSVG(this.props);

    this.div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 1e-6);

    const layout = cloud()
      .size([this.width, this.height])
      .words(word_entries)
      .spiral("archimedean")
      .padding(3)
      .font("Arial")
      .fontSize(function (d) {
        return xScale(+d.value);
      })
      .text(function (d) {
        return d.key;
      })
      .rotate(function () {
        return hourScale(~~(Math.random() * 3));
      })
      .on("end", () => {
        const imgElem = this.svg
          .append("g")
          .attr("class", "image-container")
          .attr(
            "transform",
            "translate(" + this.margin.left + "," + this.margin.top + ")"
          );

        let imageSelection = imgElem
          .selectAll("img")
          .data([{ src: image }], (d) => d.src);
        const imageForeignObject = imageSelection
          .enter()
          .append("foreignObject")
          .attr("width", this.width)
          .attr("height", this.height);

        const imageEleDiv = imageForeignObject
          .append("xhtml:div")
          .attr("class", "D3WordCloudImgDiv");

        const newImageEle = imageEleDiv.append("img");
        newImageEle
          .merge(imgElem)
          .attr("src", (d) => d.src)
          .attr("width", this.width)
          .attr("height", this.height);

        imageSelection = imageEleDiv
          .selectAll("img.D3WordCloudStaticImage")
          .data(
            ["https://www.broadstreet.io" + wordCloudChartData.chartImage],
            (d) => d
          );
        imageSelection
          .enter()
          .append("img")
          .merge(imageSelection)
          .attr("class", "D3WordCloudStaticImage")
          .attr("id", "D3WordCloudStaticImage");
        imageSelection.exit().remove();
        this.image = this.svg.select("img#D3WordCloudStaticImage");
        this.image
          .attr(
            "src",
            "https://www.broadstreet.io" + wordCloudChartData.chartImage
          )
          .attr("width", this.width)
          .attr("height", this.height);
      });
    layout.start();
  };

  mouseover = (e) => {
    this.div.style("display", "inline-block").style("opacity", "1");
  };

  mousemove = (e) => {
    const {
      wordCloudChartData: { data },
    } = this.props;
    //const format = mainText && (mainText.includes(".") && mainText.indexOf(".") < 2) ?
    // d3.format(",.1~r") : d3.format(",.0f");
    const formatInt = d3.format(".0f");
    const formatFloat = d3.format(".1f");
    const format = (n) => {
      if (n <= 0.95) {
        return formatFloat(n);
      } else {
        return formatInt(n);
      }
    };
    let html = "";
    if (data.length) {
      data.forEach((item) => {
        html += `<span>${format(item.weight)}% ${
          item.label.toLowerCase() === "english"
            ? item.label + " only"
            : item.label
        }</span><br>`;
      });
    }

    this.div
      .html(html)
      .style("left", e.pageX - 34 + "px")
      .style("top", e.pageY - 5 + "px")
      .style("transform", "translate(-50%,-100%)")
      .style("display", "inline-block")
      .style("opacity", "1")
      .style("min-width", "120px")
      .style("background-color", "#FFFFFF")
      .style("padding", "5px")
      .style("color", "#424B54")
      .style("font-size", "10px")
      .style("font-family", "ProximaNova-Regular")
      .style("border", "1px solid #424B54");
  };

  mouseout = () => {
    this.div.style("display", "none").style("opacity", 1e-6);
  };

  autoAlignSVG = (props) => {
    const { isWide } = props;
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height
        }`
      );
  };

  render() {
    return (
      <div className="D3WordCloud d-flex flex-column h-100 w-100">
        <div
          className="w-100 h-100 d-flex justify-content-center align-items-center"
          ref={(ele) => (this.parentEle = ele)}
        ></div>
      </div>
    );
  }
}

export default D3WordCloudChart;
