import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { ToolTip } from "common-components";
import SaveCardsPopUp from "../CommonComponents/SaveCardsPopUp";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import HomeFeedSection from "./HomeFeedSection";
import Subheader from "../Subheader/Subheader";
import SubNav from "../SubNav/SubNav";
import FooterComponent from "../CommonComponents/FooterComponent";
import RunAReport from "../RunAReport/RunAReport";
import ExploreMaps from "../ExploreMaps/ExploreMaps";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";
import FavoriteTools from "../FavoriteTools/FavoriteTools";

class ChannelLink extends Component {
  render() {
    const { iconClass, title } = this.props;
    return (
      <div className="filter-tag">
        <span className={iconClass} />
        <a href={"#title"}>{title}</a>
      </div>
    );
  }
}

class HomeFeed extends Component {
  static defaultProps = {
    isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      sectionCursorArray: [""],
      isStoryVisible: props.openedStory != null,
      storyId:
        props.openedStory != null ? props.openedStory.cardForStory.id : null,
      updateFavorites: false,
      updatePage: false,
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    onRef(undefined);
  }

  handleNextPage = (cursor, pageNumber) => {
    const { sectionCursorArray } = this.state;
    var mutableArray = sectionCursorArray.slice(0);
    if (mutableArray.length <= pageNumber) {
      mutableArray.push(cursor);
    } else {
      mutableArray[pageNumber] = cursor;
    }
    // TODO: use prevState when updating state based on state variable
    this.setState({ sectionCursorArray: mutableArray });
  };

  buildAllSectionLayouts = (cardEdges) => {
    var sectionLayouts = [];
    for (var c = 1; c <= 4; c++) {
      sectionLayouts.push(this.buildNewSectionLayout(cardEdges, c));
    }
    return sectionLayouts;
  };

  buildNewSectionLayout = (cardEdges, columns) => {
    var layout = cardEdges.map((edge, index) => {
      return {
        x: index % columns,
        y: Math.floor(index / columns),
        w: 1,
        h:
          edge.node.frontFacingComponent.componentType === "ReportCard"
            ? 10
            : 12,
        i: edge.node.id,
      };
    });
    return layout;
  };

  hideStory = () => {
    const { openedStory, history } = this.props;
    if (openedStory != null) {
      history.push("/");
    } else {
      this.setState({
        isStoryVisible: false,
        storyId: null,
      });
    }
  };

  handleUpdateFavorites = () => {
    this.setState((prevState) => {
      return {
        updateFavorites: !prevState.updateFavorites,
      };
    });
  };

  updatePage = () => {
    this.setState((prevState) => {
      return {
        updatePage: !prevState.updatePage,
      };
    });
  };

  render() {
    const { isStoryVisible, updateFavorites, sectionCursorArray, updatePage } =
      this.state;

    const {
      isLeftMenuOpen,
      filterTag,
      handleBrowseAllStories,
      handleExploreMapsAndData,
      userData,
      openStoryPopup,
      openCardFamilyPopupWithUrl,
      openStoryPopupWithUrl,
      handleLoginPopUp,
      history,
      permaTag,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>BroadStreet{filterTag ? " - " + filterTag : " Home"}</title>
          <meta
            name="description"
            content={
              filterTag
                ? "Stories tagged with " + filterTag
                : "Data, tools, and stories to improve communities and inspire change."
            }
          />
        </Helmet>
        <div
          className={
            isStoryVisible === true
              ? "projectBoard-right-component withStory"
              : "projectBoard-right-component"
          }
        >
          {/*<div className="projectBoard-right-component" >*/}
          <div className="project-board-home-wrapper container">
            {filterTag ? (
              <div>
                <Subheader />
                <div>
                  {/*<img src="../../imagesLeftnav/leftNavEduc.svg" alt="" style={this.divStyle}/>*/}

                  {permaTag &&
                  [
                    "Economy",
                    "Education",
                    "Environment",
                    "Food",
                    "Health",
                    "Housing",
                    "Transportation",
                    "commons stories",
                  ].includes(filterTag) ? (
                    <div>
                      {filterTag === "Economy" ? (
                        <ChannelLink
                          title="Economy"
                          iconClass="channelEcon-icon"
                          tagName="Economy"
                        />
                      ) : null}
                      {filterTag === "Education" ? (
                        <ChannelLink
                          title="Education"
                          iconClass="channelEduc-icon"
                          tagName="Education"
                        />
                      ) : null}
                      {filterTag === "Environment" ? (
                        <ChannelLink
                          title="Environment"
                          iconClass="channelLeaf-icon"
                          tagName="Environment"
                        />
                      ) : null}
                      {filterTag === "Food" ? (
                        <ChannelLink
                          title="Food"
                          iconClass="channelFood-icon"
                          tagName="Food"
                        />
                      ) : null}
                      {filterTag === "Health" ? (
                        <ChannelLink
                          title="Health"
                          iconClass="channelHealth-icon"
                          tagName="Health"
                        />
                      ) : null}
                      {filterTag === "Housing" ? (
                        <ChannelLink
                          title="Housing"
                          iconClass="channelHouse-icon"
                          tagName="Housing"
                        />
                      ) : null}
                      {filterTag === "Transportation" ? (
                        <ChannelLink
                          title="Transportation"
                          iconClass="channelBike-icon"
                          tagName="Transportation"
                        />
                      ) : null}
                      {filterTag === "commons stories" ? (
                        <ChannelLink
                          title="Commons Stories"
                          iconClass="left-icon-11"
                          tagName="Commons Stories"
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div className="filter-tag">
                      <span> # </span>
                      <a href={"#tag"}>{filterTag}</a>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="projectboard-home-gallery-wrapper d-flex justify-content-center">
                  <div className="d-flex flex-column flex-lg-row w-100 col-md-8 col-lg-7 justify-content-center">
                    <div className="projectboard-home-gallery-detail d-flex flex-column justify-content-center">
                      <h2 className="text-center">
                        {userData.currentUser.edges.length > 0
                          ? ` Hi ${userData.currentUser.edges[0].node.userFirstName}!`
                          : ""}
                      </h2>
                      <p className="text-center">
                        What do you want to do today?
                      </p>
                    </div>
                    <div className="projectboard-home-gallery-profile w-100">
                      <SubNav />
                    </div>
                  </div>
                </div>
                <div className="project-board-card-home">
                  {userData.currentUser.edges.length > 0 && (
                    <div className="project-board-home-top-wrap">
                      <div className="w-100">
                        <h3 className="text-left py-3 mt-4 mb-4 pl-4">
                          Favorite Tools and Reports
                        </h3>
                        <FavoriteTools
                          isLeftMenuOpen={isLeftMenuOpen}
                          handleBrowseAllStories={handleBrowseAllStories}
                          handleExploreMapsAndData={handleExploreMapsAndData}
                          userData={userData}
                          openStoryPopup={openStoryPopup}
                          openCardFamilyPopupWithUrl={
                            openCardFamilyPopupWithUrl
                          }
                          openStoryPopupWithUrl={openStoryPopupWithUrl}
                          handleLoginPopUp={handleLoginPopUp}
                          updateFavorites={updateFavorites}
                          updatePage={this.updatePage}
                          history={history}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {filterTag &&
              sectionCursorArray.map((cursor, index) => {
                return (
                  <QueryRenderer
                    key={index}
                    environment={environment}
                    query={graphql`
                      query HomeFeedQuery(
                        $pageSize: Int
                        $filterTag: String
                        $permaTag: Int
                        $cursor: String
                      ) {
                        publicBoard {
                          sections {
                            edges {
                              node {
                                boardCardUses(
                                  first: $pageSize
                                  tag: $filterTag
                                  permaTag: $permaTag
                                  after: $cursor
                                ) {
                                  pageInfo {
                                    startCursor
                                    endCursor
                                    hasNextPage
                                  }
                                  edges {
                                    cursor
                                    node {
                                      id
                                      cardPosition
                                      frontFacingComponent {
                                        componentType
                                        name
                                      }
                                      isFavorite
                                      subComponentType
                                    }
                                  }
                                }
                                id
                                name
                                description
                                sectionPosition
                              }
                            }
                          }
                          id
                          name
                          subtitle
                          theme {
                            jsonStyleProperties
                          }
                        }
                      }
                    `}
                    variables={{
                      pageSize: 8,
                      filterTag: filterTag,
                      permaTag: permaTag,
                      cursor: cursor,
                    }}
                    render={({ error, props }) => {
                      if (error) {
                        console.error(error);
                        return <div>Error!</div>;
                      }
                      if (!props) {
                        return <div>Loading...</div>;
                      }
                      const layoutArray = this.buildAllSectionLayouts(
                        props.publicBoard.sections.edges[0].node.boardCardUses
                          .edges
                      );
                      return (
                        <div>
                          <HomeFeedInner
                            {...this.props}
                            data={props}
                            index={index}
                            layoutArray={layoutArray}
                            handleNextPage={this.handleNextPage}
                            sectionCursorArray={sectionCursorArray}
                            openStoryPopup={openStoryPopup}
                            openCardFamilyPopupWithUrl={
                              openCardFamilyPopupWithUrl
                            }
                            openStoryPopupWithUrl={openStoryPopupWithUrl}
                            userData={userData}
                            history={history}
                            handleLoginPopUp={handleLoginPopUp}
                          />
                        </div>
                      );
                    }}
                  />
                );
              })}
            {!filterTag && (
              <div className="w-100" id="report">
                <h3 className="text-left py-3 mt-4 mb-4 pl-4">
                  Create a New Story
                </h3>
                <RunAReport
                  isLeftMenuOpen={isLeftMenuOpen}
                  handleBrowseAllStories={handleBrowseAllStories}
                  handleExploreMapsAndData={handleExploreMapsAndData}
                  userData={userData}
                  openStoryPopup={openStoryPopup}
                  openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                  openStoryPopupWithUrl={openStoryPopupWithUrl}
                  handleLoginPopUp={handleLoginPopUp}
                  handleUpdate={this.handleUpdateFavorites}
                  updatePage={updatePage}
                  history={history}
                />
              </div>
            )}

            {!filterTag && (
              <div className="w-100">
                <h3 className="text-left py-3 mt-4 mb-4 pl-4">
                  Data and Mapping Tools
                </h3>
                <ExploreMaps
                  isLeftMenuOpen={isLeftMenuOpen}
                  handleBrowseAllStories={handleBrowseAllStories}
                  handleExploreMapsAndData={handleExploreMapsAndData}
                  userData={userData}
                  openStoryPopup={openStoryPopup}
                  openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
                  openStoryPopupWithUrl={openStoryPopupWithUrl}
                  handleLoginPopUp={handleLoginPopUp}
                  handleUpdate={this.handleUpdateFavorites}
                  updatePage={updatePage}
                  history={history}
                />
              </div>
            )}
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

export default HomeFeed;

class HomeFeedInner extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
    // isStoryVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      layout: [],

      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "",

      isSaveCardsPopUpVisible: false,
      selectedCardForMutation: "",

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      isSaveCardsPopUpVisibleForHome: false,
      xOriginSaveCardsPopUpForHome: 0,
      yOriginSaveCardsPopUpForHome: 0,

      // isStoryVisible: props.openedStory!=null,
      // storyId: props.openedStory!=null?props.openedStory.cardForStory.id:null,
      sectionCursorArray: [""],

      saveCardFromMore: false,

      addRemoveFavourite: false,

      data: [],
      isSavedTooltip: false,
      savedCardId: "",
      selectedCard: null,
      showCardButtonModal: false,
    };
  }

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    var cardsArray = [];
    const { data } = this.props;
    data.publicBoard.sections.edges[0].node.boardCardUses.edges.map((item) => {
      var mutableObject = Object.assign({}, item);
      //     mutableObject.isUpdate = false
      cardsArray.push(mutableObject);
      return cardsArray;
    });
    this.setState({
      data: cardsArray,
    });
  }

  handleReRenderCard = (cardId) => {
    const { data, selectedCardForMutation } = this.state;
    var arrayData = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === selectedCardForMutation) {
        var changedObject = data[i];
        changedObject.isUpdate === true
          ? (changedObject.isUpdate = false)
          : (changedObject.isUpdate = true);
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState(
      {
        data: arrayData,
      },
      this.hidePopUp()
    );
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  hideToolTip = () => {
    this.setState({
      isToolTipVisible: false,
      isSavedTooltip: false,
      savedCardId: "",
    });
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId, popUpType) => {
    const { isSaveCardsPopUpVisibleForHome, isSaveCardsPopUpVisible } =
      this.state;
    if (popUpType === "BeforeYouCanDoThatPopUp") {
      if (isSaveCardsPopUpVisibleForHome === false) {
        this.setState({
          xOriginSaveCardsPopUpForHome: x,
          yOriginSaveCardsPopUpForHome: y,
          isSaveCardsPopUpVisibleForHome: true,
          selectedCardForMutation: cardId,
          selectedCard: this.getSelectedCard(cardId),
        });
      }
    } else {
      if (isSaveCardsPopUpVisible === false) {
        this.setState({
          isSaveCardsPopUpVisible: true,
          selectedCardForMutation: cardId,
          selectedCard: this.getSelectedCard(cardId),
        });
      }
    }
  };
  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isSaveCardsPopUpVisibleForHome,
      isReportErrorPopUpVisible,
    } = this.state;

    if (isPopUpVisible) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isSaveCardsPopUpVisibleForHome && closed) {
      this.setState({
        isSaveCardsPopUpVisibleForHome: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
  };

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { data } = this.props;
    let selectedCard =
      data.publicBoard.sections.edges[0].node.boardCardUses.edges.find(
        (item) => {
          return item.node.id === cardId;
        }
      );
    if (popUpType === "CloseTag") {
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "MoreInfo") {
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
        selectedCard,
      });
    }
    //  else if (popUpType === "EditTags"){
    //      this.setState({isEditHashTagPopUpVisible : true, xOriginEditHashTagPopUp : x ,
    // yOriginEditHashTagPopUp : y, selectedCardForMutation:cardId}) }
  };

  // show  ShareCardPopUp
  handleShareCardPopUp = (x, y) => {
    const { isShareCardPopUpVisible } = this.state;
    if (isShareCardPopUpVisible === false) {
      this.setState({
        isShareCardPopUpVisible: true,
        xOriginShareCardPopUp: x,
        yOriginShareCardPopUp: y,
      });
    }
  };

  //ReportErrorPopup
  handleReportErrorPopUp = (x, y) => {
    const { isReportErrorPopUpVisible } = this.state;
    if (isReportErrorPopUpVisible === false) {
      this.setState({
        xOriginReportErrorPopUp: x,
        yOriginReportErrorPopUp: y,
        isReportErrorPopUpVisible: true,
      });
    }
  };

  getSelectedCard = (cardId) => {
    const { data } = this.props;
    let selectedCard = null;
    if (
      data &&
      data.publicBoard &&
      data.publicBoard.sections &&
      data.publicBoard.sections.edges
    ) {
      data.publicBoard.sections.edges.forEach((section) => {
        if (
          section &&
          section.node &&
          section.node.boardCardUses &&
          section.node.boardCardUses.edges
        ) {
          selectedCard = section.node.boardCardUses.edges.find(
            (item) => item.node.id === cardId
          );
        }
      });
    }
    return selectedCard;
  };

  onCardButtonClick = (cardId, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard: this.getSelectedCard(cardId),
    });
  };

  onCloseCardButtonModal = () => {
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
  };

  render() {
    const {
      showCardButtonModal,
      isSaveCardsPopUpVisible,
      selectedCardForMutation,
      saveCardFromMore,
      isSaveCardsPopUpVisibleForHome,
      xOriginSaveCardsPopUpForHome,
      yOriginSaveCardsPopUpForHome,
      arrayOfTagsOfCardObject,
      isEditHashTagPopUpVisible,
      data,
      newTagsForCardObject,
      isSavedTooltip,
      savedCardId,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
      selectedCard,
    } = this.state;
    const {
      history,
      index,
      sectionCursorArray,
      layoutArray,
      handleNextPage,
      handleStoryVisiblity,
      openCardFamilyPopupWithUrl,
      userData,
      handleLoginPopUp,
    } = this.props;
    return (
      <Fragment>
        <div className="project-board-home-wrapper" onClick={this.hidePopUp}>
          {
            <HomeFeedSection
              key={index}
              {...this.props}
              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
              isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
              isShowMoreVisible={sectionCursorArray.length < index + 2}
              layoutArray={layoutArray}
              pageNumber={index}
              sectionData={data}
              handleArrayOfTagsOfCardObject={this.handleArrayOfTagsOfCardObject}
              handleNextPage={handleNextPage}
              handleSaveCardsPopUp={this.handleSaveCardsPopUp}
              handleSelectThemePopUp={this.handleSelectThemePopUp}
              handleStoryVisiblity={handleStoryVisiblity}
              hideToolTip={this.hideToolTip}
              onCardButtonClick={this.onCardButtonClick}
              hideUnHidePopUp={this.hideUnHidePopUp}
              newTagsForCardObject={newTagsForCardObject}
              onCloseUpView={this.handleCloseUpView}
              onNextPage={this.handleNextPage}
              openCardFamilyPopupWithUrl={openCardFamilyPopupWithUrl}
              selectedCardForMutation={selectedCardForMutation}
              showToolTip={this.showToolTip}
              userData={userData}
              handleLoginPopUp={handleLoginPopUp}
              history={history}
              isSavedTooltip={isSavedTooltip}
              savedCardId={savedCardId}
            />
          }

          <ToolTip
            x={xOrigin}
            y={yOrigin}
            isVisible={isToolTipVisible}
            text={text}
          />
          {isSaveCardsPopUpVisibleForHome ? (
            <SaveCardsPopUp
              isVisible={isSaveCardsPopUpVisibleForHome}
              history={history}
              x={xOriginSaveCardsPopUpForHome}
              y={yOriginSaveCardsPopUpForHome}
              cardId={selectedCardForMutation}
              hidePopUp={this.hidePopUp}
              isHomeFeed={true}
              saveCardFromMore={saveCardFromMore}
            />
          ) : null}
          {showCardButtonModal || isSaveCardsPopUpVisible ? (
            <CardButtonsPopUp
              history={history}
              showToolTip={this.showToolTip}
              hideToolTip={this.hideToolTip}
              handleReRenderCard={this.handleReRenderCard}
              displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
              isCollections={false}
              disabledDownloadButton={true}
              isFavorites={true}
              isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
              cardId={selectedCardForMutation}
              selectedCard={selectedCard}
              closePopUp={this.onCloseCardButtonModal}
              handleShareCardPopUp={this.handleShareCardPopUp}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

// export default HomeFeedInner
