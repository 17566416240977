import React, { Component } from "react";
import "../../css/components/CommonComponents/SearchHashTags.css";
import "../../css/components/CommonComponents/StarTagForm.css";
import "../../css/components/CommonComponents/NewCrossTagPopUp.css";

class NewCrossTagPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChild = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible, x, y, hidePopUp } = this.props;
    if (visible) {
      return (
        <div
          style={{
            left: x,
            top: y,
            position: "absolute",
          }}
          onClick={this.handleChild}
        >
          <div className="SearchtagsContainer">
            {/*<ul className="searchoptionlist">*/}

            {/*<li className="nameemail">*/}
            <span className="startagform commingsoon-popup">
              <h3 className="commingSoon-newCrossTag">Coming Soon!</h3>
              <span>Select your own area. Available March 2019.</span>
              <label className="savebtn commingsoon-btn">
                <input
                  type="button"
                  name="close"
                  value="close"
                  onClick={hidePopUp}
                />
              </label>
            </span>
            {/*</ul>*/}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default NewCrossTagPopUp;
