import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import DownloadDataCard from './DownloadDataCard';

class DownloadDataWideChart extends Component {
  render() {
    const { id, data, familyADI, showTwitterCardPopup } = this.props;
    const content = (
      <div className='d-flex w-100 h-100 position-relative'>
        {(id && data) ? <DownloadDataCard id={data.id}
                                          key={data.id + 'downloadDataCard'}
                                          isWide={true}
                                          familyADI={familyADI}/> : ''
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default DownloadDataWideChart;
