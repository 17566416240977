import React, { Component } from "react";
import Switch from "react-switch";
import * as d3 from "d3";
import * as _ from "lodash";

class D3PieChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 5, bottom: 10, left: 5 };
    this.clientWidth = 217;
    this.clientHeight = 217;
    this.toggleHeight = 28;
    this.state = {
      ratio: 1,
      isToggle: false,
      data: [],
      fontSize: 11,
    };
  }

  handleChange = (checked, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { pieData } = this.props;
    this.setState({ isToggle: checked }, () => {
      this.createChartData(pieData);
    });
  };

  componentWillReceiveProps(nextProps) {
    const { pieData } = this.props;
    if (!_.isEqual(nextProps.pieData, pieData)) {
      this.createChartData(nextProps.pieData);
    }
  }

  createChartData = (pieData) => {
    const { isToggle } = this.state;
    if (isToggle) {
      this.setState({
        data: pieData.data.map((o) => {
          const obj = {};
          obj.title = pieData.pieTypes[1].title;
          if (o[pieData.pieTypes[1].pieType] != null) {
            obj.value = parseFloat(o[pieData.pieTypes[1].pieType].toFixed(2));
          } else {
            obj.value = 0.0;
          }
          obj.name = o.name;
          obj.name2 = o.name2;
          obj.color = o.color;
          return obj;
        }),
      });
    } else {
      this.setState({
        data: pieData.data.map((o) => {
          const obj = {};
          obj.title = pieData.pieTypes[0].title;
          if (o[pieData.pieTypes[0].pieType] != null) {
            obj.value = parseFloat(o[pieData.pieTypes[0].pieType].toFixed(2));
          } else {
            obj.value = 0.0;
          }
          obj.name = o.name;
          obj.name2 = o.name2;
          obj.color = o.color;
          return obj;
        }),
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    if (!_.isEqual(prevState.data, data)) {
      this.addArcs();
      this.displayHighValue();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init();
  };

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  init = () => {
    const { pieData, id } = this.props;
    if (pieData && id) {
      this.createSvg();
    }
  };

  findMaxValue = () => {
    const { data } = this.state;
    let lowest = { value: Number.POSITIVE_INFINITY };
    let highest = { value: Number.NEGATIVE_INFINITY };
    let tmp;
    for (let i = data.length - 1; i >= 0; i--) {
      tmp = data[i];
      if (tmp.value < lowest.value) lowest = tmp;
      if (tmp.value > highest.value) highest = tmp;
    }
    return highest;
  };

  displayHighValue = () => {
    const data = Object.values(this.findMaxValue());
    this.addLegends(data.slice(1, data.length - 1));
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);
    // this.svgToggleG = this.svg.select('#' + id + 'toggle-g-container');
    const gSelection = this.svg
      .selectAll("g.g-container")
      .data([svgId], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "g-container");
    this.autoAlignSVG();
    this.addArcs();
    this.displayHighValue();
  };

  addArcs = () => {
    const self = this;
    const { data } = this.state;
    // this.svgG.selectAll('g').remove();
    const pieSelect = this.svgG
      .selectAll("g")
      .data(this.pie(data), (d) => d.index);
    const newPieSelect = pieSelect
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        const innerRadius = self.props.thickness
          ? self.radius + 5 - self.props.thickness
          : 0;
        const arc = self.arc
          .innerRadius(innerRadius)
          .outerRadius(self.radius + 5);
        d3.select(this)
          .select("path")
          .transition()
          .duration(500)
          .ease(d3.easeBackOut)
          .attr("d", arc);
        const data = Object.values(d.data);
        self.addLegends(data.slice(1, data.length - 1));
      })
      .on("mouseout", function () {
        const innerRadius = self.props.thickness
          ? self.radius - self.props.thickness
          : 0;
        d3.select(this)
          .select("path")
          .transition()
          .attr(
            "d",
            self.arc.innerRadius(innerRadius).outerRadius(self.radius)
          );
        self.displayHighValue();
      });
    pieSelect.exit().remove();
    const pathSelect = newPieSelect
      .merge(pieSelect)
      .selectAll("path")
      .data(
        (d) => [d],
        (d) => d.index
      );
    pathSelect
      .enter()
      .append("path")
      .merge(pathSelect)
      .transition()
      .ease(d3.easeCircle)
      .duration(300)
      .attr("d", this.arc)
      .styleTween("fill", function (d) {
        return d3.interpolate(d.data.color, d.data.color);
      });
    pathSelect.exit().remove();
  };

  addLegends(titles) {
    const titleG = this.svgG
      .selectAll("g.pie-titles-g")
      .data([Date.now()], (d) => d);
    const newTitleG = titleG.enter().append("g");
    newTitleG
      .merge(titleG)
      .attr("class", "pie-titles-g")
      .attr("transform", "translate(0,-20)");
    const textSelect = newTitleG
      .merge(titleG)
      .selectAll("text.pie-text")
      .data(titles, (d) => d);
    const newText = textSelect.enter().append("text");
    newText
      .merge(textSelect)
      .attr("class", "pie-text")
      .attr("text-anchor", "middle")
      .attr("dy", (d, i) => {
        return i === 0 ? 5 : 10 + i * 20;
      })
      .style("font-size", (d, i) => (i === 0 ? "23px" : "15px"))
      .style("font-family", (d, i) =>
        i ? "ProximaNova-Regular" : "ProximaNova-Bold"
      )
      .style("fill", "#5B5850")
      .text((d, i) =>
        i || d == null
          ? d == null
            ? "No Data"
            : d
          : d < 1
          ? d.toFixed(1) + "%"
          : Math.round(d) + "%"
      );
    textSelect.exit().remove();
    titleG.exit().remove();
  }

  autoAlignSVG = () => {
    const { isWide, thickness } = this.props;
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.toggleHeight;

    this.radius = Math.min(this.width, this.height) / 2;

    this.setState({
      ratio: (this.size / 217) * 0.65,
    });

    this.arc = d3
      .arc()
      .innerRadius(thickness ? this.radius - thickness : 0)
      .outerRadius(this.radius);
    this.pie = d3
      .pie()
      .value((d) => {
        return d.value;
      })
      .sort(null);
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height + this.margin.top + this.margin.bottom
        }`
      );

    this.svgG.attr(
      "transform",
      "translate(" +
        (this.margin.left + this.width / 2) +
        "," +
        (this.margin.top + this.height / 2) +
        ")"
    );
    // this.svgToggleG.attr('y', (this.height + this.margin.top));
  };

  render() {
    const { pieData, id } = this.props;
    const { isToggle, fontSize, ratio } = this.state;
    const switchTitle = {
      fontSize: fontSize + "px",
      color: "#989898",
      fontFamily: "ProximaNova-Regular",
    };
    const svgId = id.replace(/=/g, "");
    return (
      <div className="D3PieChart d-flex flex-column h-100 w-100">
        <div
          className="h-100 d-flex flex-column justify-content-center align-items-center w-100"
          ref={(ele) => (this.parentEle = ele)}
        >
          <svg id={svgId} />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "6px",
              height: `28px`,
              lineHeight: "0.8",
              transform: `scale(${ratio})`,
            }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span style={switchTitle}>
                {pieData.pieTypes.length && pieData.pieTypes[0].title}
              </span>
              <div className="px-2 d-flex align-items-center">
                <Switch
                  onChange={this.handleChange}
                  checked={isToggle}
                  className="react-switch"
                  id="togglePie"
                  height={18}
                  width={35}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#989898"
                  onHandleColor="#fff"
                />
              </div>
              <span style={switchTitle}>
                {pieData.pieTypes.length && pieData.pieTypes[1].title}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default D3PieChart;
