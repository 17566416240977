import React, { Component } from 'react';
import '../../css/components/WideCard/WideCardWrapper.css';
import CardBottomComponents from '../Card/CardBottomComponents';
import CardBottom from '../Card/CardBottom';

class WideCardWrapper extends Component {
  render() {
    const {
      wideCardData, children, middleContent, title, fromChart,
      downloadWideVersion, hideAreaTag
    } = this.props;
    const areaTag = wideCardData && wideCardData.boardCardUse &&
      wideCardData.boardCardUse.configuration && wideCardData.boardCardUse.configuration.savedArea &&
      wideCardData.boardCardUse.configuration.savedArea.name;
    const content = <div className={`${downloadWideVersion ? 'pr-3' : ''} card-body m-0 p-0 h-100`}>
      <div className='d-flex h-100 wide-card-contain'>
        <div
          className={'order-sm-3 order-1 data-viz' + (fromChart ? ' d-flex' +
            ' justify-content-center' : ' map-data-viz')}>
          <div className='h-100 w-100'>
            {children}
          </div>
        </div>
        <div className='d-flex flex-column align-items-lg-start
                            align-items-center order-sm-1 order-2 left-content'>
          <div
            className="wide-card-title d-flex flex-column">
            <span>{title ? title : wideCardData.boardCardUse.configuration.title}</span>
            {!hideAreaTag ? <span>{areaTag ? areaTag : 'United States'}</span>
              : null}
          </div>
          <div className='middle-content'>
            {middleContent}
          </div>
        </div>
        <div
          className={`${downloadWideVersion ? 'pl-3' : ''} left-content bottom-component order-sm-2 order-3 bottom-left-component`}>
          <CardBottomComponents bottomData={wideCardData}
                                hideBottomComponent={true}
                                isMapcardsVisible={false}
                                y={'-100px'}
                                isWide={true}
                                {...this.props}/>
        </div>
        {!downloadWideVersion ?
          <div className='col-sm-12 d-flex bottom-component order-sm-4 order-4'>
            <div className='col-lg-2 col-md-3 col-sm-2 bottom-content' style={{ zIndex: 1 }}>
              <CardBottom bottomData={wideCardData}
                          className="d-flex justify-content-center"
                          isMapcardsVisible={false}
                          y={'-100px'}
                          {...this.props}/>
            </div>
          </div>
          : null
        }
      </div>
    </div>;
    return (
      <>
        {
          downloadWideVersion ? <div style={{ position: 'absolute', left: '-30000px' }}>
              <div className="download-card-wrapper card chart-container px-3 mx-4"
                   style={{ height: '675px', width: '1200px' }}
                   id={`card-family-${wideCardData.boardCardUse.id.replace(/=/g, '')}`}>
                {content}
              </div>
            </div>
            : <div className="wide-card-wrapper card chart-container h-100"
                   id={wideCardData.boardCardUse.id}>
              {content}
            </div>
        }
      </>
    );
  }
}

export default WideCardWrapper;
