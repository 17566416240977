import React from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/components/MySavedAreasPopup/MySavedAreasPopup.css";

const SelectAreaTagsPopUp = (props) => {
  const { hidePopUp } = props;
  const pages = [
    {
      showTitle: true,
      title: "New Area",
      titleClass: "border-bottom-0",
      name: "new-area",
      showButton: false,
      newArea: true,
      modalCustomClass: 'h-100 pt-0',
      props: {
        ...props,
        isCalledFromTallModalPopUp: true,
        placeholderText: "Search My Saved Areas",
      },
    },
  ];
  return (
    <StandardTallModalPopup
      closePopUp={hidePopUp}
      pages={pages}
      currentPage={pages[0]}
    />
  );
};
export default SelectAreaTagsPopUp;
