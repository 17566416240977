import { createAction, handleActions } from "redux-actions";
import { GET_USER, SET_USER, GET_SUBSCRIPTION_INFO, GET_CREDIT_REFERRAL_INFO, START_MEMBERSHIP } from "./actionTypes";
import { createSelector } from "reselect";

import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../RelayEnvironment";
import { fulfilled } from "./actionUtils";
// ==================================
// Selectors
// ==================================
export const userSelector = createSelector(
  state => state.user,
  user => user.data
);

export const subscriptionInfoSelector = createSelector(
  state => state.user,
  user => user.subscriptionInfo
);

export const creditReferralInfoSelector = createSelector(
  state => state.user,
  user => user.creditReferralInfo
);

// ==================================
// Actions
// ==================================

export const getUserInfoAction = createAction(GET_USER, () => { return { user: '' } });
export const setUserInfoAction = createAction(SET_USER, (user) => (user));

export const startMembershipAction = createAction(START_MEMBERSHIP, (isStart) => (isStart));

export const getSubscriptionInfo = createAction(GET_SUBSCRIPTION_INFO, async () => {
  const _query = graphql`
      query  userSubscriptionInfoQuery {
        currentUser {
          edges {
            node {
              id
              userImage {
                id
                url
              }
              username
              userFirstName
              userLastName
              userReportName
              briefBio
              contactInfo
              email
              activePromoCode
              activePromoCodeMessage
              deleteByDate
              isDeletedAccount
              isOverSubscriptionLimits
              pauseSubscriptionStartDate
              pauseSubscriptionEndDate
              pausedUserSubscriptionLevel
              pausedDaysTotal
              pausesUsed
              stripeCustomerId
              subscriptions
              subscriptionCoupons
              subscriptionCouponCode
              usageCountStories
              usageMaxStories
              usageCountCards
              usageMaxCards
              userSubscriptionExpiration
              userSubscriptionLevel
              userSubscriptionTerm
              activeCouponCode {
                subscriptionCouponCode
                created
              }
              creditAmount
              defaultPaymentMethod {
                id
                object
                type
                card {
                  brand
                  country
                  last4
                  expMonth
                  expYear
                }
              }
              couponsGiven {
                edges {
                  node {
                    activator {
                      username
                    }
                    subscriptionCouponCode
                    created
                  }
                }
              }
            }
          }
        }
      }
    `;
  try {
    const resp = await fetchQuery(environment, _query);
    if (!resp || resp.currentUser == null || resp.currentUser.edges == null || resp.currentUser.edges[0] == null) {
      return null;
    } else {
      return resp.currentUser.edges[0].node;
    }
  } catch (err) {
    return null;
  }

})

export const getCreditReferralInfo = createAction(GET_CREDIT_REFERRAL_INFO, async () => {
  const _query = graphql`
      query  userCreditReferralInfoQuery {
        currentUser {
          edges {
            node {
              creditAmount
              referralLink
              referrals {
                edges {
                  node {
                    creditAmount
                    historyDescription
                  }
                }
              }
            }
          }
        }
      }
    `;
  try {
    const resp = await fetchQuery(environment, _query);
    if (!resp || resp.currentUser == null || resp.currentUser.edges == null || resp.currentUser.edges[0] == null) {
      return null;
    } else {
      return resp.currentUser.edges[0].node;
    }
  } catch (err) {
    return null;
  }

})


// ==================================
// Action Handlers
// ==================================

const ACTION_HANDLERS = {
  [getUserInfoAction]: (state, action) => ({
    ...state,
    data: action.payload
  }),
  [fulfilled(getSubscriptionInfo)]: (state, action) => {
    return ({
      ...state,
      subscriptionInfo: action.payload
    })
  },
  [fulfilled(getCreditReferralInfo)]: (state, action) => {
    return ({
      ...state,
      creditReferralInfo: action.payload
    })
  },
  [setUserInfoAction]: (state, action) => {

    return ({
      ...state,
      data: action.payload
    })
  },
  [startMembershipAction]: (state, action) => {
    return ({
      ...state,
      startMembership: action.payload
    })
  }
};

// ==================================
// Reducer
// ==================================
const initialState = {
  data: null,
  subscriptionInfo: null,
  creditReferralInfo: null,
  startMembership: false
};

export default handleActions(ACTION_HANDLERS, initialState);
