import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../css/components/Subheader/Subheader.css';
import { appBaseUrl } from '../../utility/utilities';

class Subheader extends Component {
  render() {
    return (
      <div className="projectboard-tab-section">
        <ul>
          <li className=""><a href={appBaseUrl + 'map/'} target="_blank" rel="noopener noreferrer">Map
            Room</a></li>
          <li className=""><Link to="/NEWS/member%20gallery/1">Member Gallery</Link></li>
          <li className=""><Link to="/">Run a Report</Link></li>
        </ul>
      </div>);
  }
}

export default Subheader;
