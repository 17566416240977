import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";

class D3PositiveNegativeHorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 10, right: 15, bottom: 10, left: 10 };
    this.clientWidth = 245;
    this.clientHeight = 230;
    this.state = {
      ratio: 1,
    };
  }

  componentDidUpdate() {
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (!this.mounted) return;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    if (isWide && ratio !== this.size / 230) {
      this.setState({
        ratio: this.size / 230,
      });
    }
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { pnhbData, id } = this.props;
    if (pnhbData && id) {
      d3.select(this.parentEle)
        .selectAll("svg")
        .filter((d) => {
          return d && d.id !== id;
        })
        .remove();
      this.createSvg();
      window.addEventListener("resize", this.onWindowResize);
      this.mounted = true;
    }
  };

  createSvg = () => {
    const { pnhbData, id } = this.props;
    const svgId = id.replace(/=/g, "");
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3PositiveNegativeHorizontalBarChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);
    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "g-container");
    const gRectSelection = this.svgG.selectAll("g.rect-container").data(
      (d) => [d],
      (d) => d
    );
    gRectSelection
      .enter()
      .append("g")
      .merge(gRectSelection)
      .attr("class", "rect-container")
      .attr("id", (d) => d + "rect-container");
    gRectSelection.exit().remove();
    this.svgRectG = d3.select("g#" + svgId + "rect-container");
    const gLabels = this.svgG.selectAll("g.labels-container").data(
      (d) => [d],
      (d) => d
    );
    gLabels
      .enter()
      .append("g")
      .merge(gLabels)
      .attr("class", "labels-container")
      .attr("id", (d) => d + "labels-container");
    gLabels.exit().remove();

    const gAxisSelection = this.svgG.selectAll("g.axis-container").data(
      (d) => [d],
      (d) => d
    );
    gAxisSelection
      .enter()
      .append("g")
      .merge(gAxisSelection)
      .attr("class", "axis-container")
      .attr("id", (d) => d + "axis-container");
    gAxisSelection.exit().remove();
    this.axisG = d3.select("g#" + svgId + "axis-container");

    const gDashPathSelection = this.svgG
      .selectAll("g.dash-path-container")
      .data(
        (d) => [d],
        (d) => d
      );
    gDashPathSelection
      .enter()
      .append("g")
      .merge(gDashPathSelection)
      .attr("class", "dash-path-container")
      .attr("id", (d) => d + "dash-path-container");
    gDashPathSelection.exit().remove();
    this.svgDashPathG = d3.select("g#" + svgId + "dash-path-container");

    this.svgLabelsG = d3.select("g#" + svgId + "labels-container");
    this.y = d3
      .scaleBand()
      .domain(pnhbData.data.map((d) => d.label))
      .paddingInner(0.5)
      .paddingOuter(0);
    const minValue = d3.min(
      pnhbData.barTypes.map((d) =>
        d3.min(pnhbData.data.map((c) => c[d.barType]))
      )
    );
    const maxValue = d3.max([
      d3.max(
        pnhbData.barTypes.map((d) =>
          d3.max(pnhbData.data.map((c) => c[d.barType]))
        )
      ),
      -minValue * 2,
    ]);
    const mv = maxValue ? maxValue : 0;
    this.xTop = d3.scaleLinear().domain([2, mv]);
    this.x = d3.scaleLinear().domain([minValue, mv]);
    this.xLabel = d3.scaleLinear().range([0, mv]);
    this.yBarType = d3
      .scaleBand()
      .domain(pnhbData.barTypes.map((d) => d.barType))
      .paddingInner(0.4)
      .paddingOuter(0);
    this.autoAlignSVG(minValue ? minValue : 0, mv);
    this.addXAxis();
    this.createDashPath();
    this.createRect();
    this.createLabels();
  };

  autoAlignSVG = (minValue, maxValue) => {
    const { isWide } = this.props;
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    const { pnhbData } = this.props;
    this.labelHeight = 50;
    const height = this.clientHeight;
    this.height =
      (height < pnhbData.data.length * 35
        ? pnhbData.data.length * 35
        : height) -
      this.margin.top -
      this.margin.bottom -
      this.labelHeight;
    this.labelWidth = 100;
    this.size =
      this.mainParentEle.clientHeight > this.mainParentEle.clientWidth
        ? this.mainParentEle.clientWidth
        : this.mainParentEle.clientHeight;
    const { ratio } = this.state;
    if (isWide && ratio !== this.size / 230) {
      this.setState({
        ratio: this.size / 230,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr(
        "height",
        this.height + this.margin.top + this.margin.bottom + this.labelHeight
      );
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.y.range([0, this.height]);
    this.yBarType.range([0, this.y.bandwidth()]);
    this.svgRectG.attr("transform", "translate(" + this.labelWidth + ",0)");
    this.svgLabelsG.attr("transform", "translate(0,0)");
    this.x.range([0, this.width - this.labelWidth]);
    this.xLabel.domain([0, this.width - this.labelWidth]);
    const newMaxValue = this.xLabel(maxValue.toString().length * 8 + 5);
    this.x.domain([minValue - newMaxValue, maxValue + newMaxValue]).nice();
    this.xTop.range([0, this.width - this.labelWidth - this.x(5)]);
    this.xTop.domain([1, 1]);
    this.axisG.attr(
      "transform",
      "translate(" + this.labelWidth + "," + this.y.bandwidth() + ")"
    );
    this.svgDashPathG.attr(
      "transform",
      "translate(" + this.labelWidth + "," + this.y.bandwidth() + ")"
    );
  };

  addXAxis = () => {
    const { barTitle, xTitle } = this.props;

    this.axisG.select(".axis--x").remove();
    const topXAxisG = this.axisG
      .append("g")
      .attr("class", "axis axis--x")
      .attr("transform", `translate(${this.x(5)}, 2)`)
      .call(d3.axisBottom(this.xTop).ticks(0));
    topXAxisG.selectAll("path.domain").style("stroke-dasharray", "4 4");
    topXAxisG
      .append("foreignObject")
      .attr("x", 0)
      .attr("y", -10)
      .attr("height", 25)
      .attr("width", this.width - this.labelWidth - this.x(5))
      .attr("transform", `translate(0, -15)`);
    this.axisG
      .append("g")
      .attr("class", "axis axis--x")
      .attr("transform", `translate(0,${this.height})`)
      .call(d3.axisBottom(this.x).ticks(5))
      .append("text")
      .attr("x", -5)
      .attr("transform", `translate(${(this.width - this.labelWidth) / 3}, 25)`)
      .attr("fill", "#000")
      .text(xTitle)
      .style("font-size", "9px")
      .style("text-anchor", "middle")
      .attr("dy", "4px");
    const tspan = this.axisG
      .selectAll(".axis--x")
      .selectAll("foreignObject")
      .selectAll("div")
      .data([barTitle]);
    tspan
      .enter()
      .append("xhtml:div")
      .merge(tspan)
      .text((d) => d)
      .style("color", "#424B54")
      .style("display", "flex")
      .style("width", this.width - this.labelWidth - this.x(5))
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "11px")
      .style("line-height", "1");

    tspan.exit().remove();
  };

  createDashPath() {
    const { mainText } = this.props;
    const lineG = this.svgDashPathG.selectAll("path.dash").data([0]);
    const topXAxisGLeft = this.svgDashPathG
      .append("g")
      .attr("class", "axis title")
      .attr("transform", `translate(${this.x(5) - this.labelWidth}, 2)`);

    topXAxisGLeft
      .append("foreignObject")
      .attr("x", 0)
      .attr("y", -10)
      .attr("height", 25)
      .attr("width", this.labelWidth + 15)
      .attr("transform", `translate(0, -15)`);

    const tspan = this.svgDashPathG
      .selectAll(".title")
      .selectAll("foreignObject")
      .selectAll("div")
      .data([mainText]);
    tspan
      .enter()
      .append("xhtml:div")
      .merge(tspan)
      .text((d) => d)
      .style("color", "#424B54")
      .style("display", "flex")
      .style("width", this.width - this.labelWidth)
      .style("font-family", "ProximaNova-Regular")
      .style("font-size", "10px")
      .style("justify-content", "center")
      .style("line-height", "1");

    const newLineG = lineG.enter().append("path");
    newLineG
      .merge(lineG)
      .attr("class", "dash")
      .attr("d", (d) => {
        return "M" + this.x(d) + ",0 L" + this.x(d) + "," + this.height;
      })
      .style("stroke", (d) => (d.color ? d.color : "#424B54"))
      .style("stroke-width", " 1px")
      .style("stroke-dasharray", "5,4");
    lineG.exit().remove();
  }

  createLabels() {
    //        const self = this;
    const rightSidePadding = 13;
    const { pnhbData } = this.props;
    const lineG = this.svgLabelsG
      .selectAll("g.labels-g")
      .data(pnhbData.data, (d) => d.label);
    const newLineG = lineG.enter().append("g");
    newLineG
      .merge(lineG)
      .attr("class", "labels-g")
      .attr("transform", (d) => {
        return (
          "translate(0," + (this.y(d.label) + this.y.bandwidth() / 2) + ")"
        );
      });
    lineG.exit().remove();
    const foreignobjectLabel = newLineG
      .merge(lineG)
      .selectAll("foreignObject.foreignObjectLabel")
      .data(
        (d) => [d],
        (d) => d.label
      );
    const newforeignobjectLabel = foreignobjectLabel
      .enter()
      .append("foreignObject");

    newforeignobjectLabel
      .merge(foreignobjectLabel)
      .attr("width", this.labelWidth - rightSidePadding)
      .attr("height", this.y.bandwidth() * 2)
      .attr("x", "0")
      .attr("y", "-4")
      .attr("class", "foreignObjectLabel");

    foreignobjectLabel.exit().remove();
    const textLabel = newforeignobjectLabel
      .merge(foreignobjectLabel)
      .selectAll("div.label")
      .data(
        (d) => [d],
        (d) => d.label
      );
    textLabel
      .enter()
      .append("xhtml:div")
      .merge(textLabel)
      .text((d) => d.label)
      .attr("class", "label")
      .style("font-size", "10px")
      .style("color", "#424B54")
      .style("padding", "0")
      .style("font-family", "ProximaNova-Regular")
      .style("max-width", this.labelWidth - rightSidePadding + "px")
      .style("line-height", "1em")
      .style("text-align", "right")
      .style("width", this.labelWidth - rightSidePadding + "px")
      .each(function (d) {
        //   const label = d3.select(this)
        //  const labelH = label && label.node() && label.node().getBoundingClientRect() &&
        // label.node().getBoundingClientRect().height if (labelH > (self.y.bandwidth() * 2)) {
        // label.text(d.label.replace(/\W*\s(\S)*$/, '')) }
      });
    textLabel.exit().remove();
  }

  createRect() {
    const { pnhbData } = this.props;
    const rectG = this.svgRectG
      .selectAll("g.rect-g")
      .data(pnhbData.data, (d) => d.label);
    const newRectG = rectG.enter().append("g");
    newRectG
      .merge(rectG)
      .attr("class", "rect-g")
      .attr("transform", (d) => {
        return (
          "translate(0," + (this.y(d.label) + this.y.bandwidth() / 2) + ")"
        );
      });
    const rectCountG = newRectG
      .merge(rectG)
      .selectAll("g.rect-count-g")
      .data(
        (d) => {
          const data = [];
          pnhbData.barTypes.forEach((c) => {
            const obj = {};
            obj.data = d;
            obj.barObj = c;
            data.push(obj);
          });
          return data;
        },
        (d) => d.barType + d.data.label
      );
    const newRectCountG = rectCountG.enter().append("g");
    newRectCountG
      .merge(rectCountG)
      .attr("class", "rect-count-g")
      .attr(
        "transform",
        (d) =>
          "translate(" +
          (d.data[d.barObj.barType] < 0
            ? this.x(d.data[d.barObj.barType])
            : this.x(0)) +
          "," +
          this.yBarType(d.barObj.barType) +
          ")"
      );
    rectCountG.exit().remove();
    rectG.exit().remove();
    const rect = newRectCountG
      .merge(rectCountG)
      .selectAll("rect.rect")
      .data(
        (d) => [d],
        (d) => d.barType + d.data.label
      );
    rect
      .enter()
      .append("rect")
      .merge(rect)
      .attr("class", (d) => "rect " + (d.barObj && d.barObj.barType))
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", this.yBarType.bandwidth() / 2)
      .attr("ry", this.yBarType.bandwidth() / 2)
      .attr("width", (d) => {
        const w =
          d.barObj && d.data[d.barObj.barType] < 0
            ? this.x(d.data[d.barObj.barType] * -1) - this.x(0)
            : this.x(d.data[d.barObj.barType]) - this.x(0);
        return isNaN(w) ? 0 : w;
      })
      .attr("height", this.yBarType.bandwidth())
      .style("fill", (d) =>
        d.barObj && d.barObj.color && d.data[d.barObj.barType] < 0
          ? d.barObj.color[1]
          : d.barObj.color[0]
      );

    rect.exit().remove();
    const count = newRectCountG
      .merge(rectCountG)
      .selectAll("text.count")
      .data(
        (d) =>
          d.barObj && pnhbData.barTypes[0].barType === d.barObj.barType
            ? [d]
            : [],
        (d) => d.barType + d.data.label
      );
    count
      .enter()
      .append("text")
      .merge(count)
      .attr("class", (d) => "count " + (d.barObj && d.barObj.barType))
      .attr("x", (d) => {
        return d.barObj && d.data[d.barObj.barType] > 0
          ? this.x(d.data[d.barObj.barType]) + 2 - this.x(0)
          : -2;
      })
      .attr("y", 0)
      .attr("dy", "8px")
      .text((d) => {
        return d.barObj && d.data[d.barObj.barType]
          ? d.data[d.barObj.barType] < 1 && d.data[d.barObj.barType] > -1
            ? _.round(d.data[d.barObj.barType], 1)
            : _.round(d.data[d.barObj.barType])
          : "";
      })
      .style("fill", "#424B54")
      .style("font-size", "12px")
      .style("text-anchor", (d) =>
        d.data[d.barObj.barType] > 0 ? "start" : "end"
      )
      .style("font-family", "ProximaNova-Bold");

    rect.exit().remove();
  }

  render() {
    const { ratio } = this.state;
    return (
      <div
        className="D3PositiveNegativeHorizontalBarChart h-100 d-flex flex-grow-1 flex-column justify-content-center align-items-center"
        ref={(ele) => (this.mainParentEle = ele)}
      >
        <div
          style={{
            height: "230px",
            width: "247px",
            transform: `scale(${ratio})`,
          }}
        >
          <div
            className="flex-grow-1 card-scrollbar h-100"
            ref={(ele) => (this.parentEle = ele)}
          />
        </div>
      </div>
    );
  }
}

export default D3PositiveNegativeHorizontalBarChart;
