/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateInvitationInput = {|
  email?: ?string,
  clientMutationId?: ?string,
|};
export type ReferFriendsCreateInvitationMutationVariables = {|
  input: CreateInvitationInput
|};
export type ReferFriendsCreateInvitationMutationResponse = {|
  +createInvitation: ?{|
    +success: ?boolean
  |}
|};
export type ReferFriendsCreateInvitationMutation = {|
  variables: ReferFriendsCreateInvitationMutationVariables,
  response: ReferFriendsCreateInvitationMutationResponse,
|};
*/


/*
mutation ReferFriendsCreateInvitationMutation(
  $input: CreateInvitationInput!
) {
  createInvitation(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateInvitationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateInvitationPayload",
    "kind": "LinkedField",
    "name": "createInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferFriendsCreateInvitationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReferFriendsCreateInvitationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ReferFriendsCreateInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation ReferFriendsCreateInvitationMutation(\n  $input: CreateInvitationInput!\n) {\n  createInvitation(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '18177e6b2b14d3d8b072a42ebe4ce876';

module.exports = node;
