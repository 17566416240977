import React, { useState, useRef, useEffect } from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const EditSponsorCodePopup = ({ closePopUp, oldSponsorCode }) => {
  const [updating, setUpdating] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [editSponsorCode, setEditSponsorCode] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(oldSponsorCode);

  const updateCouponCode = (e) => {
    if (!selectedCoupon) {
      setErrorMsg("Please enter sponsor code");
      return;
    }
    const mutation = graphql`
      mutation EditSponsorCodePopupMutation($input: UpdateUserAccountInput!) {
        updateUserAccount(input: $input) {
          success
        }
      }
    `;
    const variables = {
      input: {
        subscriptionCouponCode: selectedCoupon,
      },
    };

    setUpdating(true);
    setErrorMsg("");
    setSuccessMsg("");
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.updateUserAccount.success === true) {
          setUpdating(false);
          setSuccessMsg("Successfuly updated!");
          setEditSponsorCode(false);
          setTimeout(() => {
            setSuccessMsg("");
          }, 5000);
        } else {
          setUpdating(false);
          setErrorMsg(response.updateUserAccount.validationErrors.message);
        }
      },
      onError: (err) => {
        console.log(err.errors);
        setUpdating(false);
        setErrorMsg("Could not update new Promo code");
      },
    });
  };

  const helperText = (
    <div className="text-muted mt-2">
      NOTE: Updating the Sponsor Code will instantly make the old Sponsor Code
      null and void. The new Sponsor Code can be used for new sign ups. <br />
      <br />
      BroadStreet members who have already activated memberships using
      sponsorships will not be impacted.
    </div>
  );
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "Edit My Sponsor Code",
      name: "sponsor-code",
      formContainerClass: "px-3",
      showButton: true,
      buttonTitle: successMsg ? 'SAVED' : (updating ? 'WORKING' : 'SAVE'),
      handleOnClick: updateCouponCode,
      disabled: updating,
      formItems: [
        {
          type: "text",
          ref: inputRef,
          value: selectedCoupon,
          className: editSponsorCode ? 'd-block' : 'd-none',
          onChange: (e) => setSelectedCoupon(e.target.value),
          onKeyUp: (e) => {
            if (e.key === "Enter") {
              updateCouponCode(e);
            }
            if (e.key === "Escape") {
              setEditSponsorCode(false);
              setSelectedCoupon(oldSponsorCode);
            }
          },
        },
        {
          type: "editCouponCodeBox",
          condition: editSponsorCode,
          handleOnClick: () => setEditSponsorCode(true),
          couponCode: selectedCoupon
        },
        {
          type: "string",
          text: updating ? "Updating ..." : helperText,
        },
        {
          type: "string",
          text: errorMsg,
          className: "text-warning",
        },
        {
          type: "string",
          text: successMsg,
          className: "text-success",
        }
      ],
    },
  ];

  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={closePopUp}
          currentPage={pagesArray[0]}
          pages={pagesArray}
        />
      ) : null}
    </>
  );
};

export default EditSponsorCodePopup;
