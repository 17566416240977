import React, { useEffect, useState, useRef } from "react";
import "../../css/components/AccountSettings/AccountSettings.css";
import {
  getSubscriptionInfo,
  userSelector,
  subscriptionInfoSelector,
} from "../../actions/user";
import { connect } from "react-redux";
import PurchaseCouponsPopup from "./PurchaseCouponsPopup";
import { AnnouncementPopup } from "common-components";

import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../RelayEnvironment";
import AccountEditPopup from "./AccountEditPopup";
import EditSponsorCodePopup from "./EditSponsorCodePopup";
import { toast } from "react-toastify";

const placeHolderImage = require("../../images-placeholder/profile1.png");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.45)",
    zIndex: 1000,
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#inputModal");

const Sponsor = ({
  subscriptionInfo,
  userData,
  getSubscriptionInfo,
  avatarImage,
  avatarEditor,
  imageId,
  handleImageEditorPopUpVisible,
}) => {
  const [showPurchasePopup, setShowPurchasPopup] = useState(false);
  const [showPurchaseCompletePopup, setShowPurchaseCompletePopup] =
    useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [coupons, setCoupons] = useState([]);

  const [isEditAccount, setIsEditAccount] = useState(false);

  const [updating, setUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [showEditSponsorPopup, setShowEditSponsorPopup] = useState(false);

  const inputTag = useRef();

  const openModal = (coupon) => {
    setSelectedCoupon(coupon);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setShowPurchaseCompletePopup(false);
    refreshPage();
  };

  const handleVouchersPurchased = () => {
    setShowPurchasPopup(false);
    setShowPurchaseCompletePopup(true);
  };

  const refreshPage = () => {
    getSubscriptionInfo();
  };
  const updateCouponCode = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      const mutation = graphql`
        mutation SponsorUpdateCouponMutation($input: UpdateUserAccountInput!) {
          updateUserAccount(input: $input) {
            success
          }
        }
      `;
      const variables = {
        input: {
          subscriptionCouponCode: selectedCoupon,
        },
      };

      setUpdating(true);
      setErrorMsg("");
      setSuccessMsg("");
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.updateUserAccount.success === true) {
            setUpdating(false);
            setSuccessMsg("Successfuly updated!");
            refreshPage();
            setTimeout(() => {
              closeModal();
              setEditSponsorCode(false);
            }, 1000);
          } else {
            setUpdating(false);
            setErrorMsg(response.updateUserAccount.validationErrors.message);
          }
        },
        onError: (err) => {
          console.log(err.errors);
          setUpdating(false);
          setErrorMsg("Could not update new Sponsor code");
        },
      });
    }
  };
  useEffect(() => {
    refreshPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriptionInfo && subscriptionInfo.subscriptionCouponCode) {
      setCoupons([
        { couponCode: subscriptionInfo.subscriptionCouponCode, type: "1 Year" },
      ]);
    } else {
      setCoupons([]);
    }
  }, [subscriptionInfo]);

  const afterOpenModal = () => {
    inputTag.current.focus();
  };
  const copyLink = (e) => {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value =
      subscriptionInfo && subscriptionInfo.subscriptionCouponCode
        ? subscriptionInfo.subscriptionCouponCode
        : "";

    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Your referral link is copied to clipboard!");
  };

  const history = useHistory();
  const [editSponsorCode, setEditSponsorCode] = useState(false);
  const avtarImage =
    subscriptionInfo && subscriptionInfo.userImage != null
      ? subscriptionInfo.userImage.url
      : placeHolderImage;
  const subscriptionData =
    subscriptionInfo && subscriptionInfo.subscriptionCouponCode
      ? subscriptionInfo.subscriptionCouponCode
      : "";
  const voucherCount = subscriptionInfo
    ? subscriptionInfo.subscriptionCoupons
    : 0;
  return (
    <>
      <div className="d-flex flex-column align-items-center account-settings">
        <div
          className="d-flex flex-column align-items-center"
          style={{ maxWidth: 290 }}
        >
          {/* <div className="text-left mb-5">
            <Link to="/accountSetting">Account Overview </Link>\
        </div> */}
          <div className="ibox-head mb-5 w-100">
            <h3 className="font-weight-bold w-100 text-center">
              Become a Sponsor
            </h3>
          </div>
          <button
            className="button button-outline mb-3 px-5"
            onClick={() => {
              setShowPurchasPopup(true);
            }}
          >
            Purchase Vouchers
            <img
              src={require("../../images/iconLongArrowAltRight.svg")}
              className="ml-2"
              alt=""
            />
          </button>
          <div className="text-bold mb-5">
            You have ${subscriptionInfo ? subscriptionInfo.creditAmount : 0} in
            credit
          </div>
          <div className="mb-5 text-left">
            <p>
              Want to share BroadStreet with others? Purchase shareable
              vouchers. When someone activates a BroadStreet membership using
              your sponsor code, then you become a sponsor.
            </p>
          </div>
          <div className="mb-5">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="my-0 font-weight-normal">My Public Profile</h4>
              <button
                className="button button-outline ml-3 p-0"
                onClick={() => {
                  history.push("/accountSetting/myProfile");
                }}
              >
                Edit
              </button>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div
                className="d-inline-block position-relative mr-3"
                style={{ minWidth: 115 }}
              >
                <img
                  src={avtarImage}
                  onError={(e) => {
                    e.target.src = placeHolderImage;
                    e.target.onerror = null;
                  }}
                  alt=""
                  data-toggle="modal"
                  data-bigimage={avtarImage}
                  data-target="#myModal"
                  className="img-fluid rounded-circle"
                  height="115px"
                  width="115px"
                  onClick={() =>
                    history.push("/accountSetting/accountOverview")
                  }
                />
                <button
                  className="button-icon"
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    bottom: 0,
                    opacity: 0.5,
                  }}
                  onClick={() =>
                    history.push("/accountSetting/accountOverview")
                  }
                >
                  <img src={require("../../images/iconEdit.svg")} alt="" />
                </button>
              </div>
              {subscriptionInfo && (
                <div className="text-left">
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: "bold",
                      color: "#5A5A5A",
                    }}
                  >
                    SPONSORED BY
                  </div>
                  <div className="text-success">
                    {subscriptionInfo.userReportName}
                  </div>
                  <div style={{ fontSize: 11, color: "#989898" }}>
                    {subscriptionInfo.briefBio} <br />
                    {subscriptionInfo.contactInfo}
                  </div>
                </div>
              )}
              {isEditAccount && (
                <AccountEditPopup
                  avatarImage={avatarImage}
                  imageId={imageId}
                  handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
                  avatarEditor={avatarEditor}
                  closePopUp={() => {
                    setIsEditAccount(false);
                    refreshPage();
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-100 text-left mb-4">
            <h4 className="mb-4 font-weight-normal">My Sponsor Code</h4>

            {editSponsorCode ? (
              <div>
                <label>Label</label>
                <input
                  value={selectedCoupon}
                  onChange={(e) => {
                    setSelectedCoupon(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      updateCouponCode(e);
                    }
                    if (e.key === "Escape") {
                      setEditSponsorCode(false);
                    }
                  }}
                  className="w-100"
                />
                <div className="text-muted">
                  {updating ? ": Updating ..." : "Helper text"}
                </div>
                <div className="text-warning">{errorMsg}</div>
              </div>
            ) : (
              <>
                {subscriptionInfo ? (
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      style={{
                        minWidth: 185,
                        borderRadius: 3,
                        border: "solid 2px #D3D3D3",
                      }}
                      className="d-flex justify-content-between mr-2 align-items-center p-2"
                    >
                      {subscriptionData}
                      <img
                        onClick={(e) => {
                          copyLink(e);
                        }}
                        src={require("../../images/iconCopy1.svg")}
                        alt=""
                      />
                    </div>
                    <button
                      className="button button-outline ml-3"
                      onClick={() => {
                        setShowEditSponsorPopup(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : null}
                <Link
                  to="#"
                  onClick={(e) => {
                    copyLink(e);
                  }}
                >
                  Click to Copy
                </Link>
              </>
            )}
          </div>
          {showEditSponsorPopup && (
            <EditSponsorCodePopup
              closePopUp={() => {
                setShowEditSponsorPopup(false);
                refreshPage();
              }}
              oldSponsorCode={subscriptionData}
            />
          )}
          <div className="text-left w-100 mb-4">
            <h4 className="mb-3 font-weight-normal">
              Vouchers Shared With ...
            </h4>
            <div className="users">
              {subscriptionInfo &&
                subscriptionInfo.couponsGiven &&
                subscriptionInfo.couponsGiven.edges &&
                subscriptionInfo.couponsGiven.edges.map((item, index) => (
                  <div className="d-flex align-items-center mb-3" key={index}>
                    <img
                      src={require("../../images/tmp-avatar.png")}
                      alt=""
                      className="avatar mr-2"
                    />
                    <div>
                      <div className="font-weight-bold">
                        {item.node.activator.username}
                      </div>
                      <div className="text-muted">
                        {item.node.subscriptionCouponCode}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <h4 className="mb-4 w-100 text-left font-weight-normal">Vouchers</h4>
          <div className="text-muted mb-3">
            You have {voucherCount}{" "}
            {voucherCount === 1 ? "voucher/coupon" : "vouchers/coupons"}
          </div>
          {coupons.map((item, index) => (
            <div className="membership-card" key={index}>
              <div className="membership-type">
                <div>{item.type} Membership</div>
                <img
                  onClick={(e) => {
                    copyLink(e);
                  }}
                  src={require("../../images/iconCopy1.svg")}
                  alt=""
                />
              </div>
              <div className="promo-code">SPONSOR CODE:</div>
              <div
                className="promo-value"
                onClick={() => openModal(item.couponCode)}
              >
                {item.couponCode}
                <img src={require("../../images/iconEdit1.svg")} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {showPurchasePopup && (
        <PurchaseCouponsPopup
          sponsorCode={subscriptionData}
          closePopUp={() => {
            setShowPurchasPopup(false);
          }}
          onSuccess={handleVouchersPurchased}
        />
      )}
      {showPurchaseCompletePopup && (
        <>
          <AnnouncementPopup
            closePopUp={closeModal}
            onOkClick={closeModal}
            announcementMessage="Congratulations, your vouchers were purchased successfully."
            announcementPrompt="Thank you for supporting the BroadStreet mission."
            closeButtonLabel="Close"
            okButtonLabel="VIEW"
            title="SUCCESSFUL PAYMENT"
          ></AnnouncementPopup>
        </>
      )}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Buy Vouchers for Sponsor Code"
      >
        {errorMsg && (
          <div className="alert alert-danger mb-3 rounded" role="alert">
            {errorMsg}
          </div>
        )}
        {successMsg && (
          <div className="alert alert-success mb-3 rounded" role="alert">
            {successMsg}
          </div>
        )}
        {updating && <div>Updating...</div>}
        <input
          style={{ minWidth: 250 }}
          ref={inputTag}
          value={selectedCoupon}
          onChange={(e) => setSelectedCoupon(e.target.value)}
          onKeyDown={updateCouponCode}
        />
      </Modal>
    </>
  );
};

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state),
  userData: userSelector(state),
});

const mapDispatch = {
  getSubscriptionInfo,
};
export default connect(mapStatus, mapDispatch)(Sponsor);
