import React, { Component } from "react";
import * as d3 from "d3";

class D3LineChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 16, right: 10, bottom: 30, left: 10 };
    this.yAxisLabelWidth = 20;
    this.clientWidth = 235;
    this.clientHeight = 218;
    this.spaceBetweenLegeds = 16;
    this.xValue = "year";
    this.yValue = "population";
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.update();
  };

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  init = () => {
    this.x = d3.scaleLinear();
    this.y = d3.scaleLinear();
    this.line = d3
      .line()
      .x((d) => this.x(d[this.xValue]))
      .y((d) => this.y(d[this.yValue]));
    this.createSvg();
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3LineChart")
      .attr("id", (d) => d)
      .on("mousemove", () => this.drawTooltip(true));
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "gcontainer");

    const legendG = this.svgG.selectAll("g.legend-container").data(
      (d) => [d],
      (d) => d
    );
    legendG
      .enter()
      .append("g")
      .merge(legendG)
      .attr("id", (d) => d + "legendcontainer")
      .attr("class", "legend-container");
    legendG.exit().remove();
    this.legendG = this.svgG.select("g#" + svgId + "legendcontainer");

    const linesG = this.svgG.selectAll("g.area-container").data(
      (d) => [d],
      (d) => d
    );
    linesG
      .enter()
      .append("g")
      .merge(linesG)
      .attr("id", (d) => d + "areacontainer")
      .attr("class", "area-container");
    linesG.exit().remove();
    this.linesG = this.svgG.select("g#" + svgId + "areacontainer");

    const linesAreaRect = this.linesG.selectAll("rect.lines-area-rect").data(
      (d) => [d],
      (d) => d
    );
    linesAreaRect
      .enter()
      .append("rect")
      .merge(linesAreaRect)
      .attr("id", (d) => d + "linesarearect")
      .attr("class", "lines-area-rect")
      .style("opacity", 0);
    linesAreaRect.exit().remove();
    this.linesAreaRect = this.linesG.select("rect#" + svgId + "linesarearect");

    const xAxis = this.svgG.selectAll(".x.axis").data(
      (d) => [d],
      (d) => d
    );
    xAxis
      .enter()
      .append("g")
      .merge(xAxis)
      .attr("id", (d) => d + "xAxis")
      .attr("class", "x axis");
    xAxis.exit().remove();
    this.xAxis = this.svgG.select("g#" + svgId + "xAxis");

    const yAxis = this.svgG.selectAll(".y.axis").data(
      (d) => [d],
      (d) => d
    );
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("id", (d) => d + "yAxis")
      .attr("class", "y axis");
    yAxis.exit().remove();
    this.yAxis = this.svgG.select("g#" + svgId + "yAxis");
    const tooltipLine = this.svgG.selectAll("line.tooltip-line").data(
      (d) => [d],
      (d) => d
    );
    tooltipLine
      .enter()
      .append("line")
      .merge(tooltipLine)
      .attr("id", (d) => d + "tooltipline")
      .attr("class", "tooltip-line")
      .style("pointer-events", "none");
    tooltipLine.exit().remove();
    this.tooltipLine = this.svgG.select("line#" + svgId + "tooltipline");
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.legendHeight;

    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height + this.margin.top + this.margin.bottom + this.legendHeight
        }`
      );
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.linesAreaRect
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width - this.yAxisLabelWidth)
      .attr("height", this.height);
    this.xAxis.attr("transform", "translate(0, " + this.height + ")");
    this.yAxis.attr("transform", "translate(" + this.width + ", 0)");
    this.legendG.attr(
      "transform",
      "translate(0," +
        (this.height + this.margin.top + this.margin.bottom) +
        ")"
    );

    this.x.range([0, this.width - this.yAxisLabelWidth]);
    this.y.range([this.height, 0]);
  };

  update = () => {
    const { lineData } = this.props;
    if (lineData.data.length) {
      this.legendHeight =
        (lineData.pathTypes.length + 1) * this.spaceBetweenLegeds;
      this.autoAlignSVG();
      this.sources = lineData.pathTypes.map((pt) => {
        return {
          id: pt.lineType,
          type: pt,
          values: lineData.data.map((d) => {
            const obj = {};
            obj[this.xValue] = d[this.xValue];
            obj[this.yValue] = d[pt.lineType];
            return obj;
          }),
        };
      });
      this.x.domain(d3.extent(lineData.data, (d) => d[this.xValue]));
      this.y.domain([
        d3.min(this.sources, (c) => d3.min(c.values, (d) => d[this.yValue])),
        d3.max(this.sources, (c) => d3.max(c.values, (d) => d[this.yValue])) +
          2,
      ]);

      this.updateXAxis();
      this.updateYAxis();
      this.updateLines();
      this.updateLegends();
    }
  };

  updateXAxis = () => {
    const { lineData } = this.props;
    this.xAxis.selectAll("text.xTitle").remove();
    this.xAxis
      .call(
        d3
          .axisBottom(this.x)
          .ticks(4)
          .tickFormat((d) => d)
      )
      .append("text")
      .attr("x", 0)
      .attr("class", "xTitle")
      .attr("transform", `translate(0,30)`)
      .attr("fill", "#424B54")
      .text(lineData.xAxisLabel)
      .style("text-anchor", "start")
      .style("font-family", "ProximaNova-Bold");
    this.xAxis
      .select("path.domain")
      .attr("d", "M0,0 L" + this.width + ",0")
      .style("fill", "#424B54");
    this.xAxis.selectAll("g.tick").selectAll("line").remove();
    this.xAxis
      .selectAll("g.tick")
      .selectAll("text")
      .style("text-anchor", "start");
  };

  updateYAxis = () => {
    const { lineData } = this.props;
    this.yAxis.selectAll("text.yTitle").remove();
    this.yAxis
      .call(d3.axisLeft(this.y).ticks(4))
      .append("text")
      .attr("class", "yTitle")
      .attr("transform", "translate(" + -this.width + ",0)")
      .attr("y", 0)
      .attr("dy", "0")
      .attr("fill", "#424B54")
      .attr("fill-opacity", "0.65")
      .text(lineData.yAxisLabel)
      .style("text-anchor", "start")
      .style("font-family", "ProximaNova-Regular")
      .style("fill", "#424B54")
      .attr("dy", "-1px");
    this.yAxis
      .selectAll("g.tick")
      .selectAll("line")
      .attr("x2", 0)
      .attr("x1", -this.width)
      .style("stroke", "#424B54")
      .style("opacity", "0.2")
      .attr("dy", "-1px");
    this.yAxis
      .selectAll("g.tick")
      .selectAll("text")
      .attr("y", -4)
      .style("text-anchor", "start")
      .attr("dy", "-1px")
      .style("fill", "#424B54");
    this.yAxis.select("path.domain").remove();
  };

  updateLines = () => {
    const lines = this.linesG
      .selectAll("path.line")
      .data(this.sources, (d) => d.id);
    lines
      .enter()
      .append("path")
      .merge(lines)
      .attr("id", (d) => d.id + "line")
      .attr("class", "line")
      .attr("d", (d) => this.line(d.values))
      .style("fill", "none")
      .style("stroke", (d) => (d.type.stroke ? d.type.stroke : "#424B54"))
      .style("stroke-width", (d) =>
        d.type["stroke-width"] ? d.type["stroke-width"] : "1.5px"
      );
    lines.exit().remove();

    this.raiseLinesAreaRect();
  };

  raiseLinesAreaRect = () => {
    this.linesAreaRect
      .raise()
      .attr("opacity", 0)
      .on("mousemove", () => {
        d3.event.stopPropagation();
        this.drawTooltip();
      })
      .on("mouseout", () => this.drawTooltip(true));
  };

  updateLegends = () => {
    const { lineData } = this.props;
    // legends G enter, update, exit
    const rectHeight = 6;
    const rectWidth = 14;
    const legendG = this.legendG
      .selectAll("g.legend")
      .data(lineData.pathTypes, (d) => d && d.lineType);
    const newLegendG = legendG.enter().append("g");
    newLegendG
      .merge(legendG)
      .attr("class", "legend")
      .attr(
        "transform",
        (d, i) =>
          `translate(${this.margin.left},${this.spaceBetweenLegeds * i})`
      );
    legendG.exit().remove();
    // legends rect enter, update, exit
    const legendRect = newLegendG
      .merge(legendG)
      .selectAll("rect")
      .data(
        (d) => [d],
        (d) => d && d.lineType
      );
    const newRect = legendRect.enter().append("rect");
    newRect
      .merge(legendRect)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", rectWidth)
      .attr("height", rectHeight)
      .attr("rx", rectHeight / 2)
      .attr("ry", rectWidth / 2)
      .attr("transform", `translate(0,-${rectHeight / 2})`)
      .style("fill", (d) => d.stroke);
    legendRect.exit().remove();
    // legends text enter, update, exit
    const legendText = newLegendG
      .merge(legendG)
      .selectAll("text")
      .data(
        (d) => [d],
        (d) => d && d.lineType
      );
    const newText = legendText.enter().append("text");
    newText
      .merge(legendText)
      .attr("x", rectWidth + 5)
      .attr("y", 0)
      .style("font-size", "12px")
      .attr("text-anchor", "start")
      .attr("dy", "4px")
      .style("fill", "#424B54")
      .style("color", "#424B54")
      .style("font-family", "ProximaNova-Regular")
      .text((d) => d.title && d.title);
    legendText.exit().remove();
  };

  drawTooltip = (isRemove) => {
    const { lineData } = this.props;
    if (this.parentEle) {
      this.fontSize =
        Math.min(this.parentEle.clientWidth, this.parentEle.clientHeight) / 17;
    } else {
      this.fontSize = 10;
    }
    const isFirefox = typeof InstallTrigger !== "undefined";
    let x = isFirefox
      ? d3.event.layerX - 4
      : d3.mouse(this.linesAreaRect.node())[0] + 4;
    const year = Math.floor(this.x.invert(x));

    const tooltipData = lineData.data.find((o) => o.year === year);
    if (!tooltipData) {
      return false;
    }
    if (isRemove) {
      if (this.tooltipLine) this.tooltipLine.attr("stroke", "none");
    } else {
      this.tooltipLine
        .attr("stroke", "#424B54")
        .attr("x1", this.x(year))
        .attr("x2", this.x(year))
        .attr("y1", 0)
        .attr("y2", this.height)
        .on("mousemove", () => this.drawTooltip(true));
    }

    const mPos = d3.mouse(d3.select("body").node());
    const tooltip = d3
      .select("body")
      .selectAll("div.d3-tooltip")
      .data(isRemove ? [] : [tooltipData], (d) => d);
    const newTooltip = tooltip.enter().append("div");
    newTooltip
      .merge(tooltip)
      .attr("class", "d3-tooltip")
      .style("display", "block")
      .style("position", "absolute")
      .style("font-size", this.fontSize + "px")
      .style("font-family", "ProximaNova-Regular")
      .style("font-weight", "bolder")
      .style("background-color", "rgba(255, 255, 255, 1)")
      .style("box-shadow", "0px 5px 15px 0px rgba(0,0,0,0.3)")
      .style("padding", this.fontSize / 2 + "px")
      .style("text-align", "left")
      .style("left", mPos[0] + this.fontSize * 2 + "px")
      .style("top", mPos[1] - this.fontSize * 2 + "px")
      .html(year)
      .on("mousemove", () => this.drawTooltip(true));

    const div = newTooltip
      .merge(tooltip)
      .selectAll("div.d3-tooltip-div")
      .data(lineData.pathTypes, (d) => d.lineType);
    const newDiv = div.enter().append("div");
    newDiv
      .merge(div)
      .attr("class", "d3-tooltip-div")
      .style("color", (d) => d.stroke)
      .style("font-size", this.fontSize + "px")
      .html((d) => d.title + " : " + tooltipData[d.lineType].toFixed(1));
    div.exit().remove();
    tooltip.exit().remove();
  };

  render() {
    return (
      <div className="D3LineChart h-100 w-100" style={{ position: "relative" }}>
        <div
          className="h-100 d-flex justify-content-center align-items-center w-100"
          ref={(ele) => (this.parentEle = ele)}
        />
      </div>
    );
  }
}

export default D3LineChart;
