/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBoardCardUseInput = {|
  boardCardUseId: string,
  boardSectionId?: ?string,
  cardPosition?: ?number,
  sharingStatus?: ?number,
  endYear?: ?number,
  internalOrderList?: ?string,
  jsonStyleProperties?: ?any,
  organizationId?: ?string,
  removeSavedArea?: ?boolean,
  reportId?: ?string,
  savedAreaId?: ?string,
  startYear?: ?number,
  storyLink?: ?string,
  storyText?: ?string,
  title?: ?string,
  toolLink?: ?string,
  jsonProperties?: ?any,
  boardId?: ?string,
  clientMutationId?: ?string,
|};
export type MapsEditMutationVariables = {|
  input: UpdateBoardCardUseInput
|};
export type MapsEditMutationResponse = {|
  +updateBoardCardUse: ?{|
    +boardCardUseNode: ?{|
      +id: string
    |}
  |}
|};
export type MapsEditMutation = {|
  variables: MapsEditMutationVariables,
  response: MapsEditMutationResponse,
|};
*/


/*
mutation MapsEditMutation(
  $input: UpdateBoardCardUseInput!
) {
  updateBoardCardUse(input: $input) {
    boardCardUseNode {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBoardCardUseInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBoardCardUsePayload",
    "kind": "LinkedField",
    "name": "updateBoardCardUse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUseNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MapsEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MapsEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MapsEditMutation",
    "operationKind": "mutation",
    "text": "mutation MapsEditMutation(\n  $input: UpdateBoardCardUseInput!\n) {\n  updateBoardCardUse(input: $input) {\n    boardCardUseNode {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de20acef05e97ba44c3ebd78029d2463';

module.exports = node;
