import React, { useState } from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { STRIPE_PUBLIC_KEY } from "../../config";
import { userSelector } from "../../actions/user";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        backgroundColor: "white",
        fontFamily:
          "ProximaNova-Regular, Roboto, Open Sans, Segoe UI, sans-serif",
        /*color: '#403f4c',*/
        /* borderRadius: '3px', */
        "::placeholder": {
          color: "#989898",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const _CheckOutForm = ({ email, stripe, elements }) => {
  const [zipCode, setZipCode] = useState("");
  const [showZipCode, setShowZipCode] = useState(false);

  const [updating, setUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = ({ error }) => {
    if (error) {
      setErrorMsg(error.message);
    }
  };

  const handleSubmit = () => {
    if (!stripe) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    setUpdating(true);
    setErrorMsg("");
    setSuccessMsg("");

    const cardElement = elements.getElement(CardNumberElement);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
        },
      })
      .then((result) => {
        UpdatePayment(result.paymentMethod.id);
      })
      .catch((err) => {
        if (err && err.errors) {
          setErrorMsg(err.errors[0].message);
        }
        setUpdating(false);
      });
  };
  const UpdatePayment = (paymentMethod) => {
    const mutation = graphql`
      mutation UpdatePaymentPopupMutation(
        $input: ReplaceDefaultPaymentMethodInput!
      ) {
        replaceDefaultPaymentMethod(input: $input) {
          success
        }
      }
    `;

    const variables = {
      input: {
        paymentMethod: paymentMethod,
        // useCreditAmount: creditAmount,
        // clientMutationId: ""
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        console.log(response);

        setSuccessMsg("Updated payment successfully.");
        setUpdating(false);
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setUpdating(false);
      },
    });
  };

  return (
    <div>
      {errorMsg && (
        <div className="alert alert-danger mb-3 rounded" role="alert">
          {errorMsg}
        </div>
      )}
      {successMsg && (
        <div className="alert alert-success mb-3 rounded" role="alert">
          {successMsg}
        </div>
      )}
      <div className="checkout-form">
        <div className="card-elements mb-3">
          <div
            className="input-group"
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <div className="pre-icon">
              <img alt={""} src={require("../../images/iconBilling.svg")} />
            </div>
            <div className="d-flex align-items-center flex-grow-1">
              <CardNumberElement
                className="flex-grow-1"
                options={createOptions()}
                onChange={handleChange}
                onFocus={() => setShowZipCode(true)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="input-group"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <div className="pre-icon">
                <img alt="" src={require("../../images/iconDate.svg")} />
              </div>
              <div className="d-flex align-items-center flex-grow-1">
                <CardExpiryElement
                  className="flex-grow-1"
                  options={createOptions()}
                  onChange={handleChange}
                  onFocus={() => setShowZipCode(true)}
                />
              </div>
            </div>
            <div
              className="input-group"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <div className="pre-icon">
                <img alt="" src={require("../../images/iconLock.svg")} />
              </div>
              <div className="d-flex align-items-center flex-grow-1">
                <CardCvcElement
                  className="flex-grow-1"
                  onFocus={() => setShowZipCode(true)}
                  options={createOptions()}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={"input-group mb-4 zip-code" + (showZipCode ? " show" : "")}
        >
          <div className="pre-icon">
            <img alt="" src={require("../../images/iconArea.svg")} />
          </div>
          <input
            type="text"
            placeholder="ZIP Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>
      </div>
      <button
        className="button-large button-important w-100"
        disabled={updating}
        onClick={() => handleSubmit()}
      >
        {updating && (
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {updating ? "Updating..." : "SAVE"}
      </button>
    </div>
  );
};
const mapState = (state) => ({
  email: userSelector(state).username,
});
const CheckOutForm = connect(mapState)(_CheckOutForm);

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => {
        return <CheckOutForm elements={elements} stripe={stripe} />;
      }}
    </ElementsConsumer>
  );
};

const UpdatePaymentPopup = ({ username, closePopUp }) => {
  const elementProvider = () => {
    return (
      <>
        <div className="px-4">
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm />
          </Elements>
        </div>
      </>
    );
  };

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "update payment",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "update-payment",
      formContainerClass: "px-3",
      formItems: [
        {
          type: "stripeElements",
          renderStripeElements: elementProvider,
        },
      ],
    },
  ];
  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={closePopUp}
          currentPage={pagesArray[0]}
          pages={pagesArray}
        />
      ) : null}
    </>
  );
};

const mapStatus = (state) => ({
  username: state.user.data.username,
});
export default connect(mapStatus)(UpdatePaymentPopup);
