/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WideIndicatorMapCardContainerQueryVariables = {|
  input: string,
  centroidInput?: ?boolean,
|};
export type WideIndicatorMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +configuration: {|
      +indicators: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +alpha: ?number,
            +indicator: ?{|
              +id: string,
              +dataDictionaryVariable: ?{|
                +unitOfMeasure: ?string
              |},
              +mapLegend: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +quantile: ?number,
                    +legendText: ?string,
                  |}
                |}>
              |},
            |},
            +palette: ?number,
          |}
        |}>
      |},
      +palette: ?string,
      +savedArea: ?{|
        +id: string,
        +extent: ?$ReadOnlyArray<?number>,
        +primaryShapes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +geojsonFeature: ?string
            |}
          |}>
        |},
        +indicatorShapes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +geojsonFeature: ?string
            |}
          |}>
        |},
      |},
    |},
    +subComponentType: ?string,
  |}
|};
export type WideIndicatorMapCardContainerQuery = {|
  variables: WideIndicatorMapCardContainerQueryVariables,
  response: WideIndicatorMapCardContainerQueryResponse,
|};
*/


/*
query WideIndicatorMapCardContainerQuery(
  $input: ID!
  $centroidInput: Boolean
) {
  boardCardUse(id: $input) {
    id
    configuration {
      indicators {
        edges {
          node {
            alpha
            indicator {
              id
              dataDictionaryVariable {
                unitOfMeasure
                id
              }
              mapLegend {
                edges {
                  node {
                    quantile
                    legendText
                    id
                  }
                }
              }
            }
            palette
            id
          }
        }
      }
      palette
      savedArea {
        id
        extent
        primaryShapes {
          edges {
            node {
              geojsonFeature
            }
          }
        }
        indicatorShapes(id: $input, centroidsOnly: $centroidInput) {
          edges {
            node {
              geojsonFeature
            }
          }
        }
      }
      id
    }
    subComponentType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "centroidInput",
    "type": "Boolean"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "input"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alpha",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitOfMeasure",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantile",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legendText",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "palette",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GeoJsonShapeNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonShapeNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "geojsonFeature",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SavedAreaNode",
  "kind": "LinkedField",
  "name": "savedArea",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoJsonShapeNodeConnection",
      "kind": "LinkedField",
      "name": "primaryShapes",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "centroidsOnly",
          "variableName": "centroidInput"
        },
        (v1/*: any*/)
      ],
      "concreteType": "GeoJsonShapeNodeConnection",
      "kind": "LinkedField",
      "name": "indicatorShapes",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subComponentType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WideIndicatorMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardSelectedIndicatorNodeConnection",
                "kind": "LinkedField",
                "name": "indicators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardSelectedIndicatorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardSelectedIndicatorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SearchableIndicatorNode",
                            "kind": "LinkedField",
                            "name": "indicator",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DataDictionaryVariableNode",
                                "kind": "LinkedField",
                                "name": "dataDictionaryVariable",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SearchableIndicatorMapLegendNodeConnection",
                                "kind": "LinkedField",
                                "name": "mapLegend",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SearchableIndicatorMapLegendNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SearchableIndicatorMapLegendNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WideIndicatorMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardSelectedIndicatorNodeConnection",
                "kind": "LinkedField",
                "name": "indicators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardSelectedIndicatorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardSelectedIndicatorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SearchableIndicatorNode",
                            "kind": "LinkedField",
                            "name": "indicator",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DataDictionaryVariableNode",
                                "kind": "LinkedField",
                                "name": "dataDictionaryVariable",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SearchableIndicatorMapLegendNodeConnection",
                                "kind": "LinkedField",
                                "name": "mapLegend",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SearchableIndicatorMapLegendNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SearchableIndicatorMapLegendNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "WideIndicatorMapCardContainerQuery",
    "operationKind": "query",
    "text": "query WideIndicatorMapCardContainerQuery(\n  $input: ID!\n  $centroidInput: Boolean\n) {\n  boardCardUse(id: $input) {\n    id\n    configuration {\n      indicators {\n        edges {\n          node {\n            alpha\n            indicator {\n              id\n              dataDictionaryVariable {\n                unitOfMeasure\n                id\n              }\n              mapLegend {\n                edges {\n                  node {\n                    quantile\n                    legendText\n                    id\n                  }\n                }\n              }\n            }\n            palette\n            id\n          }\n        }\n      }\n      palette\n      savedArea {\n        id\n        extent\n        primaryShapes {\n          edges {\n            node {\n              geojsonFeature\n            }\n          }\n        }\n        indicatorShapes(id: $input, centroidsOnly: $centroidInput) {\n          edges {\n            node {\n              geojsonFeature\n            }\n          }\n        }\n      }\n      id\n    }\n    subComponentType\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bfbfb5210181b4587ba2156133e212e';

module.exports = node;
