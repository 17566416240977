import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
    mutation SaveCardToStoryMutation($input:CopyCardToSectionsInput!)  
    {  copyCardToSections(input:  $input)  
        {  boardCardUseNode  
            {  
                id
            }  
        }  
    }
  `;

export default (
  cardId,
  boardSectionIds,
  createDataDownloadCard,
  callback,
  errorCallback
) => {
  const variables = {
    'input':
      {
        'boardCardUseId': cardId,
        'sectionIds': boardSectionIds,
        'createDataDownloadCard': createDataDownloadCard === true
      }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        // console.log('Response from server section layouts update.',JSON.stringify(response))
        // console.log('Response from server section layouts update.',(response))
        callback();
      },
      onError: (err) => {
        console.error("Error from server section layouts update sectionLayoutMutation", JSON.stringify(err));
        errorCallback(err);
      }
    },
  );
}
