import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import DotMap from "../DotMap/DotMap";
import "../../css/components/Card/WideDotMapCardContainer.css";
import WideCardWrapper from "../WideCard/WideCardWrapper";
import { DownloadConsumer } from "../Root/downloadContext";

class WideDotMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
    };
    this.adiLegends = [
      { label: "Most Affluent", color: "#78A22F" },
      {
        label: "",
        color: "#6A8D73",
      },
      { label: "Average", color: "#D5DED6" },
      { label: "", color: "#FFA987" },
      { label: "Most Deprived", color: "#FF6B35" },
    ];
    this.raceLegends = [
      { label: "Black or AA", color: "#000000" },
      { label: "Asian", color: "rgb(0, 147, 157)" },
      { label: "Other Race", color: "rgb(179, 204, 135)" },
      { label: "2 or More Races", color: "rgb(120, 162, 47)" },
      { label: "Native American", color: "rgb(211, 87, 33)" },
      { label: "Native Hawaiian", color: "rgb(255, 107, 53)" },
      { label: "White", color: "rgb(232, 232, 232)" },
    ];
  }

  render() {
    const { wideCardData, showTwitterCardPopup, isEditingEnabled, id } =
      this.props;
    const legends =
      wideCardData.boardCardUse.subComponentType === "dotsAdi"
        ? this.adiLegends
        : this.raceLegends;
    const middleContent = (
      <ul className="list-group mt-3 mt-sm-5 legend text-left">
        {legends.map((legend, index) => {
          return (
            <li key={index} className="list-group-item p-1">
              <div style={{ background: legend.color }} />
              {legend.label}
            </li>
          );
        })}
        <li key="disclaimer" className="list-group-item p-1">
          <div />
          (1 dot represents 10 children)
        </li>
      </ul>
    );

    const content = (
      <div
        className={
          isEditingEnabled
            ? "wideDotMapCardContainer movable"
            : "wideDotMapCardContainer clickable"
        }
        onClick={this.handleClick}
      >
        <div className="wideDotMapCardContainer-map">
          <QueryRenderer
            environment={environment}
            query={graphql`
              query WideDotMapCardContainerQuery($input: ID!) {
                boardCardUse(id: $input) {
                  id
                  configuration {
                    savedArea {
                      counties {
                        edges {
                          node {
                            id
                            fips5
                            geojsonFeature
                          }
                        }
                      }
                      extent
                      jsonid
                    }
                  }
                  subComponentType
                }
              }
            `}
            variables={{ input: id }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return (
                  <div>
                    <br />
                    <br />
                    Loading...
                  </div>
                );
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    You need to log in as a user with permissions to access this
                    content.
                  </div>
                );
              }
              if (props.boardCardUse.configuration.savedArea.jsonid == null) {
                return (
                  <div>
                    <br />
                    (Map Not Available)
                  </div>
                );
              }

              return (
                <div className="h-100">
                  <DotMap
                    id={
                      "widemap" + id + (showTwitterCardPopup ? "twitter" : "")
                    }
                    counties={
                      props.boardCardUse.configuration.savedArea.counties.edges
                    }
                    savedAreaJsonId={
                      props.boardCardUse.configuration.savedArea.jsonid
                    }
                    subComponentType={props.boardCardUse.subComponentType}
                    bounds={props.boardCardUse.configuration.savedArea.extent}
                  />
                  <div className="wideDotMapCardContainer-watermrk-img">
                    <img
                      src={require("../../images/favicon1.svg")}
                      alt="BroadStreet"
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    );

    return (
      <DownloadConsumer>
        {(context) => {
          context.setCardId(id);
          context.setCardType("map");
          return showTwitterCardPopup ? (
            content
          ) : (
            <WideCardWrapper middleContent={middleContent} {...this.props}>
              {content}
            </WideCardWrapper>
          );
        }}
      </DownloadConsumer>
    );
  }
}

export default WideDotMapCardContainer;
