// Card component container HOC , this componet is used as the container to all the cards we are
// using in our app. Must be used as container to every type of card.

import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import CardBottomComponents from '../Card/CardBottomComponents';

import '../../css/components/Card/CardContainer.css';
/*import ReactDOM from "react-dom";
import * as $ from "jquery";*/
import { reportToolCardArray } from '../../utility/utilities';

function withCardContainer(WrappingComponent, data) {
  return class CardContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isEditHashTagPopUpVisible: false
      };
    }

    handleEditHashTagPopUp = () => {
      this.setState({
        isEditHashTagPopUpVisible: true
      });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {isEditHashTagPopUpVisible} = this.state;
      if (nextProps.isEditHashTagPopUpVisible !== isEditHashTagPopUpVisible && isEditHashTagPopUpVisible === true) {
        this.setState({
          isEditHashTagPopUpVisible: false
        });
      }
    }

    /*hideUnHidePopUp = (x, y, popUpType, cardId) => {
        const {storyCard, data, hideUnHidePopUp} = this.props;
        if (storyCard) {
            const pos = $($(ReactDOM
                .findDOMNode(this.cardParent)
            ).parent()).position();
            y = pos.top + ReactDOM
                .findDOMNode(this.cardParent).clientHeight;
            if (document.querySelector("#card-family")) {
                y = document.querySelector("#card-family").scrollTop;
            }
        }
        const cardType = data.node.frontFacingComponent.componentType;
        hideUnHidePopUp(x, y, popUpType, cardId, cardType);
    };*/

    /*handleSaveCardsPopUp = (left, top, id, cardId, downloadCard) => {
        const {storyCard, handleSaveCardsPopUp} = this.props;
        if (storyCard) {
            const pos = $($(ReactDOM
                .findDOMNode(this.cardParent)
            ).parent()).position();
            top = pos.top + ReactDOM
                .findDOMNode(this.cardParent).clientHeight;
            if (document.querySelector("#card-family")) {
                top = document.querySelector("#card-family").scrollTop;
            }
        }
        handleSaveCardsPopUp(left, top, id,
            cardId, downloadCard);
    };*/

    /*handleShareCardPopUp = (left, top) => {
        const {storyCard, handleShareCardPopUp} = this.props;
        if (storyCard) {
            const pos = $($(ReactDOM
                .findDOMNode(this.cardParent)
            ).parent()).position();
            top = pos.top + ReactDOM
                .findDOMNode(this.cardParent).clientHeight;
            if (document.querySelector("#card-family")) {
                top = document.querySelector("#card-family").scrollTop;
            }
        }
        handleShareCardPopUp(left, top);
    };*/

    render() {
      const {data} = this.props;
      const {isMapcardsVisible, isEditHashTagPopUpVisible} = this.state;
      return (
        <div className="card-main-wrapper" id={data.node.id}
             ref={me => this.cardParent = me}>
          <QueryRenderer
            environment={environment}
            query={graphql`
            query CardContainerQuery($input: ID!) {
              boardCardUse(id: $input) {
                id
                jsonid
                additionalConfiguration {
                  jsonProperties
                }
                chartImage
                configuration {
                    id
                  author {
                    id
                    name
                    profileImage {
                      url
                    }
                  }
                   publishedStoryBoard  {
                    hasEditPermission
                    sourceBoard {
                          id
                          name
                    }
                   }
                  citation
                  endYear
                  internalOrderList
                  jsonStyleProperties
                  organization {
                    id
                    organizationName
                  }
                  permaLink
                  report {
                    id
                    jsonid
                  }
                  savedArea {
                    id
                    jsonid
                    name
                  }
                  startYear
                  storyLink
                  storyPublishedDate
                  storyUpdatedDate
                  thumbnailImage {
                    id
                    url
                  }
                  subtitle
                  title
                  toolLink
                }
                cardPosition
                frontFacingComponent {
                  componentType
                  name
                }
                subComponentType
                statistics {
                    name
                    jsonData
                }
                isFavorite
                sharingStatus
                tags {
                  edges {
                    node {
                      boardTag {
                        name
                        permaTag
                      }
                    }
                  }
                }
              }
            }
                    `}
            variables={{input: data.node.id, isUpdate: data.isUpdate}}
            render={({error, props}) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return <div/>;
              }
              if (props && props.boardCardUse) {
                const reportToolCard = reportToolCardArray.indexOf(props.boardCardUse.subComponentType) > -1;
                const id = props.boardCardUse.id.replace(/=/g, "");
                return <div className="cardContainer" id={id}>
                  <WrappingComponent {...this.props} dataItem={data}
                                     id={props.boardCardUse.id}
                                     data={props}/>
                  {!reportToolCard &&
                  <CardBottomComponents bottomData={props}
                                        isMapcardsVisible={isMapcardsVisible}
                                        {...this.props}
                    /* hideUnHidePopUp={this.hideUnHidePopUp}*/
                    /* handleSaveCardsPopUp={this.handleSaveCardsPopUp}*/
                    /*handleShareCardPopUp={this.handleShareCardPopUp}*/
                                        isEditHashTagPopUpVisible={isEditHashTagPopUpVisible}
                                        handleEditHashTagPopUp={this.handleEditHashTagPopUp}/>
                  }
                </div>;
              }
              return null;
            }}/>
        </div>
      );
    }
  };
}

export default withCardContainer;


