import React, { Component } from 'react';
import D3PositiveNegativeHorizontalBarChart from '../Card/D3PositiveNegativeHorizontalBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3PositiveNegativeHorizontalBarWideChart extends Component {
  render() {
    const { id, pnhbData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'positiveNegativeHorizontalBar' : 'positiveNegativeHorizontalBarTwitter');
    const content = (
      <div className='D3PositiveNegativeHorizontalBarWideChart d-flex w-100 h-100'>
        {(pnhbData && pnhbData.data) &&
        <D3PositiveNegativeHorizontalBarChart
          id={wideChartId}
          key={wideChartId}
          mainText={'U.S. Benchmark'}
          barTitle={'Higher than national rates'}
          xTitle={'DIFFERENCE IN DEATHS PER 100k PEOPLE'}
          pnhbData={pnhbData}
          min={0}
          max={0}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3PositiveNegativeHorizontalBarWideChart;
