import React, { Component } from "react";
import CardBottomComponents from "../Card/CardBottomComponents";
import LogDataDownloadForUserMutation from "../../mutations/LogDataDownloadForUserMutation";
import "../../css/components/Card/WideExportFamilyADIDataCardContainer.css";
import * as $ from "jquery";
import ReactDOM from "react-dom";

class WideExportFamilyADIDataCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adiComponentTable: [],
    };
  }

  setTableData = (props) => {
    const { cardFamilyData } = props;
    const adiComponentTable = JSON.parse(
      cardFamilyData.boardCardUse.adiComponentTable
    );
    if (
      adiComponentTable !== props.cardFamilyData.boardCardUse.adiComponentTable
    ) {
      this.setState((prevState) => ({
        adiComponentTable,
        ...prevState.adiComponentTable,
      }));
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setTableData(nextProps);
  }

  componentDidMount() {
    this.setTableData(this.props);
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportedFileName = fileTitle + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    this.logDataDownloadForUser();
  }

  logDataDownloadForUser() {
    const { wideCardData } = this.props;
    LogDataDownloadForUserMutation(wideCardData.boardCardUse.id, () => {
      console.log("logged download");
    });
  }

  download(data) {
    const headers = {
      indicatorId: "Indicator Id",
      indicatorName: "Indicator Name",
      percentile: "Percentile",
      valueText: "Value",
      unit: "Unit",
    };

    const itemsFormatted = [];

    // format the data
    data.forEach((item) => {
      itemsFormatted.push({
        indicatorId: item.indicatorId,
        indicatorName: item.indicatorName.replace(/,/g, ""), // remove commas to avoid errors
        percentile: item.percentile,
        valueText: item.valueText.replace(/,/g, ""), // remove commas to avoid errors
        unit: item.unit,
      });
    });

    const fileTitle = "family-adi-export"; // or 'my-unique-title'
    // call the exportCSVFile() function to process the JSON and trigger the download
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }

  hideUnHidePopUp = (x, y, popUpType, cardId) => {
    const { storyCard, hideUnHidePopUp } = this.props;
    if (storyCard) {
      const pos = $(
        $(ReactDOM.findDOMNode(this.cardParent)).parent()
      ).position();
      y = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
      if (document.querySelector("#card-family")) {
        y = document.querySelector("#card-family").scrollHeight;
      }
    }
    hideUnHidePopUp(x, y, popUpType, cardId);
  };

  handleSaveCardsPopUp = (left, top, id, cardId, downloadCard) => {
    const { storyCard, handleSaveCardsPopUp } = this.props;
    if (storyCard) {
      const pos = $(
        $(ReactDOM.findDOMNode(this.cardParent)).parent()
      ).position();
      top = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
      if (document.querySelector("#card-family")) {
        top = document.querySelector("#card-family").scrollHeight;
      }
    }
    handleSaveCardsPopUp(left, top, id, cardId, downloadCard);
  };

  handleShareCardPopUp = (left, top) => {
    const { storyCard, handleShareCardPopUp } = this.props;
    if (storyCard) {
      const pos = $(
        $(ReactDOM.findDOMNode(this.cardParent)).parent()
      ).position();
      top = pos.top + ReactDOM.findDOMNode(this.cardParent).clientHeight;
      if (document.querySelector("#card-family")) {
        top = document.querySelector("#card-family").scrollHeight;
      }
    }
    handleShareCardPopUp(left, top);
  };

  render() {
    const { adiComponentTable } = this.state;
    const { wideCardData } = this.props;
    const tr = adiComponentTable.map((o, i) => {
      return (
        <tr key={i}>
          <td>{o.indicatorName}</td>
          <td>{o.percentile}</td>
          <td>{o.valueText}</td>
          <td>{o.unit}</td>
        </tr>
      );
    });
    return (
      <div
        className="card adi-table-card"
        id={wideCardData.boardCardUse.id}
        ref={(me) => (this.cardParent = me)}
      >
        <div className="card-body h-100">
          <div className="row h-100">
            <div className="col-xl-9 col-lg-7 col-md-6 adi-table h-100">
              <table className="table border table-sm">
                <thead>
                  <tr>
                    <th scope="col">Indicators for the Family #ADI</th>
                    <th scope="col">Percentile</th>
                    <th scope="col">Value</th>
                    <th scope="col">Unit</th>
                  </tr>
                </thead>
                <tbody>{tr}</tbody>
              </table>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-6">
              <div className="card text-left h-100">
                <div className="card-body pt-0 h-100">
                  <div className="row h-100">
                    <div className="col-12 font-bold">
                      Download Data for the Family #ADI
                    </div>
                    <div className="col-12 position-absolute bottom-0">
                      <button
                        type="button"
                        className="download-btn mb-2"
                        onClick={() => this.download(adiComponentTable)}
                      >
                        Download
                      </button>
                      <CardBottomComponents
                        bottomData={wideCardData}
                        isMapcardsVisible={false}
                        createDataDownloadCard={true}
                        {...this.props}
                        hideUnHidePopUp={this.hideUnHidePopUp}
                        handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                        handleShareCardPopUp={this.handleShareCardPopUp}
                        y={"-50px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WideExportFamilyADIDataCardContainer;
