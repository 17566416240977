import React, { Component } from 'react';
import D3MultiForceLayout from '../Card/D3MultiForceLayoutChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3MultiForceLayoutWideChart extends Component {
  render() {
    const { id, title, nodes, switchLabel1, switchLabel2, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3MultiForceLayoutWideChart' : 'D3MultiForceLayoutTwitterChart');
    const content = (
      <div className='D3MultiForceLayoutWideChart d-flex w-100 h-100'>
        {(nodes) &&
        <D3MultiForceLayout
          id={wideChartId}
          key={wideChartId}
          nodes={nodes}
          title={title}
          switchLabel1={switchLabel1}
          switchLabel2={switchLabel2}
          isWide={true}
        />
        }</div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3MultiForceLayoutWideChart;
