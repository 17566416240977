import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3LineDotChart from './D3LineDotChart';

class D3LineDotWideChart extends Component {

  render() {
    const {id, lineDotData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'lineDotWide' : 'lineDotTwitter');
    const content = (
      <div className='D3LineDotWideChart d-flex w-100 h-100 '>
        {(lineDotData) &&
        <D3LineDotChart
          {...this.props}
          id={wideChartId}
          key={wideChartId}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3LineDotWideChart;
