import React, { useState, useRef, useEffect } from "react";
import { StandardTallModalPopup } from "common-components";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const EditPromoCodePopup = ({ closePopUp, oldPromoCode }) => {
  const [updating, setUpdating] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [editPromoCode, setEditPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState(oldPromoCode);
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const helperText = (
    <div className="text-muted mt-2">
      NOTE: Updating the Promo Code will apply a promotional discount to your
      next invoice for an annual membership.
    </div>
  );
  const updatePromoCode = (e) => {
    if (!promoCode) {
      setErrorMsg("Please enter promo code");
      return;
    }
    const mutation = graphql`
      mutation EditPromoCodePopupMutation($input: ApplyPromoCodeInput!) {
        applyPromoCode(input: $input) {
          promoCodeMessage
          success
        }
      }
    `;
    const variables = {
      input: {
        promoCode: promoCode,
        updateActiveSubscription: true,
      },
    };

    setErrorMsg("");
    setSuccessMsg("");
    setUpdating(true);

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response.applyPromoCode.success) {
          setUpdating(false);
          setSuccessMsg(response.applyPromoCode.promoCodeMessage);
          setEditPromoCode(false);
          setTimeout(() => {
            setSuccessMsg("");
          }, 5000);
        } else {
          setUpdating(false);
          setErrorMsg(response.applyPromoCode.promoCodeMessage);
        }
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setUpdating(false);
      },
    });
  };

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "Edit My Promo Code",
      name: "promo-code",
      formContainerClass: "px-3",
      showButton: true,
      buttonTitle: successMsg ? "SAVED" : updating ? "WORKING" : "SAVE",
      handleOnClick: updatePromoCode,
      disabled: updating,
      formItems: [
        {
          type: "text",
          ref: inputRef,
          value: promoCode,
          className: editPromoCode ? "d-block" : "d-none",
          onChange: (e) => setPromoCode(e.target.value),
          onKeyUp: (e) => {
            if (e.key === "Enter") {
              updatePromoCode(e);
            }
            if (e.key === "Escape") {
              setEditPromoCode(false);
              setPromoCode(oldPromoCode);
            }
          },
        },
        {
          type: "editCouponCodeBox",
          condition: editPromoCode,
          handleOnClick: () => setEditPromoCode(true),
          couponCode: promoCode,
        },
        {
          type: "string",
          text: updating ? "Updating ..." : helperText,
        },
        {
          type: "string",
          text: errorMsg,
          className: "text-warning",
        },
        {
          type: "string",
          text: successMsg,
          className: "text-success",
        },
      ],
    },
  ];

  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={closePopUp}
          currentPage={pagesArray[0]}
          pages={pagesArray}
        />
      ) : null}
    </>
  );
};

export default EditPromoCodePopup;
