import * as $ from "jquery";
import React, { useState, useEffect } from "react";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { apiBaseUrl } from "../../utility/utilities";
import { StandardTallModalPopup } from "common-components";

const ShareStoryPopUp = (props) => {
  const {
    data,
    userData,
    privateLinkCollaborators,
    existingCollab,
    existingInvitations,
    history,
    hasEditPermission,
    closePopUp,
    data: { board },
    cardId,
    featureImageId,
    openLoginPopUp,
  } = props;
  const userLoggedIn =
    userData &&
    userData.currentUser &&
    userData.currentUser.edges &&
    userData.currentUser.edges.length > 0;

  const setCollaboratorsModal = (key) => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[4];
    collaboratorsPage.props.visibleModal = { [key]: true };
    switch (key) {
      case "collaborators":
        collaboratorsPage.title = "Collaborators";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Add";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () =>
          setCollaboratorsModal("addCollaborators");
        break;
      case "addCollaborators":
        collaboratorsPage.title = "Add Collaborators";
        collaboratorsPage.showButton = false;
        break;
      case "sharePrivateLink":
        collaboratorsPage.title = "Share a Private Link";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Copy";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () => copyPrivateLink();
        break;
      case "inviteViaEmail":
        collaboratorsPage.title = "Invite via Email";
        collaboratorsPage.showButton = false;
        break;
      default:
        break;
    }
    setPagesArray(pages);
  };
  let targetBoardSectionIds = [];
  let addToFavorites = false;
  let addToPublicFeed = false;
  const [currentPage, setCurrentPage] = useState({});
  const [pagesArray, setPagesArray] = useState([
    {
      showTitle: true,
      showPageItem: true,
      title: "Share",
      name: "share",
      menuList: [
        {
          icon: "left-icon-link",
          title: "Copy Link (Read-Only)",
          itemCustomClass: "copy-btn",
          name: "copy-link",
          hideRightArrowIcon: true,
          stopAnimation: true,
          onClickItem: () => {
            copyLink();
            return false;
          },
        },
        {
          icon: "left-icon-email-blue",
          title: "Invite via Email",
          isBlueButton: true,
          hide: !userLoggedIn,
          onClickItem: () => {
            changeCurrentPage("invite_via_email");
          },
        },
        {
          icon: "left-icon-save",
          title: "Save Card",
          onClickItem: () => changeCurrentPage("save-card"),
        },
        {
          icon: "left-icon-download",
          title: "Download",
          name: "download",
          onClickItem: () => changeCurrentPage("download"),
        },
        {
          icon: "icon-export",
          title: "Print Report",
          name: "print-report",
          onClickItem: () => {
            closePopUp();
            setTimeout(() => {
              window.print();
            }, 500);
            return false;
          },
        },
        {
          icon: "left-icon-man",
          title: "Collaborators",
          name: "collaborators",
          hide: !props.hasEditPermission,
          onClickItem: () => changeCurrentPage("collaborators"),
        },
        {
          icon: "left-icon-lock",
          title: "Sharing & Privacy \nSettings",
          name: "sharing_privacy_settings",
          hide: !props.hasEditPermission,
          redirectTo: "#sharing-privacy",
          parent: "share",
          onClickItem: () => props.onSettingsClick(),
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "Invite via Email",
      parent: "share",
      name: "invite_via_email",
      collaborators: true,
      props: { ...props, visibleModal: { inviteViaEmail: true } },
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "Download as",
      parent: "share",
      name: "download",
      formItems: [
        {
          type: "link-button",
          // href: "downloadPdf",
          className: "download-btn disabled",
          parentClassName: "left-menu current-list",
          text: "PDF",
        },
        {
          type: "link-button",
          // href: "downloadWord",
          parentClassName: "left-menu current-list",
          className: "download-btn disabled",
          text: "WORD",
        },
        {
          type: "string",
          className: "text pt-2",
          text: "Not available yet, but coming soon!",
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      showMyFavourites: true,
      buttonTitle: "save",
      title: "Save Card",
      parent: "share",
      name: "save-card",
      fromStory: true,
      saveList: true,
      onChangeStorySelection: (
        sectionIdList,
        addToFavoritesArg,
        addToPublicFeedArg
      ) => {
        targetBoardSectionIds = sectionIdList;
        addToFavorites = addToFavoritesArg;
        addToPublicFeed = addToPublicFeedArg;
      },
      handleOnClick: () => {
        handlePublishBoardAsStory();
      },
    },
    {
      showTitle: true,
      showButton: true,
      buttonTitle: "ADD",
      title: "Collaborators",
      parent: "share",
      name: "collaborators",
      collaborators: true,
      props: { ...props, setCollaboratorsModal },
      handleOnClick: () => {
        setCollaboratorsModal("addCollaborators");
      },
    },
  ]);

  const makeStoryPublic = () => {
    if (board && !board.publishOutsidePaywall) {
      const mutation = graphql`
        mutation ShareStoryPopUpUpdateStoryMutation($input: UpdateBoardInput!) {
          updateBoard(input: $input) {
            boardNode {
              id
              name
              subtitle
            }
          }
        }
      `;
      const variables = {
        input: {
          boardId: board.id,
          publishOutsidePaywall: true,
        },
      };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: () => {},
        onError: (err) => {},
      });
    }
  };

  const copyLink = () => {
    const pagesArrayCopy = [...pagesArray];
    pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data.name === "share") {
          data.menuList &&
            data.menuList.forEach((item) => {
              if (item.name === "copy-link") {
                item.title = "LINK COPIED!!";
                item.itemCustomClass = "copied-btn";
                item.disabled = false;
                item.onClickItem = () => {
                  return false;
                };
              }
            });
        }
        return data;
      });
    setPagesArray(pagesArrayCopy);
    let link = window.location.href;
    if (data && data.board && data.board.permaLink) {
      const getLink = data.board.permaLink.split("/my/")[1];
      link = `${apiBaseUrl}board/story/${getLink}`;
    }
    makeStoryPublic();
    let inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = link;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();
  };

  useEffect(() => {
    const pages = [...pagesArray];
    let collaboratorsPage = pages[4];
    if (
      collaboratorsPage.props.privateLinkCollaborators !==
        privateLinkCollaborators ||
      collaboratorsPage.props.existingCollab !== existingCollab ||
      collaboratorsPage.props.existingInvitations !== existingInvitations
    ) {
      collaboratorsPage.props.privateLinkCollaborators =
        privateLinkCollaborators;
      collaboratorsPage.props.existingCollab = existingCollab;
      collaboratorsPage.props.existingInvitations = existingInvitations;
      setPagesArray(pages);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateLinkCollaborators, existingCollab, existingInvitations]);

  useEffect(() => {
    setCurrentPage(pagesArray[0]);
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEditPermission]);

  const changeCurrentPage = (name) => {
    if (name === "collaborators") {
      setCollaboratorsModal("collaborators");
    }
    if (pagesArray && pagesArray.length) {
      const currentPage = pagesArray.find((data) => data.name === name);
      setCurrentPage(currentPage);
    }
  };

  const checkPermission = () => {
    if (
      !hasEditPermission &&
      history &&
      history.location &&
      (history.location.hash === "#collaborators" ||
        history.location.hash === "#addCollaborators" ||
        history.location.hash === "#sharePrivateLink" ||
        history.location.hash === "#inviteCollab")
    ) {
      history.goBack();
    }
  };

  const goBack = () => {
    if (currentPage.name === "collaborators") {
      if (currentPage.props.visibleModal.collaborators) {
        changeCurrentPage(currentPage.parent);
      } else if (currentPage.props.visibleModal.addCollaborators) {
        setCollaboratorsModal("collaborators");
      } else {
        setCollaboratorsModal("addCollaborators");
      }
    } else {
      if (currentPage && currentPage.parent) {
        changeCurrentPage(currentPage.parent);
      } else {
        closePopUp();
      }
    }
  };

  const copyPrivateLink = () => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[4];
    collaboratorsPage.buttonTitle = "Copied";
    collaboratorsPage.buttonState = "saved";
    setPagesArray(pages);
    navigator.clipboard.writeText($("#PrivateLink").val());
    $("#PrivateLink").focus().select();
  };

  const handlePublishBoardAsStory = () => {
    pagesArray &&
      pagesArray.map((data) => {
        if (data.name === "save-card") {
          data.buttonTitle = "saving";
          data.disabled = true;
        }
        return data;
      });
    setPagesArray([...pagesArray]);
    const mutation = graphql`
      mutation ShareStoryPopUpDMutation($input: PublishBoardInput!) {
        publishBoard(input: $input) {
          boardCardUseNode {
            id
          }
        }
      }
    `;
    const variables = {
      input: {
        boardId: cardId,
        featureImageId: featureImageId,
        boardSectionIds: targetBoardSectionIds,
        addToFavorites: addToFavorites,
        addToPublicFeed: addToPublicFeed,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (
          response.publishBoard &&
          response.publishBoard.boardCardUseNode &&
          response.publishBoard.boardCardUseNode.id
        ) {
          targetBoardSectionIds = [];
          addToFavorites = false;
          addToPublicFeed = false;
          pagesArray &&
            pagesArray.map((data) => {
              if (data.name === "save-card") {
                data.buttonTitle = "saved";
                data.disabled = true;
                data.buttonState = "saved";
              }
              return data;
            });
          setPagesArray([...pagesArray]);
        }
      },
      onError: (err) => {
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          //show login popup
          openLoginPopUp();
        }
      },
    });
  };

  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={goBack}
          pages={pagesArray}
          currentPage={currentPage}
          selectedCardId={cardId}
        />
      ) : null}
    </>
  );
};

export default ShareStoryPopUp;
