import React, { useState } from "react";
import "../../css/components/CommonComponents/AddSectionPopUp.css";
import { StandardTallModalPopup } from "common-components";

const AddSectionPopUp = (props) => {
  const { onSaveSectionLayout, closePopUp } = props;

  const [selectedValue, setSelectedValue] = useState("default");

  const saveSection = () => {
    onSaveSectionLayout(selectedValue);
  };

  const pages = [
    {
      showTitle: true,
      title: "Add Section",
      showPageItem: true,
      name: "add-section",
      showButton: true,
      buttonTitle: "SAVE",
      handleOnClick: saveSection,
      addSection: true,
      props: { ...props, selectedValue, setSelectedValue },
    },
  ];

  const currentPage = pages[0];

  return (
    <StandardTallModalPopup
      goBack={closePopUp}
      closePopUp={closePopUp}
      pages={pages}
      currentPage={currentPage}
    />
  );
};

export default AddSectionPopUp;
