import React, { Component } from 'react';
import D3HorizontalBarChart from '../Card/D3HorizontalBarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3HorizontalBarWideChart extends Component {
  render() {
    const { id, horizontalBar, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3HorizontalBarWideChart d-flex w-100 h-100 position-relative'>
        {(horizontalBar && horizontalBar.horizontalBarData) &&
        <D3HorizontalBarChart
          id={id + 'barwide'}
          maxValue={horizontalBar.maxValue}
          mainText={horizontalBar.mainText}
          subText={horizontalBar.subText}
          minValue={horizontalBar.minValue}
          horizontalBarData={horizontalBar.horizontalBarData}
          refData={horizontalBar.refData}
          noCommunityBar={horizontalBar.noCommunityBar}
          key={id + 'horizontalWideBar'}
          isWide={true}
          mainTextEle={this.mainTextEle}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3HorizontalBarWideChart;
