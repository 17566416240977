import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import IndicatorMap from "../IndicatorMap/IndicatorMap";
import "../../css/components/Card/D3DisparitiesDeprivationWideChart.css";
import "../../css/components/Card/WideIndicatorMapCardContainer.css";
import WideCardWrapper from "../WideCard/WideCardWrapper";
import { DownloadConsumer } from "../Root/downloadContext";

class WideIndicatorMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
      legends: [],
      colors: [],
    };
  }

  updateLegend = (legends, colors, unitOfMeasure) => {
    if (colors != null && legends != null) {
      this.setState({ legends, colors: colors.reverse(), unitOfMeasure });
    } else {
      this.setState({ legends: [], colors: [], unitOfMeasure });
    }
  };

  render() {
    const {
      isEditingEnabled,
      wideCardData,
      showTwitterCardPopup,
      downloadWideVersion,
    } = this.props;
    const { legends, colors, unitOfMeasure } = this.state;
    const { title } = this.props.data.node.configuration;
    const { id } = this.props;
    const middleContent = (
      <ul
        className="list-group mt-3 mt-sm-5 legend"
        key={legends.length + colors.length}
      >
        {legends.length &&
        wideCardData.boardCardUse.subComponentType !== "bubbleMap" &&
        title !==
          "Food Desert Census Tracts (Shaded = Food Desert; Dark = More Severe)"
          ? legends.map((legend, index) => {
              const legendText = legend.node.legendText;
              let legendSuffix = null;
              if (
                legendText !== "No Data or Data Suppressed" &&
                legendText !== "N/A"
              ) {
                if (unitOfMeasure === "%") {
                  legendSuffix = "%";
                }
              }

              return (
                <li key={index} className="list-group-item p-1">
                  <div
                    style={{
                      background:
                        colors.length && colors[index] ? colors[index] : "",
                      opacity: "0.4",
                    }}
                  />
                  {legend.node.legendText}
                  {legendSuffix}
                </li>
              );
            })
          : null}
      </ul>
    );

    const content = (
      <div
        className={
          "wideIndicatorMapCardContainer h-100 " +
          (isEditingEnabled ? "movable" : "clickable")
        }
        onClick={this.handleClick}
      >
        <div className="wideIndicatorMapCardContainer-map">
          <QueryRenderer
            environment={environment}
            query={graphql`
              query WideIndicatorMapCardContainerQuery(
                $input: ID!
                $centroidInput: Boolean
              ) {
                boardCardUse(id: $input) {
                  id
                  configuration {
                    indicators {
                      edges {
                        node {
                          alpha
                          indicator {
                            id
                            dataDictionaryVariable {
                              unitOfMeasure
                            }
                            mapLegend {
                              edges {
                                node {
                                  quantile
                                  legendText
                                }
                              }
                            }
                          }
                          palette
                        }
                      }
                    }
                    palette
                    savedArea {
                      id
                      extent
                      primaryShapes {
                        edges {
                          node {
                            geojsonFeature
                          }
                        }
                      }
                      indicatorShapes(
                        id: $input
                        centroidsOnly: $centroidInput
                      ) {
                        edges {
                          node {
                            geojsonFeature
                          }
                        }
                      }
                    }
                  }
                  subComponentType
                }
              }
            `}
            variables={{
              input: id,
              centroidInput:
                wideCardData.boardCardUse.subComponentType === "bubbleMap",
            }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return (
                  <div>
                    <br />
                    <br />
                    Loading...
                  </div>
                );
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    You need to log in as a user with permissions to access this
                    content.
                  </div>
                );
              }
              if (
                props.boardCardUse.configuration.savedArea.primaryShapes.edges
                  .length === 0
              ) {
                return (
                  <div>
                    <br />
                    (Map Not Available)
                  </div>
                );
              }

              return (
                <div className="h-100">
                  <IndicatorMap
                    id={"imap" + id + (downloadWideVersion ? "twitter" : "")}
                    indicatorShapes={
                      props.boardCardUse.configuration.savedArea.indicatorShapes
                        .edges
                    }
                    areaPrimaryShapes={
                      props.boardCardUse.configuration.savedArea.primaryShapes
                        .edges
                    }
                    palette={
                      !props.boardCardUse.configuration.palette
                        ? null
                        : JSON.parse(props.boardCardUse.configuration.palette)
                    }
                    extent={props.boardCardUse.configuration.savedArea.extent}
                    typeOfMap={props.boardCardUse.subComponentType}
                    indicators={
                      props.boardCardUse.configuration.indicators.edges
                    }
                    savedAreaId={props.boardCardUse.configuration.savedArea.id}
                    updateLegends={this.updateLegend}
                  />
                  <div className="wideIndicatorMapCardContainer-watermrk-img">
                    <img
                      src={require("../../images/favicon1.svg")}
                      alt="BroadStreet"
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
    return (
      <DownloadConsumer>
        {(context) => {
          context.setCardId(id);
          context.setCardType("map");
          return showTwitterCardPopup ? (
            content
          ) : (
            <WideCardWrapper middleContent={middleContent} {...this.props}>
              {content}
            </WideCardWrapper>
          );
        }}
      </DownloadConsumer>
    );
  }
}

export default WideIndicatorMapCardContainer;
