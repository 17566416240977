import React, { useEffect, useState } from "react";
import { SaveCardToStoryMutation } from "../../mutations";
import { StandardTallModalPopup } from "common-components";

const SaveCardsPopUp = (props) => {
  const {
    isVisible,
    history: {
      location: { hash },
    },
    cardId,
    hidePopUp,
  } = props;
  const [sectionIds, setSectionIds] = useState([]);
  const [pagesArray, setPagesArray] = useState([
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      disabled: true,
      buttonTitle: "save",
      title: "Save Card",
      parent: "more",
      name: "save-card",
      onChangeStorySelection: (sectionIdList) => {
        setSectionIds([...sectionIdList]);
      },
      fromStory: true,
      handleOnClick: () => {
        saveCardToStory(sectionIds);
      },
      saveList: true,
    },
  ]);
  const [currentPage] = useState(pagesArray[0]);

  useEffect(() => {
    const pagesArrayCopy = [...pagesArray];
    pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data.name === "save-card") {
          data.disabled = !sectionIds.length;
          data.handleOnClick = () => {
            saveCardToStory(sectionIds);
          };
        }
        return data;
      });
    setPagesArray(pagesArrayCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIds]);

  const saveCardToStory = (sectionIds) => {
    // Save card to story
    if (sectionIds.length) {
      pagesArray &&
        pagesArray.map((data) => {
          if (data.name === "save-card") {
            data.buttonTitle = "saving";
            data.disabled = true;
          }
          return data;
        });
      setPagesArray(pagesArray);
      SaveCardToStoryMutation(
        cardId,
        sectionIds,
        false,
        () => {
          pagesArray &&
            pagesArray.map((data) => {
              if (data.name === "save-card") {
                data.buttonTitle = "save";
              }
              return data;
            });
          setPagesArray(pagesArray);
          hideSaveCardPopup(true, true);
        },
        (err) => {
          console.error(JSON.stringify(err));
        }
      );
    }
  };

  const hideSaveCardPopup = (isSaved, isClosed) => {
    hidePopUp("", isSaved, cardId, isClosed);
  };

  if (isVisible) {
    return (
      <StandardTallModalPopup
        closePopUp={() => hideSaveCardPopup(false, true)}
        hash={hash}
        pages={pagesArray}
        currentPage={currentPage}
        selectedCardId={cardId}
      />
    );
  } else {
    return null;
  }
};

export default SaveCardsPopUp;
