import React, { Component } from "react";
import "../../css/components/Card/D3PictureProgressChart.css";
import * as d3 from "d3";

class D3PictureProgressChart extends Component {
  resizeStyle = {
    tooltipText: 10,
  };
  constructor(props) {
    super(props);
    this.margin = { top: 0, right: 0, bottom: 0, left: 65 };
    this.clientWidth = 217;
    this.clientHeight = 167;
    this.textHeight = 100;
    this.PIGGY_BANK = "PIGGY_BANK";
    this.selectedStyle = this.resizeStyle;
    this.state = {
      percent: 0,
      ratio: 1,
    };
  }

  componentDidMount() {
    this.init(this.props);
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    this.init(this.props);
    const { ratio } = this.state;
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
      if (isWide && ratio !== this.size / 245) {
        this.setState({ ratio: this.size / 245 });
      }
    }
  };

  init = (props) => {
    this.createSvg(props);
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    this.svg = d3.select(this.parentEle).select("svg#" + id);

    const gSelection = this.svg.selectAll("g.g-container").data([id], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + id + "gcontainer");
    this.infoSVGG = this.svg.select("g#" + id + "-info-gcontainer");

    const divTooltipSelection = d3
      .select("body")
      .selectAll("div.tooltip")
      .data([1]);
    divTooltipSelection.enter().append("div").attr("class", "tooltip");
    divTooltipSelection.exit();
    this.divTooltip = d3.select("body").select("div.tooltip");

    this.updateInfo(props);
    if (props.type === this.PIGGY_BANK) {
      this.margin.left = 45;
      this.autoAlignSVG();
      this.makeProgress(props);
      this.makePiggyBank(props);
    } else {
      this.margin.left = 65;
      this.autoAlignSVG();
      this.makeProgress(props);
      this.makeTeddyBear(props);
    }
  };

  styleOnResize = (isWide) => {
    this.fontSize = isWide ? this.size / 21 : 10;
    this.resizeStyle.tooltipText = this.fontSize;
    this.selectedStyle = this.resizeStyle;
  };

  autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    const { isWide } = this.props;
    const { ratio } = this.state;
    this.divTooltip.style("display", "none");
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
      if (isWide && ratio !== this.size / 245) {
        this.setState({ ratio: this.size / 245 });
      }
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.textHeight / 2;

    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr(
        "height",
        this.height + this.margin.top + this.margin.bottom + this.textHeight
      )
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right}
            ${
              this.height +
              this.margin.top +
              this.margin.bottom +
              this.textHeight
            }`
      );

    this.styleOnResize(isWide);

    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.infoSVGG.attr("transform", "translate(0,0)");
    this.infoSVGG
      .select("foreignObject")
      .attr("y", this.height + this.margin.top);
  };

  updateInfo(props) {
    const {
      text,
      id,
      main_text,
      baby_info_text,
      chartData: { valueLabel },
    } = props;
    const svgId = id.replace(/=/g, "");
    if (main_text) {
      d3.select("#main-text-" + svgId).html(main_text);
    } else {
      // let value = percent;
      // value = value < 1 ? _.round(value, 1) : _.round(value);
      d3.select("#main-text-" + svgId).html(valueLabel ? valueLabel : 0 + "%");
    }
    if (baby_info_text) {
      d3.select("#baby-info-text-" + svgId).html(baby_info_text);
    }
    d3.select("#details-text-" + svgId).html(text);
  }

  makeTeddyBear(props) {
    const { chartData } = props;
    const g = this.svgG.selectAll("g.teddy-bear-container").data(
      (d) => [d],
      (d) => d
    );
    const newG = g.enter().append("g");
    newG
      .merge(g)
      .attr("class", "teddy-bear-container")
      .attr("transform", "translate(-1226.841,-6082.552)");

    const bodyPath = newG.selectAll("path.body").data(
      (d) => [d],
      (d) => d
    );
    const newBodyPathEle = bodyPath.enter().append("path");
    newBodyPathEle
      .merge(bodyPath)
      .attr("class", "body")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("stroke-miterlimit", "22.93")
      .attr(
        "d",
        "M1294.3,6172c-0.6,5.8-1.2,13.5-2.1,17.7c-0.9,4.2-2.3,6.7-4.2,8.8c-1.9,2.1-4.3,3.6-7,4.4   " +
          "c-2.4,0.6-4.6,0.2-5.9-1.5c-1.3-1.8-1.8-4.9-1.1-8.9c0.9-4.5,2.6-8.8,5.1-12.7c2.5-3.9,5.5-6.8,6.8-9c1.3-2.2,2.5-4.2,1.4-5.2   " +
          "-1.1-0.9-1.7,4.1-1,5.8c0.8,1.8,2.3,3.2,4.1,3.9c1.8,0.6,3.8,0.4,5.4-0.5c2.1-1.2,3.8-3,4.8-5.3c1.7-3.2,2.5-6.8,2.4-10.5   " +
          "c-0.2-3.7-1.9-7.3-3.8-11.8c-1.9-4.5-4-9.8-6.4-13.6c-1.9-3.1-4.5-5.7-7.6-7.5c-2.6-1.7-5.3-2.8-6.1-2.7c-0.8,0.1,0.3,1.5,1.4,3.4   " +
          "c1.3,2.4,2.5,5,3.3,7.6c1.7,4.6,2.8,9.4,3.3,14.3c0.6,6.1,0.3,13.7-1.8,19.2c-1.7,5.1-5.9,8.9-11.1,10c-5.3,1.3-12,0.5-17-1.1   " +
          "c-5-1.6-8.3-3.9-10.5-7.6c-2.1-3.6-3.1-8.5-3.1-14.1c0.1-5.7,0.9-11.3,2.4-16.7c1.4-5,3.8-8.3,4.5-10.3c0.6-2-0.5-2.6-1.8-2.4   " +
          "c-2.1,0.5-4,1.4-5.6,2.8c-3.3,2.7-6.3,5.8-8.7,9.3c-2.7,3.8-4.8,8.1-6,12.6c-1,3.9-1.3,8-0.8,12c0.3,3,1.4,5.9,3,8.5   " +
          "c1.4,2.1,3.1,3.6,5.2,3.9c2.2,0.2,4.3-0.3,6.2-1.5c1.8-1,2.9-2.2,3-4s-0.9-4.4-1.5-7.9c-0.6-4.3-0.6-8.8-0.1-13.1   " +
          "c0.5-5.1,1.6-10.1,3.2-15c1.5-4.2,3.3-7,5.1-8.6c1.8-1.6,3.6-2.1,4-2.9c0.5-0.8-8.6-1.9-11.6-8.1c-3-6.2,0.9-15.7-0.4-16.7   " +
          "c-1.3-1-3.8-1.9-4.8-3.7c-1-1.7-0.5-4.3,0.7-6.1c2.4-3.4,7-4.6,10.8-2.7c1.4,0.8,2.1,1.9,3.3,1.7s2.9-1.5,5.8-2.3   " +
          "c3.2-0.8,6.4-1.1,9.7-0.9c2.1,0,4.2,0.4,6.1,1.2c1.3,0.6,2.1,1.4,3.8,1.4c1.7,0,4.4-1.8,6.3-1.5s3,2.7,3.6,4.5   " +
          "c0.6,1.8,0.7,3.7,0.3,5.6c-0.5,1.4-1.6,2.4-3,2.9c-1.3,0.6,2.9,5.9,0.7,15.1c-2.2,9.2-14.9,11-16.2,11.2c-1.3,0.2-2.9,0.6-5.2,0.8   " +
          "c-2.3,0.2-5.2,0.4-7.3-1.6c-2.1-2-2.6-5.4-2.5-6.7s0.8-3,2.9-4.7c2.1-1.7,4.6-2.8,7.3-3c2.5-0.2,4.9,0.6,6.8,2.2   " +
          "c1.9,1.5,2.3,1.8,3.1,3.7c0.9,1.9,1,2,0.3,4c-0.4,1.4-1.2,2.7-2.4,3.5c-1.1,0.8-1.9,1-3.8,0.5c-1.8-0.5-3-2.5-3.5-2.5   " +
          "s-1.1,1.3-2,2.2c-1,0.8-1.3,0.9-1,0.8c0.8-0.5,1.5-1.2,2-2.1c0.9-1.5,0.7-1.3,0.5-2.7c-0.2-1.4-3-2.9-2.9-3.4   " +
          "c0.1-0.5,1.6-0.6,2.9-0.7c0.8,0,1.7,0.1,2.5,0.4c0.2,0.5-1.9,2.7-2.5,3.3 M1237.7,6174.9c-0.5,4.5-1,9.1,0.7,13.4   " +
          "c1.9,4.5,5.1,8.2,9.2,10.8c3.8,2.4,7.5,3.1,9.4,1.6c1.8-1.5,1.8-5.2,0.9-9.2c-0.9-3.8-2.2-7.4-3.9-10.9c-0.9-1.8-2-3.6-3.2-5.3"
      )
      .on("mouseover", () => {
        if (chartData) {
          this.createTooltip(chartData);
        }
      })
      .on("mouseout", () => {
        this.divTooltip.style("display", "none");
      });
    bodyPath.exit().remove();

    const leftEyePath = newG.selectAll("path.left-eye").data(
      (d) => [d],
      (d) => d
    );
    const newLeftEyePathEle = leftEyePath.enter().append("path");
    newLeftEyePathEle
      .merge(leftEyePath)
      .attr("class", "left-eye")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        "M1252.5,6107.1c0.4,0,0.7,0.8,0.7,1.2c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0   " +
          "c-0.4,0-0.5-0.3-0.5-0.7C1252.1,6107.9,1252.1,6107.1,1252.5,6107.1z"
      );
    leftEyePath.exit().remove();

    const rightEyePath = newG.selectAll("path.right-eye").data(
      (d) => [d],
      (d) => d
    );
    const newLeftRightEyePathEle = rightEyePath.enter().append("path");
    newLeftRightEyePathEle
      .merge(rightEyePath)
      .attr("class", "right-eye")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        "M1280,6108.1c0.4,0,0.7,0.8,0.7,1.2c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0   " +
          "c-0.4,0-0.5-0.4-0.5-0.8S1279.6,6108.1,1280,6108.1z"
      );
    rightEyePath.exit().remove();

    g.exit().remove();
  }

  makePiggyBank(props) {
    const { chartData } = props;
    const g = this.svgG.selectAll("g.piggy-bank-container").data(
      (d) => [d],
      (d) => d
    );
    const newG = g.enter().append("g");
    newG
      .merge(g)
      .attr("class", "piggy-bank-container")
      .attr("transform", "translate(-582.55 -1188.416)");

    const bodyPath = newG.selectAll("path.body").data(
      (d) => [d],
      (d) => d
    );
    const newBodyPathEle = bodyPath.enter().append("path");
    newBodyPathEle
      .merge(bodyPath)
      .attr("class", "body")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("pointer-events", "all")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        "M718,1239.1c-9.2,2.4-10.3-5.7-8.4-11.2s8.5-2.6,5.6,4s-14,3.1-14,3.1s0.4,10.5-3.8,19.8   " +
          "c-2.8,5.9-6.5,11.3-11,16c-0.6,6-2.8,11.7-6.4,16.6c-0.4,0.7-0.9,1.4-1.5,1.9c-7.7,1.2-11.6,0.8-11.6,0.8l-3.4-9.7   " +
          "c0,0-5.6,2.6-15.7,2.8c-5.7,0.2-11.3-0.8-16.6-2.8c0,0-2.3,8.4-3.9,9.4s-5.6,0.4-8.9,0.4h-3.8l-1.3-14.9c0,0-7.6-3.7-11-8.6   " +
          "s-8.1-12.8-8.1-12.8s-7.1-1.3-8.7-3.3s-2.1-15.5-2.1-15.5l10.2-2.3c0.8-4.4,2-8.7,3.4-12.9c1.6-4.1,4.3-7.8,7.8-10.5   " +
          "c0,0-2.7-8-2.4-12.8c0.1-2.6,0.8-5.2,2-7.6c4.2,2.4,8.5,4.6,12.9,6.5c5.3,2.1,4.6,2,4.7,1.9s14.5-7.6,41.6-3.4s35.3,28.2,35.8,31   " +
          "s0.9,6.6,0.9,6.6"
      )
      .on("mouseover", () => {
        if (chartData) {
          this.createTooltip(chartData);
        }
      })
      .on("mouseout", () => {
        this.divTooltip.style("display", "none");
      });
    bodyPath.exit().remove();

    const arcPath = newG.selectAll("path.arc-line").data(
      (d) => [d],
      (d) => d
    );
    const newArcPathEle = arcPath.enter().append("path");
    newArcPathEle
      .merge(arcPath)
      .attr("class", "arc-line")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("stroke-miterlimit", "4.0018")
      .attr("d", "M642.3,1204.1c0,0,3.7-1.8,10.1-1.3c3.6,0.2,7.1,1.1,10.2,2.8");
    arcPath.exit().remove();

    const eyePath = newG.selectAll("path.piggy-eye").data(
      (d) => [d],
      (d) => d
    );
    const newEyePathEle = eyePath.enter().append("path");
    newEyePathEle
      .merge(eyePath)
      .attr("class", "piggy-eye")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "1.25")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        "M609.9,1232c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,0-2.9,0s-2.9,1.6-2.9,0   " +
          "C607,1233.3,608.3,1232,609.9,1232z"
      );
    eyePath.exit().remove();

    g.exit().remove();
  }

  createTooltip = (chartData) => {
    const mPos = d3.mouse(d3.select("body").node());
    this.divTooltip.style("left", mPos[0] + 10 + "px");
    this.divTooltip.style("top", mPos[1] - 15 + "px");
    this.divTooltip.style("display", "inline-block");
    this.divTooltip
      .style("opacity", "1")
      .style("background", "#FFFFFF 0% 0% no-repeat padding-box")
      .style("box-shadow", "0px 1px 4px #00000066")
      .style("padding", "6px")
      .style("border-radius", "2px")
      .style("color", "#424B54")
      .style("font-size", this.selectedStyle.tooltipText + "px")
      .style("font-family", "ProximaNova-Regular");

    this.divTooltip.html(
      `<span style="font-family:ProximaNova-Bold;">${
        chartData.detailLabel1 ? chartData.detailLabel1 : ""
      }</span><br>` +
        `${chartData.detailLabel2 ? chartData.detailLabel2 : ""}<br>` +
        `${chartData.detailLabel3 ? chartData.detailLabel3 : ""}<br>` +
        `${chartData.detailLabel4 ? chartData.detailLabel4 : ""}<br>`
    );
  };

  makeProgress(props) {
    const { percent, type } = props;
    const g = this.svgG.selectAll("g.picture-progress-container").data(
      (d) => [d],
      (d) => d
    );
    const newG = g.enter().append("g");
    newG
      .merge(g)
      .attr("class", "picture-progress-container")
      .attr(
        "transform",
        type === this.PIGGY_BANK ? "translate(19, 12)" : "translate(22, 45)"
      );

    let pathD =
      "M16.7,0C26,0,33.5,10.3,33.5,23s-1.3,24-16.7,23C1.3,45,0,35.7,0,23S7.5,0,16.7,0z";
    if (type === this.PIGGY_BANK) {
      pathD =
        "M44.8,0c24.7,0,44.8,16,44.8,35.8s-20,35.8-44.8,35.8S0,55.6,0,35.8S20,0,44.8,0z";
    }
    const progressClipPath = newG.selectAll("clipPath").data(
      (d) => [d],
      (d) => d
    );
    progressClipPath
      .enter()
      .append("clipPath")
      .attr("id", (d) => "clipPath" + d)
      .merge(progressClipPath);
    progressClipPath.exit().remove();
    const progressPath = newG
      .selectAll("clipPath")
      .selectAll("path.picture-progress")
      .data(
        (d) => [d],
        (d) => d
      );
    const newProgressPathEle = progressPath.enter().append("path");
    newProgressPathEle
      .merge(progressPath)
      .attr("class", "picture-progress")
      // .attr('fill', (d) => `url(#lg${d})`)
      .attr("d", pathD);
    progressPath.exit().remove();
    if (newG.select("path.picture-progress").node()) {
      const getBBox = newG.select("path.picture-progress").node().getBBox();
      const x = d3
        .scaleLinear()
        .range([0, getBBox.width])
        .domain([0, getBBox.width]);
      const y = d3.scaleLinear().range([getBBox.height, 0]).domain([0, 100]);
      const area = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x(function (d) {
          return x(d.x);
        })
        .y0(y(0))
        .y1(function (d) {
          return y(d.y);
        });
      const par = percent > 5 ? percent : 5;
      const halfX = getBBox.width / 2;
      const parVal = 5;
      let points;
      if (par !== 100) {
        points = [
          { x: 0, y: par - (par * (parVal * 4)) / 100 },
          { x: halfX / 2, y: par - (par * (parVal * 3)) / 100 },
          { x: halfX + halfX / 2, y: par + (par * (parVal + parVal)) / 100 },
          { x: halfX + halfX, y: par },
        ];
      } else {
        points = [
          { x: 0, y: par },
          { x: halfX + halfX, y: par },
        ];
      }
      const progressValuePath = newG.selectAll("path.progressPath").data(
        (d) => [d],
        (d) => d
      );
      progressValuePath
        .enter()
        .append("path")
        .merge(progressValuePath)
        .attr("d", (d) => {
          return area(points);
        })
        .attr("clip-path", (d) => `url(#clipPath${d})`)
        .attr("fill", "#D35721");
      progressValuePath.exit().remove();
      g.exit().remove();
    }
  }

  render() {
    const { baby_info_text, id } = this.props;
    const { ratio } = this.state;
    const svgId = id.replace(/=/g, "");
    return (
      <div
        className="D3PictureProgressChart d-flex flex-column justify-content-center align-items-center h-100 w-100"
        ref={(ele) => (this.parentEle = ele)}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            height: "245px",
            width: "245px",
            padding: "0 16px",
            transform: `scale(${ratio})`,
          }}
        >
          <svg id={svgId}>
            <g
              id={svgId + "-info-gcontainer"}
              className={svgId + "-info-gcontainer"}
            >
              <foreignObject x="0" y="0" width="100%" height={this.textHeight}>
                <div className="row">
                  {baby_info_text && (
                    <div
                      id={`baby-info-text-${svgId}`}
                      className="col-12 details-text text-center"
                    />
                  )}
                  <div
                    id={`main-text-${svgId}`}
                    className="col-12 main-text text-center"
                  />
                  <div
                    id={`details-text-${svgId}`}
                    className="col-12 details-text text-center"
                  />
                </div>
              </foreignObject>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default D3PictureProgressChart;
