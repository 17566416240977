import React, { useState } from "react";
import "../../css/custom.css";
import "../../css/components/MemberShipPopup/MemberShipPopup.css";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

import { commitMutation } from "react-relay";

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import {
  getSubscriptionInfo,
  subscriptionInfoSelector,
  userSelector,
} from "../../actions/user";
import { STRIPE_PUBLIC_KEY } from "../../config";
import { StandardTallModalPopup } from "common-components";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const STEP_2 = "Step 2";
const STEP_3 = "Step 3";
const STEP_SPONSOR = "Step Sponsor";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        backgroundColor: "white",
        /*color: '#403f4c',*/
        borderRadius: "3px",
        "::placeholder": {
          color: "#989898",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const APPLY = "Apply";
const WORKING = "Working";
const APPLIED = "Applied";

const _CheckOutForm = ({
  onSuccess,
  subscriptionType,
  email,
  stripe,
  elements,
}) => {
  const [zipCode, setZipCode] = useState("");
  const [showZipCode, setShowZipCode] = useState(false);

  const [updating, setUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [promoCodeMsg, setPromoCodeMsg] = useState("");
  const [annualSubscriptionAmount, setAnnualSubscriptionAmount] =
    useState(false);

  /*const [successApplied, setSuccessApplied] = useState(false);*/
  const [promoCode, setPromoCode] = useState("");

  const [buttonText, setButtonText] = useState(APPLY);

  const handleChange = ({ error }) => {
    if (error) {
      setErrorMsg(error.message);
    }
  };

  const handleSubmit = () => {
    if (!stripe) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }
    if (updating === true) {
      return;
    }
    setUpdating(true);
    setErrorMsg("");
    setSuccessMsg("");

    const cardElement = elements.getElement(CardNumberElement);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
        },
      })
      .then((result) => {
        createCustomer(result.paymentMethod.id, email);
      })
      .catch((err) => {
        console.log(err);
        if (err && err.errors) {
          setErrorMsg(err.errors[0].message);
        }
        setUpdating(false);
      });
  };
  const createCustomer = (paymentMethod, email) => {
    const mutation = graphql`
      mutation MemberShipPopupMutation($input: CreateStripeSubscriptionInput!) {
        createStripeSubscription(input: $input) {
          subscription
          validationErrors {
            key
            message
          }
        }
      }
    `;
    const variables = {
      input: {
        email: email,
        paymentMethod: paymentMethod,
        subscriptionTerm: subscriptionType,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        handleSubscription(
          JSON.parse(response.createStripeSubscription.subscription)
        );
      },
      onError: (err) => {
        console.log("error", err);
        setErrorMsg(err.errors[0].message);
        setUpdating(false);
      },
    });
  };

  const handleSubscription = (subscription) => {
    console.log(subscription);
    const { latest_invoice } = subscription;
    // NOTE: amount_remaining should be 0 at this point unless payment failed somehow
    if (latest_invoice.id) {
      setUpdating(false);
      if (subscription != null && subscription.id != null) {
        setSuccessMsg("Successfully added the subscription.");
        onSuccess(subscriptionType);
      }
      /*
      const { client_secret } = payment_intent;
      stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            email: email
          }
        }
      }).then(function (result) {
        setUpdating(false);
        console.log(result);
        if (result.error) {
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          setErrorMsg(result.error.message + ": " + result.error.code);
          //You cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed.');
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            setSuccessMsg('Successfully added the subscription.');
          } else {
            setErrorMsg('Failed adding the subscription.');
          }
        }
      });
      */
    } else {
      setErrorMsg("Failed adding the subscription.");
      setUpdating(false);
    }
  };

  const applyPromoCode = () => {
    console.log("clicked apply btn");
    if (buttonText !== APPLY) return;
    const mutation = graphql`
      mutation MemberShipPopupApplyPromoCodeMutation(
        $input: ApplyPromoCodeInput!
      ) {
        applyPromoCode(input: $input) {
          annualSubscriptionAmount
          promoCodeMessage
          success
        }
      }
    `;
    let input = {
      promoCode: promoCode,
    };
    setPromoCodeMsg("");
    setAnnualSubscriptionAmount(false);
    /*setSuccessApplied(false);*/
    setButtonText(WORKING);
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        console.log(response, errors);
        if (response.applyPromoCode.success) {
          /*setSuccessApplied(true);*/
          setPromoCodeMsg(response.applyPromoCode.promoCodeMessage);
          setAnnualSubscriptionAmount(
            response.applyPromoCode.annualSubscriptionAmount
          );
          setButtonText(APPLIED);
          setTimeout(() => {
            setButtonText(APPLY);
          }, 10000);
        } else {
          setButtonText(APPLY);
          setPromoCodeMsg(response.applyPromoCode.promoCodeMessage);
        }
        // console.log('Profile update response from server.', JSON.stringify(response));
        // TODO : check if you are getting a user id then reload the component.
      },
      onError: (err) => {
        setButtonText(APPLY);
        console.error(JSON.stringify(err));
        setErrorMsg("Unexpected error");
        //alert('Profile update failed, try again.');
        // TODO: Show error message
      },
    });
  };
  return (
    <div>
      {errorMsg && (
        <div className="alert alert-danger mb-3 rounded" role="alert">
          {errorMsg}
        </div>
      )}
      {successMsg && (
        <div className="alert alert-success mb-3 rounded" role="alert">
          {successMsg}
        </div>
      )}
      <div className="checkout-form">
        <div className={showZipCode ? "show-zip-code show" : "show-zip-code"}>
          <div className="card-elements mb-3">
            <div
              className="input-group"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: "none",
              }}
            >
              <div className="pre-icon">
                <img alt="" src={require("../../images/iconBilling.svg")} />
              </div>
              <div className="d-flex align-items-center flex-grow-1">
                <CardNumberElement
                  className="flex-grow-1"
                  options={createOptions()}
                  onChange={handleChange}
                  onFocus={() => setShowZipCode(true)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div
                className="input-group"
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRight: "none",
                }}
              >
                <div className="pre-icon">
                  <img alt="" src={require("../../images/iconDate.svg")} />
                </div>
                <div className="d-flex align-items-center flex-grow-1">
                  <CardExpiryElement
                    className="flex-grow-1"
                    options={createOptions()}
                    onChange={handleChange}
                    onFocus={() => setShowZipCode(true)}
                  />
                </div>
              </div>
              <div
                className="input-group"
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <div className="pre-icon">
                  <img alt="" src={require("../../images/iconLock.svg")} />
                </div>
                <div className="d-flex align-items-center flex-grow-1">
                  <CardCvcElement
                    className="flex-grow-1"
                    onFocus={() => setShowZipCode(true)}
                    options={createOptions()}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "input-group mb-4 zip-code" + (showZipCode ? " show" : "")
            }
          >
            <div className="pre-icon">
              <img alt="" src={require("../../images/iconArea.svg")} />
            </div>
            <input
              type="text"
              placeholder="ZIP Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
          {subscriptionType === "yearly" && (
            <div className="mb-3">
              <label className="font-weight-bold">Promo Code</label>
              <div className="d-flex align-items-center">
                <input
                  className="flex-grow-1 mr-3"
                  style={{ width: "140px" }}
                  placeholder="Promo Code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button
                  className={
                    buttonText === APPLIED
                      ? "button button-done"
                      : "button button-subtle"
                  }
                  onClick={() => applyPromoCode()}
                  disabled={buttonText === WORKING}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          )}
          {subscriptionType === "monthly" ? (
            <>
              <div className="text-center">
                <span className="font-weight-bold">$34.00</span> monthly
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <span className="font-weight-bold">
                  {annualSubscriptionAmount !== false ? (
                    <>
                      <s>$360.00</s> $
                      {Number(annualSubscriptionAmount / 100.0).toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}{" "}
                    </>
                  ) : (
                    "$360.00"
                  )}
                </span>{" "}
                for 1 year
              </div>
            </>
          )}
          <div className="text-success mb-3 text-center">{promoCodeMsg}</div>
        </div>
      </div>
      <button
        className="button-large button-important w-100"
        disabled={updating}
        onClick={() => handleSubmit()}
      >
        {updating && (
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {updating ? "Updating..." : "SUBMIT"}
      </button>
    </div>
  );
};
const mapState = (state) => ({
  email: userSelector(state).username,
});
const CheckOutForm = connect(mapState)(_CheckOutForm);

const InjectedCheckoutForm = ({ subscriptionType, onSuccess }) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => {
        return (
          <CheckOutForm
            elements={elements}
            stripe={stripe}
            subscriptionType={subscriptionType}
            onSuccess={onSuccess}
          />
        );
      }}
    </ElementsConsumer>
  );
};

const MemberShipPopup = ({
  subscriptionInfo,
  username,
  closePopUp,
  onSuccess,
}) => {
  const hasSubscription =
    subscriptionInfo.userSubscriptionLevel > 0 ||
    (subscriptionInfo.pausedUserSubscriptionLevel > 0 &&
      subscriptionInfo.pauseSubscriptionEndDate != null);
  const allowBuyYearly =
    !hasSubscription ||
    subscriptionInfo.activeCouponCode != null ||
    subscriptionInfo.userSubscriptionTerm === "monthly";
  const allowBuyMonthly =
    !hasSubscription ||
    subscriptionInfo.activeCouponCode != null ||
    subscriptionInfo.userSubscriptionTerm === "yearly";
  const allowUseSponsorCode =
    !hasSubscription || subscriptionInfo.activeCouponCode == null;

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [step, setStep] = useState(STEP_2);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [applying, setApplying] = useState(false);

  const [couponCode, setCouponCode] = useState("");

  const updateCouponCode = (event) => {
      setCouponCode(event.target.value);
  };

  const elementProvider = () => {
    return (
      <>
        {step === STEP_3 && (
          <div className="px-4">
            <Elements stripe={stripePromise}>
              <InjectedCheckoutForm
                subscriptionType={subscriptionType}
                onSuccess={onSuccess}
              />
            </Elements>
          </div>
        )}
      </>
    );
  };

  const pagesArray = [
    {
      showTitle: true,
      showPageItem: false,
      title: "membership",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "membership",
      formContainerClass: "px-3",
      formItems: [
        {
          type: "string",
          parentClassName: "p-b-0 m-0",
          className: "text-center mb-4 font-weight-bold",
          text: "Select a plan:",
          iconClass: "forward-arrow-icon",
        },
        {
          type: "button",
          parentClassName: "p-b-0 m-0",
          className: "button-large w-100 mb-4",
          buttonType: "outlined",
          disabled: !allowBuyYearly,
          handleOnClick: () => {
            setSubscriptionType("yearly");
            setStep(STEP_3);
            changeCurrentPage("checkout-form");
          },
          buttonTitle: "Billed Yearly (Save 10%)",
          iconClass: "forward-arrow-icon",
        },
        {
          type: "button",
          buttonType: "important",
          parentClassName: "p-b-0 m-0",
          className: "button-large w-100 mb-4",
          disabled: !allowBuyMonthly,
          handleOnClick: () => {
            setSubscriptionType("monthly");
            setStep(STEP_3);
            changeCurrentPage("checkout-form");
          },
          buttonTitle: "Billed Monthly",
          iconClass: "forward-arrow-icon",
        },
        {
          type: "button",
          parentClassName: "p-b-0 m-0",
          className: "button-large w-100 mb-4",
          buttonType: "outlined",
          disabled: !allowUseSponsorCode,
          handleOnClick: () => {
            setStep(STEP_SPONSOR);
            changeCurrentPage("sponsored-membership");
          },
          buttonTitle: "My Membership is Sponsored",
          iconClass: "forward-arrow-icon",
        },
        {
          type: "link-button",
          parentClassName: "p-b-0 m-0 d-flex justify-content-center",
          href: "/accountSetting/sponsor",
          text: "Want to become a sponsor?",
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "membership",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "checkout-form",
      formContainerClass: "px-3",
      showStripeCheckout: step === STEP_3,
      checkoutData: {
        stripPublicKey: STRIPE_PUBLIC_KEY,
        subscriptionType,
        onSuccess,
      },
      formItems: [
        {
          type: "stripeElements",
          renderStripeElements: elementProvider,
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "sponsored membership",
      titleClass: "text-center mb-4 font-weight-bold",
      subTitle: username,
      subtitleClass: "text-muted",
      name: "sponsored-membership",
      formContainerClass: "px-3 grid-2-col",
      formItems: [
        {
          type: "alert",
          alertClass: "alert-success rounded",
          alertMsg: successMsg,
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "alert",
          alertClass: "alert-danger",
          alertMsg: errorMsg,
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "string",
          text: (
            <>
              If you have a <b>prepaid membership</b> from a <b>sponsor</b>,
              insert it below and get one free year of BroadStreet
            </>
          ),
          fieldContainerClass: "grid-1-3 mb-4",
        },
        {
          type: "text",
          label: "Sponsor Code",
          labelClassName: "p-b-2",
          placeholder: "Sponsor Code",
          onChange: updateCouponCode,
          fieldContainerClass: "grid-1-2",
        },
        {
          type: "button",
          buttonTitle: applying ? "WORKING" : "APPLY",
          handleOnClick: () => activateCouponCode(),
          disable: applying,
          fieldContainerClass: "grid-2-3 vertical-flex-end pl-3 pb-1",
        },
        {
          type: "string",
          text: (
            <>
              A <b>$360.00</b> value
            </>
          ),
          fieldContainerClass: "grid-1-3 text-center my-4",
        },
        {
          type: "button",
          buttonTitle: "SUMBIT",
          buttonType: "important",
          className: "button-large button-important w-100",
          disabled: true,
          fieldContainerClass: "grid-1-3",
        },
      ],
    },
  ];

  const [currentPage, setCurrentPage] = useState(pagesArray[0]);

  const activateCouponCode = () => {
    const mutation = graphql`
      mutation MemberShipPopupUseSubscriptionCouponMutation(
        $input: UseSubscriptionCouponInput!
      ) {
        useSubscriptionCoupon(input: $input) {
          success
          validationErrors {
            key
            message
          }
        }
      }
    `;
    let input = {
      subscriptionCouponCode: couponCode,
    };

    /*setSuccessActivated(false);*/
    setApplying(true);
    setErrorMsg("");
    setSuccessMsg("");

    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: (response, errors) => {
        console.log(response, errors);
        if (response.useSubscriptionCoupon.success) {
          /*setSuccessActivated(true);*/
          setSuccessMsg("Congratulations your subscription has been activated");
          onSuccess("sponsor");
        } else {
          setErrorMsg(
            response.useSubscriptionCoupon.validationErrors[0].message
          );
        }
        setApplying(false);
        // console.log('Profile update response from server.', JSON.stringify(response));
        // TODO : check if you are getting a user id then reload the component.
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        if (err.errors && err.errors.length > 0 && err.errors[0].message) {
          setErrorMsg(err.errors[0].message);
        } else {
          setErrorMsg("Unexpected error");
        }
        setApplying(false);
        //alert('Profile update failed, try again.');
        // TODO: Show error message
      },
    });
  };

  const goBack = () => {
    if (step === STEP_2) {
      closePopUp();
    } else if (step === STEP_3 || step === STEP_SPONSOR) {
      setStep(STEP_2);
      changeCurrentPage("membership");
    } else {
      closePopUp();
    }
  };

  const changeCurrentPage = (name) => {
    if (pagesArray && pagesArray.length) {
      pagesArray.map((data) => {
        if (data.name === name) {
          setCurrentPage(data);
        }
        return data;
      });
    }
  };

  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={goBack}
          currentPage={currentPage}
          pages={pagesArray}
        />
      ) : null}
    </>
  );
};

const mapStatus = (state) => ({
  subscriptionInfo: subscriptionInfoSelector(state),
  username: state.user.data.username,
});
const mapDispatch = {
  getSubscriptionInfo,
};
export default connect(mapStatus, mapDispatch)(MemberShipPopup);
