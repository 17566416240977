import React from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3HeartChart from './D3HeartChart';
import D3HeartChart2 from './D3HeartChart2';
import D3HeartChart3 from './D3HeartChart3';
import D3HeartChart4 from './D3HeartChart4';

const D3HeartWideChart = (props) => {
  const { id, subComponentType, chartData, showTwitterCardPopup } = props;
  const getSubComponent = () => {
    if (subComponentType === 'heartChart1') {
      const wideChartId = id + (showTwitterCardPopup ? 'D3HeartWideChart' : 'D3HeartTwitterChart');
      if (chartData.label) {
        return <D3HeartChart
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />;
      }
    } else if (subComponentType === 'heartChart2') {
      const wideChartId = id + (showTwitterCardPopup ? 'D3HeartWideChart2' : 'D3HeartTwitterChart2');
      if (chartData.label) {
        return <D3HeartChart2
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />;
      }
    } else if (subComponentType === 'heartChart3') {
      const wideChartId = id + (showTwitterCardPopup ? 'D3HeartWideChart3' : 'D3HeartTwitterChart3');
      if (chartData.label) {
        return <D3HeartChart3
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />;
      }
    } else if (subComponentType === 'heartChart4') {
      const wideChartId = id + (showTwitterCardPopup ? 'D3HeartWideChart4' : 'D3HeartTwitterChart4');
      if (chartData.label) {
        return <D3HeartChart4
          id={wideChartId}
          key={wideChartId}
          chartData={chartData}
          isWide={true}
        />;
      }
    }
  };

  return (
    <>
      {showTwitterCardPopup ? <div className="D3HeartWideChart d-flex w-100 h-100">
          {getSubComponent()}
        </div>
        : <WideCardWrapper {...props} fromChart={true}>
          <div className="D3HeartWideChart d-flex w-100 h-100">
            {getSubComponent()}
          </div>
        </WideCardWrapper>
      }
    </>
  );

};

export default D3HeartWideChart;
