/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadBoardImageInput = {|
  file: any,
  imageList?: ?string,
  parentImageId?: ?string,
  clientMutationId?: ?string,
|};
export type FeaturedImagePopupUploadMutationVariables = {|
  input: UploadBoardImageInput
|};
export type FeaturedImagePopupUploadMutationResponse = {|
  +uploadBoardImage: ?{|
    +success: ?boolean,
    +boardImageNode: ?{|
      +id: string,
      +name: ?string,
      +url: ?string,
    |},
  |}
|};
export type FeaturedImagePopupUploadMutation = {|
  variables: FeaturedImagePopupUploadMutationVariables,
  response: FeaturedImagePopupUploadMutationResponse,
|};
*/


/*
mutation FeaturedImagePopupUploadMutation(
  $input: UploadBoardImageInput!
) {
  uploadBoardImage(input: $input) {
    success
    boardImageNode {
      id
      name
      url
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UploadBoardImageInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadBoardImagePayload",
    "kind": "LinkedField",
    "name": "uploadBoardImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardImageNode",
        "kind": "LinkedField",
        "name": "boardImageNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedImagePopupUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturedImagePopupUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FeaturedImagePopupUploadMutation",
    "operationKind": "mutation",
    "text": "mutation FeaturedImagePopupUploadMutation(\n  $input: UploadBoardImageInput!\n) {\n  uploadBoardImage(input: $input) {\n    success\n    boardImageNode {\n      id\n      name\n      url\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '942e1656538b0bdb6673014ed76d7734';

module.exports = node;
