import React from "react";
import "../../css/components/ChooseAreaPopup/ChooseAreaPopup.css";
import { WideModalPopup } from "common-components";
import { apiBaseUrl } from "../../utility/utilities";

class ChooseAreaPopup extends React.Component {
  closePopUp = () => {
    const { location, history } = this.props;
    const closeURL = location.state && location.state.closeURL;
    if (closeURL) {
      history.push(closeURL.replace("/board", ""));
    } else {
      history.push("/HOME");
    }
  };

  goBack = () => {
    const { history } = this.props;
    if (history.length > 1) {
      history.goBack();
    } else {
      this.closePopUp();
    }
  };

  openNewArea = () => {
    const { history, location, match } = this.props;
    const state = location.state;
    history.push("/areaselector/" + match.params.filterTag, state);
  };

  openMySavedAreas = () => {
    const { history, location, match } = this.props;
    const state = location.state;
    history.push("/mysavedareas/" + match.params.filterTag, state);
  };

  render() {
    const { location, userData, showLogin } = this.props;
    if (userData == null) {
      return (
        <WideModalPopup goBack={this.goBack} closePopUp={this.closePopUp}>
          <div className="ChooseAreaPopup-top-break" />
          <div className="ChooseAreaPopup-login">
            <img
              src={require("../../images/areaselector/download.png")}
              alt="Mappy the Community Map"
              title="Mappy the Community Map"
            />
            Please{" "}
            <span className="ChooseAreaPopup-login-link" onClick={showLogin}>
              Log In or Sign Up
            </span>{" "}
            to create indicator reports.
          </div>
        </WideModalPopup>
      );
    }
    return (
      <WideModalPopup
        goBack={this.goBack}
        className="ChooseAreaPopup"
        closePopUp={this.closePopUp}
      >
        <div className="ChooseAreaPopup-top-break" />
        <div className="ChooseAreaPopup-form-parent">
          <div className="col-12 ChooseAreaPopup-title">
            <h1>Is this for a new or saved area?</h1>
          </div>
          <div className="ChooseAreaPopup-title-break" />
          <div className="row mx-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="card area-selector-card">
                <div className="card-body p-0">
                  <img src={require("../../images/button-new.svg")} alt="" />
                </div>
                <div className="card-footer border-0 px-0">
                  <button
                    className="btn btn-block btn-outline-primary btn-new-area"
                    onClick={this.openNewArea}
                  >
                    New Area
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="card area-selector-card">
                <div className="card-body p-0">
                  <img src={require("../../images/button-saved.svg")} alt="" />
                </div>
                <div className="card-footer border-0 px-0">
                  <button
                    className="btn btn-block btn-outline-primary btn-new-area"
                    onClick={this.openMySavedAreas}
                  >
                    My Saved Areas
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              {location &&
              location.state &&
              location.state.popupType === "EXPLOREMAPS" ? (
                <div className="card area-selector-card">
                  <div className="card-body bg-white p-0" />
                  <div className="card-footer border-0 px-0">
                    <a
                      className="btn link-btn btn-block text-left btn-skip-link"
                      href={apiBaseUrl + "map/"}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Skip,
                      <br />
                      Go to Map Room >>
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">&nbsp;</div>
          </div>
        </div>
      </WideModalPopup>
    );
  }
}

export default ChooseAreaPopup;
