import React, { Component } from "react";
import "../../css/admin.settings.css";
import "../../css/themify-icons/css/themify-icons.css";
import { Link } from "react-router-dom";

class LeftNavigationAccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { match } = this.props;
    // const filterTag = match && match.params ? match.params.filterTag : "";
    return (
      <ul className="metismenu">
        <li className="active">
          <ul className="nav-2-level collapse show in list-group list-group-divider faq-tabs">
            {/* <li className={"list-group-item " + (filterTag ? "" : "selected")}>
              <Link to="/accountSetting">Account</Link>
            </li> */}
            <li className={"list-group-item "}>
              <Link to="/accountSetting/accountOverview">Account Overview</Link>
            </li>
            <li className={"list-group-item "}>
              <Link to="/accountSetting/password">Password</Link>
            </li>
            <li className={"list-group-item "}>
              <Link to="/accountSetting/myProfile">My Public Profile</Link>
            </li>
            {/*
            <li className={'list-group-item ' + (filterTag === 'notifications' ? 'selected' : '')}>
              <Link to="/accountSetting/notifications">Notifications</Link>
            </li>
            */}
            <li className={"list-group-item "}>
              <Link to="/accountSetting/referFriends">Credit & Referrals</Link>
            </li>
            <li className={"list-group-item "}>
              <Link to="/accountSetting/billing">Billing</Link>
            </li>
            <li className={"list-group-item "}>
              <Link to="/accountSetting/sponsor">Become a Sponsor</Link>
            </li>
            {/*
                        <li className={"list-group-item " + (filterTag === "homePage" ? "selected" : "")}>
                            <Link to="/accountSetting/homePage">Homepage</Link>
                        </li>
                         */}
          </ul>
        </li>
      </ul>
    );
  }
}

export default LeftNavigationAccountSettings;
