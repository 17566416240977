import React, { Component } from 'react';
import './css/mapbox-gl.css';
import './App.css';
import Root from './components/Root/index';
import { BrowserRouter } from 'react-router-dom';
import { withCookies, CookiesProvider } from 'react-cookie';

class App extends Component {
  render() {
    return (
      <div className="App">
        <CookiesProvider>
          <BrowserRouter>
            <Root/>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    );
  }
}

export default withCookies(App);
