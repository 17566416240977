import React, { Component } from 'react';
import D3ThermometerChart from '../Card/D3ThermometerChart';
import thermometer from '../../images/thermometer-blank.svg';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3ThermometerWideChart extends Component {
  render() {
    const { id, thermoData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3ThermometerWideChart' : 'D3ThermometerTwitterChart');
    const content = (
      <div className='D3ThermometerWideChart d-flex w-100 h-100 position-relative'>
        {(thermoData && thermoData.data) &&
        <D3ThermometerChart
          id={wideChartId}
          key={wideChartId}
          image={thermometer}
          imageHeight={thermoData.imageHeight}
          imageWidth={thermoData.imageWidth}
          thermoData={thermoData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3ThermometerWideChart;
