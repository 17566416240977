// This file is used to render main Project Board , all of the components used in the project
// boards are imported here : - import {SortableContainer, SortableElement, arrayMove} from
// 'react-sortable-hoc'
import React, { Component } from "react";
import CloseUp from "../CloseUpView/CloseUp";
import environment from "../../RelayEnvironment";
import "../../css/styles.css";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { Responsive } from "react-grid-layout";
import WidthProvider from "./ProjectBoardWidthProvider";
import { reportToolCardArray } from "../../utility/utilities";
import {
  ShareCardWithSocial,
  ShareCardWithEmbedded,
} from "../CommonComponents/ShareCardPopUp";
import SelectThemePopUp from "../CommonComponents/SelectThemePopUp";
import { Editor, getEventTransfer } from "slate-react";
import isUrl from "is-url";
import Html from "slate-html-serializer";
import { isKeyHotkey } from "is-hotkey";
import { Button, Icon, Menu } from "./components";
import styled from "@emotion/styled";
import { Value } from "slate";
import NewCrossTagPopUp from "../CommonComponents/NewCrossTagPopUp";
import RemoveCardPopUp from "../CommonComponents/RemoveCardPopUp";
import ReactDOM from "react-dom";
import SectionCard from "./SectionCard";
import * as _ from "lodash";
import {
  AnnouncementPopup,
  SelectAreaPopup,
  ToolTip,
  ButtonComponent,
} from "common-components";
import WideCardPopup from "../CommonComponents/WideCardPopup";
import SelectAreaTagsPopUp from "../CommonComponents/SelectAreaTagsPopUp";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import CardButtonsPopUp from "../CommonComponents/CardButtonsPopUp";
const placeHolderImage = require("../../images/uni-square.png");
const placeHolderBannerImage = require("../../images/uni-banner.png");

// Slate integration
const initialValue = {
  document: {
    nodes: [
      {
        object: "block",
        type: "paragraph",
        nodes: [{ object: "text", leaves: [{ text: "" }] }],
      },
    ],
  },
};

const DEFAULT_NODE = "paragraph";
const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");
const isCodeHotkey = isKeyHotkey("mod+`");

const BLOCK_TAGS = {
  blockquote: "quote",
  p: "paragraph",
  pre: "code",
};

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: "block",
          type: type,
          data: {
            className: el.getAttribute("class"),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === "block") {
        switch (obj.type) {
          case "paragraph":
            return <p className={obj.data.get("className")}>{children}</p>;
          default:
            break;
        }
      }
    },
  },
];

// Create a new serializer instance with our `rules` from above.
const html = new Html({ rules });

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// var arrayCardPosition =  [
//     {"x":0,"y":0,"w":0.8,"h":10.5,"i":"Qm9hcmRDYXJkVXNlTm9kZToxNQ=="},
//     {"x":1,"y":0,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToyMA=="},
//     {"x":2,"y":0,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToxOA=="},
//     {"x":3,"y":0,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToxMA=="},
//     {"x":4,"y":0,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToyMw=="},
//     {"x":0,"y":1,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToy"},
//     {"x":1,"y":1,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToyMg=="},
//     {"x":2,"y":1,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToxOQ=="},
//     {"x":3,"y":1,"w":0.8,"h":9.35,"i":"Qm9hcmRDYXJkVXNlTm9kZToyMQ=="}
// ]

class NewSection extends Component {
  render() {
    return (
      <div>
        <div className="ProjectBoard-blanck_data">
          <div className="cardContainer-blanck-card">
            <div className="cardcontainer-txt">
              Map and Story Cards You Save Go Here
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const StyledMenu = styled(Menu)`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #f4f4f4;
  border: 2px solid #989898;
  border-radius: 5px;
  transition: opacity 0.75s;
`;

class HoverMenu extends React.Component {
  render() {
    const {
      className,
      innerRef,
      renderMarkButton,
      renderBlockButton,
      renderLinkButton,
    } = this.props;
    const root = window.document.getElementById("projectboardMainId");

    if (root) {
      return ReactDOM.createPortal(
        <StyledMenu className={className} innerRef={innerRef}>
          {renderMarkButton("bold", "format_bold")}
          {renderMarkButton("italic", "format_italic")}
          {renderMarkButton("underlined", "format_underlined")}
          {renderBlockButton("heading-one", "looks_one")}
          {renderBlockButton("heading-two", "looks_two")}
          {renderBlockButton("block-quote", "format_quote")}
          {renderBlockButton("numbered-list", "format_list_numbered")}
          {renderBlockButton("bulleted-list", "format_list_bulleted")}
          {renderLinkButton("link", "link")}
        </StyledMenu>,
        root
      );
    } else {
      return null;
    }
  }
}

function unwrapLink(editor) {
  editor.unwrapInline("link");
}

function wrapLink(editor, href) {
  editor.wrapInline({
    type: "link",
    data: { href },
  });

  editor.moveToEnd();
}

class Section extends Component {
  static defaultProps = {
    isResizable: false,
    items: 20,
    rowHeight: 30,
    refreshLayout: false,
  };

  constructor(props) {
    super(props);
    let layout = JSON.parse(props.sectionData.node.layout);
    layout.forEach((item) => {
      if (item.h === 5) {
        item.h = 5.4;
      }
    });
    this.state = {
      isPopUpVisible: false,
      isToolTipVisible: false,
      xOrigin: 0,
      yOrigin: 0,
      text: "", // text for tooltip

      xOriginTagCrossPopUp: 0,
      yOriginTagCrossPopUp: 0,
      isCloseUpViewVisible: false,

      isShareCardPopUpVisible: false,
      xOriginShareCardPopUp: 0,
      yOriginShareCardPopUp: 0,

      isShareCardWithSocialVisible: false,
      xOriginShareCardWithSocial: 0,
      yOriginShareCardWithSocial: 0,

      isShareCardWithEmbeddedVisible: false,
      xOriginShareCardWithEmbedded: 0,
      yOriginShareCardWithEmbedded: 0,

      isSelectThemePopUpVisible: false,
      xOriginSelectThemePopUp: 0,
      yOriginSelectThemePopUp: 0,

      isSaveCardsPopUpVisible: false,

      isEditHashTagPopUpVisible: false,
      xOriginEditHashTagPopUp: 0,
      yOriginEditHashTagPopUp: 0,

      isReportErrorPopUpVisible: false,
      xReportErrorPopupPopUp: 0,
      yOriginReportErrorPopup: 0,

      selectedCardForWideView: 0,
      isEditMode: props.isEditingEnabled,
      layout: layout,
      sectionLayout: props.sectionData.node.sectionLayout,
      savedLayout: [],
      editedLayout: [],
      layoutVersion: 0,

      data: [],
      selectedCardForMutation: null, // This will store the id of the card every time a popup is
      // selected.
      value: html.deserialize(`<p>${props.sectionData.node.name}</p>`), // the title for the
      // section are saved here
      // value: props.sectionData.node.name === '' ? html.deserialize(' ') :
      // html.deserialize(`<p>${props.sectionData.node.name}</p>`) , // the title for the section
      // are saved here
      sectionDescValue:
        props.sectionData.node.description === "" ||
        props.sectionData.node.description === null ||
        props.sectionData.node.description.indexOf("{") === -1
          ? Value.fromJSON(initialValue)
          : Value.fromJSON(JSON.parse(props.sectionData.node.description)),
      sectionTitleUpdated: null,
      sectionDescUpdated: null,

      isDeleteSectionPopUpVisible: false,

      isNewCrossTagPopUpVisible: false,
      xOriginNewCrossTagPopUp: 0,
      yOriginNewCrossTagPopUp: 0,

      isRemoveCardPopUpVisible: false,
      xOriginRemoveCardPopUp: 0,
      yOriginRemoveCardPopUp: 0,

      removeCard: false,
      deleteId: "",
      bannerImage: props.sectionData.node.image1
        ? props.sectionData.node.image1.url
        : placeHolderBannerImage,
      sectionImage: props.sectionData.node.image1
        ? props.sectionData.node.image1.url
        : placeHolderImage,
      fromImagePopup: "",
      updateSectionId: "",
      image1Id: null,
      isSavedTooltip: false,
      savedCardId: "",
      swapPosition: null,
      selectedCard: null,
      isOpenServiceSelector: false,
      showCardButtonModal: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { deleteId } = this.state;
    const { isEditingEnabled, sectionData } = this.props;
    if (
      nextProps.isEditingEnabled !== isEditingEnabled &&
      isEditingEnabled === true
    ) {
      if (deleteId !== sectionData.node.id) {
        this.handleUpdateSection(nextProps, nextState);
      }
    }
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fromImagePopup, updateSectionId } = this.state;
    const { avatarEditor, imageId, onSectionUpdate, sectionData } = this.props;
    if (
      nextProps.avatarEditor &&
      nextProps.avatarEditor !== avatarEditor &&
      updateSectionId === nextProps.sectionData.node.id
    ) {
      console.log(
        "need to update image for section",
        updateSectionId,
        fromImagePopup
      );
      console.log("parentImageId", nextProps.parentImageId);
      if (
        !!nextProps.avatarEditor
          .getImage()
          .toDataURL()
          .match('data:image\\/([a-zA-Z]*);base64,([^\\"]*)') ||
        nextProps.parentImageId != null
      ) {
        fetch(nextProps.avatarEditor.getImage().toDataURL())
          .then((res) => res.blob())
          .then((blob) => {
            if (fromImagePopup === "banner") {
              console.log("upload section banner image");
              this.setState(
                {
                  bannerImage: nextProps.avatarEditor.getImage().toDataURL(),
                  fromImagePopup: "",
                  updateSectionId: "",
                },
                () => {
                  this.handleUploadImage(
                    {
                      0: new File([blob], "ProjectImg.png", {
                        type: "image/png",
                      }),
                    },
                    "banner"
                  );
                }
              );
            } else if (fromImagePopup === "sectionImage") {
              console.log("upload section sqaurefeature image");
              this.setState(
                {
                  sectionImage: nextProps.avatarEditor.getImage().toDataURL(),
                  fromImagePopup: "",
                  updateSectionId: "",
                },
                () => {
                  this.handleUploadImage(
                    {
                      0: new File([blob], "ProjectImg.png", {
                        type: "image/png",
                      }),
                    },
                    "squarefeature"
                  );
                }
              );
            }
          });
      } else {
        console.log("invalid image format");
      }
    } else if (
      nextProps.imageId &&
      nextProps.imageId !== imageId &&
      updateSectionId === nextProps.sectionData.node.id
    ) {
      if (fromImagePopup === "banner") {
        this.setState(
          {
            bannerImage: nextProps.avatarImage,
            fromImagePopup: "",
            updateSectionId: "",
          },
          () => {
            onSectionUpdate(sectionData.node.id, { image1: nextProps.imageId });
          }
        );
      }
      if (fromImagePopup === "sectionImage") {
        this.setState(
          {
            sectionImage: nextProps.avatarImage,
            fromImagePopup: "",
            updateSectionId: "",
          },
          () => {
            onSectionUpdate(sectionData.node.id, { image1: nextProps.imageId });
          }
        );
      }
    }

    if (!_.isEqual(sectionData, nextProps.sectionData)) {
      try {
        let layout = JSON.parse(nextProps.sectionData.node.layout);
        layout.forEach((item) => {
          if (item.h === 5) {
            item.h = 5.4;
          }
        });
        this.setState({
          layout,
          sectionLayout: nextProps.sectionData.node.sectionLayout,
        });
      } catch (e) {
        console.error(e);
      }
      var cardsArray = [];
      nextProps.sectionData.node.boardCardUses.edges.forEach((item) => {
        var mutableObject = Object.assign({}, item);
        mutableObject.isUpdate = false;
        cardsArray.push(mutableObject);
      });
      this.setState({
        data: cardsArray,
      });
    }
  }

  /********hover menue editor start****************/

  renderEditor = (props, editor) => {
    const { isEditingEnabled } = this.props;
    if (isEditingEnabled) {
      return (
        <React.Fragment>
          {props.children}
          <HoverMenu
            innerRef={(menu) => (this.menu = menu)}
            editor={editor}
            onClickMark={this.onClickMark}
            onClickLink={this.onClickLink}
            renderBlockButton={this.renderBlockButton}
            renderMarkButton={this.renderMarkButton}
            renderLinkButton={this.renderLinkButton}
          />
        </React.Fragment>
      );
    } else {
      return;
    }
  };

  updateMenu = () => {
    const menu = this.menu;
    if (!menu) return;

    const { sectionDescValue } = this.state;
    const { fragment, selection } = sectionDescValue;
    if (selection.isBlurred || selection.isCollapsed || fragment.text === "") {
      menu.removeAttribute("style");
      return;
    }

    const native = window.getSelection();
    const range = native.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    menu.style.opacity = 1;
    menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;
    menu.style.left = `${
      rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2
    }px`;
  };

  showCardAreaPopUp = (cardId, savedAreaId) => {
    this.props.setCardId(cardId);
    this.setState({
      showAreaCardPopup: true,
      cardId,
      savedAreaId,
      selectedCardForMutation: cardId,
    });
  };

  hideCardAreaPopUp = () => {
    this.setState({
      showAreaCardPopup: false,
      cardId: "",
    });
  };

  hasMark = (type) => {
    const { sectionDescValue } = this.state;
    return sectionDescValue.activeMarks.some((mark) => mark.type === type);
  };

  hasBlock = (type) => {
    const { sectionDescValue } = this.state;
    return sectionDescValue.blocks.some((node) => node.type === type);
  };

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type);
    return (
      <Button
        active={isActive}
        onMouseDown={(event) => this.onClickMark(event, type)}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  renderBlockButton = (type, icon) => {
    let isActive = this.hasBlock(type);
    if (["numbered-list", "bulleted-list"].includes(type)) {
      const { sectionDescValue } = this.state;
      const parent = sectionDescValue.document.getParent(
        sectionDescValue.blocks.first().key
      );
      isActive = this.hasBlock("list-item") && parent && parent.type === type;
    }

    return (
      <Button
        active={isActive}
        onMouseDown={(event) => this.onClickBlock(event, type)}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  renderLinkButton = (type, icon) => {
    return (
      <Button
        reversed
        active={this.hasLinks() === true ? this.hasLinks() : null}
        onMouseDown={(event) => this.onClickLink(event, type)}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  hasLinks = () => {
    const { sectionDescValue } = this.state;
    return sectionDescValue.inlines.some((inline) => inline.type === "link");
  };

  onClickLink = (event) => {
    event.preventDefault();
    const { sectionDescValue } = this.state;
    const hasLinks = this.hasLinks();
    const change = sectionDescValue.change();
    var href = "";
    if (hasLinks) {
      change.call(unwrapLink);
    } else if (sectionDescValue.selection.isExpanded) {
      href = window.prompt("Enter the URL of the link:");
      if (href === null) {
        return;
      }
      if (
        !(
          href.startsWith("http") ||
          href.startsWith("mailto:") ||
          href.startsWith("ftp:")
        )
      ) {
        href = "https://" + href;
      }

      change.call(wrapLink, href);
    } else {
      href = window.prompt("Enter the URL of the link:");
      if (href === null) {
        return;
      }
      const text = window.prompt("Enter the text for the link:");

      if (text === null) {
        return;
      }
      if (
        !(
          href.startsWith("http") ||
          href.startsWith("mailto:") ||
          href.startsWith("ftp:")
        )
      ) {
        href = "https://" + href;
      }
      change
        .insertText(text)
        .moveFocusBackward(text.length)
        .call(wrapLink, href);
    }
    this.onChange(change);
  };

  onPaste = (event, change) => {
    if (change.value.selection.isCollapsed) return;

    const transfer = getEventTransfer(event);
    const { type, text } = transfer;
    if (type !== "text" && type !== "html") return;
    if (!isUrl(text)) return;

    if (this.hasLinks()) {
      change.call(unwrapLink);
    }

    change.call(wrapLink, text);
    return true;
  };
  /********hover menue editor end****************/

  renderNodeDesc = (props) => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case "block-quote":
        return <blockquote {...attributes}>{children}</blockquote>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "heading-one":
        return <h1 {...attributes}>{children}</h1>;
      case "heading-two":
        return <h2 {...attributes}>{children}</h2>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return (
          <ul>
            <ol {...attributes}>{children}</ol>
          </ul>
        );
      case "link": {
        const { data } = node;
        const href = data.get("href");
        return (
          <a {...attributes} href={href}>
            {children}
          </a>
        );
      }
      default:
        break;
    }
  };

  renderMark = (props) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case "bold":
        return <b {...attributes}>{children}</b>;
      case "code":
        return <code {...attributes}>{children}</code>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      default:
        break;
    }
  };

  onChange = ({ value }) => {
    const { onSectionUpdate, sectionData } = this.props;
    this.setState({ sectionDescValue: value, sectionDescUpdated: value });
    if (this.descriptionInitialized === true) {
      onSectionUpdate(sectionData.node.id, { description: value });
    } else {
      this.descriptionInitialized = true;
    }
  };

  onKeyDown = (event, change) => {
    let mark;
    if (isBoldHotkey(event)) {
      mark = "bold";
    } else if (isItalicHotkey(event)) {
      mark = "italic";
    } else if (isUnderlinedHotkey(event)) {
      mark = "underlined";
    } else if (isCodeHotkey(event)) {
      mark = "code";
    } else {
      return;
    }
    event.preventDefault();
    change.toggleMark(mark);
    return true;
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    const { sectionDescValue } = this.state;
    const change = sectionDescValue.change().toggleMark(type);
    this.onChange(change);
  };

  onClickBlock = (event, type) => {
    event.preventDefault();
    const { sectionDescValue } = this.state;
    const change = sectionDescValue.change();
    const { document } = sectionDescValue;

    // Handle everything but list buttons.
    if (type !== "bulleted-list" && type !== "numbered-list") {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock("list-item");

      if (isList) {
        change
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        change.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock("list-item");
      const isType = sectionDescValue.blocks.some((block) => {
        return !!document.getClosest(
          block.key,
          (parent) => parent.type === type
        );
      });

      if (isList && isType) {
        change
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        change
          .unwrapBlock(
            type === "bulleted-list" ? "numbered-list" : "bulleted-list"
          )
          .wrapBlock(type);
      } else {
        change.setBlocks("list-item").wrapBlock(type);
      }
    }
    this.onChange(change);
  };

  renderNode = (props) => {
    switch (props.node.type) {
      case "paragraph":
        return <p {...props.attributes}>{props.children}</p>;
      default:
        break;
    }
  };

  onChangeEditableText = ({ value }) => {
    const { onSectionUpdate, sectionData } = this.props;
    const { document } = this.state.value;
    // When the document changes, save the serialized HTML to Local Storage.
    if (value.document !== document) {
      const string = html.serialize(value);
      // TODO :  persist the value of string to the api
      var stringWithoutTag = string.replace(/<(?:.|\n)*?>/gm, ""); // This regular expression
      // removes html from string
      if (stringWithoutTag.length > 50) {
        alert("Only 50 characters allowed!");
        return;
      }
      onSectionUpdate(sectionData.node.id, { name: stringWithoutTag });
    }
    this.setState({ value: value, sectionTitleUpdated: value });
  };

  // api update section in the board
  handleUpdateSection = (nextProps, nextState) => {
    /*return null;*/
    const {
      sectionTitleUpdated,
      sectionDescUpdated,
      value,
      image1Id,
      sectionDescValue,
    } = this.state;
    const { sectionData, onSectionUpdate } = this.props;
    if (sectionTitleUpdated !== null || sectionDescUpdated !== null) {
      const titleString =
        sectionTitleUpdated === null
          ? html.serialize(value)
          : html.serialize(sectionTitleUpdated);
      const titleStringWithoutHtml = titleString.replace(/<(?:.|\n)*?>/gm, "");
      const mutation = graphql`
        mutation SectionDMutation($input: UpdateBoardSectionInput!) {
          updateBoardSection(input: $input) {
            boardSectionNode {
              id
              board {
                hasUnpublishedChanges
              }
            }
          }
        }
      `;
      const variables = {
        input: {
          image1Id: image1Id,
          sectionId: sectionData.node.id,
          name: titleStringWithoutHtml,
          description:
            sectionTitleUpdated === null
              ? JSON.stringify(sectionDescValue)
              : JSON.stringify(sectionDescUpdated),
        },
      };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (
            response &&
            response.updateBoardSection &&
            response.updateBoardSection.boardSectionNode
          ) {
            onSectionUpdate(
              response.updateBoardSection.boardSectionNode.board
                .hasUnpublishedChanges
            );
            if (nextState.swapPosition) {
              this.moveSectionPosition(nextState.swapPosition, nextProps);
            }
          }
        },
        onError: (err) => console.error(err),
      });
    }
  };

  handleUploadImage = (file, imageList) => {
    const { parentImageId, onSectionUpdate, sectionData } = this.props;
    const mutation = graphql`
      mutation SectionEMutation($input: UploadBoardImageInput!) {
        uploadBoardImage(input: $input) {
          success
          boardImageNode {
            id
            name
            url
          }
        }
      }
    `;
    var uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    const variables = {
      input: {
        file: null,
        imageList: imageList,
        parentImageId: parentImageId,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      uploadables,
      onCompleted: (response, errors) => {
        if (imageList === "banner") {
          this.setState({
            image1Id: response.uploadBoardImage.boardImageNode.id,
          });
        } else {
          this.setState({
            image1Id: response.uploadBoardImage.boardImageNode.id,
          });
        }
        console.log("section image changed");
        onSectionUpdate(sectionData.node.id, {
          image1: response.uploadBoardImage.boardImageNode.id,
        });
      },
      onError: (err) => console.error(err),
    });
  };

  componentDidMount() {
    // iterating through every card object in array and adding isUpdate property to each card
    // object , we will need it to re-render the specific card
    const { sectionData } = this.props;
    var cardsArray = [];
    sectionData.node.boardCardUses.edges.map((item) => {
      var mutableObject = Object.assign({}, item);
      mutableObject.isUpdate = false;
      cardsArray.push(mutableObject);
      return cardsArray;
    });
    this.setState({
      data: cardsArray,
    });
    this.updateMenu();
  }

  componentDidUpdate() {
    const { refreshLayout, onRefreshLayout } = this.props;
    if (refreshLayout) {
      // send message to react-grid-layout WidthProvider to reposition
      window.postMessage("need to refresh grid layout", "*");
      onRefreshLayout();
    }
    this.updateMenu();
  }

  // show the popup on the start tag cross
  hideUnHidePopUp = (x, y, popUpType, cardId) => {
      const { data } = this.state;
    if (popUpType === "CloseTag") {
      this.props.setCardId(cardId);
      this.setState({
        isPopUpVisible: true,
        xOriginTagCrossPopUp: x,
        yOriginTagCrossPopUp: y,
        selectedCardForMutation: cardId,
      });
    } else if (popUpType === "MoreInfo") {
      let selectedCard = data.find((item) => {
        return item.node.id === cardId;
      });
      this.props.setCardId(cardId);
      this.setState({
        isToolTipVisible: false,
        selectedCardForMutation: cardId,
        selectedCard,
      });
    } else if (popUpType === "EditTags") {
    this.props.setCardId(cardId);
    this.setState({
        isEditHashTagPopUpVisible: true,
        xOriginEditHashTagPopUp: x,
        yOriginEditHashTagPopUp: y,
        selectedCardForMutation: cardId,
      });
    }
  };

  onCardButtonClick = (cardId) => {
    const { sectionData } = this.props;
    let selectedCard = null;
    sectionData &&
      sectionData.node &&
      sectionData.node.boardCardUses &&
      sectionData.node.boardCardUses.edges &&
      sectionData.node.boardCardUses.edges.forEach((item, index) => {
        if (item.node.id === cardId) {
          selectedCard = item;
        }
        return selectedCard;
      });
    this.props.setCardId(cardId);
    this.setState({
      showCardButtonModal: true,
      selectedCardForMutation: cardId,
      selectedCard,
    });
  };

  onCloseCardButtonModal = () => {
    this.setState({
      showCardButtonModal: false,
      isSaveCardsPopUpVisible: false,
    });
  };

  // hide the popup on the start tag cross
  hidePopUp = (popUpType, isSavedTooltip, savedCardId, closed = false) => {
    const {
      showAreaCardPopup,
      isPopUpVisible,
      isShareCardPopUpVisible,
      isShareCardWithSocialVisible,
      isShareCardWithEmbeddedVisible,
      isSelectThemePopUpVisible,
      isSaveCardsPopUpVisible,
      isEditHashTagPopUpVisible,
      isReportErrorPopUpVisible,
      isNewCrossTagPopUpVisible,
      isRemoveCardPopUpVisible,
      isOpenServiceSelector,
    } = this.state;
    const { handleUpdate } = this.props;
    if (showAreaCardPopup) {
      this.setState({ showAreaCardPopup: false });
    }
    if (isPopUpVisible && !isOpenServiceSelector) {
      this.setState({ isPopUpVisible: false });
    }
    if (isShareCardPopUpVisible) {
      this.setState({ isShareCardPopUpVisible: false });
    }
    if (isShareCardWithSocialVisible) {
      this.setState({ isShareCardWithSocialVisible: false });
    }
    if (isShareCardWithEmbeddedVisible) {
      this.setState({ isShareCardWithEmbeddedVisible: false });
    }
    if (isSelectThemePopUpVisible) {
      this.setState({ isSelectThemePopUpVisible: false });
    }
    if (isSaveCardsPopUpVisible && closed) {
      handleUpdate(true);
      this.setState({
        isSaveCardsPopUpVisible: false,
        isSavedTooltip,
        savedCardId,
      });
    }
    if (isEditHashTagPopUpVisible) {
      this.setState({ isEditHashTagPopUpVisible: false });
    }
    if (isReportErrorPopUpVisible) {
      this.setState({ isReportErrorPopUpVisible: false });
    }
    if (isNewCrossTagPopUpVisible) {
      this.setState({ isNewCrossTagPopUpVisible: false });
    }
    if (isRemoveCardPopUpVisible) {
      this.setState({ isRemoveCardPopUpVisible: false });
    }
  };

  // show tooltip on various element
  showToolTip = (x, y, text) => {
    this.setState({
      isToolTipVisible: true,
      xOrigin: x,
      yOrigin: y,
      text: text,
    });
  };

  // hide tooltip on various element
  hideToolTip = () => {
    const { isToolTipVisible } = this.state;
    if (isToolTipVisible) {
      this.setState({
        isToolTipVisible: false,
        isSavedTooltip: false,
        savedCardId: "",
      });
    }
  };

  // show and hide the card closeup view
  handleCloseUpView = (index) => {
    const { isCloseUpViewVisible } = this.state;
    if (isCloseUpViewVisible === false) {
      this.setState({
        isCloseUpViewVisible: true,
        selectedCardForWideView: index,
      });
    } else {
      this.setState({ isCloseUpViewVisible: false });
    }
  };

  // show  ShareCardPopUp
  handleShareCardPopUp = (x, y) => {
    const { isShareCardPopUpVisible } = this.state;
    if (isShareCardPopUpVisible === false) {
      this.setState({
        isShareCardPopUpVisible: true,
        xOriginShareCardPopUp: x,
        yOriginShareCardPopUp: y,
      });
    }
  };

  // show  ShareCardWithSocial
  handleShareCardWithSocial = (x, y) => {
    const { isShareCardWithSocialVisible } = this.state;
    if (isShareCardWithSocialVisible === false) {
      this.setState({
        isShareCardPopUpVisible: false,
        isShareCardWithSocialVisible: true,
        xOriginShareCardWithSocial: x,
        yOriginShareCardWithSocial: y,
      });
    }
  };

  // show ShareCardWithEmbedded
  handleShareCardWithEmbedded = (x, y) => {
    const { isShareCardWithEmbeddedVisible } = this.state;
    if (isShareCardWithEmbeddedVisible === false) {
      this.setState({
        isShareCardWithEmbeddedVisible: true,
        isShareCardPopUpVisible: false,
        xOriginShareCardWithEmbedded: x,
        yOriginShareCardWithEmbedded: y,
      });
    }
  };

  // show SelectTheme popup
  handleSelectThemePopUp = (x, y) => {
    const { isSelectThemePopUpVisible } = this.state;
    if (isSelectThemePopUpVisible === false) {
      this.setState({
        xOriginSelectThemePopUp: x,
        yOriginSelectThemePopUp: y,
        isSelectThemePopUpVisible: true,
      });
    }
  };

  // show SaveCardsPopUp
  handleSaveCardsPopUp = (x, y, cardId) => {
      const { isSaveCardsPopUpVisible } = this.state;
    if (isSaveCardsPopUpVisible === false) {
      this.props.setCardId(cardId);
      this.setState({
        isSaveCardsPopUpVisible: true,
        selectedCardForMutation: cardId,
        selectedCard: this.getSelectedCard(cardId),
      });
    }
  };

  //ReportErrorPopup
  handleReportErrorPopUp = (x, y) => {
    const { isReportErrorPopUpVisible } = this.state;
    if (isReportErrorPopUpVisible === false) {
      this.setState({
        xOriginReportErrorPopUp: x,
        yOriginReportErrorPopUp: y,
        isReportErrorPopUpVisible: true,
      });
    }
  };

  handleDeleteSectionPopUp = () => {
    const { isDeleteSectionPopUpVisible } = this.state;
    if (isDeleteSectionPopUpVisible === false) {
      this.setState({
        isDeleteSectionPopUpVisible: true,
      });
    } else {
      this.setState({
        isDeleteSectionPopUpVisible: false,
      });
    }
  };

  moveSectionPosition = (swapPosition, props) => {
    const {
      sectionData: { node },
      sectionArray,
      isLoginPopUpVisible,
      onPositionChange,
      handleLoginPopUp,
    } = props;
    const sectionId = node.id;
    let sectionId1 = null;
    let sectionId2 = null;
    this.setState({ swapPosition: null });
    sectionArray.forEach((item, index) => {
      if (item.node.id === sectionId) {
        if (swapPosition === "up" && node.sectionPosition !== 0) {
          sectionId1 = sectionId;
          sectionId2 = sectionArray[index - 1].node.id;
        } else if (
          swapPosition === "down" &&
          node.sectionPosition !== sectionArray.length - 1
        ) {
          sectionId2 = sectionId;
          sectionId1 = sectionArray[index + 1].node.id;
        }
      }
    });

    if (sectionId1 && sectionId2) {
      const mutation = graphql`
        mutation SectionSwapPositionMutation(
          $input: SwapBoardSectionPositionsInput!
        ) {
          swapBoardSectionPositions(input: $input) {
            boardSectionNode1 {
              id
              sectionPosition
            }
            boardSectionNode2 {
              id
              sectionPosition
            }
          }
        }
      `;
      const variables = {
        input: {
          sectionId1: sectionId1,
          sectionId2: sectionId2,
        },
      };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (
            response.hasOwnProperty("swapBoardSectionPositions") &&
            response["swapBoardSectionPositions"]
          ) {
            onPositionChange(response["swapBoardSectionPositions"]);
          }
        },
        onError: (err) => {
          console.error(err);
          if (
            err.errors &&
            err.errors.length &&
            err.errors[0].message === "UserLoginRequired" &&
            !isLoginPopUpVisible
          ) {
            //show login popup
            handleLoginPopUp();
          }
        },
      });
    }
  };

  handleMoveSectionPosition = (swapPosition) => {
    const { isEditingEnabled, handleSliderValue } = this.props;
    if (isEditingEnabled) {
      this.setState({ swapPosition });
      handleSliderValue(null);
    } else {
      this.moveSectionPosition(swapPosition, this.props);
    }
  };

  handleLayoutChange = (layout, allLayouts) => {
    const { onCardsLayoutChange, sectionData } = this.props;
    this.setState({ editedLayout: layout });
    const sortedLayout = layout
      .slice()
      .sort((a, b) => (a.x === b.x ? a.y - b.y : a.x - b.x));
    var currentRow = 0;
    var currentColumn = -1;
    var updatedLayout = [];
    for (var i = 0; i < sortedLayout.length; i++) {
      if (sortedLayout[i].x > currentColumn) {
        currentColumn = sortedLayout[i].x;
        currentRow = 0;
      } else {
        currentRow++;
      }
      updatedLayout[i] = {
        x: sortedLayout[i].x,
        y: currentRow,
        w: sortedLayout[i].w,
        h: sortedLayout[i].h,
        i: sortedLayout[i].i,
      };
    }
    onCardsLayoutChange(sectionData.node.id, updatedLayout);
  };
  // Calls back with breakpoint and new # cols
  onBreakpointChange = (newBreakpoint, newCols) => {
    //console.log("onBreakpointChange: " + newBreakpoint + ", newCols: " + newCols);
    //this.setState({numberOfColumns: newCols});
  };

  nextCardView = () => {
    const { data, selectedCardForWideView } = this.state;
    if (selectedCardForWideView !== data.length - 1) {
      this.setState({ selectedCardForWideView: selectedCardForWideView + 1 });
    }
  };

  previousCardView = () => {
    const { selectedCardForWideView } = this.state;
    if (selectedCardForWideView !== 0) {
      this.setState({ selectedCardForWideView: selectedCardForWideView - 1 });
    }
  };

  handleReRenderCard = (cardId) => {
    var arrayData = [];
    const { data } = this.state;
    for (var i = 0; i < data.length; i++) {
      if (data[i].node.id === cardId) {
        var changedObject = data[i];
        changedObject.isUpdate === true
          ? (changedObject.isUpdate = false)
          : (changedObject.isUpdate = true);
        arrayData.push(changedObject);
      } else {
        arrayData.push(data[i]);
      }
    }
    this.setState(
      {
        data: arrayData,
      },
      () => {
        this.hidePopUp();
      }
    );
  };

  handleArrayOfTagsOfCardObject = (obj) => {
    this.setState({
      arrayOfTagsOfCardObject: obj,
    });
  };

  handleDeleteSection = () => {
    const {
      sectionData,
      handleUpdateBoard,
      isLoginPopUpVisible,
      handleLoginPopUp,
    } = this.props;
    const mutation = graphql`
      mutation SectionBMutation($input: DeleteBoardSectionInput!) {
        deleteBoardSection(input: $input) {
          sectionDeleted
        }
      }
    `;
    const variables = {
      input: { sectionId: sectionData.node.id },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        this.setState({
          deleteId: sectionData.node.id,
        });
        handleUpdateBoard(sectionData.node.id);
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired" &&
          !isLoginPopUpVisible
        ) {
          //show login popup
          this.setState({ isLoginPopUpVisible: true });
          handleLoginPopUp();
        }
      },
    });
  };

  changeCardHeight = (cardId, cardType, isCardOpen) => {
    const { layout } = this.state;
    var escapedCardId = cardId
      .replace(/=/g, "\\=")
      .replace("/", "\\/")
      .replace("+", "\\+");
    var el = document
      .querySelector(`#${escapedCardId}`)
      .getBoundingClientRect();
    var cardHeight = el.height;
    var cardHeightInGrid = 1 + cardHeight / 40;

    var arrayCardPos = layout;
    var pos = -1;
    layout.map((item, index) => {
      if (item.i === cardId) {
        pos = index;
      }
      return pos;
    });
    if (isCardOpen === true) {
      arrayCardPos[pos].h = cardHeightInGrid;
    } else {
      if (cardType === "ReportCard") {
        arrayCardPos[pos].h = 10;
      } else {
        arrayCardPos[pos].h = 11;
      }
    }
    this.setState({ layout: [] }, () =>
      this.setState({ layout: arrayCardPos })
    );
  };

  crossTagPopUp = (y, x, show) => {
    if (show === true) {
      this.setState({
        xOriginNewCrossTagPopUp: x,
        yOriginNewCrossTagPopUp: y,
        isNewCrossTagPopUpVisible: true,
        isPopUpVisible: false,
      });
    }
  };

  handleRenderOnRemove = (layout) => {
    const { handleUpdate } = this.props;
    handleUpdate(true);
    this.setState({ layout });
  };

  handleImagePopUp = () => {
    const { bannerImage } = this.state;
    const { sectionData, handleImageEditorPopUpVisible } = this.props;
    document.documentElement.scrollTop = 0;
    this.setState(
      {
        fromImagePopup: "banner",
        updateSectionId: sectionData.node.id,
      },
      () => {
        handleImageEditorPopUpVisible("16:5", null, bannerImage, 265, {
          height: "auto",
          width: 652,
        });
      }
    );
  };

  handleImage2PopUp = () => {
    const { sectionImage } = this.state;
    const { sectionData, handleImageEditorPopUpVisible } = this.props;
    document.documentElement.scrollTop = 0;
    this.setState(
      {
        fromImagePopup: "sectionImage",
        updateSectionId: sectionData.node.id,
      },
      () => {
        handleImageEditorPopUpVisible("1:1", null, sectionImage, 260, {
          height: "auto",
          width: 210,
        });
      }
    );
  };

  openCloseServiceSelector = (isOpenServiceSelector) => {
    this.setState({ isOpenServiceSelector });
  };

  handleWideCardVisible = (cardId) => {
    this.setState({
      showWideCardPopup: true,
      cardId,
    });
  };

  closeWideCardPopup = () => {
    this.setState({
      showWideCardPopup: false,
      cardId: "",
    });
  };

  getSelectedCard = (cardId) => {
    const { sectionData } = this.props;
    let selectedCard = null;
    if (
      sectionData &&
      sectionData.node &&
      sectionData.node.boardCardUses &&
      sectionData.node.boardCardUses.edges
    ) {
      selectedCard = sectionData.node.boardCardUses.edges.find(
        (item) => item.node.id === cardId
      );
    }
    return selectedCard;
  };

  handleTwitterCardPopup = (showTwitterCardPopup, cardId) => {
    this.setState({
      showTwitterCardPopup: showTwitterCardPopup,
      cardId,
      selectedCard: this.getSelectedCard(cardId),
    });
  };

  render() {
    const {
      sectionLayout,
      bannerImage,
      sectionImage,
      showCardButtonModal,
      showTwitterCardPopup,
      isSaveCardsPopUpVisible,
      selectedCard,
      isRemoveCardPopUpVisible,
      value,
      sectionDescValue,
      data,
      layout,
      newTagsForCardObject,
      arrayOfTagsOfCardObject,
      selectedCardForMutation,
      isEditHashTagPopUpVisible,
      isSavedTooltip,
      savedCardId,
      showAreaCardPopup,
      xOriginTagCrossPopUp,
      yOriginTagCrossPopUp,
      isPopUpVisible,
      xOriginNewCrossTagPopUp,
      yOriginNewCrossTagPopUp,
      isNewCrossTagPopUpVisible,
      xOrigin,
      yOrigin,
      isToolTipVisible,
      text,
      isCloseUpViewVisible,
      selectedCardForWideView,
      isShareCardWithSocialVisible,
      xOriginShareCardWithSocial,
      yOriginShareCardWithSocial,
      isShareCardWithEmbeddedVisible,
      xOriginShareCardWithEmbedded,
      yOriginShareCardWithEmbedded,
      isSelectThemePopUpVisible,
      xOriginSelectThemePopUp,
      yOriginSelectThemePopUp,
      isDeleteSectionPopUpVisible,
      showWideCardPopup,
      cardId,
    } = this.state;
    const {
      sectionArray,
      sectionData: { node },
      index,
      handleAddSection,
      publishedStoryId,
      onFocus,
      isLeftMenuOpen,
      isEditingEnabled,
      userData,
      openCardFamilyPopupWithUrl,
      handleLoginPopUp,
      history,
      openStoryPopup,
      openStoryPopupWithUrl,
      isPublishedStory,
      location,
      match,
    } = this.props;
    const isSectionVisible =
      sectionLayout === "default" ||
      sectionLayout === "text" ||
      sectionLayout === "headerAndCards";
    const subComponentType =
      selectedCard && selectedCard.node && selectedCard.node.subComponentType;
    const disabledDownloadButton =
      selectedCard &&
      selectedCard.node &&
      selectedCard.node.frontFacingComponent &&
      (selectedCard.node.frontFacingComponent.componentType === "StoryCard" ||
        reportToolCardArray.includes(subComponentType));
    return (
      <div
        onClick={() => this.hidePopUp()}
        className="projectboardMainContainer mt-5"
        id="projectboardMainId"
      >
        {/* <div onClick={this.changeCardHeight}>testing the card expansion</div> */}
        <div className={"ProjectBoard-name-section ProjectBoard-name-edit"}>
          <div className="section-actions d-flex justify-content-end">
            <ButtonComponent
              buttonType="link"
              iconClass="icon-addSection"
              buttonTitle={"Add New Section Above"}
              onClick={(e) => handleAddSection(node.sectionPosition)}
            />
            {index !== sectionArray.length - 1 && (
              <ButtonComponent
                buttonType="link"
                iconClass="icon-down"
                buttonTitle={"Move Down"}
                onClick={() => this.handleMoveSectionPosition("down")}
              />
            )}
            {index !== 0 && (
              <ButtonComponent
                buttonType="link"
                iconClass="icon-up"
                buttonTitle={"Move Up"}
                onClick={() => this.handleMoveSectionPosition("up")}
              />
            )}
            <ButtonComponent
              buttonType="link"
              iconClass="icon-bin bs-15"
              onClick={this.handleDeleteSectionPopUp}
            />
          </div>
          {isSectionVisible && (
            <div className="ProjectBoard-name-title">
              <Editor
                placeholder="Add section description here!"
                value={value}
                onChange={this.onChangeEditableText}
                renderNode={this.renderNode}
                //renderMark={this.renderMark}
                onFocus={onFocus}
              />
            </div>
          )}
          {(sectionLayout === "default" || sectionLayout === "text") && (
            <div className="ProjectBoard-name-dec">
              <div>
                <Editor
                  placeholder="Add description here!"
                  ref={(editor) => (this.editor = editor)}
                  value={sectionDescValue}
                  onChange={this.onChange}
                  onKeyDown={this.onKeyDown}
                  renderNode={this.renderNodeDesc}
                  renderMark={this.renderMark}
                  renderEditor={this.renderEditor}
                  onPaste={this.onPaste}
                  onFocus={onFocus}
                />
              </div>
            </div>
          )}
          {sectionLayout === "bannerWithImage" && (
            <div className="col-12 d-flex flex-md-row flex-column">
              <div className="col-md-4 col-12">
                <div className="ProjectBoard-name-title">
                  <Editor
                    placeholder="Add section description here!"
                    value={value}
                    onChange={this.onChangeEditableText}
                    renderNode={this.renderNode}
                    //renderMark={this.renderMark}
                    onFocus={onFocus}
                  />
                </div>
                <div className="ProjectBoard-name-dec">
                  <div>
                    <Editor
                      placeholder="Add description here!"
                      ref={(editor) => (this.editor = editor)}
                      value={sectionDescValue}
                      onChange={this.onChange}
                      onKeyDown={this.onKeyDown}
                      renderNode={this.renderNodeDesc}
                      renderMark={this.renderMark}
                      renderEditor={this.renderEditor}
                      onPaste={this.onPaste}
                      onFocus={onFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-12">
                <div className="d-flex banner-image w-100">
                  <div
                    className="gallery clickable w-100"
                    onClick={() => this.handleImagePopUp()}
                  >
                    <img
                      src={bannerImage || placeHolderBannerImage}
                      alt=""
                      data-bigimage={bannerImage}
                      className="sectionWithImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {sectionLayout === "bannerWithText" && (
            <div className="col-12 d-flex flex-md-row flex-column">
              <div className="col-md-3 col-12">
                <div className="ProjectBoard-name-title">
                  <div>
                    <Editor
                      placeholder="Add section description here!"
                      value={value}
                      onChange={this.onChangeEditableText}
                      renderNode={this.renderNode}
                      //renderMark={this.renderMark}
                      onFocus={onFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-3 col-12 banner-image d-flex p-0 justify-content-center">
                <div
                  className={"gallery clickable"}
                  onClick={() => this.handleImage2PopUp()}
                >
                  <img
                    src={sectionImage}
                    alt=""
                    data-bigimage={sectionImage}
                    className="sectionWithTextImage"
                  />
                </div>
              </div>
              <div className="col-xl-7 col-md-6 col-12">
                <div className="ProjectBoard-name-dec">
                  <div>
                    <Editor
                      placeholder="Add description here!"
                      ref={(editor) => (this.editor = editor)}
                      value={sectionDescValue}
                      onChange={this.onChange}
                      onKeyDown={this.onKeyDown}
                      renderNode={this.renderNodeDesc}
                      renderMark={this.renderMark}
                      renderEditor={this.renderEditor}
                      onPaste={this.onPaste}
                      onFocus={onFocus}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="ProjectBoard-Container">
            {(sectionLayout === "default" ||
              sectionLayout === "headerAndCards") && (
              <div className="ProjectBoard-Right">
                <div className="Card-Wrap">
                  {/* <div>
                                          <button onClick={(e) => this.onLayoutSave(e, 0)}>Save Section Layout</button>
                                          <button onClick={(e) => this.onLayoutLoad(e, 0)}>Load Section Layout</button>
                                        </div>verticalCompact={false} */}
                  {data.length === 0 ? (
                    <NewSection />
                  ) : (
                    <ResponsiveReactGridLayout
                      className={
                        isLeftMenuOpen ? "rgl-menu-open" : "rgl-menu-closed"
                      }
                      cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
                      breakpoints={{
                        lg: 1350,
                        md: 1050,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={{
                        lg: layout,
                        md: layout,
                        sm: layout,
                        xs: layout,
                        xxs: layout,
                      }}
                      onLayoutChange={this.handleLayoutChange}
                      onBreakpointChange={this.onBreakpointChange}
                      isDraggable={true}
                      draggableHandle={isEditingEnabled ? "" : ".doesnt-exist"}
                      margin={[35, 10]}
                      containerPadding={[0, 0]}
                      autoSize={true}
                      {...this.props}
                    >
                      {data.map((item, index) => {
                        return (
                          <div key={item.node.id}>
                            <SectionCard
                              key={item.node.id}
                              item={item}
                              index={index}
                              changeCardHeight={this.changeCardHeight}
                              isEditingEnabled={isEditingEnabled}
                              hideUnHidePopUp={this.hideUnHidePopUp}
                              showToolTip={this.showToolTip}
                              hideToolTip={this.hideToolTip}
                              handleCloseUpView={this.handleCloseUpView}
                              handleSelectThemePopUp={
                                this.handleSelectThemePopUp
                              }
                              handleSaveCardsPopUp={this.handleSaveCardsPopUp}
                              newTagsForCardObject={newTagsForCardObject}
                              arrayOfTagsOfCardObject={arrayOfTagsOfCardObject}
                              handleArrayOfTagsOfCardObject={
                                this.handleArrayOfTagsOfCardObject
                              }
                              selectedCardForMutation={selectedCardForMutation}
                              isEditHashTagPopUpVisible={
                                isEditHashTagPopUpVisible
                              }
                              handleUpdateBoard={this.handleUpdateBoard}
                              handleReRenderCard={this.handleReRenderCard}
                              userData={userData}
                              openCardFamilyPopupWithUrl={
                                openCardFamilyPopupWithUrl
                              }
                              handleLoginPopUp={handleLoginPopUp}
                              history={history}
                              openStoryPopup={openStoryPopup}
                              openStoryPopupWithUrl={openStoryPopupWithUrl}
                              isSavedTooltip={isSavedTooltip}
                              savedCardId={savedCardId}
                              handleWideCardVisible={this.handleWideCardVisible}
                              showCardAreaPopUp={this.showCardAreaPopUp}
                              isPublishedStory={isPublishedStory}
                              onCardButtonClick={this.onCardButtonClick}
                            />
                          </div>
                        );
                      })}
                    </ResponsiveReactGridLayout>
                  )}
                  {showAreaCardPopup ? (
                    <SelectAreaTagsPopUp
                      hidePopUp={this.hideCardAreaPopUp}
                      openCloseServiceSelector={this.openCloseServiceSelector}
                      handleReRenderCard={this.handleReRenderCard}
                      popUpRenderAt="PublishBoardAsStoryPopUp"
                      selectedCardId={selectedCardForMutation}
                      isVisible={showAreaCardPopup}
                      userData={userData}
                      location={location}
                      history={history}
                      match={match}
                      x={xOriginTagCrossPopUp}
                      y={yOriginTagCrossPopUp}
                      showLogin={this.handleLoginPopUp}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>

          {isPopUpVisible === true ? (
            <SelectAreaPopup
              hidePopUp={this.hidePopUp}
              isVisible={isPopUpVisible}
              openCloseServiceSelector={this.openCloseServiceSelector}
              x={xOriginTagCrossPopUp}
              y={yOriginTagCrossPopUp}
              selectedCardId={selectedCardForMutation}
              crossTagPopUp={this.crossTagPopUp}
              handleReRenderCard={this.handleReRenderCard}
              userData={userData}
              location={location}
              history={history}
              match={match}
              showLogin={handleLoginPopUp}
            />
          ) : null}
          <NewCrossTagPopUp
            x={xOriginNewCrossTagPopUp}
            y={yOriginNewCrossTagPopUp}
            isVisible={isNewCrossTagPopUpVisible}
            hidePopUp={this.hidePopUp}
          />
          <ToolTip
            x={xOrigin}
            y={yOrigin}
            isVisible={isToolTipVisible}
            text={text}
          />
          {showCardButtonModal || isSaveCardsPopUpVisible ? (
            <CardButtonsPopUp
              history={history}
              showToolTip={this.showToolTip}
              hideToolTip={this.hideToolTip}
              handleRenderOnRemove={this.handleRenderOnRemove}
              handleReRenderCard={this.handleReRenderCard}
              displayPageName={isSaveCardsPopUpVisible ? "share-card" : ""}
              isFavorites={true}
              publishedStoryId={publishedStoryId}
              disabledDownloadButton={disabledDownloadButton}
              handleTwitterCardPopup={this.handleTwitterCardPopup}
              isCollections={true}
              isFavorite={selectedCard ? selectedCard.node.isFavorite : false}
              cardId={selectedCardForMutation}
              selectedCard={selectedCard}
              closePopUp={this.onCloseCardButtonModal}
              handleShareCardPopUp={this.handleShareCardPopUp}
            />
          ) : null}

          {isRemoveCardPopUpVisible ? (
            <RemoveCardPopUp
              isVisible={isRemoveCardPopUpVisible}
              hidePopUp={this.hidePopUp}
              cardId={selectedCardForMutation}
              handleRenderOnRemove={this.handleRenderOnRemove}
            />
          ) : null}
          <CloseUp
            isVisible={isCloseUpViewVisible}
            data={data[selectedCardForWideView]}
            hideUnHidePopUp={this.hideUnHidePopUp}
            showToolTip={this.showToolTip}
            hideToolTip={this.hideToolTip}
            onCloseUpView={this.handleCloseUpView}
            nextCardView={this.nextCardView}
            previousCardView={this.previousCardView}
            handleSaveCardsPopUp={this.handleSaveCardsPopUp}
          />
          <ShareCardWithSocial
            isVisible={isShareCardWithSocialVisible}
            x={xOriginShareCardWithSocial}
            y={yOriginShareCardWithSocial}
          />
          <ShareCardWithEmbedded
            isVisible={isShareCardWithEmbeddedVisible}
            x={xOriginShareCardWithEmbedded}
            y={yOriginShareCardWithEmbedded}
          />
          <SelectThemePopUp
            isVisible={isSelectThemePopUpVisible}
            x={xOriginSelectThemePopUp}
            y={yOriginSelectThemePopUp}
          />
        </div>
        {isDeleteSectionPopUpVisible === true ? (
          <AnnouncementPopup
            closePopUp={this.handleDeleteSectionPopUp}
            onOkClick={this.handleDeleteSection}
            announcementMessage="This will permanently delete this section for you, your collaborators, and all embedded links."
            announcementPrompt="Are you sure?"
            okButtonLabel="Delete"
            closeButtonLabel="Cancel"
            title="Delete Section"
          />
        ) : null}
        {showWideCardPopup && (
          <WideCardPopup
            cardId={cardId}
            handleReRenderCard={this.handleReRenderCard}
            handleTwitterCardPopup={this.handleTwitterCardPopup}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            history={history}
          />
        )}
        {showTwitterCardPopup && !disabledDownloadButton && (
          <WideCardPopup
            cardId={cardId}
            showFullPage={true}
            showTwitterCardPopup={showTwitterCardPopup}
            handleReRenderCard={this.handleReRenderCard}
            closeWideCardPopup={this.closeWideCardPopup}
            userData={userData}
            history={history}
          />
        )}
      </div>
    );
  }
}

export default Section;
