import React, { Component } from 'react';
import '../../css/components/Card/RunAReportCard.css';

class SvgIconCard extends Component {
  render() {
    const {link, icon, title, detail, onCardClick, popupType} = this.props;
    return (<div className={'RunAReportCard h-100 w-100 ' + (onCardClick ? 'clickable' : 'movable')}
                 onClick={() => onCardClick ? onCardClick(link, popupType) : null}>
      <div className="h-100 w-100 text-decoration-none">
        <div className="d-flex align-items-center flex-column h-100 px-4 text-center">
          <img className="rr-icon" src={icon} alt=""/>
          <div className="title">{title}</div>
          <div className="detail p-2">{detail}</div>
        </div>
      </div>
    </div>);
  }
}

export default SvgIconCard;
