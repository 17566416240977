import React, { Component } from "react";
import * as d3 from "d3";

class D3AreaDeprivationScore extends Component {
  constructor(props) {
    super(props);
    this.arrowWidth = 32;
    this.margin = {
      top: 2.5,
      right: this.arrowWidth / 2,
      bottom: 2.5,
      left: this.arrowWidth / 2,
    };
    this.clientWidth = 245;
    this.clientHeight = 80;
    this.duration = 1000;
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (nextProps.data !== data) {
      this.update();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentDidUpdate() {
    this.update();
  }

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  onWindowResize = () => {
    if (!this.mounted) return;
    const { isWide } = this.props;
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    this.mainSvg
      .attr("viewBox", "0 0 245 217")
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%");
  };

  init = () => {
    const { id } = this.props;
    this.barsData = d3.range(5);
    this.percentileScale = d3.scaleLinear().domain([0, 100]);
    this.barScale = d3.scaleBand().domain(this.barsData);
    this.color = d3
      .scaleOrdinal()
      .domain(this.barsData)
      .range(["#78A22F", "#6A8D73", "#D5DED6", "#FFA987", "#FF6B35"]);
    if (id) {
      this.createSvg();
    }
  };

  animateNumber = (ele, value) => {
    d3.select(ele)
      .selectAll("span.animate-title")
      .data([value])
      .enter()
      .append("span")
      .text(0)
      .attr("class", "animate-title")
      .transition()
      .duration(750)
      .tween("text", function (d) {
        const self = this;
        const i = d3.interpolate(this.textContent, d);
        const prec = (d + "").split(".");
        const round = prec.length > 1 ? Math.pow(10, prec[1].length) : 1;

        return function (t) {
          d3.select(self).text(Math.round(i(t) * round) / round);
        };
      });
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");

    this.mainSvg = d3.select(this.parentEle).select(`svg#mainSvg-${svgId}`);

    this.svg = d3.select(this.parentEle).select("svg#svgParentEle");

    const gSelection = this.svg
      .selectAll("g.g-container")
      .data([svgId], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = d3.select("g#" + svgId + "g-container");

    const percentile = this.svgG
      .selectAll(".percentile")
      .data([svgId], (d) => d);
    percentile
      .enter()
      .append("g")
      .merge(percentile)
      .attr("class", "percentile")
      .attr("id", (d) => d + "percentile");
    percentile.exit().remove();
    this.percentileG = d3.select("g#" + svgId + "percentile");

    const bars = this.svgG.selectAll(".bars").data([svgId], (d) => d);
    bars
      .enter()
      .append("g")
      .merge(bars)
      .attr("class", "bars")
      .attr("id", (d) => d + "bars");
    bars.exit().remove();
    this.barsG = d3.select("g#" + svgId + "bars");

    this.autoAlignSVG();
    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;

    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    this.mainSvg
      .attr("viewBox", "0 0 245 217")
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%");
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height = this.clientHeight - this.margin.top - this.margin.bottom;
    this.barHeight = 12;
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.barsG.attr(
      "transform",
      "translate(0, " + (this.height - this.barHeight) + ")"
    );
    this.percentileG.attr(
      "transform",
      "translate(0, " + (this.height - this.barHeight - 20) + ")"
    );

    this.barScale.range([0, this.width]).padding(0);
    this.percentileScale.range([0, this.width]);
  };

  roundedRect(x, y, width, height, rtr, rbr, rbl, rtl) {
    const radiusTopRight = rtr ? rtr : 0;
    const radiusBottomRight = rbr ? rbr : 0;
    const radiusBottomLeft = rbl ? rbl : 0;
    const radiusTopLeft = rtl ? rtl : 0;
    return (
      "M" +
      x +
      "," +
      y +
      "h" +
      (width - radiusTopRight) +
      "a" +
      radiusTopRight +
      "," +
      radiusTopRight +
      " 0 0 1 " +
      radiusTopRight +
      "," +
      radiusTopRight +
      "v" +
      (height - 2 * radiusBottomRight) +
      "a" +
      radiusBottomRight +
      "," +
      radiusBottomRight +
      " 0 0 1 " +
      -radiusBottomRight +
      "," +
      radiusBottomRight +
      "h" +
      (radiusBottomLeft - width) +
      "a" +
      radiusBottomLeft +
      "," +
      radiusBottomLeft +
      " 0 0 1 " +
      -radiusBottomLeft +
      "," +
      -radiusBottomLeft +
      "v" +
      -(height - 2 * radiusTopLeft) +
      "a" +
      radiusTopLeft +
      "," +
      radiusTopLeft +
      " 0 0 1 " +
      radiusTopLeft +
      "," +
      -radiusTopLeft +
      "z"
    );
  }

  update = () => {
    const self = this;
    const { mainTitle, value } = this.props;
    if (mainTitle > -1) {
      this.animateNumber(this.mainTitleEle, mainTitle);

      this.percentileG
        .data([{ value, title: mainTitle }])
        .attr("transform", function () {
          const val = d3.select(this).attr("data");
          return `translate(${
            val ? val : 0
          }, ${self.height - self.barHeight - 20})`;
        })
        .transition()
        .duration(this.duration)
        .attr(
          "transform",
          (d) =>
            "translate(" +
            (this.percentileScale(d.value) - this.arrowWidth / 2) +
            ", " +
            (this.height - this.barHeight - 20) +
            ")"
        )
        .on("end", function (d) {
          d3.select(this).attr(
            "data",
            self.percentileScale(d.value) - self.arrowWidth / 2
          );
        });
      const percentile = this.percentileG.selectAll("text").data(
        (d) => [d.title],
        (d) => d.title
      );
      percentile
        .enter()
        .append("text")
        .merge(percentile)
        .attr("x", this.arrowWidth / 2)
        .attr("y", -5)
        .text((d) => d)
        .style("font-family", "ProximaNova-Regular")
        .style("text-anchor", "middle")
        .style("color", "#424B54")
        .style("fill", "#424B54");
      percentile.exit().remove();
      const arrow = this.percentileG.selectAll("path.arrow").data(
        (d) => [d],
        (d) => d
      );
      arrow
        .enter()
        .append("path")
        .merge(arrow)
        .attr("class", "arrow")
        .attr("d", "M31.6,0L15.8,15.8L0,0H31.6z")
        .style("fill", "#424B54");
      arrow.exit().remove();

      const bars = this.barsG.selectAll(".bar").data(this.barsData);
      bars
        .enter()
        .append("path")
        .attr("class", "bar")
        .style("fill", (d) => this.color(d))
        .attr("d", this.roundedRect(0, 0, 0, this.barHeight))
        .merge(bars)
        .transition()
        .duration(this.duration)
        .attr("d", (d, i) => {
          const br = this.barHeight / 2;
          if (i === 0) {
            return this.roundedRect(
              this.barScale(d),
              0,
              this.barScale.bandwidth(),
              this.barHeight,
              0,
              0,
              br,
              br
            );
          } else if (i === this.barsData.length - 1) {
            return this.roundedRect(
              this.barScale(d) + br,
              0,
              this.barScale.bandwidth() - br,
              this.barHeight,
              br,
              br,
              0,
              0
            );
          } else {
            return this.roundedRect(
              this.barScale(d),
              0,
              this.barScale.bandwidth(),
              this.barHeight
            );
          }
        });
      bars.exit().remove();
    }
  };

  render() {
    const { mainSubTitle, id } = this.props;
    const style = { fontSize: "11px", lineHeight: "13px", color: "#424B54" };
    const centerStyle = {
      fontSize: "11px",
      lineHeight: "13px",
      paddingRight: "0px",
      color: "#424b54",
    };
    const showNumberLabels =
      mainSubTitle !== "IS THE AREA DEPRIVATION SCORE FOR OUR COMMUNITY";
    return (
      <div
        className={
          "D3AreaDeprivationScore h-100 w-100 d-flex justify-content-center align-items-center"
        }
        ref={(ele) => (this.parentEle = ele)}
      >
        <svg
          id={"mainSvg-" + id.replace(/=/g, "")}
          width={"100%"}
          height={"100%"}
        >
          <foreignObject x={0} y={0} height={"217"} width={"245"}>
            <div className="D3HorizontalBarChart flex-grow-1 h-100 w-100">
              <div className="px-3 bar-title" style={{ height: "80px" }}>
                <div
                  className="main-text mb-2"
                  ref={(ele) => (this.mainTitleEle = ele)}
                />
                <div className="details-text" style={style}>
                  {mainSubTitle}
                </div>
              </div>
              <div className="row m-0 mb-1" style={{ height: "90px" }}>
                <svg id={"svgParentEle"} />
              </div>
              {showNumberLabels ? (
                <div
                  className="row mx-1 px-3 justify-content-between"
                  style={{ height: "50px" }}
                >
                  <small className="text-left" style={style}>
                    <b>0</b>
                    <br />
                    Most
                    <br />
                    Affluent
                  </small>
                  <small className="text-center" style={centerStyle}>
                    <b>50</b>
                    <br />
                    National
                    <br />
                    Average
                  </small>
                  <small className="text-right" style={style}>
                    <b>100</b>
                    <br />
                    Most
                    <br />
                    Deprived
                  </small>
                </div>
              ) : (
                <div
                  className="row mx-1 px-3 justify-content-between"
                  style={{ height: "50px" }}
                >
                  <small className="text-left" style={style}>
                    <b>40</b>
                    <br />
                    Most
                    <br />
                    Affluent
                  </small>
                  <small className="text-center" style={centerStyle}>
                    <b>100</b>
                    <br />
                    National
                    <br />
                    Average
                  </small>
                  <small className="text-right" style={style}>
                    <b>160</b>
                    <br />
                    Most
                    <br />
                    Deprived
                  </small>
                </div>
              )}
            </div>
          </foreignObject>
        </svg>
      </div>
    );
  }
}

export default D3AreaDeprivationScore;
