import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../RelayEnvironment';

const mutation = graphql`
    mutation  
        LogDataDownloadForUserMutation($input:LogDataDownloadForUserInput!)  
        {  logDataDownloadForUser(input:$input)  
            {  loggedDownload 
            }  
        }
    `;

export default (cardId, callback) => {
    const variables = {
        "input": {"boardCardUseId": cardId}
    };

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response, errors) => {
                // console.log('Response from server section layouts update.',JSON.stringify(response))
                // console.log('Response from server section layouts update.',(response))
                callback();
            },
            onError: (err) => console.error("Error from server on log data download", JSON.stringify(err)),
        },
    );
}