import React, { Component } from 'react';
import D3BarChart from './D3BarChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3BarWideChart extends Component {

  render() {
    const { id, dualVerticalBarChartData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='D3BarWideChart d-flex w-100 h-100 position-relative'>
        {(dualVerticalBarChartData && dualVerticalBarChartData.data) &&
        <D3BarChart
          id={id}
          key={id}
          dualVerticalBarChartData={dualVerticalBarChartData}
          isWide={true}
          {...this.props}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3BarWideChart;
