import React, { Component } from 'react';
import D3SaladChart from '../Card/D3SaladChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3SaladWideChart extends Component {
  render() {
    const { id, saladChartFEI, saladSrc, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'saladWide' : 'saladTwitter');
    const content = (
      <div className='D3SaladWideChart d-flex w-100 h-100'>
        {(saladChartFEI) &&
        <D3SaladChart
          id={wideChartId}
          key={wideChartId}
          saladSrc={saladSrc}
          value={saladChartFEI.value}
          valueLabel={saladChartFEI.valueLabel}
          isWide={true}
          min={-2} max={12.5}/>
        }</div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3SaladWideChart;
