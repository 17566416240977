/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBoardCollaboratorInput = {|
  boardId: string,
  boardAuthorId?: ?string,
  email?: ?string,
  privateLinkCollaborator?: ?boolean,
  clientMutationId?: ?string,
|};
export type ProjectBoardRemoveCollaboratorMutationVariables = {|
  input: DeleteBoardCollaboratorInput
|};
export type ProjectBoardRemoveCollaboratorMutationResponse = {|
  +deleteBoardCollaborator: ?{|
    +boardNode: ?{|
      +id: string,
      +collaborators: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +userAccount: ?{|
              +email: ?string
            |},
            +profileImage: ?{|
              +id: string,
              +caption: ?string,
              +url: ?string,
            |},
          |}
        |}>
      |},
      +invitations: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +email: string
          |}
        |}>
      |},
    |}
  |}
|};
export type ProjectBoardRemoveCollaboratorMutation = {|
  variables: ProjectBoardRemoveCollaboratorMutationVariables,
  response: ProjectBoardRemoveCollaboratorMutationResponse,
|};
*/


/*
mutation ProjectBoardRemoveCollaboratorMutation(
  $input: DeleteBoardCollaboratorInput!
) {
  deleteBoardCollaborator(input: $input) {
    boardNode {
      id
      collaborators {
        edges {
          node {
            id
            name
            userAccount {
              email
              id
            }
            profileImage {
              id
              caption
              url
            }
          }
        }
      }
      invitations {
        edges {
          node {
            email
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteBoardCollaboratorInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardImageNode",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBoardRemoveCollaboratorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardCollaboratorPayload",
        "kind": "LinkedField",
        "name": "deleteBoardCollaborator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNodeConnection",
                "kind": "LinkedField",
                "name": "collaborators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardAuthorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardAuthorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserAccountNode",
                            "kind": "LinkedField",
                            "name": "userAccount",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollaboratorInvitationNodeConnection",
                "kind": "LinkedField",
                "name": "invitations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollaboratorInvitationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollaboratorInvitationNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBoardRemoveCollaboratorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardCollaboratorPayload",
        "kind": "LinkedField",
        "name": "deleteBoardCollaborator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardNode",
            "kind": "LinkedField",
            "name": "boardNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardAuthorNodeConnection",
                "kind": "LinkedField",
                "name": "collaborators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardAuthorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardAuthorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserAccountNode",
                            "kind": "LinkedField",
                            "name": "userAccount",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollaboratorInvitationNodeConnection",
                "kind": "LinkedField",
                "name": "invitations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollaboratorInvitationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollaboratorInvitationNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectBoardRemoveCollaboratorMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectBoardRemoveCollaboratorMutation(\n  $input: DeleteBoardCollaboratorInput!\n) {\n  deleteBoardCollaborator(input: $input) {\n    boardNode {\n      id\n      collaborators {\n        edges {\n          node {\n            id\n            name\n            userAccount {\n              email\n              id\n            }\n            profileImage {\n              id\n              caption\n              url\n            }\n          }\n        }\n      }\n      invitations {\n        edges {\n          node {\n            email\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb286d60b6e23d674de6c6eb27e212af';

module.exports = node;
