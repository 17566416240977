import React, { Component } from 'react';
import D3PieChart from '../Card/D3PieChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3PieWideChart extends Component {
  render() {
    const { id, pieData, showTwitterCardPopup } = this.props;
    const content = (
      <div className='h-100 D3PieWideChart d-flex flex-column w-100 position-relative'>
        <div className='h-100 w-100'>
          {(pieData && pieData.data) &&
          <D3PieChart
            id={id + 'wide'}
            key={id + 'donutChart'}
            thickness={25}
            pieData={pieData}
            isWide={true}
          />
          }
        </div>
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3PieWideChart;
