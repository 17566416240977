/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserAccountInput = {|
  username?: ?string,
  userEmail?: ?string,
  userFirstName?: ?string,
  userLastName?: ?string,
  userOldPassword?: ?string,
  userNewPassword?: ?string,
  userReportName?: ?string,
  userDegrees?: ?string,
  useCompanyInSignature?: ?boolean,
  userSecondaryEmail?: ?string,
  userBillingFirstName?: ?string,
  userBillingLastName?: ?string,
  userBillingAddress?: ?string,
  userOrganizationName?: ?string,
  userOrganizationTitle?: ?string,
  userOrganizationAddress?: ?string,
  userOrganizationCity?: ?string,
  userOrganizationState?: ?string,
  userOrganizationZip?: ?string,
  userOrganizationEmail?: ?string,
  userOrganizationPhone?: ?string,
  briefBio?: ?string,
  contactInfo?: ?string,
  file?: ?any,
  isProfilePublic?: ?boolean,
  userTitle?: ?string,
  subscriptionCouponCode?: ?string,
  clientMutationId?: ?string,
|};
export type AccountEditPopupMutationVariables = {|
  input: UpdateUserAccountInput
|};
export type AccountEditPopupMutationResponse = {|
  +updateUserAccount: ?{|
    +userAccountNode: ?{|
      +userImage: ?{|
        +url: ?string
      |},
      +email: ?string,
    |},
    +validationErrors: ?$ReadOnlyArray<?{|
      +key: ?string,
      +message: ?string,
    |}>,
  |}
|};
export type AccountEditPopupMutation = {|
  variables: AccountEditPopupMutationVariables,
  response: AccountEditPopupMutationResponse,
|};
*/


/*
mutation AccountEditPopupMutation(
  $input: UpdateUserAccountInput!
) {
  updateUserAccount(input: $input) {
    userAccountNode {
      userImage {
        url
        id
      }
      email
      id
    }
    validationErrors {
      key
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserAccountInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "validationErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountEditPopupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserAccountPayload",
        "kind": "LinkedField",
        "name": "updateUserAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNode",
            "kind": "LinkedField",
            "name": "userAccountNode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileImageNode",
                "kind": "LinkedField",
                "name": "userImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountEditPopupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserAccountPayload",
        "kind": "LinkedField",
        "name": "updateUserAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccountNode",
            "kind": "LinkedField",
            "name": "userAccountNode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileImageNode",
                "kind": "LinkedField",
                "name": "userImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountEditPopupMutation",
    "operationKind": "mutation",
    "text": "mutation AccountEditPopupMutation(\n  $input: UpdateUserAccountInput!\n) {\n  updateUserAccount(input: $input) {\n    userAccountNode {\n      userImage {\n        url\n        id\n      }\n      email\n      id\n    }\n    validationErrors {\n      key\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a4c90dff4e2857387d272bb72c92fd5';

module.exports = node;
