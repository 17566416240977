import React, { useState, useEffect, useContext, useRef } from "react";
import "../../css/components/CommonComponents/AddSectionPopUp.css";
import { StandardTallModalPopup } from "common-components";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import * as domToImage from "../../modules/dom-to-image/dom-to-image";
import { apiBaseUrl, reportToolCardArray } from "../../utility/utilities";
import {
  CreateTwitterRequestTokenMutation,
  VerifyTwitterCredentialsMutation,
  CopyCardToFavouritesMutation,
  RemoveCardFromFavouritesMutation,
  CardFeedbackMutation,
  SaveCardToStoryMutation,
  PostTweetMutation,
} from "../../mutations";
import { DownloadContext } from "../Root/downloadContext";
import RemoveCardPopUp from "./RemoveCardPopUp";
import { commitMutation, fetchQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

const CardButtonsPopUp = (props) => {
  const {
    history: {
      location: { hash },
    },
    closePopUp,
    cardId,
    handleReRenderCard,
    isFavorite,
    isFavorites,
    isCollections,
    showFullPage,
    selectedCard,
    handleTwitterCardPopup,
    disabledDownloadButton,
    displayPageName,
    publishedStoryId,
    isArea,
  } = props;
  const [showRemoveCard, setShowRemoveCard] = useState(false);
  const [count, setCount] = useState(0);
  const [boardId, setBoardId] = useState("");
  const [isPublicStory, setIsPublicStory] = useState(false);
  const downloadContext = useContext(DownloadContext);

  const publishStoryCardType = new Set([
    "ChartCard",
    "AdiMapCard",
    "DotMapCard",
    "IndicatorMapCard",
    "ServiceAreaMapCard",
    "MapCard",
  ]);

  const hidePopUp = (onCancelClick) => {
    if (!onCancelClick) {
      closePopUp();
    }
    setShowRemoveCard(false);
  };

  const copyURl = () => {
    let componentType = "";
    let subComponentType = "";
    if (selectedCard && selectedCard.node) {
      componentType =
        selectedCard.node.frontFacingComponent &&
        selectedCard.node.frontFacingComponent.componentType;
      subComponentType = selectedCard.node.subComponentType;
    } else {
      componentType =
        selectedCard &&
        selectedCard.frontFacingComponent &&
        selectedCard.frontFacingComponent.componentType;
      subComponentType =
        selectedCard && selectedCard.frontFacingComponent.subComponentType;
    }

    const isToolCard = reportToolCardArray.includes(subComponentType);
    if (componentType === "StoryCard") {
      return `${apiBaseUrl}board/pubboard/${cardId}`;
    } else if (
      publishStoryCardType.has(componentType) &&
      publishedStoryId &&
      !isToolCard
    ) {
      return `${apiBaseUrl}board/pubboard/${publishedStoryId}/${cardId}`;
    } else {
      return `${apiBaseUrl}board/card/${cardId}`;
    }
  };

  const [commentText, setCommentText] = useState("");
  const [tweetText, setTweetText] = useState(
    "Visit BroadStreet to see cards like this https://broadstreet.io"
  );
  const [sectionIds, setSectionIds] = useState([]);
  const [currentPageName, setCurrentPageName] = useState(displayPageName);

  const handleReport = () => {
    if (commentText) {
      if (pagesArray && pagesArray.length) {
        pagesArray.map((data) => {
          if (data.name === "report-error") {
            data.buttonTitle = "reporting";
            data.disabled = true;
            setCurrentPage(data);
          }
          return data;
        });
        setPagesArray(pagesArray);
      }
      CardFeedbackMutation(
        cardId,
        commentText,
        () => {
          if (pagesArray && pagesArray.length) {
            pagesArray.map((data) => {
              if (data.name === "report-error") {
                data.buttonTitle = "report";
                setCurrentPage(data);
              }
              return data;
            });
            setPagesArray(pagesArray);
          }
          goBack();
          toast.success("Feedback sent successfully.");
        },
        (err) => {
          goBack();
          toast.error(JSON.stringify(err));
          console.error(JSON.stringify(err));
        }
      );
    }
  };

  const boardRef = useRef();
  boardRef.current = boardId;

  const tweetTextRef = useRef();
  tweetTextRef.current = tweetText;

  const publicStoryRef = useRef();
  publicStoryRef.current = isPublicStory;

  const [pagesArray, setPagesArray] = useState([
    {
      showTitle: true,
      showPageItem: true,
      title: "Card Settings",
      name: "more",
      menuList: [
        {
          icon: "left-icon-share",
          title: "Share this Card",
          onClickItem: () => changeCurrentPage("share-card"),
        },
        {
          icon: "left-icon-save",
          title: "Save Card",
          onClickItem: () => changeCurrentPage("save-card"),
          hide: showFullPage,
        },
        {
          icon: "left-icon-favorite",
          title:
            isFavorites && isFavorite
              ? "Remove from Favorites"
              : "Add Card to Favorites",
          stopAnimation: true,
          name: "add-to-favorites",
          onClickItem: () => {
            if (isFavorites && isFavorite) {
              handleRemoveCardToFavourites();
            } else {
              handleCopyCardToFavourites();
            }
          },
          hide: showFullPage,
        },
        {
          icon: "icon-bin",
          title: "Remove Card",
          showInEditMode: true,
          isDisplayOnEditMode: isCollections,
          stopAnimation: true,
          onClickItem: () => {
            setShowRemoveCard(true);
          },
        },
        {
          icon: "icon-chat",
          title: "Report Error or Abuse",
          onClickItem: () => changeCurrentPage("report-error"),
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "Share Card",
      menuList: [
        {
          icon: "left-icon-darkLink",
          title: "Copy Link",
          onClickItem: () => changeCurrentPage("copy-link"),
        },
        {
          icon: "left-icon-save",
          title: "Save to",
          onClickItem: () => changeCurrentPage("save-card"),
        },
        {
          icon: "left-icon-download",
          title: "Download",
          showLoader: false,
          disabled: disabledDownloadButton,
          name: "download",
          onClickItem: () =>
            disabledDownloadButton ? null : changeCurrentPage("download"),
        },
        {
          icon: "left-icon-twitter",
          title: "Twitter",
          showLoader: false,
          name: "twitter",
          stopAnimation: true,
          onClickItem: () => handleTwitterPost(),
        },
        /*{
          icon: 'left-icon-facebook',
          title: 'Facebook'
        }*/
      ],
      parent: "more",
      name: "share-card",
      isDisplayComingSoonPage: false,
    },
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      disabled: false,
      buttonTitle: "Copy",
      title: "Copy Link",
      parent: "share-card",
      name: "copy-link",
      handleOnClick: () => copyLink(),
      formItems: [
        {
          type: "textarea",
          readOnly: true,
          name: "copy-link",
          placeholder: "Type Here",
          label: "Share this read-only link",
          value: copyURl(),
          onFocus: (e) => onFocusCopyLink(e),
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      title: "Download",
      parent: "share-card",
      name: "download",
      menuList: [
        /*{
          icon: 'left-icon-data',
          title: 'Data',
          rightIcon: 'icon-right-download'
        },*/
        {
          icon: "left-icon-media",
          title: "Image",
          name: "image",
          rightIcon: "icon-right-download",
          onClickItem: () => startDownloading(true),
        },
        /*{
          icon: 'left-icon-gift',
          title: 'Data & Image',
          rightIcon: 'icon-right-download',
          name: 'dataImage'
        }*/
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      disabled: true,
      buttonTitle: "Report",
      title: "Report Error or Abuse",
      parent: "more",
      name: "report-error",
      handleOnClick: () => handleReport(commentText),
      formItems: [
        {
          type: "textarea",
          name: "add-comment",
          placeholder: "Type Here",
          label: "Add comments",
          value: commentText,
          onChange: (e) => {
            setCommentText(e.currentTarget.value);
          },
        },
      ],
    },
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      disabled: true,
      buttonTitle: "save",
      title: "Save Card",
      parent: currentPageName === "share-card" ? "share-card" : "more",
      name: "save-card",
      onChangeStorySelection: (sectionIdList) => {
        setSectionIds([...sectionIdList]);
      },
      fromStory: true,
      handleOnClick: () => {
        saveCardToStory(sectionIds);
      },
      saveList: true,
    },
    {
      showTitle: true,
      showPageItem: false,
      showButton: true,
      disabled: false,
      buttonTitle: "Post",
      title: "Twitter",
      parent: "share-card",
      name: "twitter-card",
      handleOnClick: () => tweetPost(tweetTextRef.current),
      formItems: [
        {
          type: "textarea",
          name: "twitter-card",
          placeholder: "Type Here...Max 280 characters allowed.",
          helperText: "Max 280 characters.",
          label: "Write Tweet",
          value: tweetTextRef.current,
          maxLength: 280,
          onChange: (e) => {
            setTweetText(e.currentTarget.value);
          },
        },
        {
          type: "image",
          label: "",
          alt: "Image",
          height: "100%",
          width: "100%",
          src: null,
        },
      ],
    },
  ]);

  let retryCount = 1;

  useEffect(() => {
    const getCardData = async () => {
      const _query = graphql`
        query CardButtonsPopUpCardDataQuery($input: ID!) {
          boardCardUse(id: $input) {
            id
            jsonid
            additionalConfiguration {
              jsonProperties
            }
            chartImage
            configuration {
              id
              author {
                id
                name
                profileImage {
                  url
                }
              }
              publishedStoryBoard {
                hasEditPermission
                sourceBoard {
                  id
                  name
                  publishOutsidePaywall
                }
              }
              citation
              endYear
              internalOrderList
              jsonStyleProperties
              organization {
                id
                organizationName
              }
              permaLink
              report {
                id
                jsonid
              }
              savedArea {
                id
                jsonid
                name
              }
              startYear
              storyLink
              storyPublishedDate
              storyUpdatedDate
              thumbnailImage {
                id
                url
              }
              subtitle
              title
              toolLink
            }
            cardPosition
            frontFacingComponent {
              componentType
              name
            }
            subComponentType
            statistics {
              name
              jsonData
            }
            isFavorite
            sharingStatus
            tags {
              edges {
                node {
                  boardTag {
                    name
                    permaTag
                  }
                }
              }
            }
            boardSection {
              board {
                id
                isCardFamily
                isPublishedStory
                publishOutsidePaywall
                name
                publicFeedStatus
                publishedStoryCard {
                  id
                }
              }
            }
          }
        }
      `;
      try {
        const variables = { input: cardId, isUpdate: false };
        return await fetchQuery(environment, _query, variables);
      } catch (err) {
        return null;
      }
    };
    getCardData().then((data) => {
      let areaTag, title, boardIdNew, publishOutsidePaywall;
      if (data && data.boardCardUse && data.boardCardUse.configuration) {
        areaTag =
          data.boardCardUse.configuration.savedArea &&
          data.boardCardUse.configuration.savedArea.name;
        title = data.boardCardUse.configuration.title;
        boardIdNew = data.boardCardUse.configuration.publishedStoryBoard
          ? data.boardCardUse.configuration.publishedStoryBoard.sourceBoard &&
            data.boardCardUse.configuration.publishedStoryBoard.sourceBoard.id
          : data &&
            data.boardCardUse.boardSection &&
            data.boardCardUse.boardSection.board &&
            data.boardCardUse.boardSection.board.id;
        if (boardIdNew) {
          setBoardId(boardIdNew);
        }

        publishOutsidePaywall = data.boardCardUse.configuration
          .publishedStoryBoard
          ? data.boardCardUse.configuration.publishedStoryBoard.sourceBoard &&
            data.boardCardUse.configuration.publishedStoryBoard.sourceBoard
              .publishOutsidePaywall
          : data &&
            data.boardCardUse.boardSection &&
            data.boardCardUse.boardSection.board &&
            data.boardCardUse.boardSection.board.publishOutsidePaywall;
        setIsPublicStory(publishOutsidePaywall);
      }

      let twitterText = "";
      if (areaTag || title) {
        const subComponentType =
          data && data.boardCardUse && data.boardCardUse.subComponentType;
        const tweetTitle = title.replace("in Our Community", "");
        const isToolCard = reportToolCardArray.includes(subComponentType);
        twitterText = `${isToolCard ? "Create a" : ""} ${tweetTitle || ""} ${
          areaTag && !isArea ? "in " + areaTag : ""
        }\n#broadstreet #datagram \n${copyURl()}`;
      } else {
        twitterText = copyURl();
      }
      setTweetText(twitterText);
      updateTwitterData(twitterText);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  useEffect(() => {
    const pagesArrayCopy = [...pagesArray];
    if (handleTwitterCardPopup) {
      handleTwitterCardPopup(true, cardId);
    }

    pagesArrayCopy.length &&
      pagesArrayCopy.map((data) => {
        if (data.name === "report-error") {
          data.disabled = !commentText;
          data.handleOnClick = () => {
            handleReport(commentText);
          };
        }
        return data;
      });
    setPagesArray(pagesArrayCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentText]);

  useEffect(() => {
    const pagesArrayCopy = [...pagesArray];

    if (pagesArrayCopy.length) {
      pagesArrayCopy.map((data) => {
        if (data.name === "twitter-card") {
          data.disabled = !tweetText;
        }
        return data;
      });
      setPagesArray(pagesArrayCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweetText]);

  useEffect(() => {
    const pagesArrayCopy = [...pagesArray];
    pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data.name === "save-card") {
          data.disabled = !sectionIds.length;
          data.handleOnClick = () => {
            saveCardToStory(sectionIds);
          };
        }
        return data;
      });
    setPagesArray(pagesArrayCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIds]);

  const [currentPage, setCurrentPage] = useState(pagesArray[0]);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (isDownloading && downloadContext.id) {
      exportToImage();
    }
    const pagesArrayCopy = [...pagesArray];
    pagesArrayCopy &&
      pagesArrayCopy.forEach((data) => {
        if (data.name === "share-card") {
          data.menuList &&
            data.menuList.forEach((item) => {
              if (item.name === "twitter") {
                item.onClickItem = () => handleTwitterPost();
              }
            });
        }
      });
    setPagesArray(pagesArrayCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadContext.id, isDownloading]);

  // component will unmount
  useEffect(() => {
    return () => {
      setIsDownloading(false);
      downloadContext.setCardId(null);
      downloadContext.setCardType("chart");
      if (handleTwitterCardPopup) {
        handleTwitterCardPopup(false, cardId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset copy button copy link once copied and return back to page
    const pagesArrayCopy = [...pagesArray];
    pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data.name === "copy-link" && data.disabled === true) {
          data.buttonTitle = "COPY";
          data.disabled = false;
          data.buttonState = "save";
        }
        if (data.name === "twitter-card" && data.disabled === true) {
          data.buttonTitle = "POST";
          data.disabled = false;
          data.buttonState = "save";
        }

        if (data.name === "save-card" && data.disabled === true) {
          data.buttonTitle = "save";
          data.disabled = false;
          data.buttonState = "save";
        }
        if (currentPageName === "share-card" || currentPageName === "more") {
          if (data.name === "save-card") {
            data.parent =
              displayPageName === "share-card" ? "share-card" : currentPageName;
          }
        }

        if (disabledDownloadButton) {
          if (data.name === "share-card") {
            data.menuList &&
              data.menuList.forEach((item) => {
                if (item.name === "download") {
                  item.disabled = disabledDownloadButton;
                  item.onClickItem = () =>
                    disabledDownloadButton
                      ? null
                      : changeCurrentPage("download");
                  return item;
                }
              });
          }
        }
        return data;
      });
    if (displayPageName && displayPageName === currentPageName) {
      const data =
        pagesArrayCopy &&
        pagesArrayCopy.find((d) => d.name === displayPageName);
      if (data) {
        setCurrentPage(data);
      }
    }
    setPagesArray(pagesArrayCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSaveStoryLayout = () => {
    if (boardRef.current && !publicStoryRef.current) {
      const mutation = graphql`
        mutation CardButtonsPopUpCardDataMutation($input: UpdateBoardInput!) {
          updateBoard(input: $input) {
            boardNode {
              id
              name
              subtitle
            }
          }
        }
      `;
      const variables = {
        input: {
          boardId: boardRef.current,
          publishOutsidePaywall: true,
        },
      };
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: () => {},
        onError: (err) => {},
      });
    }
  };

  const copyLink = () => {
    handleSaveStoryLayout();
    let inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = copyURl();
    inp.select();
    document.execCommand("copy", false);
    pagesArray &&
      pagesArray.map((data) => {
        if (data.name === "copy-link") {
          data.buttonTitle = "COPIED";
          data.disabled = true;
          data.buttonState = "saved";
        }
        return data;
      });
    setPagesArray(pagesArray);
    inp.remove();
  };

  const onFocusCopyLink = (e) => {
    e.target.select();
  };

  const postTweetOnTwitter = (tweetMessage, imgFile) => {
    if (tweetMessage) {
      PostTweetMutation(
        imgFile,
        tweetMessage,
        (res) => {
          if (pagesArray && pagesArray.length) {
            pagesArray.map((data) => {
              if (data.name === "twitter-card") {
                data.buttonTitle = "POST";
                setCurrentPage(data);
              }
              return data;
            });
            setPagesArray(pagesArray);
          }
          goBack();
          const resMessage = res.postTweet.resultMessage;
          if (res.postTweet.success) {
            handleSaveStoryLayout();
            toast.success(
              resMessage ? resMessage : "Tweet posted successfully."
            );
          } else if (!res.postTweet.success) {
            toast.error(resMessage ? resMessage : "Failed to post tweet.");
          } else {
            toast.error("oops, something went wrong!");
          }
        },
        (err) => {
          goBack();
          toast.error(JSON.stringify(err));
          console.error(JSON.stringify(err));
        }
      );
    }
  };

  const tweetPost = (tweetMessage) => {
    if (pagesArray && pagesArray.length) {
      pagesArray.map((data) => {
        if (data.name === "twitter-card") {
          data.buttonTitle = "Posting";
          data.disabled = true;
          setCurrentPage(data);
        }
        return data;
      });
      setPagesArray(pagesArray);
    }
    const id = cardId.replace(/=/g, "");
    let svgElement = document.getElementById(`card-family-${id}`);
    if (svgElement) {
      domToImage
        .toPng(svgElement, { cacheBust: true })
        .then((dataUrl) => {
          const arr = dataUrl.split(",");
          const bstr = atob(arr[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const imgFile = {
            0: new File([u8arr], `${cardId}.png`, { type: "image/png" }),
          };
          postTweetOnTwitter(tweetMessage, imgFile);
        })
        .catch(() => {
          postTweetOnTwitter(tweetMessage, null);
        });
    } else {
      postTweetOnTwitter(tweetMessage, null);
    }
  };

  const handleCopyCardToFavourites = () => {
    // Add to favorites
    console.log("11111111111111122222222222222222", cardId);
    CopyCardToFavouritesMutation(
      cardId,
      () => {
        closePopUp();
        if (handleReRenderCard) {
          handleReRenderCard(cardId);
        }
      },
      (err) => {
        console.error(JSON.stringify(err));
      }
    );
  };

  const handleRemoveCardToFavourites = () => {
    // Remove from favorites
    RemoveCardFromFavouritesMutation(
      cardId,
      () => {
        closePopUp();
        if (handleReRenderCard) {
          handleReRenderCard(cardId);
        }
      },
      (err) => {
        console.error(JSON.stringify(err));
      }
    );
  };

  const startDownloading = () => {
    setIsDownloading(true);
    const pageArrayData = updateState(true);
    setPagesArray(pageArrayData);
  };

  const exportToImage = () => {
    const id = cardId.replace(/=/g, "");
    let svgElement = document.getElementById(`card-family-${id}`);
    if (svgElement) {
      setTimeout(
        () => {
          domToImage
            .toPng(svgElement, { cacheBust: true })
            .then((dataUrl) => {
              const pageArrayData = updateState(false);
              setPagesArray(pageArrayData);
              const a = document.createElement("a");
              a.href = dataUrl;
              a.download = `${cardId}.png`;
              a.click();
              setIsDownloading(false);
            })
            .catch((err) => {
              console.error("oops, something went wrong!", err);
              htmlToImage
                .toPng(svgElement, { cacheBust: true })
                .then((dataUrl) => {
                  const pageArrayData = updateState(false);
                  setPagesArray(pageArrayData);
                  const a = document.createElement("a");
                  a.href = dataUrl;
                  a.download = `${cardId}.png`;
                  a.click();
                  setIsDownloading(false);
                })
                .catch((error) => {
                  const pageArrayData = updateState(false);
                  setPagesArray(pageArrayData);
                  setIsDownloading(false);
                  // downloadContext.setCardId(null);
                  console.error("oops, something went wrong!", error);
                });
            });
          // if map card and first time download, then load after 4 seconds as map takes time to
          // load on dom
        },
        downloadContext.cardType === "map" && count === 0 ? 4000 : 1000
      );
    } else {
      toast.error("Something went wrong. Please try again.");
      const pageArrayData = updateState(false);
      setPagesArray(pageArrayData);
      setIsDownloading(false);
      downloadContext.setCardId(null);
      downloadContext.setCardType("chart");
    }
    setCount(count + 1);
  };

  const saveCardToStory = (sectionIds) => {
    // Save card to story
    if (sectionIds.length) {
      pagesArray &&
        pagesArray.map((data) => {
          if (data.name === "save-card") {
            data.buttonTitle = "saving";
            data.disabled = true;
          }
          return data;
        });
      setPagesArray(pagesArray);
      SaveCardToStoryMutation(
        cardId,
        sectionIds,
        false,
        () => {
          pagesArray &&
            pagesArray.map((data) => {
              if (data.name === "save-card") {
                data.buttonTitle = "saved";
                data.disabled = true;
                data.buttonState = "saved";
              }
              return data;
            });
          setPagesArray(pagesArray);
          setSectionIds([]);
          if (handleReRenderCard) {
            handleReRenderCard(cardId);
          }
          goBack();
        },
        (err) => {
          console.error(JSON.stringify(err));
        }
      );
    }
  };

  const changeCurrentPage = (name) => {
    if (pagesArray && pagesArray.length) {
      pagesArray.map((data) => {
        if (data.name === name) {
          setCurrentPageName(name);
          setCurrentPage(data);
        }
        return data;
      });
    }
  };

  const goBack = () => {
    if (currentPage && currentPage.parent) {
      changeCurrentPage(currentPage.parent);
    } else {
      closePopUp();
    }
  };

  const updateState = (showLoader) => {
    const pagesArrayCopy = [...pagesArray];
    return (
      pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data && data.menuList) {
          data.menuList.map((cardData) => {
            if (cardData.name === "image" || cardData.name === "twitter") {
              cardData.showLoader = showLoader;
            }
            return cardData;
          });
        }
        return data;
      })
    );
  };

  const updateTwitterData = (tweeterText) => {
    const pagesArrayCopy = [...pagesArray];
    return (
      pagesArrayCopy &&
      pagesArrayCopy.map((data) => {
        if (data.name === "twitter-card") {
          data &&
            data.formItems &&
            data.formItems.map((item) => {
              if (item.type === "textarea") {
                item.value = tweeterText;
              }
              return item;
            });
        }
        return data;
      })
    );
  };

  const handleTwitterPost = async () => {
    const pageArrayData = updateState(true);
    setPagesArray(pageArrayData);
    const _query = graphql`
      query CardButtonsPopUpQuery {
        currentUser {
          edges {
            node {
              jsonid
              username
              hasTwitterAccessToken
              userFirstName
            }
          }
        }
      }
    `;
    try {
      const resp = await fetchQuery(environment, _query);
      if (
        !resp ||
        resp.currentUser == null ||
        resp.currentUser.edges == null ||
        resp.currentUser.edges[0] == null
      ) {
        return null;
      } else {
        if (resp.currentUser.edges[0].node.hasTwitterAccessToken) {
          const verifyTwitterToken = await verifyTwitterCredentials();
          if (verifyTwitterToken) {
            changePageTwitterForm();
          } else {
            createTwitterRequestToken();
          }
        } else {
          createTwitterRequestToken();
          console.log("Calling createTwittFerRequestToken !", resp);
        }
      }
    } catch (err) {
      return null;
    }
  };

  const changePageTwitterForm = () => {
    const id = cardId.replace(/=/g, "");
    let svgElement = document.getElementById(`card-family-${id}`);
    if (svgElement) {
      setTimeout(
        async () => {
          domToImage
            .toPng(svgElement, { cacheBust: true })
            .then((dataUrl) => {
              pagesArray &&
                pagesArray.map((data) => {
                  if (data.name === "twitter-card") {
                    let forItem = data.formItems.find(
                      (i) => i.type === "image"
                    );
                    if (forItem) {
                      forItem.src = dataUrl;
                      forItem.component = (
                        <div className="d-flex justify-content-center">
                          <img
                            src={dataUrl}
                            className={`image-preview`}
                            alt="TwitterForm"
                          />
                        </div>
                      );
                    }
                  }
                  return data;
                });
              const pageArrayData = updateState(false);
              setPagesArray(pageArrayData);
              setPagesArray(pagesArray);
              changeCurrentPage("twitter-card");
            })
            .catch(() => {
              const pageArrayData = updateState(false);
              setPagesArray(pageArrayData);
              changeCurrentPage("twitter-card");
            });
        },
        downloadContext.cardType === "map" && count === 0 ? 5000 : 1000
      );
    } else {
      retryCount++;
      if (retryCount <= 5) {
        changePageTwitterForm();
      } else {
        const pageArrayData = updateState(false);
        setPagesArray(pageArrayData);
        changeCurrentPage("twitter-card");
      }
    }
  };

  const createTwitterRequestToken = () => {
    CreateTwitterRequestTokenMutation(
      (response) => {
        if (
          response.createTwitterRequestToken &&
          response.createTwitterRequestToken.success === true
        ) {
          const {
            createTwitterRequestToken: { redirectUrl, oauthToken },
          } = response;
          const authUrl = `${redirectUrl}?oauth_token=${oauthToken}`;
          window.open(authUrl, "", "fullscreen=no");
        }
        const pageArrayData = updateState(false);
        setPagesArray(pageArrayData);
      },
      (err) => {
        console.error(" create token error", err);
        const pageArrayData = updateState(false);
        setPagesArray(pageArrayData);
      }
    );
  };

  const verifyTwitterCredentials = () => {
    return new Promise((resolve) => {
      VerifyTwitterCredentialsMutation(
        (response) => {
          if (response.verifyTwitterCredentials) {
            resolve(response.verifyTwitterCredentials.success);
          }
          resolve(false);
        },
        (err) => {
          console.error(" create token error", err);
          resolve(false);
        }
      );
    });
  };

  return (
    <>
      {pagesArray.length ? (
        <StandardTallModalPopup
          closePopUp={closePopUp}
          goBack={currentPage.name === displayPageName ? null : goBack}
          hash={hash}
          pages={pagesArray}
          currentPage={currentPage}
          selectedCardId={cardId}
        />
      ) : null}
      {showRemoveCard ? (
        <RemoveCardPopUp
          isVisible={showRemoveCard}
          hidePopUp={hidePopUp}
          cardId={cardId}
          {...props}
        />
      ) : null}
    </>
  );
};

export default CardButtonsPopUp;
