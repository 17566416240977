export function LightenDarkenColor(col, amt) {

    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;


    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

export const arrayOfMapPlaceholders = [
    require('../images-placeholder/map1.png'),
    require('../images-placeholder/map2.png'),
    require('../images-placeholder/map3.png'),
    require('../images-placeholder/map4.png'),
    require('../images-placeholder/map5.png'),
    require('../images-placeholder/map6.png'),
    require('../images-placeholder/map7.png'),
    require('../images-placeholder/map8.png'),
    require('../images-placeholder/map9.png'),
];

export const arrayOfStoryPlaceholders = [
    require('../images-placeholder/story1.png'),
    require('../images-placeholder/story2.png'),
    require('../images-placeholder/story3.png'),
    require('../images-placeholder/story4.png'),
    require('../images-placeholder/story5.png'),
];

export const reportToolCardArray = [
    'infantMortalityToolCard',
    'covid19ToolCard',
    'acesToolCard',
    'demographicsToolCard',
    'foodAssessmentCard',
    'mortalityToolCard',
    'areaDeprivationIndexCard',
    'communityIndicatorReportCard',
    'makeAMapCard',
    'browseIndicatorsCard',
    'createNewStory',
    'ToolCard',
    'stiToolCard',
    'preventingViolenceToolCard',
    'suicidePreventionToolCard',
];

const NODE_ENV = process.env.NODE_ENV;

export const apiBaseUrl = (() => {
    if (NODE_ENV === "development") {
        return "https://www.broadstreet.io/";
    } else {
        return "https://www.broadstreet.io/";
    }
})();

export const appBaseUrl = (() => {
    //return "http://localhost:3000/";
    return "https://www.broadstreet.io/";
    // return "https://www.broadstreet.io/";
})();
