import React, { Component, Fragment } from 'react';
import '../../css/admin.settings.css';
import '../../css/themify-icons/css/themify-icons.css';
import LeftNavigationAccountSettings from './LeftNavigationAccountSettings';
import PasswordPageContent from './PasswordPageContent';
import MyProfilePageContent from './MyProfilePageContent';
import HomePageContent from './HomePageContent';
import ReferFriends from './ReferFriends';
import AccountOverview from './AccountOverview';
import Billing from './BillingPage';
import Sponsor from './Sponsor';
import Notifications from './Notifications';
import ScrollToTop from './ScrollToTop';

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { location, avatarImage, handleImageEditorPopUpVisible, avatarEditor, imageId, updatedBoard, handleMembershipActivated } = this.props;
    return (
      <Fragment>
        <ScrollToTop />
        <div
          className={'content-wrapper ' + location.pathname === '/accountSetting' ? 'pb-0' : ''}>
          <div className="page-content fade-in-up">
            <div className="row mx-0 account-settings">
              <div className="col-lg-2 col-md-3 col-12 whitebg pr-3 pr-md-0">
                <LeftNavigationAccountSettings {...this.props} />
              </div>
              <div className="col-lg-10 col-md-9 col-12">
                <div className="ibox">
                  <div className="ibox-body tab-content">
                    {location.pathname === '/accountSetting' ?
                      <AccountOverview
                        avatarImage={avatarImage}
                        imageId={imageId}
                        handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
                        handleMembershipActivated={handleMembershipActivated}
                        avatarEditor={avatarEditor} 
                        isNewSignup={false} /> :
                      location.pathname === '/accountSetting/password' ?
                        <PasswordPageContent /> :
                        location.pathname === '/accountSetting/myProfile' ?
                          <MyProfilePageContent /> :
                          location.pathname === '/accountSetting/referFriends' ?
                            <ReferFriends /> :
                            location.pathname === '/accountSetting/accountOverview' ?
                              <AccountOverview
                                avatarImage={avatarImage}
                                imageId={imageId}
                                handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
                                handleMembershipActivated={handleMembershipActivated}
                                avatarEditor={avatarEditor} 
                                isNewSignup={false} /> :
                            location.pathname === '/accountSetting/accountOverview/new' ?
                              <AccountOverview
                                avatarImage={avatarImage}
                                imageId={imageId}
                                handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
                                handleMembershipActivated={handleMembershipActivated}
                                avatarEditor={avatarEditor}
                                isNewSignup={true} /> :
                              location.pathname === '/accountSetting/billing' ?
                                <Billing /> :
                                location.pathname === '/accountSetting/notifications' ?
                                  <Notifications /> :
                                  location.pathname === '/accountSetting/sponsor' ?
                                    <Sponsor
                                      avatarImage={avatarImage}
                                      imageId={imageId}
                                      handleImageEditorPopUpVisible={handleImageEditorPopUpVisible}
                                      avatarEditor={avatarEditor} /> :
                                    <HomePageContent updatedBoard={updatedBoard} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>);
  }
}

export default AccountPage;
