import React, { Component } from 'react';
import WideCardWrapper from '../WideCard/WideCardWrapper';
import D3PictureProgressChart from './D3PictureProgressChart';

class D3PictureProgressWideChart extends Component {
  render() {
    const {
      id, type, percent, text, baby_info_text, main_text,
      chartData, showTwitterCardPopup
    } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'D3PictureProgressWideChart' : 'D3PictureProgressTwitterChart');
    const content = (
      <div className='D3PictureProgressWideChart d-flex w-100 h-100'>
        <D3PictureProgressChart
          id={wideChartId}
          key={wideChartId}
          percent={percent}
          text={text}
          main_text={main_text}
          type={type}
          baby_info_text={baby_info_text}
          chartData={chartData}
          isWide={true}
        />
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3PictureProgressWideChart;
