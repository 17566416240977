import React, { useState, useEffect, useRef } from "react";
import "../../css/components/Card/D3TextLayoutBabyChart.css";
import * as d3 from "d3";

const D3MultipleHorizontalBarChart = (props) => {
  let margin = { top: 10, right: 0, bottom: 10, left: 0 };
  const clientWidth = 220;
  const clientHeight = 220;
  const duration = 1000;
  const leftLabelTextWidth = 81;
  const bottomLabelTextHeight = 66;

  const parentEle = useRef(null);
  let updateData,
    averageValue,
    svg,
    svgG,
    barG,
    width,
    x,
    y,
    gLine,
    gBottomContainer,
    yAxis,
    height = null;
  const [ratio, setRatio] = useState(1);
  const [mounted, setMounted] = useState(true);
  const { isWide, chartData } = props;

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    setMounted(true);

    return () => {
      setMounted(false);
      window.removeEventListener("resize", onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData) {
      init(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const onWindowResize = () => {
    if (!mounted) return;
    init(props);

    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
  };

  const init = (props) => {
    x = d3.scaleLinear();
    y = d3.scaleBand();

    createSvg(props);
    autoAlignSVG();
    updateInfo(props);
  };

  const autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
    //  Set the dimensions and margins of the diagram
    width = clientWidth - margin.left - margin.right - leftLabelTextWidth;
    height = clientHeight - margin.top - margin.bottom - bottomLabelTextHeight;
    x.rangeRound([width, 0]);
    y.rangeRound([0, height]).padding(0.25);
    svg
      .attr("width", width + margin.left + margin.right + leftLabelTextWidth)
      .attr(
        "height",
        height + margin.top + margin.bottom + bottomLabelTextHeight
      )
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right + leftLabelTextWidth}
          ${
            height +
            margin.top +
            margin.bottom +
            bottomLabelTextHeight
          }`
      );

    svgG.attr(
      "transform",
      "translate(" +
        (margin.left + leftLabelTextWidth) +
        "," +
        (margin.top + 5) +
        ")"
    );
    // xAxis.attr("transform", "translate(0, " + height + ")");
    yAxis.attr("transform", "translate(0, 0)");
    gBottomContainer.attr(
      "transform",
      `translate(0,${
        clientHeight - (margin.bottom + bottomLabelTextHeight + margin.top + 5)
      })`
    );
    gBottomContainer
      .select("foreignObject")
      .attr("height", margin.bottom + bottomLabelTextHeight)
      .attr("width", clientWidth - leftLabelTextWidth);
  };

  const updateInfo = (props) => {
    const { chartData } = props;
    if (chartData) {
      update();
    }
  };
  const createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    svg = d3.select(parentEle.current).select("svg#" + id);
    const gSelection = svg.selectAll("g.g-container").data([id], (d) => d);
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("id", (d) => d + "gcontainer")
      .attr("class", "g-container");
    gSelection.exit().remove();
    svgG = svg.select("g#" + id + "gcontainer");

    barG = svgG.selectAll("g.bar-container").data([id], (d) => d);
    barG
      .enter()
      .append("g")
      .merge(barG)
      .attr("class", "bar-container")
      .attr("id", (d) => d + "bar-container");
    barG.exit().remove();
    barG = svgG.select("g#" + id + "bar-container");
    gLine = svgG.selectAll("g.dashed-line").data([id], (d) => d);
    gLine
      .enter()
      .append("g")
      .merge(gLine)
      .attr("class", "dashed-line")
      .attr("id", (d) => d + "dashed-line");
    gLine.exit().remove();
    gLine = svgG.select("g#" + id + "dashed-line");
    let gAxisSelection = svgG
      .selectAll("g.axis-container")
      .data([id], (d) => d);
    gAxisSelection
      .enter()
      .append("g")
      .merge(gAxisSelection)
      .attr("class", "axis-container")
      .attr("id", (d) => d + "axis-container");
    gAxisSelection.exit().remove();
    let axisG = svgG.select("g#" + id + "axis-container");
    yAxis = axisG.selectAll(".y.axis").data([id], (d) => d);
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("id", (d) => d + "yAxis")
      .attr("class", "y axis");
    yAxis.exit().remove();
    yAxis = axisG.select("g#" + id + "yAxis");

    gBottomContainer = svgG
      .selectAll("g.bottom-container")
      .data([id], (d) => d);
    gBottomContainer
      .enter()
      .append("g")
      .merge(gBottomContainer)
      .attr("class", "bottom-container")
      .attr("id", (d) => d + "bottom-container");
    gBottomContainer.exit().remove();
    gBottomContainer = svgG.select("g#" + id + "bottom-container");
    const path = gBottomContainer.selectAll("path").data([id], (d) => d);
    path
      .enter()
      .append("path")
      .merge(path)
      .attr("class", "path")
      .attr("id", (d) => d + "path");
    path.exit().remove();
    const foreignObject = gBottomContainer
      .selectAll("foreignObject")
      .data([id], (d) => d);
    foreignObject
      .enter()
      .append("foreignObject")
      .merge(foreignObject)
      .attr("class", "foreignObject")
      .attr("x", "0")
      .attr("y", "0")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("id", (d) => d + "foreignObject");
    foreignObject.exit().remove();
  };
  const update = () => {
    const { chartData } = props;
    /* const totalValue = chartData.data
      .map((d) => d.percent)
      .reduce((total, value) => total + value);*/
    const usData = chartData.data.find(d => d.label === 'United States');
    averageValue = usData ? usData.percent : 0;
    const firstThreeData = chartData.data.slice(0, 3);
    firstThreeData.push({ label: "" });
    updateData = firstThreeData.concat(
      chartData.data.slice(3, chartData.data.length)
    );

    autoAlignSVG();

    if (updateData && updateData.length) {
      x.domain([100, 0]);
      y.domain(updateData.map((d) => d.label));
      updateYAxis();
      updateStackedBar();
      updateDashedLine();
      updateTextDetails();
    }
  };
  const updateYAxis = () => {
    yAxis
      .call(d3.axisLeft(y))
      .selectAll(".tick text") // select all the x tick texts
      .call(function (t) {
        t.each(function () {
          // for each one
          const self = d3.select(this);
          const s = self.text().split("-"); // get the text and split it
          self.text(""); // clear it out
          const sSelection = self.selectAll("tspan").data(s);
          const newSelection = sSelection.enter().append("tspan"); // insert two tspans
          newSelection
            .merge(sSelection)
            .attr("x", "-9px")
            .attr("dy", "0.35em")
            .attr("font-size", "11px")
            .attr("color", "#424B54")
            .attr("font-family", "ProximaNova-Regular")
            .text((d) => d)
            .call((elements) => {
              elements.each(function () {
                let tspan = d3.select(this);
                let textLength = tspan.node().getComputedTextLength(),
                  text = tspan.text();
                while (
                  textLength > leftLabelTextWidth - 10 &&
                  text.length > 0
                ) {
                  text = text.slice(0, -1);
                  tspan.text(text + "...");
                  textLength = tspan.node().getComputedTextLength();
                }
              });
            });
          sSelection.exit().remove();
        });
      });
    yAxis.select(".domain").remove();
    yAxis.selectAll(".tick line").remove();
  };
  const updateDashedLine = () => {
    const line = gLine
      .selectAll("line.dash-line")
      .data([averageValue, averageValue], (d, i) => d + "" + i);
    const newLine = line
      .enter()
      .append("line")
      .style("opacity", 0)
      .attr("x1", 0)
      .attr("x2", 0);
    newLine
      .merge(line)
      .attr("class", "dash-line")
      .style("stroke", (d, i) => (i === 1 ? "#FFFFFF" : "#424B54"))
      .style("stroke-width", (d, i) => (i === 1 ? "1px" : "1px"))
      .style("stroke-linecap", "butt")
      .style("stroke-dasharray", (d, i) => (i === 0 ? "" : "4,4"))
      .attr("y1", () => {
        const offset = y.paddingOuter() * y.bandwidth();
        return y(y.domain()[0]) - offset - 5;
      })
      .attr("y2", () => {
        const offset = y.paddingOuter() * y.bandwidth();
        return (
          y(y.domain()[updateData.length - 1]) + y.bandwidth() + offset + 5
        );
      })
      .transition()
      .duration(duration)
      .attr("x1", (d) => x(d))
      .attr("x2", (d) => x(d))
      .style("opacity", 1);
    line.exit().transition().duration(duration).style("opacity", 0).remove();
  };
  const updateStackedBar = () => {
    const stack = barG.selectAll("g.stack").data(
      [
        {
          type: "blank",
          color: "dark",
          data: updateData,
        },
        {
          type: "percent",
          color: "light",
          data: updateData,
        },
      ],
      (d) => d.color
    );
    const newStack = stack.enter().append("g");
    newStack
      .merge(stack)
      .attr("class", "stack")
      .transition()
      .duration(duration);

    stack.exit().remove();
    const bar = newStack
      .merge(stack)
      .selectAll("rect.bar")
      .data(
        (d) => {
          return d.data
            .filter((c) => c.label !== "")
            .map((c) => ({ parent: d.type, data: c }));
        },
        (d) => d.data.label
      );
    const newBar = bar.enter().append("rect");
    newBar
      .merge(bar)
      .attr("class", "bar")
      .attr("y", (d) => y(d.data.label))
      .attr("height", y.bandwidth())
      .transition()
      .duration(duration)
      .attr("x", 0)
      .attr("width", (d) =>
        d.parent === "blank" ? x(x.domain()[0]) : x(d.data.percent)
      )
      .style("fill", (d) => {
        return d.parent !== "blank"
          ? d.data.percent > averageValue
            ? "#D35721"
            : "#424B54"
          : "#E3E3E3";
      });
    bar.exit().remove();
  };
  const updateTextDetails = () => {
    const textDetailsHtml =
      "<div class='main-text fs-12' style='color:#D35721;'>Over the" +
      " Average" +
      " </div><div class='main-text fs-12'>for the United States</div>";
    const divElement = gBottomContainer
      .select("foreignObject")
      .selectAll("#detailsText")
      .data([id], (d) => d);
    divElement
      .enter()
      .append("xhtml:div")
      .merge(divElement)
      .attr(
        "class",
        "d-flex flex-column text-right text-nowrap justify-content-end"
      )
      .attr("id", "detailsText")
      .style("height", bottomLabelTextHeight + "px")
      .html(textDetailsHtml);
    divElement.exit().remove();

    const extraPadding = 20;
    const topHeight = 4;
    const bottomHeight = 12;
    const path = gBottomContainer.select("path");
    let startXPosition = x(averageValue) + 5;
    let endXPosition = x(x.domain()[0]) - 1;

    const MakePath = `M${startXPosition} ${
      extraPadding + 0
    } L${startXPosition} ${extraPadding + topHeight} M${startXPosition} ${
      extraPadding + topHeight
    } L${endXPosition} ${extraPadding + topHeight} M${endXPosition} ${
      extraPadding + topHeight
    } L${endXPosition} ${extraPadding + 0} M${
      (endXPosition + startXPosition) / 2
    } ${extraPadding + topHeight} L${(endXPosition + startXPosition) / 2} ${
      extraPadding + bottomHeight
    }`;
    path
      .attr("d", MakePath)
      .attr("stroke-width", "1px")
      .attr("stroke", "#424B54");
  };

  const id = props.id.replace(/=/g, "");

  return (
    <div
      className="D3TextLayoutBabyChart d-flex flex-column h-100 w-100 justify-content-center align-items-center"
      ref={parentEle}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "245px",
          width: "245px",
          padding: "0 16px",
          transform: `scale(${ratio})`,
        }}
      >
        <svg id={id}> </svg>
      </div>
    </div>
  );
};

export default D3MultipleHorizontalBarChart;
