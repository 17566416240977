import React, { useState, useEffect, useRef } from "react";
import "../../css/components/Card/D3TextLayoutBabyChart.css";
import * as d3 from "d3";
import FilledHeartImg from '../../images/iconFilledHeart.svg';

const D3HeartChart4 = (props) => {
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const clientWidth = 220;
  const clientHeight = 220;
  const labelTextWidth = 89;
  const textDetailsHeight = 45;
  const centerSpace = 20;
  const parentEle = useRef(null);
  let svg,
    svgG,
    width,
    height = null;
  const [ratio, setRatio] = useState(1);
  const [mounted, setMounted] = useState(true);
  const { isWide, chartData } = props;

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    setMounted(true);

    return () => {
      setMounted(false);
      window.removeEventListener("resize", onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData) {
      init(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const onWindowResize = () => {
    if (!mounted) return;
    init(props);

    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
  };

  const init = (props) => {
    createSvg(props);
  };

  const createSvg = (props) => {
    const id = props.id.replace(/=/g, "");
    svg = d3.select(parentEle.current).select("svg#" + id);
    svgG = d3.select("g#" + id + "-g-container");
    autoAlignSVG();
    updateInfo(props);
  };

  const autoAlignSVG = () => {
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      const size = Math.min(
        parentEle.current.clientWidth,
        parentEle.current.clientHeight
      );
      if (isWide && ratio !== size / 245) {
        setRatio(size / 245);
      }
    }
    //  Set the dimensions and margins of the diagram
    width = clientWidth - margin.left - margin.right;
    height = clientHeight - margin.top - margin.bottom;
    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right}
                    ${height + margin.top + margin.bottom}`
      );
    svgG.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svgG
      .select("foreignObject")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svgG
      .select("foreignObject")
      .select("#parentHeartDivElement")
      .style("height", height + margin.top + margin.bottom + "px");
  };

  const updateInfo = (props) => {
    const { chartData } = props;
    const id = props.id.replace(/=/g, "");
    if (chartData) {
      makeBaby(id);
      updateIconsAndLabels(chartData);
      updateTextDetails(chartData);
    }
  };
  const makeBaby = () => {
    const newWidth = clientWidth - labelTextWidth - centerSpace;
    const babyContainer = svgG.select("foreignObject").select("#babyContainer");
    const labelEle = babyContainer.select(".labels");

    const labelsHtml =
      "<span class='details-text fs-14'>" +
      chartData.label +
      " </span><br/><span class='main-text fs-14'>" +
      chartData.label2 +
      " </span><span class='details-text fs-14' >" +
      chartData.label3 +
      "</span>";
    labelEle.html(labelsHtml);
    const babyIcon = babyContainer.selectAll(".baby-icon").data([id], (d) => d);
    const newG = babyIcon.enter().append("div");
    newG
      .merge(babyIcon)
      .attr("class", "baby-icon flex-grow-1  d-flex")
      .style("width", newWidth + "px")
      .style("height", "60px")
      .style("position", "relative");

    const heartSvg = newG.selectAll("svg.heart-svg").data(
      (d) => [d],
      (d) => d
    );
    const newHeartSVGEle = heartSvg.enter().append("svg");
    newHeartSVGEle
      .merge(heartSvg)
      .attr("class", "heart-svg")
      .attr("viewBox", "0 0 512 512")
      .attr("height", 20)
      .attr("width", 20)
      .style("position", "absolute")
      .style("left", "calc(50% - " + 10 + "px )")
      .style("top", "calc(50% - " + 10 + "px )")
      .style("z-index", "1");

    const heartPath = newHeartSVGEle.selectAll("path.heart").data(
      (d) => [d],
      (d) => d
    );
    const newHeartPathEle = heartPath.enter().append("path");
    newHeartPathEle
      .merge(heartPath)
      .attr("class", "heart")
      .attr("fill", "#D35721")
      .attr("fill-opacity", 0.75)
      .attr(
        "d",
        "M376,30c-27.783,0-53.255,8.804-75.707,26.168c-21.525,16.647-35.856,37.85-44.293,53.268    " +
          "c-8.437-15.419-22.768-36.621-44.293-53.268C189.255,38.804,163.783,30,136,30C58.468,30,0,93.417,0,177.514    " +
          "c0,90.854,72.943,153.015,183.369,247.118c18.752,15.981,40.007,34.095,62.099,53.414C248.38,480.596,252.12,482,256,482    " +
          "s7.62-1.404,10.532-3.953c22.094-19.322,43.348-37.435,62.111-53.425C439.057,330.529,512,268.368,512,177.514    " +
          "C512,93.417,453.532,30,376,30z"
      );
    heartPath.exit().remove();
    heartSvg.exit().remove();

    const svg = newG.selectAll("svg.baby-svg").data(
      (d) => [d],
      (d) => d
    );
    const newSVGEle = svg.enter().append("svg");
    newSVGEle
      .merge(svg)
      .attr("class", "baby-svg")
      .attr("viewBox", "0 0 610.1 207.1")
      .attr("height", "100%")
      .attr("width", "100%")
      .style("z-index", "2");

    const bodyPath = newSVGEle.selectAll("path.body").data(
      (d) => [d],
      (d) => d
    );
    const newBodyPathEle = bodyPath.enter().append("path");
    newBodyPathEle
      .merge(bodyPath)
      .attr("class", "body")
      .attr("fill", "none")
      .attr("stroke", "#424B54")
      .attr("stroke-width", "4")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .attr("stroke-miterlimit", "22.93")
      .attr(
        "d",
        "M609.9,88.4c-22.4,2.4-44.8,4.7-61.8,9.3s-28.3,11.4-37.2,15.9c-8.9,4.6-15.4,7-21.1,7.9s-10.6,0.5-14.2-1  " +
          "s-6.1-3.9-6.3-4.5c-0.2-0.6,1.7,0.7,4.2,2c2.6,1.4,5.3,2.5,8.2,3.3c2.6,0.6,4.8,0.5,5.3-0.3s-0.7-2.3-1.6-2.9s-1.6-0.3-1.1,0.2  " +
          "c1.2,0.7,2.6,1.1,4,1.3c1.7,0.5,3.5,0.5,5.2,0.2c1.1-0.3,1.9-1.4,1.7-2.5c-0.2-0.7-0.8-1.4-1.5-1.7c-0.2,0,0.6,0.8,1.8,1.5  " +
          "c1.4,0.8,3,1.1,4.5,0.9c1.7-0.3,3.3-1.4,3.7-2.6s-0.6-2.4-1.8-3.1s-2.4-0.7-2.3-0.4c1.1,0.6,2.2,1.1,3.4,1.6c1.6,0.8,3,1.7,4.5,1.7  " +
          "c1.6-0.1,3-1,3.8-2.4c0.9-1.5,0.9-3.5,0-5c-1-1.6-3.1-2.8-5.2-3.9c-2.4-1.2-4.6-2.5-6.9-4c-2.5-1.8-4.8-3.7-7-5.9  " +
          "c-1.9-1.8-2.9-3-2.1-3s3.4,1.3,5.9,3.1s4.8,4.2,7.2,5.9c2.2,1.4,4.4,2.6,6.8,3.6c1.9,1,3.2,2,4.9,2.7c1.8,0.8,3.8,1,5.8,0.8  " +
          "c1.6-0.2,3.1-1.1,4.1-2.4c0.6-0.9,0.8-2.1,0.3-3.2c-0.4-1.2-1.4-2.2-2.6-2.6c-1.5-0.6-3.6-0.9-4.9-1.5c-1.3-0.6-1.9-1.4-1.3-1.4  " +
          "c1.4,0.5,2.7,1,4,1.7c1.7,0.8,3.5,1.3,4.8,1.1c1.3-0.3,2.4-1.2,3.1-2.4c0.6-0.8,0.7-1.9,0.1-2.7c-1-1-2.2-1.7-3.6-2  " +
          "c-1.2-0.4-2.5-0.7-3.7-0.8c-0.3,0.1,1.1,0.6,2.8,0.9s3.4,0.1,4.9-0.5c1.1-0.6,1.6-1.9,1.4-3.1c-0.1-1.5-1-2.8-2.3-3.5  " +
          "c-1.5-0.5-3.1-0.7-4.7-0.5c-0.5,0.1,1.3,0.2,2.8,0.1s2.7-0.5,3.2-1.3s0.3-1.8-0.4-3c-0.9-1.7-2.2-3.3-3.8-4.5  " +
          "c-3.3-2.7-6.8-5.1-10.5-7.2c-5.1-3.1-12-7-16.9-10s-7.7-5.2-10.6-6.5c-3-1.3-6.4-1.7-9.6-1.2c-3.8,0.7-7.6,2-11.1,3.8  " +
          "c-3.7,1.8-7.4,3.8-10.3,4.9c-3,1.1-5.2,1.2-6.1,2.3s-0.4,3.3-0.4,5.7c-0.1,2.3-0.4,4.7-1,6.9c-0.4,1.8-0.6,2.8,0,4.2  " +
          "c0.7,1.5,1.7,2.9,2.9,4c0.9,0.6,1.2-0.1,0.5-1.8s-2.6-4.3-3.9-7.1c-1.3-2.8-1.8-6-1.5-9.1c0.4-3.4,1.8-7.2,0.4-15.9  " +
          "s-5.7-22.2-19.2-30.2s-36-10.5-53.2-7.7s-29.1,11-40.9,16.7s-23.7,8.6-35.9,11.4s-24.9,5.5-35.2,8c-10.3,2.5-18.4,4.7-23.4,5.4  " +
          "s-6.9-0.1-9.4-1.3c-2.6-1.4-5.4-2.6-8.2-3.5c-2.5-0.7-4.4-0.5-6.5-1.5s-4.5-2.9-6.6-4s-3.9-1.2-4.2-0.2s1,3.1,3.8,5.2  " +
          "c3.1,2.2,6.4,3.9,10,5.1c3,1,4.7,0.8,6,1.8s2,3.1,3.5,4.5s3.5,1.9,5.8,3s5,2.8,7.2,3.8c2.2,1,4,1.3,3.9-0.3s-2.3-5.2-5.3-7.9  " +
          "s-6.9-4.7-7.8-4.1s1.2,3.9,3.8,6.7c2.7,2.8,5.6,5.4,8.7,7.9c3,2.6,6,5.4,9.7,8.2c3.7,2.8,7.9,5.7,11.5,9.4c3.4,3.7,6.2,7.8,8.4,12.2  " +
          "c1.6,3.2,2.9,6.5,3.9,9.9c0.8,2.3,1.3,4.7,1.3,7.1c0,2.2-0.7,4.4-0.6,6.8s0.8,4.8,0.6,7.6c-0.3,3-1,6-2.1,8.8  " +
          "c-1,2.9-2.1,5.5-2.6,5.9s-0.4-1.5-1.5-2.6s-3.1-1.3-4.7-1.1s-2.6,0.7-3,0.4s0-1.5,0.8-2.6s2.3-2.1,3.1-3.6c0.5-1,0.9-2.1,1-3.2  " +
          "c-0.2,1.3-0.3,2.5-0.2,3.8c0.1,1.8,0.7,3.4,0.8,4.9c0.1,1.6-0.2,3.1-0.7,4.6c-0.6,1.7-1.4,3.4-2.5,4.8c-1.2,1.7-3,3.5-3.6,3.6  " +
          "s-0.3-1.5-0.5-3.2s-1.2-3.8-1-5.1s1.8-2.2,2-1.6s-0.7,2.4-2.1,2.6s-3.3-1-5.1-1.8c-1.8-0.8-3.5-1-5.2-1.9s-3.2-2.4-4.3-2  " +
          "s-1.5,2.6-1.2,3.8s1.3,1.6,2.3,0.8c1.2-1.2,2.1-2.7,2.5-4.3c0.4-1.5,0.2-2.6,0.8-3.3s1.7-0.9,2.1-1.9c0.3-1.4,0-2.8-1-3.9  " +
          "c-1.1-1-2.6-1.3-4-0.9c-1.6,0.4-3.2,1.4-3.2,1.7s1.6-0.2,2.8,0.2c1.2,0.5,2,1.8,1.8,3.1c-0.1,1.5-1.1,3.1-1,4.9s1.4,3.6,1.2,5.2  " +
          "s-1.8,3-2.5,4.5s-0.8,2.8-2.1,3.4s-3.8,0.1-5.8-1.3s-3.4-3.8-5.8-5.4c-2.5-1.6-5.5-2.4-8.5-2.4c-2.5,0.1-4.1,1.1-4.9,3  " +
          "s-0.8,4.5-1.3,6.9s-1.7,4.6-3.4,6.4c-1.8,2.1-4.4,4.2-4.7,4.8s1.8-0.2,3.6-1.4c4-2.3,6.6-6.5,6.6-11.2c0-2.1-0.7-3.9-1.9-3.7  " +
          "s-3,2.3-4.5,4.9s-2.8,5.6-4.6,8.4c-1.8,2.8-4.2,5.2-7.1,6.9c-2.7,1.4-5.8,1.9-8.8,1.4c-2.8-0.4-5.2-1.7-8.5-2.3s-7.7-0.4-13.4-0.5  " +
          "c-7-0.2-14-1-20.8-2.6c-8.8-2-17.2-5.5-24.8-10.4c-8.4-5.6-15.5-12.9-21-21.3c-5.8-8.7-10.1-18.7-11.3-26.2s0.8-12.3,2.7-14.9  " +
          "s3.7-2.7,3.4-3.4s-2.7-2.1-2.3-5.8s3.4-9.7,6.8-14.4c2.7-3.8,5.9-7.3,9.5-10.1c2.6-1.9,4.1-2.3,6.5-4.4s5.9-5.9,14.2-9.4  " +
          "s21.8-6.7,32.7-7.8s19.3-0.3,26,3s11.6,9.1,15,11.9s5.4,2.7,4.3,3c-3,0.7-6,1.1-9.1,1.1c-3.8,0-7.3-0.7-11.3,1.6s-8.6,7.5-12,11.9  " +
          "s-5.6,8.1-6.2,12.5c-0.6,4.9,0.4,9.8,2.8,14.1c2.5,4.5,6.5,8.3,8.7,12.8c2.2,4.6,2.6,9.8,1.2,14.7c-1.4,5-4.7,9.8-5,11  " +
          "s2.3-1.1,4-3.7s2.4-5.2,3.1-4.6s1.5,4.5,0.5,8.7s-4,8.7-7,12.6s-6.1,7.1-7.4,10.4s-0.8,6.6-2.6,8.1s-5.8,1.3-2.4,1.8  " +
          "s14.1,1.9,20.9,2.5c3.9,0.4,7.9,0.5,11.9,0.4c2,0,4.1,0,6.1,0.2c2.8,0.2,5.6,0.7,8.4,1.3c4,1.1,8.2,1.9,12.3,2.5  " +
          "c5.2,0.6,10.6,0.1,15.6-1.5c4.9-1.7,9-4.9,12.2-7.3c3.2-2.4,5.2-4.2,6.3-6.5s0.9-5.1,1.1-5.3s0.7,2.4,1.9,4.7s3.2,4.6,5.1,7.7  " +
          "c1.9,3.1,3.4,6.5,4.1,10c0.4,2.8-0.5,4.5-2.6,5.2s-5.5,0.6-8.4,0.6s-5.5,0.2-8.8,0.5c-3.3,0.3-7.5,0.8-11.1,0.9s-6.6-0.2-8.3-1.1  " +
          "s-2.2-2.3-1.6-3.4s2.4-1.9,4.9-2.6c2-0.6,4-1,6-1.2c0.2,0.1-2.8,1-6,1.7s-6.4,1.4-9.6,1.9c-3,0.6-6.1,1-9.2,1.2  " +
          "c-2.9,0.2-5.4,0-6.6-1.3c-1.1-1.5-1.1-3.5,0-5c1-1.2,2.8-1.2,2.7-0.5s-2.2,2.1-4.4,2.5c-2.2,0.3-4.4-0.1-6.3-1.4  " +
          "c-1.7-1.1-2.7-2.7-0.5-3.2s7.5,0,13.5,0.7s12.5,1.5,19.9,0.5s15.6-3.8,21.1-7.9c5.4-4.1,8.1-9.7,12.1-13.4s9.3-5.6,15.8-6.8  " +
          "c7.2-1.1,14.4-1.6,21.7-1.3c6.4,0.2,12.9,1,19.1,2.6c5,1.4,8.2,3.3,6.2,3.4s-9.4-1.7-15.1-3.6c-4.6-1.5-9.1-3.6-13.3-6.1  " +
          "c-3.5-2.2-6.6-4.5-9.8-5.4s-6.4-0.6-10-2.7c-4.6-2.7-7.6-7.4-8.3-12.6c-0.5-6,2.4-13.2,2.3-19.7s-3.3-12.2-1.2-16.8s9.3-8,18.5-6.6  " +
          "s20.3,7.8,29.4,15.8c8.5,7.5,15.6,16.6,20.8,26.8c4.3,8.2,7.2,17,8.5,26.1c1,7.5,0.4,13.3-1.8,18.2c-2.1,5.1-6.2,9-11.3,11.1  " +
          "c-5.5,2.1-12.6,2.4-19.3,3.2s-12.8,2.2-18.6,3.2c-5.4,0.8-10.8,1.3-16.3,1.5c-5.2,0.2-10.1,0.2-11.1-0.4s2.1-1.8,2.7-3.3  " +
          "s-1.4-3.4,0-3.8s6.1,0.8,14.2,1c9.7,0.1,19.3-0.7,28.8-2.4c9.4-1.9,16.8-4.8,22.1-8s8.4-6.8,12.2-8.3s8.1-1,13.6-0.4  " +
          "c6.1,0.7,12.3,0.8,18.5,0.4c6.3-0.8,12.4-2.4,18.3-4.7c6.7-2.4,13.1-5.6,19.1-9.4c6.1-4,11.5-9.1,18.8-15.4s16.6-13.8,22.7-20.2  " +
          "s9-11.7,11.1-17.7s3.4-12.6,2.8-10.7s-2.8,12.3-7.4,21.6c-4.4,8.9-10.7,16.7-18.5,22.9c-6.5,5-14.4,7.9-22.6,8.3  " +
          "c-8.6,0.4-17.2-0.7-25.4-3.5c-7.3-2.6-12.4-6.3-15.7-10.2c-2.9-3.3-4.8-7.4-5.5-11.7c-0.5-3.1-0.3-6.3,0.8-9.3c1-2.8,2.5-5.3,7-7.7  " +
          "c4.6-2.4,12.1-4.6,20.9-11.1s18.8-17.3,24.6-25.6s7.5-14.3,3.4-22.5s-14.1-18.7-29.6-23.3s-36.4-3.4-51.4-0.4s-24.3,8-25.2,14.2  " +
          "s6.3,13.6,10.7,23.3s6,21.5,5.1,32.3s-4.4,20.6-4.4,29.2s3.5,16,6.9,25.1s6.7,19.8,6.3,27.2c-0.4,7.3-4.5,11.3-6.2,11.9  " +
          "s-1-1.9,4.5,5.6s15.6,25.1,21.7,29.9s7.9-3.3,4.4-10.4s-12.6-13.3-34.7-10.4s-57.3,14.7-91.6,15.5s-67.6-9.5-97-23.1  " +
          "s-54.8-30.5-77.1-51.3c-22.7-21.1-41.9-45.9-56-57.9S9.4,55,0.2,55.8"
      );

    bodyPath.exit().remove();
    svg.exit().remove();
    newG.exit().remove();
    babyIcon.exit().remove();
  };
  const updateTextDetails = (data) => {
    const textElement = svgG.select("foreignObject").select("#detailsText");
    const textDetailsHtml =
      "<span style='width:61px;'>" + data.label6 + " </span>";
    textElement.html(textDetailsHtml);
  };
  const updateIconsAndLabels = (data) => {
    let value = data.value ? data.value : 0;
    const arrayData = Array.from(new Array(Math.round(value)), (x, i) =>
      value >= i + 1 ? 1 : i + 1 - value
    );
    const parentHeartElement = svgG
      .select("foreignObject")
      .select("#heartContainer");
    if (value === 0) {
      const NoDataHtml =
        "<span class='main-text fs-14' style='width:86px;'>Unknown, No data.</span>";
      const showNoDataLabel = parentHeartElement
        .select(".icons")
        .selectAll(".icon")
        .data([value], (d) => d);
      showNoDataLabel
        .enter()
        .append("div")
        .merge(showNoDataLabel)
        .attr("class", "icon d-flex justify-content-end w-100 text-right")
        .html(NoDataHtml);
      showNoDataLabel.exit().remove();
    } else {
      const allHeartIconElements = parentHeartElement
        .select(".icons")
        .selectAll(".icon")
        .data(arrayData);
      allHeartIconElements
        .enter()
        .append("div")
        .merge(allHeartIconElements)
        .attr("class", "icon")
        .style("height", "18px")
        .style("margin", "1px")
        .style("width", (d) => (d === 1 ? "20px" : "10px"))
        .style('background', `url(${FilledHeartImg})`)
        .style('background-size', 'cover')
        .style('background-repeat', 'no-repeat');

      allHeartIconElements.exit().remove();
    }
    const HeartLabelEle = parentHeartElement.select(".labels");
    const labelsHtml =
      "<span class='main-text fs-14' style='color:#D35721;'>" +
      data.label4 +
      " </span><span class='main-text fs-14'>" +
      data.label5 +
      " </span>";
    HeartLabelEle.html(labelsHtml);
  };

  const id = props.id.replace(/=/g, "");
  const detailsTextStyle = {
    fontSize: "11px",
    fontFamily: "ProximaNova-Regular",
    lineHeight: 1.2,
    display: "block",
    color: "#424B54",
    height: textDetailsHeight,
  };
  return (
    <div
      className="D3TextLayoutBabyChart d-flex flex-column h-100 w-100
      justify-content-center align-items-center"
      ref={parentEle}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "245px",
          width: "245px",
          padding: "0 16px",
          transform: `scale(${ratio})`,
        }}
      >
        <svg id={id}>
          <g id={id + "-g-container"} className="g-container">
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div
                id="parentHeartDivElement"
                className="d-flex align-items-center justify-content-center flex-column w-100"
              >
                <div
                  id="babyContainer"
                  className="baby-container flex-grow-1 justify-content-center d-flex w-100"
                  style={{ height: (clientHeight - textDetailsHeight) / 2 }}
                >
                  <div
                    style={{
                      width: labelTextWidth,
                      fontSize: "14px",
                      fontFamily: "ProximaNova-Regular",
                      lineHeight: 1.2,
                    }}
                    className="labels flex-grow-1 text-left"
                  />
                  <div className="d-flex" style={{ width: centerSpace }} />
                  <div
                    style={{
                      width: clientWidth - labelTextWidth - centerSpace,
                    }}
                    className="baby-icon flex-grow-1  d-flex"
                  />
                </div>

                <div
                  id="heartContainer"
                  className="heart-Container flex-grow-1 d-flex w-100 justify-content-center"
                  style={{ height: (clientHeight - textDetailsHeight) / 2 }}
                >
                  <div
                    style={{
                      width: labelTextWidth,
                      fontSize: "14px",
                      fontFamily: "ProximaNova-Regular",
                      lineHeight: 1.2,
                    }}
                    className="labels text-left"
                  />
                  <div className="d-flex" style={{ width: centerSpace }} />
                  <div
                    style={{
                      width: clientHeight - labelTextWidth - centerSpace,
                      height: "fit-content",
                    }}
                    className="icons flex-grow-1 d-flex flex-wrap-reverse align-items-end"
                  />
                </div>
                <div
                  id="detailsText"
                  className="details-text flex-grow-1 w-100 d-flex justify-content-end align-items-center"
                  style={detailsTextStyle}
                />
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default D3HeartChart4;
