import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

let buttonStyle = (props) => css`
  cursor: pointer;
  color: ${props.reversed
  ? (props.active ? 'black' : '#292929')
  : (props.active ? 'black' : '#292929')};
`;
export const Button = styled('span')`
    ${buttonStyle}
`;

export const Icon = styled(({className, ...rest}) => {
  return <span className={`material-icons ${className}`} {...rest} />;
})`
  font-size: 18px;
  vertical-align: text-bottom;
`;

export const Menu = styled('div')`
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`;

export const Toolbar = styled(Menu)`
  position: relative;
  padding: 10px;
  margin: 0 0px 15px;
  border-bottom: 2px solid #eee;
  background-color: #f4f4f4;
  border: 1px solid #989898;
  border-radius: 5px;
`;
