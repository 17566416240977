import React, { Component } from 'react';

class CardCitation extends Component {

  render() {
    const {data} = this.props;
    return (
      <div className="CardCitationSub position-relative">
        <span className={`my-2 w-100`}>{data}</span>
        <div className="CardCitation-border position-relative"/>
      </div>
    );
  }
}

export default CardCitation;
