import React, { Component } from "react";
import { QueryRenderer, commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/components/CommonComponents/SearchHashTags.css";
import "../../css/components/CommonComponents/AddMoreIcon.css";

class SelectHashTagListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  handleClick = () => {
    const { handleAddRegularHashTag, handleRemoveTagsApi, data } = this.props;
    const { checked } = this.state;
    if (checked === false) {
      this.setState(
        {
          checked: true,
        },
        () => handleAddRegularHashTag(data.node.name)
      );
    } else if (checked === true) {
      this.setState(
        {
          checked: false,
        },
        () => handleRemoveTagsApi(data.node.name)
      );
    }
  };

  render() {
    const { data } = this.props;
    const { checked } = this.state;
    return (
      <label>
        <input
          defaultChecked={checked}
          type="checkbox"
          name="hashtag"
          onClick={this.handleClick}
        />
        {data.node.name}{" "}
      </label>
    );
  }
}

class EditHashTagPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTag: null,
      arrayAddedTags: [],
      searchText: "",
      isUpdateData: false,
    };
  }

  handleCreateHashTag = () => {
    const { searchText } = this.state;
    const mutation = graphql`
      mutation EditHashTagPopUpBMutation($input: CreateBoardTagInput!) {
        createBoardTag(input: $input) {
          boardTagNode {
            id
            name
          }
        }
      }
    `;
    const variables = { input: { name: searchText } };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO first check if response returns true then chnage the state
        this.setState((prevState) => ({
          isUpdateData: !prevState.isUpdateData,
        }));
      },
      onError: (err) => console.error(err),
    });
  };

  handleChild = (e) => {
    e.stopPropagation();
  };

  handleAddRegularHashTag = (tag) => {
    this.setState(
      {
        selectedTag: tag,
      },
      () => this.apiAddRegularHashTag()
    );
  };

  apiAddRegularHashTag = () => {
    const { selectedCardId, handleAddingTags } = this.props;
    const { selectedTag } = this.state;
    const mutation = graphql`
      mutation EditHashTagPopUpMutation($input: AddHashtagToCardInput!) {
        addHashtagToCard(input: $input) {
          boardTagNode {
            id
            name
          }
        }
      }
    `;
    const variables = {
      input: {
        boardCardUseId: selectedCardId,
        tagName: selectedTag,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO first check if response returns true then hit the callback method
        handleAddingTags({
          node: {
            boardTag: {
              name: selectedTag,
              permaTag: 0,
            },
          },
        });
      },
      onError: (err) => console.error(err),
    });
  };

  onTextChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  render() {
    const { x, y, handleRemoveTagsApi, tags, isVisible } = this.props;
    const { searchText } = this.state;
    return (
      <div
        style={{ left: x - 45, top: y, position: "absolute", zIndex: 1 }}
        onClick={this.handleChild}
      >
        {isVisible ? (
          <div className="SearchtagsContainer">
            <ul className="searchoptionlist">
              <li className="nameemail">
                <input
                  type="text"
                  placeholder="Search Hashtags"
                  className=""
                  value={searchText}
                  onChange={(e) => {
                    this.onTextChange(e);
                  }}
                />
              </li>
              {/* <li className="searchoptionchekbox"> */}
              <QueryRenderer
                environment={environment}
                query={graphql`
                  query EditHashTagPopUpQuery($input: String) {
                    myHashtags(search: $input) {
                      edges {
                        node {
                          id
                          name
                          permaTag
                        }
                      }
                    }
                  }
                `}
                variables={{ input: searchText, isUpdateData: searchText }}
                render={({ error, props }) => {
                  if (error) {
                    console.error(error);
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return <div />;
                  }
                  //console.log("hash tags " , JSON.stringify(props))
                  return (
                    <li className="searchoptionchekbox">
                      {props.myHashtags.edges.map((item, index) => {
                        var isFound = false;
                        for (var i = 0; i < tags.edges.length; i++) {
                          if (
                            tags.edges[i].node.boardTag.name === item.node.name
                          ) {
                            isFound = true;
                            break;
                          }
                        }
                        return (
                          <SelectHashTagListItem
                            checked={isFound}
                            key={index}
                            data={item}
                            handleAddRegularHashTag={
                              this.handleAddRegularHashTag
                            }
                            handleRemoveTagsApi={handleRemoveTagsApi}
                          />
                        );
                      })}
                      {searchText === "" ? null : (
                        <label
                          className="createnewboard"
                          onClick={this.handleCreateHashTag}
                        >
                          <span className="addmore-icon">
                            <img
                              alt=""
                              src={require("../../images/iconPlus.svg")}
                            />
                          </span>
                          <span> Create "{searchText}"</span>
                        </label>
                      )}
                    </li>
                  );
                }}
              />
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default EditHashTagPopUp;
