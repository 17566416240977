/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type IndicatorMapCardContainerQueryVariables = {|
  input: string,
  centroidInput?: ?boolean,
|};
export type IndicatorMapCardContainerQueryResponse = {|
  +boardCardUse: ?{|
    +id: string,
    +mapImage: ?string,
    +configuration: {|
      +indicators: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +alpha: ?number,
            +indicator: ?{|
              +id: string
            |},
            +palette: ?number,
          |}
        |}>
      |},
      +palette: ?string,
      +savedArea: ?{|
        +id: string,
        +extent: ?$ReadOnlyArray<?number>,
        +primaryShapes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +geojsonFeature: ?string
            |}
          |}>
        |},
        +indicatorShapes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +geojsonFeature: ?string
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type IndicatorMapCardContainerQuery = {|
  variables: IndicatorMapCardContainerQueryVariables,
  response: IndicatorMapCardContainerQueryResponse,
|};
*/


/*
query IndicatorMapCardContainerQuery(
  $input: ID!
  $centroidInput: Boolean
) {
  boardCardUse(id: $input) {
    id
    mapImage
    configuration {
      indicators {
        edges {
          node {
            alpha
            indicator {
              id
            }
            palette
            id
          }
        }
      }
      palette
      savedArea {
        id
        extent
        primaryShapes {
          edges {
            node {
              geojsonFeature
            }
          }
        }
        indicatorShapes(id: $input, centroidsOnly: $centroidInput) {
          edges {
            node {
              geojsonFeature
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "centroidInput",
    "type": "Boolean"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "input"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mapImage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alpha",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SearchableIndicatorNode",
  "kind": "LinkedField",
  "name": "indicator",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "palette",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GeoJsonShapeNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonShapeNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "geojsonFeature",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SavedAreaNode",
  "kind": "LinkedField",
  "name": "savedArea",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeoJsonShapeNodeConnection",
      "kind": "LinkedField",
      "name": "primaryShapes",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "centroidsOnly",
          "variableName": "centroidInput"
        },
        (v1/*: any*/)
      ],
      "concreteType": "GeoJsonShapeNodeConnection",
      "kind": "LinkedField",
      "name": "indicatorShapes",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IndicatorMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardSelectedIndicatorNodeConnection",
                "kind": "LinkedField",
                "name": "indicators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardSelectedIndicatorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardSelectedIndicatorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IndicatorMapCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoardCardUseNode",
        "kind": "LinkedField",
        "name": "boardCardUse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardCardConfigurationNode",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardCardSelectedIndicatorNodeConnection",
                "kind": "LinkedField",
                "name": "indicators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardCardSelectedIndicatorNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BoardCardSelectedIndicatorNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v9/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "IndicatorMapCardContainerQuery",
    "operationKind": "query",
    "text": "query IndicatorMapCardContainerQuery(\n  $input: ID!\n  $centroidInput: Boolean\n) {\n  boardCardUse(id: $input) {\n    id\n    mapImage\n    configuration {\n      indicators {\n        edges {\n          node {\n            alpha\n            indicator {\n              id\n            }\n            palette\n            id\n          }\n        }\n      }\n      palette\n      savedArea {\n        id\n        extent\n        primaryShapes {\n          edges {\n            node {\n              geojsonFeature\n            }\n          }\n        }\n        indicatorShapes(id: $input, centroidsOnly: $centroidInput) {\n          edges {\n            node {\n              geojsonFeature\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f2da62511c2dfd954e7db07ac7e1746';

module.exports = node;
