import React, { Component } from 'react';
import D3PollutionChart from '../Card/D3PollutionChart';
import WideCardWrapper from '../WideCard/WideCardWrapper';

class D3PollutionWideChart extends Component {
  render() {
    const { id, pollutionData, showTwitterCardPopup } = this.props;
    const wideChartId = id + (showTwitterCardPopup ? 'pollutionWideChart' : 'pollutionTwitterChart');
    const content = (
      <div className='D3PollutionWideChart d-flex w-100 h-100'>
        {(pollutionData && pollutionData.data) &&
        <D3PollutionChart
          id={wideChartId}
          key={wideChartId}
          pollutionData={pollutionData}
          isWide={true}
        />
        }
      </div>
    );
    return (
      <>
        {showTwitterCardPopup ? content : <WideCardWrapper  {...this.props} fromChart={true}>
          {content}
        </WideCardWrapper>
        }
      </>
    );
  }
}

export default D3PollutionWideChart;
