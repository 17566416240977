import React, { Component } from "react";
import * as d3 from "d3";
import * as _ from "lodash";

class D3AreaAndLineChart extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 5, right: 10, bottom: 5, left: 40 };
    this.clientWidth = 217;
    this.clientHeight = 217;
    this.xAxisTitleHeight = 35;
    this.legendHeight = 25;
    this.state = {
      ratio: 1,
    };
  }

  componentDidUpdate() {
    this.update();
  }

  componentDidMount() {
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    if (!this.mounted) return;
    this.size = Math.min(
      this.parentEle.clientWidth,
      this.parentEle.clientHeight
    );
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height +
          this.margin.top +
          this.margin.bottom +
          this.xAxisTitleHeight +
          this.legendHeight
        }`
      );
  };

  init = () => {
    const { areaLineData } = this.props;
    this.x = d3.scaleLinear();
    this.y = d3.scaleLinear();
    if (areaLineData && areaLineData.pathTypes && areaLineData.data) {
      this.createSvg();
    }
  };

  createSvg = () => {
    const { id } = this.props;
    const svgId = id.replace(/=/g, "");
    const selection = d3
      .select(this.parentEle)
      .selectAll("svg")
      .data([svgId], (d) => d);
    selection
      .enter()
      .append("svg")
      .merge(selection)
      .attr("class", "D3AreaAndLineChart")
      .attr("id", (d) => d);
    selection.exit().remove();
    this.svg = d3.select(this.parentEle).select("svg#" + svgId);

    const gDefsSelection = this.svg.selectAll("g.defs-container").data(
      (d) => [d],
      (d) => d
    );
    gDefsSelection
      .enter()
      .append("g")
      .merge(gDefsSelection)
      .attr("class", "defs-container")
      .attr("id", (d) => d + "defs-container");
    gDefsSelection.exit().remove();
    this.defs = this.svg.select("g#" + svgId + "defs-container");

    const gSelection = this.svg.selectAll("g.g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gSelection)
      .attr("class", "g-container")
      .attr("id", (d) => d + "g-container");
    gSelection.exit().remove();
    this.svgG = this.svg.select("g#" + svgId + "g-container");

    const gLegendSelection = this.svg.selectAll("g.legend-g-container").data(
      (d) => [d],
      (d) => d
    );
    gSelection
      .enter()
      .append("g")
      .merge(gLegendSelection)
      .attr("class", "legend-g-container")
      .attr("id", (d) => d + "legend-g-container");
    gSelection.exit().remove();
    this.svgLegendG = this.svg.select("g#" + svgId + "legend-g-container");

    const gAreaSelection = this.svgG.selectAll("g.area-container").data(
      (d) => [d],
      (d) => d
    );
    gAreaSelection
      .enter()
      .append("g")
      .merge(gAreaSelection)
      .attr("class", "area-container")
      .attr("id", (d) => d + "area-container");
    gAreaSelection.exit().remove();
    this.areaG = this.svgG.select("g#" + svgId + "area-container");

    const linesAreaRect = this.areaG.selectAll("rect.lines-area-rect").data(
      (d) => [d],
      (d) => d
    );
    linesAreaRect
      .enter()
      .append("rect")
      .merge(linesAreaRect)
      .attr("id", (d) => d + "linesarearect")
      .attr("class", "lines-area-rect")
      .style("opacity", 0);
    linesAreaRect.exit().remove();
    this.linesAreaRect = this.areaG.select("rect#" + svgId + "linesarearect");

    const tooltipLine = this.svgG.selectAll("line.tooltip-line").data(
      (d) => [d],
      (d) => d
    );
    tooltipLine
      .enter()
      .append("line")
      .merge(tooltipLine)
      .attr("id", (d) => d + "tooltipline")
      .attr("class", "tooltip-line");
    tooltipLine.exit().remove();
    this.tooltipLine = this.svgG.select("line#" + svgId + "tooltipline");

    const gAxisSelection = this.svgG.selectAll("g.axis-container").data(
      (d) => [d],
      (d) => d
    );
    gAxisSelection
      .enter()
      .append("g")
      .merge(gAxisSelection)
      .attr("class", "axis-container")
      .attr("id", (d) => d + "axis-container");
    gAxisSelection.exit().remove();
    this.axisG = this.svgG.select("g#" + svgId + "axis-container");

    const xAxis = this.axisG.selectAll(".x.axis").data(
      (d) => [d],
      (d) => d
    );
    xAxis
      .enter()
      .append("g")
      .merge(xAxis)
      .attr("id", (d) => d + "xAxis")
      .attr("class", "x axis");
    xAxis.exit().remove();
    this.xAxis = this.axisG.select("g#" + svgId + "xAxis");

    const yAxis = this.axisG.selectAll(".y.axis").data(
      (d) => [d],
      (d) => d
    );
    yAxis
      .enter()
      .append("g")
      .merge(yAxis)
      .attr("id", (d) => d + "yAxis")
      .attr("class", "y axis");
    yAxis.exit().remove();
    this.yAxis = this.axisG.select("g#" + svgId + "yAxis");
    this.update();
  };

  autoAlignSVG = () => {
    const { isWide } = this.props;
    const { ratio } = this.state;
    //  Set the dimensions and margins of the diagram
    if (isWide) {
      this.size = Math.min(
        this.parentEle.clientWidth,
        this.parentEle.clientHeight
      );
    }
    //  Set the dimensions and margins of the diagram
    this.width = this.clientWidth - this.margin.left - this.margin.right;
    this.height =
      this.clientHeight -
      this.margin.top -
      this.margin.bottom -
      this.legendHeight -
      this.xAxisTitleHeight;

    this.x.range([0, this.width]);
    this.y.range([this.height, 0]);
    if (isWide && ratio !== this.size / 217) {
      this.setState({
        ratio: this.size / 217,
      });
    }
    //  moves the 'group' element to the top left margin
    this.svg
      .attr("width", this.size ? this.size : "100%")
      .attr("height", this.size ? this.size : "100%")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${
          this.height +
          this.margin.top +
          this.xAxisTitleHeight +
          this.margin.bottom +
          this.legendHeight
        }`
      );

    this.svgLegendG.attr(
      "transform",
      "translate(" +
        this.margin.left +
        "," +
        (this.height +
          this.margin.top +
          this.margin.bottom +
          this.xAxisTitleHeight +
          this.legendHeight / 2) +
        ")"
    );

    this.svgG.attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );
    this.xAxis.attr("transform", "translate(0, " + this.height + ")");
    this.yAxis.attr("transform", "translate(0, 0)");
    this.linesAreaRect
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this.height);
  };

  createPathType() {
    this.area = d3
      .area()
      .x((d) => this.x(d.age))
      .y0(this.y(0))
      .y1((d) => this.y(d.population));
    this.line = d3
      .line()
      .x((d) => this.x(d.age))
      .y((d) => this.y(d.population));
  }

  createGradient = () => {
    const { areaLineData } = this.props;
    _.forEach(
      _.filter(areaLineData.pathTypes, (d) => d.areaType),
      (d) => {
        const area = this.defs.selectAll("#" + d.areaType).data([d]);
        const newArea = area.enter().append("linearGradient");
        newArea
          .merge(area)
          .attr("id", d.areaType)
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "0%")
          .attr("y2", "100%");
        const stopSelection = newArea
          .merge(area)
          .selectAll("stop")
          .data((s) => [d, d]);
        stopSelection
          .enter()
          .append("stop")
          .merge(stopSelection)
          .attr("offset", (d, i) => (i === 0 ? "0%" : "100%"))
          .attr("stop-color", (d, i) => d.colors[i]);
      }
    );
  };

  update = () => {
    const { areaLineData } = this.props;
    if (areaLineData && areaLineData.pathTypes && areaLineData.data) {
      this.sources = areaLineData.pathTypes.map((id) => {
        return {
          id: id.areaType ? id.areaType : id.lineType,
          type: id,
          values: areaLineData.data.map((d) => {
            return {
              age: d.age,
              population: d[id.areaType ? id.areaType : id.lineType],
            };
          }),
        };
      });
      this.x.domain(d3.extent(areaLineData.data, (d) => d.age));
      this.y.domain([
        0,
        d3.max(this.sources, (c) => d3.max(c.values, (d) => d.population)),
      ]);

      this.autoAlignSVG();
      this.createPathType();
      this.createGradient();
      this.updateXAxis();
      this.updateYAxis();
      this.updateAreas();
      this.updateLegends();
    }
  };

  updateLegends = () => {
    const { areaLineData } = this.props;
    const { pathTypes } = areaLineData;
    // legends G enter, update, exit
    const rectHeight = 6;
    const rectWidth = 14;
    const legendG = this.svgLegendG
      .selectAll("g.legend")
      .data(pathTypes, (d) =>
        d && d.title ? d.title : d.areaType ? d.areaType : d.lineType
      );
    const newLegendG = legendG.enter().append("g");
    newLegendG
      .merge(legendG)
      .attr("class", (d, i) => "legend")
      .attr("id", (d, i) => "g-legend-" + i);
    legendG.exit().remove();
    // legends rect enter, update, exit
    const legendRect = newLegendG
      .merge(legendG)
      .selectAll("rect")
      .data(
        (d) => [d],
        (d) => (d && d.title ? d.title : d.areaType ? d.areaType : d.lineType)
      );
    const newRect = legendRect.enter().append("rect");
    newRect
      .merge(legendRect)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", rectWidth)
      .attr("height", rectHeight)
      .attr("rx", rectHeight / 2)
      .attr("ry", rectWidth / 2)
      .attr("transform", `translate(0,-${rectHeight / 2})`)
      .style("fill", (d) =>
        d.areaType ? "url(#" + d.areaType + ")" : d.stroke
      );
    legendRect.exit().remove();
    // legends text enter, update, exit
    const legendText = newLegendG
      .merge(legendG)
      .selectAll("text")
      .data(
        (d) => [d],
        (d) => (d && d.title ? d.title : d.areaType ? d.areaType : d.lineType)
      );
    const newText = legendText.enter().append("text");
    newText
      .merge(legendText)
      .attr("x", rectWidth + 5)
      .attr("y", 0)
      .style("font-size", "12px")
      .attr("text-anchor", "start")
      .attr("dy", "4px")
      .style("fill", "#424B54")
      .style("font-family", "ProximaNova-Regular")
      .text((d) => (d.title ? d.title : d.areaType ? d.areaType : d.lineType));
    legendText.exit().remove();
    let legendSize = 0;
    this.svgLegendG.selectAll("g.legend").attr("transform", function (d, i) {
      let box = { x: 0, width: 0 };
      if (i !== 0) {
        box = d3
          .select(this.parentNode)
          .select("g#g-legend-" + (i - 1))
          .node()
          .getBBox();
        legendSize = legendSize + box.width + 10;
      }
      d3.select(this).attr("transform", `translate(${legendSize},${0})`);
      return `translate(${legendSize},${0})`;
    });
  };

  updateXAxis = () => {
    this.xAxis.selectAll("text.x-axis-title").remove();
    this.xAxis
      .call(d3.axisBottom(this.x).ticks(5))
      .append("text")
      .attr("x", 0)
      .attr("class", "x-axis-title")
      .attr("transform", `translate(${this.width / 2}, 30)`)
      .attr("dy", "4px")
      .style("fill", "#989898")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .text("AGE (YEARS)");
  };

  updateYAxis = () => {
    this.yAxis.selectAll("text.y-axis-title").remove();
    this.yAxis
      .call(
        d3
          .axisLeft(this.y)
          .ticks(4)
          .tickFormat((d) => d + "%")
      )
      .append("text")
      .attr("class", "y-axis-title")
      .attr("dy", "0.71em")
      .style("fill", "#989898")
      .style("text-anchor", "middle")
      .style("font-family", "ProximaNova-Regular")
      .text("PERCENT OF POPULATION")
      .attr("transform", `translate(-35, ${this.height / 2})rotate(-90)`);
  };

  updateAreas = () => {
    const source = this.areaG
      .selectAll(".area")
      .data(this.sources, (d) => d.id);
    const newSource = source.enter().append("g");

    newSource
      .merge(source)
      .attr("class", (d) => `area ${d.id} ${d.type.className}`);
    const path = newSource
      .merge(source)
      .selectAll("path")
      .data((d) => [d]);
    const newPath = path.enter().append("path");
    newPath
      .merge(path)
      .attr("d", (d) =>
        d.type.areaType ? this.area(d.values) : this.line(d.values)
      )
      .each(function (d) {
        if (d.type.lineType) {
          d3.select(this)
            .style("stroke", d.type.stroke ? d.type.stroke : "#424B54")
            .style(
              "stroke-width",
              d.type["stroke-width"] ? d.type["stroke-width"] : "1.5px"
            );
        } else {
          d3.select(this).style("fill", "url(#" + d.type.areaType + ")");
        }
      });
    path.exit().remove();
    source.exit().remove();

    this.raiseLinesAreaRect();
  };

  raiseLinesAreaRect = () => {
    this.linesAreaRect
      .raise()
      .attr("opacity", 0)
      .on("mousemove", () => {
        d3.event.stopPropagation();
        this.drawTooltip();
      });
  };

  drawTooltip = (isRemove) => {
    const { areaLineData } = this.props;

    const makeTooltip = (data, mPos, svgMPos) => {
      const { ratio } = this.state;
      this.tooltipLine
        .attr("stroke", "#424B54")
        .attr("x1", data.length ? this.x(data[0].age) : 0)
        .attr("x2", data.length ? this.x(data[0].age) : 0)
        .attr("y1", 0)
        .attr("y2", this.height)
        .style("display", data.length ? "block" : "none");

      const tooltip = d3
        .select("body")
        .selectAll("div.d3-tooltip")
        .data(data, (d) => d);
      const newTooltip = tooltip.enter().append("div");
      newTooltip
        .merge(tooltip)
        .attr("class", "d3-tooltip")
        .style("display", "block")
        .style("position", "absolute")
        .style("font-size", ratio * 10 + "px")
        .style("background-color", "rgba(255, 255, 255, 1)")
        .style("box-shadow", "0px 5px 15px 0px rgba(0,0,0,0.3)")
        .style("padding", ratio + 5 + "px")
        .style("text-align", "left")
        .style("left", mPos[0] + "px")
        .style("top", mPos[1] - 20 + "px")
        .style(
          "transform",
          `translate(${svgMPos[0] > this.width / 2 ? "-110%" : "10%"},0)`
        )
        .on("mousemove", () => this.drawTooltip(true));

      const div = newTooltip
        .merge(tooltip)
        .selectAll("div.d3-tooltip-div")
        .data(
          (d) =>
            Object.keys(d)
              .filter(
                (key) => key !== "age" && key !== "males" && key !== "females"
              )
              .map((key, i) => {
                let text = d[key];
                let fontFamily = "ProximaNova-Regular";
                if (key === "xLabel") {
                  fontFamily = "ProximaNova-Bold";
                  let age = d["xLabel"].split("-");
                  if (age.length > 1) {
                    text = `Age ${age[0]} to ${age[1]} years`;
                  } else {
                    text = `Age ${age[0]} years`;
                  }
                }
                if (key === "ourcommunity") {
                  text = `${_.round(d[key], 1)}% of Our Community`;
                }
                if (key === "us") {
                  text = `${_.round(d[key], 1)}% of U.S.`;
                }

                return { id: i, text, fontFamily };
              }),
          (d) => d.id
        );
      const newDiv = div.enter().append("div");
      newDiv
        .merge(div)
        .attr("class", "d3-tooltip-div")
        .style("font-family", (d) => d.fontFamily)
        .html((d) => d.text);
      div.exit().remove();
      tooltip.exit().remove();
    };

    if (isRemove) {
      makeTooltip([], [0, 0], [0, 0]);
    } else {
      const isFirefox = typeof InstallTrigger !== "undefined";
      let x = isFirefox
        ? d3.event.layerX - 45
        : d3.mouse(this.linesAreaRect.node())[0] + 4;
      const age = Math.floor(this.x.invert(x));
      const tooltipData = areaLineData.data.find((o, i) => {
        o.xLabel = areaLineData.toolTips[i].xLabel;
        let data;
        if (i < areaLineData.data.length - 1) {
          data =
            parseInt(age, 10) >= parseInt(o.age, 10) &&
            parseInt(age, 10) < areaLineData.data[i + 1].age;
        } else if (i === areaLineData.data.length - 1) {
          data = parseInt(age, 10) <= parseInt(o.age, 10);
        }
        return data;
      });
      if (tooltipData && tooltipData.xLabel !== "...") {
        const mPos = d3.mouse(d3.select("body").node());
        const svgMPos = d3.mouse(this.svgG.node());
        //console.log(svgMPos, this.clientWidth / 2, "svgMPos");
        makeTooltip(
          [
            {
              xLabel: tooltipData.xLabel,
              ourcommunity: tooltipData.ourcommunity,
              us: tooltipData.us,
              age: tooltipData.age,
              males: tooltipData.males,
              females: tooltipData.females,
            },
          ],
          mPos,
          svgMPos
        );
      }
    }
  };

  render() {
    return (
      <div
        className="D3AreaAndLineChart h-100 w-100"
        onMouseMove={() => this.drawTooltip(true)}
      >
        <div
          className="d-flex justify-content-center align-items-center h-100"
          ref={(ele) => (this.parentEle = ele)}
        />
      </div>
    );
  }
}

export default D3AreaAndLineChart;
