import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import BroadStreetServiceAreaMap from "../BroadStreetServiceAreaMap/BroadStreetServiceAreaMap";
import "../../css/components/Card/D3DisparitiesDeprivationWideChart.css";
import "../../css/components/Card/WideServiceAreaMapCardContainer.css";
import WideCardWrapper from "../WideCard/WideCardWrapper";
import { DownloadConsumer } from "../Root/downloadContext";

class WideServiceAreaMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total",
      ourcommunity_data: [],
      statebenchmark_data: [],
      unitedstates_data: [],
      // TODO: display color codes for primary, secondary, tertiary geography
      legends: [],
    };
  }

  render() {
    const { legends } = this.state;
    const {
      id,
      wideCardData,
      showTwitterCardPopup,
      downloadWideVersion,
      isEditingEnabled,
    } = this.props;
    const middleContent = (
      <ul className="list-group mt-5 legend">
        {legends.map((legend, index) => {
          return (
            <li key={index} className="list-group-item p-1">
              <div style={{ background: legend.color }} />
              {legend.label}
            </li>
          );
        })}
      </ul>
    );
    const title = wideCardData.boardCardUse.configuration.savedArea.name;
    const content = (
      <div
        className={
          "wideServiceAreaMapCardContainer h-100 " +
          (isEditingEnabled ? "movable" : "clickable")
        }
        onClick={this.handleClick}
      >
        <div className="wideServiceAreaMapCardContainer-map">
          <QueryRenderer
            environment={environment}
            query={graphql`
              query WideServiceAreaMapCardContainerQuery($input: ID!) {
                boardCardUse(id: $input) {
                  id
                  mapImage
                  statistics {
                    id
                    jsonData
                  }
                  configuration {
                    savedArea {
                      id
                      name
                      typeOfArea
                      counties {
                        edges {
                          node {
                            id
                            name
                            fips5
                            geojsonFeature
                            state {
                              stateAb
                            }
                          }
                        }
                      }
                      places {
                        edges {
                          node {
                            id
                            geoId
                            name
                            geojsonFeature
                          }
                        }
                      }
                      zipcodes {
                        edges {
                          node {
                            id
                            zip
                            zcta {
                              zcta
                              geojsonFeature
                            }
                          }
                        }
                      }
                      reportstatisticsSet {
                        edges {
                          node {
                            id
                            saZctaCount
                            saGeo
                            saStateList
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            variables={{ input: id }}
            render={({ error, props }) => {
              if (error) {
                console.error(error);
                return <div>Error!</div>;
              }
              if (!props) {
                return (
                  <div>
                    <div className="serviceAreaMapCardContainer-map">
                      <br />
                      <br />
                      Loading...
                    </div>
                  </div>
                );
              }
              if (props.boardCardUse == null) {
                return (
                  <div>
                    <br />
                    You need to log in as a user with permissions to access this
                    content.
                  </div>
                );
              }
              const sa = props.boardCardUse.configuration.savedArea;
              if (sa == null || sa.reportstatisticsSet.edges.length === 0) {
                return (
                  <div>
                    <br />
                    (Map Not Available)
                  </div>
                );
              }
              const counties = sa.counties
                ? sa.counties.edges.map((edge) => {
                    return edge.node.geojsonFeature;
                  })
                : [];
              const places = sa.places
                ? sa.places.edges.map((edge) => {
                    return edge.node.geojsonFeature;
                  })
                : [];
              const zipcodes = sa.zipcodes
                ? sa.zipcodes.edges.map((edge) => {
                    return edge.node.zcta.geojsonFeature;
                  })
                : [];
              const wideCardId =
                sa.id +
                (downloadWideVersion ? "wideSAMapTwitter" : "wideSAMap");
              return (
                <div className="h-100">
                  <BroadStreetServiceAreaMap
                    id={wideCardId}
                    isEditingArea={false}
                    zipcodes={zipcodes}
                    counties={counties}
                    places={places}
                    typeOfArea={sa.typeOfArea.toLowerCase()}
                  />
                  <div className="wideServiceAreaMapCardContainer-watermrk-img">
                    <img
                      src={require("../../images/favicon1.svg")}
                      alt="BroadStreet"
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
    return (
      <DownloadConsumer>
        {(context) => {
          context.setCardId(id);
          context.setCardType("map");
          return showTwitterCardPopup ? (
            content
          ) : (
            <WideCardWrapper
              middleContent={middleContent}
              title={title}
              hideAreaTag={true}
              {...this.props}
            >
              {content}
            </WideCardWrapper>
          );
        }}
      </DownloadConsumer>
    );
  }
}

export default WideServiceAreaMapCardContainer;
