import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import D3RingWideChart from './D3RingWideChart';
import DownloadDataWideChart from './DownloadDataWideChart';
import withWideCardContainer from './WideCardContainer';
import D3DisparitiesDeprivationWideChart from './D3DisparitiesDeprivationWideChart';
import D3MultipleStackBarWideChart from './D3MultipleStackBarWideChart';
import gaugeImg from '../../images/gauge-green-red.svg';
import gaugeNeedleImg from '../../images/gauge-needle.svg';
import D3GaugeWideChart from './D3GaugeWideChart';
import D3AreaDeprivationScoreWideChart from './D3AreaDeprivationScoreWideChart';
import D3LineWideChart from './D3LineWideChart';
import D3LineDotWideChart from './D3LineDotWideChart';
import D3DualHorizontalBarWideChart from './D3DualHorizontalBarWiderChart';
import D3StackedBarWideChart from './D3StackedBarWideChart';
import D3PopulationBlockWideChart from './D3PopulationBlockWideChart';
import D3PollutionWideChart from './D3PollutionWideChart';
import D3PositiveNegativeHorizontalBarWideChart from './D3PositiveNegativeHorizontalBarWideChart';
import D3AreaAndLineWideChart from './D3AreaAndLineWideChart';
import D3ThermometerWideChart from './D3ThermometerWideChart';
import D3PieWideChart from './D3PieWideChart';
import D3HorizontalStackedBarWideChart from './D3HorizontalStackedBarWideChart';
import D3MultiForceLayoutWideChart from './D3MultiForceLayoutWideChart';
import D3HorizontalBarWideChart from './D3HorizontalBarWideChart';
import DiamondVerticalBarWideChart from './DiamondVerticalBarWideChart';
import D3BarWideChart from './D3BarWideChart';
import D3WordCloudWideChart from './D3WordCloudWideChart';
import D3GridProgressWideChart from './D3GridProgressWideChart';
import D3DotGridProgressWideChart from './D3DotGridProgressWideChart';
import D3SaladWideChart from './D3SaladWideChart';
import manGrid from '../../images/man-grid-white.svg';
import hatGrid from '../../images/icon-grid-hat-white.svg';
import dotGrid from "../../images/dot-grid-white.svg";
import TextLayoutType1Wide from './TextLayoutType1Wide';
import saladSvg from '../../images/salad.svg';
import TextLayoutType2Wide from './TextLayoutType2Wide';
import D3NumberWideCard from './D3NumberWideChart';
import D3CheckboxWideCard from './D3CheckboxWideCard';
import LunchWideChartCard from './D3LunchWideChart';
import D3ProgressBarWideChart from './D3ProgressBarWideChart';
import D3SimpleGaugeWideChart from './D3SimpleGaugeWideChart';
import D3ClickableGaugeWideChart from './D3ClickableGaugeWideChart';
import D3DonutProgressWideChart from './D3DonutProgressWideChart';
import D3PictureProgressWideChart from './D3PictureProgressWideChart';
import D3TextLayoutMotherWideChart from './D3TextLayoutMotherWideChart';
import D3TextLayoutBabyWideChart from './D3TextLayoutBabyWideChart';
import D3HeartWideChart from './D3HeartWideChart';
import D3MultipleHorizontalBarWideChart from './D3MultipleHorizontalBarWideChart';
import infantBottle from '../../images/infant-bottle.svg';
import D3MultipleStackHorizontalBarWideChart from './D3MultipleStackHorizontalBarWideChart';
import TwitterCardWrapper from '../WideCard/TwitterCardWrapper';
import PeopleWideChart from './PeopleWideChart';

class WideChartCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verticalBarData: {columns: []},
      msbData: [],
      familiesLivingBelowPoverty: {},
      areaDeprivationScore: {},
      lineChartData: {},
      lineDot: {},
      dhbData: {},
      hispanicPopulationData: {},
      percentBlackPopulationData: {},
      populationBlock: {data: [], blockTypes: []},
      dsgcData: {},
      pollutionData: {legends: [], data: []},
      pnhbData: {data: [], barTypes: []},
      areaLineData: {data: [], pathTypes: []},
      thermoData: {data: [], barTypes: [], imageHeight: 0, imageWidth: 0},
      pieData: {data: [], pieTypes: []},
      povertyByRaceCard: {},
      childFIMFLData: {nodes: []},
      peopleFIMFLData: {nodes: []},
      horizontalBar: {},
      infantHorizontalBar: {},
      medianHorizontalBar: {},
      diamondVerticalBarChartData: {data: [], barTypes: []},
      dualVerticalBarChartData: {data: [], barTypes: []},
      wordCloudChartData: {},
      hgpData: {},
      mgpData: {},
      dgpData: {},
      saladChartFEI: {},
      textLayoutType1: {},
      textLayoutType2: {},
      numberChildrenCard: {},
      checkboxCard: {},
      lunchChartData: {data: []},
      diabetesRateData: {},
      obesityRateData: {},
      progressBarChartData: {},
      simpleGaugeChartData: {data: [], pieTypes: []},
      clickableGaugeChartData: {data: [], pieTypes: []},
      multipleSectionBarChartData: {data: [], pieTypes: [], mouseoverColor: ''},
      highSchoolGraduation: {},
      hwcp: {},
      medianMonthlyMortgage: {},
      medianGrossRent: {},
      under9YearsEducation: {},
      whiteCollarEmp: {},
      peopleBelow150Poverty: {},
      medianFamilyIncome: {},
      unemploymentRate: {},
      homesWithCrowding: {},
      singleParentsWithChild: {},
      homesWithoutTel: {},
      homeOwnershipRate: {},
      medianHomeVal: {},
      homesMotorVehicle: {},
      svgFillData: {},
      svgTextLayoutType2Data: {},
      svgTextLayoutType1Data: {},
      heartChartData: {},
      multipleHorizontalBarChartData: {},
      svgRingChartData: {},
      peopleChartData: {},
    };
  }

  setStatistics = (props) => {
    const {boardCardUse} = props.wideCardData;
    const statistics = boardCardUse.statistics;
    if (statistics) {
      switch (boardCardUse.subComponentType) {
        case 'verticalBarChart':
          const verticalBarData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({verticalBarData, ...prevState.verticalBarData}));
          break;
        case 'multipleStackBar':
          const msbData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({msbData, ...prevState.msbData}));
          break;
        case 'familiesLivingBelowPoverty':
          const familiesLivingBelowPoverty = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({familiesLivingBelowPoverty, ...prevState.familiesLivingBelowPoverty}));
          break;
        case 'areaDeprivationScore':
          const areaDeprivationScore = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({areaDeprivationScore, ...prevState.areaDeprivationScore}));
          break;
        case 'lineChart':
          const lineChartData = JSON.parse(statistics.jsonData);
          lineChartData.name = statistics.name;
          this.setState((prevState) => ({lineChartData, ...prevState.lineChartData}));
          break;
        case 'lineDot':
          const lineDot = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({lineDot: lineDot, ...prevState.lineDot}));
          break;
        case 'dualHorizontalBarChart':
          this.setState((prevState) => ({dhbData: JSON.parse(statistics.jsonData), ...prevState.dhbData}));
          break;
        case 'hispanicPopulationSBChart':
          const hispanicPopulationData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({hispanicPopulationData, ...prevState.hispanicPopulationData}));
          break;
        case 'percentBlackPopulationCard':
          const percentBlackPopulationData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({percentBlackPopulationData, ...prevState.percentBlackPopulationData}));
          break;
        case 'populationBlock':
          const populationBlock = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({populationBlock: populationBlock, ...prevState.populationBlock}));
          break;
        case 'donutSvgGaugeChart':
          const donutSvgGaugeChart = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({dsgcData: donutSvgGaugeChart, ...prevState.dsgcData}));
          break;
        case 'svgFillChart':
          const svgFillChart = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({svgFillData: svgFillChart, ...prevState.svgFillData}));
          break;
        case 'svgTextLayoutType2':
          const svgTextLayoutType2Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({svgTextLayoutType2Data: svgTextLayoutType2Data, ...prevState.svgTextLayoutType2Data}));
          break;
        case 'svgTextLayoutType1':
          const svgTextLayoutType1Data = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({svgTextLayoutType1Data: svgTextLayoutType1Data, ...prevState.svgTextLayoutType1Data}));
          break;
        case 'heartChart1':
        case 'heartChart2':
        case 'heartChart3':
        case 'heartChart4':
          const heartChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({heartChartData: heartChartData, ...prevState.heartChartData}));
          break;
        case 'multipleHorizontalBarChart':
          const multipleHorizontalBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({multipleHorizontalBarChartData: multipleHorizontalBarChartData, ...prevState.multipleHorizontalBarChartData}));
        break;
        case 'svgRingChart':
          const svgRingChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({svgRingChartData: svgRingChartData, ...prevState.svgRingChartData}));
        break;
        case 'pollutionChart':
          const pollutionData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({pollutionData, ...prevState.pollutionData}));
          break;
        case 'positiveNegativeHorizontalBar':
          const pnhbData = JSON.parse(statistics.jsonData);
          pnhbData.data && pnhbData.data.unshift({label: '', value: 0});
          this.setState((prevState) => ({pnhbData: pnhbData, ...prevState.pnhbData}));
          break;
        case 'areaLineChart':
          this.setState((prevState) => ({areaLineData: JSON.parse(statistics.jsonData), ...prevState.areaLineData}));
          break;
        case 'thermometerStackProgress':
          const thermoData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({thermoData, ...prevState.thermoData}));
          break;
        case 'donutChart':
          this.setState((prevState) => ({pieData: JSON.parse(statistics.jsonData), ...prevState.pieData}));
          break;
        case 'povertyByRaceCard':
          const jsonData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({...{povertyByRaceCard: jsonData}, ...prevState.povertyByRaceCard}));
          break;
        case 'childFIMultiForceLayout':
          const childFIMFLData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({childFIMFLData, ...prevState.childFIMFLData}));
          break;
        case 'peopleFIMultiForceLayout':
          const peopleFIMFLData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({peopleFIMFLData, ...prevState.peopleFIMFLData}));
          break;
        case 'diamondVerticalBarChart':
          const diamondVerticalBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({diamondVerticalBarChartData, ...prevState.diamondVerticalBarChartData}));
          break;
        case 'dualVerticalBarChart':
          const dualVerticalBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({dualVerticalBarChartData, ...prevState.dualVerticalBarChartData}));
          break;
        case 'wordCloud':
          const wordCloudChartData = JSON.parse(statistics.jsonData);
          wordCloudChartData['chartImage'] = boardCardUse.chartImage;
          this.setState((prevState) => ({wordCloudChartData, ...prevState.wordCloudChartData}));
          break;
        case 'hatGridProgress':
          const hgpData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({hgpData: hgpData, ...prevState.hgpData}));
          break;
        case 'manGridProgress':
          const manGridProgress = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({mgpData: manGridProgress, ...prevState.mgpData}));
          break;
        case 'dotGridProgress':
          const dgpData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({dgpData: dgpData, ...prevState.dgpData}));
          break;
        case 'saladChartFEI':
          const saladChartFEI = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({saladChartFEI, ...prevState.saladChartFEI}));
          break;
        case 'horizontalBar':
          const sd = JSON.parse(statistics.jsonData);
          if (boardCardUse && boardCardUse.configuration && boardCardUse.configuration.title === 'Median Family Income') {
            this.setState((prevState) => ({medianHorizontalBar: sd, ...prevState.medianHorizontalBar}));
          } else if (boardCardUse && boardCardUse.configuration && boardCardUse.configuration.title === 'Infant Deaths') {
            this.setState((prevState) => ({infantHorizontalBar: sd, ...prevState.infantHorizontalBar}));
          } else {
            this.setState((prevState) => ({horizontalBar: sd, ...prevState.horizontalBar}));
          }
          break;
        case 'textLayoutType1':
          const textLayoutType1 = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({textLayoutType1: textLayoutType1, ...prevState.textLayoutType1}));
          break;
        case 'textLayoutType2':
          const textLayoutType2 = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({textLayoutType2: textLayoutType2, ...prevState.textLayoutType2}));
          break;
        case 'numberChildrenCard':
        case 'numberChildrenUnder5Card':
        case 'numberCard':
          const numberChildrenCard = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({numberChildrenCard, ...prevState.numberChildrenCard}));
          break;
        case 'checkboxCard':
          const checkboxCard = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({checkboxCard, ...prevState.checkboxCard}));
          break;
        case 'lunchChart':
          const lunchChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({lunchChartData, ...prevState.lunchChartData}));
          break;
        case 'diabetesRateChart':
          const diabetesRateData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({diabetesRateData, ...prevState.diabetesRateData}));
          break;
        case 'obesityRateChart':
          const obesityRateData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({obesityRateData, ...prevState.obesityRateData}));
          break;
        case 'progressBarChart':
          const progressBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({progressBarChartData, ...prevState.progressBarChartData}));
          break;
        case 'simpleGaugeChart':
          const simpleGaugeChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({simpleGaugeChartData, ...prevState.simpleGaugeChartData}));
          break;
        case 'clickableGaugeChart':
          const clickableGaugeChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({clickableGaugeChartData, ...prevState.clickableGaugeChartData}));
          break;
        case 'multipleSectionBarChart':
          const multipleSectionBarChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({multipleSectionBarChartData, ...prevState.multipleSectionBarChartData}));
          break;
        case 'highSchoolGraduation':
          const highSchoolGraduation = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({highSchoolGraduation, ...prevState.highSchoolGraduation}));
          break;
        case 'homesWithoutCompletePlumbing':
          const hwcp = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({hwcp, ...prevState.hwcp}));
          break;
        case 'medianMonthlyMortgage':
          const medianMonthlyMortgage = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({medianMonthlyMortgage, ...prevState.medianMonthlyMortgage}));
          break;
        case 'medianGrossRent':
          const medianGrossRent = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({medianGrossRent, ...prevState.medianGrossRent}));
          break;
        case 'under9YearsEducation':
          const under9YearsEducation = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({under9YearsEducation, ...prevState.under9YearsEducation}));
          break;
        case 'whiteCollarEmp':
          const whiteCollarEmp = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({whiteCollarEmp, ...prevState.whiteCollarEmp}));
          break;
        case 'peopleBelow150Poverty':
          const peopleBelow150Poverty = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({peopleBelow150Poverty, ...prevState.peopleBelow150Poverty}));
          break;
        case 'medianFamilyIncome':
          const medianFamilyIncome = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({medianFamilyIncome, ...prevState.medianFamilyIncome}));
          break;
        case 'unemploymentRate':
          const unemploymentRate = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({unemploymentRate, ...prevState.unemploymentRate}));
          break;
        case 'homesWithCrowding':
          const homesWithCrowding = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({homesWithCrowding, ...prevState.homesWithCrowding}));
          break;
        case 'singleParentsWithChild':
          const singleParentsWithChild = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({singleParentsWithChild, ...prevState.singleParentsWithChild}));
          break;
        case 'homesWithoutTel':
          const homesWithoutTel = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({homesWithoutTel, ...prevState.homesWithoutTel}));
          break;
        case 'homeOwnershipRate':
          const homeOwnershipRate = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({homeOwnershipRate, ...prevState.homeOwnershipRate}));
          break;
        case 'medianHomeVal':
          const medianHomeVal = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({medianHomeVal, ...prevState.medianHomeVal}));
          break;
          default:
        case 'peopleChart1':
          const peopleChartData = JSON.parse(statistics.jsonData);
          this.setState((prevState) => ({peopleChartData, ...prevState.peopleChartData}));
          break;
        // console.log(subComponentType, JSON.parse(statistics.jsonData))
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setStatistics(nextProps);
  }

  componentDidMount() {
    this.setStatistics(this.props);
  }

  getSubComponent = (data) => {
    // Each component will take 100% height and 100% width of its parent..
    const {subComponentType} = data;
    const {
      verticalBarData, msbData, familiesLivingBelowPoverty, areaDeprivationScore, lineChartData,
      lineDot, dhbData, hispanicPopulationData, percentBlackPopulationData, populationBlock, pollutionData,
      pnhbData, areaLineData, thermoData, pieData, povertyByRaceCard, peopleFIMFLData, childFIMFLData,
      infantHorizontalBar, medianHorizontalBar, horizontalBar, diamondVerticalBarChartData, dualVerticalBarChartData, wordCloudChartData,
      hgpData, dgpData, mgpData, saladChartFEI, textLayoutType1, textLayoutType2, numberChildrenCard, checkboxCard, lunchChartData,
      diabetesRateData, obesityRateData, progressBarChartData, simpleGaugeChartData, clickableGaugeChartData,
      multipleSectionBarChartData, highSchoolGraduation, hwcp, medianMonthlyMortgage, medianGrossRent,
      under9YearsEducation, whiteCollarEmp, peopleBelow150Poverty, medianFamilyIncome, unemploymentRate,
      homesWithCrowding, singleParentsWithChild, homesWithoutTel, homeOwnershipRate, medianHomeVal,
      homesMotorVehicle, dsgcData, svgFillData, svgTextLayoutType2Data, svgTextLayoutType1Data, heartChartData,
      multipleHorizontalBarChartData, svgRingChartData, peopleChartData
    } = this.state;

    if (subComponentType === 'verticalBarChart') {
      return <D3DisparitiesDeprivationWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'd3DisparitiesDeprivationWideChart'}
        vbData={verticalBarData}/>;
    } else if (subComponentType === 'multipleStackBar') {
      return <D3MultipleStackBarWideChart
        {...this.props}
        id={data.id + 'd3MultipleStackBarWideChart'}
        key={data.id + 'd3MultipleStackBarWideChart'}
        msbData={msbData}/>;
    } else if (subComponentType === 'familiesLivingBelowPoverty') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id + 'd3GaugeWideChart'}
        key={data.id + 'd3GaugeWideChart'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={familiesLivingBelowPoverty.min}
        max={familiesLivingBelowPoverty.max}
        value={familiesLivingBelowPoverty.value}
        valueText={familiesLivingBelowPoverty.valueText}
        text={familiesLivingBelowPoverty.text}
        unit={familiesLivingBelowPoverty.unit}
      />;
    } else if (subComponentType === 'areaDeprivationScore') {
      return <D3AreaDeprivationScoreWideChart
        {...this.props}
        id={data.id + 'd3AreaDeprivationScoreWideChart'}
        key={data.id + 'd3AreaDeprivationScoreWideChart'}
        areaDeprivationScoreData={areaDeprivationScore}
        value={areaDeprivationScore.value}
        mainTitle={areaDeprivationScore.mainTitle}
        mainSubTitle={areaDeprivationScore.mainSubTitle}/>;
    } else if (subComponentType === 'lineChart') {
      return <D3LineWideChart
        {...this.props}
        id={data.id + 'D3LineWideChart'}
        key={data.id + 'D3LineWideChart'}
        lineData={lineChartData}
        mainTitle={lineChartData.name}/>;
    } else if (subComponentType === 'lineDot') {
      return <D3LineDotWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'lineDotWide'}
        maxValue={lineDot.maxValue}
        minValue={lineDot.minValue}
        lineDotData={lineDot.data}
        lineColor={lineDot.lineColor}
        dotColor={lineDot.dotColor}
        dotRadius={lineDot.dotRadius}
        lineWidth={lineDot.lineWidth}
        titleText={lineDot.titleText}
      />;
    } else if (subComponentType === 'dualHorizontalBarChart') {
      return <D3DualHorizontalBarWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'dualHorizontalBarWideChart'}
        dhbData={dhbData}
      />;
    } else if (subComponentType === 'hispanicPopulationSBChart') {
      return <D3StackedBarWideChart
        id={data.id} key={data.id + 'hispanicPopulationSBWideChart'}
        sbData={hispanicPopulationData}
        {...this.props}
      />;
    } else if (subComponentType === 'percentBlackPopulationCard') {
      return <D3StackedBarWideChart
        id={data.id} key={data.id + 'percentBlackPopulationWideCard'}
        sbData={percentBlackPopulationData}
        {...this.props}
      />;
    } else if (subComponentType === 'populationBlock') {
      return <D3PopulationBlockWideChart
        id={data.id}
        key={data.id + 'populationWideBlock'}
        populationBlock={populationBlock}
        {...this.props}
      />;
    } else if (subComponentType === 'donutSvgGaugeChart') {
      if (Object.keys(dsgcData).length) {
        let svgImage;
        try {
          svgImage = require(`../../images/${dsgcData.svg1}`);
        } catch(e) {
          console.error(e);
        }
        return <D3DonutProgressWideChart
          id={data.id + 'donutSvgGaugeWideChart'}
          key={data.id + 'donutSvgGaugeWideChart'}
          percent={dsgcData.value ? dsgcData.value : 0}
          text={dsgcData.mainText}
          image={svgImage || infantBottle}
          {...this.props}
        />;
      } else {
        return null;
      }
    } else if (subComponentType === 'svgFillChart') {
      let type;
      if (svgFillData.svg1 === 'piggy-bank.svg') {
        type = "PIGGY_BANK";
      }
      if (Object.keys(svgFillData).length) {
        return <D3PictureProgressWideChart
          id={data.id}
          key={data.id + 'D3PictureProgressWideChart'}
          percent={svgFillData.value ? svgFillData.value : 0.1}
          text={svgFillData.mainText}
          type={type}
          chartData={svgFillData}
          {...this.props}
        />;
      } else {
        return null;
      }
    } else if (subComponentType === 'svgTextLayoutType2') {
      if (Object.keys(svgTextLayoutType2Data).length) {
        return <D3TextLayoutMotherWideChart
          id={data.id}
          key={data.id + 'D3TextLayoutMotherWideChart'}
          chartData={svgTextLayoutType2Data}
          {...this.props}
        />;
      } else {
        return null;
      }
    } else if (subComponentType === 'svgTextLayoutType1') {
      if (Object.keys(svgTextLayoutType1Data).length) {
        return <D3TextLayoutBabyWideChart
          id={data.id}
          key={data.id + 'D3TextLayoutBabyChart'}
          chartData={svgTextLayoutType1Data}
          {...this.props}
        />;
      } else {
        return null;
      }
    }
    else if (subComponentType.includes('heartChart')) {
      if (Object.keys(heartChartData).length) {
        return <D3HeartWideChart
          id={data.id}
          key={data.id + 'D3HeartChart'}
          chartData={heartChartData}
          subComponentType={subComponentType}
          {...this.props}
        />;
      } else {
        return null;
      }

    }
    else if (subComponentType === 'multipleHorizontalBarChart') {
      if (multipleHorizontalBarChartData.data) {
        return <D3MultipleHorizontalBarWideChart
          id={data.id}
          key={data.id + 'D3MultipleHorizontalBarChart'}
          chartData={multipleHorizontalBarChartData}
          {...this.props}
        />;
      }
    } else if (subComponentType === 'svgRingChart') {
      if (Object.keys(svgRingChartData).length) {
        return  <D3RingWideChart
          id={data.id}
          key={data.id + 'D3RingChart'}
          chartData={svgRingChartData}
          {...this.props}
        />
      } else {
        return null;
      }
    } else if (subComponentType === 'pollutionChart') {
      return <D3PollutionWideChart
        id={data.id}
        key={data.id + 'pollutionChart'}
        pollutionData={pollutionData}
        {...this.props}
      />;
    } else if (subComponentType === 'positiveNegativeHorizontalBar') {
      return <D3PositiveNegativeHorizontalBarWideChart
        id={data.id}
        key={data.id + 'positiveNegativeHorizontalBar'}
        pnhbData={pnhbData}
        {...this.props}
      />;
    } else if (subComponentType === 'areaLineChart') {
      return <D3AreaAndLineWideChart
        key={data.id + 'areaLineChart'}
        id={data.id}
        areaLineData={areaLineData}
        {...this.props}
      />;
    } else if (subComponentType === 'thermometerStackProgress') {
      return <D3ThermometerWideChart
        id={data.id}
        key={data.id + 'thermometerStackProgress'}
        thermoData={thermoData}
        {...this.props}
      />;
    } else if (subComponentType === 'donutChart') {
      return <D3PieWideChart
        id={data.id}
        key={data.id + 'donutChart'}
        thickness={30}
        pieData={pieData}
        {...this.props}
      />;
    } else if (subComponentType === 'povertyByRaceCard') {
      return <D3HorizontalStackedBarWideChart
        id={data.id}
        key={data.id + 'povertyByRaceCard'}
        povertyByRaceCard={povertyByRaceCard}
        {...this.props}
      />;
    } else if (subComponentType === 'childFIMultiForceLayout') {
      return <D3MultiForceLayoutWideChart
        id={data.id} key={data.id + 'childFIMultiForceLayout'}
        nodes={childFIMFLData.nodes} title={childFIMFLData.title}
        switchLabel1={childFIMFLData.switchLabel1} switchLabel2={childFIMFLData.switchLabel2}
        {...this.props}
      />;
    } else if (subComponentType === 'peopleFIMultiForceLayout') {
      return <D3MultiForceLayoutWideChart
        id={data.id} key={data.id + 'peopleFIMultiForceLayout'}
        nodes={peopleFIMFLData.nodes} title={peopleFIMFLData.title}
        switchLabel1={peopleFIMFLData.switchLabel1} switchLabel2={peopleFIMFLData.switchLabel2}
        {...this.props}
      />;
    } else if (subComponentType === 'horizontalBar') {
      if (data.configuration.title === 'Median Family Income') {
        return <D3HorizontalBarWideChart
          id={data.id + 'horizontalWideBar'}
          horizontalBar={medianHorizontalBar}
          key={data.id + 'horizontalBar'}
          {...this.props}
        />;
      } else if (data.configuration.title === 'Infant Deaths') {
        return <D3HorizontalBarWideChart
          id={data.id + 'horizontalWideBar'}
          horizontalBar={infantHorizontalBar}
          key={data.id + 'horizontalBar'}
          {...this.props}
        />;
      } else {
        return <D3HorizontalBarWideChart
          id={data.id + 'horizontalWideBar'}
          horizontalBar={horizontalBar}
          key={data.id + 'horizontalBar'}
          {...this.props}
        />;
      }
    } else if (subComponentType === 'diamondVerticalBarChart') {
      return <DiamondVerticalBarWideChart
        id={data.id + 'barWideChart'}
        key={data.id + 'barWideChart'}
        diamondVerticalBarChartData={diamondVerticalBarChartData}
        {...this.props}
      />;
    } else if (subComponentType === 'dualVerticalBarChart') {
      return <D3BarWideChart
        id={data.id + 'barWideChart'}
        key={data.id + 'barWideChart'}
        dualVerticalBarChartData={dualVerticalBarChartData}
        {...this.props}
      />;
    } else if (subComponentType === 'wordCloud') {
      return <D3WordCloudWideChart
        id={data.id + 'wordCloudWideChart'}
        key={data.id + 'wordCloudWideChart'}
        wordCloudChartData={wordCloudChartData}
        {...this.props}
      />;
    } else if (subComponentType === 'hatGridProgress') {
      return <D3GridProgressWideChart
        id={data.id + 'hatGridProgress'}
        key={data.id + 'hatGridProgress'}
        imagePath={hatGrid}
        chartData={hgpData}
        {...this.props}
      />;
    } else if (subComponentType === 'dotGridProgress') {
      return <D3DotGridProgressWideChart
        id={data.id + 'dotGridProgress'}
        key={data.id + 'dotGridProgress'}
        imagePath={dotGrid}
        chartData={dgpData}
        {...this.props}
      />;
    } else if (subComponentType === 'manGridProgress') {
      return <D3GridProgressWideChart
        id={data.id + 'manGridProgress'}
        key={data.id + 'manGridProgress'}
        imagePath={manGrid}
        chartData={mgpData}
        {...this.props}
      />;
    } else if (subComponentType === 'saladChartFEI') {
      return <D3SaladWideChart
        id={data.id}
        key={data.id + 'saladChartFEI'}
        saladSrc={saladSvg}
        saladChartFEI={saladChartFEI}
        {...this.props}
      />;
    } else if (subComponentType === 'textLayoutType1') {
      return <TextLayoutType1Wide
        id={data.id}
        key={data.id + 'textLayoutType1'}
        textLayoutType1={textLayoutType1}
        {...this.props}
      />;
    } else if (subComponentType === 'textLayoutType2') {
      return <TextLayoutType2Wide
        id={data.id}
        key={data.id + 'textLayoutType2'}
        textLayoutType2={textLayoutType2}
        {...this.props}
      />;
    } else if (subComponentType === 'numberChildrenCard' ||
      subComponentType === 'numberChildrenUnder5Card' ||
        subComponentType === 'numberCard'
      || subComponentType === 'numberSeniorsCard') {
      return <D3NumberWideCard
        id={data.id}
        key={data.id + 'numberChildrenCard'}
        numberChildrenCard={numberChildrenCard}
        {...this.props}
      />;
    } else if (subComponentType === 'checkboxCard') {
      return <D3CheckboxWideCard
        id={data.id}
        key={data.id + 'checkboxCard'}
        checkboxCard={checkboxCard}
        {...this.props}
      />;
    } else if (subComponentType === 'diabetesRateChart') {
      return <D3ProgressBarWideChart
        id={data.id}
        key={data.id + 'diabetesRateChart'}
        chartData={diabetesRateData}
        {...this.props}
      />;
    } else if (subComponentType === 'lunchChart') {
      return (
        <LunchWideChartCard
          id={data.id}
          key={data.id + 'lunchChartWide'}
          lunchChartData={lunchChartData}
          {...this.props}/>);
    } else if (subComponentType === 'obesityRateChart') {
      return <D3ProgressBarWideChart
        id={data.id}
        key={data.id + 'obesityRateChart'}
        chartData={obesityRateData}
        {...this.props}
      />;
    } else if (subComponentType === 'progressBarChart') {
      return <D3ProgressBarWideChart
        id={data.id}
        key={data.id + 'progressBarChart'}
        chartData={progressBarChartData}
        {...this.props}
      />;
    } else if (subComponentType === 'simpleGaugeChart') {
      return <D3SimpleGaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'simpleGaugeChart'}
        simpleGaugeChartData={simpleGaugeChartData}
      />;
    } else if (subComponentType === 'clickableGaugeChart') {
      return <D3ClickableGaugeWideChart
        id={data.id}
        key={data.id + 'clickableGaugeChart'}
        clickableGaugeChartData={clickableGaugeChartData}
        min={0}
        max={100}
        {...this.props}
      />;
    } else if (subComponentType === 'multipleSectionBarChart') {
      return <D3MultipleStackHorizontalBarWideChart
        id={data.id + 'multipleSectionBarChart'}
        key={data.id + 'multipleSectionBarChart'}
        msbData={multipleSectionBarChartData}
        {...this.props}/>;
    } else if (subComponentType === 'highSchoolGraduation') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'highSchoolGraduation'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={highSchoolGraduation.min}
        max={highSchoolGraduation.max}
        value={highSchoolGraduation.value}
        valueText={highSchoolGraduation.valueText}
        text={highSchoolGraduation.text}
        unit={highSchoolGraduation.unit}
      />;
    } else if (subComponentType === 'homesWithoutCompletePlumbing') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'homesWithoutCompletePlumbing'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={hwcp.min}
        max={hwcp.max}
        value={hwcp.value}
        valueText={hwcp.valueText}
        text={hwcp.text}
        unit={hwcp.unit}
      />;
    } else if (subComponentType === 'medianMonthlyMortgage') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'medianMonthlyMortgage'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={medianMonthlyMortgage.min}
        max={medianMonthlyMortgage.max}
        value={medianMonthlyMortgage.value}
        valueText={medianMonthlyMortgage.valueText}
        text={medianMonthlyMortgage.text}
        unit={medianMonthlyMortgage.unit}
      />;
    } else if (subComponentType === 'medianGrossRent') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'medianGrossRent'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={medianGrossRent.min}
        max={medianGrossRent.max}
        value={medianGrossRent.value}
        valueText={medianGrossRent.valueText}
        text={medianGrossRent.text}
        unit={medianGrossRent.unit}
      />;
    } else if (subComponentType === 'under9YearsEducation') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'under9YearsEducation'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={under9YearsEducation.min}
        max={under9YearsEducation.max}
        value={under9YearsEducation.value}
        valueText={under9YearsEducation.valueText}
        text={under9YearsEducation.text}
        unit={under9YearsEducation.unit}
      />;
    } else if (subComponentType === 'whiteCollarEmp') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'whiteCollarEmp'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={whiteCollarEmp.min}
        max={whiteCollarEmp.max}
        value={whiteCollarEmp.value}
        valueText={whiteCollarEmp.valueText}
        text={whiteCollarEmp.text}
        unit={whiteCollarEmp.unit}
      />;
    } else if (subComponentType === 'peopleBelow150Poverty') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'peopleBelow150Poverty'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={peopleBelow150Poverty.min}
        max={peopleBelow150Poverty.max}
        value={peopleBelow150Poverty.value}
        valueText={peopleBelow150Poverty.valueText}
        text={peopleBelow150Poverty.text}
        unit={peopleBelow150Poverty.unit}
      />;
    } else if (subComponentType === 'medianFamilyIncome') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'medianFamilyIncome'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={medianFamilyIncome.min}
        max={medianFamilyIncome.max}
        value={medianFamilyIncome.value}
        valueText={medianFamilyIncome.valueText}
        text={medianFamilyIncome.text}
        unit={medianFamilyIncome.unit}
      />;
    } else if (subComponentType === 'unemploymentRate') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'unemploymentRate'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={unemploymentRate.min}
        max={unemploymentRate.max}
        value={unemploymentRate.value}
        valueText={unemploymentRate.valueText}
        text={unemploymentRate.text}
        unit={unemploymentRate.unit}
      />;
    } else if (subComponentType === 'homesWithCrowding') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'homesWithCrowding'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={homesWithCrowding.min}
        max={homesWithCrowding.max}
        value={homesWithCrowding.value}
        valueText={homesWithCrowding.valueText}
        text={homesWithCrowding.text}
        unit={homesWithCrowding.unit}
      />;
    } else if (subComponentType === 'singleParentsWithChild') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={singleParentsWithChild.min}
        max={singleParentsWithChild.max}
        value={singleParentsWithChild.value}
        valueText={singleParentsWithChild.valueText}
        text={singleParentsWithChild.text}
        unit={singleParentsWithChild.unit}
      />;
    } else if (subComponentType === 'homesWithoutTel') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'homesWithoutTel'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={homesWithoutTel.min}
        max={homesWithoutTel.max}
        value={homesWithoutTel.value}
        valueText={homesWithoutTel.valueText}
        text={homesWithoutTel.text}
        unit={homesWithoutTel.unit}
      />;
    } else if (subComponentType === 'homeOwnershipRate') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'homeOwnershipRate'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={homeOwnershipRate.min}
        max={homeOwnershipRate.max}
        value={homeOwnershipRate.value}
        valueText={homeOwnershipRate.valueText}
        text={homeOwnershipRate.text}
        unit={homeOwnershipRate.unit}
      />;
    } else if (subComponentType === 'medianHomeVal') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'medianHomeVal'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={medianHomeVal.min}
        max={medianHomeVal.max}
        value={medianHomeVal.value}
        valueText={medianHomeVal.valueText}
        text={medianHomeVal.text}
        unit={medianHomeVal.unit}
      />;
    } else if (subComponentType === 'homesMotorVehicle') {
      return <D3GaugeWideChart
        {...this.props}
        id={data.id}
        key={data.id + 'homesMotorVehicle'}
        gauge={gaugeImg}
        needle={gaugeNeedleImg}
        min={homesMotorVehicle.min}
        max={homesMotorVehicle.max}
        value={homesMotorVehicle.value}
        valueText={homesMotorVehicle.valueText}
        text={homesMotorVehicle.text}
        unit={homesMotorVehicle.unit}
      />;
    } else if (subComponentType === 'multipleStackBar') {
      return <D3MultipleStackBarWideChart
        {...this.props}
        id={data.id + 'd3MultipleStackBarWideChart'}
        key={data.id + 'd3MultipleStackBarWideChart'}
        msbData={msbData}
      />;
    } else if (subComponentType === 'downloadDataFamilyADI') {
      return (<QueryRenderer
        environment={environment}
        query={graphql`
                      query WideChartCardContainerDownloadAdiQuery($input: ID!) {
                        boardCardUse(id: $input) {
                          adiComponentTable
                        }
                      }
                    `}
        variables={{
          input: data.id
        }}
        render={({error, props}) => {
          if (error) {
            console.error(error);
            return <div>Error!</div>;
          }
          if (!props) {
            return <div>Loading...</div>;
          }
          if (props.boardCardUse == null) {
            return (
              <div>
                <br/>
                You need to log in as a user with permissions to access this
                card.
              </div>
            );
          }
          const familyADI = JSON.parse(props.boardCardUse.adiComponentTable);

          return (
            <DownloadDataWideChart
              {...this.props}
              id={data.id}
              key={data.id + 'downloadDataCard'}
              familyADI={familyADI}
            />
          );
        }}
      />);
    } else if(subComponentType === 'peopleChart1'){
      return (
        <PeopleWideChart
          {...this.props}
          id={data.id + "peopleChart1"}
          key={data.id + "peopleChart1"}
          peopleChartData={peopleChartData}
        />
      ); 
    }
  };

  render() {
    const {wideCardData, showTwitterCardPopup} = this.props;
    return (
      <div className='card h-100 pt-3'>
        <div className='card-body h-75 py-0 px-sm-5 px-3'>
          {showTwitterCardPopup ?
            <div style={{position: 'absolute', left: '-30000px', zIndex: '999999'}}>
              <TwitterCardWrapper {...this.props} fromChart={true}>
                {this.getSubComponent(wideCardData.boardCardUse)}
              </TwitterCardWrapper>
            </div>
            :
            this.getSubComponent(wideCardData.boardCardUse)
          }
        </div>
      </div>
    );
  }
}

export default withWideCardContainer(WideChartCardContainer);
